import React from 'react';
import { OrderStatus } from 'generated/graphql';
import {
  Container,
  TopPart,
  BottomPart,
  MoneyAndStatus,
  Value,
  Status,
  Description,
  Date,
} from './styles';

type Props = {
  moneyValue: string;
  status: string;
  statusType: OrderStatus;
  description: string;
  date: string;
  state: 'enabled' | 'disabled';
};

const Card: React.FC<Props> = ({
  moneyValue,
  status,
  statusType,
  description,
  date,
  state,
}) => (
  <Container state={state}>
    <TopPart>
      <MoneyAndStatus>
        <Value
          style={{
            textDecoration: state === 'disabled' ? 'line-through' : 'none',
          }}
        >
          {moneyValue}
        </Value>
        <Status type="text" status={statusType}>
          {status}
        </Status>
      </MoneyAndStatus>
      <Description>{description}</Description>
    </TopPart>
    <BottomPart>
      <Date>{date}</Date>
    </BottomPart>
  </Container>
);

export default Card;
