import React from 'react';
import { Container, Title, Date, Hour } from './styles';

type CardProps = {
  title: string;
  date: string;
  hour: string;
};

const Card: React.FC<CardProps> = ({ title, date, hour }) => (
  <Container>
    <Title>{title}</Title>
    <Date>{date}</Date>
    <Hour>{hour}</Hour>
  </Container>
);

export default Card;
