import React from 'react';
import { PricingMethod, PricingConfig } from 'types/station';
import { formatPricingConfig } from 'services/formatPriceConfig';
import { useTranslation } from 'react-i18next';
import { TranslationKeyType } from 'types/translation';
import { CurrencyType } from 'types';
import { H3 } from '../../../Typographies/styles';
import {
  Container,
  Title,
  Item,
  ItemSeparator,
  ItemTypography,
} from './styles';

type Props = {
  energyConsumed: string;
  pricingConfig: PricingConfig | undefined;
  subtotal: string;
  chargeFee: string;
  totalCost: string;
  duration: string;
  currencyType?: CurrencyType;
};

const pricingLabel: Record<PricingMethod, TranslationKeyType> = {
  PerTimePricingConfig: 'recharge-modal.per-time.princing.config.title',
  PerEnergyPricingConfig: 'recharge-modal.per-energy.pricing.config',
};

const SessionCost: React.FC<Props> = ({
  energyConsumed,
  pricingConfig,
  subtotal,
  chargeFee,
  totalCost,
  duration,
  currencyType,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t('recharge-modal.session-cost.header.title')}</Title>
      {pricingConfig && (
        <>
          {pricingConfig.__typename === 'PerEnergyPricingConfig' && (
            <Item>
              <ItemTypography>
                {t('recharge-modal.session-cost.energy-consumed.title')}
              </ItemTypography>
              <ItemTypography>{energyConsumed}</ItemTypography>
            </Item>
          )}
          {pricingConfig.__typename === 'PerTimePricingConfig' && (
            <Item>
              <ItemTypography>
                {t('recharge-modal.session-cost.duration.title')}
              </ItemTypography>
              <ItemTypography>{duration || '-'}</ItemTypography>
            </Item>
          )}
          <Item>
            <ItemTypography>
              {t(pricingLabel[pricingConfig.__typename])}
            </ItemTypography>
            <ItemTypography>
              {formatPricingConfig(pricingConfig, undefined, currencyType)}
            </ItemTypography>
          </Item>
        </>
      )}
      <ItemSeparator></ItemSeparator>
      <Item>
        <ItemTypography>
          {t('recharge-modal.session-cost.sub-total.title')}
        </ItemTypography>
        <ItemTypography>{subtotal}</ItemTypography>
      </Item>
      <Item>
        <ItemTypography>
          {t('recharge-modal.session-cost.charge-fee.title')}
        </ItemTypography>
        <ItemTypography>{chargeFee}</ItemTypography>
      </Item>
      <Item>
        <H3>{t('recharge-modal.session-cost.total-cost.title')}</H3>
        <H3>{totalCost}</H3>
      </Item>
    </Container>
  );
};

export default SessionCost;
