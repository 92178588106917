import {
  Maybe,
  StationConnectivityStatus,
  StationModelCapability,
  StationPageDetailsQuery,
  StationPageDetailsQueryVariables,
} from 'generated/graphql';
import stationPageDetails from 'graphql/queries/stationPageDetails';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type StationContextType = {
  id: string;
  stationName: string;
  lastConnectedAt: string;
  stationConnectivityStatus: StationConnectivityStatus;
  loading: boolean;
  setStationId: (id: string) => void;
  activeAlarmsCount: number;
  archived: any;
  model: Maybe<{ capabilities: StationModelCapability[] }>;
  cpoId: string;
};

const StationContext = React.createContext<StationContextType>({
  id: '',
  archived: true,
  stationName: '',
  lastConnectedAt: '',
  stationConnectivityStatus: 'OFFLINE',
  setStationId: () => {
    throw new Error('not implemented');
  },
  loading: true,
  activeAlarmsCount: 0,
  model: {
    capabilities: [],
  },
  cpoId: '',
});

const StationProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [stationId, setStationId] = useState('');

  const { data, loading } = useAuthQuery<
    StationPageDetailsQuery,
    StationPageDetailsQueryVariables
  >(stationPageDetails, {
    variables: {
      where: { stationId },
      includeAlarms: hasScopes(['alarm:read']),
    },
    fetchPolicy: 'network-only',
    skip: !stationId,
  });

  /* @ts-ignore FIXME: */
  const {
    name,
    stationConnectivityStatus = 'OFFLINE',
    lastConnectedAt,
    activeAlarms,
    archived,
    model,
    cpoId,
  } = data?.station ?? {};

  return (
    <StationContext.Provider
      value={{
        id: stationId,
        stationName: name || '',
        lastConnectedAt: lastConnectedAt
          ? t('station-page.last-received-signal.title', {
              lastConnectedAt: moment(lastConnectedAt).fromNow(),
            })
          : '',
        stationConnectivityStatus,
        setStationId,
        activeAlarmsCount: activeAlarms?.count ?? 0,
        loading,
        archived,
        model,
        cpoId: cpoId || '',
      }}
    >
      {children}
    </StationContext.Provider>
  );
};

export { StationContext, StationProvider };
