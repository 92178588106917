import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { H3 } from 'new-components/Typographies/styles';
import React, { useContext, useState } from 'react';
import { useAuthMutation } from 'hooks';
import toast from 'services/toast';
import AuthContext from 'contexts/Auth/context';
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
} from 'generated/graphql';
import createOrganizationMutation from 'graphql/mutations/createOrganization';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SelectOrganizationDropdown } from 'atomic-components/molecules/SelectOrganizationDropdown';
import { Header, Body, OrganizationLabel } from './styles';

type Props = {
  onClose: () => void;
  show: boolean;
  onCreatedOrganization?: () => void;
};

const CreateOrganizationModal: React.FC<Props> = ({
  onClose,
  show,
  onCreatedOrganization,
}) => {
  const { t } = useTranslation();
  const [newOrg, setNewOrg] = useState('');
  const { organizations } = useContext(AuthContext);
  const [selectedOrganization, setSelectedOrganization] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(undefined);

  const [createOrganization, { loading }] = useAuthMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(createOrganizationMutation, {
    context: { headers: { 'org-id': selectedOrganization?.id ?? '' } },
  });

  const handleNewOrgChange = (value: string) => {
    setNewOrg(value);
  };

  const handleCreateOrgClick = async () => {
    if (!selectedOrganization?.id) return;
    try {
      await createOrganization({
        variables: {
          data: {
            id: newOrg.slugify(),
            name: newOrg,
          },
          where: {
            parentOrganizationId: selectedOrganization.id,
          },
        },
      });
      toast.success(t('create-organization-modal.toast-successfully-created'));
      onClose();
      onCreatedOrganization?.();
    } catch (e) {
      console.error(e);
      toast.error(t('create-organization-modal.toast-generic-error'));
    }
  };

  return (
    <Modal size="md" show={show} onClose={onClose} centered>
      <Modal.Header>
        <Header>
          <Icon color="PRIMARY" type="NEW_ORG" size={24} />
          <H3>{t('create-organization-modal.header.title')}</H3>
        </Header>
        <Modal.Header.Attachments padding="24">
          <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Box>
            <Flex flexFlow="column">
              <FormControl>
                <FormLabel fontSize="sm" mb={4}>
                  {t('create-organization-modal.form.label')}{' '}
                  <OrganizationLabel>
                    {selectedOrganization?.name ?? '_______'}
                  </OrganizationLabel>
                </FormLabel>
                <Flex>
                  <Box h={10} w="full" mr={6}>
                    <Input
                      onChange={(e) => handleNewOrgChange(e.target.value)}
                      placeholder={t(
                        'create-organization-modal.form.input-placeholder'
                      )}
                      value={newOrg}
                    />
                  </Box>
                  <SelectOrganizationDropdown
                    value={
                      selectedOrganization?.name ??
                      t(
                        'create-organization-modal.form.select-organization-dropdown.default-value'
                      )
                    }
                    setSelectedOrganization={setSelectedOrganization}
                    organizations={organizations ?? []}
                  />
                </Flex>
              </FormControl>
              <Flex alignSelf="flex-end" mt={8}>
                <Button
                  onClick={handleCreateOrgClick}
                  isLoading={loading}
                  disabled={newOrg.trim() === '' || !selectedOrganization}
                >
                  {t('create-organization-modal.form.btn')}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

export default CreateOrganizationModal;
