import Typography from 'components/Typography';
import Config from 'config';
import styled, { css } from 'styled-components';

const dropdownOptionHeight = 36;
const reverse = (openDirection: 'top' | 'bottom') =>
  openDirection === 'top' ? 'bottom' : 'top';

export const DropdownSelectorContainer = styled.div<{ isWide?: boolean }>`
  position: relative;
  width: ${({ isWide }) => (isWide ? '255px' : '194px')};
  height: 45px;
  margin-right: 7px;
  & > * {
    box-sizing: unset;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 45px;
`;

export const Icon = styled(Config.ICONS.PAGINATION_UP)<{
  hashover: 'true' | 'false';
}>`
  position: absolute;
  right: 6px;
  margin-top: 12px;
  height: 10px;
  width: 10px;
  padding: 7px;
  border-radius: 6px;
  margin: 5px -7px -7px;
  transform: rotate(180deg);
  box-sizing: unset;

  ${({ hashover }) =>
    hashover === 'true' &&
    css`
      &:hover {
        background: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
      }
    `}
`;

export const DropdownSelector = styled.div<{
  open: boolean;
  numberOfOptions: number;
  index: number;
  openDirection: 'top' | 'bottom';
  hover?: boolean;
}>`
  border-radius: 9px;
  border: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  user-select: none;
  cursor: pointer;
  background: ${({ theme }) => theme.COLORS.WHITE};
  display: flex;
  ${({ open, numberOfOptions, index, openDirection, theme: { COLORS } }) => css`
  ${reverse(openDirection)}: 0px;
  height: ${
    open ? dropdownOptionHeight * numberOfOptions : dropdownOptionHeight
  }px;
  width: 100%;
  transition: height .3s;
  flex-flow: ${openDirection === 'bottom' ? 'column' : 'column-reverse'} nowrap;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: unset;
  &:active {
    background: ${!open && COLORS.BACKGROUND};
  }
  > * {
    :first-child {
      background: ${({ theme }) => theme.COLORS.WHITE};
    }
    :nth-child(2) {
      margin-${reverse(openDirection)}: 0px;
    }
    &:not(${Icon}) {
      pointer-events: ${open ? 'auto' : 'none'};
      :nth-child(${index + 1}) {
        > ${Typography} {
          color: ${open && COLORS.CLICKABLE};
        }
      }
    }
  }
  `}
  ${({ hover, theme }) =>
    hover &&
    css`
      margin: 1px;
      border: 5px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
      background: ${theme.COLORS.BACKGROUND};
      box-sizing: content-box;
    `}
`;

export const ContainerCommonIntervalPick = styled.div`
  position: absolute;
  z-index: 1;
`;

export const ContainerPersonalizedIntervalPick = styled.div`
  position: absolute;
  z-index: 1;
  top: 50px;
  right: 0px;
  width: 818px;
  display: flex;
  background: ${({ theme }) => theme.COLORS.WHITE};
  flex-flow: row-reverse nowrap;
  border-radius: 12px;
  > ${DropdownSelectorContainer} {
    right: 12px;
    margin-right: -4px;
    > ${DropdownSelector} {
      height: 432px;
      border-radius: 0 9px 9px 0;
      margin-top: 1px;
      &:hover {
        border: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
        margin: 1px 5px 5px;
        cursor: default;
      }
    }
  }
`;

export const DropdownOption = styled.div`
  width: 100%;
  display: flex;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.BACKGROUND};
  }
  ${Typography} {
    margin-right: 45px;
    margin-left: 40px;
    height: 35px;
    align-items: center;
    display: flex;
  }
`;

export const CalendarIcon = styled(Config.ICONS.CALENDAR)`
  position: absolute;
  left: 17px;
  padding-top: 8px;
  width: 14px;
  box-sizing: unset;
`;
