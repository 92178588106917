import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
} from '@chakra-ui/react';
import {
  CreateMemberMutation,
  CreateMemberMutationVariables,
  MemberRole,
  UpdateMemberMutation,
  UpdateMemberMutationVariables,
} from 'generated/graphql';
import createMemberMutation from 'graphql/mutations/createMember';
import updateMemberMutation from 'graphql/mutations/updateMember';
import { useAuthMutation } from 'hooks';
import NewCard from 'new-components/NewCard';
import Select from 'new-components/Select';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'services/toast';
import {
  Form,
  InputWrapper,
  LeftAlignedButtonRow,
  PaddedContainer,
  SpaceBetweenButtons,
} from './styles';

export type FormValues = {
  memberId?: number;
  email?: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  role: MemberRole;
};

type Props = {
  refetchMembers: () => void;
  selectedAccounToEdit?: FormValues;
  organizationsIds: string[];
};

const MemberForm: React.FC<Props> = ({
  refetchMembers,
  selectedAccounToEdit,
  organizationsIds,
}) => {
  const { t } = useTranslation();
  const defaultValues: FormValues = {
    memberId: undefined,
    firstName: '',
    lastName: '',
    email: '',
    role: 'VIEWER',
    organizationId: organizationsIds[0],
  };

  const [values, setValues] = useState<FormValues>(defaultValues);

  useEffect(() => {
    if (selectedAccounToEdit) setValues(selectedAccounToEdit);
  }, [selectedAccounToEdit]);

  const clearForm = () => {
    setValues(defaultValues);
  };

  const isEditing = useMemo(() => !!values.memberId, [values.memberId]);

  const [dispatchCreateMemberMutation, { loading: loadingCreateMember }] =
    useAuthMutation<CreateMemberMutation, CreateMemberMutationVariables>(
      createMemberMutation,
      {
        // with this we, turn this into a workspaced mutation given the current organizationId form value
        context: { headers: { 'org-id': values.organizationId } },
      }
    );
  const [dispatchUpdateMemberMutation, { loading: loadingUpdateMember }] =
    useAuthMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(
      updateMemberMutation,
      {
        // with this we, turn this into a workspaced mutation given the current organizationId form value
        context: { headers: { 'org-id': values.organizationId } },
      }
    );

  const createMember = async () => {
    const { errors } = await dispatchCreateMemberMutation({
      variables: {
        data: {
          ...values,
          email: values.email!,
        },
      },
    });

    if (errors) {
      toast.error(
        t('configurations.member-form.toast.error-in-registration-message')
      );
    } else {
      clearForm();
      toast.success(
        t('configurations.member-form.toast.toast-successfully-message')
      );
      refetchMembers();
    }
  };

  const updateMember = async () => {
    const { firstName, lastName, role, organizationId } = values;
    if (!values.memberId) return;
    const { errors } = await dispatchUpdateMemberMutation({
      variables: {
        where: { memberId: values.memberId, organizationId },
        data: {
          firstName,
          lastName,
          role,
          organizationId,
        },
      },
    });

    if (errors) {
      toast.error(
        t('configurations.member-form.toast.error-editing-user-message')
      );
    } else {
      clearForm();
      toast.success(
        t('configurations.member-form.toast-user-edit-successfully-message')
      );
      refetchMembers();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEditing) await updateMember();
    else await createMember();
  };

  return (
    <NewCard>
      <PaddedContainer>
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                {t('configurations.member-form.first-name.title')}
              </FormLabel>
              <Input
                onChange={(e) =>
                  setValues({ ...values, firstName: e.target.value })
                }
                value={values.firstName}
              />
              <FormErrorMessage>
                {t('required-field-error.message')}
              </FormErrorMessage>
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                {t('configurations.member-form.last-name.title')}
              </FormLabel>
              <Input
                onChange={(e) =>
                  setValues({ ...values, lastName: e.target.value })
                }
                value={values.lastName}
              />
              <FormErrorMessage>
                {t('required-field-error.message')}
              </FormErrorMessage>
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <FormControl isRequired>
              <FormLabel fontSize="sm" fontWeight="bold">
                {t('configurations.member-form.email.title')}
              </FormLabel>
              <Input
                type="email"
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                value={values.email}
                disabled={isEditing}
                placeholder={t(
                  'configurations.member-form.email-input.placeholder'
                )}
              />
              <FormErrorMessage>
                {t('required-field-error.message')}
              </FormErrorMessage>
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Select<string>
              label={t('configurations.member-form.select.placeholder')}
              value={values.organizationId}
              options={organizationsIds}
              onChange={(organizationId) =>
                setValues({ ...values, organizationId })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Select<MemberRole>
              label="Role"
              options={[
                'STAFF',
                'OWNER',
                'ADMIN',
                'SUPPORT',
                'TECHNICIAN',
                'VIEWER',
              ]}
              value={values.role}
              onChange={(role) => setValues({ ...values, role })}
            />
          </InputWrapper>
          <LeftAlignedButtonRow>
            <Button
              type="submit"
              isLoading={loadingUpdateMember || loadingCreateMember}
            >
              {t('configurations.member-form.submit-btn.title', {
                title: isEditing ? 'Editar' : 'Cadastrar',
              })}
            </Button>
            {isEditing && (
              <>
                <SpaceBetweenButtons />
                <Button
                  colorScheme="gray"
                  variant="outline"
                  onClick={() => clearForm()}
                  fontWeight="normal"
                >
                  {t('configurations.member-form.cancel-btn.title')}
                </Button>
              </>
            )}
          </LeftAlignedButtonRow>
        </Form>
      </PaddedContainer>
    </NewCard>
  );
};

export default MemberForm;
