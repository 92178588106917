import ReactGA from 'react-ga';

type Event = {
  category: 'Station' | 'Session';
  action: string;
  label: string;
};

const sendEvent = ({ category, action, label }: Event) =>
  ReactGA.event({
    category,
    action,
    label,
  });

export default sendEvent;
