import styled from 'styled-components';
import { Typography } from 'components';

export const H1 = styled(Typography).attrs({
  size: 30,
  weight: 'bold',
})``;
export const H2 = styled(Typography).attrs({
  size: 24,
  weight: 'bold',
})``;
export const H3 = styled(Typography).attrs({
  size: 18,
  weight: 'bold',
})``;
export const H4 = styled(Typography).attrs({
  size: 16,
  weight: 'bold',
})``;
export const H5 = styled(Typography).attrs({
  size: 14,
  weight: 'bold',
})``;
export const H6 = styled(Typography).attrs({
  size: 10,
})``;
export const BodyTypography = styled(Typography).attrs({
  size: 14,
})``;
export const Comment = styled(Typography).attrs({
  size: 12,
})``;
