import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './assets/fonts/Rawline-Light.ttf';
import './assets/fonts/Roboto-Black.ttf';
import './assets/fonts/Roboto-Bold.ttf';
import './assets/fonts/Roboto-Medium.ttf';
import './assets/fonts/Roboto-Regular.ttf';
import './assets/fonts/Gotham-Light.otf';
import './assets/fonts/Gotham-Black.otf';
import './assets/fonts/Gotham-Bold.otf';
import './assets/fonts/Gotham-Medium.otf';
import './assets/fonts/Mont-Bold.ttf';
import './assets/fonts/Mont-Regular.ttf';
import './ReactotronConfig.js';
import './custom.scss';
import env from '@beam-australia/react-env';
import * as serviceWorker from './serviceWorker.js';
import DashboardApp from './main/dashboard/App';
import ResetPasswordApp from './main/reset-password/App';
import AccountExclusionApp from './main/account-exclusion/App';

export type ApplicationName =
  | 'DASHBOARD'
  | 'RESET_PASSWORD'
  | 'ACCOUNT_EXCLUSION';

TagManager.initialize({
  gtmId: 'GTM-TXXCSDSZ',
});

const renderApp = () => {
  const applicationName = env('APPLICATION_NAME') as ApplicationName;
  if (applicationName === 'RESET_PASSWORD') return <ResetPasswordApp />;
  if (applicationName === 'ACCOUNT_EXCLUSION') return <AccountExclusionApp />;

  return <DashboardApp />;
};
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    itemprop?: string;
  }
}

ReactDOM.render(renderApp(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO: Check a good place to put that, maybe something like a Init.ts;
declare global {
  interface String {
    slugify(separador?: string): string;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.slugify = function slugify(separator = '-') {
  return this.toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);
};
