import { useContext, useEffect, useState } from 'react';
import { CreateVoucherEventsContext } from '../../../context/events-context';

export const useCreateVoucherAbout = () => {
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    events.updateVoucherName(name);
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    events.updateVoucherDescription(description);
    // eslint-disable-next-line
  }, [description]);

  return {
    name,
    setName,
    description,
    setDescription,
  };
};
