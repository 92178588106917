import React, { useEffect, useState, useContext } from 'react';

import Table, { Column } from 'components/Table';

import {
  TableStationEventsQuery,
  TableStationEventsQueryVariables,
} from 'generated/graphql';
import { Icon } from 'new-components/Icon';
import { StationContext } from 'pages/StationPage/StationContext';
import { formatAlarmLabel } from 'services/formatAlarm';

import JSONInterface from 'components/JSONInterface';
import tableStationEvents from 'graphql/queries/tableStationEvents';
import { useAuthQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { BlankStateContainer } from 'components/Table/styles';
import { BlankState } from 'new-components/BlankState';
import { StationLogsContext } from '../StationLogsContext';

import {
  Container,
  JSONSidebar,
  JSONWrapper,
  MessageContainer,
} from './styles';

type StreamEvent = NonNullable<
  TableStationEventsQuery['station']
>['events'][number];

const defaultPagination = {
  pageIndex: 0,
  pageSize: 25,
};

export const StationEvents = () => {
  const { t } = useTranslation();
  const { id: stationId } = useContext(StationContext);
  const { selectedAlarmStationLogId } = useContext(StationLogsContext);

  const [{ pageIndex, pageSize }, setPagination] = useState(defaultPagination);

  const columns: Column<StreamEvent>[] = [
    {
      Header: t('station-page.station-logs-tab.table.column-version.title'),
      accessor: 'streamVersion',
      disableSortBy: true,
      Cell: ({ row: { original } }) => original.streamVersion,
    },
    {
      Header: t('station-page.station-logs-tab.table.column-version.title'),
      accessor: 'type',
      disableSortBy: true,
    },
    {
      Header: t('station-page.station-logs-tab.table.column-message.title'),
      accessor: 'severity',
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <MessageContainer id={`alarm-${original.streamVersion.toString()}`}>
          {original.alarm ? (
            <>
              <Icon color="CRITICAL" type="NEW_INFO" />
              <span>{formatAlarmLabel(original.alarm)}</span>
            </>
          ) : (
            '-'
          )}
        </MessageContainer>
      ),
    },
    {
      Header: t('station-page.control-tab.station-events-table.date.title'),
      accessor: 'timestamp',
      disableSortBy: true,
      Cell: ({ row: { original } }) =>
        t('station-page.station-logs-tab.table.column-date.value', {
          value: new Date(original.timestamp),
        }),
    },
  ];

  const queryVariables: TableStationEventsQueryVariables = {
    where: { stationId },
    limit: pageSize,
    offset: pageIndex * pageSize,
    filter: {
      stationLogId: selectedAlarmStationLogId ?? undefined,
    },
  };

  const { data, loading } = useAuthQuery<
    TableStationEventsQuery,
    TableStationEventsQueryVariables
  >(tableStationEvents, { variables: queryVariables });

  const numberOfPages = Math.ceil(
    (data?.station?.eventsSummary.count ?? 0) / pageSize
  );

  const renderedEvents: StreamEvent[] = data?.station?.events ?? [];

  useEffect(() => {
    if (selectedAlarmStationLogId) setPagination(defaultPagination);
  }, [selectedAlarmStationLogId]);

  return (
    <Container>
      <Table<StreamEvent>
        paginated
        expandable
        columns={columns}
        data={renderedEvents}
        pageCount={numberOfPages}
        pageIndex={pageIndex}
        loading={loading}
        fetchData={setPagination}
        renderRowSubComponent={({ row: { original } }) => (
          <JSONWrapper>
            <JSONSidebar />
            <JSONInterface data={original.data} mode="view" />
          </JSONWrapper>
        )}
        renderBlankState={
          renderedEvents.length ? undefined : (
            <BlankStateContainer size={51} top={35}>
              <BlankState
                icon="NEW_ERROR"
                title="Sem registro de Logs"
                description="Não foram encontrados registros para os parâmetros definidos para a sua busca."
              />
            </BlankStateContainer>
          )
        }
      />
    </Container>
  );
};

export default StationEvents;
