import { createContext } from 'react';

export type CreateVoucherStateData = {
  name: string;
  description: string;
  discount: number | null;
  organizationId: string | null;
  limits: {
    isUnlimited: boolean;
    usageLimit: number | null;
  };
  validityPeriod: {
    isUnexpirable: boolean;
    from: Date | null;
    to: Date | null;
  };
  driversSelection: {
    allDriversSelected: boolean;
    allDriversSelectedExcept: number[];
    selectedDrivers: number[];
  };
  stationsSelection: {
    allStationsSelected: boolean;
    allStationsSelectedExcept: string[];
    selectedStations: string[];
  };
};

export const createVoucherInitialStateData: CreateVoucherStateData = {
  name: '',
  description: '',
  discount: null,
  organizationId: null,
  limits: {
    isUnlimited: false,
    usageLimit: null,
  },
  validityPeriod: {
    isUnexpirable: false,
    from: null,
    to: null,
  },
  driversSelection: {
    allDriversSelected: false,
    allDriversSelectedExcept: [],
    selectedDrivers: [],
  },
  stationsSelection: {
    allStationsSelected: false,
    selectedStations: [],
    allStationsSelectedExcept: [],
  },
};

type CreateVoucherStatesContextType = {
  createVoucherData: CreateVoucherStateData;
};

export const CreateVoucherStatesContext =
  createContext<CreateVoucherStatesContextType>({
    createVoucherData: createVoucherInitialStateData,
  });
