import { gql } from '@apollo/client';

export default gql`
  query emspsRfidsList($search: String, $pagination: PaginationInput) {
    rfids(search: $search, pagination: $pagination, sort: { emspId: ASC }) {
      hexId
      status
      decimalId
      activatedAt
      consumerLabel
      emspId
    }
  }
`;
