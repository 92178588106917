import { ProfileDropdown } from 'new-components/ProfileDropdown';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from './styles';

const TopNavbar: React.FC = ({ children }) => (
  <Container justifyFlexEnd={!children}>
    {children && children}

    <ProfileDropdown />
  </Container>
);

export default withRouter(TopNavbar);
