import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import Config from 'config';
import { useTranslation } from 'react-i18next';
import { useArchiveVoucherModal } from './service';

function ArchiveVoucherModal() {
  const { archiveVoucher, archiveVoucherModalVisible, closeModal } =
    useArchiveVoucherModal();

  const { t } = useTranslation();

  return (
    <Modal onClose={closeModal} isOpen={archiveVoucherModalVisible} isCentered>
      <ModalOverlay />
      <ModalContent
        borderColor={'TERTIARY_GRAY'}
        borderRadius={8}
        borderWidth={1}
        maxWidth={500}
      >
        <ModalCloseButton />
        <Box p={6}>
          <Heading color={'black'} mb={4} fontSize={'lg'} fontWeight={700}>
            {t('payments.voucher-tab.table.archive-voucher-modal.title')}
          </Heading>
          <Text fontSize={'md'} color={'blackAlpha.800'}>
            {t('payments.voucher-tab.table.archive-voucher-modal.description')}
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} p={6}>
          <Button
            data-testid={'close-modal'}
            bg={'none'}
            colorScheme={'gray'}
            color={'primary.500'}
            onClick={() => closeModal()}
            mr={4}
          >
            {t(
              'payments.voucher-tab.table.archive-voucher-modal.button-cancel'
            )}
          </Button>
          <Button
            data-testid={'archive-voucher'}
            bg={Config.COLORS.CRITICAL}
            colorScheme={'red'}
            onClick={archiveVoucher}
          >
            {t(
              'payments.voucher-tab.table.archive-voucher-modal.button-archive'
            )}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default ArchiveVoucherModal;
