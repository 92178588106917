import { Typography } from 'components';
import Table, { Column } from 'components/Table';
import Config from 'config';
import {
  StationConnectorsQuery,
  StationConnectorsQueryVariables,
  StationOwnerControlDetailsQuery,
} from 'generated/graphql';
import stationConnectors from 'graphql/queries/stationConnectors';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import { ManageConnectorDropdown } from 'new-components/ManageConnectorDropdown';
import Tag from 'new-components/Tag';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPower } from 'services/format';
import { CentralizedRow, ContainerConnectors, ContainerIcon } from './styles';

type StationConnector = NonNullable<
  StationConnectorsQuery['station']
>['connectors'][number];

interface Props {
  station: NonNullable<StationOwnerControlDetailsQuery['station']>;
  stationId: string;
}

const ConnectorsTable = ({ station, stationId }: Props) => {
  const { t } = useTranslation();
  const [columns] = useState<Column<StationConnector>[]>([
    {
      Header: t('station-page.control-connectors-table.id.title'),
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { original } }) => original.id,
    },
    {
      Header: t('station-page.control-connectors-table.connector.title'),
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const ConnectorImage = Config.ICONS.CONNECTORS[original.type];
        const connectorTypeText = `${original.currentType}-${
          Config.LABELS.CONNECTOR_TYPES[original.type]
        }`;
        return (
          <ContainerConnectors>
            <ContainerIcon>
              <ConnectorImage width={20} heigth={20} />
            </ContainerIcon>
            <Typography>{connectorTypeText}</Typography>
          </ContainerConnectors>
        );
      },
    },
    {
      Header: t('station-page.control-connectors-table.power.title'),
      accessor: 'power',
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <Typography>{formatPower(original.power, { digits: 0 })}</Typography>
      ),
    },
    {
      Header: t('station-page.control-connectors-table.status.title'),
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <CentralizedRow children={<Tag type={original.status} />} />
      ),
    },
    {
      Header: t('station-page.control-connectors-table.observation.title'),
      accessor: 'errorCode',
      disableSortBy: true,
      Cell: ({ row: { original } }) =>
        original.errorCode
          ? t(Config.LABELS.ERROR_LABELS[original.errorCode])
          : '-',
    },
    {
      Header: () => null,
      accessor: 'station',
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <ManageConnectorDropdown station={station} connector={original} />
      ),
    },
  ]);

  const { data } = useRealtimeQuery<
    StationConnectorsQuery,
    StationConnectorsQueryVariables
  >(stationConnectors, {
    variables: { where: { stationId } },
  });

  const renderedConnectors: StationConnector[] =
    data?.station?.connectors ?? [];

  return (
    <>
      <Table<StationConnector> data={renderedConnectors} columns={columns} />
    </>
  );
};

export default ConnectorsTable;
