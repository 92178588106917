import { CreateVoucherEventsContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/events-context';
import { CreateVoucherStatesContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/state-context';
import { useContext, useEffect, useState } from 'react';

export const useCreateVoucherUsageLimits = () => {
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const { createVoucherData } = useContext(CreateVoucherStatesContext);

  const [invalidUsageLimit, setInvalidUsageLimit] = useState<boolean>(false);
  const [voucherLimits, setVoucherLimits] = useState<{
    isUnlimited: boolean;
    usageLimit: number | null;
  }>({
    isUnlimited: false,
    usageLimit: null,
  });

  const onChangeVoucherLimitsInput = (value: string) => {
    if (value) {
      if (parseInt(value) < 1) {
        setInvalidUsageLimit(true);
      } else {
        setInvalidUsageLimit(false);
      }
      setVoucherLimits({
        isUnlimited: false,
        usageLimit: parseInt(value),
      });
    } else {
      setVoucherLimits({
        isUnlimited: false,
        usageLimit: null,
      });
    }
  };

  const toggleVoucherIsUnlimited = () => {
    setVoucherLimits({
      usageLimit: null,
      isUnlimited: !createVoucherData.limits.isUnlimited,
    });
    setInvalidUsageLimit(false);
  };

  useEffect(() => {
    events.updateVoucherLimits(voucherLimits);
    // eslint-disable-next-line
  }, [voucherLimits]);

  return {
    invalidUsageLimit,
    voucherLimits,
    onChangeVoucherLimitsInput,
    toggleVoucherIsUnlimited,
  };
};
