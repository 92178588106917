import Config from 'config';
import React from 'react';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '../Navbar/styles';

export const Container = styled.div<{ wide: boolean }>`
  background: ${({ theme }) => theme.COLORS.SIDEBAR};
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: column nowrap;
  min-width: ${({ wide }) => (wide ? '215px' : '50px')};
  max-width: ${({ wide }) => (wide ? '215px' : '50px')};
  transition: 0.2s all ease-in-out;
  z-index: 1;
`;

export const LogoRow = styled.div<{ open: boolean }>`
  position: relative;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  height: ${NAVBAR_HEIGHT};
  padding: 0px 20px 0px 15px;
  ::after {
    content: '';
    position: absolute;
    right: 7px;
    height: 50px;
    width: 50px;
  }
  > * {
    :last-child {
      max-height: ${({ open }) => (open ? '15px' : 'none')};
    }
    :first-child {
      margin-left: 3.5px;
      margin-right: -5px;
    }
  }
`;

export const MenuIcon = styled(Config.ICONS.MENU)`
  flex: 0 0 auto;
  margin-left: 3px;
`;

// to avoid runtime warning
// https://github.com/styled-components/styled-components/issues/1198
export const LogoIcon = styled(() => <Config.ICONS.SIDEBAR.LOGO />)<{
  visible: boolean;
}>`
  height: 100px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: 0.2s opacity ease-in-out;
`;

export const ChevronIcon = styled(Config.ICONS.CHEVRON)`
  color: ${({ theme }) => theme.COLORS.WHITE};
  transform: rotate(-90deg);
  height: 20px;
`;
