import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import TERMS_OF_SERVICE from './TermsOfService';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const EDPConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-3',
  CODE: 'edp',
  LABEL: 'EDP',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#3EC09C',
    CLICKABLE: '#3A75F5',
    ERROR: '#F6334F',
    SIDEBAR: '#3EC09C',
    LOGIN_LOGO_BACKGROUND: '#3EC09C',

    RISE: '#3EC09C',
    FALL: '#F6334F',
    NEUTRAL: '#3A75F5',

    CRITICAL: '#F6334F',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#3EC09C',
    NoCost: '#F4F4F4',
    Error: '#F6334F',

    AVAILABLE: '#3EC09C',
    CHARGING: '#3A75F5',
    INOPERATIVE: '#F6334F',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F6334F',
    RESERVED: '#F6334F',

    PREPARING: '#3A75F5',
    FINISHING: '#3A75F5',

    SELECTED_DATE_BOUND_BACKGROUND: '#1FA580',
    SELECTED_DATE_BOUND_BORDER: '#30F3BE',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#067557',

    DATE_PRE_SELECTED_BACKGROUND: '#E8FFF9',
    DATE_PRE_SELECTED_BORDER: '#30F3BE',
    DATE_PRE_SELECTED_COLOR: '#1FA580',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#E8FFF9',

    DATE_SELECTED_BACKGROUND: '#47D8B0',
    DATE_SELECTED_BORDER: '#5BFFD2',
    DATE_SELECTED_HOVER_BACKGROUND: '#259B7A',

    CURRENT_DAY: '#259B7A',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#3EC09C',
      Subsidized: '#3EC09C',
      NoCost: '#F4F4F4',
      Error: '#F6334F',
      ErrorOnPreAuthorization: '#F6334F',
    },
    STATION_STATUS: {
      AVAILABLE: '#3EC09C',
      CHARGING: '#3A75F5',
      INOPERATIVE: '#F6334F',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F6334F',
      RESERVED: '#F6334F',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#3EC09C',
      PREPARING: '#3A75F5',
      CHARGING: '#3A75F5',
      FINISHING: '#3A75F5',
      INOPERATIVE: '#F6334F',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#F6334F',
    },

    BILLING: '#3EC09C',
    RECHARGES: '#3A75F5',
    AVAILABILITY: '#9351C2',

    BUTTON_AVAILABLE: '#3EC09C',
    BUTTON_UNAVAILABLE: '#939393',
    BUTTON_LOADING: '#3A75F5',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#3A75F5',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#1FA580',
      SELECTED_DATE_BOUND_BORDER: '#30F3BE',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#067557',

      DATE_PRE_SELECTED_BACKGROUND: '#E8FFF9',
      DATE_PRE_SELECTED_BORDER: '#30F3BE',
      DATE_PRE_SELECTED_COLOR: '#1FA580',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#E8FFF9',

      DATE_SELECTED_BACKGROUND: '#47D8B0',
      DATE_SELECTED_BORDER: '#5BFFD2',
      DATE_SELECTED_HOVER_BACKGROUND: '#259B7A',

      CURRENT_DAY: '#259B7A',
    },
  },
  MAP: {
    INITIAL_REGION: {
      lat: -23.3065861,
      lng: -46.6858452,
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular, Roboto',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE,
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'EDP Smart - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/edp',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#c4ece1',
      '100': '#9ee0cd',
      '200': '#8bd9c4',
      '300': '#77d3ba',
      '400': '#64cdb0',
      '500': '#3EC09C',
      '600': '#32997d',
      '700': '#2b866d',
      '800': '#25735d',
      '900': '#194c3e',
    },
    LINK: {
      '50': '#e4ecfe',
      '100': '#b3cafb',
      '200': '#9bb9fa',
      '300': '#83a8f9',
      '400': '#6b97f7',
      '500': '#3A75F5',
      '600': '#0c54f0',
      '700': '#0b4cd8',
      '800': '#0a43bf',
      '900': '#07328f',
    },
    ERROR: {
      '50': '#fedee2',
      '100': '#fbadb8',
      '200': '#fa95a3',
      '300': '#f97c8e',
      '400': '#f86479',
      '500': '#F6334F',
      '600': '#ec0a2b',
      '700': '#d30926',
      '800': '#bb0822',
      '900': '#8a0619',
    },
    SUCCESS: {
      '50': '#c4ece1',
      '100': '#9ee0cd',
      '200': '#8bd9c4',
      '300': '#77d3ba',
      '400': '#64cdb0',
      '500': '#3EC09C',
      '600': '#32997d',
      '700': '#2b866d',
      '800': '#25735d',
      '900': '#194c3e',
    },
    CHARGING: {
      '50': '#e4ecfe',
      '100': '#b3cafb',
      '200': '#9bb9fa',
      '300': '#83a8f9',
      '400': '#6b97f7',
      '500': '#3A75F5',
      '600': '#0c54f0',
      '700': '#0b4cd8',
      '800': '#0a43bf',
      '900': '#07328f',
    },
    AVAILABLE: {
      '50': '#c4ece1',
      '100': '#9ee0cd',
      '200': '#8bd9c4',
      '300': '#77d3ba',
      '400': '#64cdb0',
      '500': '#3EC09C',
      '600': '#32997d',
      '700': '#2b866d',
      '800': '#25735d',
      '900': '#194c3e',
    },
    SECONDARY: {
      '50': '#e4ecfe',
      '100': '#b3cafb',
      '200': '#9bb9fa',
      '300': '#83a8f9',
      '400': '#6b97f7',
      '500': '#3A75F5',
      '600': '#0c54f0',
      '700': '#0b4cd8',
      '800': '#0a43bf',
      '900': '#07328f',
    },
    RESERVE: {
      '50': '#e5d5f0',
      '100': '#ceafe3',
      '200': '#c29ddc',
      '300': '#b68ad6',
      '400': '#aa77cf',
      '500': '#9351C2',
      '600': '#793aa6',
      '700': '#6b3493',
      '800': '#5d2d80',
      '900': '#42205a',
    },
    INOPERATIVE: {
      '50': '#fedee2',
      '100': '#fbadb8',
      '200': '#fa95a3',
      '300': '#f97c8e',
      '400': '#f86479',
      '500': '#F6334F',
      '600': '#ec0a2b',
      '700': '#d30926',
      '800': '#bb0822',
      '900': '#8a0619',
    },
  },
};
export default EDPConfig;
