import ConnectorInfo from 'components/ConnectorsList/ConnectorInfo';
import Selector from 'components/Selector';
import Config from 'config';
import type {
  StationDetailsGraphQuery,
  StationDetailsGraphQueryVariables,
} from 'generated/graphql';
import stationDetailsGraph from 'graphql/queries/stationDetailsGraph';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TimeRange } from 'services/timeRange';
import CurrencyTypeIcon from 'atomic-components/atoms/CurrencyTypeIcon';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import Card from '../../../../components/Card';
import PerDateTimeGraph from './PerDateTimeGraph';
import {
  GraphDescription,
  GraphTitle,
  Row,
  SelectorContainer,
  ShowStationOrConnectorGraph,
} from './styles';

interface Props {
  stationId?: string;
  timeRange: TimeRange;
}

/*
  Defining both consts was decided because Object.keys loses specific
  keys - becoming string[] - so type casts would be necessary using this approach.
  This is also why Object.values with index is being used instead of Object.entries,
  as it suffers from the same problem on the key.
*/
const GRAPH_TYPES = ['recharge', 'usageRate', 'revenue'] as const;
const GRAPH_SETTINGS = {
  recharge: {
    label: 'station-page.station-and-connectors-graphs.recharge.title',
    color: 'RECHARGES',
    Icon: Config.ICONS.RECHARGE_SMALL,
  },
  usageRate: {
    label: 'station-page.station-and-connectors-graphs.usage-rate.title',
    color: 'AVAILABILITY',
    Icon: Config.ICONS.USE_RATE_SMALL,
  },
  revenue: {
    label: 'station-page.station-and-connectors-graphs.revenue.title',
    color: 'BILLING',
    Icon: CurrencyTypeIcon,
  },
} as const;

// first time accessing Dashboard(will cache more times)
const now = moment();

const StationAndConnectorsGraphs: React.FC<Props> = ({
  stationId,
  timeRange,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [selectedGraphType, selectGraphType] = useState<
    typeof GRAPH_TYPES[number]
  >(GRAPH_TYPES[0]);
  const [currentGraph, displayGraph] = useState<'station' | 'connectors'>(
    'station'
  );

  const { data, error, loading } = useRealtimeQuery<
    StationDetailsGraphQuery,
    StationDetailsGraphQueryVariables
  >(stationDetailsGraph, {
    variables: {
      where: { stationId },
      input: timeRange.toPerDateTimeInput(
        timeRange.convertDateTimeByCurrentResolution(now)
      ),
    },
    skip: !stationId || !hasScopes(['session:read']),
    pollInterval: 1000 * 10,
  });

  const defaultCurrencyTypes = useDefaultCurrencyTypes();
  const currencyType = getOrganizationDefaultCurrencyType(
    defaultCurrencyTypes,
    data?.station?.cpoId
  );

  return (
    <Card
      maxHeight={843}
      type="normal"
      loading={loading}
      error={error && !data}
    >
      <>
        {currentGraph === 'connectors' ? (
          <>
            <SelectorContainer>
              <Selector
                options={GRAPH_TYPES}
                format={(type) => t(GRAPH_SETTINGS[type].label)}
                value={selectedGraphType}
                onChange={selectGraphType}
              />
            </SelectorContainer>
            {data?.station?.connectors.map((connector, _, array) => (
              <Row
                showing="connectors"
                single={data?.station?.connectors.length === 1}
                key={connector.id}
              >
                <GraphTitle
                  showing="connectors"
                  backgroundColor={
                    Config.COLORS.CONNECTOR_STATUS[connector.status]
                  }
                >
                  {t(Config.LABELS.CONNECTOR_STATUS[connector.status])}
                </GraphTitle>
                <GraphDescription horizontal={array.length === 1}>
                  {/* @ts-ignore FIXME: */}
                  <ConnectorInfo
                    connector={connector}
                    noContainer
                    stationId={data?.station?.id}
                    connectorId={connector.id}
                  />
                </GraphDescription>
                <PerDateTimeGraph
                  // I used singular which breaks the pattern but is more semantic
                  showing="connector"
                  height={array.length === 1 ? 80 : 100}
                  width={array.length === 1 ? 100 : 65}
                  timeRange={timeRange}
                  data={{
                    value: connector,
                    option: selectedGraphType,
                  }}
                />
              </Row>
            ))}
          </>
        ) : (
          Object.values(GRAPH_SETTINGS).map(({ label, color, Icon }, index) => (
            <Row showing="station" key={index}>
              <GraphTitle
                showing="station"
                backgroundColor={Config.COLORS[color]}
              >
                {GRAPH_TYPES[index] === 'revenue' ? (
                  <Icon currencyType={currencyType} color="WHITE" />
                ) : (
                  <Icon />
                )}
                {t(label)}
              </GraphTitle>
              {data?.station && (
                <PerDateTimeGraph
                  showing="station"
                  height={100}
                  width={100}
                  timeRange={timeRange}
                  data={{
                    value: data.station,
                    option: GRAPH_TYPES[index], // see the other comment
                  }}
                  unitKey={
                    GRAPH_TYPES[index] === 'revenue' ? currencyType : undefined
                  }
                />
              )}
            </Row>
          ))
        )}
        {hasScopes(['session:read']) && (
          <ShowStationOrConnectorGraph
            color="LINK"
            onClick={() =>
              displayGraph((prev) =>
                prev === 'station' ? 'connectors' : 'station'
              )
            }
          >
            {currentGraph === 'station'
              ? t('station-page.station-analytics.graph-by-connectors.title')
              : t('station-page.station-analytics.graph-by-station.title')}
          </ShowStationOrConnectorGraph>
        )}
      </>
    </Card>
  );
};

export default StationAndConnectorsGraphs;
