import { gql } from '@apollo/client';

export default gql`
  query DriverModalHeader($where: DriverWhereUniqueInput!) {
    driver(where: $where) {
      id
      profile {
        id
        firstName
        lastName
        email
      }
      emspId
    }
  }
`;
