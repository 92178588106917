import { Typography } from 'components';
import Card from 'components/Card';
import Page from 'components/Page';
import Table, { Column } from 'components/Table';
import {
  GroupStationsQuery,
  GroupStationsQueryVariables,
} from 'generated/graphql';
import { useAuthQuery } from 'hooks';
import React, { useState } from 'react';
import UpdateGroupStationsModal from 'atomic-components/organisms/modals/UpdateGroupStations';
import groupStationsQuery from 'graphql/queries/groupStations';
import useHasScopes from 'hooks/useHasScope';
import { Button } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';
import { TableHeader, OrderTableContainer } from '../Users/styles';
import { formatStationNameToCheckbox } from '../functions/function';

type Props = NonNullable<
  GroupStationsQuery['group']['stationsProfile'][number]
>;

type Group = {
  groupId: string;
  updateGroupSummary: () => void;
};

type Options = {
  id: string;
  name: string;
};

const Stations = ({ groupId, updateGroupSummary }: Group) => {
  const { t } = useTranslation();
  const columns: Column<Props>[] = [
    {
      Header: t('access-groups-modal.station-tab.title').toUpperCase(),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { name },
        },
      }) => <Typography>{name ? `${name} ` : '-'}</Typography>,
    },
  ];

  const [selectedValues, setSelectedValues] = useState<Options[]>([]);
  const [addStationsVisible, setAddStationsVisible] = useState(false);

  const hasScopes = useHasScopes();

  const { data, error, loading, refetch } = useAuthQuery<
    GroupStationsQuery,
    GroupStationsQueryVariables
  >(groupStationsQuery, {
    variables: {
      where: {
        id: groupId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleOpenModal = async () => {
    const selectedValuesFormated = formatStationNameToCheckbox(
      data?.group.stationsProfile || []
    );
    setSelectedValues(selectedValuesFormated);
    setAddStationsVisible(true);
  };

  return (
    <Page>
      <UpdateGroupStationsModal
        groupId={groupId}
        onClose={() => setAddStationsVisible(false)}
        show={addStationsVisible}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        updateListWhenUpdated={refetch}
        updateGroupSummary={updateGroupSummary}
      />
      <OrderTableContainer>
        <Card
          minHeight={841}
          padded={300}
          error={error}
          loading={loading}
          type="complex"
          maxHeight={841}
          noCardPadding={true}
        >
          <TableHeader>
            <Typography color="PRIMARY_GRAY" weight="light">
              {t('access-groups-modal.stations-tab.card.title')}
            </Typography>

            {hasScopes(['group:edit']) && (
              <>
                <Button
                  onClick={handleOpenModal}
                  isLoading={loading}
                  leftIcon={<Icon type="NEW_EDIT" size={16} color="WHITE" />}
                  size="sm"
                >
                  {t('access-groups-modal.stations-tab.card.btn.title')}
                </Button>
              </>
            )}
          </TableHeader>
          <Table<Props>
            expandable={false}
            paginated={false}
            isFullWidth={true}
            columns={columns}
            loading={loading}
            data={data?.group.stationsProfile || []}
          />
        </Card>
      </OrderTableContainer>
    </Page>
  );
};

export default Stations;
