import { gql } from '@apollo/client';

export default gql`
  query ExportAllSessions(
    $timeRange: TimeRangeInput
    $filter: SessionFilterInput
  ) {
    exportSessions(
      sort: { createdAt: DESC }
      timeRange: $timeRange
      filter: $filter
    ) {
      url
    }
  }
`;
