import Typography from 'components/Typography';
import Config from 'config';
import styled, { css } from 'styled-components';

type ButtonProps = {
  backgroundColor: string;
  visual: 'primary' | 'secondary';
  disabled: boolean;
  grayBorder: boolean;
};

export const ButtonContainer = styled.button<ButtonProps>`
  ${({ grayBorder, backgroundColor, theme, visual, disabled }) => css`
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    background-color: ${backgroundColor};
    border-radius: 8px;
    justify-content: center;
    border: ${grayBorder ? '2px' : '0px'} solid ${theme.COLORS.TERTIARY_GRAY};
    margin: 0px;
    width: 100%;
    height: 100%;
    ${!disabled &&
    css`
      :hover {
        cursor: pointer;
        ${visual === 'primary' &&
        css`
          margin: -5px;
          border-width: 5px;
          height: calc(100% + 10px);
          width: calc(100% + 10px);
          border-radius: 12px;
          background-clip: padding-box;
        `}
        ${visual === 'secondary' &&
        `background-color: ${theme.COLORS.TERTIARY_GRAY}`};
      }
      :focus {
        outline: none;
        border-color: ${Config.COLORS.TERTIARY_GRAY};
      }
      :active {
        filter: brightness(0.8);
      }
    `}
    ${Typography} {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  `}
`;

export const FeedbackContainer = styled.div`
  flex-direction: row;
  width: 130;
  align-items: center;
  justify-content: space-between;
`;
