import AuthContext from 'contexts/Auth/context';
import {
  ExportConsumersQuery,
  ExportConsumersQueryVariables,
} from 'generated/graphql';
import exportConsumers from 'graphql/queries/exportConsumers';
import { useContext } from 'react';
import { useClient } from 'urql';

export const useExportClients = () => {
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  const client = useClient();
  return async () => {
    const { data, error } = await client
      .query<ExportConsumersQuery, ExportConsumersQueryVariables>(
        exportConsumers,
        {},
        {
          fetchOptions: {
            headers: {
              authorization: `Bearer ${memberAuthToken}`,
              'org-ids': selectedOrganizationIds as any,
            },
          },
          requestPolicy: 'network-only',
        }
      )
      .toPromise();

    if (error) {
      alert('Erro ao baixar dados');
    }

    const url = data?.exportConsumers.url;
    if (!url) return;

    saveAs(url, `dados-clientes-voltbras.xlsx`);
  };
};
