import Config from 'config';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: ${Config.COLORS.BACKGROUND};
  padding: 30px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const InnerContainer = styled.div`
  max-width: 1700px;
  position: relative;
  height: 100%;
  margin: auto;
`;
