import { Cell, TableContainer } from 'components/Table/styles';
import { DropdownContainer } from 'new-components/NewDropdown/styles';
import styled from 'styled-components';

type StationTableContainerProps = {
  hasFooter: boolean;
};

export const NewDropdownContainer = styled.div`
  position: relative;
  float: right;
`;

export const StationTableContainer = styled.div<StationTableContainerProps>`
  margin-bottom: 47px;
  ${Cell}:last-child, ${TableContainer} {
    overflow: visible;
  }

  ${DropdownContainer} {
    top: 1px;
    right: 37px;
  }
`;

export const AddStationContainer = styled.div`
  position: absolute;
  right: -6px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterButtonContainer = styled.div`
  margin-right: 12px;
`;

// TODO: Nelson fix
export const DetailsButton = styled.button`
  width: 107px;
  cursor: pointer;
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
  font-family: ${({ theme }) => theme.FONTS.PRIMARY};
  font-size: 14px;
  height: 31px;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.COLORS.TERTIARY_GRAY}`};
  background-color: ${({ theme }) => theme.COLORS.WHITE};
`;
