import { InjectChildrenProps } from '.';
import { Row } from './styles';

type ExpandableRowProps = {
  expandable?: boolean;
  isExpanded: boolean;
  expandRow: (isExpanded: boolean) => void;
  content: JSX.Element | JSX.Element[];
} & InjectChildrenProps;

const DropdownExpandableRow = ({
  expandable = true,
  isExpanded,
  expandRow,
  content,
  children,
}: ExpandableRowProps) => (
  <>
    <Row
      active={expandable}
      onClick={expandable ? () => expandRow(!isExpanded) : () => {}}
    >
      {children}
    </Row>
    {isExpanded && content}
  </>
);

export { DropdownExpandableRow };
