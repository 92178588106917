import {
  Box,
  BoxProps,
  Heading,
  IconButton,
  Flex,
  Tag,
  Tooltip,
  Stack,
  Text,
} from '@chakra-ui/react';
import AuthContext, { Organization } from 'contexts/Auth/context';
import { Icon } from 'new-components/Icon';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getTagText,
  saveCurrentPreSelectedOrgIdsOnCookie,
  WHITELABELS,
} from '../functions';
import { ItemAnimation, MotionBox } from '../styles';
import { DinamicVerticalLine } from './dinamicVerticalLine';
import { OrganizationCheckbox } from './organizationCheckbox';
import { OrganizationIdsType } from './organizationsSection';

type Props = {
  preSelectedOrganizationIds: OrganizationIdsType;
  organization: Organization;
  isExpanded: boolean;
  paddingLeft?: number;
  onClickToPreSelectOrganization: () => void;
  onClickToExpandSuborganizations: () => void;
  onChangeAllPreSelectAllSuborganizations: () => void;
  tagInfo?: {
    totalNumberOfOrganizations: number;
    numberOfOrganizationsSelected: number;
  };
  isFirstLevel?: boolean;
  hasSuborganization: boolean;
  hasAllSuborganizationSelected?: boolean;
} & BoxProps;

export const OrganizationDropdownRow = ({
  preSelectedOrganizationIds,
  organization,
  isExpanded,
  onClickToPreSelectOrganization,
  onClickToExpandSuborganizations,
  onChangeAllPreSelectAllSuborganizations,
  tagInfo,
  paddingLeft = 0,
  isFirstLevel,
  hasSuborganization,
  hasAllSuborganizationSelected = false,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { switchOrganizationDashboardUrl } = useContext(AuthContext);

  const handleSaveChangesToSwitchDashboardUrl = (
    orgId: string,
    dashboardUrl: string
  ) => {
    saveCurrentPreSelectedOrgIdsOnCookie(preSelectedOrganizationIds);
    switchOrganizationDashboardUrl(orgId, dashboardUrl);
  };
  const isTagVisible =
    tagInfo?.totalNumberOfOrganizations &&
    tagInfo?.totalNumberOfOrganizations > 1 &&
    !!tagInfo?.numberOfOrganizationsSelected;

  const labelAllSelected = hasAllSuborganizationSelected
    ? 'profile-dropdown.organizations-section.remove-all-suborganizations-label'
    : 'profile-dropdown.organizations-section.select-all-suborganizations-label';

  const iconChangeAllSelected = hasAllSuborganizationSelected
    ? 'NEW_DOUBLE_REMOVE_CHECK'
    : 'NEW_DOUBLE_CHECK';

  return (
    <MotionBox data-group variants={ItemAnimation}>
      <Box
        cursor="pointer"
        position="relative"
        bg="transparent"
        width="full"
        data-test="orgsBox"
        px={1.5}
        py={isFirstLevel ? 1.5 : 1}
        _hover={{
          bg: hasSuborganization ? 'gray.50' : 'transparent',
        }}
        pointerEvents={hasSuborganization ? 'auto' : 'none'}
        onClick={(event) => {
          event.stopPropagation();
          onClickToExpandSuborganizations();
        }}
        {...rest}
      >
        {!isFirstLevel && <DinamicVerticalLine left={`${paddingLeft + 2}px`} />}

        <Flex alignItems="center" pl={`${paddingLeft}px`}>
          <OrganizationCheckbox
            preSelectedOrganizationIds={preSelectedOrganizationIds}
            onClick={() => {
              onClickToPreSelectOrganization();
            }}
            organizationId={organization.id}
          >
            {!isFirstLevel ? (
              <Text
                maxWidth={isTagVisible ? '120px' : '170px'}
                noOfLines={1}
                fontSize="sm"
              >
                {organization.name}
              </Text>
            ) : (
              <Heading
                maxWidth={isTagVisible ? '120px' : '170px'}
                noOfLines={1}
                fontSize="sm"
                data-test="FirstOrgSelect"
              >
                {organization.name}
              </Heading>
            )}
          </OrganizationCheckbox>
          {!!isTagVisible && (
            <Flex align="center" justify="center" mr={2}>
              <Tooltip
                label={t(
                  'profile-dropdown.organizations-section.selected-organization-tooltip-label'
                )}
                bg="gray.900"
                hasArrow
              >
                <Tag
                  variant="outline"
                  colorScheme="gray"
                  size="sm"
                  height={3.5}
                  minH={3.5}
                  fontSize="x-small"
                  py={2}
                >
                  {getTagText(
                    tagInfo?.numberOfOrganizationsSelected ?? 0,
                    tagInfo?.totalNumberOfOrganizations ?? 0
                  )}
                </Tag>
              </Tooltip>
            </Flex>
          )}
          <Flex ml="auto" pointerEvents="visible">
            <Stack direction="row">
              {hasSuborganization && (
                <Box
                  visibility="hidden"
                  _groupHover={{
                    visibility: 'visible',
                  }}
                >
                  <Tooltip label={t(labelAllSelected)} bg="gray.900" hasArrow>
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label={t(
                        'profile-dropdown.organizations-section.select-all-suborganizations-label'
                      )}
                      size="xs"
                      data-test="selectAllOrgsButton"
                      onClick={(event) => {
                        event.stopPropagation();
                        onChangeAllPreSelectAllSuborganizations();
                      }}
                      icon={
                        <Icon
                          size={16}
                          color="SECONDARY_GRAY"
                          type={iconChangeAllSelected}
                        />
                      }
                    />
                  </Tooltip>
                </Box>
              )}
              {WHITELABELS.includes(organization.id) && (
                <Box
                  visibility="hidden"
                  _groupHover={{
                    visibility: 'visible',
                  }}
                >
                  <Tooltip
                    label={t(
                      'profile-dropdown.organizations-section.open-organization-dashboard-label'
                    )}
                    bg="gray.900"
                    hasArrow
                  >
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label={t(
                        'profile-dropdown.organizations-section.open-organization-dashboard-label'
                      )}
                      size="xs"
                      onClick={() =>
                        handleSaveChangesToSwitchDashboardUrl(
                          organization.id,
                          organization.dashboardUrl
                        )
                      }
                      icon={
                        <Icon
                          size={16}
                          color="SECONDARY_GRAY"
                          type="NEW_LAUNCH"
                        />
                      }
                    />
                  </Tooltip>
                </Box>
              )}
              {hasSuborganization && (
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  size="xs"
                  aria-label={t(
                    'profile-dropdown.organizations-section.expand-suborganizations-label'
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClickToExpandSuborganizations();
                  }}
                  icon={
                    <Icon
                      size={16}
                      color="SECONDARY_GRAY"
                      type={
                        isExpanded ? 'NEW_CHEVRON_DOWN' : 'NEW_CHEVRON_RIGHT'
                      }
                    />
                  }
                />
              )}
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </MotionBox>
  );
};
