import Config from 'config/index';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  images: string[];
  imageSize?: { height?: string; width?: string };
  config?: {
    showArrows?: boolean;
    swipeable?: boolean;
    showThumbs?: boolean;
    showStatus?: boolean;
  };
};

const ImagesCarousel: React.FC<Props> = ({ images, imageSize, config }) => {
  const {
    showArrows = true,
    swipeable = true,
    showStatus = false,
    showThumbs = false,
  } = config || {};
  const { height = '200px', width = '100%' } = imageSize || {};

  const renderImages = () => {
    if (images.length === 0)
      return (
        <img
          style={{
            objectFit: 'contain',
            background: Config.COLORS.TERTIARY_GRAY,
          }}
          alt="Eletroposto"
          src={Config.IMAGES.STATION_FALLBACK}
          height={height}
          width={width}
        />
      );

    return (
      <Carousel
        showArrows={showArrows}
        swipeable={swipeable}
        showStatus={showStatus}
        showThumbs={showThumbs}
      >
        {images.map((image: string, index: number) => (
          <img
            style={{ objectFit: 'contain' }}
            key={index}
            alt="Eletroposto"
            src={image}
            height={height}
            width={width}
          />
        ))}
      </Carousel>
    );
  };

  return <>{renderImages()}</>;
};

export default ImagesCarousel;
