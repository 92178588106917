import { gql } from '@apollo/client';

export default gql`
  query StationOwnerEditDetails($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      chargePointId
      name
      visibility
      coordinates {
        latitude
        longitude
      }
      address {
        street
        streetNumber
        city
        state
        neighborhood
        postalCode
        country
      }
      configPortal {
        url
        user
        password
      }
      routerPortal {
        url
        user
        password
      }
      ocppVersion
      ocppUrl
      ocppPublicAccessAllowed
      unlockMethods
      connectors {
        id
        evseId
        type
        maxPower
        maxAmperage
        maxVoltage
      }
    }
  }
`;
