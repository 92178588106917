import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const ElectroBoConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-10',
  CODE: 'ende-guaracachi',
  LABEL: 'ElectroBo',
  LANGUAGE: 'es_ES',
  COUNTRY: 'BO',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#001C66',
    CLICKABLE: '#021D64',
    ERROR: '#F87060',
    SIDEBAR: '#001C66',
    LOGIN_LOGO_BACKGROUND: '#001C66',
    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#021D64',
    CRITICAL: '#F87060',
    WARNING: '#E6B606',
    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',
    AVAILABLE: '#04E762',
    CHARGING: '#00C1FD',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91',
    PREPARING: '#00C1FD',
    FINISHING: '#00C1FD',
    SELECTED_DATE_BOUND_BACKGROUND: '#021D64',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#01164B',
    DATE_PRE_SELECTED_BACKGROUND: '#001C66',
    DATE_PRE_SELECTED_BORDER: '#0132B1',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#0138CB',
    DATE_PRE_SELECTED_COLOR: '#01164B',
    DATE_SELECTED_BACKGROUND: '#001C66',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#021D64',
    CURRENT_DAY: '#001C66',
    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#662C91',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#00C1FD',
      CHARGING: '#00C1FD',
      FINISHING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },
    BILLING: '#04E762',
    RECHARGES: '#00C1FD',
    AVAILABILITY: '#662C91',
    BUTTON_AVAILABLE: '#001C66',
    BUTTON_UNAVAILABLE: '#0138CB',
    BUTTON_LOADING: '#0132B1',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#001C66',
    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#021D64',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#01164B',
      DATE_PRE_SELECTED_BACKGROUND: '#0001C66',
      DATE_PRE_SELECTED_BORDER: '#0132B1',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#0138CB',
      DATE_PRE_SELECTED_COLOR: '#01164B',
      DATE_SELECTED_BACKGROUND: '#001C66',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#021D64',
      CURRENT_DAY: '#001C66',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -17.779564,
      lng: -63.1563474,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'ElectroBo - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/electrobo',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#013FE5',
      '100': '#0138CB',
      '200': '#0132B1',
      '300': '#022B97',
      '400': '#02247E',
      '500': '#001C66',
      '600': '#021D64',
      '700': '#01164B',
      '800': '#010E32',
      '900': '#010719',
    },
    LINK: {
      '50': '#013FE5',
      '100': '#0138CB',
      '200': '#0132B1',
      '300': '#022B97',
      '400': '#02247E',
      '500': '#001C66',
      '600': '#021D64',
      '700': '#01164B',
      '800': '#010E32',
      '900': '#010719',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#00C1FD',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#013FE5',
      '100': '#0138CB',
      '200': '#0132B1',
      '300': '#022B97',
      '400': '#02247E',
      '500': '#001C66',
      '600': '#021D64',
      '700': '#01164B',
      '800': '#010E32',
      '900': '#010719',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#662C91',
      '600': '#4a206a',
      '700': '#3d1a56',
      '800': '#2f1443',
      '900': '#13081c',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default ElectroBoConfig;
