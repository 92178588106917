import { Typography } from 'components';
import styled from 'styled-components';

export const Container = styled.div<{ top: number }>`
  width: 100%;
  ${({ top }) => `margin-top: ${top}px`};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 35px;
  padding-right: 35px;
  text-align: center;
`;

export const Title = styled(Typography).attrs({
  size: 16,
  weight: 'bold',
  color: 'SECONDARY_GRAY',
})`
  margin-top: 11px;
  margin-bottom: 8px;
`;

export const Description = styled(Typography).attrs({
  size: 14,
  color: 'SECONDARY_GRAY',
})``;
