import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import { CreateVoucherStatesContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/state-context';
import { FromDateUntilDate } from 'services/timeRange';
import Calendar from './Calendar';
import { useCreateVoucherRulesValidityPeriod } from '../services';

const PersonalizedPeriod = () => {
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);
  const { onPersonalizedDateSelection } = useCreateVoucherRulesValidityPeriod();

  const {
    createVoucherData: { validityPeriod },
  } = useContext(CreateVoucherStatesContext);

  const fromDateUntilDateObject = new FromDateUntilDate(
    '',
    '',
    'DD/MM/YYYY',
    validityPeriod.from,
    validityPeriod.to,
    (fromLabel: string, toLabel: string) =>
      t(
        'payments.voucher-tab.create-voucher.rules.validity-period.date-label-format',
        { fromLabel, toLabel }
      )
  );

  fromDateUntilDateObject.apply(validityPeriod.from, validityPeriod.to);

  const outsideBoxRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (!outsideBoxRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <Flex mt="0.25rem" ref={outsideBoxRef} gap="1rem" flexDirection="column">
      <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
        {t(
          'payments.voucher-tab.create-voucher.rules.validity-period.subtitle'
        )}
      </Text>
      <Flex
        flexDirection="row"
        bottom="0px"
        alignItems="end"
        gap="0.625rem"
        maxHeight="2.625rem"
        maxWidth="12.25rem"
      >
        <Button
          bgColor="white"
          color="black"
          border="1px"
          fontWeight="normal"
          isDisabled={validityPeriod.isUnexpirable}
          borderRadius="0.5rem"
          minWidth="12.25rem"
          w="12.25rem"
          h="2.625rem"
          fontSize="sm"
          textAlign="center"
          lineHeight="2.625rem"
          mt="1rem"
          _hover={{ bg: 'gray.100' }}
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {validityPeriod.from === null || validityPeriod.to === null
            ? t(
                'payments.voucher-tab.create-voucher.rules.validity-period.selection-box-placeholder'
              )
            : fromDateUntilDateObject.shortLabel}
        </Button>
        {showCalendar && (
          <Box zIndex="9999">
            <Calendar
              defaultValue={fromDateUntilDateObject}
              isVisible={showCalendar}
              closeCalendar={() => setShowCalendar(false)}
              onSelectDate={onPersonalizedDateSelection}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default PersonalizedPeriod;
