import styled, { createGlobalStyle } from 'styled-components';
import Config from 'config';

import RawlineLight from './assets/fonts/Rawline-Light.ttf';

import RobotoBlack from './assets/fonts/Roboto-Black.ttf';
import RobotoBold from './assets/fonts/Roboto-Bold.ttf';
import RobotoMedium from './assets/fonts/Roboto-Medium.ttf';
import RobotoMono from './assets/fonts/Roboto-Mono.ttf';
import RobotoRegular from './assets/fonts/Roboto-Regular.ttf';

import GothamLight from './assets/fonts/Gotham-Light.otf';
import GothamBlack from './assets/fonts/Gotham-Black.otf';
import GothamBold from './assets/fonts/Gotham-Bold.otf';
import GothamMedium from './assets/fonts/Gotham-Medium.otf';
import GothamItalic from './assets/fonts/Gotham-Italic.otf';
import GothamRegular from './assets/fonts/Gotham-Regular.ttf';

import MontRegular from './assets/fonts/Mont-Regular.ttf';
import MontBold from './assets/fonts/Mont-Bold.ttf';

import CamptonBold from './assets/fonts/Campton-Bold.otf';

import SoraBold from './assets/fonts/Sora-Bold.ttf';
import SoraLight from './assets/fonts/Sora-Light.ttf';
import SoraRegular from './assets/fonts/Sora-Regular.ttf';
import SoraMedium from './assets/fonts/Sora-Medium.ttf';

import UrbaneBold from './assets/fonts/Urbane-Bold.ttf';
import UrbaneLight from './assets/fonts/Urbane-Light.ttf';
import UrbaneMedium from './assets/fonts/Urbane-Medium.ttf';

import VerdanaBold from './assets/fonts/Verdana-Bold.ttf';
import VerdanaRegular from './assets/fonts/Verdana-Regular.ttf';

import MontserratBold from './assets/fonts/Montserrat-Bold.ttf';
import MontserratRegular from './assets/fonts/Montserrat-Regular.ttf';
import MontserratLight from './assets/fonts/Montserrat-Light.ttf';

import AeonikBold from './assets/fonts/Aeonik-Bold.ttf';
import AeonikRegular from './assets/fonts/Aeonik-Regular.ttf';
import AeonikLight from './assets/fonts/Aeonik-Light.ttf';

import InterRegular from './assets/fonts/Inter-Regular.ttf';

export default createGlobalStyle`
* {
  font-family: -apple-system, BlinkMacSystemFont, ${
    Config.FONTS.PRIMARY
  }, "Helvetica Neue", sans-serif;
  scrollbar-color: #aaa #f4f4f4;
  scrollbar-width: thick;
}

#root, body, html {
  width: 100%;
  height: 100%;
}

.slide.selected {
  background: #DBDBDB;
}

body {
  margin: 0;
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* Scrollbar */
/* ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: blue;
  border-radius: 6px;
  position: absolute;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background-color: #cdd7d6;
  border-radius: 6px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f; 
} */

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"), url(${RobotoRegular}) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"), url(${RobotoRegular}) format("truetype");
}
@font-face {
  font-family: "Roboto-Mono";
  src: local("Roboto-Mono"), url(${RobotoMono}) format("truetype");
}
@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"), url(${RobotoBlack}) format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"), url(${RobotoBold}) format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"), url(${RobotoMedium}) format("truetype");
}
@font-face {
  font-family: "Rawline-Light";
  src: local("Rawline-Light"), url(${RawlineLight}) format("truetype");
}


// Mont
@font-face {
  font-family: "Mont-Regular";
  src: local("Mont-Regular"), url(${MontRegular}) format("truetype");
}

@font-face {
  font-family: "Mont-Bold";
  src: local("Mont-Bold"), url(${MontBold}) format("truetype");
}


// Gotham
@font-face {

  font-family: "Gotham-Regular";
  src: local("Gotham-Regular"), url(${GothamRegular}) format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham-Regular"), url(${GothamRegular}) format("truetype");
}
@font-face {
  font-family: "Gotham-Italic";
  src: local("Gotham-Italic"), url(${GothamItalic}) format("truetype");
}
@font-face {
  font-family: "Gotham-Black";
  src: local("Gotham-Black"), url(${GothamBlack}) format("truetype");
}
@font-face {
  font-family: "Gotham-Bold";
  src: local("Gotham-Bold"), url(${GothamBold}) format("truetype");
}
@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"), url(${GothamMedium}) format("truetype");
}
@font-face {
  font-family: "Gotham-Light";
  src: local("Gotham-Light"), url(${GothamLight}) format("truetype");
}

//Sora
@font-face {
  font-family: "Sora-Bold";
  src: local("Sora-Bold"), url(${SoraBold}) format("truetype");
}
@font-face {
  font-family: "Sora-Regular";
  src: local("Sora-Regular"), url(${SoraRegular}) format("truetype");
}
@font-face {
  font-family: "Sora-Light";
  src: local("Sora-Light"), url(${SoraLight}) format("truetype");
}
@font-face {
  font-family: "Sora-Medium";
  src: local("Sora-Medium"), url(${SoraMedium}) format("truetype");
}

// Campton
@font-face {
  font-family: "Campton-Bold";
  src: local("Campton-Bold"), url(${CamptonBold}) format("opentype");
}

// Urbane
@font-face {
  font-family: "Urbane-Bold";
  src: local("Urbane-Bold"), url(${UrbaneBold}) format("truetype");
}
@font-face {
  font-family: "Urbane-Medium";
  src: local("Urbane-Medium"), url(${UrbaneMedium}) format("truetype");
}
@font-face {
  font-family: "Urbane-Light";
  src: local("Urbane-Light"), url(${UrbaneLight}) format("truetype");
}

// Verdana
@font-face {
  font-family: "Verdana-Bold";
  src: local("Verdana-Bold"), url(${VerdanaBold}) format("truetype");
}
@font-face {
  font-family: "Verdana-Regular";
  src: local("Verdana-Regular"), url(${VerdanaRegular}) format("truetype");
}


// Montserrat
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"), url(${MontserratBold}) format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"), url(${MontserratRegular}) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"), url(${MontserratLight}) format("truetype");
}

// Aeonik
@font-face {
  font-family: "Aeonik-Bold";
  src: local("Aeonik-Bold"), url(${AeonikBold}) format("truetype");
}
@font-face {
  font-family: "Aeonik-Regular";
  src: local("Aeonik-Regular"), url(${AeonikRegular}) format("truetype");
}

@font-face {
  font-family: "Aeonik-Light";
  src: local("Aeonik-Light"), url(${AeonikLight}) format("truetype");
}

//Inter
@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"), url(${InterRegular}) format("truetype");
}

.menu-item {

  &__label {
    vertical-align: middle;
    text-align: left;
    font-size: 14px;
    word-wrap: normal;
    transition: color 0.3s ease-out;
    letter-spacing: 0;
    color: #FFF0; 
  }

  &__open {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: 14px;
    transition: color 0.3s ease-out;
  }
}

.input-toggler:checked ~ .app-sidebar {
  .menu-item {
    &__label {
      visibility: initial;
      color: ${({ theme }) => theme.COLORS.WHITE};
      width: calc(100% + 25px);
    }
  }
}
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  height: 100%;
`;
