import styled from 'styled-components';

export const Container = styled.div<{
  visual: 'primary' | 'secondary';
  active: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 31px;
  height: 31px;
  box-sizing: border-box;

  cursor: pointer;
  border: 2px solid
    ${({ theme, visual, active }) =>
      visual === 'primary' && !active
        ? theme.COLORS.TERTIARY_GRAY
        : theme.COLORS.CLICKABLE};
  border-radius: 8px;
  background-color: ${({ theme, visual }) =>
    visual === 'primary' ? theme.COLORS.WHITE : theme.COLORS.CLICKABLE};
  color: ${({ theme, visual }) =>
    visual === 'primary' ? theme.COLORS.PRIMARY_GRAY : theme.COLORS.WHITE};

  &:active {
    border: 1px solid ${({ theme }) => theme.COLORS.CLICKABLE};
  }
`;
