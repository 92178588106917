import { gql } from '@apollo/client';

export default gql`
  query AllStationsProfileVoucher(
    $limit: Int
    $offset: Int
    $filter: StationProfileFilterInput
    $search: String
    $sort: StationProfileSortInput
  ) {
    stationsProfile(
      limit: $limit
      offset: $offset
      filter: $filter
      search: $search
      sort: $sort
    ) {
      id
      name
      cpoId
      emsps {
        id
      }
      address {
        city
      }
    }
    stationsProfileSummary(filter: $filter) {
      count
    }
  }
`;
