export const parseSearchValue = (
  value: string,
  matcherUserId: RegExp,
  matcherVehicleId: RegExp
) => {
  const userIdMatch = value.match(matcherUserId)?.[1];
  const vehicleIdMatch = value.match(matcherVehicleId)?.[1];

  if (userIdMatch) return userIdMatch;
  if (vehicleIdMatch) return vehicleIdMatch;
  return value;
};

export const userIdMatcher = /[U,u]ser ([0-9]+)/;

export const vehicleIdMatcher = /[V,v][E,e] ([0-9]+)/;
