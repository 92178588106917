export const NON_ALPHANUMERIC_CHARACTERS = '!@#$%&*()/|\\?{}[]=-';
export const PASSWORD_MINIMUM_LENGTH = 8;

export type PasswordPolicyError =
  | 'SUCCESS'
  | 'TOO_SHORT'
  | 'NO_UPPERCASE'
  | 'NO_NUMBER'
  | 'NO_SPECIAL_CHARACTER'
  | 'REPEATED_PASSWORD'
  | 'NO_LOWERCASE'
  | 'NO_MATCH_PASSWORDS';

type ValidatePasswordArgs = {
  password: string;
  isRepeatedPassword: boolean;
};

export function passwordContainsLowerCase(password: string): boolean {
  return password.toUpperCase() !== password;
}

export function passwordContainsUppercase(password: string): boolean {
  return password.toLowerCase() !== password;
}

export function passwordContainsNumeric(password: string): boolean {
  return /\d/.test(password);
}

export function passwordContainsMinimumLength(password: string): boolean {
  return password.length >= PASSWORD_MINIMUM_LENGTH;
}

export function passwordContainsNonAlphanumeric(password: string): boolean {
  return password
    .split('')
    .some((character) => NON_ALPHANUMERIC_CHARACTERS.includes(character));
}

export const validatePassword = ({
  isRepeatedPassword,
  password,
}: ValidatePasswordArgs): {
  errors: PasswordPolicyError[];
} => {
  const errors: PasswordPolicyError[] = [];

  if (!passwordContainsMinimumLength(password)) errors.push('TOO_SHORT');
  if (!passwordContainsUppercase(password)) errors.push('NO_UPPERCASE');
  if (!passwordContainsLowerCase(password)) errors.push('NO_LOWERCASE');
  if (!passwordContainsNonAlphanumeric(password))
    errors.push('NO_SPECIAL_CHARACTER');
  if (!passwordContainsNumeric(password)) errors.push('NO_NUMBER');
  if (isRepeatedPassword) errors.push('REPEATED_PASSWORD');

  return { errors };
};
