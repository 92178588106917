import React from 'react';
import { formatEnergy } from 'services/format';
import Graph from 'components/Graph';
import { MeterSample } from 'generated/graphql';
import { BodyTypography } from 'new-components/Typographies/styles';
import { useTranslation } from 'react-i18next';
import { Container, GraphContainer } from './styles';

type EnergyMeasurement = MeterSample;

const sortMeasurement = (a: EnergyMeasurement, b: EnergyMeasurement) =>
  Number(new Date(a.timestamp)) - Number(new Date(b.timestamp));

type Props = {
  energyMeasurements: EnergyMeasurement[];
};

const MeasurementsGraph: React.FC<Props> = ({ energyMeasurements }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <GraphContainer>
        {energyMeasurements.length > 0 ? (
          <Graph<EnergyMeasurement>
            data={energyMeasurements?.slice().sort(sortMeasurement)}
            x={{
              dataKey: 'timestamp',
              formatText: (value) =>
                t('recharge-modal.measurements-graph.graph-text', {
                  value: new Date(value),
                }),
            }}
            y={{
              dataKey: 'value',
              color: 'RECHARGES',
              unit: 'kWh',
              formatText: (value) =>
                formatEnergy(value, {
                  showUnit: false,
                  digits: 0,
                }),
            }}
            tooltipContent={({ payload: { timestamp, value } }) => (
              <>
                <p>
                  {t('recharge-modal.hours.title')}{' '}
                  {t(
                    'recharge-modal.measurements-graph-tooltip-content-hours',
                    {
                      value: new Date(timestamp),
                    }
                  )}
                </p>
                <p>
                  {t('recharge-modal.energy.title')}{' '}
                  {formatEnergy(Number(value))}{' '}
                </p>
              </>
            )}
          />
        ) : (
          <BodyTypography>
            {t('recharge-modal.without-data.message')}
          </BodyTypography>
        )}
      </GraphContainer>
    </Container>
  );
};

export default MeasurementsGraph;
