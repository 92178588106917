import getAdressInfo, { CEP as AddressInfo } from 'cep-promise';
import {
  AllowStationToBePubliclyAccessedViaOcppMutationVariables,
  ChangeStationConnectorsMutationVariables,
  ChangeStationLocationMutationVariables,
  ChangeStationUnlockMethodsMutationVariables,
  ConfigureStationCommunicationMutationVariables,
  ConnectorCreateInput,
  Maybe,
  StationOwnerEditDetailsQuery,
  StationOwnerEditDetailsQueryVariables,
  // ChangeStationChargePointIdMutationVariables,
  // ConfigureStationVisibilityMutationVariables,
} from 'generated/graphql';
// import configureStationVisibility from 'graphql/mutations/configureStationVisibility';
// import changeStationChargePointIdMutation from 'graphql/mutations/changeStationChargePointId';
import allowStationToBePubliclyAccessedViaOCPP from 'graphql/mutations/allowStationToBePubliclyAccessedViaOCPP';
import changeStationConnectorsMutation from 'graphql/mutations/changeStationConnectors';
import changeStationLocationMutation from 'graphql/mutations/changeStationLocation';
import changeStationUnlockMethodsMutation from 'graphql/mutations/changeStationUnlockMethods';
import configureStationCommunicationMutation from 'graphql/mutations/configureStationCommunication';
import stationOwnerEditDetails from 'graphql/queries/stationOwnerEditDetails';
import { useAuthMutation, useAuthQuery } from 'hooks';
import { StationContext } from 'pages/StationPage/StationContext';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'services/toast';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  SkeletonText,
  Stack,
  Switch,
  WrapItem,
  Input,
  NumberInput,
  NumberInputField,
  Divider,
  Select,
  Center,
  StackDivider,
  Flex,
  Spacer,
  FormHelperText,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import Config from 'config';
import { useTranslation } from 'react-i18next';

type SetVariablesFunction<Variables> = (
  vars: Partial<Partial<Variables>>
) => void;

type FormProps<Variables> = {
  stationId: string;
  mutationFile: any;
  title: string;
  children: (
    setVariables: SetVariablesFunction<Variables>,
    variables: Partial<Variables>
  ) => React.ReactNode;
  defaultVariables?: Partial<Variables>;
  onSave: Function;
  onPrev: Function;
};

function Form<Variables extends { where: { stationId: string } }>({
  stationId,
  children,
  mutationFile,
  defaultVariables,
  title,
  onSave,
  onPrev,
}: FormProps<Variables>) {
  const { t } = useTranslation();
  const [variables, setVariables] = useState<Partial<Variables>>(
    defaultVariables ?? {}
  );
  const loadingDefaultVariables = Object.values(variables).length === 0;
  useEffect(() => {
    if (defaultVariables && loadingDefaultVariables) {
      setVariables(defaultVariables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVariables]);
  const [callMutation, { loading }] = useAuthMutation(mutationFile);

  const where = { stationId };

  const tryMutation = async () => {
    try {
      await callMutation({ variables: { where, ...variables } });
      toast.success(t('generic-feedback-success-message'));
      onSave();
    } catch (e) {
      toast.error(t('generic-feedback-error-message'));
      console.warn('Erro na mutation, você preencheu todos os campos? ', e);
    }
  };

  return (
    <WrapItem>
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        bgColor="white"
        borderRadius="lg"
      >
        <SkeletonText isLoaded={!loadingDefaultVariables}>
          <Heading fontSize="xl">{title}</Heading>
          {children(
            (partialVars) => setVariables({ ...variables, ...partialVars }),
            variables
          )}
          <Flex>
            <Spacer />
            <HStack spacing={4}>
              <Button onClick={() => onPrev()} variant="outline">
                Anterior
              </Button>
              <Button onClick={tryMutation} isLoading={loading}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </SkeletonText>
      </Box>
    </WrapItem>
  );
}

// From https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
function omitDeep<T>(value: T, key: string): T {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key)) as any;
  }
  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      // @ts-ignore
      return { [k]: omitDeep(value[k], key), ...newObject };
    }, {} as T);
  }
  return value;
}

const StepByStepConfiguration: React.FC = () => {
  const { id: stationId, model } = useContext(StationContext);

  const isInArray = (array: string[], string: string) => {
    if (!array) return false;
    return array.includes(string);
  };

  const toggleString = <T extends string>(array: T[], string: T) => {
    const newArray = array ? [...array] : [];
    if (isInArray(newArray, string))
      newArray.splice(newArray.indexOf(string), 1);
    else newArray.push(string);
    return newArray;
  };

  const formatConnectorLabel = ({
    label,
    type,
  }: {
    label: string;
    type: string;
  }) => {
    const formattedLabel =
      type === 'GBT_AC'
        ? label.concat(' AC')
        : type === 'GBT_DC'
        ? label.concat(' DC')
        : label;
    return formattedLabel;
  };

  const setConnector = (
    index: number,
    connector: Partial<ConnectorCreateInput>,
    oldConnectors?: ConnectorCreateInput[]
  ) => {
    const connectors = oldConnectors ?? [];
    connectors[index] = { ...connectors[index], ...connector };
    return connectors;
  };

  const { data, stale, refetch } = useAuthQuery<
    StationOwnerEditDetailsQuery,
    StationOwnerEditDetailsQueryVariables
  >(stationOwnerEditDetails, {
    variables: { where: { stationId } },
    skip: !stationId,
  });

  const correctedData: typeof data = omitDeep(data, '__typename');

  const station = stale ? undefined : correctedData?.station;

  const [cep, _setCep] = useState('');
  const [_addressInfo, setAddressInfo] = useState<AddressInfo | undefined>();
  useEffect(() => {
    if (cep.length === 8) {
      getAdressInfo(cep.replace('-', '')).then((res: any) =>
        setAddressInfo(res)
      );
    }
  }, [cep]);

  const {
    ocppVersion,
    ocppUrl,
    ocppPublicAccessAllowed,
    routerPortal,
    configPortal,
    address,
    coordinates,
    connectors,
    unlockMethods,
    // chargePointId,
    // visibility
  } = station ?? {};

  function getInitialStep() {
    if (connectors?.length === 0) return 0;
    if (!ocppVersion) return 1;
    if (!unlockMethods?.length) return 2;
    if (!address || !coordinates) return 3;
    return 4;
  }
  const [alreadySetInitialStep, setAlreadySetInitialStep] = useState(false);
  const { nextStep, prevStep, setStep, activeStep } = useSteps({
    initialStep: 0,
  });
  useEffect(() => {
    if (station && !alreadySetInitialStep) {
      setStep(getInitialStep());
      setAlreadySetInitialStep(true);
    }
    // eslint-disable-next-line
  }, [station]);

  const goToNextStep = () => {
    refetch();
    nextStep();
  };

  const unlockMethodsForm = (
    <Form<ChangeStationUnlockMethodsMutationVariables>
      title="Mudar método de desbloqueio"
      stationId={stationId}
      mutationFile={changeStationUnlockMethodsMutation}
      onSave={goToNextStep}
      onPrev={prevStep}
      defaultVariables={{
        data: {
          unlockMethods: unlockMethods ?? [],
        },
      }}
    >
      {(setVariables, variables) => (
        <>
          <HStack spacing={8}>
            {model?.capabilities.includes('RFID') && (
              <Box>
                <Switch
                  isChecked={isInArray(
                    (variables as ChangeStationUnlockMethodsMutationVariables)
                      ?.data?.unlockMethods,
                    'ChargingCard'
                  )}
                  onChange={() =>
                    setVariables({
                      data: {
                        unlockMethods: toggleString(
                          (
                            variables as ChangeStationUnlockMethodsMutationVariables
                          )?.data?.unlockMethods,
                          'ChargingCard'
                        ),
                      },
                    })
                  }
                >
                  ChargingCard
                </Switch>
              </Box>
            )}
            <Box>
              <Switch
                isChecked={isInArray(
                  (variables as ChangeStationUnlockMethodsMutationVariables)
                    ?.data?.unlockMethods,
                  'Remote'
                )}
                defaultChecked={isInArray(unlockMethods ?? [], 'Remote')}
                onChange={() =>
                  setVariables({
                    data: {
                      unlockMethods: toggleString(
                        (
                          variables as ChangeStationUnlockMethodsMutationVariables
                        )?.data?.unlockMethods,
                        'Remote'
                      ),
                    },
                  })
                }
              >
                Remote
              </Switch>
            </Box>
            <Box>
              <Switch
                isChecked={isInArray(
                  (variables as ChangeStationUnlockMethodsMutationVariables)
                    ?.data?.unlockMethods,
                  'Open'
                )}
                onChange={() =>
                  setVariables({
                    data: {
                      unlockMethods: toggleString(
                        (
                          variables as ChangeStationUnlockMethodsMutationVariables
                        )?.data?.unlockMethods,
                        'Open'
                      ),
                    },
                  })
                }
              >
                Livre
              </Switch>
            </Box>
          </HStack>
        </>
      )}
    </Form>
  );

  const [numberOfConnectors, setNumberOfConnectors] = useState(0);
  useEffect(
    () => connectors && setNumberOfConnectors(connectors.length),
    // eslint-disable-next-line
    [connectors?.length]
  );

  const connectorsForm = (
    <Form<{
      connectors: Exclude<
        ChangeStationConnectorsMutationVariables['connectors'],
        ConnectorCreateInput
      >;
      where: { stationId: string };
    }>
      title="Mudar conectores"
      stationId={stationId}
      mutationFile={changeStationConnectorsMutation}
      onSave={nextStep}
      onPrev={prevStep}
      defaultVariables={
        connectors
          ? { connectors: connectors as ConnectorCreateInput[] }
          : undefined
      }
    >
      {(setVariables, variables) => (
        <Box>
          <Button
            onClick={() => {
              const n = numberOfConnectors;
              setNumberOfConnectors((n) => n + 1);
              setVariables({
                connectors: setConnector(
                  n,
                  { id: n + 1 },
                  variables.connectors
                ),
              });
            }}
          >
            +
          </Button>
          <Button onClick={() => setNumberOfConnectors((n) => n - 1)}>-</Button>
          <HStack spacing={8} divider={<StackDivider />}>
            {Array.from(new Array(numberOfConnectors)).map((_, i) => (
              <Stack key={i} p={5}>
                <Heading fontSize="3xl">Conector {i + 1}</Heading>
                <FormLabel>Tipo de conector</FormLabel>
                <Select
                  value={variables.connectors?.[i]?.type}
                  onChange={(e) =>
                    setVariables({
                      connectors: setConnector(
                        i,
                        { type: e.target.value as any },
                        variables.connectors
                      ),
                    })
                  }
                >
                  {Object.entries(Config.LABELS.CONNECTOR_TYPES).map(
                    ([type, label]) => {
                      const formattedLabel = formatConnectorLabel({
                        type,
                        label,
                      });
                      return (
                        <option value={type} key={type}>
                          {formattedLabel}
                        </option>
                      );
                    }
                  )}
                </Select>
                <FormLabel>Potência</FormLabel>
                <NumberInput
                  value={`${variables.connectors?.[i]?.maxPower ?? 0} Wh`}
                  min={0}
                  step={1000}
                  onChange={(text) =>
                    setVariables({
                      connectors: setConnector(
                        i,
                        { maxPower: parseInt(text.replace(' Wh', '')) },
                        variables.connectors
                      ),
                    })
                  }
                >
                  <NumberInputField />
                </NumberInput>

                <FormLabel>Tensão(voltage)</FormLabel>
                <NumberInput
                  value={`${variables.connectors?.[i]?.maxVoltage ?? 0} V`}
                  min={0}
                  step={10}
                  onChange={(text) =>
                    setVariables({
                      connectors: setConnector(
                        i,
                        { maxVoltage: parseInt(text.replace(' V', '')) },
                        variables.connectors
                      ),
                    })
                  }
                >
                  <NumberInputField />
                </NumberInput>

                <FormLabel>Corrente(amperage)</FormLabel>
                <NumberInput
                  value={`${variables.connectors?.[i]?.maxAmperage ?? 0} A`}
                  min={0}
                  step={1}
                  onChange={(text) =>
                    setVariables({
                      connectors: setConnector(
                        i,
                        { maxAmperage: parseInt(text.replace(' A', '')) },
                        variables.connectors
                      ),
                    })
                  }
                >
                  <NumberInputField />
                </NumberInput>

                <FormLabel>EVSE do conector</FormLabel>
                <NumberInput
                  value={variables.connectors?.[i]?.evseId ?? undefined}
                  min={1}
                  onChange={(text) =>
                    setVariables({
                      connectors: setConnector(
                        i,
                        { evseId: parseInt(text) },
                        variables?.connectors
                      ),
                    })
                  }
                >
                  <NumberInputField />
                </NumberInput>
              </Stack>
            ))}
          </HStack>
        </Box>
      )}
    </Form>
  );

  const communicationForm = (
    <Form<ConfigureStationCommunicationMutationVariables>
      title="Configurar conexão"
      stationId={stationId}
      mutationFile={configureStationCommunicationMutation}
      onSave={nextStep}
      onPrev={prevStep}
      defaultVariables={{ configPortal, ocppUrl, ocppVersion, routerPortal }}
    >
      {(setVariables, variables) => (
        <Stack spacing={8} p={5}>
          <FormControl isRequired>
            <FormLabel>Versão OCPP</FormLabel>
            <Select
              value={variables.ocppVersion}
              onChange={(e) =>
                setVariables({ ocppVersion: e.target.value as any })
              }
            >
              <option value="V15">v1.5</option>
              <option value="V16">v1.6</option>
            </Select>
          </FormControl>
          <FormControl isRequired={variables.ocppVersion === 'V15'}>
            <FormLabel>URL OCPP</FormLabel>
            <Input
              value={variables.ocppUrl}
              onChange={(e) => setVariables({ ocppUrl: e.target.value as any })}
            />
            <FormHelperText>
              EFACEC ends with /smartgrid/ChargePointService
            </FormHelperText>
          </FormControl>
          <Divider />
          <Stack spacing={4}>
            <Heading fontSize="xl">Roteador</Heading>
            <Stack>
              <FormControl isRequired={!!variables.routerPortal}>
                <FormLabel>URL</FormLabel>
                <Input
                  value={variables.routerPortal?.url}
                  onChange={(e) =>
                    setVariables({
                      routerPortal: {
                        ...variables.routerPortal,
                        url: e.target.value,
                      } as any,
                    })
                  }
                />
              </FormControl>
              <FormControl isRequired={!!variables.routerPortal}>
                <FormLabel>Usuário</FormLabel>
                <Input
                  value={variables.routerPortal?.user}
                  onChange={(e) =>
                    setVariables({
                      routerPortal: {
                        ...variables.routerPortal,
                        user: e.target.value,
                      } as any,
                    })
                  }
                />
              </FormControl>
              <FormControl isRequired={!!variables.routerPortal}>
                <FormLabel>Senha</FormLabel>
                <Input
                  value={variables.routerPortal?.password}
                  onChange={(e) =>
                    setVariables({
                      routerPortal: {
                        ...variables.routerPortal,
                        password: e.target.value,
                      } as any,
                    })
                  }
                />
              </FormControl>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={4}>
            <Heading fontSize="xl">Plataforma Carregador</Heading>
            <HStack>
              <FormControl isRequired={!!variables.configPortal}>
                <FormLabel>URL</FormLabel>
                <Input
                  value={variables.configPortal?.url}
                  onChange={(e) =>
                    setVariables({
                      configPortal: {
                        ...variables.configPortal,
                        url: e.target.value,
                      } as any,
                    })
                  }
                />
              </FormControl>
              <FormControl isRequired={!!variables.configPortal}>
                <FormLabel>Usuário</FormLabel>
                <Input
                  value={variables.configPortal?.user}
                  onChange={(e) =>
                    setVariables({
                      configPortal: {
                        ...variables.configPortal,
                        user: e.target.value,
                      } as any,
                    })
                  }
                />
              </FormControl>
              <FormControl isRequired={!!variables.configPortal}>
                <FormLabel>Senha</FormLabel>
                <Input
                  value={variables.configPortal?.password}
                  onChange={(e) =>
                    setVariables({
                      configPortal: {
                        ...variables.configPortal,
                        password: e.target.value,
                      } as any,
                    })
                  }
                />
              </FormControl>
            </HStack>
          </Stack>
        </Stack>
      )}
    </Form>
  );

  const ocppPublicForm = (
    <Form<AllowStationToBePubliclyAccessedViaOcppMutationVariables>
      title="Acesso público via OCPP"
      stationId={stationId}
      mutationFile={allowStationToBePubliclyAccessedViaOCPP}
      onSave={() => {}}
      onPrev={prevStep}
      defaultVariables={{ allow: ocppPublicAccessAllowed }}
    >
      {(setVariables, { allow }) => (
        <Box p={5}>
          <Switch
            isChecked={allow}
            onChange={() => setVariables({ allow: !allow })}
          />
        </Box>
      )}
    </Form>
  );

  const parseCoordinate = (coordinate: string): number => {
    const parsedText = parseFloat(coordinate);
    return Number.isNaN(parsedText) ? 0 : parsedText;
  };

  const defaultAddress: Maybe<{
    street: string;
    streetNumber: string;
    city: string;
    state: string;
    neighborhood: string;
    postalCode: string;
    country: string;
  }> = address ?? {
    street: '',
    city: '',
    country: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    streetNumber: '',
  };

  const defaultCoords: Maybe<{ longitude: number; latitude: number }> =
    coordinates ?? {
      latitude: 0,
      longitude: 0,
    };

  let displayLat: string = '';
  let displayLong: string = '';

  const locationForm = (
    <Form<ChangeStationLocationMutationVariables>
      title="Configurar endereço"
      stationId={stationId}
      mutationFile={changeStationLocationMutation}
      onSave={nextStep}
      onPrev={prevStep}
      defaultVariables={{ ...defaultAddress, ...defaultCoords }}
    >
      {(
        setVariables,
        { city, country, neighborhood, postalCode, state, street, streetNumber }
      ) => (
        <Box p={5}>
          <HStack spacing={8} divider={<StackDivider />}>
            <Flex alignSelf="flex-start" flexDirection="column">
              <FormControl isRequired>
                <FormLabel>Latitude</FormLabel>
                <NumberInput
                  value={displayLat}
                  min={-90}
                  max={90}
                  precision={10}
                  onChange={(n) => {
                    displayLat = n;
                    setVariables({ latitude: parseCoordinate(n) });
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Longitude</FormLabel>
                <NumberInput
                  value={displayLong}
                  min={-90}
                  max={90}
                  precision={10}
                  onChange={(n) => {
                    displayLong = n;
                    setVariables({ longitude: parseCoordinate(n) });
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </Flex>
            <Stack>
              <FormControl>
                <FormLabel>Cidade:</FormLabel>
                <Input
                  value={city}
                  onChange={(e) =>
                    setVariables({ city: e.target.value as any })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>País:</FormLabel>
                <Input
                  value={country}
                  onChange={(e) =>
                    setVariables({ country: e.target.value as any })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Bairro:</FormLabel>
                <Input
                  value={neighborhood}
                  onChange={(e) =>
                    setVariables({ neighborhood: e.target.value as any })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>CEP:</FormLabel>
                <Input
                  value={postalCode}
                  onChange={(e) =>
                    setVariables({ postalCode: e.target.value as any })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Estado:</FormLabel>
                <Input
                  value={state}
                  onChange={(e) =>
                    setVariables({ state: e.target.value as any })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Nome da rua:</FormLabel>
                <Input
                  value={street}
                  onChange={(e) =>
                    setVariables({ street: e.target.value as any })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Nº da rua:</FormLabel>
                <Input
                  value={streetNumber}
                  onChange={(e) =>
                    setVariables({ streetNumber: e.target.value as any })
                  }
                />
              </FormControl>
            </Stack>
          </HStack>
        </Box>
      )}
    </Form>
  );
  // const visiblityForm = (
  //   <Form<ConfigureStationVisibilityMutationVariables>
  //     title="Configurar visibilidade"
  //     stationId={stationId}
  //     mutationFile={configureStationVisibility}
  //     type="simple"
  //     onSave={nextStep}
  //     onPrev={prevStep}
  //     defaultVariables={{ visibility }}
  //   >
  //     {(setVariables, { visibility }) => (
  //       <Box p={5}>
  //         <Select
  //           value={visibility}
  //           onChange={(e) =>
  //             setVariables({ visibility: e.target.value as any })
  //           }
  //         >
  //           <option value="PUBLIC">Público</option>
  //           <option value="PRIVATE">Privado</option>
  //         </Select>
  //       </Box>
  //     )}
  //   </Form>
  // );

  return (
    <Box
      backgroundColor={Config.COLORS.BACKGROUND}
      height="auto"
      paddingBottom="100px"
    >
      <Stack>
        <Steps
          activeStep={activeStep}
          p={10}
          onClickStep={(step) => setStep(step)}
        >
          <Step label="Configurar conectores">
            <Center>{connectorsForm}</Center>
          </Step>
          <Step label="Configurar conexão">
            <Center>{ocppPublicForm}</Center>
            <Center>{communicationForm}</Center>
          </Step>
          <Step label="Método de desbloqueio">
            <Center>{unlockMethodsForm}</Center>
          </Step>
          <Step label="Endereço">
            <Center>{locationForm}</Center>
          </Step>
          {/* <Step label="Visibilidade">
            <Center>{visiblityForm}</Center>
          </Step> */}
        </Steps>
        {activeStep === 4 && (
          <Center>
            <Heading>Todos os passos completados, parabéns!</Heading>
          </Center>
        )}
      </Stack>
    </Box>
  );
};

export default StepByStepConfiguration;
