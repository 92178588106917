/* eslint-disable react-hooks/rules-of-hooks */
import {
  CreateVoucherMutation,
  CreateVoucherMutationVariables,
} from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import createVoucher from 'graphql/mutations/createVoucher';
import { CreateVoucherClient } from '..';

export const useCreateVoucherGraphqlClient = (): CreateVoucherClient => ({
  createVoucher: ({
    description,
    discount,
    driversSelection,
    limits,
    name,
    organizationId,
    stationsSelection,
    validityPeriod,
  }) => {
    const [createVoucherMutation, { loading }] = useAuthMutation<
      CreateVoucherMutation,
      CreateVoucherMutationVariables
    >(createVoucher, {
      variables: {
        where: {
          organizationId: organizationId ?? '',
        },
        data: {
          name,
          description,
          discount: discount ? discount / 100 : 0,
          limits: {
            ...limits,
            usageLimit: limits.usageLimit ?? 0,
          },
          validityPeriod: {
            ...validityPeriod,
            from: validityPeriod.from?.toISOString(),
            to: validityPeriod.to?.toISOString(),
          },
          driversSelection,
          stationsSelection,
        },
      },
    });
    return {
      createVoucherMutation,
      createVoucherLoading: loading,
    };
  },
});
