import { gql } from '@apollo/client';

export default gql`
  query OrdersHistory(
    $pagination: PaginationInput
    $timeRange: TimeRangeInput
    $sort: SortOrderInput = { createdAt: DESC }
    $filter: OrderFilterInput
  ) {
    ordersSummary {
      count
    }
    orders(
      pagination: $pagination
      timeRange: $timeRange
      sort: $sort
      filter: $filter
    ) {
      orderId
      preAuthorizeAmount
      capturedValue
      valueToBeCaptured
      status
      currencyType
      paymentMethod {
        id
        ... on PaymentCard {
          brand
          partiallyHiddenCardNumber
        }
        ... on PlacePlan {
          holderFullName
          title
        }
        __typename
      }
      createdAt
      driverId
      session {
        id
        stationProfile {
          id
          name
          cpoId
        }
      }
    }
  }
`;
