import styled from 'styled-components';

export const GroupOuterContainer = styled.div`
  width: 400px;
`;

export const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;
