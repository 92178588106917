import ErrorContainer from 'new-components/ErrorComponent';
import NewModal from 'new-components/NewModal';
import styled from 'styled-components';

export const Container = styled(NewModal)`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`;

export const ErrorComponent = styled(ErrorContainer)`
  top: calc(50% - 31px);
`;
