import { Box } from '@chakra-ui/react';
import { Link } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  appCode: string;
};

export const ForgotPasswordLink = ({ appCode }: Props) => {
  const { t } = useTranslation();

  return (
    <Box h="full" textAlign="right" mt={1}>
      <Link
        size={14}
        color="PRIMARY_GRAY"
        textDecoration="underline"
        to={`/request-driver-password-reset/?appCode=${appCode}`}
      >
        {t('account-exclusion-form.forgot-password.link-text')}
      </Link>
    </Box>
  );
};
