import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const PlugRConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: false,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'plug-r',
  LABEL: 'Plug-R',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#26B4E3',
    CLICKABLE: '#26B4E3',
    ERROR: '#D62246',
    SIDEBAR: '#26B4E3',
    LOGIN_LOGO_BACKGROUND: '#26B4E3',

    RISE: '#3FA34D',
    FALL: '#D62246',
    NEUTRAL: '#26B4E3',

    CRITICAL: '#D62246',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#3FA34D',
    NoCost: '#F4F4F4',
    Error: '#D62246',

    AVAILABLE: '#3FA34D',
    CHARGING: '#26B4E3',
    INOPERATIVE: '#D62246',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#D62246',
    RESERVED: '#662C91',

    PREPARING: '#26B4E3',
    FINISHING: '#26B4E3',

    SELECTED_DATE_BOUND_BACKGROUND: '#1894BE',
    SELECTED_DATE_BOUND_BORDER: '#26B4E3',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#1683A7',

    DATE_PRE_SELECTED_BACKGROUND: '#C4EBF7',
    DATE_PRE_SELECTED_BORDER: '#80D3EF',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#C4EBF7',
    DATE_PRE_SELECTED_COLOR: '#1894BE',

    DATE_SELECTED_BACKGROUND: '#26B4E3',
    DATE_SELECTED_BORDER: '#26B4E3',
    DATE_SELECTED_HOVER_BACKGROUND: '#1894BE',

    CURRENT_DAY: '#1894BE',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#3FA34D',
      Subsidized: '#3FA34D',
      NoCost: '#F4F4F4',
      Error: '#D62246',
      ErrorOnPreAuthorization: '#D62246',
    },
    STATION_STATUS: {
      AVAILABLE: '#3FA34D',
      CHARGING: '#26B4E3',
      INOPERATIVE: '#D62246',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#D62246',
      RESERVED: '#662C91',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#3FA34D',
      PREPARING: '#26B4E3',
      CHARGING: '#26B4E3',
      FINISHING: '#26B4E3',
      INOPERATIVE: '#D62246',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },

    BILLING: '#3FA34D',
    RECHARGES: '#26B4E3',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#26B4E3',
    BUTTON_UNAVAILABLE: '#939393',
    BUTTON_LOADING: '#1894BE',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#26B4E3',
    //----

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#1894BE',
      SELECTED_DATE_BOUND_BORDER: '#26B4E3',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#1683A7',

      DATE_PRE_SELECTED_BACKGROUND: '#C4EBF7',
      DATE_PRE_SELECTED_BORDER: '#80D3EF',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#C4EBF7',
      DATE_PRE_SELECTED_COLOR: '#1894BE',

      DATE_SELECTED_BACKGROUND: '#26B4E3',
      DATE_SELECTED_BORDER: '#80D3EF',
      DATE_SELECTED_HOVER_BACKGROUND: '#1894BE',

      CURRENT_DAY: '#1894BE',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Plug-R - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/plug-r',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#C4EBF7',
      '100': '#97DBF2',
      '200': '#80D3EF',
      '300': '#6ACBEC',
      '400': '#53C4E9',
      '500': '#26B4E3',
      '600': '#1894BE',
      '700': '#1683A7',
      '800': '#137190',
      '900': '#0D4E63',
    },
    LINK: {
      '50': '#C4EBF7',
      '100': '#97DBF2',
      '200': '#80D3EF',
      '300': '#6ACBEC',
      '400': '#53C4E9',
      '500': '#26B4E3',
      '600': '#1894BE',
      '700': '#1683A7',
      '800': '#137190',
      '900': '#0D4E63',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#ED8B9E',
      '200': '#E9758C',
      '300': '#E65F7A',
      '400': '#E24968',
      '500': '#D62246',
      '600': '#AA1B38',
      '700': '#941830',
      '800': '#7E1429',
      '900': '#520D1B',
    },
    SUCCESS: {
      '50': '#B3E2B9',
      '100': '#8ED398',
      '200': '#7CCC87',
      '300': '#69C576',
      '400': '#57BE65',
      '500': '#3FA34D',
      '600': '#317E3C',
      '700': '#2A6C33',
      '800': '#23592A',
      '900': '#143519',
    },
    CHARGING: {
      '50': '#C4EBF7',
      '100': '#97DBF2',
      '200': '#80D3EF',
      '300': '#6ACBEC',
      '400': '#53C4E9',
      '500': '#26B4E3',
      '600': '#1894BE',
      '700': '#1683A7',
      '800': '#137190',
      '900': '#0D4E63',
    },
    AVAILABLE: {
      '50': '#B3E2B9',
      '100': '#8ED398',
      '200': '#7CCC87',
      '300': '#69C576',
      '400': '#57BE65',
      '500': '#3FA34D',
      '600': '#317E3C',
      '700': '#2A6C33',
      '800': '#23592A',
      '900': '#143519',
    },
    SECONDARY: {
      '50': '#C4EBF7',
      '100': '#97DBF2',
      '200': '#80D3EF',
      '300': '#6ACBEC',
      '400': '#53C4E9',
      '500': '#26B4E3',
      '600': '#1894BE',
      '700': '#1683A7',
      '800': '#137190',
      '900': '#0D4E63',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#662C91',
      '600': '#4A206A',
      '700': '#3D1A56',
      '800': '#2F1443',
      '900': '#13081C',
    },
    INOPERATIVE: {
      '50': '#F4B7C3',
      '100': '#ED8B9E',
      '200': '##E9758C',
      '300': '#E65F7A',
      '400': '#E24968',
      '500': '#D62246',
      '600': '#AA1B38',
      '700': '#941830',
      '800': '#7E1429',
      '900': '#520D1B',
    },
  },
};
export default PlugRConfig;
