import {
  Input,
  Button,
  FormControl,
  FormLabel,
  InputRightElement,
  InputGroup,
  Container,
  FormErrorMessage,
  Heading,
  ScaleFade,
} from '@chakra-ui/react';
import { IconPassword } from 'components/Input/styles';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PasswordPolicy } from './password-policy';
import { validatePassword } from '../utils/password-policy';
import { SuccessSubmitFeedback } from './success-submit-feedback';

type Props = {
  password: string;
  setPassword: (password: string) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
};

export const ResetPasswordForm = ({
  onSubmit,
  isLoading,
  password,
  setPassword,
}: Props) => {
  const [isRepeatedPassword, setIsRepeatedPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] =
    useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const { t } = useTranslation();

  const validatePasswordMatch = () => {
    const passwordMatch = password === passwordConfirmation;
    return passwordMatch;
  };

  const { errors } = validatePassword({
    password,
    isRepeatedPassword,
  });

  const onChangePassword = (password: string) => {
    setIsRepeatedPassword(false);
    setPasswordMatchError(false);
    setPassword(password);
  };

  const onChangePasswordConfirmation = (passwordConfirmation: string) => {
    setIsRepeatedPassword(false);
    setPasswordMatchError(false);
    setPasswordConfirmation(passwordConfirmation);
  };

  const isButtonDisabled =
    !!errors.length || passwordMatchError || !passwordConfirmation.length;
  const passwordInputType = passwordVisible ? 'text' : 'password';
  const passwordConfirmationType = passwordConfirmationVisible
    ? 'text'
    : 'password';

  const handleErrorMessage = (error: Error) => {
    if (error.message.includes('password must not repeat last 12 passwords')) {
      setIsRepeatedPassword(true);
      return;
    }
    const isInvalidLink =
      error.message.includes('invalid-action-code') ||
      error.message.includes('expired-action-code');

    toast.error(
      isInvalidLink
        ? t('reset-password-form.submit-invalid-link-error-message')
        : t('reset-password-form.submit-generic-error-message')
    );
  };

  const onSubmitForm = async (event: FormEvent<HTMLFormElement>) => {
    const passwordMatch = validatePasswordMatch();
    if (!passwordMatch) return setPasswordMatchError(true);

    try {
      await onSubmit(event);
      setIsPasswordChanged(true);
    } catch (e) {
      if (e instanceof Error) handleErrorMessage(e);
    }
  };

  const onBlurPasswordConfirmationInput = () => {
    if (!passwordConfirmation.length) return;

    const passwordMatch = validatePasswordMatch();
    if (!passwordMatch) setPasswordMatchError(true);
  };

  if (isPasswordChanged) return <SuccessSubmitFeedback />;

  return (
    <Container width="full">
      <ScaleFade initialScale={0.4} in={true}>
        <Heading fontSize="4xl" textAlign="center">
          {t('reset-password.title')}
        </Heading>

        <PasswordPolicy.Header title={t('password-policy-list.title')} />
        <PasswordPolicy.List errors={errors} />

        <form onSubmit={onSubmitForm}>
          <FormControl mt={10}>
            <FormLabel fontSize="sm" htmlFor="password">
              {t('reset-password-form.password-title')}
            </FormLabel>
            <InputGroup>
              <Input
                autoFocus
                id="password"
                disabled={isLoading}
                type={passwordInputType}
                onCopy={(e) => e.preventDefault()}
                onChange={(e) => onChangePassword(e.target.value)}
                aria-label={t('reset-password-form.password-input-description')}
              />
              <InputRightElement>
                <IconPassword
                  onClick={() => setPasswordVisible((show) => !show)}
                  show={passwordVisible}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl mt={4} mb={12} h={20} isInvalid={passwordMatchError}>
            <FormLabel fontSize="sm" htmlFor="passwordConfirmation">
              {t('reset-password-form.password-confirmation-title')}
            </FormLabel>
            <InputGroup height={10}>
              <Input
                disabled={isLoading}
                id="passwordConfirmation"
                onBlur={onBlurPasswordConfirmationInput}
                type={passwordConfirmationType}
                onCopy={(e) => e.preventDefault()}
                onChange={(e) => onChangePasswordConfirmation(e.target.value)}
                aria-label={t(
                  'reset-password-form.password-confirmation-input-description'
                )}
              />
              <InputRightElement>
                <IconPassword
                  onClick={() =>
                    setPasswordConfirmationVisible((show) => !show)
                  }
                  show={passwordConfirmationVisible}
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>
              {t('reset-password-form.error-title')}
            </FormErrorMessage>
          </FormControl>

          <Button
            type="submit"
            disabled={isButtonDisabled}
            isLoading={isLoading}
          >
            {t('reset-password-form.button-title')}
          </Button>
        </form>
      </ScaleFade>
    </Container>
  );
};
