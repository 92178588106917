import { Typography } from 'components';
import Config from 'config';
import React, { useEffect, useState } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  AlignButtonClose,
  CircleContainer,
  Container,
  ContainerPopover,
  Content,
  ContentHolder,
  DescriptionContainer,
  QuestionMark,
  QuestionMarkButton,
  QuestionMarkCircle,
} from './styles';

const InfoBox: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <ContentHolder>
          <AlignButtonClose>
            <Config.ICONS.X height={12} width={12} onClick={onClose} />
          </AlignButtonClose>
          <DescriptionContainer>
            <Typography size={14} weight="light">
              {t('connectors-list-help.more-information-row.title')}
            </Typography>
          </DescriptionContainer>
          <CircleContainer>
            <Config.ICONS.CONNECTORS_HELP />
          </CircleContainer>
          <Flex justify="center">
            <Button size="sm" w="80%" onClick={onClose}>
              {t('connectors-list-help.close-btn.title')}
            </Button>
          </Flex>
        </ContentHolder>
      </Content>
    </Container>
  );
};

const ConnectorsListHelp = () => {
  const [showHelp, setShowHelp] = useState<boolean>(false);

  // workaround before presentation
  const toggleHelpComponent = () => setShowHelp(!showHelp);

  useEffect(() => {
    const closeHelpComponent = () => showHelp && setShowHelp(false);
    if (showHelp && window.addEventListener('scroll', closeHelpComponent, true))
      return window.removeEventListener('scroll', closeHelpComponent);
  }, [showHelp]);

  return (
    <>
      {showHelp ? (
        <ContainerPopover
          onClick={(e) => e.stopPropagation()}
          title="Saiba mais"
        >
          <Popover
            content={({ position, targetRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                targetRect={targetRect}
                popoverRect={popoverRect}
                arrowColor={Config.COLORS.WHITE}
                arrowSize={15}
                arrowStyle={{ zIndex: 10 }}
                style={{ filter: 'drop-shadow(0px 2px 6px #00000029)' }}
              >
                <InfoBox onClose={toggleHelpComponent} />
              </ArrowContainer>
            )}
            onClickOutside={(e) => {
              e.stopPropagation();
              toggleHelpComponent();
            }}
            isOpen={showHelp}
            position="bottom"
            windowBorderPadding={0}
            transitionDuration={0}
            align="center"
            containerStyle={{ overflow: 'visible' }}
          >
            <QuestionMarkButton
              onClick={(e) => {
                e.stopPropagation();
                toggleHelpComponent();
              }}
            >
              <QuestionMarkCircle>
                <QuestionMark>?</QuestionMark>
              </QuestionMarkCircle>
            </QuestionMarkButton>
          </Popover>
        </ContainerPopover>
      ) : (
        <QuestionMarkButton
          onClick={(e) => {
            e.stopPropagation();
            toggleHelpComponent();
          }}
        >
          <QuestionMarkCircle>
            <QuestionMark>?</QuestionMark>
          </QuestionMarkCircle>
        </QuestionMarkButton>
      )}
    </>
  );
};

export default ConnectorsListHelp;
