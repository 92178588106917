import Loader from 'components/Loader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Error, LoaderContainer } from './styles';

export type CardType = 'simple' | 'normal' | 'complex' | 'half';
interface Props {
  type: CardType;
  loading?: boolean;
  minHeight?: number;
  maxHeight?: number;
  error?: any;
  padded?: number;
  // TODO: i needed it, its a workaround and should not be keeped in system (when whe change to atomic design, remove that)
  noCardPadding?: boolean;
  wrap?: boolean;
}

const Card: React.FC<Props> = ({
  type,
  loading,
  error,
  children,
  minHeight,
  maxHeight,
  padded,
  noCardPadding,
  wrap,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      padded={padded}
      noCardPadding={noCardPadding}
      minHeight={minHeight}
      maxHeight={maxHeight}
      type={type}
      centerWhen={loading || error}
      wrap={wrap}
    >
      {error ? (
        <Error>{t('session-table-card.error-message')}</Error>
      ) : (
        <>
          {children}
          {loading && (
            <LoaderContainer>
              <Loader color="INFO_GRAY" />
            </LoaderContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default Card;
