import Config from 'config';
import React from 'react';

export type NotNestedNewIcons = typeof Config['NEW_ICONS'];
export type NotNestedColors = Omit<
  typeof Config['COLORS'],
  'CONNECTOR_STATUS' | 'CALENDAR' | 'STATION_STATUS' | 'ORDER_STATUS'
>;
export type IconSize = 12 | 16 | 20 | 24 | 28 | 36 | 64 | 128 | 256;

interface GenericIconProps {
  /** The default size value is 16px */
  size?: IconSize;
  type: keyof NotNestedNewIcons;
  color: keyof NotNestedColors;
}

const Icon = ({ size = 16, type, color }: GenericIconProps) => {
  const RenderedIcon = Config.NEW_ICONS[type];

  return (
    <RenderedIcon width={size} height={size} color={Config.COLORS[color]} />
  );
};

export { Icon };
