import { Skeleton, Td, Tr } from '@chakra-ui/react';

type Props = {
  columns: Array<string>;
};

const render = (columns: Array<string>) => {
  const skeletons = [];
  for (let i = 0; i < 12; i++) {
    skeletons.push(
      <Tr>
        {columns.map((_, index) => (
          <Td key={index}>
            <Skeleton h="5" />
          </Td>
        ))}
      </Tr>
    );
  }
  return skeletons;
};

export const SkeletonsTable = ({ columns }: Props) => <>{render(columns)}</>;
