import pt_BR from './pt_BR';
import es_ES from './es_ES';
import es_UY from './es_UY';

const resources = {
  es_ES,
  pt_BR,
  es_UY,
};

export default resources;
