import { Typography } from 'components';
import Card from 'components/Card';
import Page from 'components/Page';
import Table, { Column } from 'components/Table';
import {
  GroupDriversQuery,
  GroupDriversQueryVariables,
} from 'generated/graphql';
import { useAuthQuery } from 'hooks';
import React, { useState } from 'react';
import groupDetails from 'graphql/queries/groupDrivers';
import UpdateGroupDriversModal from 'atomic-components/organisms/modals/UpdateGroupDrivers';
import useHasScopes from 'hooks/useHasScope';
import { Button } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';
import { TableHeader, CardWrapper, OrderTableContainer } from './styles';
import { formatDriverNameToCheckbox } from '../functions/function';

type Props = NonNullable<
  NonNullable<GroupDriversQuery['group']>['drivers']
>[number];

type UsersProps = {
  groupId: string;
  updateGroupSummary: () => void;
};

type Options = {
  id: number;
  name: string;
};

const Users = ({ groupId, updateGroupSummary }: UsersProps) => {
  const { t } = useTranslation();
  const columns: Column<Props>[] = [
    {
      Header: t('access-groups-modal.user-tab.column.id.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { id },
        },
      }) => <Typography>{id || '-'}</Typography>,
    },
    {
      Header: t('access-groups-modal.user-tab.column.name.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            profile: { firstName },
          },
        },
      }) => <Typography>{firstName ? `${firstName} ` : '-'}</Typography>,
    },
    {
      Header: t('access-groups-modal.user-tab.column.email.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            profile: { email },
          },
        },
      }) => <Typography>{email ? `${email} ` : '-'}</Typography>,
    },
  ];

  const [addUsersToGroup, setAddUsersToGroup] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Options[]>([]);

  const hasScopes = useHasScopes();

  const {
    data: driversOfGroup,
    error,
    loading,
    refetch,
  } = useAuthQuery<GroupDriversQuery, GroupDriversQueryVariables>(
    groupDetails,
    {
      variables: {
        where: {
          id: groupId,
        },
      },
    }
  );
  const handleOpenModal = () => {
    const result = formatDriverNameToCheckbox(
      driversOfGroup?.group.drivers || []
    );
    setSelectedValues(result);
    setAddUsersToGroup(true);
  };

  return (
    <Page>
      <UpdateGroupDriversModal
        groupId={groupId}
        onClose={() => setAddUsersToGroup(false)}
        show={addUsersToGroup}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        updateListWhenUpdated={refetch}
        updateGroupSummary={updateGroupSummary}
      />

      <CardWrapper>
        <OrderTableContainer>
          <Card
            error={error}
            loading={loading}
            type="complex"
            noCardPadding={true}
            minHeight={700}
          >
            <TableHeader>
              <Typography color="PRIMARY_GRAY" weight="light">
                {t('access-groups-modal.users-tab.card.title')}
              </Typography>
              {hasScopes(['group:edit']) && (
                <>
                  <Button
                    onClick={handleOpenModal}
                    leftIcon={<Icon type="NEW_EDIT" size={16} color="WHITE" />}
                    size="sm"
                  >
                    {t('access-groups-modal.users-tab.card.btn.title')}
                  </Button>
                </>
              )}
            </TableHeader>
            <Table<Props>
              expandable={false}
              paginated={false}
              isFullWidth={true}
              columnMaxWidth={50}
              columns={columns}
              loading={loading}
              data={driversOfGroup?.group.drivers || []}
            />
          </Card>
        </OrderTableContainer>
      </CardWrapper>
    </Page>
  );
};

export default Users;
