import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import Filter, { equals, FilterTerms, parseUserId } from 'components/Filter';
import Page from 'components/Page';
import StationLink from 'components/StationLink';
import Table, { Column, Row } from 'components/Table';
import Typography from 'components/Typography';
import type {
  AppInfoFromScreen,
  CommentsQuery,
  CommentsQueryVariables,
  CommentType,
} from 'generated/graphql';
import comments from 'graphql/queries/comments';
import { useAuthQuery } from 'hooks';
import { t } from 'i18next';
import React, { useState } from 'react';
import type { FilterTerm } from 'types';
import DriverHeader from './DriverHeader';
import DriverMessage from './DriverMessage';
import {
  CommentMetadataContainer,
  CommentOriginContainer,
  CommentStationContainer,
  CommentTypeContainer,
  CommentUserContainer,
  Container,
  FeedbackIcon,
  FilterButtonContainer,
  ReviewIcon,
  TableContainer,
  TitleContainer,
} from './styles';

type Comment = CommentsQuery['comments'][0];
type CommentBuckets = {
  stationName: string[];
  driverId: number[];
  types: CommentType[];
};
type FilterKeys = keyof CommentBuckets;
type Term = FilterTerm<FilterKeys>;

const bucketCommentTerms = (appliedFilterTerms: FilterTerm<FilterKeys>[]) => {
  const buckets: CommentBuckets = { stationName: [], driverId: [], types: [] };

  appliedFilterTerms.forEach(({ type, value }) => {
    switch (type) {
      case 'driverId': {
        const id = parseUserId(value);
        if (!Number.isNaN(id)) buckets[type].push(id);
        break;
      }
      case 'types':
        buckets[type].push(value as CommentType);
        break;
      default:
        buckets[type].push(value);
    }
  });

  return buckets;
};

const nonEmptyEntries = <T extends Record<string, any[]>>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v.length)
  ) as Partial<T>;

const formatScreenName = (screen: AppInfoFromScreen): string => {
  switch (screen) {
    case 'StationDetails':
      return t('comments.stations-details.title');
    case 'TimeOut':
      return t('comments.time-out.title');
  }
};

const showCommentOrigin = (comment: Comment, extraText?: string) =>
  comment.deviceInfo && (
    <>
      <Typography size={10} color="SECONDARY_GRAY">
        {t('comments.origin.title')}
      </Typography>
      <Typography size={14}>
        App {comment.deviceInfo.appName} v{comment.deviceInfo.appVersion} (
        {comment.deviceInfo.osName} {comment.deviceInfo.osVersion}) {extraText}
      </Typography>
    </>
  );

// TODO: lookup defaults
const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const columns: Column<Comment>[] = [
  {
    Header: () => t('comments.column.type.title'),
    accessor: '__typename',
    disableSortBy: true,
    Cell: ({ row: { original } }: { row: Row<Comment> }) => (
      <Container>
        <CommentTypeContainer>
          {
            /* @ts-ignore */
            {
              Feedback: (
                <>
                  <FeedbackIcon />
                  <Typography color={'SECONDARY_GRAY'}>
                    {t('comments.comment-type-feedback.title')}
                  </Typography>
                </>
              ),
              Review: (
                <>
                  <ReviewIcon />
                  <Typography color={'SECONDARY_GRAY'}>
                    {t('comments.comment-type-review.title')}
                  </Typography>
                </>
              ),
            }[original.__typename]
          }
        </CommentTypeContainer>
      </Container>
    ),
  },
  {
    Header: () => t('comments.column.user.title'),
    accessor: 'driverId',
    disableSortBy: true,
    Cell: ({ row: { original } }: { row: Row<Comment> }) => (
      <Container>
        <CommentUserContainer>
          {original.__typename === 'Review' && (
            <>
              <DriverHeader
                id={original.driverId}
                stars={original.stars as 1 | 2 | 3 | 4 | 5}
              />
              <DriverMessage>
                <Typography
                  color={
                    original.optionalMessage ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'
                  }
                >
                  {original.optionalMessage ||
                    t('comments.without-comment.label')}
                </Typography>
              </DriverMessage>
            </>
          )}
          {original.__typename === 'Feedback' && (
            <>
              <DriverHeader id={original.driverId} />
              <DriverMessage>
                <Typography>{original.message}</Typography>
              </DriverMessage>
            </>
          )}
        </CommentUserContainer>
      </Container>
    ),
  },
  {
    Header: () => t('comments.column.station.title'),
    accessor: 'stationProfile',
    disableSortBy: true,
    Cell: ({ row: { original } }: { row: Row<Comment> }) => (
      <Container>
        <CommentStationContainer>
          <StationLink
            stationId={original.stationProfile.id}
            stationName={original.stationProfile.name}
            cpoId={original.stationProfile.cpoId}
          />
          <CommentMetadataContainer>
            {original.__typename === 'Review' && (
              <>
                {original.deviceInfo && (
                  <CommentOriginContainer>
                    {showCommentOrigin(original)}
                  </CommentOriginContainer>
                )}
                <Typography size={10} color="SECONDARY_GRAY">
                  {t('comments.metadata-session-id.title')}
                </Typography>
                <Typography size={14}>{original.sessionId}</Typography>
              </>
            )}
            {original.__typename === 'Feedback' &&
              showCommentOrigin(
                original,
                formatScreenName(original.fromAppScreen)
              )}
          </CommentMetadataContainer>
        </CommentStationContainer>
      </Container>
    ),
  },
  {
    Header: () => t('comments.column.data.title'),
    accessor: 'createdAt',
    disableSortBy: true,
    Cell: ({ row: { original } }: { row: Row<Comment> }) => (
      <Typography color="SECONDARY_GRAY">
        {t('comments.column-date.value', {
          value: new Date(original.createdAt),
        })}
      </Typography>
    ),
  },
];

const Comments: React.FC<{}> = () => {
  const filters = [
    {
      inputType: 'text',
      type: 'driverId',
      placeholder: t('filters.user.placeholder'),
      label: t('filters.user.label'),
    },
    {
      inputType: 'text',
      type: 'stationName',
      placeholder: t('filter-by-station.placeholder'),
      label: t('filter-by-station.title'),
    },
    {
      inputType: 'checkbox',
      type: 'types',
      label: 'Tipos',
      boxes: [
        {
          name: 'review',
          value: 'REVIEW',
          label: t('comments.filters.types.boxes.review.label'),
        },
        {
          name: 'feedback',
          value: 'FEEDBACK',
          label: t('comments.filters.types.boxes.feedback.label'),
        },
      ],
    },
  ] as const;

  const [{ pageIndex, pageSize }, setPagination] = useState(defaultPagination);
  const [filterPanelActive, setFilterPanelActive] = useState(false);
  const [appliedFilterTerms, setAppliedFilterTerms] = useState<Term[]>([]);

  const removeAppliedFilter = (term: Term) =>
    setAppliedFilterTerms(appliedFilterTerms.filter((t) => !equals(term, t)));

  const { stationName, driverId, types } =
    bucketCommentTerms(appliedFilterTerms);

  const {
    data: commentsData,
    error,
    loading,
  } = useAuthQuery<CommentsQuery, CommentsQueryVariables>(comments, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: nonEmptyEntries({ stationName, driverId, types }),
    },
  });

  return (
    <Page>
      <Card minHeight={841} error={error} loading={loading} type="complex">
        <CardHeader>
          <TitleContainer>
            <CardTitle title={t('comments.table-card.title')} />
          </TitleContainer>
          <FilterButtonContainer>
            <FilterTerms
              renderOnHeader={true}
              terms={appliedFilterTerms}
              removeTerm={removeAppliedFilter}
            />
            <Filter
              filters={filters}
              active={filterPanelActive}
              toggleFilterPanel={() => setFilterPanelActive(!filterPanelActive)}
              appliedFilterTerms={appliedFilterTerms}
              setAppliedFilterTerms={setAppliedFilterTerms}
            />
          </FilterButtonContainer>
        </CardHeader>
        <TableContainer>
          <Table<Comment>
            paginated
            pageCount={Math.ceil(
              (commentsData?.commentsSummary.count ?? 0) / pageSize
            )}
            fetchData={setPagination}
            columns={columns}
            data={commentsData?.comments ?? []}
            headerHorizontalAlignment="flex-start"
          />
        </TableContainer>
      </Card>
    </Page>
  );
};

export default Comments;
