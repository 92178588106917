import styled from 'styled-components';

const INNER_SIZE = '400px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${INNER_SIZE};
`;

export const LoginErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.ERROR};
  width: 100%;
  border-radius: 6px;
  padding: 3px;
  margin-top: 30px;
  text-align: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const ExtrasContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;

export const RemindUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoginButtonContainer = styled.div`
  width: 190px;
  height: 52px;
  margin-top: 50px;
`;

export const TermsOfUseContainer = styled.div`
  position: absolute;
  bottom: 45px;
  width: ${INNER_SIZE};
  text-align: center;
`;
