import type { FilterTerm } from 'types';

import { useReducer } from 'react';

type TextState<U extends string> = Partial<Record<U, string>>;

export function useTextInputReducer<U extends string>() {
  const defaultInputs = {};

  const reducer = (
    state: TextState<U>,
    { type, value }: FilterTerm<U>
  ): TextState<U> => ({
    ...state,
    [type]: value,
  });

  return useReducer(reducer, defaultInputs);
}
