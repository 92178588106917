import React, { useContext } from 'react';
import AuthContext from 'contexts/Auth/context';
import { Tag, Heading } from '@chakra-ui/react';
import { formatOrgId } from 'services/format';
import { Container, InfoContainer, InfoText, Title } from './styles';

type Props = {
  tag: JSX.Element;
  info: string;
  cpoId: string;
};

const StationHeader: React.FC<Props> = ({ children, tag, info, cpoId }) => {
  const { orgNameMap } = useContext(AuthContext);
  return (
    <Container>
      <InfoContainer>
        {tag}
        <InfoText>{info}</InfoText>
        <Tag
          marginLeft={5}
          bg={'gray.200'}
          padding={'6px 8px'}
          textAlign={'center'}
        >
          <Heading fontSize="14px" color={'white'} width={'full'}>
            {cpoId === '' ? '-' : formatOrgId(cpoId, orgNameMap)}
          </Heading>
        </Tag>
      </InfoContainer>
      <Title>{children}</Title>
    </Container>
  );
};

export default StationHeader;
