import React from 'react';
import ConfigType from 'config/typing';
import { formatNumber } from 'services/format';
import { useTranslation } from 'react-i18next';
import { TranslationKeyType } from 'types/translation';
import { AppendixContainer, DownArrow, UpArrow, Container } from './styles';

interface Props {
  currentValue: number;
  previousValue?: number;
  period?: TranslationKeyType;
}

type TrendType = 'increased' | 'decreased' | 'equal';

const getTrend = (difference: number): TrendType =>
  difference >= 0 ? (difference ? 'increased' : 'equal') : 'decreased';

const TREND_CONFIG: Record<
  TrendType,
  {
    color: keyof ConfigType['COLORS'];
    Appendix?: React.ElementType;
  }
> = {
  increased: {
    color: 'RISE',
    Appendix: UpArrow,
  },
  decreased: {
    color: 'FALL',
    Appendix: DownArrow,
  },
  equal: {
    color: 'NEUTRAL',
  },
};

const ChangeIndicator: React.FC<Props> = ({
  currentValue,
  previousValue = 0,
  period = 'home.sessions-timeline-graph.period',
}) => {
  const { t } = useTranslation();
  const changeRate = previousValue && (currentValue / previousValue - 1) * 100;
  const { color, Appendix } = TREND_CONFIG[getTrend(changeRate)];

  return (
    <Container color={color}>
      {Appendix && (
        <AppendixContainer>
          <Appendix color={color} />
        </AppendixContainer>
      )}
      {changeRate ? formatNumber(changeRate) : '--,--'}% {t(period)}
    </Container>
  );
};

export default ChangeIndicator;
