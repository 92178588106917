import { Box, Text } from '@chakra-ui/react';

type Props = {
  title: string;
};

export const PasswordPolicyHeader = ({ title }: Props) => (
  <Box mt={10} mb={4}>
    <Text color="gray.500">{title}</Text>
  </Box>
);
