import { Typography } from 'components';
import styled from 'styled-components';

export const Connection = styled.div`
  border-bottom: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

export const ConnectionText = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  size: 14,
})``;

export const SignalStatus = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  size: 12,
})``;
