import { Center } from '@chakra-ui/react';
import Config from 'config';

export const Header = () => {
  const Logo = Config.NEW_ICONS.NEW_LOGO;

  return (
    <Center
      h={16}
      top={0}
      w="full"
      zIndex={1}
      position="fixed"
      backgroundColor="primary.500"
    >
      <Logo width={110} height={40} color={Config.COLORS.PRIMARY} />
    </Center>
  );
};
