import { gql } from '@apollo/client';

export default gql`
  mutation CreateGroup(
    $data: CreateGroupInput!
    $where: EmspWhereUniqueInput!
  ) {
    createGroup(data: $data, where: $where) {
      id
      name
      organizationId
    }
  }
`;
