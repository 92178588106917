import AuthContext from 'contexts/Auth/context';
import { useCallback, useContext, useState } from 'react';
import config from 'config';

export type HttpPostRequestData = {
  body: BodyInit;
  headers: HeadersInit;
  path: string;
};

export default function useAuthPostRequest(): any {
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleAuthPost = useCallback(
    async (postData: HttpPostRequestData): Promise<any> => {
      setLoading(true);
      const response = await fetch(config.API.BASE_URL + postData.path, {
        headers: {
          authorization: `Bearer ${memberAuthToken}`,
          'org-ids': JSON.stringify(selectedOrganizationIds),
        },
        body: postData?.body,
        method: 'POST',
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseBody = await response.text();
      const jsonData = responseBody ? JSON.parse(responseBody) : null;
      setData(jsonData);
      setLoading(false);
      return jsonData;
    },
    [memberAuthToken, selectedOrganizationIds]
  );

  return { handleAuthPost, data, loading };
}
