import React from 'react';
import { MembershipsQuery } from 'generated/graphql';
import IdentityProvider from 'services/identity-provider';

export type Organization =
  MembershipsQuery['memberships'][number]['organization'];

export type Member = MembershipsQuery['memberships'][number];

type AuthContextType = {
  member?: Member;
  memberAuthToken?: string;
  organizations?: Array<Organization>;
  switchOrganizationDashboardUrl: (
    orgId: string,
    orgDashboardUrl: string
  ) => void;
  setOrganizationIdSelectedOnLogin: (orgId: Organization['id']) => void;
  loginByEmail: (
    email: string,
    password: string
  ) => Promise<{ error?: string }>;
  loginBySSO: () => Promise<{ error?: string }>;
  logout: () => void;
  loading: boolean;
  refetch: () => void;
  selectedOrganizationIds?: string[];
  setSelectedOrganizationIds: React.Dispatch<React.SetStateAction<string[]>>;
  orgNameMap: Record<string, string>;
  makeMemberLoginByToken: (authToken: string) => Promise<void>;
  setMember: React.Dispatch<React.SetStateAction<Member | undefined>>;
  identityProvider: IdentityProvider;
};

const AuthContext = React.createContext<AuthContextType>({
  switchOrganizationDashboardUrl: () => {
    throw new Error('switchOrganizationDashboardUrl not defined');
  },
  loginByEmail: () => {
    throw new Error('loginByEmail not defined');
  },
  loginBySSO: () => {
    throw new Error('loginBySSO not defined');
  },
  setOrganizationIdSelectedOnLogin: () => {
    throw new Error('setOrganizationIdSelectedOnLogin not defined');
  },
  logout: () => {
    throw new Error('logout not defined');
  },
  loading: true,
  refetch: () => {
    throw new Error('refetch not defined');
  },
  setSelectedOrganizationIds: () => {
    throw new Error('setSelectedOrganizationIds not defined');
  },
  orgNameMap: {},
  makeMemberLoginByToken: () => {
    throw new Error('makeLoginByToken not defined');
  },
  setMember: () => {
    throw new Error('setMember not defined');
  },
  identityProvider: {
    type: 'SSO_IdP',
    redirect: () => {
      throw new Error('Identity Provider not implemented.');
    },
  },
});

export default AuthContext;
