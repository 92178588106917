import { gql } from '@apollo/client';

export default gql`
  query StationDetailsGraph(
    $where: StationWhereUniqueInput!
    $input: PerDateTimeInput!
  ) {
    station(where: $where) {
      id
      cpoId
      usageRatePerDateTime(input: $input) {
        from
        to
        resolution
        usageRate
      }
      sessionsSummaryPerDateTime(
        input: $input
        filter: { includesStatus: [CHARGING, WAITING_UNPLUG, FINISHED] }
      ) {
        from
        to
        resolution
        sessionsSummary {
          count
          driverCount
          grossValue
          energyConsumed
          timeCharging
        }
      }
      connectors {
        id
        station {
          id
          status
        }
        status
        type
        power
        currentType
        errorCode
        usageRatePerDateTime(input: $input) {
          from
          to
          resolution
          usageRate
        }
        sessionsSummaryPerDateTime(
          input: $input
          filter: { includesStatus: [CHARGING, WAITING_UNPLUG, FINISHED] }
        ) {
          from
          to
          resolution
          sessionsSummary {
            count
            driverCount
            grossValue
            energyConsumed
            timeCharging
          }
        }
      }
    }
  }
`;
