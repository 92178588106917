import { gql } from '@apollo/client';

export default gql`
  query ListDriversToGroup($limit: Int, $search: String) {
    drivers(search: $search, limit: $limit) {
      id
      profile {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
