import { Typography } from 'components';
import styled from 'styled-components';

export const StationInfo = styled(Typography).attrs({ size: 16 })`
  margin-right: 8px;
`;
const secondColumnWidth = 144;

export const StationInfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: calc(100% - ${secondColumnWidth}px);
  ${StationInfo} {
    min-width: 50%;
  }
  @media (max-width: 1630px) {
    max-width: 100%;
    flex-flow: column nowrap;
  }
`;

export const AlignStationInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 34px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto ${secondColumnWidth}px;

  & > ${AlignStationInfo}:nth-child(3) {
    & > ${StationInfo} {
      letter-spacing: 10px;
    }
  }

  & > ${AlignStationInfo}:nth-child(3n + 5) {
    grid-column: span 2;
  }

  & > ${AlignStationInfo}:nth-child(9) {
    grid-column: span 2;
  }

  @media (max-width: 1630px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DescriptionContainer = styled.div`
  & > ${StationInfo} {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  width: 100%;
`;

export const InfoTitle = styled(Typography).attrs({
  size: 10,
  color: 'SECONDARY_GRAY',
})``;
