import Heading from 'atomic-components/atoms/Heading';
import Text from 'atomic-components/atoms/Text';
import styled from 'styled-components';
import { MotionStyle } from 'framer-motion';

export const PaddingSubTitle = styled.div<{}>`
  padding-top: ${({ theme }) => theme.padding['PADDING-16']}px;
  padding-bottom: ${({ theme }) => theme.padding['PADDING-16']}px;
`;

export const PaddingApplyButton = styled.div<{}>`
  padding-top: ${({ theme }) => theme.padding['PADDING-16']}px;
  padding-bottom: ${({ theme }) => theme.padding['PADDING-16']}px;
`;

export const TextSubTitle = styled(Text).attrs({
  fontFamily: 'body',
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: '4',
  color: 'gray.500',
})``;

export const HeadingTitle = styled(Heading).attrs({
  fontSize: 'xl',
  color: 'gray.900',
})``;

export const CustomScaleFadeStyles: MotionStyle = {
  position: 'absolute',
  zIndex: 1000,
  width: '96%',
};
