import { Typography } from 'components';
import Breadcrumb from 'new-components/Breadcrumb';
import React from 'react';
import { Container, MainLine, PageActions } from './styles';

interface Props {
  breadcrumbs?: string[];
  title?: string;
}

const NewPageHeader: React.FC<Props> = ({ breadcrumbs, title, children }) => (
  <Container>
    {breadcrumbs ? <Breadcrumb labels={breadcrumbs} /> : ''}
    <MainLine>
      <Typography size={24} weight="bold" color={'PRIMARY_GRAY'}>
        {title}
      </Typography>
      <PageActions>{children}</PageActions>
    </MainLine>
  </Container>
);
export default NewPageHeader;
