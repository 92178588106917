import CreateOrganizationModal from 'atomic-components/organisms/modals/CreateOrganization';
import InviteMemberModal from 'atomic-components/organisms/modals/InviteMember';
import Config from 'config/index';
import AuthContext from 'contexts/Auth/context';
import useHasScopes from 'hooks/useHasScope';
import useHasModule from 'hooks/useHasModule';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import {
  BodyTypography,
  Comment,
  H5,
} from 'new-components/Typographies/styles';
import React, { useContext, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MembershipsQuery } from 'generated/graphql';
import { Box, Flex, Heading, Text, WrapItem } from '@chakra-ui/react';
import {
  BoxAnimation,
  ChevronLeftGridArea,
  DropdownContainer,
  MotionBox,
  OrganizationRowContainer,
  PageTitleGridArea,
  ProfileButtonContainer,
  ProfileHeaderContainer,
  StyledTooltip,
} from './styles';
import OrganizationSection from './components/organizationsSection';

type DropdownPages = 'main' | 'organization';

export type OrganizationType =
  MembershipsQuery['memberships'][number]['organization'];

const ProfileDropdown = withRouter(({ history }) => {
  const { t } = useTranslation();
  const { organizations, member, logout, selectedOrganizationIds } =
    useContext(AuthContext);
  const hasModule = useHasModule();
  const hasScopes = useHasScopes();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownCurrentPage, setDropdownCurrentPage] =
    useState<DropdownPages>('main');

  const selectedOrganization = useMemo(
    () => member?.organization,
    [member?.organization]
  );

  const orderedOrganizations = organizations?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const selectedOrganizationsOrdered = orderedOrganizations
    ?.filter((org) => selectedOrganizationIds?.includes(org.id))
    .map((o) => o.name);

  const [createOrgVisible, setCreateOrgVisible] = useState(false);
  const handleCloseCreateOrg = () => setCreateOrgVisible(false);

  const [inviteMemberVisible, setInviteMemberVisible] = useState(false);
  const handleCloseInviteMember = () => setInviteMemberVisible(false);

  const MainPage = React.memo(() => (
    <>
      <Dropdown.Section onClick={() => history.push('/profile')}>
        <Box
          _hover={{
            bg: 'gray.50',
            cursor: 'pointer',
          }}
          _active={{
            bg: 'gray.100',
          }}
        >
          <ProfileHeaderContainer>
            <H5>
              {member?.profile.firstName} {member?.profile.lastName}
            </H5>
            <Comment>{member?.profile.email}</Comment>
            <Comment>
              {member?.role && t(Config.LABELS.ROLES[member.role])}
            </Comment>
          </ProfileHeaderContainer>
        </Box>
      </Dropdown.Section>

      <Dropdown.Section>
        <Dropdown.Row
          data-test="dropdown-current-organization"
          onClick={(event) => {
            event.stopPropagation();
            setDropdownCurrentPage('organization');
          }}
        >
          <H5>
            {selectedOrganizationsOrdered?.length &&
            selectedOrganizationsOrdered?.length <= 1
              ? t('profile-dropdown.organization-section.singular-title')
              : t('profile-dropdown.organization-section.plural-title')}
          </H5>
          <OrganizationRowContainer>
            {selectedOrganization && (
              <Flex alignItems="center">
                <Text
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  fontSize="sm"
                  pl={3}
                  pt={0.5}
                  maxWidth={40}
                >
                  {selectedOrganizationsOrdered?.join(', ')}
                </Text>
              </Flex>
            )}
            <Icon type="NEW_CHEVRON_RIGHT" color="PRIMARY_GRAY" size={20} />
          </OrganizationRowContainer>
        </Dropdown.Row>
      </Dropdown.Section>

      <Dropdown.Section>
        <Dropdown.Row onClick={logout}>
          <H5>{t('profile-dropdown.log-out-section.title')}</H5>
          <Icon type="NEW_LOG_OUT" color="PRIMARY_GRAY" size={20} />
        </Dropdown.Row>
      </Dropdown.Section>
    </>
  ));

  const OrganizationPage = React.memo(() => (
    <>
      <Dropdown.HeaderGridContainer>
        <ChevronLeftGridArea>
          <Dropdown.ChevronLeftButton
            justify="flex-start"
            onClick={(event) => {
              event.stopPropagation();
              setDropdownCurrentPage('main');
            }}
          />
        </ChevronLeftGridArea>
        <PageTitleGridArea>
          <H5>{t('profile-dropdown.header.title')}</H5>
        </PageTitleGridArea>
      </Dropdown.HeaderGridContainer>
      <OrganizationSection organizations={orderedOrganizations ?? []} />
      {(hasScopes(['organization:create']) ||
        hasScopes(['organization:read-sub'])) && (
        <Dropdown.Section>
          <>
            {hasScopes(['organization:create']) && (
              <Dropdown.Row
                onClick={() => {
                  setIsDropdownOpen(false);
                  setDropdownCurrentPage('main');
                  setCreateOrgVisible(true);
                }}
              >
                <H5>{t('profile-dropdown.new-organization-section.title')}</H5>
                <Icon type="NEW_ADD" color="PRIMARY_GRAY" size={20} />
              </Dropdown.Row>
            )}
            {hasScopes(['organization:read-sub']) && (
              <Dropdown.Row
                onClick={() => {
                  history.push('/sub-organizacoes');
                  setIsDropdownOpen(false);
                  setDropdownCurrentPage('main');
                }}
                clickable={false}
              >
                <Flex>
                  <Heading fontSize="sm" opacity={0.3}>
                    {t('profile-dropdown.sub-organization-section.title')}
                  </Heading>
                  <StyledTooltip
                    label={t(
                      'profile-dropdown.tooltip.inactivated-section-label'
                    )}
                    hasArrow
                  >
                    <WrapItem pointerEvents="visible" px={2}>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </WrapItem>
                  </StyledTooltip>
                </Flex>
                <WrapItem opacity={0.3}>
                  <Icon type="NEW_ORG" color="PRIMARY_GRAY" size={20} />
                </WrapItem>
              </Dropdown.Row>
            )}
            {hasScopes(['agreement:read']) && hasModule('ROAMING') && (
              <Dropdown.Row
                onClick={() => {
                  history.push('/roaming');
                  setIsDropdownOpen(false);
                  setDropdownCurrentPage('main');
                }}
                clickable={false}
              >
                <Flex>
                  <Heading fontSize="sm" opacity={0.3}>
                    {t('profile-dropdown.roaming-section.title')}
                  </Heading>
                  <StyledTooltip
                    label={t(
                      'profile-dropdown.tooltip.inactivated-section-label'
                    )}
                    hasArrow
                  >
                    <WrapItem pointerEvents="visible" px={2}>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </WrapItem>
                  </StyledTooltip>
                </Flex>
                <WrapItem opacity={0.3}>
                  <Icon type="NEW_ROAMING" color="PRIMARY_GRAY" size={20} />
                </WrapItem>
              </Dropdown.Row>
            )}
          </>
        </Dropdown.Section>
      )}

      {hasScopes(['member:read']) && (
        <Dropdown.Section>
          <>
            <Dropdown.Row
              onClick={() => {
                history.push('/membros');
                setIsDropdownOpen(false);
                setDropdownCurrentPage('main');
              }}
            >
              <H5>{t('profile-dropdown.manage-members-section.title')}</H5>
              <Icon type="NEW_GROUP" color="PRIMARY_GRAY" size={20} />
            </Dropdown.Row>
            {hasScopes(['member:invite']) && (
              <Dropdown.Row
                onClick={() => {
                  setIsDropdownOpen(false);
                  setDropdownCurrentPage('main');
                  setInviteMemberVisible(true);
                }}
              >
                <H5>{t('profile-dropdown.add-member-section.title')}</H5>

                <Icon type="NEW_ADD_MEMBER" color="PRIMARY_GRAY" size={20} />
              </Dropdown.Row>
            )}
          </>
        </Dropdown.Section>
      )}
    </>
  ));

  return (
    <>
      <CreateOrganizationModal
        show={createOrgVisible}
        onClose={handleCloseCreateOrg}
      />
      <InviteMemberModal
        close={handleCloseInviteMember}
        show={inviteMemberVisible}
        onClose={handleCloseInviteMember}
      />
      <MotionBox initial="hidden" animate="visible" variants={BoxAnimation}>
        <Dropdown
          isOpen={isDropdownOpen}
          toggleDropdown={setIsDropdownOpen}
          onClickOutside={() => setIsDropdownOpen(false)}
          containerStyle={{
            zIndex: '100',
          }}
          content={() => (
            <DropdownContainer>
              <>
                {dropdownCurrentPage === 'main' && <MainPage />}
                {dropdownCurrentPage === 'organization' && <OrganizationPage />}
              </>
            </DropdownContainer>
          )}
        >
          <ProfileButtonContainer
            data-test="profile-dropdown"
            active={isDropdownOpen}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <BodyTypography>{member?.profile.firstName}</BodyTypography>
            <Icon
              type="NEW_THREE_VERTICAL_DOTS"
              color="SECONDARY_GRAY"
              size={24}
            />
          </ProfileButtonContainer>
        </Dropdown>
      </MotionBox>
    </>
  );
});

export { ProfileDropdown };
