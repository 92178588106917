import styled from 'styled-components';

interface Props {
  height?: number;
}

const CardHeader = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ height = 61 }) => height}px;
`;

export default CardHeader;
