import { gql } from '@apollo/client';
import pricingConfigFieldsFragment from '../fragments/pricingConfigFields';

export default gql`
  query TableConnectorsPrice {
    stationsProfile(
      sort: { name: ASC }
      filter: { includesVisibility: [PRIVATE, PUBLIC] }
    ) {
      id
      minimumChargeFee {
        chargeFee
        emspId
      }
      cpo {
        roamingInfo {
          roamingId
        }
      }
      emsps {
        id
        roamingInfo {
          roamingId
        }
        sessionPaymentConfig {
          currencyType
        }
      }
      name
      lastConnectorPricingUpdatedAt
      connectors {
        id
        power
        type
        pricingConfig {
          chargeFee
          enabled
          emspId
          ...PricingConfigFields
        }
        stationProfile {
          id
        }
      }
    }
  }
  ${pricingConfigFieldsFragment}
`;
