import Config from 'config';
import AuthContext from 'contexts/Auth/context';
import { useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const useGATracking = () => {
  const location = useLocation();
  const { member } = useContext(AuthContext);

  useEffect(() => {
    ReactGA.initialize(Config.GA_TRACKING_ID);
    ReactGA.set({
      dimension1: member?.id,
      dimension2: member?.organization.id,
    });
    ReactGA.pageview(location.pathname + location.search);
    // lint is saying to add "user" here, just try to test
    // the behavior when addind the user dependency
    // eslint-disable-next-line
  }, [location]);
};

export default useGATracking;
