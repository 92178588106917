import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const NeochargeConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: false,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-7',
  CODE: 'neocharge',
  LABEL: 'NeoCharge',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#27AAE1',
    CLICKABLE: '#27AAE1',
    ERROR: '#EA5E5E',
    SIDEBAR: '#27AAE1',
    LOGIN_LOGO_BACKGROUND: '#27AAE1',

    RISE: '#34D18A',
    FALL: '#EA5E5E',
    NEUTRAL: '#27AAE1',

    CRITICAL: '#EA5E5E',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#34D18A',
    NoCost: '#F4F4F4',
    Error: '#EA5E5E',

    AVAILABLE: '#34D18A',
    CHARGING: '#27AAE1',
    INOPERATIVE: '#EA5E5E',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#EA5E5E',
    RESERVED: '#FF850B',

    PREPARING: '#27AAE1',
    FINISHING: '#27AAE1',

    SELECTED_DATE_BOUND_BACKGROUND: '#27AAE1',
    SELECTED_DATE_BOUND_BORDER: '#27AAE1',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#177BA5',

    DATE_PRE_SELECTED_BACKGROUND: '#C4E8F7',
    DATE_PRE_SELECTED_BORDER: '#81CDED',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#C4E8F7',
    DATE_PRE_SELECTED_COLOR: '#1A8BBB',

    DATE_SELECTED_BACKGROUND: '#27AAE1',
    DATE_SELECTED_BORDER: '#6AC4EA',
    DATE_SELECTED_HOVER_BACKGROUND: '#1A8BBB',

    CURRENT_DAY: '#1A8BBB',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#34D18A',
      Subsidized: '#34D18A',
      NoCost: '#F4F4F4',
      Error: '#EA5E5E',
      ErrorOnPreAuthorization: '#EA5E5E',
    },
    STATION_STATUS: {
      AVAILABLE: '#34D18A',
      CHARGING: '#27AAE1',
      INOPERATIVE: '#EA5E5E',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#EA5E5E',
      RESERVED: '#FF850B',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#34D18A',
      PREPARING: '#27AAE1',
      CHARGING: '#27AAE1',
      FINISHING: '#27AAE1',
      INOPERATIVE: '#EA5E5E',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#FF850B',
    },

    BILLING: '#34D18A',
    RECHARGES: '#27AAE1',
    AVAILABILITY: '#FF850B',

    BUTTON_AVAILABLE: '#27AAE1',
    BUTTON_UNAVAILABLE: '#939393',
    BUTTON_LOADING: '#1A8BBB',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#27AAE1',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#1A8BBB',
      SELECTED_DATE_BOUND_BORDER: '#27AAE1',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#177BA5',

      DATE_PRE_SELECTED_BACKGROUND: '#C4E8F7',
      DATE_PRE_SELECTED_BORDER: '#81CDED',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#C4E8F7',
      DATE_PRE_SELECTED_COLOR: '#1A8BBB',

      DATE_SELECTED_BACKGROUND: '#27AAE1',
      DATE_SELECTED_BORDER: '#6AC4EA',
      DATE_SELECTED_HOVER_BACKGROUND: '#1A8BBB',

      CURRENT_DAY: '#1A8BBB',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Campton-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'NeoCharge - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/neocharge',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#C4E8F7',
      '100': '#97D6F1',
      '200': '#81CDED',
      '300': '#6AC4EA',
      '400': '#54BCE7',
      '500': '#27AAE1',
      '600': '#1A8BBB',
      '700': '#177BA5',
      '800': '#146A8E',
      '900': '#0E4961',
    },
    LINK: {
      '50': '#C4E8F7',
      '100': '#97D6F1',
      '200': '#81CDED',
      '300': '#6AC4EA',
      '400': '#54BCE7',
      '500': '#27AAE1',
      '600': '#1A8BBB',
      '700': '#177BA5',
      '800': '#146A8E',
      '900': '#0E4961',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#EDADB2',
      '200': '#EA8F8F',
      '300': '#E08080',
      '400': '#E27171',
      '500': '#EA5E5E',
      '600': '#E84141',
      '700': '#BF3737',
      '800': '#9B1919',
      '900': '#660C0C',
    },
    SUCCESS: {
      '50': '#D3F4E0',
      '100': '#C5EDD6',
      '200': '#ACE5C6',
      '300': '#85E8B4',
      '400': '#6CE0A6',
      '500': '#34D18A',
      '600': '#29C280',
      '700': '#26AA6E',
      '800': '#208956',
      '900': '#175B36',
    },
    CHARGING: {
      '50': '#C4E8F7',
      '100': '#97D6F1',
      '200': '#81CDED',
      '300': '#6AC4EA',
      '400': '#54BCE7',
      '500': '#27AAE1',
      '600': '#1A8BBB',
      '700': '#177BA5',
      '800': '#146A8E',
      '900': '#0E4961',
    },
    AVAILABLE: {
      '50': '#D3F4E0',
      '100': '#C5EDD6',
      '200': '#ACE5C6',
      '300': '#85E8B4',
      '400': '#6CE0A6',
      '500': '#34D18A',
      '600': '#29C280',
      '700': '#26AA6E',
      '800': '#208956',
      '900': '#175B36',
    },
    SECONDARY: {
      '50': '#C4E8F7',
      '100': '#97D6F1',
      '200': '#81CDED',
      '300': '#6AC4EA',
      '400': '#54BCE7',
      '500': '#27AAE1',
      '600': '#1A8BBB',
      '700': '#177BA5',
      '800': '#146A8E',
      '900': '#0E4961',
    },
    RESERVE: {
      '50': '#FFDEBE',
      '100': '#FFC58B',
      '200': '#FFB871',
      '300': '#FFAB58',
      '400': '#FF9F3E',
      '500': '#FF850B',
      '600': '#D76C00',
      '700': '#BE5F00',
      '800': '#A45200',
      '900': '#713900',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#EDADB2',
      '200': '#EA8F8F',
      '300': '#E08080',
      '400': '#E27171',
      '500': '#EA5E5E',
      '600': '#E84141',
      '700': '#BF3737',
      '800': '#9B1919',
      '900': '#660C0C',
    },
  },
};
export default NeochargeConfig;
