import { VoucherFilterInput } from 'generated/graphql';
import { createContext } from 'react';

export type ListVoucherEventsContextType = {
  updateParsedVoucherTableFilters: (args: Partial<VoucherFilterInput>) => void;
  parsedVoucherTableFilters: Partial<VoucherFilterInput>;
  updateRequestedVoucherName: (value: string) => void;
  requestedVoucherName: string;
};

export const initialListVouchersEventsContextValue: ListVoucherEventsContextType =
  {
    updateParsedVoucherTableFilters: () => {},
    parsedVoucherTableFilters: {},
    updateRequestedVoucherName: () => {},
    requestedVoucherName: '',
  };

export const ListVoucherEventsContext = createContext<{
  getEvents: () => ListVoucherEventsContextType;
}>({ getEvents: () => initialListVouchersEventsContextValue });
