import { gql } from '@apollo/client';

export default gql`
  query VehicleModal($where: VehicleWhereUniqueInput!) {
    vehicle(where: $where) {
      id
      plate
      emspId
      carModel {
        label
      }
      chargingCards {
        id
        hexId
      }
    }
  }
`;
