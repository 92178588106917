import React, { useEffect, useState, useRef } from 'react';
import JSONEditor, { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

import { EditorContainer } from './styles';

type Props = JSONEditorOptions & { data?: any };

const JSONInterface: React.FC<Props> = ({
  data,
  schema,
  mainMenuBar = false,
  mode = 'code',
  navigationBar = false,
  ...jsoneditorProps
}) => {
  const container = useRef<HTMLDivElement>(null);

  const [jsoneditor, setJsoneditor] = useState<JSONEditor | undefined>(
    undefined
  );

  useEffect(() => {
    if (container.current) {
      setJsoneditor(
        new JSONEditor(
          container.current,
          {
            ...jsoneditorProps,
            mainMenuBar,
            mode,
            navigationBar,
          },
          data
        )
      );
    }
    // eslint-disable-next-line
  }, [container]);

  useEffect(() => {
    if (schema) jsoneditor?.setSchema(schema);
  }, [schema, jsoneditor]);

  useEffect(() => {
    if (data) jsoneditor?.set(data);
  }, [data, jsoneditor]);

  return <EditorContainer ref={container} />;
};

export default JSONInterface;
