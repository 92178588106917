import AuthContext from 'contexts/Auth/context';
import { useContext } from 'react';
import { OrganizationDefaultCurrencyType } from 'types';

export default function useDefaultCurrencyTypes(): OrganizationDefaultCurrencyType[] {
  const { organizations } = useContext(AuthContext);

  return organizations
    ? organizations?.map((org) => ({
        orgId: org.id,
        currencyType: org.emsp?.sessionPaymentConfig?.currencyType,
      }))
    : [];
}
