import React, { useState } from 'react';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import { H5 } from 'new-components/Typographies/styles';
import useHasScopes from 'hooks/useHasScope';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ActionButtonContainer,
  DropdownContainer,
} from '../RechargeModal/components/SessionOptionsDropdown/styles';

type Props = {
  onDeleteGroup: () => void;
};
// @ts-ignore
const GroupOptionsDropdown: React.FC<Props> = ({ onDeleteGroup }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const hasScopes = useHasScopes();

  const handleDeleteClick = () => {
    setIsOpen(false);
    onDeleteGroup();
  };

  if (!hasScopes(['group:edit'] || !hasScopes(['group:create']))) {
    return false;
  }

  return (
    <Container>
      <Dropdown
        isOpen={isOpen}
        toggleDropdown={setIsOpen}
        onClickOutside={() => setIsOpen(false)}
        isBeingUsedInsideModal={true}
        content={() => (
          <DropdownContainer>
            <>
              {hasScopes(['group:edit', 'group:create']) && (
                <Dropdown.Section>
                  <Dropdown.Row onClick={handleDeleteClick}>
                    <H5 color={'ERROR'}>
                      {t('groups-options-dropdown.delete.row.title')}
                    </H5>
                    <Icon type="NEW_TRASH" color={'ERROR'} size={16} />
                  </Dropdown.Row>
                </Dropdown.Section>
              )}
            </>
          </DropdownContainer>
        )}
      >
        <ActionButtonContainer
          active={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon
            type="NEW_THREE_HORIZONTAL_DOTS"
            color="PRIMARY_GRAY"
            size={24}
          />
        </ActionButtonContainer>
      </Dropdown>
    </Container>
  );
};

export default GroupOptionsDropdown;
