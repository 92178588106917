import Config from 'config';
import { Coordinates } from 'generated/graphql';
import GoogleMapReact, { MapTypeStyle } from 'google-map-react';
import React from 'react';
import { Container } from './styles';

type Props = {
  center?: Coordinates;
  mapStyle?: MapTypeStyle[];
  /** By default the scroll wheel is enabled. This prop have been used by the StationPreview component to allow scrolling inside de "popup" */
  scrollWheelEnabled?: boolean;
  coordinatesOfAllElements: Coordinates[];
  onClick?: () => void;
  onDrag?: () => void;
};

const Map: React.FC<Props> = (props) => {
  const {
    children,
    center,
    mapStyle,
    scrollWheelEnabled = true,
    coordinatesOfAllElements,
    onClick,
    onDrag,
  } = props;
  // useTraceUpdate(props, 'componentMap');
  const setMapBoundaries = (map: google.maps.Map | null) => {
    if (!map) return;

    const bounds = new google.maps.LatLngBounds();
    coordinatesOfAllElements.forEach((element) =>
      bounds.extend(
        new google.maps.LatLng({
          lat: element.latitude,
          lng: element.longitude,
        })
      )
    );

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
  };

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Config.API.GOOGLE_MAPS.KEY }}
        defaultCenter={Config.MAP.INITIAL_REGION}
        defaultZoom={3}
        yesIWantToUseGoogleMapApiInternals
        center={center && { lat: center.latitude, lng: center.longitude }}
        onGoogleApiLoaded={({ map }) => setMapBoundaries(map)}
        options={() => ({
          disableDefaultUI: true,
          zoomControl: true,
          styles: mapStyle,
          scrollwheel: scrollWheelEnabled,
        })}
        onClick={onClick}
        onDrag={onDrag}
      >
        {children}
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
