import Typography from 'components/Typography';
import Config from 'config';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 7.5px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  & > *:active {
    cursor: move;
`;

export const ImageContainer = styled.li`
  width: calc(50% - 22.5px);
  height: 125px;
  border: 3px dashed ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  border-radius: 10px;
  margin: 7.5px;
  position: relative;
  display: inline-block;
  list-style-type: none;
  :hover {
    filter: brightness(0.8);
  }
`;

export const PreviewImage = styled.img`
  border-radius: 10px;
  display: block;
  max-height: 100%;
  width: calc(100% + 8px);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 250px;
`;

export const ImageListContainer = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
  & > *:after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    right: -5px;
    left: -5px;
    cursor: move;
  }
`;

export const CloseButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => `${theme.COLORS.PRIMARY_GRAY}80`};
  border-radius: 6px;
  display: flex;
  height: 24px;
  z-index: 1;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 24px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => `${theme.COLORS.PRIMARY_GRAY}64`};
  }
`;

export const CloseIcon = styled(Config.ICONS.X)`
  color: ${({ theme }) => `${theme.COLORS.WHITE}`};
  height: 10px;
  width: 10px;
`;

export const FirstImageText = styled(Typography).attrs({
  size: 12,
  color: 'WHITE',
})``;

export const FirstImageCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => `${theme.COLORS.PRIMARY_GRAY}`};
  border-radius: 5px;
  display: flex;
  height: 24px;
  justify-content: center;
  left: calc(50% - 30px);
  opacity: 0.8;
  position: absolute;
  right: calc(50% - 30px);
  width: 60px;
`;
