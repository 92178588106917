import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (value: string) => void;
};

export const EmailInput = ({ onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl mt={3}>
      <FormLabel fontSize="sm">
        {t('account-exclusion.email-form-label')}
      </FormLabel>
      <Input
        onChange={(e) => onChange(e.target.value)}
        placeholder={t('account-exclusion-form.email-input-placeholder')}
      />
    </FormControl>
  );
};
