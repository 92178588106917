import styled from 'styled-components';
import { H3 } from '../../../Typographies/styles';
import { Card } from '../Card/styles';

export const Container = styled(Card)`
  flex-flow: column nowrap;
  gap: 16px;
`;

export const Title = styled(H3)``;
