import { gql } from '@apollo/client';

export default gql`
  query GetVoucher($where: VoucherWhereInput!) {
    voucher(where: $where) {
      id
      name
      description
      status
      variableDiscount
      usageLimit
      isUnlimited
      emspId
      createdAt
      startsAt
      expiresAt
      createdByMember {
        id
        profile {
          email
        }
      }
      drivers {
        id
        profile {
          firstName
          lastName
          email
        }
      }
      stations {
        id
        name
        address {
          city
        }
      }
    }
  }
`;
