import { gql } from '@apollo/client';

export default gql`
  query SessionsTimeline($input: PerDateTimeInput!) {
    sessionsSummaryPerDateTime(
      input: $input
      filter: { includesStatus: [CHARGING, WAITING_UNPLUG, FINISHED] }
    ) {
      from
      sessionsSummary {
        count
        energyConsumed
        timeCharging
      }
    }
  }
`;
