import React from 'react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import InnerCard from './InnerCard/index';

export type StartedFinishedCardProps = {
  date: string;
  hour: string;
};

type StartedFinishedProps = {
  startInfo: StartedFinishedCardProps;
  finishInfo: StartedFinishedCardProps;
};

const StartedFinished: React.FC<StartedFinishedProps> = ({
  startInfo,
  finishInfo,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <InnerCard
        title={t('recharge-modal.dutation-card.begin.title')}
        {...startInfo}
      />
      <Icon type="NEW_CHEVRON_RIGHT" color="SECONDARY_GRAY" size={64}></Icon>
      <InnerCard
        title={t('recharge-modal.dutation-card.end.title')}
        {...finishInfo}
      />
    </Container>
  );
};

export default StartedFinished;
