import styled from 'styled-components';
import { padding, radius } from 'theme/selectors';

export const SessionTableContainer = styled.div`
  height: 700px;
  margin-bottom: 47px;
  overflow-y: auto;
  border: 1px solid
    ${({
      theme: {
        COLORS: { TERTIARY_GRAY },
      },
    }) => TERTIARY_GRAY};
  border-radius: ${radius('RADIUS-8')};
`;

export const Container = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  padding: ${padding('PADDING-16')};
`;
