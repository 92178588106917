import { gql } from '@apollo/client';

export default gql`
  query StationEvents(
    $where: StationWhereUniqueInput!
    $limit: Int
    $offset: Int
  ) {
    station(where: $where) {
      id
      events(sort: { streamVersion: DESC }, limit: $limit, offset: $offset) {
        streamId
        streamVersion
        type
        timestamp
        data
      }
      eventsSummary {
        count
      }
    }
  }
`;
