import Config from 'config';
import { StationStatus } from 'generated/graphql';
import React from 'react';
import { MarkerContainer } from './styles';

interface MarkerProps {
  status: StationStatus;
  lat: number; // used only for google-react-map
  lng: number; // used only for google-react-map
  onClick: () => void;
}

const Marker: React.FC<MarkerProps> = ({ status, onClick }) => (
  <MarkerContainer onClick={onClick}>
    <Config.ICONS.MARKER color={Config.COLORS.STATION_STATUS[status]} />
  </MarkerContainer>
);
export default Marker;
