import { gql } from '@apollo/client';

export default gql`
  mutation UpdateMember(
    $data: UpdateMemberInput!
    $where: MemberWhereUniqueInput!
  ) {
    updateMember(data: $data, where: $where)
  }
`;
