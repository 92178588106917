import { gql } from '@apollo/client';

export default gql`
  mutation AllowStationToBePubliclyAccessedViaOCPP(
    $where: SelectStationInput!
    $allow: Boolean!
  ) {
    allowStationToBePubliclyAccessedViaOCPP(where: $where, allow: $allow)
  }
`;
