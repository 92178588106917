import { ConnectorsListFieldsFragment } from 'generated/graphql';
import React from 'react';
import Popover from 'react-tiny-popover';
import ConnectorInfo from './ConnectorInfo';
import ConnectorStatus from '../ConnectorStatus';
import { Content } from './styles';

type Connector = Omit<ConnectorsListFieldsFragment, 'energyMeasurements'>;

type Props = {
  connector: ConnectorsListFieldsFragment;
  selectedConnector?: ConnectorsListFieldsFragment;
  stationId?: string;
  closePopover: () => void;
  connectorId: number;
  selectConnectorControl: (connector: Pick<Connector, 'id'>) => void;
  getConnectorOpenState: (
    connector: Pick<Connector, 'id'>
  ) => 'NONE' | 'ACTIVE' | 'INACTIVE';
};

const ConnectorPopover: React.FC<Props> = ({
  connector,
  selectedConnector,
  selectConnectorControl,
  getConnectorOpenState,
  closePopover,
  stationId,
  connectorId,
}) => {
  const showPopover = connector.id === selectedConnector?.id;
  return (
    <Popover
      content={
        (selectedConnector && (
          <ConnectorInfo
            connector={connector}
            stationId={stationId}
            connectorId={connectorId}
          />
        ))!
      }
      onClickOutside={closePopover}
      isOpen={showPopover}
      position="bottom"
      padding={8}
      windowBorderPadding={0}
      transitionDuration={0}
      align="start"
      containerStyle={{ overflow: 'initial', zIndex: '2000' }}
    >
      <Content>
        <ConnectorStatus
          openState={getConnectorOpenState(connector)}
          key={connector.id}
          connector={connector}
          onClick={(connector) => selectConnectorControl(connector)}
        />
      </Content>
    </Popover>
  );
};

export default ConnectorPopover;
