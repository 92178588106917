import { useMediaQuery as ChakraUseMediaQuery } from '@chakra-ui/react';

/**
 * Hook to obtain the state of a media query for the page.
 *
 * This hook returns an object containing boolean properties indicating whether the screen meets certain width conditions.
 *
 * The idea is to standardize the use of media queries in this hook for CSS-in-JS components.
 *
 * @example
 * const { isMediaLargerThan800 } = useMediaQuery();
 *
 * @returns { isMediaLargerThan800 } An object containing boolean properties indicating the state of the media queries.
 */

export const useMediaQuery = () => {
  const [isMediaLargerThan800] = ChakraUseMediaQuery('(min-width: 800px)');

  return {
    isMediaLargerThan800,
  };
};
