import Config from 'config';
import { StationStatus } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlarmInfo,
  AlarmInfoContainer,
  AlarmText,
  MarkerContainer,
} from './styles';

interface MarkerProps {
  status: StationStatus;
  stationAlarms: number;
  lat: number; // used only for google-react-map
  lng: number; // used only for google-react-map
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const Marker: React.FC<MarkerProps> = ({ status, stationAlarms, onClick }) => {
  const { t } = useTranslation();
  const renderMarkerInfos = () => {
    const stationHasMoreThanTenAlarms = stationAlarms / 10 > 1;
    const stationHasMoreThanHundredAlarms = stationAlarms / 100 > 1;
    const alarms = stationHasMoreThanHundredAlarms
      ? t('map.marker.more-than-hundred-alarms.title')
      : stationAlarms;

    if (stationAlarms)
      return (
        <AlarmInfoContainer>
          <AlarmText
            hasMoreThanTenAlarms={stationHasMoreThanTenAlarms}
            hasMoreThanHundredAlarms={stationHasMoreThanHundredAlarms}
          >
            {alarms}
          </AlarmText>
          <AlarmInfo />
        </AlarmInfoContainer>
      );
  };

  return (
    <MarkerContainer
      onClick={(e: React.MouseEvent<HTMLInputElement>) => onClick(e)}
    >
      <Config.ICONS.MARKER color={Config.COLORS.STATION_STATUS[status]} />
      {renderMarkerInfos()}
    </MarkerContainer>
  );
};
export { Marker };
