import moment from 'moment';
import { CreateVoucherEventsContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/events-context';
import { useContext, useEffect, useState } from 'react';

export const useCreateVoucherRulesValidityPeriod = () => {
  const events = useContext(CreateVoucherEventsContext).getEvents();

  const [validityPeriod, setValidityPeriod] = useState<{
    from: Date | null;
    to: Date | null;
    isUnexpirable: boolean;
  }>({ from: null, to: null, isUnexpirable: false });
  const onPersonalizedDateSelection = (
    from: moment.Moment | null,
    to: moment.Moment | null
  ) => {
    setValidityPeriod({
      from: from ? from.toDate() : null,
      to: to ? to.toDate() : null,
      isUnexpirable: false,
    });
  };

  const toggleVoucherIsUnexpirable = () => {
    const { isUnexpirable } = validityPeriod;
    setValidityPeriod({
      from: null,
      to: null,
      isUnexpirable: !isUnexpirable,
    });
  };

  const onPreDefinedValidityPeriodButtonClick = (
    from: Date | null,
    to: Date | null
  ) => {
    setValidityPeriod({
      from,
      to,
      isUnexpirable: false,
    });
  };

  useEffect(() => {
    events.updateVoucherValidityPeriod({
      from: validityPeriod.from,
      to: validityPeriod.to,
      isUnexpirable: validityPeriod.isUnexpirable,
    });
    // eslint-disable-next-line
  }, [validityPeriod]);

  return {
    validityPeriod,
    onPersonalizedDateSelection,
    toggleVoucherIsUnexpirable,
    onPreDefinedValidityPeriodButtonClick,
  };
};
