import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FromDateUntilDate } from 'services/timeRange';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth/context';
import { ButtonContainer, Container } from './styles';

type Props = {
  onSelectDate: (
    start: moment.Moment | null,
    end: moment.Moment | null
  ) => void;
  isVisible: boolean;
  closeCalendar?: () => void;
  defaultValue?: FromDateUntilDate;
};

const Calendar: React.FC<Props> = ({
  onSelectDate,
  isVisible,
  closeCalendar,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const { member } = useContext(AuthContext);
  const lng = (member?.language ?? 'pt_BR').replace('_', '-');

  const defaultProps = {
    startDatePlaceholderText: t('date-dropdown.start-date-placeholder-text'),
    endDatePlaceholderText: t('date-dropdown.end-date-placeholder-text'),
    startDateId: 'start_unique_id',
    endDateId: 'end_unique_id',
    showClearDates: true,
    keepOpenOnDateSelect: true,
    reopenPickerOnClearDates: true,
    hideKeyboardShortcutsPanel: true,
    minimumNights: 0,
  };
  const defaultFrom = defaultValue?.from;
  const defaultTo = defaultValue?.to;
  const defaultSelectedDate = defaultFrom
    ? {
        startDate: defaultFrom as moment.Moment,
        endDate: defaultTo as moment.Moment,
      }
    : { startDate: null, endDate: null };

  const [selectedDate, setSelectedDate] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>(defaultSelectedDate);
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    'startDate'
  );

  const { startDate, endDate } = selectedDate;
  const hasDateSelected = startDate && endDate;

  const handleApplyButton = () => {
    onSelectDate(startDate, endDate);
    closeCalendar?.();
  };

  moment.updateLocale(lng, {
    // The letters below have space in them to avoid the error of keys with the same name
    weekdaysMin: ['D', 'S', 'T', 'Q', 'Q ', 'S ', 'S  '],
  });
  moment.locale(member?.language ?? 'pt_BR');

  return (
    <Container isVisible={isVisible}>
      <DateRangePicker
        {...defaultProps}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={setSelectedDate}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          // don't apply if it's null (clicked outside) => this is to prevent calendar being closed on clicked outside
          if (focusedInput) setFocusedInput(focusedInput);
        }}
        numberOfMonths={2}
        maxDate={moment()}
        // this is to prevent select any date after current day
        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        initialVisibleMonth={() => moment().subtract(1, 'month')}
      />
      <ButtonContainer>
        <Button
          mt={2}
          w="100%"
          size="sm"
          variant="link"
          color="gray.900"
          onClick={() => {
            closeCalendar?.();
          }}
        >
          {t('date-dropdown.footer.cancel-btn.title')}
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button
          data-test="data-apply"
          w="100%"
          size="sm"
          isDisabled={!hasDateSelected}
          onClick={handleApplyButton}
        >
          {t('date-dropdown.footer.apply-filter-btn.title')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Calendar;
