import { gql } from '@apollo/client';

export default gql`
  fragment SessionRowFields on Session {
    id
    emspId
    consumerLabel
    energyConsumed
    duration
    edited
    createdAt
    startedChargingAt
    finishedAt
    status
    order {
      orderId
      capturedAt
      totalCost
      netValue
      currencyType
    }
  }
`;
