import { gql } from '@apollo/client';

export default gql`
  query StationsMonitoring(
    $includeAlarms: Boolean!
    $filter: StationFilterInput
  ) {
    stations(sort: { name: ASC }, filter: $filter) {
      id
      chargePointId
      name
      status
      tag
      cpoId
      lastConnectedAt
      alarmsSummary(filter: { level: CRITICAL, resolved: false })
        @include(if: $includeAlarms) {
        count
      }
      connectors {
        id
        status
        power
        type
        currentType

        errorCode
      }
      coordinates {
        latitude
        longitude
      }
    }
  }
`;
