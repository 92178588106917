import React from 'react';
import NavLink from './NavLink';
import { Container } from './styles';

type Props = {
  children: React.ReactNode;
};

const NavBar: React.FC<Props> = ({ children }) => (
  <Container>{children}</Container>
);

export default Object.assign(NavBar, {
  NavLink,
});
