import styled, { css } from 'styled-components';
import { CardType } from '.';

type ContainerProps = {
  type: CardType;
  centerWhen: boolean;
  minHeight?: number;
  maxHeight?: number;
  padded?: number;
  noCardPadding?: boolean;
  wrap?: boolean;
};

const cardPadding = (props: {
  type: 'simple' | 'normal' | 'complex' | 'half';
  padded?: number;
  noCardPadding?: boolean;
}): string => {
  const nonSimpleTop = props.padded ?? 0;
  if (props.noCardPadding) return '0px 0px 20px';
  return {
    simple: '20px',
    normal: `${nonSimpleTop}px 20px 20px`,
    complex: `${nonSimpleTop}px 20px 20px`,
    half: `${nonSimpleTop}px 20px 20px`,
  }[props.type];
};

export const Error = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.INFO_GRAY};
  &:before {
    content: '✖';
    color: ${({ theme }) => theme.COLORS.INFO_GRAY};
    font-size: x-large;
  }
  border-radius: 15px;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: white;
  box-shadow: 0px 2px 6px #0000000a;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ minHeight = 0 }) => `${minHeight}px`};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'none')};
  padding: ${cardPadding};
  justify-content: ${({ centerWhen }) =>
    centerWhen ? 'center' : 'flex-start'};
  border-radius: 15px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b7b7b7;
    font-size: 15px;
  }

  ${({ type }) => {
    switch (type) {
      case 'complex':
        return css`
        min-width: 1005px
        height: 100%;
      `;
      case 'half':
        return 'width: calc(50% - 12.5px);';
      case 'normal':
        return 'width: calc(66.66% - 6.66px);';
      case 'simple':
        return 'width: calc(33.33% - 16.66px);';
    }
  }}

  ${({ wrap }) => {
    if (wrap) {
      return css`
        flex-wrap: wrap;
        flex-direction: row;
        gap: 10px;
      `;
    }
    return '';
  }}
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  display: flex;
  justify-content: center;
  border-radius: 15px;
`;
