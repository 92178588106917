import React, { useState } from 'react';
import DriverModal from 'new-components/DriverModal';
import RechargeModal from 'new-components/RechargeModal';
import useHasScopes from 'hooks/useHasScope';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { Props as BaseProps, Consumer } from '.';
import UnlockMethod from './UnlockMethod';

type Props = BaseProps & {
  consumer: Extract<Consumer, { __typename: 'Driver' }>;
  consumerLabel: string;
  emspId: string;
};

const DriverCard: React.FC<Props> = ({
  consumerId,
  consumer,
  unlockMethod,
  consumerLabel,
  emspId,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [rechargeModal, setRechargeModal] = useState<{
    show: boolean;
    sessionId: string;
    cpoId: string;
    emspId: string;
  }>({ show: false, sessionId: '', cpoId: '', emspId: '' });

  const handleSessionRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) => {
    if (!hasScopes(['driver:read']) || !sessionId) return;
    setRechargeModal({
      show: true,
      sessionId,
      cpoId: cpoId || '',
      emspId: emspId || '',
    });
  };

  const handleRechargeModalClose = () =>
    setRechargeModal({ show: false, sessionId: '', cpoId: '', emspId: '' });

  return (
    <>
      {hasScopes(['driver:read']) && isDriverModalOpen && (
        <DriverModal
          id={consumerId}
          handleSessionRowClick={handleSessionRowClick}
          show={isDriverModalOpen}
          onClose={() => setIsDriverModalOpen(false)}
        />
      )}
      <RechargeModal
        onClose={handleRechargeModalClose}
        sessionId={rechargeModal.sessionId}
        show={rechargeModal.show}
        cpoId={rechargeModal.cpoId}
        emspId={rechargeModal.emspId}
      />
      <Card
        title={t('recharge-modal.consumer-card.title')}
        boldText={consumerLabel}
        bodyText1={consumer.profile?.firstName}
        bodyText2={consumer.profile?.lastName}
        bodyText3={consumer.profile?.email}
        footer={<UnlockMethod unlockMethod={unlockMethod} />}
        clickable={hasScopes(['driver:read'])}
        emspId={emspId}
        onClick={() => hasScopes(['driver:read']) && setIsDriverModalOpen(true)}
      />
    </>
  );
};

export default DriverCard;
