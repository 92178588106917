import { Flex } from '@chakra-ui/react';
import Search from 'atomic-components/molecules/Search';
import Filter from 'components/Filter';
import { FilterInstance } from 'types';
import { useTranslation } from 'react-i18next';
import { FilterContainer } from './style';
import { VoucherFilterKeys, useVoucherTableFilters } from './service';

export function VoucherTableFilter() {
  const {
    appliedFilterTerms,
    setAppliedFilterTerms,
    requestedVoucherName,
    setRequestedVoucherName,
    voucherTableFilterVisible,
    toggleVoucherFilterVisibility,
  } = useVoucherTableFilters();

  const { t } = useTranslation();

  const filterSettings: FilterInstance<VoucherFilterKeys>[] = [
    {
      inputType: 'text',
      type: 'voucherNames',
      placeholder: t(
        'payments.voucher-tab.table-header-filter-opt-name.placeholder'
      ),
      label: t('payments.voucher-tab.table-header-filter-opt-name.title'),
    },
    {
      inputType: 'text',
      type: 'createdByMembers',
      placeholder: t(
        'payments.voucher-tab.table-header-filter-opt-created-by.placeholder'
      ),
      label: t('payments.voucher-tab.table-header-filter-opt-created-by.title'),
    },
    {
      inputType: 'checkbox',
      boxes: [
        {
          label: t('payments.voucher-tab.status-activated.title'),
          value: 'available',
        },
        {
          label: t('payments.voucher-tab.status-expired.title'),
          value: 'expired',
        },
        {
          label: t('payments.voucher-tab.status-scheduled.title'),
          value: 'scheduled',
        },
        {
          label: t('payments.voucher-tab.status-disabled.title'),
          value: 'disabled',
        },
      ],
      label: t('payments.voucher-tab.table-header-filter-opt-status.title'),
      type: 'status',
    },
  ];

  return (
    <Flex mr={6}>
      <Flex mr={6}>
        <Search
          setSearchValue={setRequestedVoucherName}
          searchValue={requestedVoucherName}
          placeholder={t(
            'payments.voucher-tab.table-header-filter-voucher.placeholder'
          )}
        />
      </Flex>
      <FilterContainer>
        <Filter
          filters={filterSettings}
          toggleFilterPanel={toggleVoucherFilterVisibility}
          active={voucherTableFilterVisible}
          setAppliedFilterTerms={setAppliedFilterTerms}
          appliedFilterTerms={appliedFilterTerms}
        />
      </FilterContainer>
    </Flex>
  );
}
