import { DotsWrapper } from 'components/StationLink/styles';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-bottom: 25px;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 25px;

  & > :not(:first-child) {
    margin-left: 25px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: none;
  flex-flow: column;
  width: 100%;

  ${DotsWrapper} {
    &:hover {
      background-color: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
    }
    &:active {
      background-color: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
    }
  }
`;
