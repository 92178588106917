import { gql } from '@apollo/client';

export default gql`
  query StationConnectorsCurrentSession(
    $includeConnectorsCurrentSession: Boolean!
    $where: StationWhereUniqueInput!
  ) {
    station(where: $where) {
      id
      connectors {
        id
        currentSession @include(if: $includeConnectorsCurrentSession) {
          id
          emspId
          energyConsumed
          duration
          order {
            orderId
            totalCost
            currencyType
          }
          energyMeasurements {
            timestamp
            value
          }
        }
      }
    }
  }
`;
