import {
  Input,
  FormLabel,
  InputGroup,
  FormControl,
  InputRightElement,
} from '@chakra-ui/react';
import { IconPassword } from 'components/Input/styles';
import { useTranslation } from 'react-i18next';

type Props = {
  showPassword: boolean;
  setShowPassword: () => void;
  onChange: (value: string) => void;
};

export const PasswordInput = ({
  onChange,
  showPassword,
  setShowPassword,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl FormControl mt={4}>
      <FormLabel fontSize="sm">
        {t('account-exclusion.password-form-label')}
      </FormLabel>
      <InputGroup>
        <Input
          placeholder="********"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => onChange(e.target.value)}
        />
        <InputRightElement
          children={
            <IconPassword
              onClick={() => setShowPassword()}
              show={showPassword}
            />
          }
        />
      </InputGroup>
    </FormControl>
  );
};
