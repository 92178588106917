import { VoucherClientContext } from 'pages/Payments/Voucher/context/client-context';
import { useContext, useEffect, useState } from 'react';
import { CreateVoucherEventsContext } from '../../../context/events-context';
import { CreateVoucherStatesContext } from '../../../context/state-context';

export const useCreateVoucherDriversSelection = () => {
  const client = useContext(VoucherClientContext).getClient()();
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const {
    createVoucherData: { organizationId },
  } = useContext(CreateVoucherStatesContext);

  const [searchValue, setSearchValue] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(25);

  const [driversSelection, setDriversSelection] = useState<{
    allDriversSelected: boolean;
    allDriversSelectedExcept: number[];
    selectedDrivers: number[];
  }>({
    allDriversSelected: false,
    allDriversSelectedExcept: [],
    selectedDrivers: [],
  });

  const {
    data,
    error: getDriversResultError,
    loading: getDriversResultLoading,
  } = client.getDrivers({
    organizationId: organizationId ?? '',
    pageCount,
    pageIndex,
    searchValue,
  });

  useEffect(() => {
    events.updateVoucherDriversSelection(driversSelection);
    // eslint-disable-next-line
  }, [driversSelection]);

  return {
    data,
    driversSelection,
    getDriversResultError,
    getDriversResultLoading,
    pageCount,
    pageIndex,
    searchValue,
    setDriversSelection,
    setPageCount,
    setPageIndex,
    setSearchValue,
  };
};
