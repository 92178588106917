import React, { useContext } from 'react';
import Config from 'config';
import { useHistory } from 'react-router-dom';

import { StationStatus } from 'generated/graphql';
import StationStatusIndicator from 'new-components/StationStatusIndicator';
import { H5 } from 'new-components/Typographies/styles';
import AuthContext from 'contexts/Auth/context';
import { useTranslation } from 'react-i18next';
import { Heading } from '@chakra-ui/react';
import {
  Container,
  ContentGroup,
  Title,
  StationName,
  StationAddress,
  StationAddressItem,
  ConnectorInfo,
  ConnectorTitle,
  Type,
  TypeName,
  CurrentType,
  Power,
} from './styles';

type ConnectorsTypesIcons = typeof Config['ICONS']['CONNECTORS'];

type Props = {
  station: {
    id?: string;
    name?: string;
    street?: string;
    streetNumber?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    status?: StationStatus;
    cpoId?: string;
  };
  connector?: {
    type: {
      icon?: keyof ConnectorsTypesIcons;
      name?: string;
    };
    currentType?: 'AC' | 'DC';
    power?: string;
  };
  cpoId: string;
};

const StationAndConnector: React.FC<Props> = ({
  station,
  connector,
  cpoId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const ConnectorTypeIcon = connector?.type.icon
    ? Config.ICONS.CONNECTORS[connector?.type.icon]
    : React.Fragment;
  const { member } = useContext(AuthContext);
  const isCPO = station.cpoId === member?.organizationId;

  return (
    <Container
      data-test="station-card"
      clickable={isCPO}
      onClick={() => isCPO && history.push(`meus-eletropostos/${station.id}`)}
    >
      <ContentGroup>
        <Title>
          {t('recharge-modal.consumer-card.station-and-connector.title')}
        </Title>
        <StationName>
          {station.id ? <H5>{station.name}</H5> : <H5>{station.name}</H5>}
          {station.status && <StationStatusIndicator status={station.status} />}
        </StationName>
        <Heading fontSize={'14px'}>CPO : {cpoId}</Heading>
        <StationAddress>
          <StationAddressItem>
            {station.street} {station.streetNumber}
          </StationAddressItem>
          <StationAddressItem>
            {station.city || '-'}, {station.state || '-'}
          </StationAddressItem>
          <StationAddressItem>{station.postalCode || '-'}</StationAddressItem>
        </StationAddress>
      </ContentGroup>
      {connector && (
        <ContentGroup>
          <ConnectorInfo>
            <ConnectorTitle>
              {t('recharge-modal.connector-info.title')}
            </ConnectorTitle>
            <Type>
              {connector.type.icon ? (
                <ConnectorTypeIcon
                  style={{ maxHeight: '16px', width: '16px' }}
                />
              ) : null}
              <TypeName>{connector.type.name || '-'}</TypeName>
            </Type>
            <CurrentType>{connector.currentType || '-'}</CurrentType>
            <Power>{connector.power || '-'}</Power>
          </ConnectorInfo>
        </ContentGroup>
      )}
    </Container>
  );
};
export default StationAndConnector;
