import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import config from './config';

const {
  META_DATA: { TITLE, DESCRIPTION, PUBLIC_PATH },
} = config;

const Head = () => {
  const [isProduction, setIsProduction] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href.includes('dashboard.voltbras.com.br') &&
      !isProduction
    ) {
      setIsProduction(true);
    }
  }, [isProduction]);

  return (
    <Helmet>
      <title>{TITLE}</title>

      <meta name="description" content={DESCRIPTION} />
      <link rel="manifest" href={`${PUBLIC_PATH}/manifest.json`} />
      <link rel="shortcut icon" href={`${PUBLIC_PATH}/favicon.ico`} />
      <link
        rel="apple-touch-icon"
        href={`${PUBLIC_PATH}/apple-touch-icon.png`}
      />
      {isProduction && (
        <meta
          http-equiv="Content-Security-Policy"
          content="default-src 'self' *.voltbras.com.br voltbras.zendesk.com *.google-analytics.com *.googleapis.com *.zdassets.com *.googletagmanager.com fonts.gstatic.com *.google.com *.gstatic.com;
                                                        style-src 'unsafe-inline' *.googleapis.com;
                                                        img-src *.googleapis.com *.google-analytics.com *.gstatic.com 'self' data:"
        />
      )}
    </Helmet>
  );
};

export default Head;
