import Loader from 'components/Loader';
import Typography from 'components/Typography';
import Config from 'config';
import ConfigType from 'config/typing';
import React from 'react';
import { ButtonContainer } from './styles';

export type ButtonVisual = 'primary' | 'secondary';

export type ButtonKind = 'available' | 'disabled' | 'cancel' | 'loading';

// We're creating a lot more props than this component should have
// this is something we'll fix when we implement the Neoenergia WL
// heil component padronization 💃
type ButtonProps = {
  onClick: () => void;
  visual: ButtonVisual;
  kind: ButtonKind;
  textSize?: 12 | 14 | 16;
  grayBorder?: boolean;
};

type ButtonBehavior = {
  canPress: boolean;
  colorKey: keyof ConfigType['COLORS'];
};

const behaviors: Record<ButtonKind, ButtonBehavior> = {
  available: {
    canPress: true,
    colorKey: 'BUTTON_AVAILABLE',
  },
  cancel: {
    canPress: true,
    colorKey: 'ERROR',
  },
  disabled: {
    canPress: false,
    colorKey: 'BUTTON_UNAVAILABLE',
  },
  loading: {
    canPress: false,
    colorKey: 'BUTTON_LOADING',
  },
};

const Button: React.FC<ButtonProps> = ({
  visual,
  kind,
  textSize = 16,
  grayBorder = false,
  onClick,
  children,
  ...props
}) => {
  const { canPress, colorKey } = behaviors[kind];
  const color = Config.COLORS[colorKey] as string;
  const textColor = visual === 'primary' ? 'WHITE' : colorKey;
  const backgroundColor = visual === 'primary' ? color : 'transparent';
  return (
    <ButtonContainer
      disabled={!canPress}
      onClick={onClick}
      backgroundColor={backgroundColor}
      visual={visual}
      grayBorder={grayBorder}
      {...props}
    >
      {kind === 'loading' ? (
        <Loader color={textColor} />
      ) : (
        <Typography
          color={visual === 'primary' ? textColor : 'PRIMARY_GRAY'}
          size={textSize}
        >
          {children}
        </Typography>
      )}
    </ButtonContainer>
  );
};

export default Button;
