import {
  AllDriversQuery,
  AllDriversQueryVariables,
  AllStationsProfileVoucherQuery,
  AllStationsProfileVoucherQueryVariables,
  AllVoucherQuery,
  AllVoucherQueryVariables,
  GetVoucherQuery,
  GetVoucherQueryVariables,
} from 'generated/graphql';
import allDrivers from 'graphql/queries/allDrivers';
import allStationsProfileVoucher from 'graphql/queries/allStationsProfileVoucher';
import allVoucher from 'graphql/queries/allVoucher';
import getVoucher from 'graphql/queries/getVoucher';
import { useAuthQuery } from 'hooks';
import {
  GetDriversInput,
  GetDriversResult,
  GetStationsInput,
  GetStationsResult,
  GetVoucherInput,
  GetVoucherResult,
  ListVoucherInput,
  ListVoucherResult,
  VoucherClient,
} from '..';

const GetVoucher = (args: GetVoucherInput): GetVoucherResult => {
  const { data, loading, error } = useAuthQuery<
    GetVoucherQuery,
    GetVoucherQueryVariables
  >(getVoucher, { variables: args });
  return {
    data: {
      voucher: data?.voucher,
    },
    loading,
    error,
  };
};

const ListVoucher = (args: ListVoucherInput): ListVoucherResult => {
  const { data, loading, error } = useAuthQuery<
    AllVoucherQuery,
    AllVoucherQueryVariables
  >(allVoucher, { variables: args });
  return {
    data: {
      vouchers: data?.vouchers ?? null,
      vouchersSummary: data?.vouchersSummary ?? null,
    },
    loading,
    error,
  };
};

const parseSearchValue = (value: string, matcher: RegExp) => {
  const idMatch = value.match(matcher)?.[1];
  if (idMatch) return idMatch;
  return value;
};

const GetDrivers = ({
  organizationId,
  pageCount,
  pageIndex,
  searchValue,
}: GetDriversInput): GetDriversResult => {
  const parseSearchRegex = /[U,u]ser ([0-9]+)/;
  const { data, loading, error } = useAuthQuery<
    AllDriversQuery,
    AllDriversQueryVariables
  >(allDrivers, {
    variables: {
      search: parseSearchValue(searchValue, parseSearchRegex),
      offset: (pageIndex - 1) * pageCount,
      limit: pageCount,
      filter: {
        emspIds: organizationId ? [organizationId] : [],
      },
    },
  });
  return {
    data: {
      drivers: data?.drivers ?? null,
      driversSummary: data?.driversSummary ?? null,
    },
    loading,
    error,
  };
};
const GetStations = ({
  organizationId,
  pageCount,
  pageIndex,
  searchValue,
}: GetStationsInput): GetStationsResult => {
  const { data, loading, error } = useAuthQuery<
    AllStationsProfileVoucherQuery,
    AllStationsProfileVoucherQueryVariables
  >(allStationsProfileVoucher, {
    variables: {
      search: searchValue,
      offset: (pageIndex - 1) * pageCount,
      limit: pageCount,
      filter: {
        emspIds: organizationId ? [organizationId] : [],
      },
    },
  });
  return {
    data: {
      stationsProfile: data?.stationsProfile ?? null,
      stationsProfileSummary: data?.stationsProfileSummary ?? null,
    },
    loading,
    error,
  };
};

export const useVoucherDetailsClient = (): VoucherClient => {
  const activateVoucher = () => new Promise<void>(() => {});
  const archiveVoucher = () => new Promise<void>(() => {});
  return {
    getVoucher: GetVoucher,
    listVoucher: ListVoucher,
    activateVoucher,
    archiveVoucher,
    getDrivers: GetDrivers,
    getStations: GetStations,
  };
};
