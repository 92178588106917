import { gql } from '@apollo/client';

export default gql`
  query TableStations(
    $timeRange: TimeRangeInput!
    $limit: Int
    $offset: Int
    $sort: StationSortInput
    $includeSession: Boolean!
  ) {
    stations(limit: $limit, offset: $offset, sort: $sort) {
      id
      compressedId
      name
      status
      cpoId
      sessionsSummary(
        filter: { includesStatus: [CHARGING, WAITING_UNPLUG, FINISHED] }
        timeRange: $timeRange
      ) @include(if: $includeSession) {
        count
        grossValue
        timeCharging
      }
      connectors {
        id
        status
        power
        type
        station {
          id
          status
        }
        currentType
        errorCode
      }
      address {
        city
        state
        streetNumber
        postalCode
      }
    }
    stationsSummary {
      count
    }
  }
`;
