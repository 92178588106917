import { gql } from '@apollo/client';

export default gql`
  mutation CreateOrganization(
    $data: CreateOrganizationInput!
    $where: CreateOrganizationWhereUniqueInput!
  ) {
    createOrganization(data: $data, where: $where)
  }
`;
