const secondsToTime = (duration: number) => {
  const seconds = duration % 60;
  const minutes = Math.floor((duration / 60) % 60);
  const hours = Math.floor((duration / 3600) % 24).toFixed(0);

  const fullMinutes =
    minutes < 10 ? `0${minutes.toFixed(0)}` : minutes.toFixed(0);
  const fullSeconds =
    seconds < 10 ? `0${seconds.toFixed(0)}` : seconds.toFixed(0);

  return `${
    Number(hours) < 10 ? `0${hours}` : hours
  }:${fullMinutes}:${fullSeconds}`;
};

export default secondsToTime;
