import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const EMogConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-15',
  CODE: 'e-mog',
  LABEL: 'e-MOg',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#94C72D',
    CLICKABLE: '#698D20',
    ERROR: '#E52E2E',
    SIDEBAR: '#231F20',
    LOGIN_LOGO_BACKGROUND: '#94C72D',

    RISE: '#11D073',
    FALL: '#E52E2E',
    NEUTRAL: '#231F20',

    CRITICAL: '#E52E2E',
    WARNING: '#FF7C01',

    PreAuthorized: '#F5F8FA',
    Captured: '#11D073',
    NoCost: '#F5F8FA',
    Error: '#E52E2E',

    AVAILABLE: '#11D073',
    CHARGING: '#00C2FF',
    INOPERATIVE: '#E52E2E',
    UNKNOWN: '#6B737C',
    PLANNED: '#E52E2E',
    RESERVED: '#6C36FE',

    PREPARING: '#00C2FF',
    FINISHING: '#00C2FF',

    SELECTED_DATE_BOUND_BACKGROUND: '#698D20',
    SELECTED_DATE_BOUND_BORDER: '#698D20',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#516D19',

    DATE_PRE_SELECTED_BACKGROUND: '#F4F9EA',
    DATE_PRE_SELECTED_BORDER: '#CEE59E',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#F4F9EA',
    DATE_PRE_SELECTED_COLOR: '#698D20',

    DATE_SELECTED_BACKGROUND: '#CEE59E',
    DATE_SELECTED_BORDER: '#94C72D',
    DATE_SELECTED_HOVER_BACKGROUND: '#698D20',

    CURRENT_DAY: '#698D20',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F5F8FA',
      Captured: '#11D073',
      Subsidized: '#11D073',
      NoCost: '#F5F8FA',
      Error: '#E52E2E',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#11D073',
      CHARGING: '#00C2FF',
      INOPERATIVE: '#E52E2E',
      UNKNOWN: '#6B737C',
      PLANNED: '#E52E2E',
      RESERVED: '#6C36FE',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#11D073',
      PREPARING: '#00C2FF',
      CHARGING: '#00C2FF',
      FINISHING: '#00C2FF',
      INOPERATIVE: '#E52E2E',
      UNKNOWN: '#6B737C',
      RESERVED: '#6C36FE',
    },

    BILLING: '#11D073',
    RECHARGES: '#00C2FF',
    AVAILABILITY: '#6C36FE',

    BUTTON_AVAILABLE: '#94C72D',
    BUTTON_UNAVAILABLE: '#D8E1E9',
    BUTTON_LOADING: '#94C72D',
    BUTTON_TEXT_COLOR: '#1F2226',
    LINK: '#698D20',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#698D20',
      SELECTED_DATE_BOUND_BORDER: '#698D20',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#516D19',

      DATE_PRE_SELECTED_BACKGROUND: '#F4F9EA',
      DATE_PRE_SELECTED_BORDER: '#CEE59E',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#F4F9EA',
      DATE_PRE_SELECTED_COLOR: '#698D20',

      DATE_SELECTED_BACKGROUND: '#CEE59E',
      DATE_SELECTED_BORDER: '#94C72D',
      DATE_SELECTED_HOVER_BACKGROUND: '#698D20',

      CURRENT_DAY: '#698D20',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -24.72974,
      lng: -53.74691,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'e-MOg - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/e-mog',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#F4F9EA',
      '100': '#DEEEBE',
      '200': '#CEE59E',
      '300': '#B7D972',
      '400': '#A9D257',
      '500': '#94C72D',
      '600': '#87B529',
      '700': '#698D20',
      '800': '#516D19',
      '900': '#3E5413',
    },
    LINK: {
      '50': '#F4F9EA',
      '100': '#DEEEBE',
      '200': '#CEE59E',
      '300': '#B7D972',
      '400': '#A9D257',
      '500': '#94C72D',
      '600': '#87B529',
      '700': '#698D20',
      '800': '#516D19',
      '900': '#3E5413',
    },
    ERROR: {
      '50': '#FCEAEA',
      '100': '#F7BEBE',
      '200': '#F39F9F',
      '300': '#EE7373',
      '400': '#EA5858',
      '500': '#E52E2E',
      '600': '#D02A2A',
      '700': '#A32121',
      '800': '#7E1919',
      '900': '#601313',
    },
    SUCCESS: {
      '50': '#E7FAF1',
      '100': '#B5F0D4',
      '200': '#92E9BF',
      '300': '#60E0A1',
      '400': '#41D98F',
      '500': '#11D073',
      '600': '#0FBD69',
      '700': '#0C9452',
      '800': '#09723F',
      '900': '#075730',
    },
    CHARGING: {
      '50': '#E6F9FF',
      '100': '#B0ECFF',
      '200': '#8AE3FF',
      '300': '#54D6FF',
      '400': '#33CEFF',
      '500': '#00C2FF',
      '600': '#00B1E8',
      '700': '#008AB5',
      '800': '#006B8C',
      '900': '#00516B',
    },
    AVAILABLE: {
      '50': '#E7FAF1',
      '100': '#B5F0D4',
      '200': '#92E9BF',
      '300': '#60E0A1',
      '400': '#41D98F',
      '500': '#11D073',
      '600': '#0FBD69',
      '700': '#0C9452',
      '800': '#09723F',
      '900': '#075730',
    },
    SECONDARY: {
      '50': '#E9E9E9',
      '100': '#BBBABA',
      '200': '#9A9898',
      '300': '#6C696A',
      '400': '#4F4C4D',
      '500': '#231F20',
      '600': '#201C1D',
      '700': '#191617',
      '800': '#131112',
      '900': '#0F0D0D',
    },
    RESERVE: {
      '50': '#F0EBFF',
      '100': '#D1C1FF',
      '200': '#BBA3FF',
      '300': '#9D78FE',
      '400': '#895EFE',
      '500': '#6C36FE',
      '600': '#6231E7',
      '700': '#4D26B4',
      '800': '#3B1E8C',
      '900': '#2D176B',
    },
    INOPERATIVE: {
      '50': '#FCEAEA',
      '100': '#F7BEBE',
      '200': '#F39F9F',
      '300': '#EE7373',
      '400': '#EA5858',
      '500': '#E52E2E',
      '600': '#D02A2A',
      '700': '#A32121',
      '800': '#7E1919',
      '900': '#601313',
    },
  },
};
export default EMogConfig;
