import styled from 'styled-components';

export const EditorContainer = styled.div`
  width: 100%;

  .jsoneditor {
    border: none;
  }

  .jsoneditor-readonly {
    font-size: 14px;
    color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
  }

  .jsoneditor-button {
    outline: 0 !important;
    background-color: transparent !important;
  }
`;
