import { Flex, Input, InputLeftAddon, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';

type Props = {
  value: number | null;
  setValue: Function;
};

const BORDER_COLOR = Config.COLORS.LIGHT_GRAY;
const BORDER_COLOR_ON_FOCUS = Config.COLORS.PRIMARY;
const BORDER_COLOR_ON_ERROR = Config.COLORS.ERROR;

export const PercentageInput = ({ value, setValue }: Props) => {
  const [invalidPercentageValue, setInvalidPercentageValue] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { t } = useTranslation();

  const inputBorderColor = isFocused
    ? invalidPercentageValue
      ? BORDER_COLOR_ON_ERROR
      : BORDER_COLOR_ON_FOCUS
    : BORDER_COLOR;

  return (
    <>
      <Flex mt="2">
        <InputLeftAddon
          border="1px"
          borderStyle="solid"
          borderLeftRadius="0.5rem"
          borderColor={inputBorderColor}
          autoFocus
          w="2.75rem"
          h="2rem"
          fontWeight="bold"
          fontSize="md"
          justifyContent="center"
          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
          boxShadow={isFocused ? `0 0 0 1px ${inputBorderColor}` : 'none'}
        >
          %
        </InputLeftAddon>
        <Input
          type="number"
          w="6.25rem"
          h="2rem"
          borderLeftRadius="0"
          borderRightRadius="0.5rem"
          borderColor={inputBorderColor}
          focusBorderColor={inputBorderColor}
          value={value ?? undefined}
          onChange={(e) => {
            setValue(e.target.value);
            setInvalidPercentageValue(
              parseInt(e.target.value) < 1 || parseInt(e.target.value) > 100
            );
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          _hover={{ borderColor: inputBorderColor }}
        />
      </Flex>
      {invalidPercentageValue && (
        <Text position="absolute" mt="2" fontSize="sm" color="red.500">
          {t('percentage-input-error')}
        </Text>
      )}
    </>
  );
};
