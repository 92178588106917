import { Tag, TagCloseButton, TagLabel, Box } from '@chakra-ui/react';
import React from 'react';
import { FilterTerm } from 'types';
import { FilterTermsContainer } from './styles';

type Props<U extends string> = {
  terms: FilterTerm<U>[];
  removeTerm: (term: FilterTerm<U>) => void;
  renderOnHeader: boolean;
};

type TermsProps<U extends string> = {
  terms: Props<U>['terms'];
  removeTerm: Props<U>['removeTerm'];
  marginTop?: number;
};

const Terms = <U extends string>({
  terms,
  removeTerm,
  marginTop = 0,
}: TermsProps<U>) => (
  <>
    {terms.map((term, index) => (
      <Box data-test="selected-filter" marginTop={marginTop} key={index} mr="2">
        <Tag size="md" variant="solid" bg="primary.600">
          <TagLabel> {term.label ?? term.value}</TagLabel>
          <TagCloseButton
            onClick={() => removeTerm(term)}
            _focus={{
              boxShadow: 'none',
            }}
          />
        </Tag>
      </Box>
    ))}
  </>
);

const FilterTerms = <U extends string>({
  terms,
  removeTerm,
  renderOnHeader,
}: Props<U>) =>
  renderOnHeader ? (
    <>
      <FilterTermsContainer data-test="header-selected-filters-container">
        <Terms terms={terms} removeTerm={removeTerm} />
      </FilterTermsContainer>
    </>
  ) : (
    <Terms terms={terms} removeTerm={removeTerm} />
  );

export default FilterTerms;
