import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import Table from 'components/Table';
import { VoucherStatesContext } from 'pages/Payments/Voucher/context/states-context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import { VoucherDriver } from '../../services';

export const VoucherDrivers = () => {
  const { t } = useTranslation();
  const { voucherDetails, voucherDetailsLoading } =
    useContext(VoucherStatesContext);

  const drivers = voucherDetails?.drivers ?? [];
  const voucherDriversColumns = [
    {
      Header: t(
        'payments.voucher-tab.voucher-details.tab.selected-clients.table.client-id'
      ),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { id },
        },
      }) => <Text>{id}</Text>,
    },
    {
      Header: t(
        'payments.voucher-tab.voucher-details.tab.selected-clients.table.client-name'
      ),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { name },
        },
      }) => <Text>{name}</Text>,
    },
    {
      Header: t(
        'payments.voucher-tab.voucher-details.tab.selected-clients.table.client-email'
      ),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { email },
        },
      }) => <Text>{email}</Text>,
    },
  ];

  return (
    <Box p="1.25rem" data-testid="selected-drivers">
      <Box mb="1.5rem">
        <Heading mb={2} fontSize="x-small" color={Config.COLORS.SECONDARY_GRAY}>
          {t(
            'payments.voucher-tab.voucher-details.tab.selected-clients.body.title'
          )}
        </Heading>
        <Text fontSize={'sm'} colors={Config.COLORS.PRIMARY_GRAY}>
          {t(
            'payments.voucher-tab.voucher-details.tab.selected-clients.body.subtitle'
          )}
        </Text>
      </Box>
      <Box w="100%">
        <Flex
          bg="white"
          borderWidth="thin"
          borderColor="gray.100"
          borderRadius="1rem"
          p="1.5rem"
          gap="1.5rem"
          flexDirection="column"
        >
          <Table<VoucherDriver>
            columns={voucherDriversColumns}
            data={drivers}
            loading={voucherDetailsLoading}
          />
        </Flex>
      </Box>
    </Box>
  );
};
