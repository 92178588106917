import { NotNestedColors, NotNestedNewIcons } from 'new-components/Icon';
import React from 'react';
import {
  BubbleContainer,
  BubbleContent,
  Container,
  Content,
  DangerBorder,
  LeftIcon,
  RightIcon,
  Title,
} from './styles';

export type State = 'active' | 'disabled' | 'selected';

export type Accent =
  | 'success'
  | 'danger'
  | 'information'
  | 'warning'
  | 'primary'
  | 'default';

export type InformationBubbleProps = {
  title: string;
  content: React.ReactNode;
  // description: string;
  // timeFrame: string;
  accent?: Accent;
  state: State;
  icon: { type: keyof NotNestedNewIcons; color: keyof NotNestedColors };
  children?: React.ReactNode;
  onClick?: () => void;
};

const InformationBubble: React.FC<InformationBubbleProps> = ({
  icon: { type, color },
  state = 'active',
  accent = 'default',
  content,
  title,
  onClick,
  children,
}) => {
  const hasRightButton = onClick && state !== 'disabled';
  const hasDangerBorder = state !== 'disabled' && accent === 'danger';
  const hasAction = !!onClick;

  return (
    <Container
      onClick={onClick}
      hasAction={hasAction}
      accent={accent}
      state={state}
    >
      {hasDangerBorder && <DangerBorder />}
      <BubbleContainer>
        <BubbleContent state={state}>
          <LeftIcon type={type} size={16} color={color} />
          <Content>
            <Title>{title}</Title>
            {content}
          </Content>
          {hasAction && (
            <>
              {hasRightButton && state === 'selected' ? (
                <RightIcon type="NEW_CLOSE_X" color="PRIMARY_GRAY" size={20} />
              ) : (
                <RightIcon
                  type="NEW_CHEVRON_RIGHT"
                  color="SECONDARY_GRAY"
                  size={20}
                />
              )}
            </>
          )}
        </BubbleContent>
        {children}
      </BubbleContainer>
    </Container>
  );
};

export { InformationBubble };
