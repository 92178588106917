import AuthContext from 'contexts/Auth/context';
import { Module } from 'generated/graphql';
import { useContext } from 'react';

export default function useHasModule(): (neededModule: Module) => boolean {
  const { selectedOrganizationIds, organizations } = useContext(AuthContext);
  const selectedOrganizations = organizations?.filter((org) =>
    selectedOrganizationIds?.includes(org.id)
  );
  return (neededModule: Module): boolean =>
    selectedOrganizations?.every((org) => org.modules.includes(neededModule)) ||
    false;
}
