import styled from 'styled-components';
import { Typography, Button } from 'components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  &:not(:first-child) {
    margin-top: 25px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 25px;
  }
`;

export const Column = styled.div<{ type: 'normal' | 'simple' }>`
  width: ${({ type }) => (type === 'normal' ? '50%' : '33.33%')};
  box-sizing: border-box;
  padding: 0px 12.5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
`;

export const CardInnerContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0px -12.5px;
`;

export const InnerTitle = styled(Typography).attrs({
  size: 16,
})`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 50%;
`;

export const InputContainer = styled.div`
  margin-bottom: 25px;
`;

export const Title = styled(Typography).attrs({
  size: 20,
  weight: 'regular',
})``;

export const ButtonContainer = styled.div`
  margin-top: auto;
  max-width: 190px;
  align-self: center;
  width: 100%;
`;

export const EditButton = styled(Button)``;

export const ContainerPreviewImage = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND};
`;

export const PreviewImage = styled.img`
  display: block;
  max-height: 200px;
  width: auto;
  height: auto;
  margin: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  border-radius: 10px;
`;
