import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AllStationsProfileVoucherQuery } from 'generated/graphql';
import SelectItemsTable, {
  Props as TableProps,
} from 'new-components/SelectItemsTable';
import Config from 'config';
import { useContext } from 'react';
import { CreateVoucherStatesContext } from '../../context/state-context';
import { useCreateVoucherStationsSelection } from './services';

type StationProfile = AllStationsProfileVoucherQuery['stationsProfile'][number];

const SelectStationsScreen = () => {
  const { t } = useTranslation();
  const {
    createVoucherData: { organizationId },
  } = useContext(CreateVoucherStatesContext);
  const {
    stationsProfile,
    stationsProfileSummary,
    getStationsResultError,
    getStationsResultLoading,
    pageCount,
    pageIndex,
    searchValue,
    setPageCount,
    setPageIndex,
    setSearchValue,
    setStationsSelection,
    stationsSelection,
  } = useCreateVoucherStationsSelection();

  const columns: TableProps<StationProfile>['dataOptions']['columns'] = [
    {
      label: t(
        'payments.voucher-tab.create-voucher.stations.table.station-name'
      ),
      dataKeys: ['name'],
    },
    {
      label: t(
        'payments.voucher-tab.create-voucher.stations.table.station-emspId'
      ),
      data: organizationId,
    },
    {
      label: t(
        'payments.voucher-tab.create-voucher.stations.table.station-cpoId'
      ),
      dataKeys: ['cpoId'],
    },
    {
      label: t(
        'payments.voucher-tab.create-voucher.stations.table.station-city'
      ),
      dataKeys: ['address.city'],
    },
  ];

  const selectDisplayContent = () => {
    if (organizationId) {
      return (
        <SelectItemsTable<StationProfile>
          dataOptions={{
            data: stationsProfile ?? [],
            summaryCount: stationsProfileSummary?.count ?? 0,
            columns,
            loading: getStationsResultLoading,
            error: !!getStationsResultError,
          }}
          paginationOptions={{
            pageIndex,
            setPageIndex,
            pageCount,
            setPageCount,
          }}
          searchBarOptions={{
            searchBarText: t('search-station-by-name-input.title'),
            searchValue,
            setSearchValue,
          }}
          selectedItemsOptions={{
            allItemsSelected: stationsSelection.allStationsSelected,
            allItemsSelectedExcept: stationsSelection.allStationsSelectedExcept,
            selectedItems: stationsSelection.selectedStations,
          }}
          setItemsOptions={({
            allItemsSelected,
            allItemsSelectedExcept,
            selectedItems,
          }) =>
            setStationsSelection({
              allStationsSelected: allItemsSelected,
              selectedStations: selectedItems as string[],
              allStationsSelectedExcept: allItemsSelectedExcept as string[],
            })
          }
        />
      );
    }
    return (
      <Box>
        {t(
          'payments.voucher-tab.create-voucher.stations.no-organization-selected'
        )}
      </Box>
    );
  };

  return (
    <FormControl w="100%" isRequired={true}>
      <Flex
        bg="white"
        borderWidth="thin"
        borderColor="gray.100"
        borderRadius="1rem"
        p="1.5rem"
        gap="1.5rem"
        flexDirection="column"
      >
        <Box>
          <FormLabel fontSize="xl">
            {t('payments.voucher-tab.create-voucher.stations.title')}
          </FormLabel>
          <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
            {t('payments.voucher-tab.create-voucher.stations.subtitle')}
          </Text>
        </Box>
        {selectDisplayContent()}
      </Flex>
    </FormControl>
  );
};

export default SelectStationsScreen;
