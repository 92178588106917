import React, { useState } from 'react';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import { H5 } from 'new-components/Typographies/styles';
import { GetRechargeModalDataQuery } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import { useTranslation } from 'react-i18next';
import { Container, ActionButtonContainer, DropdownContainer } from './styles';

type Props = {
  onEditSessionClick: () => void;
  onStopSessionClick: () => void;
  rechargeStatus: NonNullable<GetRechargeModalDataQuery['session']>['status'];
};
// @ts-ignore
const SessionOptionsDropdown: React.FC<Props> = ({
  onEditSessionClick,
  onStopSessionClick,
  rechargeStatus,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isStopDisabled, setIsStopDisabled] = useState(true);
  const hasScopes = useHasScopes();
  React.useEffect(() => {
    switch (rechargeStatus) {
      case 'CHARGING':
      case 'WAITING_UNPLUG':
        setIsStopDisabled(false);
        break;

      case 'FINISHED':
        setIsEditDisabled(false);
        break;
      default:
        break;
    }
  }, [rechargeStatus]);

  const handleEditOnClick = () => {
    setIsOpen(false);
    onEditSessionClick();
  };

  const handleStopOnClick = () => {
    setIsOpen(false);
    onStopSessionClick();
  };

  if (!hasScopes(['session:edit']) && !hasScopes(['session:force-stop'])) {
    return false;
  }

  return (
    <Container>
      <Dropdown
        isOpen={isOpen}
        toggleDropdown={setIsOpen}
        onClickOutside={() => setIsOpen(false)}
        isBeingUsedInsideModal={true}
        content={() => (
          <DropdownContainer>
            <>
              {hasScopes(['session:edit']) && (
                <Dropdown.Section>
                  <Dropdown.Row
                    onClick={handleEditOnClick}
                    disabled={isEditDisabled}
                  >
                    <H5
                      color={isEditDisabled ? 'TERTIARY_GRAY' : 'PRIMARY_GRAY'}
                    >
                      {t(
                        'recharge-modal.session-options-dropdown.edit-row.title'
                      )}
                    </H5>
                    <Icon
                      type="NEW_EDIT"
                      size={16}
                      color={isEditDisabled ? 'TERTIARY_GRAY' : 'PRIMARY_GRAY'}
                    />
                  </Dropdown.Row>
                </Dropdown.Section>
              )}
              {hasScopes(['session:force-stop']) && (
                <Dropdown.Section>
                  <Dropdown.Row
                    onClick={handleStopOnClick}
                    disabled={isStopDisabled}
                  >
                    <H5 color={isStopDisabled ? 'TERTIARY_GRAY' : 'FALL'}>
                      {t(
                        'recharge-modal.session-options-dropdown.stop-recharge-row.title'
                      )}
                    </H5>

                    <Icon
                      type="NEW_REMOVE_CIRCLE"
                      color={isStopDisabled ? 'TERTIARY_GRAY' : 'FALL'}
                      size={16}
                    />
                  </Dropdown.Row>
                </Dropdown.Section>
              )}
            </>
          </DropdownContainer>
        )}
      >
        <ActionButtonContainer
          active={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon
            type="NEW_THREE_HORIZONTAL_DOTS"
            color="PRIMARY_GRAY"
            size={24}
          />
        </ActionButtonContainer>
      </Dropdown>
    </Container>
  );
};

export default SessionOptionsDropdown;
