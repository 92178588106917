import styled, { css } from 'styled-components';
import Config from 'config';

export const Container = styled.div<{ hasOverflow: boolean }>`
  padding: 6px 10px 0;
  margin-left: -10px;
  ${({ hasOverflow }) =>
    hasOverflow &&
    css`
      cursor: pointer;
      border-radius: 16px;
      &:hover {
        background: ${({ theme }) => theme.COLORS.BACKGROUND};
      }
      &:active {
        background: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
      }
    `}
`;

export const TextContainer = styled.div<{ isExpanded: boolean }>`
  text-align: left;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      -webkit-line-clamp: 3;
      overflow: hidden;
    `}
`;

export const ArrowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export const Arrow = styled(Config.ICONS.CHEVRON)<{ up: 'true' | 'false' }>`
  transform: ${({ up }) =>
    up === 'true' ? 'rotate(0deg);' : 'rotate(180deg);'}
  height: 7px;
`;
