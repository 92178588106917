import { InjectButtonProps, InjectChildrenProps } from '.';
import { Row } from './styles';

type RowProps = {
  onClick?: InjectButtonProps['onClick'];
} & {
  onClick: InjectButtonProps['onClick'];
  /** A propriedade ```clickable``` define se a linha estará disponivel ou não para ser clicada.
   *
   * Mesmo tendo a propriedade ```onClick``` definida, se ```clickable: false```, a
   * ação de click será ignorada. O que motivou a criação desta propriedade foi a
   * necessidade de usar um indicador de carregamento na linha, e por conseguinte
   * torna-la não clicável se ```loading: true```.
   *
   * Por padrão ```clickable: true```.
   */
  clickable?: boolean;
} & InjectChildrenProps &
  Omit<InjectButtonProps, 'onClick'>;

const DropdownRow = ({
  onClick,
  children,
  clickable = true,
  ...props
}: RowProps) => (
  <Row active={clickable} onClick={clickable ? onClick : undefined} {...props}>
    {children}
  </Row>
);

export { DropdownRow };
