import CardHeader from 'components/Card/CardHeader';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import Card from 'components/Card/index';
import Dropdown from 'components/Dropdown';
import {
  SessionsTimelineQuery,
  SessionsTimelineQueryVariables,
} from 'generated/graphql';
import sessionsTimeline from 'graphql/queries/sessionsTimeline';
import useAuthQuery from 'hooks/useAuthQuery';
import useHasScopes from 'hooks/useHasScope';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeRange, TIME_PERIODS, TIME_PERIODS_LIST } from 'services/timeRange';
import { TranslationKeyType } from 'types/translation';
import HistoryGraph from './SessionsTimelineGraph';
import { DropdownContainer, GraphContainer } from './styles';

type Summary = SessionsTimelineQuery['sessionsSummaryPerDateTime'][number];

// first time accessing Dashboard(will cache more times)
const now = moment();

const SessionsTimelineCard = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [selectedTimeRange, selectTimeRange] = useState<TimeRange>(
    TIME_PERIODS.WEEK
  );

  const { data, error, loading } = useAuthQuery<
    SessionsTimelineQuery,
    SessionsTimelineQueryVariables
  >(sessionsTimeline, {
    variables: {
      input: selectedTimeRange.toPerDateTimeInput(
        selectedTimeRange.convertDateTimeByCurrentResolution(now)
      ),
    },
    skip: !hasScopes(['session:read']),
  });

  const formatTimezone = useCallback(
    (summary: NonNullable<Summary>): NonNullable<Summary> => ({
      ...summary,
      from: selectedTimeRange.format(summary.from),
    }),
    [selectedTimeRange]
  );

  const summaries = useMemo(() => {
    const rawSummaries = data?.sessionsSummaryPerDateTime || [];
    return rawSummaries.map(formatTimezone);
  }, [formatTimezone, data?.sessionsSummaryPerDateTime]);

  return (
    <Card type="normal" loading={loading} error={!!error} padded={12}>
      <>
        <CardHeader>
          <CardTitle title={t('home.sessions-timeline.title')} />
          <DropdownContainer>
            <Dropdown
              options={TIME_PERIODS_LIST}
              value={selectedTimeRange}
              format={(option) => t(option.label as TranslationKeyType)}
              onChange={selectTimeRange}
            />
          </DropdownContainer>
        </CardHeader>
        <GraphContainer>
          <HistoryGraph sessionsSummariesPerDateTime={summaries} />
        </GraphContainer>
      </>
    </Card>
  );
};

export default SessionsTimelineCard;
