import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: none;
  flex-flow: column;
  width: 100%;
  margin-top: -30px;
`;

export const DateDropdownContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: -5px 0;
`;
