import React from 'react';
import { Container } from './styles';

type Props = {
  children: React.ReactNode;
};
// Quanto ta seleiconado tem uma borda
const Filter: React.FC<Props> = ({ children }) => (
  <Container>{children}</Container>
);

export default Filter;
