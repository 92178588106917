import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import AuthContext from 'contexts/Auth/context';
import { DocumentNode } from 'graphql';
import { useContext, useMemo } from 'react';
import { useQuery } from 'urql';

/** Esse hook faz uma query autenticada e restrita ao workspace/organização atual */
export default function useAuthQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  providedOptions: QueryHookOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables> & { stale: boolean } {
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  // TODO: Fix that, the types should be from urql
  // @ts-ignore
  const options: QueryHookOptions<TData, TVariables> = {
    ...providedOptions,
    context: useMemo(
      () => ({
        fetchOptions: {
          headers: {
            authorization:
              providedOptions?.context?.headers.authorization ??
              `Bearer ${memberAuthToken}`,
            'org-ids': selectedOrganizationIds,
          },
        },
      }),
      [
        providedOptions?.context?.headers.authorization,
        selectedOrganizationIds,
        memberAuthToken,
      ]
    ),
    variables: {
      ...providedOptions?.variables,
    } as TVariables,
  };
  const [result, executeQuery] = useQuery<TData, TVariables>({
    query,
    pause: options?.skip,
    // TODO: Fix that, the types should be from urql
    // @ts-ignore
    requestPolicy: options?.fetchPolicy || 'cache-and-network',
    ...options,
  });
  return {
    data: result.data,
    loading: !options.skip && (result.fetching || !result.data),
    error: result.error as any,
    refetch: executeQuery,
    networkStatus: 1,
    stale: result.stale,
  } as any;
}
