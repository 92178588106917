import { VoucherClientContext } from 'pages/Payments/Voucher/context/client-context';
import { useContext, useEffect, useState } from 'react';
import { CreateVoucherEventsContext } from '../../../context/events-context';
import { CreateVoucherStatesContext } from '../../../context/state-context';

export const useCreateVoucherStationsSelection = () => {
  const client = useContext(VoucherClientContext).getClient()();
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(25);
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const {
    createVoucherData: { organizationId },
  } = useContext(CreateVoucherStatesContext);

  const [stationsSelection, setStationsSelection] = useState<{
    allStationsSelected: boolean;
    allStationsSelectedExcept: string[];
    selectedStations: string[];
  }>({
    allStationsSelected: false,
    allStationsSelectedExcept: [],
    selectedStations: [],
  });

  const {
    data: { stationsProfile, stationsProfileSummary },
    error: getStationsResultError,
    loading: getStationsResultLoading,
  } = client.getStations({
    organizationId: organizationId ?? '',
    pageCount,
    pageIndex,
    searchValue,
  });

  useEffect(() => {
    events.updateVoucherStationsSelection(stationsSelection);
    // eslint-disable-next-line
  }, [stationsSelection]);

  return {
    stationsProfile,
    stationsProfileSummary,
    stationsSelection,
    getStationsResultError,
    getStationsResultLoading,
    pageCount,
    pageIndex,
    searchValue,
    setStationsSelection,
    setPageCount,
    setPageIndex,
    setSearchValue,
  };
};
