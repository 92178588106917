import {
  Heading,
  ListItem,
  OrderedList,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'components';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useURLParams } from 'hooks/useURLParam';
import { AccountExclusionAuthContext } from 'main/account-exclusion/contexts/account-exclusion';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  appName: string;
};

export const StepsList = ({ appName }: Props) => {
  const { token, setAppName } = useContext(AccountExclusionAuthContext);
  const { isMediaLargerThan800 } = useMediaQuery();
  const textSize = useBreakpointValue({ base: 14, md: 16 });
  const { t } = useTranslation();
  const urlParams = useURLParams();
  const appCode = urlParams.get('appCode');

  return (
    <React.Fragment>
      <Heading size="lg" mt={12} fontSize={{ base: 18, md: 24 }}>
        {t('account-exclusion-introduction-steps-list.title')}
      </Heading>
      <OrderedList mt={4} color="gray.500" spacing={0.5} fontSize={textSize}>
        <ListItem>
          <Trans
            i18nKey="account-exclusion-introduction-steps-list.first-item"
            components={{
              Link: (
                <Link
                  size={isMediaLargerThan800 ? 16 : 14}
                  textDecoration="underline"
                  onClick={() => setAppName(appName)}
                  to={
                    token
                      ? `/request-account-exclusion/?appCode=${appCode}`
                      : `/account-exclusion-login/?appCode=${appCode}`
                  }
                />
              ),
            }}
          />
        </ListItem>
        <ListItem>
          <Trans
            i18nKey="account-exclusion-introduction-steps-list.second-item"
            components={{
              Heading: (
                <Heading as="strong" color="gray.500" fontSize={textSize} />
              ),
            }}
          />
        </ListItem>
        <ListItem>
          <Trans
            i18nKey="account-exclusion-introduction-steps-list.third-item"
            components={{
              Heading: (
                <Heading as="strong" color="gray.500" fontSize={textSize} />
              ),
            }}
          />
        </ListItem>
      </OrderedList>
    </React.Fragment>
  );
};
