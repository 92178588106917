import styled from 'styled-components';

import { BodyTypography, H5 } from 'new-components/Typographies/styles';
import {
  SubTitle,
  ItemGroup,
  Item,
  ExtraInfo,
} from '../StationUserGenerics/styles';

export {
  Container,
  Title,
  IconTitleWrapper,
  ContentGroup,
} from '../StationUserGenerics/styles';

export const UserId = styled(SubTitle)``;
export const UserInfo = styled(ItemGroup)``;
export const UserInfoItem = styled(Item)``;
export const UnlockMethodContainer = styled(ExtraInfo)``;
export const UnlockMethodTitle = styled(H5)``;
export const UnlockMethodName = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
`;
