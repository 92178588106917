import React from 'react';
import { Typography } from 'components';
import ConnectorsList from 'components/ConnectorsList';
import ConnectorsListHelp from 'components/ConnectorsList/ConnectorsListHelp';
import Config from 'config';
import { StationDetailsQuery } from 'generated/graphql';
import { formatPricingConfig } from 'services/formatPriceConfig';
import { useTranslation } from 'react-i18next';
import { CurrencyType } from 'types';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import {
  CalendarIcon,
  CloseButton,
  ConnectorPriceColumn,
  Container,
  Content,
  ContentColumn,
  ContentRow,
  ErrorIcon,
  PriceContentRow,
  PriceText,
  SingleLineText,
  SpinnerIcon,
  StationImage,
  Text,
  WalletIcon,
} from './styles';

type StationDetails = StationDetailsQuery['station'];

type Connectors = NonNullable<StationDetailsQuery['station']>['connectors'];

const renderStationPrice = (
  connectors: Connectors,
  index: number,
  currencyType?: CurrencyType
) => {
  const connector = connectors[index];
  return (
    <>
      {connector &&
        connector.pricingConfig &&
        connector.pricingConfig.map((pricingConfig) => (
          <SingleLineText>
            {index + 1} -{' '}
            {pricingConfig?.__typename === 'PerTimePricingConfig'
              ? formatPricingConfig(pricingConfig, undefined, currencyType)
              : pricingConfig?.__typename === 'PerEnergyPricingConfig'
              ? formatPricingConfig(pricingConfig, undefined, currencyType)
              : ''}
          </SingleLineText>
        ))}
    </>
  );
};

interface Props {
  stationDetails?: StationDetails;
  onClose: () => void;
  loading: boolean;
}

const StationPanel: React.FC<Props> = ({
  onClose,
  stationDetails,
  loading,
}) => {
  const { t } = useTranslation();
  const image = stationDetails?.images[0] || Config.IMAGES.STATION_FALLBACK;

  const defaultCurrencyTypes = useDefaultCurrencyTypes();

  const contentControl = () => {
    if (loading) return <SpinnerIcon />;
    if (!stationDetails) return <ErrorIcon />;

    const {
      connectors,
      name,
      openingHours,
      description,
      cpoId,
      address: { street, city, streetNumber, state } = {},
    } = stationDetails;

    const currencyType = getOrganizationDefaultCurrencyType(
      defaultCurrencyTypes,
      cpoId
    );

    return (
      <>
        <CloseButton onClick={onClose} />
        <StationImage
          src={image}
          alt={t('map.station-panel.station-image.alt')}
        />
        <Content>
          <ContentRow>
            <Typography weight="bold" size={20}>
              {name}
            </Typography>
          </ContentRow>
          <ContentRow>
            <Text>{`${street}, ${
              streetNumber || t('map.station-panel.fill-empty-street.title')
            }`}</Text>
            <Text>{`${city}, ${state}`}</Text>
          </ContentRow>
          <ContentRow>
            <ContentColumn>
              <CalendarIcon />
              <SingleLineText>{`${
                openingHours || t('map.station-panel.fill-without-hours.title')
              }`}</SingleLineText>
            </ContentColumn>
          </ContentRow>
          <ContentRow>
            <ContentColumn>
              <WalletIcon />
              <SingleLineText>
                {t('map.station-panel.column.price.title')}
              </SingleLineText>
            </ContentColumn>
          </ContentRow>
          <PriceContentRow>
            <ConnectorPriceColumn></ConnectorPriceColumn>
            <ConnectorPriceColumn>
              <PriceText>
                {renderStationPrice(connectors, 1, currencyType)}
              </PriceText>
            </ConnectorPriceColumn>
            <ConnectorPriceColumn>
              <PriceText>
                {renderStationPrice(connectors, 2, currencyType)}
              </PriceText>
            </ConnectorPriceColumn>
          </PriceContentRow>
          <ContentRow>
            <Text>{`${
              description ||
              t('map.station-panel.fill-without.description.title')
            }`}</Text>
          </ContentRow>
          <ContentRow>
            <ConnectorsList connectors={connectors} />
            <ConnectorsListHelp />
          </ContentRow>
        </Content>
      </>
    );
  };
  return <Container>{contentControl()}</Container>;
};

export default StationPanel;
