import React from 'react';
import { StationStatus as StationStatusType } from 'generated/graphql';
import { NewTag } from 'new-components/NewTag';

type Props = {
  status: StationStatusType;
};

const StationStatusIndicator: React.FC<Props> = ({ status }) => (
  <NewTag status={status} size="inline" type="text">
    <></>
  </NewTag>
);

export default StationStatusIndicator;
