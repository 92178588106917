import React from 'react';
import { Icon } from 'new-components/Icon';
import { Container, PlanInfo } from './styles';

type Props = {
  title: string;
  holderFullName: string;
};

const PlacePlan: React.FC<Props> = ({ title, holderFullName }) => (
  <Container>
    <Icon type="NEW_CARD_GREENEA" color="PRIMARY" />
    <PlanInfo>{`${title} - ${holderFullName}`}</PlanInfo>
  </Container>
);

export default PlacePlan;
