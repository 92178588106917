import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const AnguloSolucoesConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-13',
  CODE: 'angulo-solucoes',
  LABEL: 'Ângulo Soluções',
  LANGUAGE: 'pt_BR',
  COUNTRY: 'BR',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#383189',
    CLICKABLE: '#282361',
    ERROR: '#E52E2E',
    SIDEBAR: '#C5271B',
    LOGIN_LOGO_BACKGROUND: '#EBEAF3',

    RISE: '#11D073',
    FALL: '#E52E2E',
    NEUTRAL: '#C5271B',

    CRITICAL: '#E52E2E',
    WARNING: '#FF7C01',

    PreAuthorized: '#F5F8FA',
    Captured: '#11D073',
    NoCost: '#F5F8FA',
    Error: '#E52E2E',

    AVAILABLE: '#11D073',
    CHARGING: '#00C2FF',
    INOPERATIVE: '#E52E2E',
    UNKNOWN: '#6B737C',
    PLANNED: '#E52E2E',
    RESERVED: '#6C36FE',

    PREPARING: '#00C2FF',
    FINISHING: '#00C2FF',

    SELECTED_DATE_BOUND_BACKGROUND: '#8C1C13',
    SELECTED_DATE_BOUND_BORDER: '#8C1C13',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#6C150F',

    DATE_PRE_SELECTED_BACKGROUND: '#F9E9E8',
    DATE_PRE_SELECTED_BORDER: '#D86E66',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#F9E9E8',
    DATE_PRE_SELECTED_COLOR: '#8C1C13',

    DATE_SELECTED_BACKGROUND: '#D86E66',
    DATE_SELECTED_BORDER: '#C5271B',
    DATE_SELECTED_HOVER_BACKGROUND: '#8C1C13',

    CURRENT_DAY: '#8C1C13',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F5F8FA',
      Captured: '#11D073',
      Subsidized: '#11D073',
      NoCost: '#F5F8FA',
      Error: '#E52E2E',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#11D073',
      CHARGING: '#00C2FF',
      INOPERATIVE: '#E52E2E',
      UNKNOWN: '#6B737C',
      PLANNED: '#E52E2E',
      RESERVED: '#6C36FE',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#11D073',
      PREPARING: '#00C2FF',
      CHARGING: '#00C2FF',
      FINISHING: '#00C2FF',
      INOPERATIVE: '#E52E2E',
      UNKNOWN: '#6B737C',
      RESERVED: '#6C36FE',
    },

    BILLING: '#11D073',
    RECHARGES: '#00C2FF',
    AVAILABILITY: '#6C36FE',

    BUTTON_AVAILABLE: '#383189',
    BUTTON_UNAVAILABLE: '#D8E1E9',
    BUTTON_LOADING: '#383189',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#282361',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#8C1C13',
      SELECTED_DATE_BOUND_BORDER: '#8C1C13',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#6C150F',

      DATE_PRE_SELECTED_BACKGROUND: '#F9E9E8',
      DATE_PRE_SELECTED_BORDER: '#D86E66',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#F9E9E8',
      DATE_PRE_SELECTED_COLOR: '#8C1C13',

      DATE_SELECTED_BACKGROUND: '#D86E66',
      DATE_SELECTED_BORDER: '#C5271B',
      DATE_SELECTED_HOVER_BACKGROUND: '#8C1C13',

      CURRENT_DAY: '#8C1C13',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -23.5575267,
      lng: -46.6613554,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Não temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Ângulo Soluções - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/angulo-solucoes',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#EBEAF3',
      '100': '#C1BFDA',
      '200': '#A3A0C9',
      '300': '#7A75B0',
      '400': '#605AA1',
      '500': '#383189',
      '600': '#332D7D',
      '700': '#282361',
      '800': '#1F1B4B',
      '900': '#18153A',
    },
    LINK: {
      '50': '#EBEAF3',
      '100': '#C1BFDA',
      '200': '#A3A0C9',
      '300': '#7A75B0',
      '400': '#605AA1',
      '500': '#383189',
      '600': '#332D7D',
      '700': '#282361',
      '800': '#1F1B4B',
      '900': '#18153A',
    },
    ERROR: {
      '50': '#FCEAEA',
      '100': '#F7BEBE',
      '200': '#F39F9F',
      '300': '#EE7373',
      '400': '#EA5858',
      '500': '#E52E2E',
      '600': '#D02A2A',
      '700': '#A32121',
      '800': '#7E1919',
      '900': '#601313',
    },
    SUCCESS: {
      '50': '#E7FAF1',
      '100': '#B5F0D4',
      '200': '#92E9BF',
      '300': '#60E0A1',
      '400': '#41D98F',
      '500': '#11D073',
      '600': '#0FBD69',
      '700': '#0C9452',
      '800': '#09723F',
      '900': '#075730',
    },
    CHARGING: {
      '50': '#E6F9FF',
      '100': '#B0ECFF',
      '200': '#8AE3FF',
      '300': '#54D6FF',
      '400': '#33CEFF',
      '500': '#00C2FF',
      '600': '#00B1E8',
      '700': '#008AB5',
      '800': '#006B8C',
      '900': '#00516B',
    },
    AVAILABLE: {
      '50': '#E7FAF1',
      '100': '#B5F0D4',
      '200': '#92E9BF',
      '300': '#60E0A1',
      '400': '#41D98F',
      '500': '#11D073',
      '600': '#0FBD69',
      '700': '#0C9452',
      '800': '#09723F',
      '900': '#075730',
    },
    SECONDARY: {
      '50': '#F9E9E8',
      '100': '#EDBCB8',
      '200': '#E49C96',
      '300': '#D86E66',
      '400': '#D15249',
      '500': '#C5271B',
      '600': '#B32319',
      '700': '#8C1C13',
      '800': '#6C150F',
      '900': '#53100B',
    },
    RESERVE: {
      '50': '#F0EBFF',
      '100': '#D1C1FF',
      '200': '#BBA3FF',
      '300': '#9D78FE',
      '400': '#895EFE',
      '500': '#6C36FE',
      '600': '#6231E7',
      '700': '#4D26B4',
      '800': '#3B1E8C',
      '900': '#2D176B',
    },
    INOPERATIVE: {
      '50': '#FCEAEA',
      '100': '#F7BEBE',
      '200': '#F39F9F',
      '300': '#EE7373',
      '400': '#EA5858',
      '500': '#E52E2E',
      '600': '#D02A2A',
      '700': '#A32121',
      '800': '#7E1919',
      '900': '#601313',
    },
  },
};
export default AnguloSolucoesConfig;
