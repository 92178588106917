import { gql } from '@apollo/client';

export default gql`
  query ExportStationSessions(
    $where: StationWhereUniqueInput!
    $timeRange: TimeRangeInput
    $filter: SessionFilterInput
    $limit: Int
  ) {
    station(where: $where) {
      id
      exportSessions(
        format: XLSX
        filter: $filter
        sort: { createdAt: DESC }
        timeRange: $timeRange
        limit: $limit
      ) {
        url
      }
    }
  }
`;
