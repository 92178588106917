import { InjectChildrenProps, InjectDivProps } from '.';
import { Container } from './styles';

type ContainerProps = InjectChildrenProps & InjectDivProps;

const DropdownContainer = ({ children, style, ...props }: ContainerProps) => (
  <Container {...props} style={style}>
    {children}
  </Container>
);

export { DropdownContainer };
