import { Icon } from 'new-components/Icon';
import { InjectDivProps } from '.';
import { ChevronLeftButtonContainer, HeaderIconContainer } from './styles';

type ChevronLeftButtonProps = {
  /** O alinhamento padrão do icone **"ChevronLeftButton"** é ```justify-content: "center"``` */
  justify?: 'flex-start' | 'flex-end';
} & InjectDivProps;

const DropdownChevronLeftButton = ({
  justify,
  onClick,
}: ChevronLeftButtonProps) => (
  <HeaderIconContainer justify={justify} onClick={onClick}>
    <ChevronLeftButtonContainer>
      <Icon type="NEW_CHEVRON_LEFT" color="PRIMARY_GRAY" size={20} />
    </ChevronLeftButtonContainer>
  </HeaderIconContainer>
);

export { DropdownChevronLeftButton };
