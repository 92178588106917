import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationDescription(
    $where: SelectStationInput!
    $data: DescriptionInput!
  ) {
    changeStationDescription(where: $where, data: $data)
  }
`;
