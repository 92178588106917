import { Flex, Heading, Stack, Tag } from '@chakra-ui/react';
import { RfidStatus } from 'generated/graphql';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';

type Props = {
  status: RfidStatus;
};

const RFIDStatusTag = ({ status }: Props) => {
  const { t } = useTranslation();
  switch (status) {
    case 'PreRegistered':
      return (
        <Tag bg="gray.100">
          <Heading color="gray.900" fontSize="sm">
            {t('rfid-status-tag.pre-registered.title')}
          </Heading>
        </Tag>
      );
    case 'Active':
      return (
        <Tag bg="success.500">
          <Heading fontSize="sm" color="white">
            {t('rfid-status-tag.active.title')}
          </Heading>
        </Tag>
      );
    case 'BlockedByConsumer':
      return (
        <Tag bg="error.300">
          <Heading fontSize="sm" color="gray.900">
            {t('rfid-status-tag.blocked-by-consumer.title')}
          </Heading>
        </Tag>
      );
    case 'BlockedByMember':
      return (
        <Tag bg="error.500">
          <Icon type="NEW_LOCK" color="WHITE" />
          <Heading fontSize="sm" color="white" ml="1">
            {t('rfid-status-tag.blocked-by-member.title')}
          </Heading>
        </Tag>
      );
    case 'PreRegisteredAndBlocked':
      return (
        <Flex>
          <Stack direction="row" spacing="2">
            <Tag bg="gray.100">
              <Heading fontSize="sm" color="gray.900">
                {t('rfid-status-tag.pre-registered.title')}
              </Heading>
            </Tag>
            <Tag bg="error.500">
              <Icon type="NEW_LOCK" color="WHITE" />
              <Heading fontSize="sm" color="white" ml="1">
                {t('rfid-status-tag.blocked-by-member.title')}
              </Heading>
            </Tag>
          </Stack>
        </Flex>
      );
  }
};

export default RFIDStatusTag;
