import Config from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StationDetail } from '..';
import {
  AlignStationInfo,
  Container,
  DescriptionContainer,
  InfoTitle,
  StationInfo,
} from './styles';

type Props = {
  station?: StationDetail;
};

const StationInfos: React.FC<Props> = ({ station }) => {
  const { t } = useTranslation();
  const {
    name,
    openingHours,
    qrCode,
    description,
    unlockMethods = [],
    accessRestriction,
  } = station || {};
  const { city, state, street, streetNumber } = station?.address || {};

  const renderUnlockMethods = () =>
    unlockMethods
      .map((unlockMethod) => t(Config.LABELS.UNLOCK_METHODS[unlockMethod]))
      .join(' - ');

  return (
    <Container>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.station-name.title')}</InfoTitle>
        <StationInfo>{name}</StationInfo>
      </AlignStationInfo>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.opening-hours.title')}</InfoTitle>
        <StationInfo>{openingHours}</StationInfo>
      </AlignStationInfo>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.code.title')}</InfoTitle>
        <StationInfo>{qrCode}</StationInfo>
      </AlignStationInfo>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.unlock-method.title')}</InfoTitle>
        <StationInfo>{renderUnlockMethods()}</StationInfo>
      </AlignStationInfo>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.city.title')}</InfoTitle>
        <StationInfo>{city}</StationInfo>
      </AlignStationInfo>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.state.title')}</InfoTitle>
        <StationInfo>{state}</StationInfo>
      </AlignStationInfo>
      <AlignStationInfo>
        <InfoTitle>{t('station-info.address.title')}</InfoTitle>
        <StationInfo>
          {street} - {streetNumber}
        </StationInfo>
      </AlignStationInfo>
      {accessRestriction?.active && (
        <AlignStationInfo>
          <InfoTitle>{t('station-info.access.title')}</InfoTitle>
          <DescriptionContainer>
            <StationInfo>
              {t('station-info.restricted-access.title')}
            </StationInfo>
          </DescriptionContainer>
        </AlignStationInfo>
      )}
      <AlignStationInfo>
        <InfoTitle>{t('station-info.description.title')}</InfoTitle>
        <DescriptionContainer>
          <StationInfo>{description}</StationInfo>
        </DescriptionContainer>
      </AlignStationInfo>
    </Container>
  );
};

export { StationInfos };
