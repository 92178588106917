import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  margin-bottom: 20.5px;
  padding-bottom: 11.5px;
  margin-top: -20px;
`;
