import {
  BodyTypography,
  Comment,
  H5,
} from 'new-components/Typographies/styles';
import styled, { css } from 'styled-components';
import { Card } from '../Card/styles';

export const Container = styled(Card)<{ clickable?: boolean }>`
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 12px;
  ${({ clickable }) =>
    clickable &&
    css`
      :hover {
        cursor: pointer;
        background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
      }
    `}
`;

export const ContentGroup = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`;

export const Title = styled(Comment)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;

export const SubTitle = styled(H5)``;
export const ItemGroup = styled('section')`
  display: flex;
  flex-flow: column nowrap;
`;
export const Item = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;

export const ExtraInfo = styled('section')`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 4px;
`;

export const IconTitleWrapper = styled('section')`
  display: flex;
  align-items: center;
  max-height: 16px;
  gap: 2px;
`;
