import React from 'react';
import { Box, Flex, Text, Heading } from '@chakra-ui/react';
import Loader from 'components/Loader';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { TranslationKeyType } from 'types/translation';
import Config from 'config';
import ChangeIndicator from './ChangeIndicator/index';

type Props = {
  borderColor: keyof typeof Config.COLORS;
  thisPeriodTotalValue: number;
  lastPeriodTotalValue?: number;
  loading: boolean;
  error?: ApolloError;
  indicatorText: TranslationKeyType;
  periodText?: TranslationKeyType;
  detailsText: TranslationKeyType;
  timePeriodText?: TranslationKeyType;
  valueSuffix?: string;
  valuePreffix?: string;
  valueFormat?: (n: number) => string;
  orderedOrgArray?: Array<{
    name: string;
    value: number;
  }>;
  showChange?: boolean;
  othersComputeFunction?: (othersArray: number[]) => number;
};

const IndicatorCard: React.FC<Props> = ({
  borderColor,
  loading,
  error,
  thisPeriodTotalValue,
  lastPeriodTotalValue,
  indicatorText,
  periodText,
  detailsText,
  timePeriodText,
  valueSuffix,
  valuePreffix,
  valueFormat,
  orderedOrgArray,
  showChange,
  othersComputeFunction,
}) => {
  const { t } = useTranslation();
  const companies = orderedOrgArray
    ? orderedOrgArray.slice(0, 3).map((org) => (
        <Flex justifyContent={'space-between'}>
          <Text color={'gray.500'} fontSize="14px">
            {org.name}
          </Text>
          <Text color={'gray.500'} fontSize="14px">
            {valuePreffix} {valueFormat ? valueFormat(org.value) : org.value}{' '}
            {valueSuffix}
          </Text>
        </Flex>
      ))
    : [];

  if (orderedOrgArray !== undefined && orderedOrgArray.length > 3) {
    const totalTop3 = orderedOrgArray
      .slice(0, 3)
      .map((org) => org.value)
      .reduce((prev, crr) => prev + crr, 0);

    const othersValue = othersComputeFunction
      ? othersComputeFunction(
          orderedOrgArray
            .slice(3, orderedOrgArray.length)
            .map((org) => org.value)
        )
      : thisPeriodTotalValue - totalTop3;
    companies.push(
      <Flex justifyContent={'space-between'}>
        <Text color={'gray.500'} fontSize="14px">
          Outros
        </Text>
        <Text color={'gray.500'} fontSize="14px">
          {valuePreffix} {valueFormat ? valueFormat(othersValue) : othersValue}{' '}
          {valueSuffix}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      backgroundColor={'white'}
      borderLeftColor={Config.COLORS[borderColor]}
      borderLeftWidth={'8px'}
      borderRadius={'8px'}
      padding={'8px 16px'}
      justifyContent={loading || error ? 'center' : 'space-between'}
      alignItems={'center'}
      flex={'1'}
    >
      {error ? (
        <Typography size={20} color="INFO_GRAY">
          ✖ {t('session-table-card.error-message')}
        </Typography>
      ) : loading ? (
        <Loader color="INFO_GRAY" />
      ) : (
        <>
          <Flex flexDir={'column'} justifyContent={'center'} flex={'1'}>
            {showChange && (
              <ChangeIndicator
                currentValue={thisPeriodTotalValue}
                previousValue={lastPeriodTotalValue}
                period={periodText}
              />
            )}
            <Heading color={'gray.900'} fontSize="24px">
              {valuePreffix}{' '}
              {valueFormat
                ? valueFormat(thisPeriodTotalValue)
                : thisPeriodTotalValue}{' '}
              {valueSuffix}
            </Heading>
            <Text color={'gray.500'} fontSize="14px">
              {t(indicatorText)}
            </Text>
            {timePeriodText ? (
              <Text color={'gray.500'} fontSize="14px">
                {t(timePeriodText)}
              </Text>
            ) : (
              <></>
            )}
          </Flex>
          {orderedOrgArray !== undefined && orderedOrgArray.length > 1 && (
            <>
              <Box
                backgroundColor={'gray.100'}
                width={'1px'}
                marginRight={'22px'}
                height={'110px'}
              ></Box>
              <Flex flexDir={'column'} flex={'1'} justifyContent={'center'}>
                <Text color={'gray.900'} marginBottom={'5px'} fontSize="14px">
                  {t(detailsText)}
                </Text>
                {companies}
              </Flex>
            </>
          )}
        </>
      )}
    </Flex>
  );
};
export default IndicatorCard;
