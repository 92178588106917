import { ReactNode } from 'react';
import {
  Box as ChakraBox,
  Flex,
  BoxProps as ChakraBoxProps,
} from '@chakra-ui/react';
import LoadingComponent from 'new-components/LoadingComponent';

interface BoxProps extends ChakraBoxProps {
  loading?: boolean;
  children?: ReactNode;
}

const Card = ({ children, loading, ...rest }: BoxProps) => {
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <LoadingComponent size={51} />
      </Flex>
    );
  }
  return (
    <ChakraBox
      borderRadius="8"
      borderWidth="thin"
      borderColor="gray.100"
      p="4"
      bg="white"
      shadow="sm"
      {...rest}
    >
      {children}
    </ChakraBox>
  );
};

export default Card;
