import Config from 'config';
import {
  UpdateOwnMemberProfileMutation,
  UpdateOwnMemberProfileMutationVariables,
} from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import updateOwnMemberProfileMutation from 'graphql/mutations/updateOwnMemberProfile';
import Modal from 'new-components/NewModal';
import { BodyTypography, H2 } from 'new-components/Typographies/styles';
import React, { useCallback, useContext, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react';

import AuthContext from 'contexts/Auth/context';
import toast from 'services/toast';
import { useTranslation } from 'react-i18next';
import { Header, SubHeader, Body, Form, InputGroup } from './styles';

type Props = {
  closeModal: () => void;
  show: boolean;
};

const FulfillMemberProfileModal: React.FC<Props> = ({ closeModal, show }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState({ value: '', error: '' });
  const [lastName, setLastName] = useState({ value: '', error: '' });
  const [updatingMemberProfile, setUpdatingMemberProfile] = useState(false);
  const { member, refetch } = useContext(AuthContext);
  const [dispatchUpdateOwnMemberProfile] = useAuthMutation<
    UpdateOwnMemberProfileMutation,
    UpdateOwnMemberProfileMutationVariables
  >(updateOwnMemberProfileMutation);

  const updateOwnMemberProfile = useCallback(async () => {
    setUpdatingMemberProfile(true);
    try {
      if (!member?.id) throw new Error('expected memberId but found nothing');
      const { errors } = await dispatchUpdateOwnMemberProfile({
        variables: {
          data: {
            firstName: firstName.value,
            lastName: lastName.value,
          },
        },
      });

      setUpdatingMemberProfile(false);
      if (errors) {
        toast.error(t('fulfill-member-profile-modal.toast-error-message'));
        return false;
      }
      toast.success(
        t('fulfill-member-profile-modal.toast-successfully-message')
      );
      refetch();

      return true;
    } catch (error) {
      setUpdatingMemberProfile(false);
      toast.error(t('fulfill-member-profile-modal.toast-error-message'));
      return false;
    }
  }, [
    dispatchUpdateOwnMemberProfile,
    member?.id,
    refetch,
    firstName.value,
    lastName.value,
  ]);

  const areFieldsValid = (): boolean => {
    if (!firstName.value)
      setFirstName({
        value: firstName.value,
        error: t('fulfill-member-profile-modal.invalid-field-error'),
      });
    if (!lastName.value)
      setLastName({
        ...lastName,
        error: t('fulfill-member-profile-modal.invalid-field-error'),
      });
    if (!firstName.value || !lastName.value) return false;
    return true;
  };

  const handleCreateClick = async () => {
    if (!areFieldsValid()) return;
    if (await updateOwnMemberProfile()) closeModal();
  };

  const handleNameChange = (value: string) => {
    setFirstName({ value, error: '' });
  };

  const handleLastNameChange = (value: string) => {
    setLastName({ value, error: '' });
  };

  const Logo = Config.NEW_ICONS.NEW_LOGO;

  return (
    <Modal backdrop="static" onClose={() => {}} show={show} size="sm" centered>
      <Modal.Header>
        <Header>
          <Logo width={110} height={40} color={Config.COLORS.PRIMARY} />
        </Header>
        <SubHeader>
          <H2>{t('fulfill-member-profile-modal.header.title')}</H2>
          <BodyTypography>
            {t('fulfill-member-profile-modal.header.subtitle')}
          </BodyTypography>
        </SubHeader>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Form>
            <InputGroup>
              <FormControl isInvalid={!!firstName.error}>
                <FormLabel fontSize="sm" fontWeight="bold">
                  {t('fulfill-member-profile-modal.form.input-name.title')}
                </FormLabel>
                <Input
                  placeholder={t(
                    'fulfill-member-profile-modal.form.input-name.placeholder'
                  )}
                  onChange={(e) => handleNameChange(e.target.value)}
                />
                <FormErrorMessage>{firstName.error}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!lastName.error}>
                <FormLabel fontSize="sm" fontWeight="bold">
                  {t('fulfill-member-profile-modal.form.input-last-name.title')}
                </FormLabel>
                <Input
                  placeholder={t(
                    'fulfill-member-profile-modal.form.input-last-name.placeholder'
                  )}
                  onChange={(e) => handleLastNameChange(e.target.value)}
                />
                <FormErrorMessage>{lastName.error}</FormErrorMessage>
              </FormControl>
            </InputGroup>
            <Button
              size="lg"
              isLoading={updatingMemberProfile}
              onClick={() => handleCreateClick()}
            >
              {t('fulfill-member-profile-modal.form.btn')}
            </Button>
          </Form>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

export default FulfillMemberProfileModal;
