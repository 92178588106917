import { useState } from 'react';
import { CreateVoucherClientProvider } from './client-context';
import { CreateVoucherEventsContext } from './events-context';
import {
  createVoucherInitialStateData,
  CreateVoucherStateData,
  CreateVoucherStatesContext,
} from './state-context';

export const CreateVoucherProvider: React.FC = ({ children }) => {
  const [createVoucherData, setCreateVoucherStateData] =
    useState<CreateVoucherStateData>(createVoucherInitialStateData);

  const [createVoucherErrorModalOpen, updateCreateVoucherErrorModalOpen] =
    useState<boolean>(false);

  const updateVoucherName = (name: CreateVoucherStateData['name']) => {
    setCreateVoucherStateData({ ...createVoucherData, name });
  };

  const updateVoucherDescription = (
    description: CreateVoucherStateData['description']
  ) => {
    setCreateVoucherStateData({ ...createVoucherData, description });
  };

  const updateVoucherDiscount = (
    discount: CreateVoucherStateData['discount']
  ) => {
    setCreateVoucherStateData({ ...createVoucherData, discount });
  };

  const updateVoucherOrganizationId = (
    organizationId: CreateVoucherStateData['organizationId']
  ) => {
    setCreateVoucherStateData({ ...createVoucherData, organizationId });
  };

  const updateVoucherLimits = (limits: CreateVoucherStateData['limits']) => {
    setCreateVoucherStateData({ ...createVoucherData, limits });
  };

  const updateVoucherValidityPeriod = (
    validityPeriod: CreateVoucherStateData['validityPeriod']
  ) => {
    setCreateVoucherStateData({ ...createVoucherData, validityPeriod });
  };

  const updateVoucherDriversSelection = (
    driversSelection: CreateVoucherStateData['driversSelection']
  ) => {
    setCreateVoucherStateData({ ...createVoucherData, driversSelection });
  };

  const updateVoucherStationsSelection = (
    stationsSelection: CreateVoucherStateData['stationsSelection']
  ) => {
    setCreateVoucherStateData({ ...createVoucherData, stationsSelection });
  };

  return (
    <CreateVoucherEventsContext.Provider
      value={{
        getEvents: () => ({
          updateVoucherDescription,
          updateVoucherDiscount,
          updateVoucherDriversSelection,
          updateVoucherLimits,
          updateVoucherName,
          updateVoucherOrganizationId,
          updateVoucherStationsSelection,
          updateVoucherValidityPeriod,
          updateCreateVoucherErrorModalOpen,
          createVoucherErrorModalOpen,
        }),
      }}
    >
      <CreateVoucherStatesContext.Provider
        value={{
          createVoucherData,
        }}
      >
        <CreateVoucherClientProvider>{children}</CreateVoucherClientProvider>
      </CreateVoucherStatesContext.Provider>
    </CreateVoucherEventsContext.Provider>
  );
};
