import Config from 'config';
import ConfigType from 'config/typing';
import styled, { css } from 'styled-components';

export interface Props {
  color?: keyof ConfigType['COLORS'];
  size?: 10 | 12 | 13 | 14 | 15 | 16 | 18 | 20 | 24 | 25 | 28 | 35;
  weight?: 'regular' | 'bold' | 'light';
  lineHeight?: number;
  letterSpacing?: number;
}

export const typographyStyle = css<Props>`
  font-family: ${({ weight }) => {
    switch (weight || 'regular') {
      case 'bold':
        return Config.FONTS.SECONDARY;
      case 'regular':
        return Config.FONTS.PRIMARY;
      case 'light':
        return Config.FONTS.PRIMARY;
    }
  }};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? 0}px;
  font-size: ${({ size = 14 }) => `${size}px`};

  // fix for Safari when use in input elements
  line-height: ${({ size = 14, lineHeight }) =>
    `${lineHeight ?? 1.2 * size}px`};

  color: ${({ theme, color }) => theme.COLORS[color || 'PRIMARY_GRAY']};
`;

const Typography = styled.span<Props>`
  ${typographyStyle}
`;

export default Typography;
