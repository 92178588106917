import {
  Box,
  Stack,
  FormControl,
  Switch,
  Flex,
  Tooltip,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useContext, useState, useEffect } from 'react';
import ConfigureAccessRestriction from 'graphql/mutations/configureAccessRestriction';
import DisableAccessRestriction from 'graphql/mutations/disableAccessRestriction';
import {
  ChangeStationOpeningHoursMutation,
  ChangeStationOpeningHoursMutationVariables,
  ConfigureAccessRestrictionMutation,
  ConfigureAccessRestrictionMutationVariables,
  DisableAccessRestrictionMutation,
  DisableAccessRestrictionMutationVariables,
  EstablishmentType,
  StationAccessRestrictionQuery,
  StationAccessRestrictionQueryVariables,
} from 'generated/graphql';
import { useAuthMutation, useAuthQuery } from 'hooks';
import { StationContext } from 'pages/StationPage/StationContext';
import toastMessage from 'services/toast';
import StationAccessRestriction from 'graphql/queries/StationAccessRestriction';
import ChangeStationOpeningHours from 'graphql/mutations/changeStationOpeningHours';
import Card from 'pages/StationPage/Card';
import Container from 'atomic-components/organisms/Container';
import config from 'config';
import Dropdown from 'components/Dropdown';
import Toast from 'pages/StationPage/Toast';
import { useTranslation } from 'react-i18next';

const Access = () => {
  const { t } = useTranslation();
  const { id: stationId } = useContext(StationContext);

  const SELECTABLE_ESTABLISHMENTS: Array<string> = [
    'DEFAULT',
    'AIRPORT',
    'FUEL_STATION',
    'HOTEL',
    'INN',
    'MALL',
    'PARKING_LOT',
    'RECREATION_AREA',
    'RESTAURANT',
    'SUPERMARKET',
  ];

  const { data, loading: loadingData } = useAuthQuery<
    StationAccessRestrictionQuery,
    StationAccessRestrictionQueryVariables
  >(StationAccessRestriction, {
    variables: {
      where: {
        stationId,
      },
    },
  });

  const accessInfo = data?.station?.accessRestriction;
  const openingHoursInfo = data?.station?.openingHours;

  // This is to close when user navigates to another page with toast open
  const toastOnAnotherPage = useToast();
  const [description, setDescription] = useState({ value: '', error: false });
  const [establishment, setEstablishment] = useState({
    value: 'DEFAULT',
    error: false,
  });
  const [restrictedAccess, setRestrictedAccess] = useState(false);
  const [openingHours, setOpeningHours] = useState({
    value: openingHoursInfo || '',
    error: false,
  });
  const [toastVisible, setToastVisible] = useState(false);
  const [currentDescription, setCurrentDescription] = useState(
    accessInfo?.description
  );
  const [currentEstablishment, setCurrentEstablishment] = useState(
    accessInfo?.establishmentType
  );
  const [currentOpeningHours, setCurrentOpeningHours] =
    useState(openingHoursInfo);
  const [currentRestrictedAccess, setCurrentRestrictedAccess] = useState(
    accessInfo?.active
  );

  const [configureAccessRestriction, { loading: loadingConfigure }] =
    useAuthMutation<
      ConfigureAccessRestrictionMutation,
      ConfigureAccessRestrictionMutationVariables
    >(ConfigureAccessRestriction, {
      variables: {
        where: {
          stationId,
        },
        data: {
          description: description.value || '',
          establishmentType: establishment.value as EstablishmentType,
          openingHours: openingHours.value,
        },
      },
    });

  const [disableAccessRestriction, { loading: loadingDisable }] =
    useAuthMutation<
      DisableAccessRestrictionMutation,
      DisableAccessRestrictionMutationVariables
    >(DisableAccessRestriction, {
      variables: {
        where: {
          stationId,
        },
      },
    });

  const [changeStationOpeningHours, { loading: loadingOpening }] =
    useAuthMutation<
      ChangeStationOpeningHoursMutation,
      ChangeStationOpeningHoursMutationVariables
    >(ChangeStationOpeningHours, {
      variables: {
        data: {
          openingHours: openingHours.value || '',
        },
        where: {
          stationId,
        },
      },
    });

  const closeToast = () => {
    setToastVisible(false);
  };

  const openToast = () => {
    setToastVisible(true);
  };

  const isValid = {
    openingHours: openingHours.value?.length <= 50,
  };

  useEffect(() => {
    setCurrentDescription(accessInfo?.description || '');
    setCurrentEstablishment(
      accessInfo?.establishmentType || ('DEFAULT' as EstablishmentType)
    );
    setCurrentOpeningHours(openingHoursInfo || '');
    setCurrentRestrictedAccess(accessInfo?.active || false);
    setDescription({ error: false, value: accessInfo?.description || '' });
    setOpeningHours({
      error: false,
      value: openingHoursInfo || '',
    });
    setRestrictedAccess(accessInfo?.active || false);
    setEstablishment({
      error: false,
      value: accessInfo?.establishmentType || 'DEFAULT',
    });
  }, [accessInfo, openingHoursInfo]);

  useEffect(() => {
    const oldVariables = {
      description: currentDescription,
      establishment: currentEstablishment,
      restrictedAccess: currentRestrictedAccess,
      openingHours: currentOpeningHours,
    };
    const newVariables = {
      description: description.value,
      establishment: establishment.value,
      restrictedAccess,
      openingHours: openingHours.value,
    };

    if (JSON.stringify(oldVariables) !== JSON.stringify(newVariables)) {
      openToast();
    } else {
      closeToast();
    }
  }, [
    description,
    openingHours,
    establishment,
    restrictedAccess,
    toastVisible,
    currentDescription,
    currentEstablishment,
    currentOpeningHours,
    currentRestrictedAccess,
  ]);

  const validateFields = () => {
    if (establishment.value === 'DEFAULT') {
      setEstablishment({ ...establishment, error: true });
      return false;
    }
    if (!description.value) {
      setDescription({ ...description, error: true });
      return false;
    }
    return true;
  };

  const handleUpdateOldValues = async () => {
    setCurrentDescription(description.value);
    setCurrentEstablishment(establishment.value as EstablishmentType);
    setCurrentOpeningHours(openingHours.value);
    setCurrentRestrictedAccess(restrictedAccess);
  };
  const resetFields = async () => {
    setDescription({ error: false, value: currentDescription || '' });
    setEstablishment({
      error: false,
      value: currentEstablishment || 'DEFAULT',
    });
    setOpeningHours({ error: false, value: currentOpeningHours || '' });
    setRestrictedAccess(currentRestrictedAccess || false);
    closeToast();
    toastOnAnotherPage.closeAll();
  };

  const handleConfigureAccessRestriction = async () => {
    if (!restrictedAccess) {
      try {
        await disableAccessRestriction();
        await changeStationOpeningHours();
        await handleUpdateOldValues();
        toastMessage.success(
          t('station-page.config-tab.toast-successfully-message')
        );
      } catch (e) {
        toastMessage.error(t('station-page.config-tab.toast-error-message'));
      }
    } else {
      if (!validateFields()) return;
      try {
        await configureAccessRestriction();
        await changeStationOpeningHours();
        await handleUpdateOldValues();
        toastMessage.success(
          t('station-page.config-tab.toast-successfully-message')
        );
      } catch (e) {
        toastMessage.error(t('station-page.config-tab.toast-error-message'));
      }
    }
    closeToast();
    toastOnAnotherPage.closeAll();
  };
  const handleEstablishmentChange = (value: string) => {
    setEstablishment({ error: false, value });
  };

  const handleSetRestrictedAccess = () => {
    setEstablishment({ ...establishment, error: false });
    setDescription({ ...description, error: false });
    setRestrictedAccess(!restrictedAccess);
  };

  return (
    <Container loading={loadingData}>
      <Stack spacing="8">
        <Heading fontSize="2xl">
          {t('station-page.config-tab.access-tab.header.title')}
        </Heading>
        <Box maxWidth="2xl">
          <FormControl pb="8">
            <Flex align="center" pb="2">
              <FormLabel pr="2" m="0">
                {t('station-page.config-tab.access-tab.hours-input.title')}
              </FormLabel>
              <Tooltip
                hasArrow
                bg="gray.900"
                placement="right"
                label={t(
                  'station-page.config-tab.access-tab.hours-input.label'
                )}
              >
                <Stack>
                  <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                </Stack>
              </Tooltip>
            </Flex>
            <Stack spacing="2">
              <Input
                onChange={(e) =>
                  setOpeningHours({ ...openingHours, value: e.target.value })
                }
                value={openingHours.value}
                isRequired={restrictedAccess}
                maxLength={50}
              />
            </Stack>
          </FormControl>

          <Flex alignItems="center" gridGap="2" pb="2">
            <Heading fontSize="md">
              {t('station-page.config-tab.access-tab.restriction.inp.title')}
            </Heading>
            <Tooltip
              label={t(
                'station-page.config-tab.access-tab.restriction-inp.label'
              )}
              bg="gray.900"
              placement="right"
              hasArrow
            >
              <Stack>
                <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
              </Stack>
            </Tooltip>
          </Flex>
          <Card mb={72}>
            <Stack spacing="6">
              <Flex alignItems="center">
                <Switch
                  flexDirection="row"
                  display="flex"
                  alignItems="center"
                  isChecked={restrictedAccess}
                  onChange={() => handleSetRestrictedAccess()}
                  m="0"
                >
                  <Heading fontSize="sm" pl="2">
                    {t(
                      'station-page.config-tab.access-tab.enable-restriction.title'
                    )}
                  </Heading>
                </Switch>
              </Flex>
              <FormControl isRequired isInvalid={!!establishment.error}>
                <Flex align="center" pb="2">
                  <FormLabel pr="2" m="0">
                    {t(
                      'station-page.config-tab.access-tab.establishment-type.title'
                    )}
                  </FormLabel>
                  <Tooltip
                    hasArrow
                    label={t(
                      'station-page.config-tab.access-tab.establishment-type.label'
                    )}
                    placement="right"
                    bg="gray.900"
                  >
                    <Stack>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </Stack>
                  </Tooltip>
                </Flex>
                <Dropdown
                  options={SELECTABLE_ESTABLISHMENTS}
                  value={establishment.value}
                  format={(type) => t(config.LABELS.ESTABLISHMENT_TYPE[type])}
                  onChange={handleEstablishmentChange}
                  error={!!establishment.error}
                  disabled={!restrictedAccess}
                />
                <FormErrorMessage>
                  {t('station-page.config-tab.toast.required-field-error')}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!description.error} m="0">
                <Flex align="center" pb="2">
                  <FormLabel m="0" pr="2">
                    {t(
                      'station-page.config-tab.access-tab.restriction-type.title'
                    )}
                  </FormLabel>
                  <Tooltip
                    label={t(
                      'station-page.config-tab.access-tab.restriction-type.label'
                    )}
                    bg="gray.900"
                    placement="right"
                    hasArrow
                  >
                    <Stack>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </Stack>
                  </Tooltip>
                </Flex>
                <Stack spacing="2">
                  <Input
                    onChange={(e) =>
                      setDescription({
                        ...description,
                        value: e.target.value,
                      })
                    }
                    value={description.value}
                    disabled={!restrictedAccess}
                    isRequired={restrictedAccess}
                    onFocus={() =>
                      setDescription({ ...description, error: false })
                    }
                    fontSize="sm"
                    borderRadius="8"
                    maxLength={50}
                  />
                  <FormErrorMessage>
                    {t('station-page.config-tab.toast.required-field-error')}
                  </FormErrorMessage>
                </Stack>
              </FormControl>

              <Toast
                id={'accessRestriction'}
                onClick={handleConfigureAccessRestriction}
                resetFields={resetFields}
                loading={loadingConfigure || loadingDisable || loadingOpening}
                isVisible={toastVisible}
                isValid={isValid.openingHours}
                inputsValues={[
                  description.value,
                  establishment.value,
                  openingHours.value,
                  restrictedAccess,
                  toastVisible,
                ]}
              />
            </Stack>
          </Card>
        </Box>
      </Stack>
    </Container>
  );
};

export default Access;
