import { Box, Flex, Text } from '@chakra-ui/react';
import DialogModal from 'atomic-components/organisms/DialogModal';
import AuthContext from 'contexts/Auth/context';
import {
  GetStationOrganizationsQuery,
  GetStationOrganizationsQueryVariables,
} from 'generated/graphql';
import getStationOrganizations from 'graphql/queries/getStationOrganizations';
import { useAuthQuery } from 'hooks';
import Dropdown from 'new-components/Dropdown';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatOrgId } from 'services/format';
import ConfigureConnectorPricingConfig from '.';

type Props = {
  onClose: () => void;
  show: boolean;
  stations: {
    id: string;
    name: string;
  }[];
  refetchTableData: () => void;
  openModalAgain: () => void;
};

type SelectedValues =
  | {
      id: string;
      name: string;
    }
  | undefined;

const ConfigureConnectorPricingConfigFirstStepModal = ({
  show,
  onClose,
  stations,
  refetchTableData,
  openModalAgain,
}: Props) => {
  const { orgNameMap, selectedOrganizationIds } = useContext(AuthContext);
  const { t } = useTranslation();
  const [isStationDropdownOpen, setIsStationDropdownOpen] = useState(false);
  const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStation, setSelectedStation] =
    useState<SelectedValues>(undefined);
  const [selectedOrganization, setSelectedOrganization] =
    useState<SelectedValues>(undefined);

  const { data, loading } = useAuthQuery<
    GetStationOrganizationsQuery,
    GetStationOrganizationsQueryVariables
  >(getStationOrganizations, {
    variables: {
      where: {
        stationId: selectedStation?.id,
      },
    },
  });

  const handleSelectStation = (station: { id: string; name: string }) => {
    setSelectedStation(station);
    setIsStationDropdownOpen(false);
    setIsOrgDropdownOpen(false);
    setSelectedOrganization(undefined);
  };

  const handleSelectOrganization = (org: { id: string; name: string }) => {
    setSelectedOrganization(org);
    setIsOrgDropdownOpen(false);
  };

  const handleDialogModalButtonClick = () => {
    onClose();
    setIsModalVisible(true);
  };

  const formattedSelectValues = () => {
    const minimumChargeFee =
      data?.stationProfile?.minimumChargeFee?.find(
        (m) => m?.emspId === selectedOrganization?.id
      )?.chargeFee ?? 0;

    return {
      id: selectedStation?.id ?? '-',
      name: selectedStation?.name ?? '-',
      minimumChargeFee,
    };
  };

  const clearSelectedStationState = () => {
    setSelectedOrganization(undefined);
    setSelectedStation(undefined);
  };

  const allConnectorsAlreadyHavePricingConfig = useMemo(
    () =>
      data?.stationProfile?.connectors.every(
        (c) =>
          c.pricingConfig?.some(
            (p) => p?.emspId === selectedOrganization?.id
          ) && selectedOrganization?.id
      ),
    [selectedOrganization, data?.stationProfile]
  );

  const isButtonDisabled =
    !selectedOrganization ||
    !selectedStation ||
    allConnectorsAlreadyHavePricingConfig;

  return (
    <>
      <ConfigureConnectorPricingConfig.LastStepModal
        show={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        station={formattedSelectValues() ?? []}
        connectors={data?.stationProfile?.connectors ?? []}
        refetchTableData={refetchTableData}
        organizationId={selectedOrganization?.id ?? ''}
        openFirstModalAgain={openModalAgain}
        clearSelectedStationState={clearSelectedStationState}
      />
      <DialogModal
        header={{
          title: t('configure-connector-pricing-config.first-step-modal.title'),
        }}
        subtitle={t(
          'configure-connector-pricing-config.first-step-modal.subtitle'
        )}
        description={t(
          'configure-connector-pricing-config.first-step-modal.description'
        )}
        visible={show}
        onClose={() => onClose()}
        submitButton={{
          colorScheme: 'primary',
          onPress: () => handleDialogModalButtonClick(),
          text: t('configure-connector-pricing-config.first-step-modal.btn'),
          disabled: isButtonDisabled,
        }}
        size="md"
      >
        <Flex direction="column" h={10} mb={1}>
          <Flex w="full">
            <Box w={96} mr={6}>
              <Dropdown
                isOpen={isStationDropdownOpen}
                toggleDropdown={setIsStationDropdownOpen}
                onClickOutside={() => setIsStationDropdownOpen(false)}
                isBeingUsedInsideModal={true}
                content={() => (
                  <Dropdown.Container
                    style={{
                      minWidth: '384px',
                      maxHeight: '240px',
                      overflow: 'auto',
                    }}
                  >
                    <Dropdown.Section>
                      {stations.map((station) => (
                        <Dropdown.Row
                          onClick={() => handleSelectStation(station)}
                        >
                          <Text fontSize="sm">{station.name}</Text>
                        </Dropdown.Row>
                      ))}
                    </Dropdown.Section>
                  </Dropdown.Container>
                )}
              >
                <Dropdown.TriggerSelectContainer
                  isOpen={isStationDropdownOpen}
                  onClick={() => setIsStationDropdownOpen((state) => !state)}
                >
                  <Text
                    w={96}
                    fontSize="sm"
                    color={selectedStation ? 'gray.900' : 'gray.500'}
                  >
                    {selectedStation?.name ??
                      t(
                        'configure-connector-pricing-config.dropdown.station-default-text'
                      )}
                  </Text>
                </Dropdown.TriggerSelectContainer>
              </Dropdown>
            </Box>
            <Box ml="auto" w={56}>
              <Dropdown
                isOpen={isOrgDropdownOpen}
                toggleDropdown={setIsOrgDropdownOpen}
                onClickOutside={() => setIsOrgDropdownOpen(false)}
                isBeingUsedInsideModal={true}
                content={() => (
                  <Dropdown.Container
                    style={{
                      minWidth: '224px',
                      maxHeight: '260px',
                    }}
                  >
                    <Dropdown.Section>
                      <>
                        {data?.stationProfile?.emsps
                          ?.filter(
                            (emsp) =>
                              emsp?.id &&
                              selectedOrganizationIds?.includes(emsp.id)
                          )
                          .map((emsp) => {
                            const formattedOrgName = emsp?.id
                              ? formatOrgId(emsp.id, orgNameMap)
                              : '-';
                            return (
                              <Dropdown.Row
                                onClick={() =>
                                  handleSelectOrganization({
                                    id: emsp?.id ?? '-',
                                    name: formattedOrgName,
                                  })
                                }
                              >
                                <Text fontSize="sm">{formattedOrgName}</Text>
                              </Dropdown.Row>
                            );
                          })}
                      </>
                    </Dropdown.Section>
                  </Dropdown.Container>
                )}
              >
                <Dropdown.TriggerSelectContainer
                  isOpen={isOrgDropdownOpen}
                  loading={loading}
                  onClick={() => setIsOrgDropdownOpen((state) => !state)}
                  clickable={!!selectedStation}
                >
                  <Text maxWidth={40} fontSize="sm" noOfLines={1}>
                    {selectedOrganization?.name ??
                      t(
                        'configure-connector-pricing-config.dropdown.organization-default-text'
                      )}
                  </Text>
                </Dropdown.TriggerSelectContainer>
              </Dropdown>
            </Box>
          </Flex>
          {allConnectorsAlreadyHavePricingConfig && (
            <Text fontSize="xs" color="red.500" mt={2} pb={4} ml={0.5}>
              {t(
                'configure-connector-pricing-config.first-step-modal.connectors-already-have-pricing-config-error-message'
              )}
            </Text>
          )}
        </Flex>
      </DialogModal>
    </>
  );
};

export default ConfigureConnectorPricingConfigFirstStepModal;
