import styled from 'styled-components';

export const Container = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`;

export const TitleContainer = styled.div`
  padding: 20px 0;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 417px;
  overflow-y: auto;
  margin-bottom: 47px;
`;

export const ExportButtonContainer = styled.div`
  margin-right: 12px;
`;
