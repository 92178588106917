import Typography from 'components/Typography';
import React, { useState } from 'react';
import { Container, SelectorOption } from './styles';

interface Props<T> {
  options: readonly T[];
  format: (t: T) => string;
  value: T;
  onChange: (key: T) => void;
}

export default function Selector<T>({
  options,
  format,
  value,
  onChange,
}: Props<T>) {
  const isThisSelectorSelected = (option: T) =>
    JSON.stringify(option) === JSON.stringify(value);
  const [hover, setHover] = useState<number | false>(false);

  return (
    <Container>
      {options.map((option, i) => (
        <SelectorOption
          key={i}
          isActive={isThisSelectorSelected(option)}
          onClick={() => onChange(option)}
          onMouseEnter={() => setHover(i)}
          onMouseLeave={() => setHover(false)}
        >
          <Typography
            size={14}
            color={
              isThisSelectorSelected(option) || hover === i
                ? 'PRIMARY_GRAY'
                : 'SECONDARY_GRAY'
            }
          >
            {format(option)}
          </Typography>
        </SelectorOption>
      ))}
    </Container>
  );
}
