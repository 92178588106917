import { Cell, TableContainer } from 'components/Table/styles';
import styled from 'styled-components';

export const VoucherTableContainer = styled.div`
  height: 100%;
  ${Cell}:last-child, ${TableContainer} {
    overflow: visible;
  }

  > div {
    min-height: 42rem;
    justify-content: space-between;
  }

  [data-test='pagination-container'] {
    margin-top: 40px;
    position: relative;
  }

  [data-test='pagination-container'] > *:last-child > * {
    bottom: 0;
    position: absolute;
    width: 150px;
  }
`;
