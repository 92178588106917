import { createContext } from 'react';
import { VoucherClient } from '../client';
import { useVoucherDetailsClient } from '../client/graphql';

export type VoucherClientContextType = {
  getClient: () => () => VoucherClient;
};

export const VoucherClientContext = createContext<VoucherClientContextType>({
  getClient: () => () => ({
    listVoucher: () => ({
      data: { vouchers: null, vouchersSummary: null },
      loading: true,
    }),
    activateVoucher: () => new Promise<void>(() => {}),
    archiveVoucher: () => new Promise<void>(() => {}),
    getDrivers: () => ({
      data: { drivers: null, driversSummary: null },
      loading: true,
    }),
    getStations: () => ({
      data: { stationsProfile: null, stationsProfileSummary: null },
      loading: true,
    }),
    getVoucher: () => ({ data: { voucher: null }, loading: true }),
  }),
});

export const VoucherClientProvider: React.FC = ({ children }) => (
  <VoucherClientContext.Provider
    value={{ getClient: () => useVoucherDetailsClient }}
  >
    {children}
  </VoucherClientContext.Provider>
);
