import { CurrencyType } from 'generated/graphql';
import i18n from 'i18next';

type Maybe<T> = T | null | undefined;

export const DEFAULT_CURRENCY_SYMBOL = '$';

type FormatNumberOptions = {
  digits?: number;
};

type FormatUnitOptions = FormatNumberOptions & {
  showUnit?: boolean;
  currencyType?: CurrencyType;
};

const numberOptions = { digits: 2 };
const unitOptions = {
  ...numberOptions,
  showUnit: true,
};
const energyOptions: FormatUnitOptions = { ...unitOptions };
const currencyOptions: FormatUnitOptions = {
  ...unitOptions,
};

export const formatNumber = (
  n: Maybe<number>,
  options: FormatNumberOptions = numberOptions
): string =>
  i18n.format(n, 'number', undefined, {
    maximumFractionDigits: options.digits,
    minimumFractionDigits: options.digits,
  });

export const formatUsageRate = (
  n?: number,
  { showUnit, ...options }: FormatUnitOptions = unitOptions
) =>
  n === undefined
    ? '-'
    : `${i18n.format(n * 100, 'number', undefined, {
        minimumFractionDigits: options.digits,
        maximumFractionDigits: options.digits,
      })} ${showUnit ? '%' : ''}`;

export const formatEnergy = (
  number: number,
  { showUnit, ...options }: FormatUnitOptions = energyOptions
): string => {
  const suffix = showUnit ? 'kWh' : '';
  return `${i18n.format(number, 'energy', undefined, {
    maximumFractionDigits: options.digits,
    minimumFractionDigits: options.digits,
  })} ${suffix} `;
};

export const formatCurrency = (
  number: Maybe<number>,
  {
    showUnit = currencyOptions.showUnit,
    currencyType = currencyOptions.currencyType,
    digits = currencyOptions.digits,
  }: FormatUnitOptions = currencyOptions
) => {
  if (number === null || number === undefined) return '-';
  if (showUnit) {
    if (currencyType) {
      return i18n.format(number, 'currency', undefined, {
        style: 'currency',
        currency: currencyType,
      });
    }
    return `${DEFAULT_CURRENCY_SYMBOL} ${i18n.format(
      number,
      'currency',
      undefined,
      {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      }
    )}`;
  }
  return i18n.format(number, 'currency', undefined, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const formatCurrencyType = ({
  currencyType,
}: Partial<FormatUnitOptions>): string =>
  currencyType
    ? i18n
        .format(0, 'currency', undefined, {
          style: 'currency',
          currency: currencyType,
        })
        .replace(/[\s0-9,.].+/, '')
    : DEFAULT_CURRENCY_SYMBOL;

export const formatPower = (
  number?: number,
  options: FormatNumberOptions = numberOptions
): string => {
  if (number === undefined) return '-';
  return i18n.format(number / 1000, 'number', undefined, {
    minimumFractionDigits: options.digits,
  });
};
const capitalizeFirstLetter = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const formatOrgId = (
  orgId: string,
  orgNameMap: { [key: string]: string }
): string =>
  orgId in orgNameMap
    ? orgNameMap[orgId]
    : orgId
        .split('-')
        .map((str) => capitalizeFirstLetter(str))
        .join(' ');
