import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';

export function VoucherTableBlankState() {
  const { t } = useTranslation();
  return (
    <Flex
      position={'absolute'}
      zIndex={10}
      width={'100%'}
      justifyContent={'center'}
    >
      <Box
        marginTop={55}
        width={'min-content'}
        minWidth={'12rem'}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
      >
        <Flex margin={'auto'} justifyContent={'center'} mb={8}>
          <Icon size={128} type={'NEW_STYLED_VOUCHER'} color={'PRIMARY'} />
        </Flex>
        <Heading fontSize={'md'} fontWeight={700} color={'primary.500'} mb={2}>
          {t('payments.voucher-tab.table.no-vouchers.title')}
        </Heading>
        <Text fontSize={'md'} color={'gray.500'}>
          {t('payments.voucher-tab.table.no-vouchers.description')}
        </Text>
      </Box>
    </Flex>
  );
}
