import styled from 'styled-components';

export const Header = styled('section')`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 27px 27px 0 27px;
`;

export const Body = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  padding: 15px 24px 24px 24px;
`;

export const Label = styled('label')`
  display: flex;
  flex-flow: column nowrap;
`;

export const ButtonWrapper = styled('section')`
  align-self: flex-end;
`;
