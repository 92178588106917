import { createContext } from 'react';
import { VoucherDetails } from '../VoucherDetailsModal/services';

export type VoucherStatesContextType = {
  voucherDetails: VoucherDetails | null;
  voucherDetailsLoading: boolean;
  listVouchersLoading: boolean;
  voucherDetailsModalVisible: boolean;
  archiveVoucherModalVisible: boolean;
};

export const VoucherStatesContext = createContext<VoucherStatesContextType>({
  voucherDetails: null,
  voucherDetailsLoading: false,
  listVouchersLoading: false,
  voucherDetailsModalVisible: false,
  archiveVoucherModalVisible: false,
});
