import Config from 'config';
import React from 'react';
import {
  Area,
  AreaProps,
  AreaChart,
  CartesianGrid,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  AxisDomain,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  LineType,
} from 'recharts';
import { Container, AxisText, AxisTextStyle, TooltipWrapper } from './styles';

const graphColors = ['AVAILABILITY', 'BILLING', 'RECHARGES'] as const;

export type GraphColor = Extract<
  keyof typeof Config.COLORS,
  typeof graphColors[number]
>;

type TooltipContent<T> = React.ElementType<{
  payload: Record<KeyOf<T>, ValueOf<T>>;
}>;

type AxisType<T> = {
  [K in KeyOf<T>]: {
    dataKey: K;
    formatText?: (point: T[K]) => string;
    unit?: string;
    label?: LabelProps;
    domain?: readonly [AxisDomain, AxisDomain];
    ticks?: { transform?: string };
  };
}[KeyOf<T>];

type YAxisType<T> = AxisType<T> & {
  color: GraphColor;
  showAverage?: boolean;
  show?: boolean;
};

interface Props<T> {
  data: Record<KeyOf<T>, ValueOf<T>>[];
  x: AxisType<T>;
  y?: YAxisType<T>;
  yRight?: YAxisType<T>;
  tooltipContent?: TooltipContent<T>;
}

type ValueOf<T> = T[KeyOf<T>];
type KeyOf<T> = Extract<keyof T, string | number>;

const getGradientId = (color: GraphColor) => `${color}-GRADIENT`;

const xLabelProps = {
  position: 'top',
  dy: -20,
  style: AxisTextStyle,
  textAnchor: 'end',
} as const;
const yLabelProps = { ...xLabelProps } as const;
const yRightLabelProps = { ...yLabelProps } as const;

const axisLabelProps = <T extends {}>(
  defaultLabelProps: LabelProps,
  { unit, label = {} }: AxisType<T>
): LabelProps => ({
  ...defaultLabelProps,
  value: unit ? `[${unit}]` : undefined,
  ...label,
});

const defaultXTick = { transform: 'translate(0,10)' };
const defaultYTick = {
  transform: 'translate(-30,0)',
  textAnchor: 'start',
};
const defaultYRightTick = {
  transform: 'translate(10, 0)',
  textAnchor: 'start',
};

const axisCommonProps = <T extends {}>(
  { dataKey, domain, formatText, ticks }: AxisType<T>,
  defaultTick: { transform: string; textAnchor?: string }
): YAxisProps & XAxisProps => ({
  dataKey,
  domain,
  tickLine: false,
  axisLine: false,
  tick: ({ payload: { value }, ...props }) => (
    <AxisText
      {...props}
      transform={ticks?.transform ?? defaultTick.transform}
      textAnchor={defaultTick.textAnchor}
    >
      {formatText?.(value) ?? value ?? null}
    </AxisText>
  ),
});

const areaCommonProps = <T extends {}>({
  dataKey,
  color,
}: YAxisType<T>): AreaProps => ({
  type: 'monotone',
  fillOpacity: 1,
  fill: `url(#${getGradientId(color)})`,
  dataKey,
  stroke: Config.COLORS[color],
  strokeWidth: 2,
  dot: { fill: 'white' },
});

function wrapTooltipContent<T>(Wrapped: TooltipContent<T>) {
  return ({ active, payload: payloads }: any) => {
    if (!active || !payloads?.length) return null;
    const [{ payload }] = payloads;

    return (
      <TooltipWrapper>
        <Wrapped payload={payload} />
      </TooltipWrapper>
    );
  };
}

const shouldShow = <T extends {}>(axis?: YAxisType<T>): axis is YAxisType<T> =>
  !!axis && (axis.show ?? true);

const Graph = <T extends Record<KeyOf<T>, ValueOf<T>>>({
  data,
  x,
  y,
  yRight,
  tooltipContent,
}: Props<T>) => {
  const calculateChartAverage = (axisType: AxisType<T>) => {
    const values = data.map(
      (point) => point[axisType.dataKey] as unknown as number
    );
    const sum = values.reduce((a, b) => a + b, 0);
    const average = sum / values.length;
    return average;
  };

  const generateAverageAreaProps = (axisType: AxisType<T>, yAxisId: string) => {
    const average = calculateChartAverage(axisType);
    const yAxisType = axisType as YAxisType<T>;
    return {
      type: 'monotone' as LineType,
      fillOpacity: 0,
      dataKey: () => average,
      stroke: Config.COLORS[yAxisType.color],
      strokeDasharray: '10 5',
      strokeWidth: 2,
      yAxisId,
      activeDot: false,
    };
  };

  return (
    <Container>
      <ResponsiveContainer width="98%" height="100%">
        <AreaChart data={data} margin={{ top: 40, right: -20, left: -10 }}>
          <defs>
            {graphColors.map((colorName) => (
              <linearGradient
                id={getGradientId(colorName)}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
                key={colorName}
              >
                <stop
                  offset="5%"
                  stopColor={Config.COLORS[colorName]}
                  stopOpacity={0.5}
                />
                <stop
                  offset="95%"
                  stopColor={Config.COLORS[colorName]}
                  stopOpacity={0}
                />
              </linearGradient>
            ))}
          </defs>

          <XAxis
            {...axisCommonProps(x, defaultXTick)}
            label={axisLabelProps(xLabelProps, x)}
            interval="preserveStartEnd"
          />
          {shouldShow(y) && (
            <YAxis
              {...axisCommonProps(y, defaultYTick)}
              label={axisLabelProps(yLabelProps, y)}
              yAxisId="left"
              orientation="left"
            />
          )}
          {shouldShow(yRight) && (
            <YAxis
              {...axisCommonProps(yRight, defaultYRightTick)}
              label={axisLabelProps(yRightLabelProps, yRight)}
              yAxisId="right"
              orientation="right"
            />
          )}

          <CartesianGrid strokeOpacity="0.25" />

          {shouldShow(y) && <Area {...areaCommonProps(y)} yAxisId="left" />}
          {shouldShow(yRight) && (
            <Area {...areaCommonProps(yRight)} yAxisId="right" />
          )}

          {/* Show average of charges */}
          {shouldShow(y) && y.showAverage && (
            <Area {...generateAverageAreaProps(y, 'left')} />
          )}
          {/* Show average of charges consumption */}
          {shouldShow(yRight) && yRight.showAverage && (
            <Area {...generateAverageAreaProps(yRight, 'right')} />
          )}

          {tooltipContent && (
            <Tooltip content={wrapTooltipContent(tooltipContent)} />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default Graph;
