import { gql } from '@apollo/client';

export default gql`
  query calculateSessionPrice(
    $sessionId: String!
    $duration: Int!
    $energyConsumed: Float!
  ) {
    calculateSessionPrice(
      sessionId: $sessionId
      duration: $duration
      energyConsumed: $energyConsumed
    )
  }
`;
