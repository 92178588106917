import React from 'react';
import PaymentMethodInfo, {
  Props as PaymentMethodInfoProps,
} from 'new-components/PaymentMethodInfo';
import { useTranslation } from 'react-i18next';
import { Container, Title } from './styles';

const PaymentMethod: React.FC<PaymentMethodInfoProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t('recharge-modal.payment-method.title')}</Title>
      <PaymentMethodInfo {...props}></PaymentMethodInfo>
    </Container>
  );
};

export default PaymentMethod;
