import styled from 'styled-components';
import { BodyTypography, H3, H6 } from 'new-components/Typographies/styles';

export const Container = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  height: 100%;
  width: 100%;
  padding: 12px;
  background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  border-radius: 8px;
`;

export const Title = styled(H6)`
  font-size: 10px;
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;

export const Date = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;
export const Hour = styled(H3)``;
