import Card from 'components/Card';
import Table, { Column } from 'components/Table';
import {
  OrganizationGroupsQuery,
  OrganizationGroupsQueryVariables,
} from 'generated/graphql';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import React, { useState, useContext } from 'react';
import { H5 } from 'new-components/Typographies/styles';
import CreateAccessGroupModal from 'atomic-components/organisms/modals/CreateAccessGroup';
import { Icon } from 'new-components/Icon';
import AccessGroupsModal from 'atomic-components/organisms/modals/AccessGroups';
import organizationGroups from 'graphql/queries/organizationGroups';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import {
  TypographyStyled,
  ButtonWrapper,
  TypographData,
  OrderTableContainer,
  Container,
  ColumnContainer,
} from './styles';

type Props = NonNullable<OrganizationGroupsQuery['groups'][number]>;

const AccessRestriction: React.FC = () => {
  const { t } = useTranslation();
  const { orgNameMap } = useContext(AuthContext);
  const columns: Column<Props>[] = [
    {
      Header: t('consumers.access-restriction.table.group-name.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { name },
        },
      }) => <H5>{name}</H5>,
    },
    {
      Header: t('profile-dropdown.organization-section.singular-title'),
      disableSortBy: false,
      disableFilters: false,
      Cell: ({
        row: {
          // @ts-ignore
          original: {
            // @ts-ignore
            organization: { id },
          },
        },
      }) => <>{formatOrgId(id, orgNameMap)}</>,
    },
    {
      Header: t('consumers.access-restriction.table.users.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            driversSummary: { count },
          },
        },
      }) => (
        <ColumnContainer>
          <Icon type="NEW_USER" color="PRIMARY_GRAY" size={20} />
          <TypographData>{count || 0}</TypographData>
        </ColumnContainer>
      ),
    },
    {
      Header: t('consumers.access-restriction.table.stations.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            stationsProfileSummary: { count },
          },
        },
      }) => (
        <>
          <Icon type="NEW_EV_STATION" color="PRIMARY_GRAY" size={20} />
          <TypographData>{count || 0}</TypographData>
        </>
      ),
    },
  ];

  const hasScopes = useHasScopes();
  const [groupModal, setGroupModal] = useState<{
    show: boolean;
    id: string;
    name: string;
    orgId: string;
  }>({
    show: false,
    id: '-',
    name: '-',
    orgId: '-',
  });

  const { data, error, loading, refetch } = useAuthQuery<
    OrganizationGroupsQuery,
    OrganizationGroupsQueryVariables
  >(organizationGroups);

  const handleGroupClick = (id: string, name: string, orgId: string) =>
    hasScopes(['driver:read']) &&
    setGroupModal({ id, show: true, name, orgId });

  const handleModalClose = () =>
    hasScopes(['driver:read']) && setGroupModal({ ...groupModal, show: false });

  const [createGroupVisible, setCreateGroupVisible] = useState(false);

  const handleCloseModal = () => setCreateGroupVisible(false);
  return (
    <Container>
      <Flex
        flexDirection="column"
        gap={4}
        data-test="access-restriction-container"
      >
        <Heading fontSize="md" mt={2}>
          {t('customers.access-restriction.header.title')}
        </Heading>
        {hasScopes(['group:create']) && (
          <TypographyStyled color="SECONDARY_GRAY">
            {t('customers.access-restriction.subtitle')}
          </TypographyStyled>
        )}
        {hasScopes(['group:create']) && (
          <ButtonWrapper>
            <Button size="sm" onClick={() => setCreateGroupVisible(true)}>
              {t('customers.access-restriction.create-group-btn.title')}
            </Button>
          </ButtonWrapper>
        )}
      </Flex>
      <Card
        minHeight={800}
        error={error}
        loading={loading}
        type="complex"
        noCardPadding={true}
      >
        <OrderTableContainer>
          {data?.groups && (
            <Table<Props>
              isFullWidth={true}
              columns={columns}
              data={data?.groups ? data.groups : []}
              columnMaxWidth={50}
              onRowPress={({
                original: {
                  id,
                  name,
                  organization: { id: orgId },
                },
              }) => handleGroupClick(id, name, orgId)}
            />
          )}
        </OrderTableContainer>
      </Card>
      {hasScopes(['group:create']) && (
        <CreateAccessGroupModal
          onGroupCreated={refetch}
          show={createGroupVisible}
          onClose={handleCloseModal}
        />
      )}
      {hasScopes(['group:read']) && groupModal.show && (
        <AccessGroupsModal
          updateListGroups={refetch}
          name={groupModal.name}
          id={groupModal.id}
          show={groupModal.show}
          orgId={groupModal.orgId}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};

export default AccessRestriction;
