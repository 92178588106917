import React from 'react';
import Table, { Column } from 'components/Table';
import {
  FormattedSession,
  sessionFieldLabelTable,
} from 'services/formatSessionRow';
import { getColumnOptions } from 'services/formatSessionTable';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import { Container, SessionTableContainer } from './styles';

const columns: ReadonlyArray<keyof Partial<FormattedSession>> = [
  'energyConsumed',
  'orderTotalCost',
  'emspId',
  'stationName',
  'cpoId',
  'date',
  'begin',
  'end',
  'duration',
  'status',
] as const;

export type Props<T extends Partial<FormattedSession>> = {
  data?: T[];
  error: boolean;
  fetchData: () => void;
  handleSessionRowClick: (id?: string, emspId?: string, cpoId?: string) => void;
  loading: boolean;
  pageSize: number;
};

function Sessions<T extends Partial<FormattedSession>>({
  data,
  error,
  fetchData,
  handleSessionRowClick,
  loading,
  pageSize,
}: Props<T>) {
  const { t } = useTranslation();
  const selectedColumns: Column<T>[] = Object.entries(sessionFieldLabelTable)
    .filter(([key]) => columns.includes(key as keyof Partial<FormattedSession>))
    .map(([key, label]) => getColumnOptions(key, t(label)));

  return (
    <Container>
      <Card
        minHeight={(window.innerHeight / 100) * 74}
        maxHeight={(window.innerHeight / 100) * 74}
        loading={loading}
        type="complex"
        error={error}
        noCardPadding={true}
      >
        <SessionTableContainer>
          <Table<T>
            columnMaxWidth={50}
            columns={selectedColumns}
            data={data || []}
            fetchData={fetchData}
            loading={loading}
            onRowPress={({ original: { id, emspId, cpoId } }) =>
              handleSessionRowClick(id, emspId, cpoId)
            }
            pageCount={Math.ceil((data?.length || 0) / pageSize)}
            paginated
            isFullWidth={true}
          />
        </SessionTableContainer>
      </Card>
    </Container>
  );
}

export default Sessions;
