import { gql } from '@apollo/client';

export default gql`
  mutation ForceStopCharge(
    $where: ForceStopChargeWhereInput!
    $data: ForceStopChargeDataInput!
  ) {
    forceStopCharge(where: $where, data: $data) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;
