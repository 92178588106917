import styled from 'styled-components';

export const CardAtom = styled.div<{
  width: string;
  minHeight: string;
  marginTop: string;
}>`
  background-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  display: flex;
  width: ${({ width }) => width};
  margin-top: ${({ marginTop }) => marginTop};
  padding: 25px 16px 25px 16px;
  min-height: ${({ minHeight }) => minHeight};
`;
