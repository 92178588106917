import config from 'config';
import { LanguageCode } from 'generated/graphql';

export const getOrgCountryDDIPhoneNumber = () => {
  switch (config.COUNTRY) {
    case 'PA':
      return '+507';
    case 'BO':
      return '+591';
    default:
      return '+55';
  }
};

export const getOrgCountryPhoneNumberMask = () => {
  switch (config.COUNTRY) {
    case 'BO':
      return '# ### ####';
    case 'PA':
      return undefined;
    default:
      return '(##) # ####-####';
  }
};

export const getOrgCountryMinimumPhoneNumberLength = () => {
  switch (config.COUNTRY) {
    case 'PA':
      return 7;
    case 'BO':
      return 8;
    default:
      return 11;
  }
};

export const getOrgCountryMaxPhoneNumberLength = () => {
  switch (config.COUNTRY) {
    case 'PA':
      return 8;
    default:
      return undefined;
  }
};

export const getLanguage = (language?: LanguageCode) => {
  switch (language) {
    case 'es_ES':
      return config.COUNTRY === 'UY' ? 'es_UY' : 'es_ES';
  }
};
