import {
  extendTheme,
  withDefaultColorScheme,
  ThemeConfig,
  cssVar,
} from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import Config from 'config';

const chakraConfig: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const tooltipArrowBg = cssVar('popper-arrow-bg'); // https://github.com/chakra-ui/chakra-ui/issues/4695
const Tooltip = {
  baseStyle: {
    px: '4',
    py: '2',
    background: 'gray.900',
    [tooltipArrowBg.variable]: 'colors.gray.900',
  },
};

const Input = {
  variants: {
    outline: () => ({
      field: {
        bg: 'white',
        borderColor: 'gray.100',
        fontSize: 'sm',
        color: 'gray.900',
        _placeholder: {
          color: 'gray.500',
        },
        _hover: {
          borderColor: 'gray.200',
        },
        _disabled: {
          bg: 'gray.100',
          color: 'gray.600',
          opacity: '1',
        },
      },
    }),
    filled: () => ({
      field: {
        bg: 'gray.50',
        color: 'gray.900',
        fontSize: 'sm',
        borderWidth: 'thin',
        _hover: {
          bg: 'gray.50',
        },
      },
    }),
  },
  defaultProps: {
    errorBorderColor: 'error.500',
    focusBorderColor: 'primary.500',
  },
};

const Select = {
  variants: {
    outline: () => ({
      field: {
        bg: 'white',
        borderColor: 'gray.100',
      },
    }),
  },
  defaultProps: {
    errorBorderColor: 'error.500',
    focusBorderColor: 'primary.500',
  },
};

const Textarea = {
  variants: {
    outline: () => ({
      bg: 'white',
      borderColor: 'gray.100',
      color: 'gray.900',
      fontSize: 'sm',
      _hover: {
        borderColor: 'gray.200',
      },
      _disabled: {
        bg: 'gray.100',
        color: 'gray.600',
        opacity: '1',
      },
    }),
  },
  defaultProps: {
    errorBorderColor: 'error.500',
    focusBorderColor: 'primary.500',
  },
};

const Skeleton = {
  defaultProps: {
    startColor: 'gray.50',
    endColor: 'gray.100',
  },
  baseStyle: {
    height: '3',
  },
};

const Text = {
  baseStyle: {
    color: 'gray.900',
    whiteSpace: 'pre-wrap',
  },
};

const Heading = {
  baseStyle: {
    color: 'gray.900',
    fontWeight: 'normal',
  },
};

const FormLabel = {
  baseStyle: {
    color: 'gray.900',
    lineHeight: 'short',
    fontFamily: 'heading',
  },
};

const Tabs = {
  variants: {
    line: {
      tabs: {
        width: '100%',
      },
      tab: {
        fontSize: 'sm',
        color: 'gray.500',
        _hover: {
          borderColor: 'gray.500',
          color: 'gray.900',
        },
        _selected: {
          borderColor: 'primary.500',
          color: 'gray.900',
          _hover: {
            borderColor: 'primary.500',
          },
          _active: {
            bg: 'gray.50',
          },
          shadow: 'none',
        },
      },
      tablist: {
        borderColor: 'white',
        boxShadow: 'sm',
        zIndex: '1',
        position: 'relative',
        fontFamily: 'heading',
        bg: 'white',
        width: '100%',
      },
      tabpanels: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '78vh',
      },
    },
  },
};

const Button = {
  variants: {
    solid: {
      color: Config.COLORS.BUTTON_TEXT_COLOR,
    },
  },
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
  },
};

const Switch = {
  baseStyle: {
    track: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
};

const Table = {
  variants: {
    simple: {
      thead: {
        h: '12',
        bg: 'gray.50',
      },

      th: {
        color: 'gray.500',
        fontWeight: 'bold',
        fontSize: 'sm',
        textTransform: 'none',
        fontFamily: Config.FONTS.PRIMARY,
      },
      td: {
        fontSize: 'sm',
      },
    },
  },
};

export const theme = extendTheme(
  {
    config: chakraConfig,
    fonts: {
      body: Config.FONTS.PRIMARY,
      heading: Config.FONTS.SECONDARY,
      mono: Config.FONTS.TERTIARY,
    },
    shadows: {
      outline: 'none',
    },
    colors: {
      primary: Config.COLOR_SCHEME.PRIMARY,
      gray: Config.COLOR_SCHEME.GRAY,
      link: Config.COLOR_SCHEME.LINK,
      error: Config.COLOR_SCHEME.ERROR,
      success: Config.COLOR_SCHEME.SUCCESS,
      charging: Config.COLOR_SCHEME.CHARGING,
      available: Config.COLOR_SCHEME.AVAILABLE,
      secondary: Config.COLOR_SCHEME.SECONDARY,
      reserve: Config.COLOR_SCHEME.RESERVE,
      inoperative: Config.COLOR_SCHEME.INOPERATIVE,
    },
    components: {
      Steps,
      Tooltip,
      Button,
      Input,
      Select,
      Text,
      Heading,
      Textarea,
      FormLabel,
      Tabs,
      Switch,
      Table,
      Skeleton,
    },
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
  })
);
