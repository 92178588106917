import { ConnectorsListFieldsFragment } from 'generated/graphql';
import { Icon } from 'new-components/Icon';
import React from 'react';
import { formatCurrency, formatEnergy } from 'services/format';
import secondsToTime from 'services/secondsToTime';
import CurrencyTypeIcon from 'atomic-components/atoms/CurrencyTypeIcon';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { Container, SummaryInfoContainer, SummaryInfoText } from './styles';

type Props = {
  session: NonNullable<ConnectorsListFieldsFragment['currentSession']>;
};

const SummaryInfo: React.FC<Props> = ({
  session: { energyConsumed, duration, order, emspId },
}) => {
  const totalCost = order ? order.totalCost : 0;
  const time = secondsToTime(duration);

  const defaultCurrencyTypes = useDefaultCurrencyTypes();

  const currencyType =
    order?.currencyType ??
    getOrganizationDefaultCurrencyType(defaultCurrencyTypes, emspId);

  return (
    <Container>
      <SummaryInfoContainer>
        <Icon type="NEW_CLOCK" size={16} color="PRIMARY_GRAY" />
        <SummaryInfoText>{time}</SummaryInfoText>
      </SummaryInfoContainer>
      <SummaryInfoContainer>
        <Icon type="NEW_AC_CHARGER" size={16} color="PRIMARY_GRAY" />
        <SummaryInfoText>
          {energyConsumed ? formatEnergy(energyConsumed) : '-'}
        </SummaryInfoText>
      </SummaryInfoContainer>
      <SummaryInfoContainer>
        <CurrencyTypeIcon currencyType={currencyType} />
        <SummaryInfoText>
          {totalCost
            ? formatCurrency(totalCost, {
                showUnit: false,
                digits: 2,
              })
            : '-'}
        </SummaryInfoText>
      </SummaryInfoContainer>
    </Container>
  );
};

export default SummaryInfo;
