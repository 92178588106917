import { StationsMonitoringQuery, StationStatus } from 'generated/graphql';
import Modal from 'new-components/Modal';
import StationCard from 'new-components/StationCard';
import { KnownTab, StationPage, tabs } from 'pages/StationPage';
import React, { useState } from 'react';
import { scrollIntoView } from 'services/dom';
import LoadingComponent from 'new-components/LoadingComponent';
import ErrorComponent from 'new-components/ErrorComponent';
import { IconButton } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import config from 'config';
import { TemplateData } from 'pages/Monitoring/repository';
import { Namespace, TFunction } from 'react-i18next';
import FilterContainer from 'pages/Monitoring/FilterContainer';
import { StationMonitoringStatus } from 'pages/Monitoring/types';
import MonitoringPageHelper from 'pages/Monitoring/helper';
import Map from '../../../pages/Monitoring/Map/index';
import {
  CardContainer,
  MapContainer,
  Page,
  LoadingOrErrorContainer,
  PageContent,
  StationsCardsContainer,
  StationsCardsContent,
  ContentContainer,
} from '../../../pages/Monitoring/styles';
import { MapHeader } from '../../../pages/Monitoring/Map/MapHeader';

type Station = StationsMonitoringQuery['stations'][0];

interface MonitoringPageProps {
  templateData: TemplateData;
  helper: MonitoringPageHelper;
  props: {
    setStatusSelected: (
      statusSelected: StationMonitoringStatus[] | undefined
    ) => void;
    t: TFunction<Namespace<'common'>, undefined>;
    statusSelected: StationMonitoringStatus[] | undefined;
  };
}

const MonitoringTemplate: React.FC<MonitoringPageProps> = ({
  templateData: { data, error, loading },
  props: { statusSelected, setStatusSelected, t },
  helper,
}) => {
  const [currentTab, setCurrentTab] = useState<KnownTab>(tabs[0]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const TabComponent = currentTab.component;
  // id 3 is equivalent to station logs page
  const stationLogsPage = tabs.find((tab) => tab.id === 3);

  const [selectedStationId, setSelectedStationId] = useState<
    Station['id'] | undefined
  >();

  const [showModal, setShowModal] = useState<boolean>(false);

  const stations = data?.stations ?? [];

  const stationsStatusCount = stations
    .map(({ status }) => status)
    .reduce(
      (statusCount, status) => ({
        ...statusCount,
        [status]: (statusCount[status] || 0) + 1,
      }),
      {} as Record<StationStatus, number>
    );

  const renderStationCards = () =>
    loading ? (
      <LoadingOrErrorContainer>
        <LoadingComponent size={51} />
      </LoadingOrErrorContainer>
    ) : (
      stations?.map((station) => (
        <CardContainer
          key={station.chargePointId}
          className={station.chargePointId}
        >
          <StationCard
            title={station.name}
            station={station}
            onClick={setSelectedStationId}
            active={station.id === selectedStationId}
          />
        </CardContainer>
      ))
    );
  const renderContent = () => (
    <PageContent>
      {error && !data ? (
        <LoadingOrErrorContainer>
          <ErrorComponent size="large" />
        </LoadingOrErrorContainer>
      ) : (
        <StationsCardsContainer>
          <ContentContainer>
            <MapHeader loading={loading} statusCount={stationsStatusCount} />
            <IconButton
              aria-label={t('monitoring.over-filterCard.button-aria')}
              colorScheme="gray"
              variant="outline"
              css={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
                backgroundColor: config.COLORS.WHITE,
                border: `1px solid ${config.COLORS.TERTIARY_GRAY}`,
              }}
              size="sm"
              icon={<Icon type="NEW_FILTER" color="PRIMARY_GRAY" size={20} />}
              onClick={() => setFilterOpen(!filterOpen)}
            />
          </ContentContainer>
          <FilterContainer
            helper={{
              handleChangePreStatusSelected:
                helper.handleChangePreStatusSelected,
              handleStatusTextSelect: helper.handleStatusTextSelect,
              handleButtonDisabled: helper.handleButtonDisabled,
              handleSaveStatusSelected: helper.handleSaveStatusSelected,
            }}
            onCloseModal={() => setFilterOpen(false)}
            setStatusSelected={setStatusSelected}
            statusSelected={statusSelected}
            isOpen={filterOpen}
            t={t}
          />
          <StationsCardsContent>{renderStationCards()}</StationsCardsContent>
        </StationsCardsContainer>
      )}
      <MapContainer>
        <Map
          scrollIntoView={scrollIntoView(document, 'class')}
          changeSelectedStation={[selectedStationId, setSelectedStationId]}
          stations={stations}
          onAlarmPress={() => {
            if (stationLogsPage) setCurrentTab(stationLogsPage);
            setShowModal(true);
          }}
          onOpenStationPage={() => {
            setCurrentTab(tabs[0]);
            setShowModal(true);
          }}
        />
      </MapContainer>
    </PageContent>
  );

  return (
    <Page>
      {renderContent()}
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <StationPage
          stationId={selectedStationId!}
          currentTab={currentTab}
          onTabChange={setCurrentTab}
        >
          <TabComponent />
        </StationPage>
      </Modal>
    </Page>
  );
};

export default MonitoringTemplate;
