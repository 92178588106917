import React from 'react';
import { Trans } from 'react-i18next';
import {
  FooterContainer,
  Status,
  Label,
  ConnectorsInfoContainer,
  ConnectorsInfoLabel,
} from './styles';

type Props = {
  availableConnectors: number;
  chargingConnectors: number;
  inoperativeConnectors: number;
  unknownConnectors: number;
};

const ConnectorsSummaryFooter: React.FC<Props> = ({
  availableConnectors,
  chargingConnectors,
  inoperativeConnectors,
  unknownConnectors,
}) => (
  <FooterContainer>
    <ConnectorsInfoContainer>
      <Trans
        i18nKey="home.connectors-summary.available-connectors"
        components={{ ConnectorsInfoLabel: <ConnectorsInfoLabel /> }}
      ></Trans>
      <Status />
      <Label>{availableConnectors}</Label>
    </ConnectorsInfoContainer>

    <ConnectorsInfoContainer>
      <Trans
        i18nKey="home.connectors-summary.charging-connectors"
        components={{ ConnectorsInfoLabel: <ConnectorsInfoLabel /> }}
      ></Trans>
      <Status status="CHARGING" />
      <Label>{chargingConnectors}</Label>
    </ConnectorsInfoContainer>

    <ConnectorsInfoContainer>
      <Trans
        i18nKey="home.connectors-summary.inoperative-connectors"
        components={{ ConnectorsInfoLabel: <ConnectorsInfoLabel /> }}
      ></Trans>
      <Status status="INOPERATIVE" />
      <Label>{inoperativeConnectors}</Label>
    </ConnectorsInfoContainer>

    <ConnectorsInfoContainer>
      <Trans
        i18nKey="home.connectors-summary.offline-connectors"
        components={{ ConnectorsInfoLabel: <ConnectorsInfoLabel /> }}
      ></Trans>
      <Status status="UNKNOWN" />
      <Label>{unknownConnectors}</Label>
    </ConnectorsInfoContainer>
  </FooterContainer>
);

export { ConnectorsSummaryFooter };
