import Graph from 'components/Graph';
import Typography from 'components/Typography';
import Config from 'config';
import { SessionsTimelineQuery } from 'generated/graphql';
import React, { useMemo, useState } from 'react';
import { formatEnergy } from 'services/format';
import { Checkbox, FormLabel as Label } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  GraphContainer,
  GraphOption,
  GraphOptionDecoration,
  GraphOptionsContainer,
} from './styles';

type Summary = NonNullable<
  SessionsTimelineQuery['sessionsSummaryPerDateTime'][number]
>;
type FormattedSummary = Omit<Summary['sessionsSummary'], 'timeCharging'> &
  Pick<Summary, 'from'>;

interface Props {
  sessionsSummariesPerDateTime: Summary[];
}

const SessionsTimeline: React.FC<Props> = ({
  sessionsSummariesPerDateTime,
}) => {
  const { t } = useTranslation();
  const [showEnergyConsumed, setEnergyConsumed] = useState(true);
  const [showCount, setCount] = useState(true);
  const [showAverage, setAverage] = useState(true);

  const formatSummary = (summary: Summary): FormattedSummary => ({
    from: summary?.from,
    energyConsumed: summary.sessionsSummary.energyConsumed,
    count: summary?.sessionsSummary.count,
  });

  const summaries = useMemo(
    () => sessionsSummariesPerDateTime.map(formatSummary),
    [sessionsSummariesPerDateTime]
  );

  return (
    <>
      <GraphContainer>
        <Graph<FormattedSummary>
          data={summaries}
          x={{
            dataKey: 'from',
          }}
          y={{
            dataKey: 'energyConsumed',
            unit: 'kWh',
            color: 'BILLING',
            formatText: (energy) =>
              formatEnergy(energy, { showUnit: false, digits: 0 }),
            show: showEnergyConsumed,
            showAverage,
          }}
          yRight={{
            dataKey: 'count',
            color: 'RECHARGES',
            show: showCount,
            showAverage,
          }}
          tooltipContent={({ payload: { count, energyConsumed, from } }) => (
            <>
              {/* @ts-ignore FIXME: */}
              <Typography>
                {t('home.sessions-timeline-graph.consumed-energy')}:{' '}
                {formatEnergy(energyConsumed as number)}
              </Typography>
              <Typography>
                {t('home.sessions-timeline-graph.sessions-count')}: {count}
              </Typography>
              <Typography>{from}</Typography>
            </>
          )}
        />
      </GraphContainer>
      <GraphOptionsContainer>
        <GraphOption>
          <GraphOptionDecoration color={Config.COLORS.BILLING} />
          <Label htmlFor="energy" color="gray.500" fontSize="xs" mr={2} mb={0}>
            {t('home.sessions-timeline.energy-option')}
          </Label>
          <Checkbox
            colorScheme="success"
            id="energy"
            onChange={() =>
              setEnergyConsumed((showEnergyConsumed) => !showEnergyConsumed)
            }
            isChecked={showEnergyConsumed}
            mb={0}
          />
        </GraphOption>
        <GraphOption>
          <GraphOptionDecoration color={Config.COLORS.RECHARGES} />
          <Label
            htmlFor="recharge"
            color="gray.500"
            fontSize="xs"
            mr={2}
            mb={0}
          >
            {t('home.sessions-timeline.sessions-option')}
          </Label>
          <Checkbox
            colorScheme="charging"
            id="recharge"
            onChange={() => setCount((showCount) => !showCount)}
            isChecked={showCount}
            mb={0}
          />
        </GraphOption>

        <GraphOption>
          <Label htmlFor="average" color="gray.500" fontSize="xs" mr={2} mb={0}>
            {t('home.sessions-timeline.show-average')}
          </Label>
          <Checkbox
            id="average"
            onChange={() => setAverage((showAverage) => !showAverage)}
            isChecked={showAverage}
            mb={0}
          />
        </GraphOption>
      </GraphOptionsContainer>
    </>
  );
};

export default SessionsTimeline;
