import styled from 'styled-components';
import Config from 'config';
import { Typography } from 'components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto 0 0;
`;

export const BreadcrumbItem = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  font-weight: regular;
  color: ${Config.COLORS.SECONDARY_GRAY};
`;

export const Path = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Divider = styled.span`
  margin: auto 8px;
`;
