import Card from 'components/Card';
import DateDropdown from 'components/DateDropdown';
import ExportButton from 'components/ExportButton';
import { transformToOrderSortInput } from 'components/Filter/helper';
import Table, { Column } from 'components/Table';
import {
  OrdersHistoryQuery,
  OrdersHistoryQueryVariables,
} from 'generated/graphql';
import ordersHistory from 'graphql/queries/ordersHistory';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import moment from 'moment';
import ErrorComponent from 'new-components/ErrorComponent';
import RechargeModal from 'new-components/RechargeModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExportOrders } from 'services/export/orders';
import {
  formatOrderFields,
  FormattedOrder,
  getColumnOptions,
  orderFieldLabelTable,
} from 'services/formatOrderRow';
import { TimeRange, TIME_PERIODS, TIME_PERIODS_LIST } from 'services/timeRange';
import { TranslationKeyType } from 'types/translation';
import toast from '../../../services/toast';
import {
  ButtonContainer,
  CardWrapper,
  Container,
  OrderTableContainer,
} from './styles';

const defaultPagination = {
  pageIndex: 0,
  pageSize: 50,
  sorts: [] as any,
};

const columns: Array<keyof Partial<FormattedOrder>> = [
  'orderId',
  'stationName',
  'driverId',
  'createdAt',
  'orderCapturedOrPreAuthorizedValue',
  'paymentMethod',
  'status',
];

const now = moment();

const OrderCard: React.FC = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();

  const placeholderValue = TIME_PERIODS.WEEK;

  const [{ pageIndex, pageSize, sorts }, setPagination] =
    useState(defaultPagination);
  const [timeRange, setTimeRange] = useState<TimeRange>(placeholderValue);

  const exportOrders = useExportOrders({ timeRange });

  const [rechargeModal, setRechargeModal] = useState<{
    show: boolean;
    sessionId: string;
    cpoId: string;
    emspId: string;
  }>({ show: false, sessionId: '', cpoId: '', emspId: '' });

  const handleRechargeModalClose = () =>
    setRechargeModal({ ...rechargeModal, show: false });

  const handleOrderRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) => {
    if (sessionId)
      setRechargeModal({
        show: true,
        sessionId,
        cpoId: cpoId || '',
        emspId: emspId || '',
      });
    else toast.error(t('order-card.toast.order-not-generated-error.message'));
  };

  const { data, error, loading } = useAuthQuery<
    OrdersHistoryQuery,
    OrdersHistoryQueryVariables
  >(ordersHistory, {
    variables: {
      pagination: {
        offset: pageIndex * pageSize,
        limit: pageSize,
      },
      sort: transformToOrderSortInput(sorts),
      timeRange: timeRange.toTimeRangeInput(now),
      filter: {
        status: [
          'Captured',
          'Error',
          'ErrorOnPreAuthorization',
          'PreAuthorized',
          'Subsidized',
        ],
      },
    },
  });

  const selectedColumns: Column<Partial<FormattedOrder>>[] = Object.entries(
    orderFieldLabelTable
  )
    .filter(([key]) => columns.includes(key as keyof Partial<FormattedOrder>))
    .map(([key, label]) => getColumnOptions(key, t(label)));

  return (
    <Container>
      <RechargeModal
        onClose={handleRechargeModalClose}
        show={rechargeModal.show}
        sessionId={rechargeModal.sessionId}
        cpoId={rechargeModal.cpoId}
        emspId={rechargeModal.emspId}
      />
      <ButtonContainer>
        <DateDropdown
          defaultOption={placeholderValue}
          placeholderValue={placeholderValue}
          options={TIME_PERIODS_LIST}
          format={(option) => t(option.shortLabel as TranslationKeyType)}
          onChange={setTimeRange}
        />
        {hasScopes(['order:export']) && (
          <ExportButton onClickToExport={exportOrders} />
        )}
      </ButtonContainer>
      <CardWrapper>
        <Card
          minHeight={500}
          type="complex"
          error={error && !data}
          loading={loading}
          noCardPadding={true}
        >
          {error && !data ? (
            <ErrorComponent>{t('generic-error-message')}</ErrorComponent>
          ) : (
            <OrderTableContainer>
              <Table<Partial<FormattedOrder>>
                columns={selectedColumns}
                columnMaxWidth={50}
                data={data?.orders.map(formatOrderFields) || []}
                pageCount={Math.ceil(
                  (data?.ordersSummary.count || 0) / pageSize
                )}
                onRowPress={({ original: { sessionId } }) =>
                  handleOrderRowClick(sessionId)
                }
                fetchData={setPagination}
                timeRange={timeRange}
                loading={loading}
                paginated
                expandable={false}
                isFullWidth={true}
              />
            </OrderTableContainer>
          )}
        </Card>
      </CardWrapper>
    </Container>
  );
};

export default OrderCard;
