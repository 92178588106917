import { Box, Button, Flex, Heading, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import About from './containers/About';
import Rules from './containers/Rules';
import SelectDriversContainer from './containers/SelectDrivers';
import SelectStationsContainer from './containers/SelectStations';
import { VoucherCreationErrorModal } from './containers/VoucherCreationErrorModal';
import { useCreateVoucherScreen } from './services';
import { CreateVoucherProvider } from './context';

const CreateVoucherScreen = () => {
  const { t } = useTranslation();
  const {
    isCreateButtonDisabled,
    createVoucherLoading,
    onCancelVoucherCreationButtonClick,
    onCreateVoucherButtonClick,
  } = useCreateVoucherScreen();
  return (
    <Flex
      bg="gray.50"
      flexDirection="column"
      h="100%"
      overflowY="scroll"
      w="100%"
    >
      <Box p="1rem 1.5rem 1rem 1.5rem" bg="white">
        <Heading fontSize="2xl">
          {t('payments.voucher-tab.create-voucher.title')}
        </Heading>
      </Box>
      <Flex p="1.25rem" flexDirection="column" gap="1.25rem">
        <About />
        <Rules />
        <SelectDriversContainer />
        <SelectStationsContainer />
      </Flex>
      <Box>
        <Flex
          alignItems="center"
          bgColor="white"
          flexDirection="row"
          p="1.5rem"
          gap="1rem"
          justifyContent="center"
        >
          <Button
            _hover={{ bg: 'gray.100' }}
            bgColor="white"
            color="black"
            h="2.5rem"
            onClick={() => onCancelVoucherCreationButtonClick()}
            w="7.3rem"
          >
            {t('payments.voucher-tab.create-voucher.cancel-btn.title')}
          </Button>
          <Tooltip
            borderRadius="0.5rem"
            hasArrow
            isDisabled={!isCreateButtonDisabled}
            label={t('payments.voucher-tab.create-voucher.create-btn.tooltip')}
            mb="0.125rem"
            shouldWrapChildren
          >
            <Button
              _hover={{ bg: Config.COLORS.SECONDARY_GRAY }}
              h="2.5rem"
              isDisabled={isCreateButtonDisabled}
              _disabled={{ bg: Config.COLORS.TERTIARY_GRAY }}
              isLoading={createVoucherLoading}
              onClick={() => onCreateVoucherButtonClick()}
              w="7.3rem"
            >
              {t('payments.voucher-tab.create-voucher.create-btn.title')}
            </Button>
          </Tooltip>
        </Flex>
        <VoucherCreationErrorModal />
      </Box>
    </Flex>
  );
};

export const CreateVoucher = () => (
  <CreateVoucherProvider>
    <CreateVoucherScreen />
  </CreateVoucherProvider>
);
