import {
  StationsMonitoringQuery,
  StationsMonitoringQueryVariables,
} from 'generated/graphql';
import stationsMonitoring from 'graphql/queries/stationsMonitoring';
import UseRealtimeQuery from 'hooks/useRealtimeQuery';
import {
  TemplateData,
  MonitoringPageRepository,
} from 'pages/Monitoring/repository';
import { StationMonitoringStatus } from 'pages/Monitoring/types';

export default class GraphqlMonitoringPageRepository
  implements MonitoringPageRepository
{
  getTemplateData(
    statusSelected: StationMonitoringStatus[] | undefined,
    includeAlarms: boolean
  ): TemplateData {
    const { data, error, loading } = UseRealtimeQuery<
      StationsMonitoringQuery,
      StationsMonitoringQueryVariables
    >(stationsMonitoring, {
      variables: {
        includeAlarms,
        filter: {
          includesStatus: statusSelected,
          includesVisibility: ['PUBLIC', 'PRIVATE'],
        },
      },
      pollInterval: 1000 * 10,
    });
    return {
      data,
      error,
      loading,
    };
  }
}
