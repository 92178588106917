import styled from 'styled-components';

export const Container = styled('section')`
  display: flex;
  height: 220px;
`;

export const GraphContainer = styled('section')`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
`;
