import { Connector as GraphqlConnector } from 'generated/graphql';
import React from 'react';
import { ConnectorContainer, Container, OpenStateType, Status } from './styles';

type Connector = Pick<GraphqlConnector, 'id' | 'status'>;

type Props = {
  connector: Connector;
  onClick: (connector: Connector) => void;
  openState: OpenStateType;
};

const ConnectorStatus: React.FC<Props> = ({
  connector,
  openState,
  onClick,
}) => (
  <Container onClick={() => onClick(connector)}>
    <ConnectorContainer openState={openState}>
      <Status status={connector.status} />
    </ConnectorContainer>
  </Container>
);

export default ConnectorStatus;
