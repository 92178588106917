import StationTableCard from 'cards/StationTable';
import Page from 'components/Page';
import type {
  TableStationsQuery,
  TableStationsQueryVariables,
} from 'generated/graphql';
import tableStations from 'graphql/queries/tableStations';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import moment from 'moment';
import ConnectorsSummaryCard from 'pages/Home/ConnectorsSummaryCard';
import SessionsSummariesCards from 'pages/Home/SessionsSummariesCards';
import SessionsTimelineCard from 'pages/Home/SessionsTimelineCard';
import React, { useState, useContext } from 'react';
import AuthContext from 'contexts/Auth/context';
import { transformToStationSortInput } from 'components/Filter';
import type { FormattedStation } from 'services/formatStationRow';
import { formatStationFields } from 'services/formatStationRow';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import { CardContainer, Content } from './styles';

const selectedColumns = [
  'stationName',
  'orgName',
  'state',
  'city',
  'connectors',
  'dailyCount',
  'stationConfigs',
] as const;

type PartialFormattedStation = Pick<
  FormattedStation,
  typeof selectedColumns[number]
> & { id: string };

// TODO: lookup defaults
const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
  sorts: [] as any,
};

const Home: React.FC = () => {
  const hasScopes = useHasScopes();
  const [{ pageIndex, pageSize, sorts }, setPagination] =
    useState(defaultPagination);

  const { data, error, loading } = useRealtimeQuery<
    TableStationsQuery,
    TableStationsQueryVariables
  >(tableStations, {
    variables: {
      timeRange: {
        from: moment().startOf('day').toISOString(),
      },
      limit: pageSize,
      offset: pageIndex * pageSize,
      sort:
        sorts.length > 0
          ? transformToStationSortInput(sorts, {
              from: moment().startOf('day').toISOString(),
            })
          : { cpoId: 'ASC' },
      includeSession: hasScopes(['session:read']),
    },
    pollInterval: 1000 * 10,
  });

  const { orgNameMap } = useContext(AuthContext);
  const defaultCurrencyTypes = useDefaultCurrencyTypes();

  return (
    <Page>
      <Content>
        {hasScopes(['session:read']) && (
          <CardContainer>
            <SessionsSummariesCards />
          </CardContainer>
        )}
        <CardContainer>
          <SessionsTimelineCard />
          <ConnectorsSummaryCard />
        </CardContainer>
        <CardContainer>
          <StationTableCard<PartialFormattedStation>
            data={
              data?.stations?.map((station) =>
                formatStationFields(
                  station,
                  orgNameMap,
                  getOrganizationDefaultCurrencyType(
                    defaultCurrencyTypes,
                    station.cpoId
                  )
                )
              ) ?? []
            }
            pageCount={Math.ceil(
              (data?.stationsSummary?.count || 0) / pageSize
            )}
            fetchData={setPagination}
            canSearch={false}
            error={error}
            loading={loading}
            hasFooter
            selectedColumns={
              hasScopes(['session:read'])
                ? selectedColumns
                : selectedColumns.filter((column) => column !== 'dailyCount')
            }
          />
        </CardContainer>
      </Content>
    </Page>
  );
};

export default Home;
