import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import Dropdown from 'components/Dropdown';
import {
  TableConnectorsPriceQuery,
  TableConnectorsPriceQueryVariables,
  UpdateConnectorPricingConfigurationMutation,
  UpdateConnectorPricingConfigurationMutationVariables,
} from 'generated/graphql';
import { useAuthMutation, useAuthQuery } from 'hooks';
import tableConnectorsPrice from 'graphql/queries/tableConnectorsPrice';

import Config from 'config';
import Toast from 'pages/StationPage/Toast';
import { formatCurrencyType, formatPower } from 'services/format';
import updateConnectorPricingConfigurationTableMutation from 'graphql/mutations/updateConnectorPricingConfiguration';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { SkeletonsTable } from 'atomic-components/atoms/SkeletonsTable';
import { useTranslation } from 'react-i18next';
import ConfigureConnectorPricingConfig from 'atomic-components/organisms/modals/ConfigureConnectorPricingConfig';
import ErrorComponent from 'new-components/ErrorComponent';
import {
  parseFormattedNumber,
  moneyMask,
  PRICING_METHODS,
  ConnectorConfig,
  getBillingMethodLabel,
  getConnectorsConfigChanged,
  allChargeFeesAbleToBeUpdated,
  getErrorMessage,
  structureTableData,
} from './functions';

const PriceControl = () => {
  const { t } = useTranslation();
  const [currentConnectorsConfig, setCurrentConnectorsConfig] =
    useState<ConnectorConfig[]>();
  const [connectorsConfig, setConnectorsConfig] = useState<ConnectorConfig[]>();
  const [allChargeFeesIsValid, setAllChargeFeesIsValid] = useState(true);
  const [toastVisible, setToastVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, loading, refetch, error } = useAuthQuery<
    TableConnectorsPriceQuery,
    TableConnectorsPriceQueryVariables
  >(tableConnectorsPrice);

  /*

  const [sendFile, { loading: loadingUpload }] = useAuthMutation(
    updateConnectorPricingConfigurationFile
  );
  */
  const [
    updateConnectorPrincingConfigurationTable,
    { loading: loadingUpdateConnectors },
  ] = useAuthMutation<
    UpdateConnectorPricingConfigurationMutation,
    UpdateConnectorPricingConfigurationMutationVariables
  >(updateConnectorPricingConfigurationTableMutation);

  useEffect(() => {
    if (!data) return;
    const values: ConnectorConfig[] | undefined = data.stationsProfile.flatMap(
      (station) => structureTableData(station) ?? []
    );

    setCurrentConnectorsConfig(values);
    setConnectorsConfig(values);
  }, [data]);

  const toastOnAnotherPage = useToast();
  const closeToast = useCallback(async () => {
    setToastVisible(false);
    toastOnAnotherPage.closeAll();
  }, [toastOnAnotherPage]);

  const openToast = () => {
    setToastVisible(true);
  };

  useEffect(() => {
    if (
      JSON.stringify(connectorsConfig) !==
      JSON.stringify(currentConnectorsConfig)
    ) {
      openToast();
    } else {
      closeToast();
    }
  }, [connectorsConfig, currentConnectorsConfig, closeToast]);

  const resetFields = async () => {
    setConnectorsConfig(currentConnectorsConfig);
    await closeToast();
  };

  const handleUpdateConnectors = async () => {
    try {
      const data = getConnectorsConfigChanged(
        currentConnectorsConfig ?? [],
        connectorsConfig ?? []
      );

      await updateConnectorPrincingConfigurationTable({
        variables: {
          data,
        },
      });
      toast.success(t('payments.price-control-tab.toast-successfully-message'));
    } catch (e) {
      toast.error(t('payments.price-control-tab.toast-error-message'));
    }
    setCurrentConnectorsConfig(connectorsConfig);
    await closeToast();
  };

  useEffect(() => {
    const result = allChargeFeesAbleToBeUpdated(connectorsConfig ?? []);
    if (!result) {
      setAllChargeFeesIsValid(true);
    } else {
      setAllChargeFeesIsValid(false);
    }
  }, [connectorsConfig]);

  const columns = [
    'Eletroposto',
    'Cobrança',
    'Conector',
    'eMSP',
    'CPO',
    'Tipo de tarifa',
    'Valor da tarifa',
    'Taxa de desbloqueio',
  ];

  /*
  const uploadSheetFile = async (sheetFile: FileList[number]) => {
    try {
      const { errors } = await sendFile({
        variables: {
          file: sheetFile,
        },
      });
      if (errors) throw new Error();
      toast.success('Alterações salvas com sucesso');
    } catch (e) {
      const regexExtractFileExtension = /(?:\.([^.]+))?$/;
      const fileExtension = (sheetFile.name.match(regexExtractFileExtension) ||
        [])[1];
      if (
        fileExtension === 'xls' ||
        fileExtension === 'xlsx' ||
        fileExtension === 'csv'
      ) {
        toast.error(
          'Infelizmente houve um erro ao atualizar os preços, tente novamente mais tarde.'
        );
      } else {
        toast.error(
          'Planilha enviada em um formato incorreto. Faça o upload de um arquivo .XLSX'
        );
      }
    }
  };
  */

  return (
    <Flex direction="column" mb="12" data-test="price-control-container">
      {/*
      <Flex justify="space-between" mb="12">
        <Stack spacing="4" direction="row" w="100%">
          <Box borderRadius="2xl" p="4" bg="white" w="100%" shadow="base">
            <Text>Avisos</Text>
            <Box w="sm" border="thin" borderColor="gray.900">
              <Text fontSize="small" color="gray.500" mt="4" lineHeight="tall">
                Para realizar a alteração de preço, faça o download da planilha,
                edite, faça o upload da planilha com os preços alterados e
                confirme a mudança no visualizador abaixo.
              </Text>
            </Box>
          </Box>
          <Box bg="white" p="4" w="100%" borderRadius="2xl" shadow="base">
            <Text>Edição de preço</Text>
            <Flex justify="center">
              <HStack mt="6" spacing="8">
                <Button
                  size="sm"
                  colorScheme="gray"
                  variant="outline"
                  fontWeight="normal"
                  onClick={exportConnectorsPrice}
                  leftIcon={
                    <Icon
                      type="NEW_ARROW_DOWN"
                      color="PRIMARY_GRAY"
                      size={12}
                    />
                  }
                >
                  Download da planilha
                </Button>
                <Button
                  isLoading={loadingUpload}
                  size="sm"
                  fontWeight="normal"
                  leftIcon={
                    <Icon type="NEW_ARROW_UP" color="PRIMARY_GRAY" size={12} />
                  }
                >
                  Upload da planilha
                  <Input
                    position="absolute"
                    bottom="0"
                    right="0"
                    opacity="0"
                    w="100%"
                    height="100%"
                    accept=".xlsx, .xls, .csv"
                    type="file"
                    cursor="pointer"
                    onChange={({ target: { validity, files } }) =>
                      validity.valid && files && uploadSheetFile(files[0])
                    }
                    css={{
                      '&::-webkit-file-upload-button': {
                        cursor: 'pointer',
                      },
                    }}
                  />
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Stack>
      </Flex>
    */}
      <Box
        bg="white"
        borderWidth="thin"
        borderColor="gray.100"
        borderRadius={8}
        maxWidth="full"
        overflowX="auto"
        minHeight="2xl"
        overflowY="hidden"
      >
        <Flex w="full" py={4} pr={6}>
          <Button
            size="sm"
            disabled={loading}
            ml="auto"
            onClick={() => setIsModalVisible(true)}
          >
            {t('payments.price-control-tab.table-header-btn.title')}
          </Button>
        </Flex>
        <Table colorScheme="gray" position="relative">
          <Thead>
            <Tr>
              <Th>{t('payments.price-control.table.th-station.title')}</Th>
              <Th textAlign="center">
                {t('payments.price-control.table.th-charge.title')}
              </Th>
              <Th textAlign="center">{t('emsp-commum-text')}</Th>
              <Th textAlign="center">
                {t('payments.price-control.table.th-cpo.title')}
              </Th>
              <Th textAlign="center">
                {t('payments.price-control.table.th-connector.title')}
              </Th>
              <Th textAlign="center">
                {t('payments.price-control.table.th-fare-type.title')}
              </Th>
              <Th textAlign="center">
                {t('payments.price-control.table.th-fare-value.title')}
              </Th>
              <Th textAlign="right">
                {t('payments.price-control.table.th-unlock-fee.title')}
              </Th>
            </Tr>
          </Thead>
          {error ? (
            <Box marginTop={50}>
              <ErrorComponent />
            </Box>
          ) : loading ? (
            <SkeletonsTable columns={columns} />
          ) : (
            <Tbody>
              {connectorsConfig?.map((data, index) => (
                <Tr key={index} color="gray.900">
                  <Td
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    maxWidth="56"
                  >
                    {data.stationName}
                  </Td>
                  <Td textAlign="center">
                    <Switch
                      pt={2}
                      isChecked={connectorsConfig[index].connectorEnabled}
                      onChange={() =>
                        setConnectorsConfig(
                          connectorsConfig.map((connectors, currentIndex) =>
                            currentIndex !== index
                              ? connectors
                              : {
                                  ...connectors,
                                  connectorEnabled:
                                    !connectorsConfig[index].connectorEnabled,
                                }
                          )
                        )
                      }
                    />
                  </Td>
                  <Td textAlign="center">{data.emspId ?? '-'}</Td>
                  <Td textAlign="center" whiteSpace="nowrap">
                    {data.cpoId ?? '-'}
                  </Td>
                  <Td
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    maxW={30}
                    textAlign="center"
                  >{`${data.type} - ${formatPower(data.power)} kW`}</Td>
                  <Td textAlign="center" maxW={64}>
                    <Box margin="auto" maxW={64}>
                      <Dropdown
                        options={PRICING_METHODS}
                        value={connectorsConfig[index].method}
                        format={(type) => t(Config.LABELS.BILLING_MODES[type])}
                        onChange={(value) =>
                          setConnectorsConfig(
                            connectorsConfig.map((connectors, currentIndex) =>
                              currentIndex !== index
                                ? connectors
                                : { ...connectors, method: value }
                            )
                          )
                        }
                        disabled={!connectorsConfig[index].connectorEnabled}
                      />
                    </Box>
                  </Td>
                  <Td>
                    <InputGroup display="flex" justifyContent="center">
                      <InputLeftAddon
                        fontWeight="bold"
                        children={`${formatCurrencyType({
                          currencyType: connectorsConfig[index].currencyType,
                        })}`}
                        color="gray.400"
                        bg="none"
                        border="none"
                        p={0}
                        pr={2}
                      />
                      <NumberFormat
                        format={moneyMask}
                        customInput={Input}
                        value={connectorsConfig[index].cost}
                        onValueChange={(values) =>
                          setConnectorsConfig(
                            connectorsConfig.map((connectors, currentIndex) =>
                              currentIndex !== index
                                ? connectors
                                : {
                                    ...connectors,
                                    cost: values.formattedValue,
                                  }
                            )
                          )
                        }
                        style={{
                          fontFamily: Config.FONTS.TERTIARY,
                          fontWeight: 'bold',
                        }}
                        width="20"
                        maxLength={6}
                        disabled={!connectorsConfig[index].connectorEnabled}
                      />
                      <InputRightAddon
                        children={`${getBillingMethodLabel(data.method)}`}
                        fontWeight="bold"
                        bg="none"
                        border="none"
                        color="gray.400"
                        p={0}
                        pl={3}
                      />
                    </InputGroup>
                  </Td>
                  <Td display="flex" justifyContent="flex-end">
                    <Flex flexDirection="row" alignItems="center">
                      <FormControl
                        w={36}
                        isInvalid={
                          parseFormattedNumber(
                            connectorsConfig[index].chargeFee
                          ) < connectorsConfig[index].minimumChargeFee &&
                          (connectorsConfig[index].hasPricingConfig ||
                            connectorsConfig[index].connectorEnabled)
                        }
                        height="10"
                      >
                        <InputGroup>
                          <InputLeftAddon
                            children={`${formatCurrencyType({
                              currencyType:
                                connectorsConfig[index].currencyType,
                            })}`}
                            color="gray.400"
                            fontWeight="bold"
                            bg="none"
                            border="none"
                            p={0}
                            pr={2}
                          />
                          <NumberFormat
                            maxLength={6}
                            width={20}
                            format={moneyMask}
                            style={{
                              fontFamily: Config.FONTS.TERTIARY,
                              fontWeight: 'bold',
                            }}
                            value={connectorsConfig[index].chargeFee}
                            onValueChange={(values) =>
                              setConnectorsConfig(
                                connectorsConfig.map(
                                  (connectors, currentIndex) =>
                                    currentIndex !== index
                                      ? connectors
                                      : {
                                          ...connectors,
                                          chargeFee: values.formattedValue ?? 0,
                                        }
                                )
                              )
                            }
                            customInput={Input}
                            disabled={!connectorsConfig[index].connectorEnabled}
                          />
                        </InputGroup>
                        <FormErrorMessage
                          fontSize="small"
                          whiteSpace="nowrap"
                          m="0"
                        >
                          {getErrorMessage(
                            connectorsConfig[index].minimumChargeFee
                          )}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
        <Toast
          id={'updatePricingConfig'}
          resetFields={resetFields}
          onClick={handleUpdateConnectors}
          loading={loadingUpdateConnectors}
          isVisible={toastVisible}
          isValid={allChargeFeesIsValid}
          inputsValues={[connectorsConfig]}
        />
        <ConfigureConnectorPricingConfig.FirstStepModal
          onClose={() => setIsModalVisible(false)}
          openModalAgain={() => setIsModalVisible(true)}
          show={isModalVisible}
          refetchTableData={refetch}
          stations={
            data?.stationsProfile.map((station) => ({
              id: station.id,
              name: station.name,
            })) ?? []
          }
        />
      </Box>
    </Flex>
  );
};

export default PriceControl;
