import { Icon } from 'new-components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, CardInfo } from './styles';

export type BrandIcon =
  | 'NEW_MASTER'
  | 'NEW_VISA'
  | 'NEW_ELO'
  | 'NEW_CREDIT_CARD';

type Props = {
  brand: BrandIcon;
  lastFourCardNumbers: string;
};

const PaymentCard: React.FC<Props> = ({ brand, lastFourCardNumbers }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Icon type={brand} color="PRIMARY"></Icon>
      <CardInfo>
        •••• {lastFourCardNumbers} {t('driver-modal.payment-card.short-title')}
      </CardInfo>
    </Container>
  );
};

export default PaymentCard;
