import { InjectChildrenProps } from '.';
import { HeaderGridContainer } from './styles';

type HeaderGridContainerProps = {
  /** O padrão de colunas é ```'1fr 1fr 1fr 1fr'``` */
  gridColumns?: string;
  /** O padrão de áreas é ```'icon title title .'``` */
  gridAreas?: string;
} & InjectChildrenProps;

const DropdownHeaderGridContainer = ({
  children,
}: HeaderGridContainerProps) => (
  <HeaderGridContainer>{children}</HeaderGridContainer>
);

export { DropdownHeaderGridContainer };
