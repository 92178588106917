import styled from 'styled-components';
import newCard from 'new-components/NewCard';

export const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 300px 1fr;
  overflow: hidden;
  height: 100%;
`;

export const NewCardScroll = styled(newCard)`
  overflow-y: auto;
`;
