import Loader from 'components/Loader';
import {
  VehicleModalQuery,
  VehicleModalQueryVariables,
  VehicleModalSessionsTabQuery,
  VehicleModalSessionsTabQueryVariables,
} from 'generated/graphql';
import vehicleModal from 'graphql/queries/vehicleModal';
import vehicleModalSessionsTab from 'graphql/queries/vehicleModalSessionsTab';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import Modal from 'new-components/NewModal';
import React, { useState, useContext } from 'react';
import AuthContext from 'contexts/Auth/context';
import { formatSessionFields } from 'services/formatSessionRow';
import { LoadingContainer } from 'styles';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import General from './General';
import Header, { Tab, tabs } from './Header';
import Sessions from './Sessions';
import { Body, Container } from './styles';

type Props = {
  id: number;
  handleSessionRowClick: (
    sessionId?: string,
    emspId?: string,
    cpoId?: string
  ) => void;
  onClose: () => void;
  show: boolean;
};

const defaultPagination = {
  pageIndex: 0,
  pageSize: 25,
};

const VehicleModal: React.FC<Props> = ({
  id,
  handleSessionRowClick,
  onClose,
  show,
}) => {
  const hasScopes = useHasScopes();
  const [
    { pageIndex: sessionPageIndex, pageSize: sessionPageSize },
    setSessionPagination,
  ] = useState(defaultPagination);

  const { data, loading, error } = useAuthQuery<
    VehicleModalQuery,
    VehicleModalQueryVariables
  >(vehicleModal, {
    variables: {
      where: { vehicleId: id },
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  const {
    data: sessionsData,
    error: sessionsError,
    loading: sessionsLoading,
  } = useRealtimeQuery<
    VehicleModalSessionsTabQuery,
    VehicleModalSessionsTabQueryVariables
  >(vehicleModalSessionsTab, {
    variables: {
      where: { vehicleId: id },
      offset: sessionPageIndex * sessionPageSize,
      limit: sessionPageSize,
    },
    fetchPolicy: 'network-only',
    skip: !id || !hasScopes(['session:read', 'order:read']),
  });

  const [currentTab, setCurrentTab] = useState<Tab>(tabs[0]);
  const onTabChange = (tab: Tab) => setCurrentTab(tab);

  const defaultCurrencyTypes = useDefaultCurrencyTypes();

  const { orgNameMap } = useContext(AuthContext);

  const getTabComponent = (tabSet: Tab) => {
    switch (tabSet) {
      case tabs[0]:
        return (
          <General error={!!error} loading={loading} vehicle={data?.vehicle} />
        );
      case tabs[1]:
        return (
          <>
            {hasScopes(['session:read', 'station:read']) && (
              <Sessions
                data={sessionsData?.vehicle?.sessions.map((session) =>
                  formatSessionFields(session, orgNameMap, defaultCurrencyTypes)
                )}
                error={!!sessionsError}
                fetchData={() => setSessionPagination}
                handleSessionRowClick={handleSessionRowClick}
                loading={sessionsLoading}
                pageSize={sessionPageSize}
              />
            )}
          </>
        );
    }
  };
  return (
    <Container show={show} onClose={onClose} size="lg">
      {loading ? (
        <LoadingContainer>
          <Loader color="INFO_GRAY" size={50} />
        </LoadingContainer>
      ) : (
        <>
          <Modal.Header>
            <Header
              currentTab={currentTab}
              profile={data?.vehicle}
              id={id}
              onTabChange={onTabChange}
            ></Header>
          </Modal.Header>
          <Modal.Header.Attachments padding="16">
            <Modal.Header.Close onClose={onClose} />
          </Modal.Header.Attachments>
          <Body>{getTabComponent(currentTab)}</Body>
        </>
      )}
    </Container>
  );
};

export default VehicleModal;
