import Table, { Column, FetchDataFunction } from 'components/Table';
import React from 'react';
import {
  FormattedOrder,
  getColumnOptions,
  orderFieldLabelTable,
} from 'services/formatOrderRow';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import { Container, OrderTableContainer } from './styles';

export type Props<T extends object> = {
  data?: T[];
  error: boolean;
  fetchData: FetchDataFunction<T>;
  handleSessionRowClick: (id?: string) => void;
  loading: boolean;
  pageCount: number;
};

const columns: Array<keyof Partial<FormattedOrder>> = [
  'orderId',
  'stationName',
  'createdAt',
  'orderCapturedOrPreAuthorizedValue',
  'paymentMethod',
  'status',
];

function Orders<T extends Partial<FormattedOrder>>({
  data,
  error,
  fetchData,
  handleSessionRowClick,
  loading,
  pageCount,
}: Props<T>) {
  const { t } = useTranslation();
  const selectedColumns: Column<Partial<FormattedOrder>>[] = Object.entries(
    orderFieldLabelTable
  )
    .filter(([key]) => columns.includes(key as keyof Partial<FormattedOrder>))
    .map(([key, label]) => getColumnOptions(key, t(label)));

  return (
    <Container>
      <Card
        minHeight={(window.innerHeight / 100) * 74}
        maxHeight={(window.innerHeight / 100) * 74}
        loading={loading}
        type="complex"
        error={error}
        noCardPadding={true}
      >
        <OrderTableContainer>
          <Table<Partial<FormattedOrder>>
            columnMaxWidth={50}
            columns={selectedColumns}
            data={data || []}
            fetchData={fetchData}
            loading={loading}
            onRowPress={({ original: { sessionId } }) =>
              handleSessionRowClick(sessionId)
            }
            pageCount={pageCount}
            paginated
            isFullWidth={true}
          />
        </OrderTableContainer>
      </Card>
    </Container>
  );
}

export default Orders;
