import styled from 'styled-components';

export const SessionTableContainer = styled.div`
  height: 700px;
  margin-bottom: 47px;
  overflow-y: auto;
  border: 1px solid
    ${({
      theme: {
        COLORS: { TERTIARY_GRAY },
      },
    }) => TERTIARY_GRAY};
  border-radius: 8px;
`;

export const Container = styled.div`
  flex: 1;
  background-color: #f4f4f4;
  padding: 16px;
`;
