import Table from 'components/Table';
import { Typography } from 'components';
import { useTranslation } from 'react-i18next';
import { VoucherTableContainer } from './style';
import { VoucherTableBlankState } from './VoucherTableBlankState';
import { useVouchersTable, Voucher } from './service';
import { VoucherStatusTag } from '../../../components/VoucherStatusTag';
import { VoucherActionsDropdown } from '../VouchersActionDropdown';

export const VouchersTable = () => {
  const {
    listVouchersLoading,
    pageCount,
    vouchers,
    renderBlankState,
    changeVoucherTablePagination,
    requestedVoucherName,
  } = useVouchersTable();

  const { t } = useTranslation();

  const voucherTableColumns = [
    {
      Header: t('payments.voucher-tab.table.th-voucher-name.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { name },
        },
      }) => <Typography>{name}</Typography>,
    },
    {
      Header: t('payments.voucher-tab.table.th-voucher-discount.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { variableDiscount },
        },
      }) => (
        <Typography>{`${
          variableDiscount ? `${variableDiscount}%` : '-'
        }`}</Typography>
      ),
    },
    {
      Header: t('payments.voucher-tab.table.th-voucher-usage-limit.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            usageLimit,
          },
        },
      }) => (
        <Typography>
          {usageLimit
            ? usageLimit === 1
              ? t(
                  'payments.voucher-tab.table.th-voucher-usage-limit.single-use'
                )
              : usageLimit
            : t('payments.voucher-tab.table.th-voucher-usage-limit.lifetime')}
        </Typography>
      ),
    },
    {
      Header: t('payments.voucher-tab.table.th-voucher-validity.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            expiresAt,
            // @ts-ignore
            createdAt,
          },
        },
      }) => (
        <Typography>
          {expiresAt
            ? t('payments.voucher-tab.table.th-voucher-validity.format', {
                startDate: new Date(createdAt),
                endDate: new Date(expiresAt),
              })
            : t('payments.voucher-tab.table.th-voucher-validity.lifetime')}
        </Typography>
      ),
    },
    {
      Header: t('payments.voucher-tab.table.th-voucher-organization.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            emspId,
          },
        },
      }) => <Typography>{emspId}</Typography>,
    },
    {
      Header: t('payments.voucher-tab.table.th-voucher-status.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            status,
          },
        },
      }) => <VoucherStatusTag status={status} />,
    },
    {
      Header: '',
      disableSortBy: true,
      disableFilters: true,
      id: 'dots',
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            id,
            // @ts-ignore
            status,
          },
        },
      }) => <VoucherActionsDropdown selectedVoucher={{ id, status }} />,
    },
  ];

  return (
    <VoucherTableContainer>
      <Table<Voucher>
        paginated
        fetchData={changeVoucherTablePagination}
        pageCount={pageCount}
        columns={voucherTableColumns}
        data={vouchers}
        searchValue={requestedVoucherName}
        loading={listVouchersLoading}
        renderBlankState={
          renderBlankState ? <VoucherTableBlankState /> : undefined
        }
        isFullWidth
      />
    </VoucherTableContainer>
  );
};
