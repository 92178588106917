import React from 'react';
import Typography from 'components/Typography';

interface Props {
  title: string;
  light?: boolean;
}

const CardTitle: React.FC<Props> = ({ title, light }) => (
  <Typography
    size={light ? 14 : 16}
    color={light ? 'SECONDARY_GRAY' : 'PRIMARY_GRAY'}
  >
    {title}
  </Typography>
);

export default CardTitle;
