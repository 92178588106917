import { SuccessSubmitFeedback } from 'main/account-exclusion/components/success-feedback';
import { AccountExclusionAuthContext } from 'main/account-exclusion/contexts/account-exclusion';
import requestAccountExclusionMutation from 'graphql/mutations/request-account-exclusion';
import accountExclusionInfo from 'graphql/queries/account-exclusion-info';
import DialogModal from 'atomic-components/organisms/DialogModal';
import LoadingComponent from 'new-components/LoadingComponent';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useAuthMutation, useAuthQuery } from 'hooks';
import React, { useContext, useState } from 'react';
import {
  RequestDriverAccountExclusionMutationVariables,
  RequestDriverAccountExclusionMutation,
  AccountExclusionInfoQuery,
  AccountExclusionInfoQueryVariables,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import toast from 'services/toast';

export const RequestAccountExclusion = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { token, email, appName } = useContext(AccountExclusionAuthContext);
  const { t } = useTranslation();

  const [requestAccountExclusion, { loading: loadingRequestAccountExclusion }] =
    useAuthMutation<
      RequestDriverAccountExclusionMutation,
      RequestDriverAccountExclusionMutationVariables
    >(requestAccountExclusionMutation, {
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    });

  const {
    data: accountExclusionInfoData,
    loading: loadingAccountExclusionInfo,
    refetch: refetchAccountExclusionInfo,
  } = useAuthQuery<
    AccountExclusionInfoQuery,
    AccountExclusionInfoQueryVariables
  >(accountExclusionInfo, {
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  if (loadingAccountExclusionInfo && !accountExclusionInfoData) {
    return (
      <Flex h="full" alignItems="center" justifyContent="center">
        <LoadingComponent size={51} color="INFO_GRAY" />;
      </Flex>
    );
  }

  const handleRequestAccountExclusionSubmit = async () => {
    try {
      await requestAccountExclusion();
      await refetchAccountExclusionInfo();
    } catch (error) {
      toast.error(t('request-account-exclusion.error-message'));
    }
  };

  const exclusionScheduledDate =
    accountExclusionInfoData?.currentDriver.exclusionScheduledDate;

  if (exclusionScheduledDate && appName) {
    return (
      <SuccessSubmitFeedback
        appName={appName}
        exclusionScheduledDate={new Date(exclusionScheduledDate)}
      />
    );
  }
  return (
    <React.Fragment>
      <DialogModal
        size="sm"
        header={{
          title: t('request-account-exclusion.modal.title'),
        }}
        onClose={() => setIsModalVisible(false)}
        visible={isModalVisible}
        cancelButton={{
          text: t('request-account-exclusion.modal.cancel-button-text'),
          onClick: () => setIsModalVisible(false),
        }}
        submitButton={{
          text: t('request-account-exclusion.modal.submit-button-text'),
          colorScheme: 'primary',
          loading: loadingRequestAccountExclusion,
          onPress: () => handleRequestAccountExclusionSubmit(),
        }}
      />
      <Flex
        pt={16}
        pb={8}
        h="full"
        margin="auto"
        maxWidth={512}
        flexDirection="column"
        justifyContent="center"
      >
        <Heading size="lg" mb={6} fontSize={{ base: 24, md: 32 }}>
          {t('request-account-exclusion.delete-account-text')}
        </Heading>
        <Text color="gray.500" fontSize={{ base: 14, md: 16 }}>
          {t('request-account-exclusion.description-text')}
        </Text>
        <Button mt={12} mb={4} w="full" onClick={() => setIsModalVisible(true)}>
          {t('request-account-exclusion.delete-my-account-text')}
        </Button>
        <Text color="gray.500" fontSize={{ base: 14, md: 16 }}>
          {t('request-account-exclusion.linked-account-text', {
            email,
          })}
        </Text>
      </Flex>
    </React.Fragment>
  );
};
