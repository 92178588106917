import { createContext } from 'react';
import { CreateVoucherStateData } from './state-context';

export type CreateVoucherEventsContextType = {
  updateVoucherName: (voucherName: CreateVoucherStateData['name']) => void;
  updateVoucherDescription: (
    voucherDescription: CreateVoucherStateData['description']
  ) => void;
  updateVoucherDiscount: (
    voucherDiscount: CreateVoucherStateData['discount']
  ) => void;
  updateVoucherOrganizationId: (
    voucherOrganizationId: CreateVoucherStateData['organizationId']
  ) => void;
  updateVoucherLimits: (
    voucherLimits: CreateVoucherStateData['limits']
  ) => void;
  updateVoucherValidityPeriod: (
    voucherValidityPeriod: CreateVoucherStateData['validityPeriod']
  ) => void;
  updateVoucherDriversSelection: (
    voucherDriversSelection: CreateVoucherStateData['driversSelection']
  ) => void;
  updateVoucherStationsSelection: (
    voucherStationsSelection: CreateVoucherStateData['stationsSelection']
  ) => void;
  updateCreateVoucherErrorModalOpen: (value: boolean) => void;
  createVoucherErrorModalOpen: boolean;
};

export const CreateVoucherEventsContext = createContext<{
  getEvents: () => CreateVoucherEventsContextType;
}>({
  getEvents: () => ({
    updateVoucherDescription: () => {},
    updateVoucherDiscount: () => {},
    updateVoucherDriversSelection: () => {},
    updateVoucherLimits: () => {},
    updateVoucherName: () => {},
    updateVoucherOrganizationId: () => {},
    updateVoucherStationsSelection: () => {},
    updateVoucherValidityPeriod: () => {},
    updateCreateVoucherErrorModalOpen: () => {},
    createVoucherErrorModalOpen: false,
  }),
});
