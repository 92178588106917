import { gql } from '@apollo/client';

export default gql`
  query DriverModalOrdersTab(
    $where: DriverWhereUniqueInput!
    $pagination: PaginationInput
    $filter: OrderFilterInput
  ) {
    driver(where: $where) {
      id
      orders(
        pagination: $pagination
        sort: { createdAt: DESC }
        filter: $filter
      ) {
        orderId
        preAuthorizeAmount
        capturedValue
        valueToBeCaptured
        status
        currencyType
        paymentMethod {
          id
          ... on PaymentCard {
            brand
            partiallyHiddenCardNumber
          }
          ... on PlacePlan {
            holderFullName
            title
          }
          __typename
        }
        createdAt
        driverId
        session {
          id
          stationProfile {
            id
            name
            cpoId
          }
        }
      }
      ordersSummary {
        count
      }
    }
  }
`;
