import { gql } from '@apollo/client';

export default gql`
  mutation CreateStation(
    $ownerId: String!
    $name: String!
    $stationId: String!
  ) {
    createStation(
      data: { ownerId: $ownerId, name: $name, stationId: $stationId }
    )
  }
`;
