import React, { useContext, useEffect } from 'react';
import Sidebar, { Tab } from 'atomic-components/organisms/Sidebar';
import ManagerHMI from 'pages/StationPage/ManagerHMI';
import { Flex } from '@chakra-ui/react';
import { useAuthMutation } from 'hooks';
import {
  SynchronizeStationConfigurationKeysMutation,
  SynchronizeStationConfigurationKeysMutationVariables,
} from 'generated/graphql';
import synchronizeStationConfigurationKeysMutation from 'graphql/mutations/synchronizeStationConfigurationKeys';
import Access from './Access';
import Notification from './Notification';
import Unlock from './Unlock';
import AdvancedSettings from './AdvancedSettings';
import Usage from './Usage';
import { StationContext } from '../StationContext';

const allTabs: Tab[] = [
  {
    title: 'station-page.config-tab.general-tab.title',
    component: ManagerHMI,
    requiredScope: ['station:read'],
  },
  {
    title: 'station-page.config-tab.access-tab.title',
    component: Access,
    requiredScope: ['station:edit'],
  },
  {
    title: 'station-page.config-tab.notification-tab.title',
    component: Notification,
    requiredScope: ['station:edit'],
  },
  {
    title: 'station-page.config-tab.unlock-tab.title',
    component: Unlock,
    requiredScope: ['station:edit'],
    requiredModule: 'RFID',
  },
  {
    title: 'station-page.config-tab.usage-tab.title',
    component: Usage,
    requiredScope: ['station:edit'],
    filter: (capabilities) => capabilities.includes('RFID'),
  },
  {
    title: 'station-page.config-tab.advanced-settings-tab.title',
    component: AdvancedSettings,
    requiredScope: ['station:edit'],
  },
];

const StationConfiguration = () => {
  const { id: stationId, model } = useContext(StationContext);

  const [synchronizeStationConfigurationKeys, { loading: synchronizeLoading }] =
    useAuthMutation<
      SynchronizeStationConfigurationKeysMutation,
      SynchronizeStationConfigurationKeysMutationVariables
    >(synchronizeStationConfigurationKeysMutation, {
      variables: {
        where: {
          stationId,
        },
      },
    });

  useEffect(() => {
    // Synchronize station configuration keys before openning station advanced
    // settings tab.
    synchronizeStationConfigurationKeys();
  }, []); // eslint-disable-line

  const tabs = allTabs.filter((t) =>
    t.filter != null ? t.filter(model?.capabilities ?? []) : true
  );

  return (
    <Flex>
      <Sidebar tabs={tabs} loading={synchronizeLoading} />
    </Flex>
  );
};

export default StationConfiguration;
