import { Box, Text } from '@chakra-ui/react';

type Props = {
  text: string | null;
};

export const AccountExclusionFormError = ({ text }: Props) => (
  <Box
    my={4}
    py={0.5}
    w="full"
    px={1}
    borderRadius="md"
    background="error.500"
    visibility={text ? 'visible' : 'hidden'}
  >
    <Text color="white" fontSize="sm" textAlign="center">
      {text}
    </Text>
  </Box>
);
