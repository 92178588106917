import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const RecarConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: false,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: false,
  GA_TRACKING_ID: 'UA-119882526-9',
  CODE: 'recar',
  LABEL: 'Recar',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#1E398D',
    CLICKABLE: '#122255',
    ERROR: '#F87060',
    SIDEBAR: '#1E398D',
    LOGIN_LOGO_BACKGROUND: '#1E398D',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#1E398D',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#00C1FD',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#934083',

    PREPARING: '#00C1FD',
    FINISHING: '#00C1FD',

    SELECTED_DATE_BOUND_BACKGROUND: '#122255',
    SELECTED_DATE_BOUND_BORDER: '#1E398D',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#0f1d48',

    DATE_PRE_SELECTED_BACKGROUND: '#839be5',
    DATE_PRE_SELECTED_BORDER: '#5374da',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#839be5',
    DATE_PRE_SELECTED_COLOR: '#122255',

    DATE_SELECTED_BACKGROUND: '#1E398D',
    DATE_SELECTED_BORDER: '#5374da',
    DATE_SELECTED_HOVER_BACKGROUND: '#122255',

    CURRENT_DAY: '#122255',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#934083',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#00C1FD',
      CHARGING: '#00C1FD',
      FINISHING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#934083',
    },

    BILLING: '#04E762',
    RECHARGES: '#00C1FD',
    AVAILABILITY: '#934083',

    BUTTON_AVAILABLE: '#1E398D',
    BUTTON_UNAVAILABLE: '#939393',
    BUTTON_LOADING: '#122255',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#0091CA',
    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#122255',
      SELECTED_DATE_BOUND_BORDER: '#1E398D',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#0f1d48',

      DATE_PRE_SELECTED_BACKGROUND: '#b1c3fc',
      DATE_PRE_SELECTED_BORDER: '#5374da',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#b1c3fc',
      DATE_PRE_SELECTED_COLOR: '#122255',

      DATE_SELECTED_BACKGROUND: '#1E398D',
      DATE_SELECTED_BORDER: '#5374da',
      DATE_SELECTED_HOVER_BACKGROUND: '#122255',

      CURRENT_DAY: '#122255',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Recar - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/recar',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#b1c3fc',
      '100': '#6d89e0',
      '200': '#5374da',
      '300': '#355cd4',
      '400': '#274ab7',
      '500': '#1E398D',
      '600': '#122255',
      '700': '#0f1d48',
      '800': '#0d193d',
      '900': '#0b1534',
    },
    LINK: {
      '50': '#7edaff',
      '100': '#4bccff',
      '200': '#31c5ff',
      '300': '#18beff',
      '400': '#00b6fd',
      '500': '#0091CA',
      '600': '#006c97',
      '700': '#005a7e',
      '800': '#004864',
      '900': '#002331',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#b1ecff',
      '100': '#7ee0ff',
      '200': '#64daff',
      '300': '#4bd4ff',
      '400': '#31ceff',
      '500': '#00C1FD',
      '600': '#009aca',
      '700': '#0087b1',
      '800': '#007397',
      '900': '#004c64',
    },
    AVAILABLE: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#b1c3fc',
      '100': '#6d89e0',
      '200': '#5374da',
      '300': '#355cd4',
      '400': '#274ab7',
      '500': '#1E398D',
      '600': '#122255',
      '700': '#0f1d48',
      '800': '#0d193d',
      '900': '#0b1534',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#934083',
      '600': '#672d5c',
      '700': '#481f40',
      '800': '#32162d',
      '900': '#170b14',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default RecarConfig;
