import React from 'react';
import Config from 'config';
import {
  UserNameContainer,
  UserName,
  Container,
  StarsContainer,
} from './styles';

interface Props {
  id: number;
  stars?: 1 | 2 | 3 | 4 | 5;
}

const DriverHeader: React.FC<Props> = ({ stars, id }) => {
  const starsArray = new Array(5)
    .fill(<Config.ICONS.FULL_STAR />)
    .fill(<Config.ICONS.EMPTY_STAR />, stars);

  return (
    <Container>
      <UserNameContainer>
        <Config.ICONS.USER_DARK_FALLBACK />
        <UserName>User {id}</UserName>
      </UserNameContainer>
      <StarsContainer>{stars && starsArray}</StarsContainer>
    </Container>
  );
};

export default DriverHeader;
