import Loader from 'components/Loader';
import {
  StationMonitoringPreviewAlarmsQuery,
  StationMonitoringPreviewAlarmsQueryVariables,
} from 'generated/graphql';
import stationMonitoringPreviewAlarms from 'graphql/queries/stationMonitoringPreviewAlarms';
import { useAuthQuery } from 'hooks';
import { NewTag } from 'new-components/NewTag';
import { StationAlarm } from 'new-components/StationAlarm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row, TagContainer } from '../styles';
import { AlarmContainer, Alarms, LoaderWrapper, Title } from './styles';

type StationAlarmsProps = {
  stationId: string;
  setScrollWheelEnabled: (state: boolean) => void;
  onAlarmPress: () => void;
};

const StationAlarms = ({
  stationId,
  setScrollWheelEnabled,
  onAlarmPress,
}: StationAlarmsProps) => {
  const { t } = useTranslation();
  const { data, loading } = useAuthQuery<
    StationMonitoringPreviewAlarmsQuery,
    StationMonitoringPreviewAlarmsQueryVariables
  >(stationMonitoringPreviewAlarms, {
    variables: {
      offset: 0,
      where: { stationId },
      stationId,
    },
  });

  const station = data?.station;

  const criticalAlarmsCount = station?.criticalAlarmsCount.count ?? 0;

  return (
    <Alarms
      onMouseEnter={() => setScrollWheelEnabled(false)}
      onMouseLeave={() => setScrollWheelEnabled(true)}
    >
      {loading ? (
        <LoaderWrapper>
          <Loader color="SECONDARY_GRAY" size={32} />
        </LoaderWrapper>
      ) : (
        <Column>
          <Row margin="0 0 8px 0">
            <Title>{t('monitoring.station-alarms.title')}</Title>
            <TagContainer margin="0 8px">
              <NewTag type="text" color="danger">
                {criticalAlarmsCount}
              </NewTag>
            </TagContainer>
          </Row>
          {station?.alarms.map((alarm) => (
            <AlarmContainer key={alarm.id}>
              <StationAlarm
                alarm={{ ...alarm, resolved: false }}
                isResolved={false}
                selected={false}
                onClick={onAlarmPress}
              />
            </AlarmContainer>
          ))}
        </Column>
      )}
    </Alarms>
  );
};

export { StationAlarms };
