import { gql } from '@apollo/client';

export default gql`
  mutation ConfigureStationCommunication(
    $where: SelectStationInput!
    $ocppVersion: OCPPVersion!
    $ocppUrl: String
    $routerPortal: StationPortalAccessConfigInput
    $configPortal: StationPortalAccessConfigInput
  ) {
    configureStationCommunication(
      where: $where
      data: {
        ocppVersion: $ocppVersion
        ocppUrl: $ocppUrl
        routerPortal: $routerPortal
        configPortal: $configPortal
      }
    )
  }
`;
