import { gql } from '@apollo/client';
import alarmLabelFieldsFragment from 'graphql/fragments/alarmLabelFields';

export default gql`
  query StationLogsAlarmsListing(
    $resolved: Boolean = false
    $stationId: String!
    $limit: Int
    $offset: Int
  ) {
    station(where: { stationId: $stationId }) {
      id
      activeSummary: alarmsSummary(filter: { resolved: false }) {
        count
      }
      resolvedSummary: alarmsSummary(filter: { resolved: true }) {
        count
      }
      alarms(
        filter: { resolved: $resolved }
        offset: $offset
        limit: $limit
        sort: { timestamp: DESC }
      ) {
        __typename
        id
        stationId
        resolved
        resolvedAt
        connectorId
        timestamp
        createdByStationLogId
        ...AlarmLabelFields
      }
    }
  }
  ${alarmLabelFieldsFragment}
`;
