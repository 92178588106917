import styled from 'styled-components';
import Config from 'config';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 420px;
  height: 36px;
  align-items: center;
  box-sizing: unset;
  & > * {
    box-sizing: unset;
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 13px;
  z-index: 0;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  & > * {
    box-sizing: unset;
  }
  ${Container}:focus-within &:hover {
    background: #dbdbdb 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
`;

export const CloseIcon = styled(Config.ICONS.CLOSE_ICON)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  box-sizing: unset;
  & > * {
    box-sizing: unset;
  }
  ${Container}:not(:focus-within) & {
    visibility: hidden;
  }
`;
