import {
  Box,
  Flex,
  Heading,
  Text,
  Tooltip,
  Button,
  Skeleton,
} from '@chakra-ui/react';
import {
  ChangeStationConfigurationKeyMutation,
  ChangeStationConfigurationKeyMutationVariables,
  StationConfigurationKey,
  StationConfigurationKeysQuery,
  StationConfigurationKeysQueryVariables,
  SynchronizeStationConfigurationKeysMutation,
  SynchronizeStationConfigurationKeysMutationVariables,
} from 'generated/graphql';
import changeStationConfigurationKeyMutation from 'graphql/mutations/changeStationConfigurationKey';
import stationConfigurationKeysQuery from 'graphql/queries/stationConfigurationKeys';
import { useAuthMutation, useAuthQuery } from 'hooks';
import { StationContext } from 'pages/StationPage/StationContext';
import { useContext, useEffect, useState } from 'react';
import Toast from 'pages/StationPage/Toast';
import { toast } from 'react-toastify';
import { Icon } from 'new-components/Icon';
import synchronizeStationConfigurationKeysMutation from 'graphql/mutations/synchronizeStationConfigurationKeys';
import ErrorComponent from 'new-components/ErrorComponent';
import { useTranslation } from 'react-i18next';
import {
  checkDifferenceBetweenObjects,
  getStationConfigurationKeysChanged,
  sortStationConfigurationKeys,
} from './functions';
import ConfigurationKeyInput from '../components/ConfigurationKeyInput';

const AdvancedSettings = () => {
  const { t } = useTranslation();
  const { id: stationId } = useContext(StationContext);
  const [toastVisible, setToastVisible] = useState(false);
  const [currentStationConfigurationKeys, setCurrentStationConfigurationKeys] =
    useState<StationConfigurationKey[] | []>([]);
  const [stationConfigurationKeys, setStationConfigurationKeys] = useState<
    StationConfigurationKey[] | []
  >([]);

  const resetFields = () => {
    setStationConfigurationKeys(currentStationConfigurationKeys);
    setToastVisible(false);
  };

  const [changeStationConfigurationKey, { loading: loadingChanges }] =
    useAuthMutation<
      ChangeStationConfigurationKeyMutation,
      ChangeStationConfigurationKeyMutationVariables
    >(changeStationConfigurationKeyMutation);

  const [synchronizeStationConfigurationKeys, { loading: loadingSync }] =
    useAuthMutation<
      SynchronizeStationConfigurationKeysMutation,
      SynchronizeStationConfigurationKeysMutationVariables
    >(synchronizeStationConfigurationKeysMutation, {
      variables: {
        where: {
          stationId,
        },
      },
    });

  const {
    loading: loadingQuery,
    error: errorQuery,
    data,
    refetch,
  } = useAuthQuery<
    StationConfigurationKeysQuery,
    StationConfigurationKeysQueryVariables
  >(stationConfigurationKeysQuery, {
    variables: {
      where: {
        stationId,
      },
    },
  });

  const sortedStationConfigurationKeys = sortStationConfigurationKeys(
    data?.station?.configurationKeys ?? []
  );

  useEffect(() => {
    setCurrentStationConfigurationKeys(sortedStationConfigurationKeys);
    setStationConfigurationKeys(sortedStationConfigurationKeys);
  }, [sortedStationConfigurationKeys]);

  const handleSynchronizeStationConfigurationKeysButtonPress = async () => {
    try {
      await synchronizeStationConfigurationKeys();
      toast.success(
        t('station-page.config-advanced-tab.toast-successfully-synced-msg')
      );
      await refetch();
    } catch {
      toast.error(t('station-page.config-advanced-tab.toast-sync-error-msg'));
    }
  };

  const changeStationConfigurationToSpecificKey = async ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    try {
      await changeStationConfigurationKey({
        variables: {
          key,
          value,
          where: {
            stationId,
          },
        },
      });
      toast.success(
        t('station-page.toast-station-key-successfully-configured.message', {
          key,
        })
      );
      setCurrentStationConfigurationKeys((currentKeys) =>
        currentKeys.map((k) => {
          if (k.key === key) {
            return {
              ...k,
              value,
            };
          }
          return k;
        })
      );
    } catch {
      toast.error(
        t('station-page.toast-station-configuration-key-error.message', {
          key,
        })
      );
      setStationConfigurationKeys(currentStationConfigurationKeys);
    } finally {
      setToastVisible(false);
    }
  };

  const handleSaveChangesButtonPress = () => {
    const diff = getStationConfigurationKeysChanged({
      stationConfigurationKeys,
      currentStationConfigurationKeys,
    });

    for (let i = 0; i < diff.length; i++) {
      const { key, value } = diff[i];
      changeStationConfigurationToSpecificKey({
        key,
        value,
      });
    }
  };

  useEffect(() => {
    const hasDifference = checkDifferenceBetweenObjects({
      stationConfigurationKeys,
      currentStationConfigurationKeys,
    });
    if (hasDifference) {
      setToastVisible(true);
    } else {
      setToastVisible(false);
    }
  }, [stationConfigurationKeys, currentStationConfigurationKeys]);

  return (
    <Box p="4" maxWidth="container.md">
      <Flex alignItems="center" justifyContent="space-between" mb="8">
        <Heading fontSize="2xl">
          {t('station-page.config-tab.advanced-tab.header.title')}
        </Heading>
        <Button
          isLoading={loadingSync}
          colorScheme="gray"
          variant="outline"
          fontWeight="normal"
          fontFamily="heading"
          bg="white"
          color="gray.900"
          size="sm"
          leftIcon={<Icon type="NEW_REFRESH" size={24} color="PRIMARY_GRAY" />}
          onClick={() => handleSynchronizeStationConfigurationKeysButtonPress()}
        >
          {t('station-page.config-tab.advanced-tab.synchronize-btn.title')}
        </Button>
      </Flex>
      <Box
        position="relative"
        borderRadius="8"
        borderWidth="thin"
        borderColor="gray.100"
        bg="white"
        width="100%"
        boxShadow="sm"
      >
        {errorQuery ? (
          <Box mt="24" pb="52">
            <ErrorComponent size="large" />
          </Box>
        ) : loadingQuery || loadingSync ? (
          Array(6)
            .fill('')
            .map((_) => (
              <Box p="4" borderBottomColor="gray.100" borderBottomWidth="thin">
                <Skeleton h="10" />
              </Box>
            ))
        ) : (
          <>
            {stationConfigurationKeys.map((stationConfigurationKey, index) => (
              <Box
                py="4"
                borderBottomColor="gray.100"
                borderBottomWidth="thin"
                key={index}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  px="4"
                  flexWrap="wrap"
                >
                  {stationConfigurationKey.label ? (
                    <Box>
                      <Flex align="center">
                        <Heading fontSize="md">
                          {stationConfigurationKey.label}
                        </Heading>
                        {stationConfigurationKey.description && (
                          <Tooltip
                            label={stationConfigurationKey.description}
                            hasArrow
                            placement="right"
                            bg="gray.900"
                          >
                            <Box ml="2">
                              <Icon
                                type="NEW_INFO_FILL"
                                color="SECONDARY_GRAY"
                              />
                            </Box>
                          </Tooltip>
                        )}
                      </Flex>
                      <Box mt="0.5">
                        <Text color="gray.600" fontSize="11px">
                          {stationConfigurationKey.key}
                        </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Text fontSize="sm">{stationConfigurationKey.key}</Text>
                  )}
                  <ConfigurationKeyInput
                    stationConfigurationKey={stationConfigurationKey}
                    onChangeValue={(value) =>
                      setStationConfigurationKeys(
                        stationConfigurationKeys.map(
                          (stationConfigurationKey, currentIndex) =>
                            currentIndex !== index
                              ? stationConfigurationKey
                              : {
                                  ...stationConfigurationKey,
                                  value,
                                }
                        )
                      )
                    }
                  />
                </Flex>
              </Box>
            ))}
          </>
        )}
        <Toast
          id={'changeStationConfigurationKeys'}
          resetFields={resetFields}
          onClick={() => handleSaveChangesButtonPress()}
          loading={loadingChanges}
          isVisible={toastVisible}
          inputsValues={[stationConfigurationKeys]}
        />
      </Box>
    </Box>
  );
};

export default AdvancedSettings;
