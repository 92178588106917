import React from 'react';
import { SelectorLinkContainer } from './styles';

type Props = {
  id: number;
  active: boolean;
  onClick: (id: number) => void;
  children: React.ReactChild;
  className?: string;
};

export const FilterLink: React.FC<Props> = ({
  id,
  active,
  children,
  onClick,
  className,
}) => (
  <SelectorLinkContainer
    className={className}
    key={id}
    onClick={() => onClick(id)}
    active={active}
  >
    {children}
  </SelectorLinkContainer>
);
