import { gql } from '@apollo/client';

export default gql`
  query BasicStationsDetails {
    stations {
      id
      cpoId
      coordinates {
        latitude
        longitude
      }
      status
    }
  }
`;
