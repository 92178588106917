import type {
  AllStationsQuery,
  ConnectorsListFieldsFragment,
  TableStationsQuery,
} from 'generated/graphql';
import { formatOrgId, formatEnergy } from 'services/format';
import { TranslationKeyType } from 'types/translation';
import { CurrencyType } from 'types';
import { formatCurrency, formatUsageRate } from './format';

type StationA = AllStationsQuery['stations'][number] & {
  sessionsSummary: { grossValue?: number };
};
type StationB = TableStationsQuery['stations'][number] & {
  sessionsSummary: { energyConsumed?: number };
};

type StationFormatInput = (StationA | StationB) & {
  address?: StationB['address'];
  connectors?: StationB['connectors'];
  usageRate?: StationA['usageRate'];
};

export type FormattedStation = {
  stationName: string;
  state: string;
  city: string;
  connectors?: ConnectorsListFieldsFragment[];
  grossValue: string;
  energyConsumed: string;
  dailyGrossValue: string;
  stationConfigs: string;
  count: number | string;
  dailyCount: number | string;
  id: string;
  usageRate: string;
  cpoId: string;
  orgName: string;
};

export const formatStationFields = (
  station: Partial<StationFormatInput> & { id: string },
  orgNameMap: { [key: string]: string },
  currencyType?: CurrencyType
): FormattedStation => ({
  id: station.id,
  stationName: station.name ?? '',
  city: station.address?.city ?? '-',
  state: station.address?.state ?? '-',
  cpoId: station.cpoId ?? '-',
  orgName: station.cpoId ? formatOrgId(station.cpoId, orgNameMap) : '-',
  energyConsumed: formatEnergy(station.sessionsSummary?.energyConsumed ?? 0),
  connectors: station.connectors,
  count: station.sessionsSummary?.count ?? 0,
  dailyCount: station.sessionsSummary?.count ?? 0,
  stationConfigs: station.id,
  grossValue: formatCurrency(station.sessionsSummary?.grossValue ?? 0, {
    currencyType,
  }),
  dailyGrossValue: formatCurrency(station.sessionsSummary?.grossValue ?? 0, {
    currencyType,
  }),
  usageRate: formatUsageRate(station.usageRate),
});

// STATION TABLE COLUMNS
export const stationFieldLabelTable: Record<
  Exclude<
    keyof Partial<FormattedStation>,
    'connectors' | 'id' | 'cpoId' | 'orgName'
  >,
  TranslationKeyType
> = {
  stationName: 'home.station-table.station-name.title',
  state: 'home.station-table.state.title',
  city: 'home.station-table.city.title',
  grossValue: 'home.station-table.gross-value.title',
  dailyGrossValue: 'home.station-table.daily-gross-value.title',
  stationConfigs: 'home.station-table.station-configs.title',
  count: 'home.station-table.count.title',
  energyConsumed: 'home.station-table.energy-consumed.title',
  dailyCount: 'home.station-table.daily-count.title',
  usageRate: 'home.station-table.usage-rate.title',
};

export const getStationFieldLabel = (
  key: keyof FormattedStation
): TranslationKeyType | undefined =>
  key === 'id' || key === 'connectors' || key === 'cpoId' || key === 'orgName'
    ? undefined
    : stationFieldLabelTable[key];
