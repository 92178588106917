import {
  getMultiFactorResolver,
  multiFactor,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User as FirebaseUser,
} from 'firebase/auth';
import { useState } from 'react';
import { auth } from 'services/identity-provider/firebaseConfig';
import {
  generateRecaptcha,
  USER_INFO_TFA_LOGIN_ERROR_COOKIE_KEY,
} from 'services/tfa';

export function useEnableMemberTFA(): [
  ({
    hashCode,
    verificationCode,
    currentUser,
  }: {
    hashCode: string;
    verificationCode: string;
    currentUser: FirebaseUser;
  }) => Promise<void>,
  {
    loading: boolean;
  }
] {
  const [loading, setLoading] = useState(false);

  async function enableMemberTFACallback({
    hashCode,
    verificationCode,
    currentUser,
  }: {
    hashCode: string;
    verificationCode: string;
    currentUser: FirebaseUser;
  }) {
    try {
      setLoading(true);
      const credential = PhoneAuthProvider.credential(
        hashCode,
        verificationCode
      );
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential);
      await multiFactor(currentUser).enroll(multiFactorAssertion);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return [enableMemberTFACallback, { loading }];
}

export function useDisableMemberTFA(): [
  ({
    verificationCode,
    verificationId,
    resolver,
  }: {
    verificationCode: string;
    verificationId: string;
    resolver: MultiFactorResolver;
  }) => Promise<void>,
  {
    loading: boolean;
  }
] {
  const [loading, setLoading] = useState(false);
  async function disableMemberTFACallback({
    verificationCode,
    verificationId,
    resolver,
  }: {
    verificationCode: string;
    verificationId: string;
    resolver: MultiFactorResolver;
  }) {
    setLoading(true);
    try {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      const { user: firebaseUser } = await resolver.resolveSignIn(
        multiFactorAssertion
      );
      const factors = multiFactor(firebaseUser).enrolledFactors[0].uid;
      return await multiFactor(firebaseUser).unenroll(factors);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return [disableMemberTFACallback, { loading }];
}

export async function sendTFAVerificationCode(
  recaptchaVerifier?: RecaptchaVerifier
): Promise<{
  verificationId: string;
  resolver: MultiFactorResolver;
  recaptchaVerifier?: RecaptchaVerifier;
}> {
  try {
    const userInfo = window.localStorage.getItem(
      USER_INFO_TFA_LOGIN_ERROR_COOKIE_KEY
    );

    if (!userInfo) throw new Error('user not found');

    const recaptcha = recaptchaVerifier || generateRecaptcha();

    const resolver = getMultiFactorResolver(auth, JSON.parse(userInfo));

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);

    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptcha
    );

    return {
      verificationId,
      resolver,
      recaptchaVerifier: recaptcha,
    };
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export function useTFAFirebaseLogin(): [
  ({
    verificationCode,
    verificationId,
    resolver,
  }: {
    verificationCode: string;
    verificationId: string;
    resolver: MultiFactorResolver;
  }) => Promise<{ token: string }>,
  {
    loading: boolean;
  }
] {
  const [loading, setLoading] = useState(false);
  async function tfaFirebaseLoginCallback({
    verificationCode,
    verificationId,
    resolver,
  }: {
    verificationCode: string;
    verificationId: string;
    resolver: MultiFactorResolver;
  }) {
    setLoading(true);
    try {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const { user } = await resolver.resolveSignIn(multiFactorAssertion);
      const token = await user.getIdToken(true);
      return { token };
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return [tfaFirebaseLoginCallback, { loading }];
}
