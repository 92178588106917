import Config from 'config';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const InputElement = styled.input<{
  borderColor?: keyof typeof Config.COLORS;
}>`
  outline: none;
  border-style: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  border: 2px solid
    ${({ theme, borderColor }) => theme.COLORS[borderColor || 'TERTIARY_GRAY']};
  font-size: 14px;

  border-radius: 6px;
  padding: 6px 15px;

  &::placeholder {
    color: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
    font-size: 16px;
    letter-spacing: 0.32px;
  }
  box-shadow: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
  }

  filter: none;
`;

export const IconAlert = styled(Config.ICONS.ALERT)`
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  position: absolute;
  right: -30px;
  bottom: 3.7px;
  color: ${({ theme }) => theme.COLORS.ERROR};
`;

const passwordOnOff: React.FC<{ show: boolean; onClick: () => void }> = ({
  show,
  ...props
}) =>
  show ? (
    <Config.ICONS.PASSWORD_ON {...props} />
  ) : (
    <Config.ICONS.PASSWORD_OFF {...props} />
  );

export const IconPassword = styled(passwordOnOff)`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  cursor: pointer;
`;
