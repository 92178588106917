import styled from 'styled-components';
import Config from 'config';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.LOGIN_LOGO_BACKGROUND};
`;

export const LeftContainerLogo = styled(Config.ICONS.LOGIN_LOGO).attrs({
  color: Config.COLORS.WHITE,
})`
  max-width: 60%;
  max-height: 75%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
