import { Icon } from 'new-components/Icon';
import React from 'react';
import { Body, Border, Container, Header, RoundButton } from './styles';

type Props = {
  show: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const Modal: React.FC<Props> = ({ children, show, onClose }) => (
  <Container show={show} onClose={onClose} onHide={onClose}>
    <Header>
      <RoundButton onClick={onClose}>
        <Icon type={'NEW_CLOSE_X'} color="PRIMARY_GRAY" size={24} />
      </RoundButton>
    </Header>
    <Body>
      {children}
      <Border></Border>
    </Body>
  </Container>
);

export default Modal;
