import JSONInterface from 'components/JSONInterface';
import Table, { Column } from 'components/Table';
import {
  TableStationEventsQuery,
  TableStationEventsQueryVariables,
} from 'generated/graphql';
import tableStationEvents from 'graphql/queries/tableStationEvents';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import Tag from 'new-components/Tag';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, JSONSidebar, JSONWrapper, TagContainer } from './styles';

type StreamEvent = NonNullable<
  TableStationEventsQuery['station']
>['events'][number];

const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

type Props = {
  stationId: string;
};

const StreamEventsTable: React.FC<Props> = ({ stationId }) => {
  const { t } = useTranslation();

  const [{ pageIndex, pageSize }, setPagination] = useState(defaultPagination);

  const queryOptions = {
    variables: {
      where: { stationId },
      limit: pageSize,
      offset: pageIndex * pageSize,
    },
  };

  const { data, loading } = useRealtimeQuery<
    TableStationEventsQuery,
    TableStationEventsQueryVariables
  >(tableStationEvents, queryOptions);

  const numberOfPages = Math.ceil(
    (data?.station?.eventsSummary.count ?? 0) / pageSize
  );

  const renderedEvents: StreamEvent[] = data?.station?.events ?? [];

  const columns: Column<StreamEvent>[] = [
    {
      Header: t('station-page.control-tab.station-events-table.version.title'),
      accessor: 'streamVersion',
      disableSortBy: true,
      Cell: ({ row: { original } }) => original.streamVersion,
    },
    {
      Header: t('station-page.control-tab.station-events-table.date.title'),
      accessor: 'timestamp',
      disableSortBy: true,
      Cell: ({ row: { original } }) =>
        t('station-page.control-tab.station-events-table.date.value', {
          value: new Date(original.timestamp),
        }),
    },
    {
      Header: t('station-page.control-tab.station-events-table.severity.title'),
      accessor: 'severity',
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <TagContainer>
          <Tag type={original.severity} />
        </TagContainer>
      ),
    },
    {
      Header: t('station-page.control-tab.station-events-table.event.title'),
      accessor: 'type',
      disableSortBy: true,
    },
  ];

  return (
    <Container>
      <Table<StreamEvent>
        paginated
        pageCount={numberOfPages}
        fetchData={setPagination}
        data={renderedEvents}
        columns={columns}
        expandable
        loading={loading}
        renderRowSubComponent={({ row: { original } }) => (
          <JSONWrapper>
            <JSONSidebar />
            <JSONInterface data={original.data} mode="view" />
          </JSONWrapper>
        )}
      />
    </Container>
  );
};

export default StreamEventsTable;
