import styled from 'styled-components';
import { NAVBAR_HEIGHT } from './Navbar/styles';

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  float: right;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const InnerContent = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - ${NAVBAR_HEIGHT});
`;
