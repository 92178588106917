import Config from 'config';
import {
  RouterConnectivityStatus,
  Station,
  StationConnectivityStatus,
} from 'generated/graphql';
import moment from 'moment';
import { NewTag } from 'new-components/NewTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row, TagContainer } from '../styles';
import { Connection, ConnectionText, SignalStatus } from './styles';

type StationConnectionProps = {
  routerConnectivityStatus: RouterConnectivityStatus;
  stationConnectivityStatus: StationConnectivityStatus;
  lastConnectedAt: Station['lastConnectedAt'];
};

const StationConnection = ({
  routerConnectivityStatus,
  stationConnectivityStatus,
  lastConnectedAt,
}: StationConnectionProps) => {
  const { t } = useTranslation();
  const ConnectivityStatus = () => {
    if (!routerConnectivityStatus) return null;
    if (routerConnectivityStatus === 'NOT_CONFIGURED')
      return (
        <Row margin="0 0 4px 0">
          <ConnectionText>
            {t('shared-config.modem-info.title')}{' '}
            <ConnectionText>
              {t(Config.LABELS.MODEM_STATUS[routerConnectivityStatus])}
            </ConnectionText>
          </ConnectionText>
        </Row>
      );
    return (
      <Row margin="0 0 4px 0">
        <ConnectionText>{t('shared-config.modem-info.title')} </ConnectionText>
        <TagContainer margin="0 8px">
          <NewTag type="text" size="inline" status={routerConnectivityStatus}>
            {t(Config.LABELS.MODEM_STATUS[routerConnectivityStatus])}
          </NewTag>
        </TagContainer>
      </Row>
    );
  };

  return (
    <Connection>
      <Column>
        <ConnectivityStatus />
        <Row margin="0 0 4px 0">
          <ConnectionText>
            {t('station-connection.station-signal.title')}
          </ConnectionText>
          <TagContainer margin="0 8px">
            <NewTag
              type="text"
              size="inline"
              status={stationConnectivityStatus}
            >
              {t(
                Config.LABELS.STATION_CONNECTIVITY_STATUS[
                  stationConnectivityStatus
                ]
              )}
            </NewTag>
          </TagContainer>
        </Row>
        <Row>
          <SignalStatus>
            {t('station-preview.last-received-signal.title', {
              lastConnectedAt: moment(lastConnectedAt).fromNow(),
            })}
          </SignalStatus>
        </Row>
      </Column>
    </Connection>
  );
};

export { StationConnection };
