import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const AtlasPowerConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'evpv-power',
  LABEL: 'EVPVPOWER',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#63BF45',
    CLICKABLE: '#63BF45',
    ERROR: '#F27E00',
    SIDEBAR: '#1A1A1A',
    LOGIN_LOGO_BACKGROUND: '#1A1A1A',

    RISE: '#63BF45',
    FALL: '#F27E00',
    NEUTRAL: '#63BF45',

    CRITICAL: '#F27E00',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#F27E00',
    NoCost: '#F4F4F4',
    Error: '#F27E00',

    AVAILABLE: '#63BF45',
    CHARGING: '#CDDC27',
    INOPERATIVE: '#F27E00',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F27E00',
    RESERVED: '#1A1A1A',

    PREPARING: '#CDDC27',
    FINISHING: '#CDDC27',

    SELECTED_DATE_BOUND_BACKGROUND: '#63BF45',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#63BF45',

    DATE_PRE_SELECTED_BACKGROUND: '#63BF45',
    DATE_PRE_SELECTED_BORDER: '#63BF45',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#63BF45',
    DATE_PRE_SELECTED_COLOR: '#63BF45',

    DATE_SELECTED_BACKGROUND: '#63BF45',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#63BF45',

    CURRENT_DAY: '#63BF45',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#63BF45',
      Subsidized: '#63BF45',
      NoCost: '#F4F4F4',
      Error: '#F27E00',
      ErrorOnPreAuthorization: '#F27E00',
    },
    STATION_STATUS: {
      AVAILABLE: '#63BF45',
      CHARGING: '#CDDC27',
      INOPERATIVE: '#F27E00',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F27E00',
      RESERVED: '#1A1A1A',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#63BF45',
      PREPARING: '#CDDC27',
      CHARGING: '#CDDC27',
      FINISHING: '#CDDC27',
      INOPERATIVE: '#F27E00',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#1A1A1A',
    },

    BILLING: '#63BF45',
    RECHARGES: '#CDDC27',
    AVAILABILITY: '#1A1A1A',

    BUTTON_AVAILABLE: '#63BF45',
    BUTTON_UNAVAILABLE: '#63BF45',
    BUTTON_LOADING: '#63BF45',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#63BF45',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#63BF45',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#63BF45',

      DATE_PRE_SELECTED_BACKGROUND: '#63BF45',
      DATE_PRE_SELECTED_BORDER: '#63BF45',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#63BF45',
      DATE_PRE_SELECTED_COLOR: '#63BF45',

      DATE_SELECTED_BACKGROUND: '#63BF45',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#63BF45',

      CURRENT_DAY: '#63BF45',
    },
  },
  FONTS: {
    PRIMARY: 'Montserrat-Regular',
    SECONDARY: 'Montserrat-Bold',
    TERTIARY: 'Montserrat-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.431037204521907,
      lng: -48.44221814061371,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'EVPV POWER - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/atlas-power',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#63BF45',
      '100': '#63BF45',
      '200': '#63BF45',
      '300': '#63BF45',
      '400': '#63BF45',
      '500': '#63BF45',
      '600': '#63BF45',
      '700': '#63BF45',
      '800': '#63BF45',
      '900': '#63BF45',
    },
    LINK: {
      '50': '#63BF45',
      '100': '#63BF45',
      '200': '#63BF45',
      '300': '#63BF45',
      '400': '#63BF45',
      '500': '#63BF45',
      '600': '#63BF45',
      '700': '#63BF45',
      '800': '#63BF45',
      '900': '#63BF45',
    },
    ERROR: {
      '50': '#F27E00',
      '100': '#F27E00',
      '200': '#F27E00',
      '300': '#F27E00',
      '400': '#F27E00',
      '500': '#F27E00',
      '600': '#F27E00',
      '700': '#F27E00',
      '800': '#F27E00',
      '900': '#F27E00',
    },
    SUCCESS: {
      '50': '#63BF45',
      '100': '#63BF45',
      '200': '#63BF45',
      '300': '#63BF45',
      '400': '#63BF45',
      '500': '#63BF45',
      '600': '#63BF45',
      '700': '#63BF45',
      '800': '#63BF45',
      '900': '#63BF45',
    },
    CHARGING: {
      '50': '#CDDC27',
      '100': '#CDDC27',
      '200': '#CDDC27',
      '300': '#CDDC27',
      '400': '#CDDC27',
      '500': '#CDDC27',
      '600': '#CDDC27',
      '700': '#CDDC27',
      '800': '#CDDC27',
      '900': '#CDDC27',
    },
    AVAILABLE: {
      '50': '#63BF45',
      '100': '#63BF45',
      '200': '#63BF45',
      '300': '#63BF45',
      '400': '#63BF45',
      '500': '#63BF45',
      '600': '#63BF45',
      '700': '#63BF45',
      '800': '#63BF45',
      '900': '#63BF45',
    },
    SECONDARY: {
      '50': '#1A1A1A',
      '100': '#1A1A1A',
      '200': '#1A1A1A',
      '300': '#1A1A1A',
      '400': '#1A1A1A',
      '500': '#1A1A1A',
      '600': '#1A1A1A',
      '700': '#1A1A1A',
      '800': '#1A1A1A',
      '900': '#1A1A1A',
    },
    RESERVE: {
      '50': '#1A1A1A',
      '100': '#1A1A1A',
      '200': '#1A1A1A',
      '300': '#1A1A1A',
      '400': '#1A1A1A',
      '500': '#1A1A1A',
      '600': '#1A1A1A',
      '700': '#1A1A1A',
      '800': '#1A1A1A',
      '900': '#1A1A1A',
    },
    INOPERATIVE: {
      '50': '#F27E00',
      '100': '#F27E00',
      '200': '#F27E00',
      '300': '#F27E00',
      '400': '#F27E00',
      '500': '#F27E00',
      '600': '#F27E00',
      '700': '#F27E00',
      '800': '#F27E00',
      '900': '#F27E00',
    },
  },
};
export default AtlasPowerConfig;
