import styled from 'styled-components';

export const Header = styled('header')`
  display: flex;
  justify-content: center;
  padding: 24px 0 24px 0;
`;

export const SubHeader = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 8px;
  padding: 0 0 24px 0;
`;

export const Body = styled('section')`
  padding: 0 24px 24px 24px;
`;
export const Form = styled('form')`
  display: flex;
  flex-flow: column nowrap;
  gap: 36px;
`;
export const InputGroup = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;
