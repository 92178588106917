import { Center } from '@chakra-ui/react';
import { BlankState as BaseBlankState } from 'new-components/BlankState';
import { useTranslation } from 'react-i18next';

export const BlankState = () => {
  const { t } = useTranslation();
  return (
    <Center h="full" maxW={400} margin="auto" w="full">
      <BaseBlankState
        icon="NEW_ERROR"
        title={t('account-exclusion-blank-state.title')}
        description={t('account-exclusion-blank-state.description')}
      />
    </Center>
  );
};
