import styled from 'styled-components';
import Config from 'config';
import { Typography } from 'components';

const bottomDescriptionPadding = {
  top: 9,
  bottom: 57,
};
const getTheRestOfScreenHeight =
  bottomDescriptionPadding.top + bottomDescriptionPadding.bottom + 172.6;

export const Container = styled.div`
  margin: 0;
  margin-top: -30px;
  padding: 0;
`;

export const Header = styled.div``;

export const PageCard = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: calc(
    100% - ${bottomDescriptionPadding.top + bottomDescriptionPadding.bottom}px
  );
  width: 100%;
  user-select: none;
`;

export const LeftContainer = styled.div`
  min-width: 60%;
  max-width: 60%;
  box-sizing: border-box;
`;

export const InnerContainer = styled.div`
  min-height: calc(100vh - ${getTheRestOfScreenHeight}px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AddImageIcon = styled(Config.ICONS.GALLERY)`
  margin-right: 12px;
`;

export const SaveButtonContainer = styled.div`
  width: 140px;
  height: 34px;
  margin: auto 0 auto auto;
  & > * {
    box-sizing: border-box;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const AddImageRow = styled(Row)`
  margin-bottom: 15px;
  flex-direction: column;
`;

export const ImageGalleryRow = styled(Row)`
  position: relative;
`;

export const AddButtonContainer = styled.div`
  min-width: 104px;
  max-width: 104px;
  height: 30px;
  margin-top: ${({ theme }) => theme.space['SPACE-8']}px;
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-flow: row-reverse wrap;
  width: 60%;
`;

export const TextAreaInput = styled(InputContainer)``;

// workaround to fix firefox textarea height bug
export const TextAreaFirefoxHeightFix = styled.div`
  height: auto;
  width: 100%;
`;

export const HiddenInput = styled.input`
  position: absolute;
  top: -12px;
  right: 0px;
  bottom: 0px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 17px);
  opacity: 0;
  font-size: 0px;
  cursor: pointer;
  ::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

export const Slider = styled.input.attrs({
  type: 'range',
  min: 1,
  max: 100,
  value: 50,
})`
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  :hover {
    opacity: 1;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  ::-moz-range-thumb {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`;

export const CharacterDescription = styled(Typography)<{
  paddingBottom?: number;
}>`
  display: block;
  margin-top: 5px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
`;
