import { gql } from '@apollo/client';

export default gql`
  query StationMonitoringPreview(
    $where: StationWhereUniqueInput!
    $includeAlarms: Boolean!
  ) {
    station(where: $where) {
      id
      name
      status
      tag
      lastConnectedAt
      routerConnectivityStatus
      stationConnectivityStatus
      cpoId
      criticalAlarmsCount: alarmsSummary(
        filter: { level: CRITICAL, resolved: false }
      ) @include(if: $includeAlarms) {
        count
      }
    }
  }
`;
