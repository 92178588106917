import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
`;

export const HeaderContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  text-align: center;
`;

export const SuccessInstructionsContainer = styled.div`
  margin-top: 20px;
`;

export const SuccessButtonContainer = styled.div`
  margin-top: 60px;
  width: 235px;
`;

export const ButtonContainer = styled.div`
  width: 190px;
  height: 52px;
  margin-top: 50px;
  margin-bottom: 40px;
`;
