import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import env from '@beam-australia/react-env';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const YpfConfig: ConfigType = {
  ...SharedConfig,
  YPF_SSO_ENABLED: env('YPF_SSO_ENABLED') === 'true',
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-13',
  CODE: 'ypf',
  LABEL: 'YPF',
  LANGUAGE: 'es_ES',
  COUNTRY: 'AR',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#00AABB',
    CLICKABLE: '#00AABB',
    ERROR: '#F87060',
    SIDEBAR: '#00AABB',
    LOGIN_LOGO_BACKGROUND: '#00AABB',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#0649C8',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#00AABB',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91',

    PREPARING: '#00AABB',
    FINISHING: '#00AABB',

    SELECTED_DATE_BOUND_BACKGROUND: '#02919F',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#027A86',

    DATE_PRE_SELECTED_BACKGROUND: '#00AABB',
    DATE_PRE_SELECTED_BORDER: '#02D7EC',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#0AE8FE',
    DATE_PRE_SELECTED_COLOR: '#027A86',

    DATE_SELECTED_BACKGROUND: '#00AABB',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#02919F',

    CURRENT_DAY: '#00AABB',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00AABB',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#662C91',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#00AABB',
      CHARGING: '#00AABB',
      FINISHING: '#00AABB',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },

    BILLING: '#04E762',
    RECHARGES: '#00AABB',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#00AABB',
    BUTTON_UNAVAILABLE: '#0AE8FE',
    BUTTON_LOADING: '#02D7EC',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#00AABB',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#02919F',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#027A86',

      DATE_PRE_SELECTED_BACKGROUND: '#00AABB',
      DATE_PRE_SELECTED_BORDER: '#02D7EC',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#0AE8FE',
      DATE_PRE_SELECTED_COLOR: '#027A86',

      DATE_SELECTED_BACKGROUND: '#00AABB',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#02919F',

      CURRENT_DAY: '#00AABB',
    },
  },
  FONTS: {
    PRIMARY: 'Aeonik-Regular',
    SECONDARY: 'Aeonik-Bold',
    TERTIARY: 'Aeonik-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -34.588866537295104,
      lng: -58.40378747508825,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Não temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'YPF - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/ypf',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#23EAFE',
      '100': '#0AE8FE',
      '200': '#02D7EC',
      '300': '#02BFD2',
      '400': '#02A8B9',
      '500': '#00AABB',
      '600': '#02919F',
      '700': '#027A86',
      '800': '#02636C',
      '900': '#024C53',
    },
    LINK: {
      '50': '#528CFC',
      '100': '#3A7CFB',
      '200': '#216CFA',
      '300': '#095BF9',
      '400': '#0752E2',
      '500': '#00AABB',
      '600': '#0649C8',
      '700': '#0640AF',
      '800': '#063796',
      '900': '#052E7D',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03B54D',
      '700': '#039C42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#00C1FD',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03B54D',
      '700': '#039C42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#528CFC',
      '100': '#3A7CFB',
      '200': '#216CFA',
      '300': '#095BF9',
      '400': '#0752E2',
      '500': '#00AABB',
      '600': '#0649C8',
      '700': '#0640AF',
      '800': '#063796',
      '900': '#052E7D',
    },
    RESERVE: {
      '50': '#bd92de',
      '100': '#a66bd2',
      '200': '#9a57cc',
      '300': '#8e43c6',
      '400': '#8238b8',
      '500': '#662C91',
      '600': '#4a206a',
      '700': '#3d1a56',
      '800': '#2f1443',
      '900': '#13081c',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default YpfConfig;
