import { gql } from '@apollo/client';

export default gql`
  fragment ConnectorsListFields on Connector {
    id
    status
    power
    type
    station {
      id
      status
    }
    currentType
    currentSession {
      id
      emspId
      energyConsumed
      duration
      order {
        orderId
        totalCost
        currencyType
      }
      energyMeasurements {
        timestamp
        value
      }
    }
    errorCode
  }
`;
