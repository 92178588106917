import { gql } from '@apollo/client';

export default gql`
  query StationRFIDConfigurationKeys($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      rfidConfigurationKeys {
        key
        value
        readonly
        scopes
        description
        unit
        label
        type
      }
    }
  }
`;
