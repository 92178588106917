import Config from 'config';
import styled, { css } from 'styled-components';

const ActiveMenuItem = css`
  background: ${Config.COLORS.SIDEBAR};
  filter: brightness(1.15);
  border-color: #fff;
`;

export const MenuItemContainer = styled.div<{ active: boolean }>`
  align-items: center;
  height: 45px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  white-space: pre;
  border-left: 5px solid transparent;
  ${({ active }) => active && ActiveMenuItem};
`;

export const IconContainer = styled.div`
  vertical-align: middle;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  font-size: 18px;
  > * {
    max-height: 18px;
  }
`;
