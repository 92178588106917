import {
  ReservationSortInput,
  SessionFilterInput,
  SessionSortInput,
  SessionStatus,
  SortOrderInput,
  StationFilterInput,
  StationSortInput,
} from 'generated/graphql';
import { Filters, SortingRule } from 'react-table';
import { FormattedSession } from 'services/formatSessionRow';
import { FormattedStation } from 'services/formatStationRow';
import { FilterTerm } from 'types';
import { TranslationKeyType } from 'types/translation';

export const DEFAULT_SESSION_STATUS_FILTER: SessionStatus[] = [
  'CHARGING',
  'WAITING_UNPLUG',
  'FINISHED',
];

export const equals = <U extends string>(
  termA: FilterTerm<U>,
  termB: FilterTerm<U>
) => termA.value === termB.value && termA.type === termB.type;

export const validTerm = <U extends string>(
  term: FilterTerm<U>,
  state: FilterTerm<U>[]
): {
  error: boolean;
  errorMsg: TranslationKeyType;
} => {
  if (!term.value)
    return {
      error: true,
      errorMsg: 'filter.required-field-error',
    };

  if (state.some((x) => equals(x, term)))
    return {
      error: true,
      errorMsg: 'filter.already-defined-error',
    };

  return { error: false, errorMsg: 'filter.no-error-message' };
};

export const userIdMatcher = /[U,u]ser ([0-9]+)/;

export const parseUserId = (userId: string) =>
  parseInt(userId.match(userIdMatcher)?.[1] ?? userId);

export const transformToTableFilters = <U extends string, N extends object>(
  appliedFilters: FilterTerm<U>[]
): Filters<N> =>
  Object.values(
    appliedFilters.reduce((acc, { type, value }) => {
      acc[type] = acc[type] || { id: type, value: [] };
      acc[type].value.push(value);
      return acc;
    }, {} as any)
  );

export const transformToStationFilterInput = <
  T extends Partial<FormattedStation>
>(
  filters: Filters<T>
): StationFilterInput => ({
  addressCity: filters.find((filter) => filter.id === 'city')?.value,
  name: filters.find((filter) => filter.id === 'stationName')?.value,
  includesVisibility: ['PRIVATE', 'PUBLIC'],
});

export const transformToSessionFilterInput = <
  T extends Partial<FormattedSession>
>(
  filters: Filters<T>
): SessionFilterInput => ({
  includesStatus: ['CHARGING', 'WAITING_UNPLUG', 'FINISHED'],
  stationName: filters.find((filter) => filter.id === 'stationName')?.value,
  consumerId: filters
    .find((filter) => filter.id === 'driverId')
    ?.value.map(parseUserId)
    .filter((id: number) => !Number.isNaN(id)),
});

export const joinSearchWithFilter = <U extends string>(
  appliedFilters: FilterTerm<U>[]
): Filters<FilterTerm<U>> => transformToTableFilters(appliedFilters);

// TODO - Change from any to ??? (maybe the ids from tables)
export const transformToStationSortInput = (
  sortTypes: SortingRule<FormattedStation>[],
  timeRange: { from: string; to?: string }
): StationSortInput | undefined => {
  if (!sortTypes.length) return undefined;

  // For now, we are just using one sort by time, but table support multiples sorts.
  const sortType = sortTypes[0];
  const order = sortType.desc ? 'DESC' : 'ASC';
  const field = sortType.id;
  switch (field) {
    case 'stationName':
      return { name: order };
    case 'city':
      return { addressCity: order };
    case 'state':
      return { addressState: order };
    case 'usageRate':
      return { usageRate: { timeRange, order } };
    case 'revenue':
    case 'dailyGrossValue':
      return { sessionsSummary: { timeRange, revenue: order } };
    case 'stationConfigs':
    case 'count':
    case 'dailyCount':
      return {
        sessionsSummary: {
          timeRange,
          count: order,
        },
      };
    case 'energyConsumed':
      return {
        energyConsumed: {
          timeRange,
          order,
        },
      };

    default:
      return { [field]: order };
  }
};

export const transformToSessionSortInput = (
  sortTypes: SortingRule<any>[]
): SessionSortInput | undefined => {
  if (!sortTypes.length) return undefined;
  // For now, we are just using one sort by time, but table support multiples sorts.
  const sortType = sortTypes[0];
  const order = sortType.desc ? 'DESC' : 'ASC';
  const field = sortType.id;
  switch (field) {
    case 'stationCity':
      return { stationAddressCity: order };
    case 'stationState':
      return { stationAddressState: order };
    case 'date':
      return { createdAt: order };
    case 'begin':
      return { startedChargingAt: order };
    case 'end':
      return { finishedAt: order };
    default:
      return { [field]: order };
  }
};

export const transformToOrderSortInput = (
  sortTypes: SortingRule<any>[]
): SortOrderInput | undefined => {
  if (!sortTypes.length) return undefined;
  // For now, we are just using one sort by time, but table support multiples sorts.
  const sortType = sortTypes[0];
  const order = sortType.desc ? 'DESC' : 'ASC';
  const field = sortType.id;
  switch (field) {
    case 'createdAt':
      return { createdAt: order };
  }
};

export const transformToReservationSortInput = (
  sortTypes: SortingRule<any>[]
): ReservationSortInput | undefined => {
  if (!sortTypes.length) return undefined;
  // For now, we are just using one sort by time, but table support multiples sorts.
  const sortType = sortTypes[0];
  const order = sortType.desc ? 'DESC' : 'ASC';
  const field = sortType.id;
  switch (field) {
    case 'startTime':
      return { startTime: order };
    case 'finishedAt':
      return { finishedAt: order };
  }
};
