import { Typography } from 'components';
import Config from 'config';
import styled from 'styled-components';
import { NotNestedColors } from 'types';

export type ColorOptionsType = {
  success: string;
  danger: string;
  information: string;
  warning: string;
  primary: string;
  light: string;
  darkMode: string;
  charging: string;
  default: string;
  tertiary: string;
};

export const ColorOptions: ColorOptionsType = {
  success: `${Config.COLORS.STATION_STATUS.AVAILABLE}`,
  danger: `${Config.COLORS.STATION_STATUS.INOPERATIVE}`,
  warning: `${Config.COLORS.WARNING}`,
  information: `${Config.COLORS.STATION_STATUS.RESERVED}`,
  primary: `${Config.COLORS.PRIMARY}`,
  light: `${Config.COLORS.BACKGROUND}`,
  darkMode: `${Config.COLORS.PRIMARY_GRAY}`,
  charging: `${Config.COLORS.CONNECTOR_STATUS.CHARGING}`,
  default: `${Config.COLORS.SECONDARY_GRAY}`,
  tertiary: `${Config.COLORS.TERTIARY_GRAY}`,
};

type NewTagSizes = 'inline' | 'default' | 'big';

type TagStyleProps = {
  [key: string]: {
    height: string;
    width: string;
    borderRadius: string;
    padding: string;
    margin: string;
    fontSize: string;
    fontColor: keyof NotNestedColors;
    fontWeight: string;
  };
};

const TagStyles: TagStyleProps = {
  inline: {
    height: '6.4px',
    width: '6.4px',
    borderRadius: '100%',
    padding: '0px',
    margin: '0px 8px 0px 0px',
    fontSize: '14px',
    fontColor: 'SECONDARY_GRAY',
    fontWeight: 'regular',
  },
  default: {
    height: 'auto',
    width: 'auto',
    borderRadius: '50px',
    padding: '0px 6px 0px 6px',
    margin: '0px',
    fontSize: '12px',
    fontColor: 'WHITE',
    fontWeight: 'bold',
  },
  big: {
    height: 'auto',
    width: 'auto',
    borderRadius: '6px',
    padding: '6px 8px 6px 8px',
    margin: '0px 6px 0px 0px',
    fontSize: '14px',
    fontColor: 'WHITE',
    fontWeight: 'bold',
  },
};

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const TagContainer = styled.div<{
  color: string;
  size: Partial<NewTagSizes>;
}>`
  background: ${({ color }) => color};

  border-radius: ${({ size }) => TagStyles[size].borderRadius};
  padding: ${({ size }) => TagStyles[size].padding};
  margin: ${({ size }) => TagStyles[size].margin};

  width: ${({ size }) => TagStyles[size].width};
  height: ${({ size }) => TagStyles[size].height};

  display: flex;
  flex-direction: row;

  align-self: center;
`;

export const IconContainer = styled.div`
  margin-right: 4px;
  display: flex;
  align-self: center;
`;

export const Text = styled(Typography).attrs({
  align: 'left',
})<{ props: NewTagSizes }>`
  color: ${({ props, theme: { COLORS } }) =>
    COLORS[TagStyles[props].fontColor]};
  font-size: ${({ props }) => TagStyles[props].fontSize};
  font-weight: ${({ props }) => TagStyles[props].fontWeight};
  align-self: center;
`;
