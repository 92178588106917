import ErrorContainer from 'new-components/ErrorComponent';
import NewModal from 'new-components/NewModal';
import styled from 'styled-components';

export const Container = styled(NewModal)``;
export const ErrorComponent = styled(ErrorContainer)`
  top: calc(50% - 31px);
`;
export const Body = styled('section')`
  background-color: ${({
    theme: {
      COLORS: { BACKGROUND },
    },
  }) => BACKGROUND};
  max-height: 74vh;
  flex: 1;
`;
