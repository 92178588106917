import styled from 'styled-components';
import OriginalCardHeader from 'components/Card/CardHeader';

export const Container = styled.div`
  display: flex;
  width: 100%;

  & > :not(:first-child) {
    margin-left: 25px;
  }
`;

export const CardHeader = styled(OriginalCardHeader)`
  margin-top: -7px;
`;
