import React, { useState, useRef, useEffect } from 'react';
import Config from 'config';
import { Container, ArrowContainer, Arrow, TextContainer } from './styles';

const DriverMessage: React.FC = ({ children }) => {
  const [hasOverflow, setOverflow] = useState(false);
  const [isExpanded, expand] = useState(false);
  const textContainerRef = useRef<HTMLDivElement>(null);

  // this effect is responsible for showing if the message overflows or not(passes 3 lines)
  // it refreshes the state when the component loads and when the window resizes
  // but there's a caveat when the sidebar opens/closes, the state is not re-updated
  useEffect(() => {
    const updateOverflow = () => {
      const { offsetHeight = 0, scrollHeight = 0 } =
        textContainerRef?.current ?? {};
      setOverflow(offsetHeight < scrollHeight || isExpanded);
    };
    updateOverflow();
    window.addEventListener('resize', updateOverflow);
    return () => window.removeEventListener('resize', updateOverflow);
  }, [textContainerRef, setOverflow, isExpanded]);

  return (
    <Container
      hasOverflow={hasOverflow}
      onClick={() => expand((prev) => !prev && hasOverflow)}
    >
      <TextContainer isExpanded={isExpanded} ref={textContainerRef}>
        {children}
      </TextContainer>
      <ArrowContainer>
        {
          hasOverflow && (
            <Arrow
              color={Config.COLORS.SECONDARY_GRAY}
              up={`${isExpanded}` as 'true' | 'false'}
            />
          )
          /* "`${isExpanded}` as 'true' | 'false'" is written this way to avoid svg errors: https://github.com/styled-components/styled-components/issues/1198 */
        }
      </ArrowContainer>
    </Container>
  );
};

export default DriverMessage;
