import { gql } from '@apollo/client';

export default gql`
  query emspsRfidsSummary {
    emsps {
      organization {
        name
      }
      rfidsSummary {
        activeCount
        lockedCount
        preRegisteredCount
        totalCount
      }
    }
  }
`;
