import ConfigType from 'config/typing';
import { Icon } from 'new-components/Icon';
import React from 'react';
import { Container, Description, Title } from './styles';

type Props = {
  description: string;
  icon: keyof ConfigType['NEW_ICONS'];
  title: string;
  top?: number;
};

const BlankState: React.FC<Props> = ({
  description,
  icon,
  title,
  top = 32,
}) => (
  <Container top={top}>
    <Icon size={28} type={icon} color="SECONDARY_GRAY" />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export { BlankState };
