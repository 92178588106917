import { Box, BoxProps, Flex } from '@chakra-ui/react';

export const DinamicVerticalLine = ({ ...rest }: BoxProps) => (
  <Flex
    height="full"
    borderLeftColor="gray.100"
    borderLeftWidth="thin"
    align={'center'}
    position="absolute"
    top={0}
    {...rest}
  >
    <Box w="5px" h="1px" bg="gray.100" />
  </Flex>
);
