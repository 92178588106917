import { Typography } from 'components';
import {
  ConnectorStatus,
  RouterConnectivityStatus,
  StationStatus,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

type TagCommonTypes = 'ONLINE' | 'OFFLINE' | 'CRITICAL' | 'WARNING' | 'INFO';

export type TagAllTypes =
  | TagCommonTypes
  | ConnectorStatus
  | StationStatus
  | RouterConnectivityStatus;

export type TagProps = {
  type: TagAllTypes;
};

const Tag: React.FC<TagProps> = ({ type }) => {
  const { t } = useTranslation();
  const label = {
    [type]: type,
    AVAILABLE: t('tag-available-label'),
    PREPARING: t('tag-preparing-label'),
    CHARGING: t('tag-charging-label'),
    FINISHING: t('tag-finishing-label'),
    INOPERATIVE: t('tag-inoperative-label'),
    RESERVED: t('tag-reserved-label'),
    UNKNOWN: t('tag-unknown-label'),
    NOT_CONFIGURED: t('tag-not-configured-label'),
  }[type];

  const isTextColorBlack =
    label === 'INFO' || label === t('tag-not-configured-label');
  const convertWordToPascalCase = (string: string) =>
    string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();

  return (
    <Container type={type}>
      <Typography size={12} color={isTextColorBlack ? 'PRIMARY_GRAY' : 'WHITE'}>
        {convertWordToPascalCase(label)}
      </Typography>
    </Container>
  );
};

export default Tag;
