import {
  Auth as FirebaseAuth,
  SAMLAuthProvider as FirebaseSAMLAuthProvider,
  signInWithRedirect as SignInWithRedirectFn,
} from 'firebase/auth';
import SSOIdentityProvider from '.';

export default class YPFSSOIdentityProvider implements SSOIdentityProvider {
  readonly type: 'SSO_IdP' = 'SSO_IdP';

  constructor(
    private firebaseAuth: FirebaseAuth,
    private SAMLAuthProvider: FirebaseSAMLAuthProvider,
    private signInWithRedirect: typeof SignInWithRedirectFn
  ) {}

  async redirect(): Promise<void> {
    await this.signInWithRedirect(this.firebaseAuth, this.SAMLAuthProvider);
  }
}
