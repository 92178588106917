import { gql } from '@apollo/client';

export default gql`
  query groupStations($where: GroupWhereUniqueInput!) {
    group(where: $where) {
      id
      stationsProfile(filter: { includesVisibility: [PUBLIC, PRIVATE] }) {
        id
        name
      }
    }
  }
`;
