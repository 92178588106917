import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  & > :not(:first-child) {
    margin-left: 25px;
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 52px;
  margin-left: 20px;
`;
