import Modal from 'new-components/NewModal';
import { H3, H4 } from 'new-components/Typographies/styles';
import React, { ReactNode } from 'react';
import { Flex, Button, Text, Box, HStack } from '@chakra-ui/react';
import { Size } from 'new-components/NewModal/size';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Header, Body } from './styles';

type HeaderProps = {
  title: string;
  icon?: JSX.Element;
};

type SubmitButtonProps = {
  text: string;
  colorScheme: 'primary' | 'error';
  onPress: () => void;
  loading?: boolean;
  disabled?: boolean;
};

type CancelButtonProps = {
  text: string;
  onClick: () => void;
};

type Props = {
  header: HeaderProps;
  submitButton: SubmitButtonProps;
  subtitle?: string;
  children?: ReactNode;
  visible: boolean;
  description?: string;
  cancelButton?: CancelButtonProps;
  onClose: () => void;
  size?: Size;
};

const DialogModal: React.FC<Props> = ({
  children,
  subtitle,
  header,
  onClose,
  submitButton,
  visible,
  size = 'sm',
  cancelButton,
  description,
}) => {
  const { isMediaLargerThan800 } = useMediaQuery();
  return (
    <Modal
      size={size}
      show={visible}
      onClose={onClose}
      centered
      overflow="visible"
    >
      <Modal.Header>
        <Header>
          {header.icon && <Box>{header.icon}</Box>}
          {isMediaLargerThan800 ? (
            <H3>{header.title}</H3>
          ) : (
            <H4>{header.title}</H4>
          )}
        </Header>
        {isMediaLargerThan800 && (
          <Modal.Header.Attachments padding="24">
            <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
          </Modal.Header.Attachments>
        )}
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Flex flexFlow="column">
            <Box>
              <Text fontSize="sm">{subtitle}</Text>
              {description && (
                <Text fontSize="sm" mt={4}>
                  {description}
                </Text>
              )}
            </Box>
            {children && <Box mt={4}>{children}</Box>}
            <Flex alignSelf="flex-end" mt={8}>
              <HStack spacing={6}>
                {cancelButton && (
                  <Button
                    colorScheme="gray"
                    variant="link"
                    color="gray.900"
                    onClick={cancelButton.onClick}
                  >
                    {cancelButton.text}
                  </Button>
                )}
                <Button
                  colorScheme={submitButton.colorScheme}
                  onClick={submitButton.onPress}
                  isLoading={submitButton.loading}
                  disabled={submitButton.disabled}
                >
                  {submitButton.text}
                </Button>
              </HStack>
            </Flex>
          </Flex>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

export default DialogModal;
