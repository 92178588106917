import React, { useContext } from 'react';

import Filter from 'new-components/Filter';
import { NewTag } from 'new-components/NewTag';

import { useTranslation } from 'react-i18next';
import { StationLogsContext } from '../../StationLogsContext';
import { Container, FilterLink } from './styles';

interface StationAlarmFilterProps {
  activeCount?: number;
  resolvedCount?: number;
}

const StationAlarmFilter: React.FC<StationAlarmFilterProps> = ({
  activeCount,
  resolvedCount,
}) => {
  const { t } = useTranslation();
  const { filterResolvedAlarms, setAlarms, setAlarmResolvedFilter } =
    useContext(StationLogsContext);

  return (
    <Container>
      <Filter>
        <FilterLink
          id={0}
          active={!filterResolvedAlarms}
          onClick={() => {
            if (!filterResolvedAlarms) return;

            setAlarmResolvedFilter(false);
            setAlarms([]);
          }}
        >
          <>
            {t('station-alarm.filter.opened.title')}
            {!!activeCount && (
              <NewTag type="text" color="danger">
                {activeCount}
              </NewTag>
            )}
          </>
        </FilterLink>
        <FilterLink
          id={1}
          active={filterResolvedAlarms}
          onClick={() => {
            if (filterResolvedAlarms) return;

            setAlarmResolvedFilter(true);
            setAlarms([]);
          }}
        >
          <>
            {t('station-alarm.filter.resolved.title')}
            {!!resolvedCount && <NewTag type="text">{resolvedCount}</NewTag>}
          </>
        </FilterLink>
      </Filter>
    </Container>
  );
};

export { StationAlarmFilter };
