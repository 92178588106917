import { gql } from '@apollo/client';

export default gql`
  query AllStations(
    $timeRange: TimeRangeInput!
    $limit: Int
    $offset: Int
    $filter: StationFilterInput
    $search: String
    $sort: StationSortInput
    $sessionsFilter: SessionFilterInput
    $includeSessionSummary: Boolean!
  ) {
    stations(
      limit: $limit
      offset: $offset
      filter: $filter
      search: $search
      sort: $sort
    ) {
      id
      compressedId
      name
      cpoId
      usageRate(timeRange: $timeRange)
      address {
        city
        state
        postalCode
        streetNumber
      }
      sessionsSummary(timeRange: $timeRange, filter: $sessionsFilter)
        @include(if: $includeSessionSummary) {
        energyConsumed
        count
        timeCharging
      }
    }
    stationsSummary {
      count
    }
  }
`;
