import cep from 'cep-promise';

export type AddressResponse = {
  id: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  city: string;
  state: string;
  postalCode: string;
};

type Response = {
  address?: AddressResponse;
  errorMessage?: string;
};

const getAddress = async (inputCep: string | number): Promise<Response> => {
  try {
    const address = await cep(inputCep);
    return { address: { ...address, postalCode: address.cep } };
  } catch (error) {
    return {
      errorMessage:
        'Não identificamos este CEP. Por favor, revise e digite novamente',
    };
  }
};

export { getAddress };
