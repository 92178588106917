import { gql } from 'graphql-tag';

export default gql`
  query GetDriverRFIDS($where: DriverWhereUniqueInput!) {
    driver(where: $where) {
      id
      rfids {
        status
        decimalId
        consumerLabel
        profile {
          name
        }
      }
    }
  }
`;
