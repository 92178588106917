import { useToast } from '@chakra-ui/react';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import { AvailableVoucherActions } from 'pages/Payments/Voucher/function';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedVoucherData } from '../VoucherTable/service';
import { DropdownOption } from './DropdownOption';
import { useVoucherActionsDropdown } from './service';

export type VoucherDropdownProps = {
  selectedVoucher: SelectedVoucherData;
};

export function VoucherActionsDropdown({
  selectedVoucher,
}: VoucherDropdownProps) {
  const {
    openArchiveVoucherModal,
    toggleVoucherActionsDropdown,
    closeVoucherActionsDropdown,
    voucherActionsDropdownOpen,
    visibleOptionsPerVoucherStatus,
    openViewVoucherDetails,
  } = useVoucherActionsDropdown({ selectedVoucher });

  const { t } = useTranslation();
  const toast = useToast();

  type AvailableOptions = {
    [K in keyof typeof AvailableVoucherActions]: () => ReactElement;
  };

  const availableOptions: AvailableOptions = {
    VIEW_DETAILS: () =>
      DropdownOption({
        onClick: () => {
          openViewVoucherDetails();
        },
        option: t('payments.voucher.actions.view-details.title'),
      }),
    EDIT: () =>
      DropdownOption({
        onClick: () => {
          toast({
            title: `Option not implemented`,
            status: 'warning',
            isClosable: true,
          });
        },
        option: t('payments.voucher.actions.edit.title'),
      }),
    ACTIVE: () =>
      DropdownOption({
        onClick: () => {
          toast({
            title: t(
              'payments.voucher-tab.table.row.more-options-modal-active.success-message'
            ),
            status: 'info',
            isClosable: true,
          });
        },
        option: t('payments.voucher.actions.active.title'),
      }),
    DISABLE: () =>
      DropdownOption({
        onClick: () => {
          toast({
            title: `Option not implemented`,
            status: 'warning',
            isClosable: true,
          });
        },
        option: t('payments.voucher.actions.disable.title'),
      }),
    ARCHIVE: () =>
      DropdownOption({
        onClick: () => {
          openArchiveVoucherModal();
        },
        option: t('payments.voucher.actions.archive.title'),
      }),
  };

  const options = visibleOptionsPerVoucherStatus.map(
    (option) => availableOptions[option]
  );

  return (
    <Dropdown
      isOpen={voucherActionsDropdownOpen}
      toggleDropdown={() => ({})}
      position="left"
      align="start"
      onClickOutside={closeVoucherActionsDropdown}
      content={() => (
        <Dropdown.Container>
          {options.map((option) => option())}
        </Dropdown.Container>
      )}
    >
      <Dropdown.TriggerButtonContainer
        active={voucherActionsDropdownOpen}
        onClick={toggleVoucherActionsDropdown}
      >
        <Icon type="NEW_THREE_HORIZONTAL_DOTS" color="PRIMARY_GRAY" size={24} />
      </Dropdown.TriggerButtonContainer>
    </Dropdown>
  );
}
