export const VERTICAL_LINE_SPACING_TO_INCREASE = 6;

const INITIAL_ROW_PADDING_LEFT = 14;

export function getRowPaddingLeft(
  isFirstLevel: boolean,
  index?: number
): number {
  return index && isFirstLevel ? index * INITIAL_ROW_PADDING_LEFT : 0;
}
