/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import env from '@beam-australia/react-env';
import Card from 'components/Card';
import ConnectorInfo from 'components/ConnectorsList/ConnectorInfo';
import JSONInterface from 'components/JSONInterface';
import Table, { Column } from 'components/Table';
import Typography from 'components/Typography';
import Config from 'config';
import {
  ReleaseConnectorLockMutation,
  ReleaseConnectorLockMutationVariables,
  ResetStationMutation,
  ResetStationMutationVariables,
  StationConnectorsInfoQuery,
  StationConnectorsInfoQueryVariables,
  StationEventsQuery,
  StationEventsQueryVariables,
  StationOperationInfoQuery,
  StationOperationInfoQueryVariables,
  StopSessionMutation,
  StopSessionMutationVariables,
} from 'generated/graphql';
import releaseConnectorLockMutation from 'graphql/mutations/releaseConnectorLock';
import resetStationMutation from 'graphql/mutations/resetStation';
import stopSessionMutation from 'graphql/mutations/stopSession';
import stationConnectorsInfoQuery from 'graphql/queries/stationConnectorsInfo';
import stationEventsQuery from 'graphql/queries/stationEvents';
import stationOperationInfoQuery from 'graphql/queries/stationOperationInfo';
import { useAuthMutation, useAuthQuery } from 'hooks';
import moment from 'moment';
import getCookieFromKey from 'services/getCookieFromKey';
import { StationContext } from 'pages/StationPage/StationContext';
import React, { useContext, useState } from 'react';
import { Checkbox, Flex, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  ButtonsContainer,
  ConnectorInfoContainer,
  ConnectorTitle,
  Container,
  Content,
  OperationInfo,
  OperationInfoContainer,
  OperationSection,
  ResetButton,
  ResetButtonsContainer,
  StreamEventsContainer,
} from './styles';

type StreamEvent = NonNullable<StationEventsQuery['station']>['events'][number];
type FormattedEvent = Omit<StreamEvent, 'streamVersion'> & {
  streamVersion: string | number;
};

const DEFAULT_URL_SUFFIX = 'localhost:4000';

// TODO: lookup defaults
const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const googleCloudBaseURL =
  'https://console.cloud.google.com/logs/viewer?project=cool-encoder-243621&customFacets=jsonPayload.metadata.commandName&interval=NO_LIMIT';

const getGoogleCloudLogURL = (
  stationId: string,
  streamVersion?: string | number
) => {
  const filterParam = encodeURI(`resource.type="k8s_container"
resource.labels.namespace_name="api-20888520-${env('ENV') ?? 'staging'}"
resource.labels.container_name="station-write"
${streamVersion ? `jsonPayload.event.streamVersion=${streamVersion}` : ''}
"${stationId}"`);

  return `${googleCloudBaseURL}&advancedFilter=${filterParam}`;
};

const columns: Column<FormattedEvent>[] = [
  {
    Header: 'Versão',
    accessor: 'streamVersion',
    disableSortBy: true,
    Cell: ({ row: { original } }) => (
      <a
        href={getGoogleCloudLogURL(original.streamId, original.streamVersion)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {original.streamVersion}
      </a>
    ),
  },
  {
    Header: 'Tipo',
    accessor: 'type',
    disableSortBy: true,
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    disableSortBy: true,
    Cell: ({ row: { original } }) =>
      original.timestamp !== '...'
        ? moment(original.timestamp).format('DD/MM/YYYY - HH:mm:ss')
        : original.timestamp,
  },
  {
    Header: 'Conteúdo',
    accessor: 'data',
    disableSortBy: true,
    Cell: ({ row: { original } }) => (
      <JSONInterface data={original.data} mode="view" />
    ),
  },
];

const OwnerOperations: React.FC = () => {
  const { t } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = useState(defaultPagination);
  const { id: stationId } = useContext(StationContext);

  const queryOptions = {
    variables: {
      where: { stationId },
      limit: pageSize,
      offset: pageIndex * pageSize,
    },
    skip: !stationId,
  };

  const [areRepeatedEventsVisible, setRepeatedEventsVisibility] =
    useState(false);
  const [stopSession] = useAuthMutation<
    StopSessionMutation,
    StopSessionMutationVariables
  >(stopSessionMutation);
  const [resetStation] = useAuthMutation<
    ResetStationMutation,
    ResetStationMutationVariables
  >(resetStationMutation);
  const [releaseConnectorLock] = useAuthMutation<
    ReleaseConnectorLockMutation,
    ReleaseConnectorLockMutationVariables
  >(releaseConnectorLockMutation);
  const stationConnectorsInfo = useAuthQuery<
    StationConnectorsInfoQuery,
    StationConnectorsInfoQueryVariables
  >(stationConnectorsInfoQuery, queryOptions);
  const stationOperationInfo = useAuthQuery<
    StationOperationInfoQuery,
    StationOperationInfoQueryVariables
  >(stationOperationInfoQuery, queryOptions);
  const events = useAuthQuery<StationEventsQuery, StationEventsQueryVariables>(
    stationEventsQuery,
    queryOptions
  );

  const numberOfPages = Math.ceil(
    (events.data?.station?.eventsSummary.count ?? 0) / pageSize
  );

  function repeatingEventsRemoved(
    _: StreamEvent,
    index: number,
    events: StreamEvent[]
  ) {
    const eventTypeIn = (i: number) => events[i].type;
    if (index === 0 || index === events.length - 1) return true;
    return !(
      eventTypeIn(index) === eventTypeIn(index + 1) &&
      eventTypeIn(index) === eventTypeIn(index - 1)
    );
  }

  function insertEmptyLine(
    event: StreamEvent,
    index: number,
    events: StreamEvent[]
  ) {
    if (index === events.length - 1) return [event];
    const nextEvent = events[index + 1];
    return event.streamVersion - nextEvent.streamVersion > 1
      ? [
          event,
          {
            streamVersion: `${event.streamVersion} - ${nextEvent.streamVersion}`,
            type: '...',
            timestamp: '...',
            streamId: '...',
            data: '...',
          },
        ]
      : [event];
  }

  /* @ts-ignore FIXME: */
  const renderedEvents: FormattedEvent[] = (events.data?.station?.events ?? [])
    .filter(
      (x, i, list) =>
        areRepeatedEventsVisible || repeatingEventsRemoved(x, i, list)
    )
    .flatMap((x, i, list) =>
      areRepeatedEventsVisible ? [x] : insertEmptyLine(x, i, list)
    );

  const alertOnError = async (
    command: Promise<{ data?: any; errors?: any }>
  ) => {
    try {
      const { data, errors } = await command;
      if (errors) throw errors;
      if (data) alert(`Success: ${JSON.stringify(data)}`);
    } catch (error) {
      alert(JSON.stringify(error));
      console.error(error);
    }
  };

  const OperationValue: React.FC<{
    accessor: (s: StationOperationInfoQuery['station']) => any;
    label: string;
  }> = ({ label, accessor }) => (
    <OperationInfoContainer>
      <OperationInfo>{label}</OperationInfo>
      <Typography>
        {`${accessor(stationOperationInfo?.data?.station) ?? '-'}`}
      </Typography>
    </OperationInfoContainer>
  );

  const handleAccessConnectorPlatformButtonPress = ({
    platformType,
  }: {
    platformType: 'router' | 'config';
  }) => {
    const cpoId = stationOperationInfo.data?.station?.cpoId;
    if (!cpoId) return;
    const authToken = getCookieFromKey('authToken', document);
    window.open(
      `https://${stationId}.${platformType}.${
        env('URL_SUFFIX') ?? DEFAULT_URL_SUFFIX
      }/?authToken=${authToken}&orgId=${cpoId}`
    );
  };

  return (
    <>
      <Container>
        <Card
          loading={stationOperationInfo.loading}
          error={stationOperationInfo?.error || !stationOperationInfo.data}
          type="complex"
        >
          <OperationSection>
            <OperationValue
              accessor={(station) => station?.ocppVersion}
              label="Versão OCPP"
            />
            <OperationValue
              accessor={(station) => station?.compressedId}
              label="ID Comprimido"
            />
            <OperationValue
              accessor={(station) => station?.status}
              label="Status"
            />
            <OperationValue
              accessor={(station) => station?.errorCode}
              label="Código de Erro"
            />
            <OperationValue
              accessor={(station) => station?.qrCode}
              label="QRCode(número)"
            />
          </OperationSection>
          <OperationSection>
            <OperationValue
              accessor={(station) => station?.ocppUrl}
              label="URL OCPP"
            />
            <OperationValue
              accessor={(station) => station?.ocppPublicAccessAllowed}
              label="OCPP acessado publicamente?"
            />
            <OperationValue
              accessor={(station) => station?.chargePointId}
              label="ID OCPP"
            />
          </OperationSection>
          <OperationSection>
            <OperationValue
              accessor={(station) => station?.routerPortal?.url}
              label="URL - Painel do Modem"
            />
            <OperationValue
              accessor={(station) => station?.routerPortal?.user}
              label="Usuário - Painel do Modem"
            />
            <OperationValue
              accessor={(station) => station?.routerPortal?.password}
              label="Senha - Painel do Modem"
            />
            <ActionButton
              onClick={() =>
                handleAccessConnectorPlatformButtonPress({
                  platformType: 'router',
                })
              }
            >
              Acessar Modem
            </ActionButton>
          </OperationSection>
          <OperationSection>
            <OperationValue
              accessor={(station) => station?.configPortal?.url}
              label="URL - Painel de Config."
            />
            <OperationValue
              accessor={(station) => station?.configPortal?.user}
              label="Usuário - Painel de Config."
            />
            <OperationValue
              accessor={(station) => station?.configPortal?.password}
              label="Senha - Painel de Config."
            />
            <ActionButton
              onClick={() =>
                handleAccessConnectorPlatformButtonPress({
                  platformType: 'config',
                })
              }
            >
              Acessar Config.
            </ActionButton>
          </OperationSection>
        </Card>
      </Container>
      <Container>
        <Card
          loading={stationConnectorsInfo.loading}
          error={stationConnectorsInfo?.error || !stationConnectorsInfo.data}
          type="complex"
          wrap={true}
        >
          {stationConnectorsInfo.data?.station?.connectors.map(
            (connector, _) => (
              <Content key={connector.id}>
                <ConnectorTitle
                  backgroundColor={
                    Config.COLORS.CONNECTOR_STATUS[connector.status]
                  }
                >
                  {t(Config.LABELS.CONNECTOR_STATUS[connector.status])}
                </ConnectorTitle>
                <ConnectorInfoContainer>
                  <ConnectorInfo
                    connector={connector}
                    noContainer
                    connectorId={connector.id}
                    stationId={stationConnectorsInfo.data?.station?.id}
                  />
                </ConnectorInfoContainer>
                <ButtonsContainer>
                  <ActionButton
                    onClick={() =>
                      alertOnError(
                        stopSession({
                          variables: {
                            where: { stationId, connectorId: connector.id },
                          },
                        })
                      )
                    }
                  >
                    Parar recarga
                  </ActionButton>
                  <ActionButton
                    onClick={() =>
                      alertOnError(
                        releaseConnectorLock({
                          variables: {
                            where: { stationId, connectorId: connector.id },
                          },
                        })
                      )
                    }
                  >
                    Destravar
                  </ActionButton>
                </ButtonsContainer>
              </Content>
            )
          )}
          <ResetButtonsContainer>
            <ResetButton
              onClick={() =>
                alertOnError(
                  resetStation({
                    variables: { where: { stationId }, type: 'Soft' },
                  })
                )
              }
            >
              Resetar Estação Soft
            </ResetButton>
            <ResetButton
              onClick={() =>
                alertOnError(
                  resetStation({
                    variables: { where: { stationId }, type: 'Hard' },
                  })
                )
              }
            >
              Resetar Estação Hard
            </ResetButton>
          </ResetButtonsContainer>
        </Card>
      </Container>
      <Container>
        <Card
          loading={events.loading}
          error={events.error}
          type={'complex'}
          padded={20}
        >
          <StreamEventsContainer>
            <Flex alignItems="center" mb={2}>
              <HStack spacing={2}>
                <Typography>Mostrar todos eventos sem resumir</Typography>
                <Checkbox
                  isChecked={areRepeatedEventsVisible}
                  onChange={() =>
                    setRepeatedEventsVisibility(
                      (areRepeatedEventsVisible) => !areRepeatedEventsVisible
                    )
                  }
                />
                <a
                  href={getGoogleCloudLogURL(stationId)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography color="CLICKABLE">
                    Ver todos os logs da estação
                  </Typography>
                </a>
              </HStack>
            </Flex>
            <Table<FormattedEvent>
              paginated
              pageCount={numberOfPages}
              fetchData={setPagination}
              data={renderedEvents}
              columns={columns}
            />
          </StreamEventsContainer>
        </Card>
      </Container>
    </>
  );
};

export default OwnerOperations;
