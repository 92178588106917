import styled from 'styled-components';
import { Typography } from 'components';
import Config from 'config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Content = styled(Typography)`
  margin: 124px 44px 78px 70px;
  padding-right: 18px;
  overflow: auto;
`;

export const CloseButton = styled(Config.ICONS.X)`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 10px;
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
`;

export const Paragraph = styled.p``;
