import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const EasyChargeConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-10',
  CODE: 'emobility-solutions',
  LABEL: 'eMobility Solutions',
  COUNTRY: 'UY',
  LANGUAGE: 'es_UY',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#00FF57',
    CLICKABLE: '#04AF3E',
    ERROR: '#F87060',
    SIDEBAR: '#000000',
    LOGIN_LOGO_BACKGROUND: '#000000',

    RISE: '#00FF57',
    FALL: '#F87060',
    NEUTRAL: '#04C847',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#00FF57',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#00FF57',
    CHARGING: '#00AAFF',
    INOPERATIVE: '#000000',
    UNKNOWN: '#FF3333',
    PLANNED: '#F87060',
    RESERVED: '#B20A67',

    PREPARING: '#00AAFF',
    FINISHING: '#00AAFF',

    SELECTED_DATE_BOUND_BACKGROUND: '#04C847',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#04AF3E',

    DATE_PRE_SELECTED_BACKGROUND: '#00FF57',
    DATE_PRE_SELECTED_BORDER: '#35FD79',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#4DFE8A',
    DATE_PRE_SELECTED_COLOR: '#04AF3E',

    DATE_SELECTED_BACKGROUND: '#00FF57',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#04C847',

    CURRENT_DAY: '#00FF57',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#00FF57',
      Subsidized: '#00FF57',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#00FF57',
      CHARGING: '#00AAFF',
      INOPERATIVE: '#000000',
      UNKNOWN: '#FF3333',
      PLANNED: '#F87060',
      RESERVED: '#B20A67',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#00FF57',
      PREPARING: '#00AAFF',
      CHARGING: '#00AAFF',
      FINISHING: '#00AAFF',
      INOPERATIVE: '#000000',
      UNKNOWN: '#FF3333',
      RESERVED: '#B20A67',
    },

    BILLING: '#00FF57',
    RECHARGES: '#00AAFF',
    AVAILABILITY: '#B20A67',

    BUTTON_AVAILABLE: '#00FF57',
    BUTTON_UNAVAILABLE: '#4DFE8A',
    BUTTON_LOADING: '#35FD79',
    BUTTON_TEXT_COLOR: '#000000',
    LINK: '#00FF57',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#04C847',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#04AF3E',

      DATE_PRE_SELECTED_BACKGROUND: '#00FF57',
      DATE_PRE_SELECTED_BORDER: '#35FD79',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#4DFE8A',
      DATE_PRE_SELECTED_COLOR: '#04AF3E',

      DATE_SELECTED_BACKGROUND: '#00FF57',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#04C847',

      CURRENT_DAY: '#00FF57',
    },
  },
  FONTS: {
    PRIMARY: 'Aeonik-Regular',
    SECONDARY: 'Aeonik-Bold',
    TERTIARY: 'Aeonik-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -34.88087348836612,
      lng: -56.179533485213355,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'EasyCharge - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/easycharge',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#66FF9A',
      '100': '#4DFE8A',
      '200': '#35FD79',
      '300': '#1CFD69',
      '400': '#03FC58',
      '500': '#00FF57',
      '600': '#04C847',
      '700': '#04AF3E',
      '800': '#039635',
      '900': '#037C2D',
    },
    LINK: {
      '50': '#66FF9A',
      '100': '#4DFE8A',
      '200': '#35FD79',
      '300': '#1CFD69',
      '400': '#03FC58',
      '500': '#00FF57',
      '600': '#04C847',
      '700': '#04AF3E',
      '800': '#039635',
      '900': '#037C2D',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#66FF9A',
      '100': '#4DFE8A',
      '200': '#35FD79',
      '300': '#1CFD69',
      '400': '#03FC58',
      '500': '#00FF57',
      '600': '#04C847',
      '700': '#04AF3E',
      '800': '#039635',
      '900': '#037C2D',
    },
    CHARGING: {
      '50': '#66CCFF',
      '100': '#4DC3FE',
      '200': '#35BAFD',
      '300': '#1CB2FD',
      '400': '#03A9FC',
      '500': '#00AAFF',
      '600': '#0487C8',
      '700': '#0476AF',
      '800': '#036596',
      '900': '#03547C',
    },
    AVAILABLE: {
      '50': '#66FF9A',
      '100': '#4DFE8A',
      '200': '#35FD79',
      '300': '#1CFD69',
      '400': '#03FC58',
      '500': '#00FF57',
      '600': '#04C847',
      '700': '#04AF3E',
      '800': '#039635',
      '900': '#037C2D',
    },
    SECONDARY: {
      '50': '#5C5656',
      '100': '#4F4A4A',
      '200': '#413E3E',
      '300': '#343232',
      '400': '#272626',
      '500': '#000000',
      '600': '#000000',
      '700': '#000000',
      '800': '#000000',
      '900': '#000000',
    },
    RESERVE: {
      '50': '#F42E9C',
      '100': '#F21690',
      '200': '#E20D83',
      '300': '#C90C75',
      '400': '#B10B67',
      '500': '#B20A67',
      '600': '#980A59',
      '700': '#80094B',
      '800': '#68083D',
      '900': '#380421',
    },
    INOPERATIVE: {
      '50': '#5C5656',
      '100': '#4F4A4A',
      '200': '#413E3E',
      '300': '#343232',
      '400': '#272626',
      '500': '#000000',
      '600': '#000000',
      '700': '#000000',
      '800': '#000000',
      '900': '#000000',
    },
  },
};
export default EasyChargeConfig;
