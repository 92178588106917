import { Typography } from 'components';
import Graph, { GraphColor } from 'components/Graph';
import moment from 'moment';
import {
  formatCurrency,
  formatCurrencyType,
  formatUsageRate,
} from 'services/format';
import { TimeRange } from 'services/timeRange';
import styled from 'styled-components';
import { CurrencyType } from 'types';

type Option = keyof typeof options;
type ValueKey = typeof options[Option]['key'];
type Value = { [K in ValueKey]: any[] };
type Showing = 'connector' | 'station';
interface Props<T extends Value> {
  showing: Showing;
  data: {
    value: T;
    option: Option;
  };
  unitKey?: string;
  timeRange: TimeRange;
  height: number;
  width: number;
  color?: GraphColor;
}

type Data = {
  x: string;
  y: number;
};

const options = {
  recharge: {
    key: 'sessionsSummaryPerDateTime',
    mapKey: 'sessionsSummary.count',
    axisProps: {
      formatText: (y: number, _showUnit = false, _unit?: string) => `${y}`,
      color: 'RECHARGES',
      unit: '',
      formatUnit: (unit: string) => unit,
    },
  },
  usageRate: {
    key: 'usageRatePerDateTime',
    mapKey: 'usageRate',
    axisProps: {
      formatText: (y: number, showUnit = false, _unit?: string) =>
        formatUsageRate(y, {
          showUnit,
          digits: 2,
        }),
      color: 'AVAILABILITY',
      unit: '%',
      formatUnit: (unit: string) => unit,
      domain: [
        (dataMin: number) => Math.min(dataMin, 0.96),
        (dataMax: number) =>
          dataMax === 0 ? 0.04 : Math.min(1, 1.25 * dataMax),
      ],
    },
  },
  revenue: {
    key: 'sessionsSummaryPerDateTime',
    mapKey: 'sessionsSummary.grossValue',
    axisProps: {
      formatText: (y: number, showUnit = false, unit?: string) =>
        formatCurrency(y, {
          showUnit,
          digits: 0,
          currencyType: unit as CurrencyType,
        }),
      color: 'BILLING',
      formatUnit: (unit: string) =>
        formatCurrencyType({ currencyType: unit as CurrencyType }),
      unit: undefined,
    },
  },
} as const;

const propertyAccessor =
  <T extends Value>(obj: T) =>
  (key: string) =>
    key.split('.').reduce((acc: any, prop) => acc[prop], obj);

const getData = <T extends Value>({ value, option }: Props<T>['data']) =>
  value[options[option].key]?.map<Data>((range: any) => ({
    x: moment(range.from).toISOString(),
    y: propertyAccessor(range)(options[option].mapKey),
  })) ?? [];

const GraphContainer = styled.div<{
  showing: Showing;
  height: number;
  width: number;
}>`
  height: ${({ height }) => height}%;
  width: calc(${({ width }) => width}% - 40px);
  margin-left: 20px;
  margin-top: ${({ showing }) => (showing === 'connector' ? -30 : 0)}px;
`;

const PerDateTimeGraph = <T extends Value>({
  data,
  unitKey,
  height,
  width,
  showing,
  timeRange,
}: Props<T>) => (
  <GraphContainer showing={showing} height={height} width={width}>
    <Graph<Data>
      data={getData(data)}
      x={{ dataKey: 'x', formatText: timeRange.format }}
      yRight={{
        dataKey: 'y',
        label: { position: 'top', dy: 12, dx: -50 },
        ticks: { transform: 'translate(0, 7)' },
        ...options[data.option].axisProps,
        unit: unitKey ?? options[data.option].axisProps.unit,
      }}
      tooltipContent={({ payload }) => (
        <>
          <Typography>
            {timeRange.format(moment(payload.x))} :{' '}
            <Typography weight="bold">
              {options[data.option].axisProps.formatText(
                payload.y as number,
                true,
                unitKey
              )}
            </Typography>
          </Typography>
        </>
      )}
    />
  </GraphContainer>
);

export default PerDateTimeGraph;
