import { CreateVoucherEventsContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/events-context';
import { useContext, useEffect, useState } from 'react';

export const useCreateVoucherRulesDiscountSelection = () => {
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const [discount, setDiscount] = useState<number | null>(null);

  useEffect(() => {
    events.updateVoucherDiscount(discount);
    // eslint-disable-next-line
  }, [discount]);

  return {
    discount,
    setDiscount,
  };
};
