import { Box, BoxProps, chakra, Flex, FlexProps } from '@chakra-ui/react';

export const Container = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    textAlign: 'center',
  } as BoxProps,
});

export const HeaderContainer = chakra(Flex, {
  baseStyle: {
    textAlign: 'center',
    flexDirection: 'column',
  } as FlexProps,
});

export const InputContainer = chakra(Box, {
  baseStyle: {
    width: 'full',
    marginTop: 10,
    textAlign: 'center',
    flexDirection: 'column',
  } as BoxProps,
});
