import { InjectChildrenProps, InjectDivProps } from '.';
import { Section } from './styles';

type SectionProps = InjectChildrenProps & InjectDivProps;

const DropdownSection = ({ children, ...props }: SectionProps) => (
  <Section {...props}>{children}</Section>
);

export { DropdownSection };
