import { gql } from '@apollo/client';

export default gql`
  query GenerateOrdersReport($timeRange: TimeRangeInput) {
    generateOrdersReport(
      format: XLSX
      sort: { createdAt: DESC }
      timeRange: $timeRange
    ) {
      url
    }
  }
`;
