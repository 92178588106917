import React from 'react';
import LoadingComponent from 'new-components/LoadingComponent';
import { BodyTypography } from 'new-components/Typographies/styles';
import { VehicleModalQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ErrorComponent,
  InfoGroup,
  InfoLabel,
  InfoValue,
  LoadingContainer,
} from './styles';

export type Props = {
  error: boolean;
  loading: boolean;
  vehicle?: NonNullable<VehicleModalQuery['vehicle']>;
};

const General: React.FC<Props> = ({ error, loading, vehicle }) => {
  const { t } = useTranslation();
  if (loading)
    return (
      <LoadingContainer>
        <LoadingComponent size={51} />
      </LoadingContainer>
    );
  if (error)
    return <ErrorComponent>{t('gerenic-error.message')}</ErrorComponent>;

  return (
    <Container>
      <InfoGroup>
        <InfoLabel>{t('vehicle-modal.general-tab.plate.title')}</InfoLabel>
        <InfoValue>
          <BodyTypography>{vehicle?.plate || '-'}</BodyTypography>
        </InfoValue>
      </InfoGroup>
      <InfoGroup>
        <InfoLabel>
          {t('vehicle-modal.general-tab.model-and-brand.title')}
        </InfoLabel>
        <InfoValue>
          <BodyTypography>{vehicle?.carModel.label || '-'}</BodyTypography>
        </InfoValue>
      </InfoGroup>
      <InfoGroup>
        <InfoLabel>{t('vehicle-modal.general-tab.rfids')}</InfoLabel>
        <InfoValue>
          {vehicle?.chargingCards.map((chargingCard) => (
            <BodyTypography>{chargingCard.hexId}</BodyTypography>
          ))}
        </InfoValue>
      </InfoGroup>
    </Container>
  );
};
export default General;
