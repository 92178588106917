import styled from 'styled-components';

import { FilterLink as filterLink } from 'new-components/Filter/FilterLink';

export const Container = styled.div`
  padding: 16px;
`;

export const FilterLink = styled(filterLink)`
  gap: 8px;
`;
