import { Box } from '@chakra-ui/react';
import AuthContext, { Organization } from 'contexts/Auth/context';
import React, { useCallback, useContext, useState } from 'react';
import { checkIfMemberBelongsToParentOrganization } from 'services/multiorg';
import { OrganizationSectionContent } from '../styles';

import {
  calculateSelectedOrganizations,
  getParentOrganizationIds,
} from '../functions';

import { OrganizationDropdownRow } from './organizationDropdownRow';
import { ApplyDropdownFilter } from './applyFilterComponent';

type Props = {
  organizations: Organization[] | undefined;
};

export type OrganizationIdsType = string[] | undefined;

const OrganizationsSection = ({ organizations }: Props) => {
  const { setSelectedOrganizationIds, selectedOrganizationIds } =
    useContext(AuthContext);

  const [expandedOrganizationsIds, setExpandedOrganizationsIds] =
    useState<OrganizationIdsType>(undefined);

  const [preSelectedOrganizationIds, setPreSelectedOrganizationIds] =
    useState<OrganizationIdsType>(selectedOrganizationIds);

  const getSuborganizations = (orgId?: string) =>
    organizations
      ?.filter((org) => org.parentOrganizationId === orgId)
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const checkIfHasSuborganization = (orgId?: string) =>
    organizations?.some((org) => org.parentOrganizationId === orgId) ?? false;

  const handleChangeAllPreSelectOrganizations = (
    hasAllSuborganizationSelected: boolean,
    allParentOrganizationIds: string[]
  ) => {
    if (hasAllSuborganizationSelected) {
      setPreSelectedOrganizationIds((state) =>
        state?.filter((s) => !allParentOrganizationIds.includes(s))
      );
    } else {
      setPreSelectedOrganizationIds((state) =>
        state?.length
          ? [...state, ...allParentOrganizationIds]
          : allParentOrganizationIds
      );
    }
  };

  const handleChangePreSelectedOrganizations = (orgId: string) => {
    if (preSelectedOrganizationIds?.includes(orgId)) {
      setPreSelectedOrganizationIds((state) =>
        state?.filter((o) => o !== orgId)
      );
    } else {
      setPreSelectedOrganizationIds((state) =>
        state?.length ? [...state, orgId] : [orgId]
      );
    }
  };

  const handleChangeExpandedOrganizationIds = (orgId: string) => {
    if (expandedOrganizationsIds?.includes(orgId)) {
      setExpandedOrganizationsIds((state) => state?.filter((o) => o !== orgId));
    } else {
      setExpandedOrganizationsIds((state) =>
        state?.length ? [...state, orgId] : [orgId]
      );
    }
  };

  const getAverageOfSelectedOrganizations = useCallback(
    (organization: string[]) => {
      const { numberOfOrganizationsSelected, totalNumberOfOrganizations } =
        calculateSelectedOrganizations(
          organization,
          preSelectedOrganizationIds
        );

      return {
        number: numberOfOrganizationsSelected,
        total: totalNumberOfOrganizations,
      };
    },
    [preSelectedOrganizationIds]
  );

  const filteredLevelOrgs = organizations?.filter((firstLevelOrg) => {
    const isMemberBelongsToParentOrganization =
      checkIfMemberBelongsToParentOrganization(
        organizations,
        firstLevelOrg.parentOrganizationId
      );

    return (
      !firstLevelOrg.parentOrganizationId ||
      !isMemberBelongsToParentOrganization
    );
  });

  const sortedFilteredLevelOrgs = () =>
    filteredLevelOrgs?.sort((a, b) => a.name.localeCompare(b.name));

  const checkIfAllSuborganizationsIsSelected = (suborganizations: string[]) =>
    suborganizations.every((o) => preSelectedOrganizationIds?.includes(o));

  const getAllParentOrganizationIds = (organizationId: string) =>
    getParentOrganizationIds(organizations ?? [], organizationId);

  const isExpanded = (subOrgLevel: Organization) =>
    expandedOrganizationsIds?.includes(subOrgLevel.id) ?? false;

  function buildOrgDropRows(currentOrg: Organization, currentLevel: string) {
    const currentPaddingLeft: Record<string, number> = {
      none: 0,
      first: 18,
      second: 36,
      third: 54,
      fourth: 72,
      fifth: 90,
    };

    const allParentOrganizationIds = getAllParentOrganizationIds(currentOrg.id);

    const { number, total } = getAverageOfSelectedOrganizations(
      allParentOrganizationIds
    );

    const hasAllSelected = checkIfAllSuborganizationsIsSelected(
      allParentOrganizationIds
    );

    return (
      <OrganizationDropdownRow
        paddingLeft={currentPaddingLeft[currentLevel]}
        isExpanded={isExpanded(currentOrg)}
        hasAllSuborganizationSelected={hasAllSelected}
        onClickToExpandSuborganizations={() =>
          handleChangeExpandedOrganizationIds(currentOrg.id)
        }
        onClickToPreSelectOrganization={() =>
          handleChangePreSelectedOrganizations(currentOrg.id)
        }
        onChangeAllPreSelectAllSuborganizations={() =>
          handleChangeAllPreSelectOrganizations(
            hasAllSelected,
            allParentOrganizationIds
          )
        }
        organization={currentOrg}
        preSelectedOrganizationIds={preSelectedOrganizationIds}
        hasSuborganization={checkIfHasSuborganization(currentOrg.id)}
        tagInfo={{
          numberOfOrganizationsSelected: number,
          totalNumberOfOrganizations: total,
        }}
        isFirstLevel={currentLevel === 'none'}
      />
    );
  }

  function checkIfRowIsExpanded(organizations: Organization[]) {
    const result = [];
    for (let i = 0; i < organizations.length; i++) {
      if (isExpanded(organizations[i])) {
        result.push(1);
      }
    }
    return result.length === organizations.length;
  }

  return (
    <React.Fragment>
      <OrganizationSectionContent numberOfRows={organizations?.length}>
        <React.Fragment>
          {sortedFilteredLevelOrgs()?.map((organization) => (
            <Box key={organization.id}>
              {organization && buildOrgDropRows(organization, 'none')}

              {getSuborganizations(organization?.id)?.map(
                (firstLevelSuborg) => (
                  <Box key={firstLevelSuborg.id}>
                    {isExpanded(organization) &&
                      buildOrgDropRows(firstLevelSuborg, 'first')}

                    {getSuborganizations(firstLevelSuborg.id)?.map(
                      (secondLevelSuborg) => (
                        <Box key={secondLevelSuborg.id}>
                          {checkIfRowIsExpanded([
                            organization,
                            firstLevelSuborg,
                          ]) && buildOrgDropRows(secondLevelSuborg, 'second')}

                          {getSuborganizations(secondLevelSuborg?.id)?.map(
                            (thirdLevelSuborg) =>
                              checkIfRowIsExpanded([
                                organization,
                                firstLevelSuborg,
                                secondLevelSuborg,
                              ]) && (
                                <React.Fragment>
                                  {buildOrgDropRows(thirdLevelSuborg, 'third')}

                                  {getSuborganizations(
                                    thirdLevelSuborg.id
                                  )?.map(
                                    (fourthLevelSuborg) =>
                                      checkIfRowIsExpanded([
                                        organization,
                                        firstLevelSuborg,
                                        secondLevelSuborg,
                                        thirdLevelSuborg,
                                      ]) && (
                                        <React.Fragment>
                                          {buildOrgDropRows(
                                            fourthLevelSuborg,
                                            'fourth'
                                          )}
                                          {getSuborganizations(
                                            fourthLevelSuborg.id
                                          )?.map(
                                            (fifthLevelSuborg) =>
                                              checkIfRowIsExpanded([
                                                organization,
                                                firstLevelSuborg,
                                                secondLevelSuborg,
                                                thirdLevelSuborg,
                                                fourthLevelSuborg,
                                              ]) &&
                                              buildOrgDropRows(
                                                fifthLevelSuborg,
                                                'fifth'
                                              )
                                          )}
                                        </React.Fragment>
                                      )
                                  )}
                                </React.Fragment>
                              )
                          )}
                        </Box>
                      )
                    )}
                  </Box>
                )
              )}
            </Box>
          ))}
        </React.Fragment>
      </OrganizationSectionContent>
      {ApplyDropdownFilter({
        preSelectedOrganizationIds,
        selectedOrganizationIds,
        setSelectedOrganizationIds,
      })}
    </React.Fragment>
  );
};
export default OrganizationsSection;
