import { useState } from 'react';
import { SelectedVoucherData } from '../ListVouchersTab/containers/VoucherTable/service';
import { ListVoucherProvider } from '../ListVouchersTab/context';
import { VoucherDetails } from '../VoucherDetailsModal/services';
import { VoucherClientProvider } from './client-context';
import { VoucherEventsContext } from './events-context';
import { VoucherStatesContext } from './states-context';

export const VoucherProvider: React.FC = ({ children }) => {
  const [currentSelectedVoucher, updateCurrentSelectedVoucher] =
    useState<SelectedVoucherData | null>(null);

  const [voucherDetails, updateVoucherDetails] =
    useState<VoucherDetails | null>(null);
  const [voucherDetailsLoading, updateVoucherDetailsLoading] = useState(false);
  const [voucherDetailsModalVisible, updateVoucherDetailsModalVisible] =
    useState(false);

  const [listVouchersLoading, updateListVouchersLoading] = useState(false);
  const [archiveVoucherModalVisible, updateArchiveVoucherModalVisible] =
    useState(false);

  return (
    <VoucherEventsContext.Provider
      value={{
        getEvents: () => ({
          updateCurrentSelectedVoucher,
          currentSelectedVoucher,

          updateVoucherDetails,
          voucherDetails,
          updateVoucherDetailsLoading,
          voucherDetailsLoading,
          updateVoucherDetailsModalVisible,
          openVoucherDetailsModal: () => updateVoucherDetailsModalVisible(true),
          voucherDetailsModalVisible,

          updateListVouchersLoading,
          listVouchersLoading: false,
          updateArchiveVoucherModalVisible,
          openArchiveVoucherModal: () => updateArchiveVoucherModalVisible(true),
          archiveVoucherModalVisible,
        }),
      }}
    >
      <VoucherStatesContext.Provider
        value={{
          voucherDetails,
          voucherDetailsLoading,
          listVouchersLoading,
          archiveVoucherModalVisible,
          voucherDetailsModalVisible,
        }}
      >
        <ListVoucherProvider>
          <VoucherClientProvider>{children}</VoucherClientProvider>
        </ListVoucherProvider>
      </VoucherStatesContext.Provider>
    </VoucherEventsContext.Provider>
  );
};
