import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Pagination, { linesPerPageOptionsType } from 'components/Pagination';
import Search from 'atomic-components/molecules/Search';
import {
  EmspsRfidsListQuery,
  EmspsRfidsListQueryVariables,
  EmspsRfidsSummaryQuery,
  EmspsRfidsSummaryQueryVariables,
} from 'generated/graphql';
import emspsRfidsList from 'graphql/queries/emspsRfidsList';
import emspsRfidsSummary from 'graphql/queries/emspsRfidsSummary';
import { useAuthQuery } from 'hooks';
import { Icon } from 'new-components/Icon';
import { useState, useContext } from 'react';
import AuthContext from 'contexts/Auth/context';
import CreateRFIDModal from 'atomic-components/organisms/modals/CreateRFID';
import { SkeletonsTable } from 'atomic-components/atoms/SkeletonsTable';
import useHasScopes from 'hooks/useHasScope';
import RFIDStatusTag from 'atomic-components/atoms/StatusTag';
import { useTranslation } from 'react-i18next';
import { formatOrgId } from 'services/format';
import IndicatorCard from 'components/IndicatorCard';
import { compareOrgs, SummaryArray } from 'services/multiorg';
import { parseSearchValue, vehicleIdMatcher, userIdMatcher } from './functions';
import { RFIDDropdown } from '../RFIDDropdown';

const DEFAULT_LINES_PER_PAGE_OPTION = 25;

const createOrderedArraysFromOrgSummaries = (
  summaries: EmspsRfidsSummaryQuery['emsps']
) => {
  const OrgArrayPerSummaryValue = summaries
    .map(
      (
        emsp
      ): {
        [key in
          | 'activeCount'
          | 'lockedCount'
          | 'preRegisteredCount'
          | 'totalCount']: SummaryArray;
      } => ({
        activeCount: [
          {
            name: emsp?.organization.name ?? '',
            value: emsp?.rfidsSummary.activeCount ?? 0,
          },
        ],
        lockedCount: [
          {
            name: emsp?.organization.name ?? '',
            value: emsp?.rfidsSummary.lockedCount ?? 0,
          },
        ],
        preRegisteredCount: [
          {
            name: emsp?.organization.name ?? '',
            value: emsp?.rfidsSummary.preRegisteredCount ?? 0,
          },
        ],
        totalCount: [
          {
            name: emsp?.organization.name ?? '',
            value: emsp?.rfidsSummary.totalCount ?? 0,
          },
        ],
      })
    )
    .reduce(
      (valuesA, valuesB) => ({
        activeCount: valuesA.activeCount.concat(valuesB.activeCount),
        lockedCount: valuesA.lockedCount.concat(valuesB.lockedCount),
        preRegisteredCount: valuesA.preRegisteredCount.concat(
          valuesB.preRegisteredCount
        ),
        totalCount: valuesA.totalCount.concat(valuesB.totalCount),
      }),
      {
        activeCount: [],
        lockedCount: [],
        preRegisteredCount: [],
        totalCount: [],
      }
    );

  type OrgArrayKey = keyof typeof OrgArrayPerSummaryValue;
  Object.keys(OrgArrayPerSummaryValue).forEach((key) => {
    OrgArrayPerSummaryValue[key as OrgArrayKey] =
      OrgArrayPerSummaryValue[key as OrgArrayKey].sort(compareOrgs);
  });
  return OrgArrayPerSummaryValue;
};

const RFIDMaganagement = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLinesPerPageOption, setSelectedLinesPerPageOption] =
    useState<linesPerPageOptionsType>(DEFAULT_LINES_PER_PAGE_OPTION);
  const [searchValue, setSearchValue] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(25);
  const hasScopes = useHasScopes();

  // The dash refers to a last column of editing status or deleting an rfid
  const columns = hasScopes(['rfid:create'])
    ? [
        'ID RFID',
        'Organização',
        'Consumidor',
        'Data de ativação',
        'Status',
        '_',
      ]
    : ['ID RFID', 'Organização', 'Consumidor', 'Data de ativação', 'Status'];

  const {
    data: summaryData,
    refetch: refetchSummary,
    loading: loadingSummary,
    error,
  } = useAuthQuery<EmspsRfidsSummaryQuery, EmspsRfidsSummaryQueryVariables>(
    emspsRfidsSummary
  );
  const {
    data: listData,
    loading: loadingList,
    refetch: refetchList,
  } = useAuthQuery<EmspsRfidsListQuery, EmspsRfidsListQueryVariables>(
    emspsRfidsList,
    {
      variables: {
        pagination: {
          limit: pageCount,
          offset: (pageIndex - 1) * pageCount,
        },
        search: parseSearchValue(searchValue, userIdMatcher, vehicleIdMatcher),
      },
    }
  );

  const rfidsSummary = summaryData?.emsps
    .map((emsp) => emsp?.rfidsSummary)
    .reduce(
      (sumA, sumB) => ({
        activeCount: (sumA?.activeCount || 0) + (sumB?.activeCount || 0),
        lockedCount: (sumA?.lockedCount || 0) + (sumB?.lockedCount || 0),
        preRegisteredCount:
          (sumA?.preRegisteredCount || 0) + (sumB?.preRegisteredCount || 0),
        totalCount: (sumA?.totalCount || 0) + (sumB?.totalCount || 0),
      }),
      { activeCount: 0, lockedCount: 0, preRegisteredCount: 0, totalCount: 0 }
    );

  const orderedRfidSummaryValuesPerOrg = summaryData
    ? createOrderedArraysFromOrgSummaries(summaryData.emsps)
    : undefined;
  const rfids = listData?.rfids;

  const emspHasSomeRfidRegistered =
    !rfidsSummary?.totalCount && !loadingSummary;
  const rfidDataIsLoading = loadingList || loadingSummary;

  const refetchRfidsInfo = async () => {
    await refetchSummary();
    await refetchList();
  };
  const { orgNameMap } = useContext(AuthContext);
  return (
    <Flex direction="column">
      <Flex
        justify="space-between"
        width="100%"
        bg="gray.50"
        pb="6"
        pt="8"
        zIndex="1"
      >
        <Flex
          width={'full'}
          sx={{ '& > :not(:first-child)': { 'margin-left': '25px' } }}
        >
          <IndicatorCard
            borderColor="CHARGING"
            thisPeriodTotalValue={rfidsSummary ? rfidsSummary.totalCount : 0}
            loading={loadingSummary}
            error={error}
            indicatorText="rfid.total-summary-description"
            detailsText="card.details"
            orderedOrgArray={
              orderedRfidSummaryValuesPerOrg
                ? orderedRfidSummaryValuesPerOrg.totalCount
                : []
            }
          />
          <IndicatorCard
            borderColor="AVAILABLE"
            thisPeriodTotalValue={rfidsSummary ? rfidsSummary.activeCount : 0}
            loading={loadingSummary}
            error={error}
            indicatorText="rfid.active-status-summary-description"
            detailsText="card.details"
            orderedOrgArray={
              orderedRfidSummaryValuesPerOrg
                ? orderedRfidSummaryValuesPerOrg.activeCount
                : []
            }
          />
          <IndicatorCard
            borderColor="SECONDARY_GRAY"
            thisPeriodTotalValue={
              rfidsSummary ? rfidsSummary.preRegisteredCount : 0
            }
            loading={loadingSummary}
            error={error}
            indicatorText="rfid.pre-registered-status-summary-description"
            detailsText="card.details"
            orderedOrgArray={
              orderedRfidSummaryValuesPerOrg
                ? orderedRfidSummaryValuesPerOrg.preRegisteredCount
                : []
            }
          />
          <IndicatorCard
            borderColor="ERROR"
            thisPeriodTotalValue={rfidsSummary ? rfidsSummary.lockedCount : 0}
            loading={loadingSummary}
            error={error}
            indicatorText="rfid.locked-status-summary-description"
            detailsText="card.details"
            orderedOrgArray={
              orderedRfidSummaryValuesPerOrg
                ? orderedRfidSummaryValuesPerOrg.lockedCount
                : []
            }
          />
        </Flex>
      </Flex>
      <Box zIndex="1">
        <Flex
          bg="white"
          borderWidth="thin"
          borderColor="gray.100"
          borderTopRadius={8}
          borderBottom="none"
          p="4"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row']}
        >
          <Box>
            <Text fontSize="sm">{t('rfid.table-header.title')}</Text>
          </Box>
          <Flex alignItems="center">
            <Search
              placeholder={t('rfid.table-header.search-rfid')}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            {hasScopes(['rfid:create']) && (
              <Button
                ml="4"
                leftIcon={<Icon type="NEW_ADD" color="BUTTON_TEXT_COLOR" />}
                size="sm"
                onClick={onOpen}
              >
                {t('rfid.table-header.button.title')}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
      <Box
        shadow="md"
        bg="white"
        borderWidth="thin"
        borderColor="gray.100"
        maxWidth="full"
        borderBottom="none"
        minHeight="2xl"
        maxHeight="2xl"
        overflow="auto"
      >
        <Table colorScheme="gray">
          <Thead>
            <Tr>
              <Th maxWidth={20}>{t('rfid.table.th-id.title')}</Th>
              <Th textAlign="center">
                {t('profile-dropdown.organization-section.singular-title')}
              </Th>
              <Th textAlign="center">{t('rfid.table.th-consumer.title')}</Th>
              <Th>
                <Flex alignItems="center" justifyContent="center">
                  <Text color="gray.500" pr="2">
                    {t('rfid.table.th-activation-date.title')}
                  </Text>
                  <Tooltip
                    label={t('rfid.table.th-activation-date.label')}
                    bg="gray.900"
                    placement="right"
                    hasArrow
                  >
                    <Stack>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </Stack>
                  </Tooltip>
                </Flex>
              </Th>
              <Th textAlign="center">{t('rfid.table.th-status.title')}</Th>
              {hasScopes(['rfid:create']) && <Th></Th>}
            </Tr>
          </Thead>
          <Tbody color="gray.900" position="relative">
            {rfidDataIsLoading ? (
              <SkeletonsTable columns={columns} />
            ) : emspHasSomeRfidRegistered ? (
              <Flex mt="60" position="absolute" left="41%">
                <VStack spacing="2">
                  <Box>
                    <Icon type="NEW_RFID" color="SECONDARY_GRAY" size={36} />
                  </Box>
                  <Heading fontSize="md" color="gray.500" textAlign="center">
                    {t('rfid.table.empty-table.title')}
                  </Heading>
                  <Text
                    fontSize="md"
                    color="gray.500"
                    maxWidth="60"
                    textAlign="center"
                  >
                    {t('rfid.table.empty-table.subtitle')}
                  </Text>
                </VStack>
              </Flex>
            ) : (
              <>
                {rfids?.map((rfid) => (
                  <Tr>
                    <Td maxWidth={20}>{rfid.decimalId}</Td>
                    <Td textAlign="center">
                      {formatOrgId(rfid.emspId, orgNameMap)}
                    </Td>
                    <Td textAlign="center">{rfid.consumerLabel ?? '-'}</Td>
                    <Td textAlign="center">
                      {rfid.activatedAt
                        ? t('rfid.table-row.activated-at-value', {
                            value: new Date(rfid.activatedAt),
                          })
                        : '-'}
                    </Td>
                    <Td
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textAlign="center"
                    >
                      <Flex justifyContent="center">
                        <RFIDStatusTag status={rfid.status} />
                      </Flex>
                    </Td>
                    {hasScopes(['rfid:create']) && (
                      <Td>
                        <Box float="right">
                          <RFIDDropdown
                            status={rfid.status}
                            decimalId={rfid.decimalId}
                            refetchInfo={refetchRfidsInfo}
                          />
                        </Box>
                      </Td>
                    )}
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
      </Box>
      <Box
        w="100%"
        bg="white"
        borderBottomRadius="8"
        shadow="md"
        borderWidth="thin"
        borderColor="gray.100"
        borderTop="none"
        zIndex={0}
      >
        <Flex
          h="16"
          p="4"
          position="relative"
          justifyContent="center"
          zIndex={1}
        >
          <Pagination
            activePage={pageIndex}
            pageCount={Math.ceil((rfidsSummary?.totalCount || 0) / pageCount)}
            setActivePage={setPageIndex}
            setPageCount={setPageCount}
            setSelectedLinesPerPageOption={setSelectedLinesPerPageOption}
            selectedLinesPerPageOption={selectedLinesPerPageOption}
          />
        </Flex>
      </Box>
      <CreateRFIDModal
        onClose={onClose}
        isOpen={isOpen}
        refetchRfidsInfo={refetchRfidsInfo}
      />
    </Flex>
  );
};

export default RFIDMaganagement;
