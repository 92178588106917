import StationTableCard from 'cards/StationTable';
import DateDropdown from 'components/DateDropdown';
import {
  DEFAULT_SESSION_STATUS_FILTER,
  transformToStationFilterInput,
  transformToStationSortInput,
} from 'components/Filter';
import Page from 'components/Page';
import StationsSummaryCards from 'components/StationsSummariesCards';
import {
  AllStationsOrganizationIndicatorsQuery,
  AllStationsQuery,
} from 'generated/graphql';
import allStations from 'graphql/queries/allStations';
import allStationsOrganizationIndicators from 'graphql/queries/allStationsOrganizationIndicators';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import moment from 'moment';
import NewPageHeader from 'new-components/NewPageHeader';
import { CardContainer } from 'pages/Home/styles';
import React, { useState, useContext } from 'react';
import AuthContext from 'contexts/Auth/context';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  formatStationFields,
  FormattedStation,
} from 'services/formatStationRow';
import { TimeRange, TIME_PERIODS, TIME_PERIODS_LIST } from 'services/timeRange';
import { TranslationKeyType } from 'types/translation';
import {
  compareOrgs,
  getAverageRevenueOfSelectedOrganizations,
  getRechargesSummaryOfAllSelectedOrganizations,
  getUsageRateSummaryOfAllSelectedOrganizations,
} from 'services/multiorg';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import { Container, DateDropdownContainer } from './styles';

const selectedColumns = [
  'stationName',
  'orgName',
  'city',
  'usageRate',
  'energyConsumed',
  'count',
  'stationConfigs',
] as const;

type PartialFormattedStation = Pick<
  FormattedStation,
  typeof selectedColumns[number]
> & { id: string };

// TODO: lookup defaults
const defaultPagination = {
  pageIndex: 0,
  pageSize: 50,
  filters: [] as any,
  sorts: [] as any,
  search: '',
};
// first time accessing Dashboard(will cache more times)
const now = moment();

const Stations: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasScopes = useHasScopes();

  const placeholderValue = TIME_PERIODS.WEEK;

  const [{ pageIndex, pageSize, filters, search, sorts }, setPagination] =
    useState<any>(defaultPagination);
  const [timeRange, setTimeRange] = useState<TimeRange>(placeholderValue);
  const timeRangeInput = timeRange.toTimeRangeInput(now);

  const {
    data: indicatorsData,
    error: indicatorsError,
    loading: indicatorsLoading,
  } = useAuthQuery<AllStationsOrganizationIndicatorsQuery>(
    allStationsOrganizationIndicators,
    {
      variables: {
        timeRange: timeRangeInput,
      },
      skip: !hasScopes(['session:read']),
    }
  );

  const {
    data: tableData,
    error: tableError,
    loading: tableLoading,
  } = useAuthQuery<AllStationsQuery>(allStations, {
    variables: {
      timeRange: timeRangeInput,
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: transformToStationFilterInput(filters),
      sessionsFilter: {
        includesStatus: DEFAULT_SESSION_STATUS_FILTER,
      },
      search,
      sort:
        sorts.length > 0
          ? transformToStationSortInput(sorts, {
              from: timeRangeInput.from,
              to: timeRangeInput.to,
            })
          : { cpoId: 'ASC' },
      includeSessionSummary: hasScopes(['session:read']),
    },
  });
  // TODO get gross value of each of organization
  // TODO compute total gross value by summing the gross value of all selected organizations
  // TODO do the same for count.

  const orderedAverageRevenueOrgArray = indicatorsData
    ? indicatorsData.organizations
        .map((org) => ({
          name: org.name,
          value:
            org.sessionsSummary.grossValue / org.sessionsSummary.count || 0,
        }))
        .sort(compareOrgs)
    : [];

  const numberOfRecharges =
    getRechargesSummaryOfAllSelectedOrganizations(indicatorsData);

  const orderedNumberOfRechargesOrgArray = indicatorsData
    ? indicatorsData.organizations
        .map((org) => ({
          name: org.name,
          value: org.sessionsSummary.count,
        }))
        .sort(compareOrgs)
    : [];

  const averageRevenue = getAverageRevenueOfSelectedOrganizations(
    orderedAverageRevenueOrgArray,
    numberOfRecharges
  );

  const usageRate =
    getUsageRateSummaryOfAllSelectedOrganizations(indicatorsData);

  const defaultCurrencyTypes = useDefaultCurrencyTypes();
  const currencyType = getOrganizationDefaultCurrencyType(
    defaultCurrencyTypes,
    indicatorsData?.organizations?.[0]?.id ?? undefined
  );

  const { orgNameMap } = useContext(AuthContext);

  return (
    <Page>
      <Container>
        <NewPageHeader title={t('stations.header.title')}>
          <DateDropdownContainer>
            <DateDropdown
              placeholderValue={placeholderValue}
              defaultOption={placeholderValue}
              options={TIME_PERIODS_LIST}
              format={(timeRange) =>
                t(timeRange.shortLabel as TranslationKeyType)
              }
              onChange={setTimeRange}
            />
          </DateDropdownContainer>
        </NewPageHeader>
        {hasScopes(['session:read']) && (
          <CardContainer>
            <StationsSummaryCards
              error={indicatorsError}
              loading={indicatorsLoading}
              averageRevenue={averageRevenue}
              numberOfRecharges={numberOfRecharges}
              usageRate={usageRate}
              orderedNumberOfRechargesOrgArray={
                orderedNumberOfRechargesOrgArray
              }
              orderedAverageRevenueOrgArray={orderedAverageRevenueOrgArray}
              orderedUsageRateOrgArray={
                indicatorsData
                  ? indicatorsData.organizations
                      .map((org) => ({
                        name: org.name,
                        value: org.averageUsageRate,
                      }))
                      .sort(compareOrgs)
                  : []
              }
              currencyType={currencyType}
            />
          </CardContainer>
        )}
        <CardContainer>
          <StationTableCard<PartialFormattedStation>
            onRowPress={({ original: { id } }) => {
              history.push(`/meus-eletropostos/${id}`);
            }}
            pageCount={Math.ceil(
              (tableData ? tableData.stationsSummary.count : 0) / pageSize
            )}
            canSearch
            error={tableError}
            loading={tableLoading}
            // data={tableData?.stations.map(formatStationFields) ?? []}
            data={
              tableData
                ? tableData.stations.map((station) =>
                    formatStationFields(station, orgNameMap)
                  )
                : []
            }
            fetchData={setPagination}
            hasFooter
            selectedColumns={
              hasScopes(['session:read'])
                ? selectedColumns
                : selectedColumns.filter(
                    (column) =>
                      column !== 'count' && column !== 'energyConsumed'
                  )
            }
          />
        </CardContainer>
      </Container>
    </Page>
  );
};

export default Stations;
