import styled from 'styled-components';
import LoadingContainer from 'new-components/LoadingComponent';
import { Comment as CommentTypography } from 'new-components/Typographies/styles';
import { space } from 'theme/selectors';

export const LoadingComponent = styled(LoadingContainer)``;
export const Profile = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: ${space('SPACE-4')};
`;
export const VehicleId = styled('section')`
  display: flex;
  align-items: center;
  gap: ${space('SPACE-8')};
`;
export const Comment = styled(CommentTypography)`
  color: ${({
    theme: {
      COLORS: { SECONDARY_GRAY },
    },
  }) => SECONDARY_GRAY};
`;
