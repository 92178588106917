import styled from 'styled-components';

import {
  BodyTypography,
  Comment,
  H5,
} from 'new-components/Typographies/styles';

import {
  ItemGroup,
  Item,
  ExtraInfo,
  IconTitleWrapper,
} from '../StationUserGenerics/styles';

export { Container, ContentGroup, Title } from '../StationUserGenerics/styles';

export const StationName = styled('section')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StationAddress = styled(ItemGroup)``;
export const StationAddressItem = styled(Item)``;

export const ConnectorInfo = styled(ExtraInfo)``;
export const ConnectorTitle = styled(H5)``;
export const Type = styled(IconTitleWrapper)``;
export const TypeName = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
`;

const ExtraInfoSpan = styled(Comment)`
  display: flex;
  align-items: center;
  background: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  padding: 0px 6px;
  border-radius: 100px;
  font-weight: bold;
`;
export const CurrentType = styled(ExtraInfoSpan)``;
export const Power = styled(ExtraInfoSpan)``;
