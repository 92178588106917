import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CreateVoucherStateData,
  CreateVoucherStatesContext,
} from '../context/state-context';
import { CreateVoucherClientContext } from '../context/client-context';

const validateCreateVoucherStateData = ({
  description,
  discount,
  driversSelection,
  limits,
  name,
  organizationId,
  stationsSelection,
  validityPeriod,
}: CreateVoucherStateData): boolean => {
  if (!name || !description || !discount || !organizationId) return false;

  if (!limits.isUnlimited && !limits.usageLimit) return false;

  if (
    !validityPeriod.isUnexpirable &&
    (!validityPeriod.from || !validityPeriod.to)
  )
    return false;

  if (
    !driversSelection.allDriversSelected &&
    driversSelection.selectedDrivers.length === 0 &&
    driversSelection.allDriversSelectedExcept.length === 0
  )
    return false;

  if (
    !stationsSelection.allStationsSelected &&
    stationsSelection.selectedStations.length === 0 &&
    stationsSelection.allStationsSelectedExcept.length === 0
  )
    return false;

  return true;
};

export const useCreateVoucherScreen = () => {
  const { createVoucherData, openCreateVoucherErrorModal } = useContext(
    CreateVoucherStatesContext
  );
  const client = useContext(CreateVoucherClientContext).getClient()();
  const history = useHistory();

  const [isCreateVoucherStateDataValid, setIsCreateVoucherStateDataValid] =
    useState<boolean>();

  const { createVoucherMutation, createVoucherLoading } =
    client.createVoucher(createVoucherData);

  useEffect(() => {
    setIsCreateVoucherStateDataValid(
      validateCreateVoucherStateData(createVoucherData)
    );
  }, [createVoucherData]);

  const onCreateVoucherButtonClick = async () => {
    try {
      await createVoucherMutation();
      history.goBack();
    } catch {
      openCreateVoucherErrorModal();
    }
  };

  const onCancelVoucherCreationButtonClick = () => {
    history.goBack();
  };

  return {
    onCreateVoucherButtonClick,
    onCancelVoucherCreationButtonClick,
    isCreateButtonDisabled: !isCreateVoucherStateDataValid,
    createVoucherLoading,
  };
};
