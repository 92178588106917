import { gql } from '@apollo/client';
import sessionRowFieldsFragment from 'graphql/fragments/sessionRowFields';

export default gql`
  query StationDetailsSessionsSummary(
    $where: StationWhereUniqueInput!
    $timeRange: TimeRangeInput!
    $filter: SessionFilterInput
    $limit: Int
    $offset: Int
    $sort: SessionSortInput = { createdAt: DESC }
  ) {
    station(where: $where) {
      id
      cpoId
      emsps {
        id
      }
      usageRate(timeRange: $timeRange)
      sessionsSummary(timeRange: $timeRange, filter: $filter) {
        grossValue
        count
        timeCharging
      }
      tableSessionsSummary: sessionsSummary(
        filter: $filter
        timeRange: $timeRange
      ) {
        count
        timeCharging
      }
      tableSessions: sessions(
        timeRange: $timeRange
        filter: $filter
        sort: $sort
        limit: $limit
        offset: $offset
      ) {
        ...SessionRowFields
      }
    }
  }
  ${sessionRowFieldsFragment}
`;
