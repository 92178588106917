import { gql } from '@apollo/client';
import pricingConfigFieldsFragment from '../fragments/pricingConfigFields';

export default gql`
  query GetRechargeModalData(
    $where: SessionWhereUniqueInput!
    $includeOrder: Boolean!
    $includeConsumer: Boolean!
  ) {
    session(where: $where) {
      id
      duration
      energyConsumed
      pricingConfig {
        ...PricingConfigFields
      }
      cpoId
      emspId
      createdAt
      updatedAt
      consumerId
      order @include(if: $includeOrder) {
        orderId
        subtotal
        totalCost
        capturedAt
        capturedValue
        currencyType
        preAuthorizedAt
        preAuthorizeAmount
        valueToBeCaptured
        cancelledAt
        cancellationReason
        status
        paymentMethod {
          id
          ... on PaymentCard {
            brand
            partiallyHiddenCardNumber
          }
          ... on PlacePlan {
            title
            holderFullName
          }
          __typename
        }
      }
      status
      finishedAt

      #   3 top blocks
      #   Started at/Stopped At/Occurring Cards
      startedChargingAt
      stoppedChargingAt

      #   Station and connector
      stationId
      stationProfile {
        id
        name
        status
        address {
          street
          streetNumber
          postalCode
          city
          state
        }
      }
      connector {
        id
        type
        power
        currentType
      }

      # Consumer
      consumerLabel
      consumer @include(if: $includeConsumer) {
        __typename
        ... on Vehicle {
          id
          label
          plate
          brand
          model
        }
        ... on Driver {
          id
          label
          profile {
            id
            firstName
            lastName
            email
          }
        }
      }
      remote
      deviceInfo {
        appName
        appVersion
      }
      chargingCardId
      rfid {
        decimalId
      }

      #   Graph
      energyMeasurements {
        timestamp
        value
      }
      emsp {
        roamingInfo {
          roamingId
          serviceName
        }
      }
      cpo {
        roamingInfo {
          networkName
          roamingId
        }
      }
    }
  }
  ${pricingConfigFieldsFragment}
`;
