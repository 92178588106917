import Typography, { Props as TypographyProps } from 'components/Typography';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import { StyledRouterLink } from './styles';

type Props = TypographyProps &
  LinkProps<any> & { textDecoration?: 'underline' | 'none' };

const Link: React.FC<Props> = ({
  color,
  children,
  textDecoration = 'none',
  ...props
}) => (
  <Typography
    as={StyledRouterLink}
    {...props}
    color={color || 'LINK'}
    textDecoration={textDecoration}
  >
    {children}
  </Typography>
);

export default Link;
