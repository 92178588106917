import Config from 'config';
import React, {
  KeyboardEvent,
  SetStateAction,
  useReducer,
  useState,
} from 'react';
import type { FilterAction, Filters, FilterTerm } from 'types';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  IconButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';
import { equals, validTerm } from '..';
import FilterTerms from '../FilterTerms';
import {
  CheckBoxGroupInput,
  useCheckboxReducer,
  useTextInputReducer,
} from './inputs';
import {
  AllInputsContainer,
  BlueText,
  ButtonContainer,
  CloseButton,
  Container,
  ErrorText,
  FilterText,
  Footer,
  Header,
  Title,
} from './styles';

export type SetFiltersTermsProps<U extends string> = React.Dispatch<
  SetStateAction<FilterTerm<U>[]>
>;
export interface Props<U extends string> {
  filters: Filters<U>;
  closeFilter: () => void;
  setAppliedFilterTerms: SetFiltersTermsProps<U>;
  appliedFilterTerms: FilterTerm<U>[];
}

const FilterPanel = <U extends string>({
  filters,
  closeFilter,
  setAppliedFilterTerms,
  appliedFilterTerms,
}: Props<U>) => {
  const { t } = useTranslation();

  type Term = FilterTerm<U>;
  const [textInputs, setInput] = useTextInputReducer();
  const [checkboxes, toggleCheckbox] = useCheckboxReducer(
    filters,
    appliedFilterTerms
  );

  const [errorMsg, showErrorMsg] = useState('');
  const [termsErrorMsgs, showTermsMsgs] = useState<Partial<Record<U, string>>>(
    {}
  );

  const [displayFilters, updateDisplayFilters] = useReducer(
    (state: Term[], { action, term }: FilterAction<U>) => {
      switch (action) {
        case 'ADD':
          return validTerm(term, state).error ? state : [...state, term];

        case 'REMOVE':
          if (checkboxes.get(term.type + term.value)) toggleCheckbox(term);

          return state.filter((x) => !equals(x, term));
      }
    },
    appliedFilterTerms
  );

  const setTermsAndClose = (terms: Term[]) => {
    setAppliedFilterTerms(terms);
    closeFilter();
  };

  const getError = (type: U) => termsErrorMsgs[type] ?? '';
  const setError = (type: U, error: string) =>
    showTermsMsgs({
      ...termsErrorMsgs,
      [type]: error ?? '',
    });

  const applyTerms = () =>
    displayFilters.length
      ? setTermsAndClose(displayFilters)
      : showErrorMsg(t('filter-panel.body.empty-filters-error.message'));

  const submitInput = (type: U, event?: KeyboardEvent<HTMLInputElement>) => {
    if (event) event.preventDefault();

    const term = { type, value: textInputs[type]! };

    const { error, errorMsg } = validTerm(term, displayFilters);

    if (!error) {
      updateDisplayFilters({ term, action: 'ADD' });
      setInput({ type, value: '' });
      showErrorMsg('');
    }

    setError(type, t(errorMsg));
  };

  const submitCheckbox = (term: Term) => {
    const action = checkboxes.get(term.type + term.value) ? 'REMOVE' : 'ADD';
    updateDisplayFilters({ action, term });
  };

  const hasFiltersDefined = !!displayFilters.length;

  return (
    <Container data-test="filter-container">
      <CloseButton onClick={closeFilter} />
      <Header>
        <Title weight="bold" size={20}>
          {t('filter-panel.header.title')}
        </Title>
        <AllInputsContainer>
          <Flex gap="12">
            {filters.map((filter, index) =>
              filter.inputType === 'text' ? (
                <>
                  <Box as="form" onSubmit={() => submitInput(filter.type)}>
                    <Flex gap="2">
                      <FormControl isInvalid={!!getError(filter.type)}>
                        <FormLabel fontSize="small">{filter.label}</FormLabel>
                        <Flex alignItems="flex-end" gap="2">
                          <Input
                            data-test={`filter-input-${filter.type}`}
                            size="sm"
                            borderRadius={6}
                            key={index}
                            value={textInputs[filter.type] ?? ''}
                            onChange={(event) =>
                              setInput({
                                type: filter.type,
                                value: event.target.value,
                              })
                            }
                            onKeyDown={(event) =>
                              event.key === 'Enter' &&
                              submitInput(filter.type, event)
                            }
                            placeholder={filter.placeholder}
                          />
                          <IconButton
                            size="sm"
                            aria-label={t(
                              'filter-panel.body.form.add-filter-icon-btn.aria-label'
                            )}
                            onClick={() => submitInput(filter.type)}
                            icon={
                              <Icon
                                type="NEW_ARROW_RIGHT"
                                color="WHITE"
                                size={12}
                              />
                            }
                          />
                        </Flex>
                        <FormErrorMessage>
                          {getError(filter.type)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>
                </>
              ) : (
                <CheckBoxGroupInput<U>
                  key={index}
                  filter={filter}
                  getChecked={(term) =>
                    checkboxes.get(term.type + term.value) ?? false
                  }
                  submitCheckbox={submitCheckbox}
                  toggleCheckbox={toggleCheckbox}
                />
              )
            )}
          </Flex>
        </AllInputsContainer>
        <Flex alignItems="center" mt={6}>
          <FilterText weight="bold" size={20}>
            {t('filter-panel.body.filter-list.title')}
          </FilterText>
          <FilterTerms
            terms={displayFilters}
            removeTerm={(term) =>
              updateDisplayFilters({ term, action: 'REMOVE' })
            }
            renderOnHeader={false}
          />
        </Flex>
      </Header>
      <Footer>
        <ErrorText color="ERROR" size={14}>
          {errorMsg}
        </ErrorText>
        <ButtonContainer>
          <Button
            data-test="submit-filter-button"
            isDisabled={!hasFiltersDefined}
            onClick={applyTerms}
          >
            {t('filter-panel.body.apply-filters-btn.title')}
          </Button>
          {errorMsg && (
            <Config.ICONS.ALERT
              height={15}
              width={15}
              color={Config.COLORS.ERROR}
            />
          )}
        </ButtonContainer>
        <BlueText
          data-test="submit-remove-all-filters"
          color="LINK"
          onClick={() => setTermsAndClose([])}
        >
          {t('filter-panel.body.remove-all-filters.title')}
        </BlueText>
      </Footer>
    </Container>
  );
};

export default FilterPanel;
