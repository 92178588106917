import { useEffect, useState } from 'react';
import toast from 'services/toast';
import useNavigatorOnline from './useNavigatorOnline';

const useConnectivityMonitor = () => {
  const isOnline = useNavigatorOnline();
  const [offlineToastId, setOfflineToastId] = useState<
    string | number | undefined
  >();
  useEffect(() => {
    if (!isOnline)
      setOfflineToastId(
        toast.error(
          'Parece que você está off-line. Algumas ações talvez não funcionem'
        )
      );
  }, [isOnline]);
  useEffect(() => {
    if (isOnline && offlineToastId) toast.dismiss(offlineToastId);
  }, [isOnline, offlineToastId]);
};
export default useConnectivityMonitor;
