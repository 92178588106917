/**
 * Hook for obtaining and manipulating URL parameters.
 *
 * @example
 * const urlParams = useURLParams();
 *
 * // Get the first parameter found with the specified key
 * const value = urlParams.get('key');
 *
 * // Update existing parameters
 * urlParams.set('existingParam', 'newValue');
 *
 * // Add a new parameter
 * urlParams.append('newParamKey', 'value');
 *
 * // Delete a parameter
 * urlParams.delete('existingParamKey');
 *
 * // Iterate over each parameter
 * urlParams.forEach((value, key) => {
 *   // Do something with each parameter
 * });
 *
 * // Check if a parameter exists
 * const hasParam = urlParams.has('key');
 *
 * // Get all values for a parameter found with the specified key
 * const allValues = urlParams.getAll('key');
 *
 * // Get all parameter values in string format
 * const allParamsStringify = urlParams.toString();
 */

export const useURLParams = () => new URLSearchParams(window.location.search);
