import React from 'react';
import Config from 'config';
import { Link } from 'components';
import { Container, Content, CloseButton, Paragraph } from './styles';

const TermsOfService: React.FC = () => {
  const newText = Config.TERMS_OF_SERVICE.split('\n').map((paragraphText) => (
    <Paragraph>{paragraphText}</Paragraph>
  ));

  return (
    <Container>
      <Link to="/login">
        <CloseButton />
      </Link>
      <Content>{newText}</Content>
    </Container>
  );
};

export default TermsOfService;
