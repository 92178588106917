import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from 'components';
import {
  FormControl,
  HStack,
  Flex,
  PinInput,
  Text,
  Spinner,
  Button,
  FormErrorMessage,
  Box,
  Heading,
} from '@chakra-ui/react';
import { PinInputField } from 'atomic-components/organisms/modals/TFA/ToggleTFAStatus/styles';
import AuthContext from 'contexts/Auth/context';
import { sendTFAVerificationCode, useTFAFirebaseLogin } from 'hooks/tfa';
import { MultiFactorResolver, RecaptchaVerifier } from 'firebase/auth';
import { Container, HeaderContainer, InputContainer } from './styles';

const TwoFactorAuthLogin = () => {
  const history = useHistory();
  const { makeMemberLoginByToken } = useContext(AuthContext);
  const [confirmationCodeValue, setConfirmationCodeValue] = useState('');
  const [makeTFAFirebaseLogin] = useTFAFirebaseLogin();
  const [isGenericError, setIsGenericError] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [resolver, setResolver] = useState<MultiFactorResolver>();
  const [loading, setIsLoading] = useState(false);
  const [captcha, setCaptha] = useState<RecaptchaVerifier | undefined>(
    undefined
  );

  const handleChangeConfirmationCodeValue = async (value: string) => {
    if (!resolver) return;
    if (isInvalidCode) setIsInvalidCode(false);
    setConfirmationCodeValue(value);

    if (value.length === 6) {
      setIsLoading(true);
      try {
        const { token } = await makeTFAFirebaseLogin({
          verificationCode: value,
          resolver,
          verificationId,
        });
        await makeMemberLoginByToken(token);

        history.push('/');
      } catch (e) {
        if (
          e instanceof Error &&
          e.message.includes('auth/invalid-verification-code')
        )
          setIsInvalidCode(true);
        else setIsGenericError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const sendTFACode = async () => {
    try {
      const { resolver, verificationId, recaptchaVerifier } =
        await sendTFAVerificationCode();
      setCaptha(recaptchaVerifier);
      setVerificationId(verificationId);
      setResolver(resolver);
    } catch (e) {
      console.log(e);
      setIsGenericError(true);
    }
  };

  useEffect(() => {
    sendTFACode();
  }, []);

  const handleGoBackLoginButtonPress = () => {
    setIsGenericError(false);
    history.push('/login');
  };

  if (isGenericError) {
    return (
      <Container>
        <Heading fontSize="4xl">Infelizmente, houve um erro inesperado</Heading>
        <Text fontSize="sm" color="gray.500" mt={2.5} mb={10}>
          Tente novamente em outro momento.
        </Text>
        <Button onClick={handleGoBackLoginButtonPress}>
          Voltar para Login
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <Typography weight="bold" size={35}>
          Autenticação em 2 etapas
        </Typography>
        <Text fontSize="sm" color="gray.500">
          Digite o código de confirmação de 6 dígitos, encaminhado para o seu
          celular, para acessar a sua conta
        </Text>
      </HeaderContainer>
      <InputContainer>
        {loading ? (
          <Spinner />
        ) : (
          <FormControl mt={6} isInvalid={isInvalidCode}>
            <Flex justifyContent="center">
              <HStack spacing={7}>
                <PinInput
                  value={confirmationCodeValue}
                  size="lg"
                  placeholder=""
                  onChange={(value) => handleChangeConfirmationCodeValue(value)}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
            <FormErrorMessage>
              O código digitado é inválido. Por favor, tente novamente.
            </FormErrorMessage>
          </FormControl>
        )}
      </InputContainer>
      <Box mt={10}>
        <Button
          variant="link"
          size="sm"
          id="pin-input"
          onClick={() => {
            try {
              setIsLoading(true);
              sendTFAVerificationCode(captcha).then((result) => {
                setVerificationId(result.verificationId);
                setResolver(result.resolver);
                setIsLoading(false);
              });
            } catch (e) {
              setIsGenericError(true);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Enviar código novamente
        </Button>
      </Box>
    </Container>
  );
};

export default TwoFactorAuthLogin;
