import {
  Checkbox,
  FormControl,
  FormLabel,
  Text,
  Input,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import { useCreateVoucherUsageLimits } from './services';

export const VoucherUsageLimitSelection = () => {
  const { t } = useTranslation();

  const {
    voucherLimits,
    onChangeVoucherLimitsInput,
    invalidUsageLimit,
    toggleVoucherIsUnlimited,
  } = useCreateVoucherUsageLimits();

  return (
    <Flex flexDirection="column" gap="1rem" mt="2rem">
      <FormControl isRequired={true}>
        <Flex flexDirection="column" gap="1rem">
          <FormLabel fontWeight="bold" fontSize="sm">
            {t('payments.voucher-tab.create-voucher.rules.usage-limit.title')}
          </FormLabel>
          <Flex flexDirection="column" gap="0.5rem">
            <Checkbox
              _checked={{
                '& .chakra-checkbox__control': {
                  background: 'primary.500',
                  border: '0rem',
                },
              }}
              isChecked={voucherLimits.isUnlimited}
              onChange={() => toggleVoucherIsUnlimited()}
            >
              <Text fontSize="sm">
                {t(
                  'payments.voucher-tab.create-voucher.rules.usage-limit.checkbox'
                )}
              </Text>
            </Checkbox>
            <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
              {t(
                'payments.voucher-tab.create-voucher.rules.usage-limit.subtitle'
              )}
            </Text>
          </Flex>
        </Flex>
      </FormControl>
      <FormControl isDisabled={voucherLimits.isUnlimited}>
        <FormLabel fontWeight="bold" fontSize="sm">
          {t('payments.voucher-tab.create-voucher.rules.recharge-amount.title')}
        </FormLabel>
        <Tooltip
          label={t(
            'payments.voucher-tab.create-voucher.rules.recharge-amount.tooltip'
          )}
          borderRadius="0.5rem"
          hasArrow
          isDisabled={!voucherLimits.isUnlimited}
          ml="0.125rem"
          placement="right"
          shouldWrapChildren
        >
          <Input
            borderColor={invalidUsageLimit ? 'red.500' : undefined}
            h="2.625rem"
            placeholder="-"
            type="number"
            value={voucherLimits.usageLimit ?? undefined}
            w="6.3rem"
            onChange={(e) => onChangeVoucherLimitsInput(e.target.value)}
          />
        </Tooltip>
        {invalidUsageLimit && (
          <Text position="absolute" mt="2" color="red.500" fontSize="sm">
            {t(
              'payments.voucher-tab.create-voucher.rules.usage-limit.input-error'
            )}
          </Text>
        )}
      </FormControl>
    </Flex>
  );
};
