import ConfigType from 'config/typing';
import React, { useState } from 'react';
import { Icon } from 'new-components/Icon';
import DepositToWalletModal from 'atomic-components/organisms/modals/DepositToWalletModal';
import { Box, VStack } from '@chakra-ui/react';
import {
  Container,
  Title,
  Info,
  WalletContainer,
  IconsContainer,
  ButtonIcon,
  AddCreditButton,
} from './styles';

export type Props = {
  title: string;
  info: string;
  icon: keyof ConfigType['NEW_ICONS'];
  type?: string;
  minimumWalletDepositAmount?: number;
  availableBalance?: number;
  driverId?: number;
};

const PaymentWalletInfo: React.FC<Props> = ({
  title,
  info,
  icon,
  minimumWalletDepositAmount,
  availableBalance,
  driverId,
}) => {
  const [openAddCredit, setOpenAddCredit] = useState<boolean>(false);
  const [openDepositToWalletModal, setOpenDepositToWalletModal] =
    useState<boolean>(false);

  return (
    <>
      <VStack>
        <WalletContainer>
          <Container>
            <IconsContainer>
              <Icon size={20} type={icon} color="PRIMARY" />
              <ButtonIcon onClick={() => setOpenAddCredit(!openAddCredit)}>
                <Icon type="NEW_THREE_HORIZONTAL_DOTS" color="PRIMARY_GRAY" />
              </ButtonIcon>
            </IconsContainer>
            <Title>{title}</Title>
            <Info>{info}</Info>
          </Container>
          {openAddCredit && (
            <Box mt={2}>
              <AddCreditButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  setOpenDepositToWalletModal(!openDepositToWalletModal);
                }}
                rightIcon={<Icon type="NEW_ADD" color="PRIMARY_GRAY" />}
              >
                Adicionar crédito
              </AddCreditButton>
            </Box>
          )}
        </WalletContainer>
      </VStack>
      <DepositToWalletModal
        onClose={() => setOpenDepositToWalletModal(!openDepositToWalletModal)}
        show={openDepositToWalletModal}
        availableBalance={availableBalance}
        minimumWalletDepositAmount={minimumWalletDepositAmount}
        driverId={driverId}
      />
    </>
  );
};

export default PaymentWalletInfo;
