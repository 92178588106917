import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  width: 100%;
  min-height: 34px;
  border-radius: 12px;
  display: flex;
  flex-flow: row;
  display: flex;
  justify-content: center;
  padding: 4px;
  box-sizing: border-box;
`;

export const SelectorOption = styled.div<{ isActive: boolean }>`
  background: ${({ theme, isActive }) =>
    isActive ? theme.COLORS.WHITE : theme.COLORS.TERTIARY_GRAY};
  cursor: pointer;
  flex: 1 1 auto;
  margin-right: 4px;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;

  user-select: none;
  &:hover {
    background: ${({ theme }) => theme.COLORS.BACKGROUND};
  }
  &:last-child {
    margin-right: 0px;
  }
`;
