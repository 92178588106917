import styled, { css } from 'styled-components';

interface ContainerProps {
  top?: number;
  size: number;
  position?: string;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  ${({ size, top }) =>
    top &&
    css`
      left: calc(50% - ${size / 2}px);
      right: calc(50% - ${size / 2}px);
    `}
  > *:first-child {
    // we use top != null to check if top is different than undefined AND null.
    ${({ top }) =>
      top != null ? `margin-top: ${top}px` : `align-items: center`};
  }
`;
