import { gql } from '@apollo/client';

export default gql`
  query Comments($limit: Int, $offset: Int, $filter: CommentFilterInput) {
    commentsSummary {
      count
    }
    comments(limit: $limit, offset: $offset, filter: $filter) {
      __typename
      createdAt
      driverId
      deviceInfo {
        appName
        appVersion
        osName
        osVersion
      }
      ... on Review {
        optionalMessage: message
        stars
        sessionId
        stationProfile {
          id
          name
          cpoId
        }
      }
      ... on Feedback {
        message
        fromAppScreen
        stationProfile {
          id
          name
          cpoId
        }
      }
    }
  }
`;
