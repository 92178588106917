import { createContext } from 'react';
import { SelectedVoucherData } from '../ListVouchersTab/containers/VoucherTable/service';
import { VoucherDetails } from '../VoucherDetailsModal/services';

export type VoucherEventsType = {
  updateVoucherDetails: (args: VoucherDetails) => void;
  voucherDetails: VoucherDetails | null;
  updateVoucherDetailsLoading: (value: boolean) => void;
  voucherDetailsLoading: boolean;
  updateCurrentSelectedVoucher: (args: SelectedVoucherData | null) => void;
  currentSelectedVoucher: SelectedVoucherData | null;
  updateVoucherDetailsModalVisible: (value: boolean) => void;
  openVoucherDetailsModal: () => void;
  voucherDetailsModalVisible: boolean;

  updateListVouchersLoading: (value: boolean) => void;
  listVouchersLoading: boolean;
  updateArchiveVoucherModalVisible: (value: boolean) => void;
  openArchiveVoucherModal: () => void;
  archiveVoucherModalVisible: boolean;
};

export const VoucherEventsContext = createContext<{
  getEvents: () => VoucherEventsType;
}>({
  getEvents: () => ({
    updateVoucherDetails: () => {},
    voucherDetails: null,
    updateVoucherDetailsLoading: () => {},
    voucherDetailsLoading: false,
    updateCurrentSelectedVoucher: () => {},
    currentSelectedVoucher: null,
    updateVoucherDetailsModalVisible: () => {},
    openVoucherDetailsModal: () => {},
    voucherDetailsModalVisible: false,

    updateListVouchersLoading: () => {},
    listVouchersLoading: false,
    updateArchiveVoucherModalVisible: () => {},
    openArchiveVoucherModal: () => {},
    archiveVoucherModalVisible: false,
  }),
});
