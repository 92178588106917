import React from 'react';
import ConfigType from 'config/typing';
import { GetRechargeModalDataQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import DriverCard from './DriverCard';
import VehicleCard from './VehicleCard';
import UnlockMethod from './UnlockMethod';

export type Consumer = NonNullable<
  NonNullable<NonNullable<GetRechargeModalDataQuery>['session']>['consumer']
>;

export type Props = {
  consumerId: number;
  consumer?: Consumer;
  consumerLabel: string;
  unlockMethod: {
    name: string;
    icon?: keyof ConfigType['NEW_ICONS'];
  };
  emspId: string;
};

const ConsumerCard: React.FC<Props> = ({
  consumerId,
  consumer,
  unlockMethod,
  consumerLabel,
  emspId,
}) => {
  const { t } = useTranslation();
  if (consumer == null) {
    return (
      <Card
        title={t('recharge-modal.consumer-card.title')}
        boldText={consumerLabel}
        footer={<UnlockMethod unlockMethod={unlockMethod} />}
        clickable={false}
        emspId={emspId}
      />
    );
  }

  switch (consumer.__typename) {
    case 'Driver': {
      return (
        <DriverCard
          consumerId={consumerId}
          consumer={consumer}
          consumerLabel={consumerLabel}
          unlockMethod={unlockMethod}
          emspId={emspId}
        />
      );
    }
    case 'Vehicle': {
      return (
        <VehicleCard
          consumerId={consumerId}
          consumer={consumer}
          consumerLabel={consumerLabel}
          unlockMethod={unlockMethod}
          emspId={emspId}
        />
      );
    }
  }
};

export default ConsumerCard;
