import { Box, Flex, Text, Stack, Heading } from '@chakra-ui/react';
import { t } from 'i18next';

type CPO = {
  roamingId: string;
  networkName: string;
};

type eMSP = {
  roamingId: string;
  serviceName: string;
};

type Props = {
  cpo?: CPO;
  emsp?: eMSP;
};

export const RoamingInfo = ({ cpo, emsp }: Props) => (
  <>
    <Box
      bg="white"
      borderWidth="thin"
      borderColor="gray.100"
      borderRadius={8}
      p={4}
    >
      <Box bg="gray.50" p={3} borderRadius={8} h="100%">
        <Text color="gray.500" pb={3} fontSize="sm" borderRadius="8">
          {t('recharge-modal.roaming-info.title')}
        </Text>
        <Heading fontSize="sm" lineHeight="unset">
          {t('recharge-modal.roaming-info.description')}
        </Heading>
      </Box>
    </Box>
    <Box
      borderWidth="thin"
      borderColor="gray.100"
      bg="white"
      borderRadius={8}
      p={4}
    >
      <Text pb={3} color="gray.500" fontSize="x-small">
        {t('recharge-modal.roaming-info.data.title')}
      </Text>
      <Flex flexDirection="column" fontSize="sm">
        <Stack spacing={3}>
          <Flex justifyContent="space-between">
            <Text color="gray.500">
              {t('recharge-modal.roaming-info.data.emspId.title')}
            </Text>

            <Heading fontSize="sm">{emsp?.roamingId}</Heading>
          </Flex>
          <Flex justifyContent="space-between">
            <Text color="gray.500">
              {t('recharge-modal.roaming-info.data.service.title')}
            </Text>

            <Heading fontSize="sm">{emsp?.serviceName}</Heading>
          </Flex>
          <Flex justifyContent="space-between">
            <Text color="gray.500">
              {t('recharge-modal.roaming-info.data.cpoId.title')}
            </Text>
            <Heading fontSize="sm">{cpo?.roamingId}</Heading>
          </Flex>
          <Flex justifyContent="space-between">
            <Text color="gray.500">
              {t('recharge-modal.roaming-info.data.network-name.title')}
            </Text>
            <Heading fontSize="sm">{cpo?.networkName}</Heading>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  </>
);
