import { configs } from 'config';
import { Organization } from 'contexts/Auth/context';
import {
  CHANGING_SELECTED_ORG_IDS_COOKIE_KEY,
  URL_SUFFIX,
} from 'contexts/Auth/provider';

export const WHITELABELS = Object.keys(configs);

// Obter a quantidade de organizações que estão selecionadas
// em um determinado nível da árvore de organizações/suborgs
export const calculateSelectedOrganizations = (
  organizations: string[],
  preSelectedOrganizations?: string[]
): {
  numberOfOrganizationsSelected: number;
  totalNumberOfOrganizations: number;
} => {
  let numberOfOrganizationsSelected = 0;
  let totalNumberOfOrganizations = 0;

  organizations.forEach((org) => {
    if (preSelectedOrganizations?.includes(org)) {
      numberOfOrganizationsSelected += 1;
    }
    totalNumberOfOrganizations += 1;
  });

  return {
    numberOfOrganizationsSelected,
    totalNumberOfOrganizations,
  };
};

export const selectedOrganizationsChanged = (
  preSelectedOrganizationIds: string[] | undefined,
  storedOrganizationsIds: string[] | undefined
) =>
  JSON.stringify(preSelectedOrganizationIds) ===
  JSON.stringify(storedOrganizationsIds);

export const getTagText = (
  selectedOrganizationsNumber: number,
  totalOfOrganizations: number
) => `${selectedOrganizationsNumber} / ${totalOfOrganizations}`;

export const verifyIfOrganizationIsSelected = (
  selectedOrgId: string,
  preSelectedOrganizationIds: string[] | undefined
) => preSelectedOrganizationIds?.some((org) => org === selectedOrgId) ?? false;

export const sortOrganizations = (a: string, b: string) => a.localeCompare(b);

export const saveCurrentPreSelectedOrgIdsOnCookie = (
  preSelectedOrganizationIds?: string[]
) => {
  document.cookie = `${CHANGING_SELECTED_ORG_IDS_COOKIE_KEY}=${preSelectedOrganizationIds}; domain=${URL_SUFFIX}`;
};

const getSuborganization = (organizations: Organization[], orgId?: string) =>
  organizations?.filter((org) => org.parentOrganizationId === orgId);

const getLevelParentOrganizationIds = (
  currentLevelOrganizationIds: string[],
  organizations: Organization[]
) =>
  organizations
    ?.filter((org) =>
      org?.parentOrganizationId
        ? currentLevelOrganizationIds?.includes(org?.parentOrganizationId)
        : false
    )
    .map((o) => o.id);

export const getParentOrganizationIds = (
  organizations: Organization[],
  orgId: string
) => {
  const organizationIds =
    getSuborganization(organizations, orgId)?.flatMap((subOrg) => subOrg.id) ??
    [];

  const firstLevelSuborgs = getLevelParentOrganizationIds(
    organizationIds,
    organizations
  );
  const secondLevelSuborgs = getLevelParentOrganizationIds(
    firstLevelSuborgs,
    organizations
  );
  const thirdLevelSuborg = getLevelParentOrganizationIds(
    secondLevelSuborgs,
    organizations
  );
  const fourthLevelSuborg = getLevelParentOrganizationIds(
    thirdLevelSuborg,
    organizations
  );
  const fifthLevelSuborg = getLevelParentOrganizationIds(
    fourthLevelSuborg,
    organizations
  );

  const parentOrganizationIds = [
    orgId,
    ...organizationIds,
    ...firstLevelSuborgs,
    ...secondLevelSuborgs,
    ...thirdLevelSuborg,
    ...fourthLevelSuborg,
    ...fifthLevelSuborg,
  ];

  return parentOrganizationIds;
};
