import React from 'react';
import Config from 'config';
import ConfigType from 'config/typing';
import { useTranslation } from 'react-i18next';
import {
  IconTitleWrapper,
  UnlockMethodContainer,
  UnlockMethodName,
  UnlockMethodTitle,
} from './styles';

type Props = {
  unlockMethod: {
    name: string;
    icon?: keyof ConfigType['NEW_ICONS'];
  };
};

const UnlockMethod: React.FC<Props> = ({ unlockMethod }) => {
  const { t } = useTranslation();
  const UnlockMethodIcon =
    unlockMethod.icon && Config.NEW_ICONS[unlockMethod.icon];

  return (
    <UnlockMethodContainer>
      <UnlockMethodTitle>
        {t('recharge-modal.consumer-card.unlock-method')}
      </UnlockMethodTitle>
      <IconTitleWrapper>
        {UnlockMethodIcon && (
          <UnlockMethodIcon style={{ maxHeight: '16px', width: '16px' }} />
        )}
        <UnlockMethodName>{unlockMethod.name}</UnlockMethodName>
      </IconTitleWrapper>
    </UnlockMethodContainer>
  );
};
export default UnlockMethod;
