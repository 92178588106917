import { gql } from '@apollo/client';

export default gql`
  query AccountExclusionInfo {
    currentDriver {
      id
      exclusionScheduledDate
    }
  }
`;
