import { ApolloError } from '@apollo/client';
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import Filter, { FilterTerms } from 'components/Filter';
import { FetchDataFunction } from 'components/Table';
import useHasScopes from 'hooks/useHasScope';
import ErrorComponent from 'new-components/ErrorComponent';
import React, { useState } from 'react';
import { useExportSessions } from 'services/export/sessions';
import { FormattedSession } from 'services/formatSessionRow';
import { TimeRange } from 'services/timeRange';
import ExportButton from 'components/ExportButton';
import { Filters, FilterTerm } from 'types';
import { Tooltip, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SessionTable from './SessionTable';
import {
  ButtonContainer,
  FilterButtonContainer,
  SessionTableContainer,
} from './styles';

type FilterKeys = 'driverId' | 'stationName';
export type Term = FilterTerm<FilterKeys>;

export type Props<T extends Partial<FormattedSession>> = {
  columns: ReadonlyArray<keyof T>;
  data: T[];
  pageCount: number;
  fetchData: FetchDataFunction<T>;
  loading: boolean;
  filters: Filters<FilterKeys>;
  error?: ApolloError;
  tableTitle?: string;
  filterSessionsByStationId?: string;
  timeRange?: TimeRange;
  handleRowClick: (sessionId?: string) => void;
};

const areTermsEqual = (termA: Term, termB: Term) =>
  termA.value === termB.value && termA.type === termB.type;

function SessionTableCard<T extends Partial<FormattedSession>>(
  props: Props<T>
) {
  const {
    fetchData,
    data,
    loading,
    error,
    columns,
    tableTitle,
    filters,
    pageCount,
    timeRange,
    filterSessionsByStationId,
    handleRowClick,
  } = props;

  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const exportSessions = useExportSessions({
    filterSessionsByStationId,
    timeRange,
  });

  const [appliedFilterTerms, setAppliedFilterTerms] = useState<Term[]>([]);
  const [isFilterPanelVisible, setFilterPanelVisibility] = useState(false);
  const removeAppliedFilter = (term: Term) =>
    setAppliedFilterTerms(
      appliedFilterTerms.filter((x) => !areTermsEqual(x, term))
    );
  const toggleFilterPanel = () => setFilterPanelVisibility((prev) => !prev);
  const title = tableTitle || (t('session-table-card.title') as string);

  return (
    <Card minHeight={841} type="complex" error={error && !data} loading={false}>
      <CardHeader>
        <CardTitle title={title} />
        <FilterTerms
          renderOnHeader={true}
          terms={appliedFilterTerms}
          removeTerm={removeAppliedFilter}
        />
        <ButtonContainer>
          {hasScopes(['session:export']) && (
            <>
              <Tooltip
                label={t('session-table-card.export-btn.label')}
                bg="gray.900"
                placement="right"
                textAlign="justify"
                hasArrow
              >
                <Stack>
                  <ExportButton onClickToExport={exportSessions} />
                </Stack>
              </Tooltip>
            </>
          )}
          <FilterButtonContainer>
            <Filter
              filters={filters}
              active={isFilterPanelVisible}
              appliedFilterTerms={appliedFilterTerms}
              setAppliedFilterTerms={setAppliedFilterTerms}
              toggleFilterPanel={toggleFilterPanel}
            />
          </FilterButtonContainer>
        </ButtonContainer>
      </CardHeader>
      {error && !data ? (
        <ErrorComponent>{t('session-table-card.error-message')}</ErrorComponent>
      ) : (
        <SessionTableContainer>
          <SessionTable
            columns={columns}
            data={data}
            pageCount={pageCount}
            appliedFilters={appliedFilterTerms}
            fetchData={fetchData}
            timeRange={timeRange}
            loading={loading}
            handleRowClick={handleRowClick}
          />
        </SessionTableContainer>
      )}
    </Card>
  );
}

export default React.memo(
  SessionTableCard,
  (a, b) =>
    JSON.stringify([a.data, a.filters, a.error, a.loading]) ===
    JSON.stringify([b.data, b.filters, b.error, b.loading])
);
