import React, { useState } from 'react';
import stationDetails from 'graphql/queries/stationDetails';
import {
  BasicStationsDetailsQuery,
  StationDetailsQuery,
  StationDetailsQueryVariables,
  StationStatus,
} from 'generated/graphql';
import Map from 'components/Map';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import Marker from './Marker';
import StationPanel from './StationPanel';
import StationPreview from './StationPreview';
import mapStyle from './map.styles';
import { Container } from './styles';

type Stations = BasicStationsDetailsQuery['stations'];

type Station = Required<BasicStationsDetailsQuery['stations'][number]>;

const priorityTable: Record<StationStatus, number> = {
  AVAILABLE: 1,
  CHARGING: 2,
  INOPERATIVE: 3,
  UNKNOWN: 4,
  PLANNED: 5,
  RESERVED: 5,
};

const stationSortRule = (a: Station, b: Station) =>
  priorityTable[b.status] - priorityTable[a.status];

const MapWrapper: React.FC<{ stations: Stations }> = ({ stations }) => {
  const [selectedStation, setSelectedStation] = useState<Station | undefined>(
    undefined
  );
  const { data: stationDetailedInfo, loading } = useRealtimeQuery<
    StationDetailsQuery,
    StationDetailsQueryVariables
  >(stationDetails, {
    variables: {
      where: {
        stationId: selectedStation?.id,
      },
    },
    skip: !selectedStation,
  });

  const closeStationPanel = () => setSelectedStation(undefined);

  const generateMarker = ({ coordinates, id, status, cpoId }: Station) => (
    <Marker
      lat={coordinates.latitude}
      lng={coordinates.longitude}
      key={id}
      status={status}
      onClick={() => {
        setSelectedStation({ id, status, coordinates, cpoId });
      }}
    />
  );

  const stationHasCoordinates = (
    station: Stations[number]
  ): station is Station => !!station.coordinates;

  const sortedStations = stations
    .slice()
    .filter(stationHasCoordinates)
    .sort(stationSortRule);

  return (
    <Container>
      <Map
        center={
          selectedStation && {
            latitude: selectedStation.coordinates.latitude,
            longitude: selectedStation.coordinates.longitude,
          }
        }
        coordinatesOfAllElements={sortedStations.flatMap(
          (st) => st.coordinates
        )}
        mapStyle={mapStyle}
      >
        {sortedStations.map(generateMarker)}
        {selectedStation && (
          <StationPreview
            stationDetails={stationDetailedInfo?.station}
            cpoId={selectedStation.cpoId}
            onClose={closeStationPanel}
            loading={loading}
          />
        )}
      </Map>
      {selectedStation && (
        <StationPanel
          stationDetails={stationDetailedInfo?.station}
          onClose={closeStationPanel}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default MapWrapper;
