import {
  DeleteGroupMutation,
  DeleteGroupMutationVariables,
  UpdateGroupStationsMutation,
  UpdateGroupStationsMutationVariables,
} from 'generated/graphql';
import deleteGroupMutation from 'graphql/mutations/deleteGroup';
import updateGroupStationsMutation from 'graphql/mutations/updateStationsToGroup';
import { useAuthMutation } from 'hooks';
import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { BodyTypography, H3 } from 'new-components/Typographies/styles';
import React from 'react';
import toast from 'services/toast';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Header, Body, Label, ButtonWrapper } from './styles';

type Props = {
  groupId: string;
  onClose: () => void;
  show: boolean;
  closeModalContainer: () => void;
  updateListGroups: () => void;
};

const ConfirmGroupDeletionModal: React.FC<Props> = ({
  onClose,
  show,
  groupId,
  closeModalContainer,
  updateListGroups,
}) => {
  const { t } = useTranslation();
  const [updateGroupStations, { loading: updateStationsLoading }] =
    useAuthMutation<
      UpdateGroupStationsMutation,
      UpdateGroupStationsMutationVariables
    >(updateGroupStationsMutation);

  const [deleteGroup, { loading: deleteGroupLoading }] = useAuthMutation<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >(deleteGroupMutation);

  const handleDeleteGroup = async () => {
    try {
      await updateGroupStations({
        variables: {
          data: { stationIds: [] },
          where: {
            groupId,
          },
        },
      });

      await deleteGroup({
        variables: {
          where: {
            id: groupId,
          },
        },
      });

      toast.success(
        t('confirm-group-deletion-modal.toast-successfully-deleted-message')
      );
      closeModalContainer();
      updateListGroups();
    } catch (e) {
      toast.error(t('confirm-group-deletion-modal.toast-error-message'));
    }

    onClose();
  };

  return (
    <Modal size="sm" show={show} onClose={onClose} centered>
      <Modal.Header>
        <Header>
          <Icon color="Error" type="NEW_WARNING" size={24} />
          <H3>{t('confirm-group-deletion-modal.header.title')}</H3>
        </Header>
        <Modal.Header.Attachments padding="24">
          <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Label>
            <BodyTypography>
              {t('confirm-group-deletion-modal.body.label')}
            </BodyTypography>
          </Label>
          <ButtonWrapper>
            <Flex justifyContent="flex-end">
              <Stack direction="row" spacing={6}>
                <Button
                  variant="link"
                  color="gray.900"
                  onClick={onClose}
                  size="sm"
                >
                  {t('confirm-group-deletion-modal.body.cancel-btn')}
                </Button>
                <Button
                  colorScheme="error"
                  onClick={handleDeleteGroup}
                  isLoading={updateStationsLoading || deleteGroupLoading}
                  size="sm"
                  color="white"
                >
                  {t('confirm-group-deletion-modal.body.delete-group-btn')}
                </Button>
              </Stack>
            </Flex>
          </ButtonWrapper>
        </Body>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmGroupDeletionModal;
