import useHasScopes from 'hooks/useHasScope';
import { useHistory } from 'react-router-dom';

export function useListVouchers() {
  const hasScopes = useHasScopes();
  const history = useHistory();

  const showCreateVoucherButton = hasScopes(['voucher:create']);
  const openCreateVoucherScreen = () => {
    history.push('/pagamentos/criar-cupom');
  };

  return {
    showCreateVoucherButton,
    openCreateVoucherScreen,
  };
}
