import styled from 'styled-components';

export const NAVBAR_HEIGHT = '45px';

export const OperationModeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-left: 45px;
  padding: 0px 12px 0px 12px;
  height: 29px;
  border-radius: 9px;
  &:hover {
    background: ${({ theme }) => theme.COLORS.BACKGROUND};
    cursor: pointer;
  }
`;

export const OptionsContainer = styled.div`
  height: 16px;
  margin-left: 15px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
`;
