import { useToast } from '@chakra-ui/react';
import { VoucherClientContext } from 'pages/Payments/Voucher/context/client-context';
import { VoucherEventsContext } from 'pages/Payments/Voucher/context/events-context';
import { useContext, useEffect, useState } from 'react';

export function useArchiveVoucherModal() {
  const events = useContext(VoucherEventsContext).getEvents();
  const client = useContext(VoucherClientContext).getClient()();

  const [archiveVoucherModalVisible, setArchiveVoucherModalVisible] =
    useState(false);

  useEffect(() => {
    if (events.archiveVoucherModalVisible) setArchiveVoucherModalVisible(true);
  }, [events.archiveVoucherModalVisible]);

  useEffect(() => {
    events.updateArchiveVoucherModalVisible(archiveVoucherModalVisible);
  }, [archiveVoucherModalVisible]);

  const closeModal = () => {
    setArchiveVoucherModalVisible(false);
  };

  const toast = useToast();
  const archiveVoucher = () => {
    client.archiveVoucher();
    setArchiveVoucherModalVisible(false);
    toast({
      title: `Option not implemented`,
      status: 'warning',
      isClosable: true,
    });
  };

  return {
    closeModal,
    archiveVoucher,
    archiveVoucherModalVisible,
  };
}
