import React, { RefObject, InputHTMLAttributes } from 'react';
import { Wrapper, ErrorText, HelperText, Input, Label } from './styles';

export type Props = {
  name?: string;
  label: string;
  error?: string;
  helperText?: string;
  inputRef?: RefObject<HTMLInputElement>;
} & InputHTMLAttributes<HTMLInputElement>;

const TextInput: React.FC<Props> = ({
  label,
  error,
  helperText,
  inputRef,
  className,
  type,
  ...props
}) => (
  <Wrapper className={className}>
    {label && <Label>{label}</Label>}
    <Input
      type={type ?? 'text'}
      ref={inputRef}
      error={error ? 1 : 0}
      {...props}
    />
    {error && <ErrorText>{error}</ErrorText>}
    {helperText && !error && <HelperText>{helperText}</HelperText>}
  </Wrapper>
);

export default TextInput;
