import React from 'react';
import Config from 'config';
import { Button, Text } from '@chakra-ui/react';

interface Props {
  onClick?: () => void;
  previousPage?: boolean;
  nextPage?: boolean;
  dottedLeft?: boolean;
  dottedRight?: boolean;
  active?: boolean;
}

const PaginationButton: React.FC<Props> = ({
  children,
  onClick,
  previousPage,
  nextPage,
  dottedLeft,
  dottedRight,
  active,
}) => (
  <>
    {dottedLeft && (
      <Text position="relative" pointerEvents="none" userSelect="none" m="1.5">
        ...
      </Text>
    )}
    {active ? (
      <Button
        onClick={onClick}
        colorScheme="primary"
        height="9"
        width="9"
        borderRadius="8"
        borderColor="gray.100"
        mx="1"
        bg="primary.600"
        _hover={{
          bg: 'primary',
        }}
        fontWeight="normal"
      >
        {children}
      </Button>
    ) : (
      <Button
        onClick={onClick}
        colorScheme="gray"
        variant="outline"
        height="9"
        width="9"
        borderRadius="8"
        borderColor="gray.100"
        mx="1"
        fontWeight="normal"
        _hover={{
          bg: 'gray.50',
        }}
      >
        {previousPage ? (
          <Config.ICONS.PREVIOUS_PAGE />
        ) : nextPage ? (
          <Config.ICONS.NEXT_PAGE />
        ) : (
          <Text fontSize="sm" color="gray.900">
            {children}
          </Text>
        )}
      </Button>
    )}
    {dottedRight && (
      <Text position="relative" m="1.5" pointerEvents="none" userSelect="none">
        ...
      </Text>
    )}
  </>
);
export default PaginationButton;
