import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const FullChargeConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'full-charge',
  LABEL: 'FullCharge',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#3829FF',
    CLICKABLE: '#1504F1',
    ERROR: '#F87060',
    SIDEBAR: '#061233',
    LOGIN_LOGO_BACKGROUND: '#061233',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#1504F1',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#3EBAFF',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#805B00',

    PREPARING: '#3EBAFF',
    FINISHING: '#3EBAFF',

    SELECTED_DATE_BOUND_BACKGROUND: '#0F04A4',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#1304D7',

    DATE_PRE_SELECTED_BACKGROUND: '#3829FF',
    DATE_PRE_SELECTED_BORDER: '#685DFE',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#8076FE',
    DATE_PRE_SELECTED_COLOR: '#1304D7',

    DATE_SELECTED_BACKGROUND: '#3829FF',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#0F04A4',

    CURRENT_DAY: '#3829FF',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#805B00',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#3EBAFF',
      CHARGING: '#3EBAFF',
      FINISHING: '#3EBAFF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#805B00',
    },

    BILLING: '#04E762',
    RECHARGES: '#3EBAFF',
    AVAILABILITY: '#805B00',

    BUTTON_AVAILABLE: '#3829FF',
    BUTTON_UNAVAILABLE: '#8076FE',
    BUTTON_LOADING: '#685DFE',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#3829FF',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#0F04A4',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#1304D7',

      DATE_PRE_SELECTED_BACKGROUND: '#3829FF',
      DATE_PRE_SELECTED_BORDER: '#685DFE',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#8076FE',
      DATE_PRE_SELECTED_COLOR: '#1304D7',

      DATE_SELECTED_BACKGROUND: '#3829FF',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#0F04A4',

      CURRENT_DAY: '#3829FF',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -26.505393664956564,
      lng: -49.101222246854306,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'FullCharge - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/full-charge',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#978FFF',
      '100': '#8076FE',
      '200': '#685DFE',
      '300': '#5144FD',
      '400': '#3A2CFC',
      '500': '#3829FF',
      '600': '#1504F1',
      '700': '#1304D7',
      '800': '#1104BE',
      '900': '#0F04A4',
    },
    LINK: {
      '50': '#978FFF',
      '100': '#8076FE',
      '200': '#685DFE',
      '300': '#5144FD',
      '400': '#3A2CFC',
      '500': '#3829FF',
      '600': '#1504F1',
      '700': '#1304D7',
      '800': '#1104BE',
      '900': '#0F04A4',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#A4DEFF',
      '100': '#8BD5FE',
      '200': '#72CCFE',
      '300': '#59C3FD',
      '400': '#40B9FD',
      '500': '#3EBAFF',
      '600': '#0FA7FB',
      '700': '#0599EC',
      '800': '#0589D2',
      '900': '#0578B9',
    },
    AVAILABLE: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#1648CF',
      '100': '#1440B8',
      '200': '#1238A0',
      '300': '#103089',
      '400': '#0E2872',
      '500': '#061233',
      '600': '#061130',
      '700': '#06102D',
      '800': '#030817',
      '900': '#02050E',
    },
    RESERVE: {
      '50': '#E5A301',
      '100': '#CB9101',
      '200': '#B27F01',
      '300': '#986C02',
      '400': '#7E5A02',
      '500': '#805B00',
      '600': '#4C3601',
      '700': '#322401',
      '800': '#191201',
      '900': '#130E00',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default FullChargeConfig;
