import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { fileToDataURL } from '../../../../services/file';
import {
  CloseButton,
  CloseIcon,
  Container,
  FirstImageCard,
  FirstImageText,
  ImageContainer,
  ImageListContainer,
  PreviewImage,
} from './styles';

const reorder = (list: Content[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type Content = {
  id: string;
  content: File | undefined;
};
interface ImageListProps {
  imageList: Content[];
  onDelete: (index: number) => void;
}

interface ImageWrapperProps {
  image: Content;
  index: number;
  onDelete: (index: number) => void;
}

const ImageWrapper = SortableElement(
  ({ values }: { values: ImageWrapperProps }) => {
    const { t } = useTranslation();

    const { image, index, onDelete } = values;
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
      if (image.content)
        fileToDataURL(image.content).then((url) => setImageSrc(url));
    }, [image.content]);

    return (
      <ImageContainer>
        {index === 0 && image?.content && (
          <FirstImageCard>
            <FirstImageText>
              {t('station-page.images-gallery.first-image-title')}
            </FirstImageText>
          </FirstImageCard>
        )}
        {image?.content && (
          <CloseButton onClick={() => image.content && onDelete(index)}>
            <CloseIcon />
          </CloseButton>
        )}
        {image?.content && <PreviewImage src={imageSrc} />}
      </ImageContainer>
    );
  }
);

const ImageList = SortableContainer(
  ({ values }: { values: ImageListProps }) => {
    const { imageList } = values;
    return (
      <ImageListContainer>
        {imageList.map((image, index) => (
          <ImageWrapper
            key={`item-${image.id}`}
            index={index}
            values={{ image, index, ...values }}
          />
        ))}
      </ImageListContainer>
    );
  }
);
interface Props {
  images: File[];
  imagesSize: number;
  onDelete: (index: number) => void;
  onUpdate: (images: File[]) => void;
}

const ImagesGallery: React.FC<Props> = ({
  images,
  imagesSize,
  onDelete,
  onUpdate,
}) => {
  const [imageList, setImagesList] = useState<Content[]>([]);

  useEffect(() => {
    const formatedImages = [...new Array(imagesSize)].map(
      (_: string, index) => ({
        id: `id-${index}`,
        content: images[index],
      })
    );
    setImagesList(formatedImages);
  }, [images, imagesSize]);

  const updateCallback = (reoderedImages: Content[]) => {
    const images = reoderedImages
      .map((image) => image.content)
      .filter((content): content is File => !!content);
    onUpdate(images);
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;
    if (!imageList[newIndex].content) return;

    const reoderedImages = reorder(imageList, oldIndex, newIndex);
    setImagesList(reoderedImages);
    updateCallback(reoderedImages);
  };

  return (
    <Container>
      {/* distance prop is being used as workaround (based on lib suggestion) to able click at children element (close button)
      https://github.com/clauderic/react-sortable-hoc#click-events-being-swallowed */}
      <ImageList
        values={{ imageList, onDelete }}
        distance={2}
        onSortEnd={onSortEnd}
        axis="xy"
      />
    </Container>
  );
};

export default ImagesGallery;
