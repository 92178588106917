import AuthContext from 'contexts/Auth/context';
import { AllVehiclesQuery, AllVehiclesQueryVariables } from 'generated/graphql';
import allVehicles from 'graphql/queries/allVehicles';
import { useContext } from 'react';
import { formatVehicles } from 'services/formatVehicleRow';
import { useClient } from 'urql';
import { downloadSheetFile } from './helper';

type Vehicles = AllVehiclesQuery['vehicles'];

export const useExportVehicles = () => {
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  const client = useClient();
  return async () => {
    const { data, error } = await client
      .query<AllVehiclesQuery, AllVehiclesQueryVariables>(
        allVehicles,
        {},
        {
          fetchOptions: {
            headers: {
              authorization: `Bearer ${memberAuthToken}`,
              'org-ids': selectedOrganizationIds as any,
            },
          },
          requestPolicy: 'network-only',
        }
      )
      .toPromise();

    const vehicles = data?.vehicles;

    if (!vehicles) return;
    if (error) alert('Erro ao baixar dados');

    const getSheetOf = {
      allVehicles: (vehicles: Vehicles) =>
        vehicles.flatMap(formatVehicles).filter((x) => !!x),
    };

    downloadSheetFile({
      filename: `dados-veiculos-voltbras.xlsx`,
      sheets: [
        {
          data: getSheetOf.allVehicles(vehicles),
          tabName: 'Lista de veículos',
        },
      ],
    });
  };
};
