import { gql } from '@apollo/client';

export default gql`
  query StationOperationInfo($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      name
      compressedId
      chargePointId
      cpoId
      status
      errorCode

      qrCode

      ocppVersion
      ocppUrl
      ocppPublicAccessAllowed
      configPortal {
        url
        user
        password
      }
      routerPortal {
        url
        user
        password
      }
    }
  }
`;
