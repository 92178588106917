import styled from 'styled-components';
import ErrorContainer from 'new-components/ErrorComponent';
import { H6 } from 'new-components/Typographies/styles';
import { padding, space } from 'theme/selectors';

export const Container = styled('section')`
  display: grid;
  gap: ${space('SPACE-48')};
  padding: ${padding('PADDING-32')};
  overflow-y: auto;
`;

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ErrorComponent = styled(ErrorContainer)`
  top: calc(50% - 31px);
`;

export const RowGroup = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: ${space('SPACE-24')};
`;

export const UserInfoRow = styled('section')`
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const InfoGroup = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: ${space('SPACE-8')};
`;
export const InfoLabel = styled(H6)`
  color: ${({
    theme: {
      COLORS: { SECONDARY_GRAY },
    },
  }) => SECONDARY_GRAY};
`;
export const InfoValue = styled('section')`
  display: flex;
  flex-flow: column nowrap;
`;
