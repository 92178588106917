import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationConnectors(
    $where: SelectStationInput!
    $connectors: [ConnectorCreateInput!]!
  ) {
    changeStationConnectors(where: $where, data: { connectors: $connectors })
  }
`;
