import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import Page from 'components/Page';
import {
  CreateStationMutationVariables,
  OrganizationsIdsQuery,
} from 'generated/graphql';
import useNonWorkspaceableAuthQuery from 'hooks/useNonWorkspaceableAuthQuery';
import organizationsIdsQuery from 'graphql/queries/organizationsIds';
import React, { useMemo, useState } from 'react';
import { useAuthMutation } from 'hooks';
import * as uuid from 'uuid';
import Card from 'components/Card';
import createStation from 'graphql/mutations/createStation';
import Select from 'new-components/Select';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  EditButton,
  Row,
  Title,
} from '../StationPage/OwnerInternalUse/OwnerEditStation/styles';

const OwnerCreateStation: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useNonWorkspaceableAuthQuery<OrganizationsIdsQuery>(
    organizationsIdsQuery
  );

  type SetVariablesFunction<Variables> = (
    vars: Partial<Partial<Variables>>
  ) => void;

  type FormProps<Variables> = {
    mutationFile: any;
    type?: 'simple' | 'normal' | 'complex';
    children: (
      setVariables: SetVariablesFunction<Variables>,
      variables: Partial<Variables>
    ) => React.ReactNode;
  };

  const organizationsIds = useMemo(
    () => [
      t('owner-create-station.form.select.placeholder'),
      ...(data?.organizations.map((org) => org.id) ?? []),
    ],
    [data?.organizations] // eslint-disable-line
  );

  function Form<Variables extends {}>({
    children,
    mutationFile,
    type = 'simple',
  }: FormProps<Variables>) {
    const [variables, setVariables] = useState<Variables | {}>({});
    const [callMutation, { loading, error }] = useAuthMutation(mutationFile);

    const tryMutation = async () => {
      try {
        await callMutation({
          variables: { stationId: uuid.v4(), ...variables },
        });
      } catch (e) {
        alert(t('owner-create-station-error-alert.message'));
        console.warn(
          t('owner-create-station-mutation-error.message', {
            error: e,
          })
        );
      }
    };

    return (
      <Card type={type} loading={loading} error={error} padded={20}>
        {children(
          (partialVars) => setVariables({ ...variables, ...partialVars }),
          variables
        )}
        <ButtonContainer>
          <EditButton
            kind={'available'}
            visual={'primary'}
            onClick={tryMutation}
          >
            {t('owner-create-station.form.submit-btn.title')}
          </EditButton>
        </ButtonContainer>
      </Card>
    );
  }

  return (
    <Page>
      <Row>
        <Form<CreateStationMutationVariables>
          mutationFile={createStation}
          type="complex"
        >
          {(setVariables, { ownerId }) => (
            <>
              <Title>{t('owner-create-station.header.title')}</Title>
              <Flex
                mb={8}
                mt={4}
                justifyContent="space-between"
                maxWidth={600}
                flexWrap="wrap"
              >
                <FormControl maxWidth={200}>
                  <FormLabel fontWeight="bold" fontSize="sm">
                    {t('owner-create-station.form.company.title')}
                  </FormLabel>
                  <Select<string>
                    value={ownerId ?? ''}
                    options={organizationsIds}
                    onChange={(ownerId) => setVariables({ ownerId })}
                    data-test="set-owner-id"
                  />
                </FormControl>
                <FormControl maxWidth={200}>
                  <FormLabel fontWeight="bold" fontSize="sm">
                    {t('owner-create-station.form.station-name.title')}
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="String"
                    onChange={(e) => setVariables({ name: e.target.value })}
                  />
                </FormControl>
              </Flex>
            </>
          )}
        </Form>
      </Row>
    </Page>
  );
};
export default OwnerCreateStation;
