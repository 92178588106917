// TODO: if you are coding the "VehicleModal" story, just uncomment all commented lines
// and you'll have a working implementation
import React, { useState } from 'react';
// import VehicleModal from 'new-components/VehicleModal';
import RechargeModal from 'new-components/RechargeModal';
import useHasScopes from 'hooks/useHasScope';
import VehicleModal from 'new-components/VehicleModal';
import Card from './Card';
import { Props as BaseProps, Consumer } from '.';
import UnlockMethod from './UnlockMethod';

type Props = BaseProps & {
  consumer: Extract<Consumer, { __typename: 'Vehicle' }>;
  consumerLabel: string;
  emspId: string;
};

const VehicleCard: React.FC<Props> = ({
  consumer,
  unlockMethod,
  consumerLabel,
  emspId,
}) => {
  const hasScopes = useHasScopes();
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const [rechargeModal, setRechargeModal] = useState<{
    show: boolean;
    sessionId: string;
    cpoId: string;
    emspId: string;
  }>({ show: false, sessionId: '', cpoId: '', emspId: '' });

  const handleSessionRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) => {
    if (!hasScopes(['vehicle:read']) || !sessionId) return;
    setRechargeModal({
      show: true,
      sessionId,
      cpoId: cpoId || '',
      emspId: emspId || '',
    });
  };

  const handleRechargeModalClose = () =>
    setRechargeModal({ show: false, sessionId: '', cpoId: '', emspId: '' });

  return (
    <>
      <VehicleModal
        id={consumer.id}
        handleSessionRowClick={handleSessionRowClick}
        show={hasScopes(['vehicle:read']) && isVehicleModalOpen}
        onClose={() => setIsVehicleModalOpen(false)}
      />

      <RechargeModal
        onClose={handleRechargeModalClose}
        sessionId={rechargeModal.sessionId}
        show={rechargeModal.show}
        cpoId={rechargeModal.cpoId}
        emspId={rechargeModal.emspId}
      />
      <Card
        title="VEÍCULO"
        boldText={consumerLabel}
        bodyText1={consumer.plate}
        bodyText2={consumer.brand}
        bodyText3={consumer.model}
        footer={<UnlockMethod unlockMethod={unlockMethod} />}
        clickable={hasScopes(['vehicle:read'])}
        onClick={() =>
          hasScopes(['vehicle:read']) && setIsVehicleModalOpen(true)
        }
        emspId={emspId}
      />
    </>
  );
};

export default VehicleCard;
