import React from 'react';
import { Container, InnerContainer } from './styles';

interface Props {
  className?: string /* prop que permite aplicar styles usando styled components */;
}

const Page: React.FC<Props> = ({ className, children }) => (
  <Container className={className}>
    <InnerContainer>{children}</InnerContainer>
  </Container>
);

export default Page;
