import React, { useContext } from 'react';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import {
  Box,
  Heading,
  TabList,
  Tabs,
  TabPanel,
  Tab,
  TabPanels,
  Flex,
} from '@chakra-ui/react';
import useHasScopes from 'hooks/useHasScope';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth/context';
import OrderCard from './OrderCard';
import PriceControl from './PriceControl';
import { PaymentsContext } from './context';
import { VoucherProvider } from './Voucher/context';
import { ListVoucherTab } from './Voucher/ListVouchersTab';
import { VoucherDetailsModal } from './Voucher/VoucherDetailsModal';

type TabProps = {
  icon: keyof NotNestedNewIcons;
  text: string;
};

const Payments = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const { currentIndex, setCurrentIndex } = useContext(PaymentsContext);
  const { member } = useContext(AuthContext);

  const tabs = [
    hasScopes(['order:read', 'session:read', 'station:read']) && {
      icon: 'NEW_HISTORY',
      text: t('payments.order-tab.title'),
    },
    hasScopes(['configure-price']) && {
      icon: 'NEW_HOME',
      text: t('payments.price-control-tab.title'),
    },
    member?.role === 'STAFF' &&
      hasScopes(['voucher:create']) && {
        icon: 'NEW_VOUCHER',
        text: t('payments.voucher-tab.title'),
      },
  ] as TabProps[];

  return (
    <Flex w="100%" bg="gray.50" flexDirection="column">
      <Box p="6" pt="7" bg="white">
        <Heading fontSize="2xl">{t('payments.header.title')}</Heading>
      </Box>
      <Tabs
        defaultIndex={currentIndex}
        onChange={(index) => setCurrentIndex(index)}
      >
        <TabList pl="6">
          {tabs
            .filter((tab) => !!tab)
            .map((tab, index) => (
              <Tab data-test={`payment-head-button-${index}`}>
                <Box mr="2">
                  <Icon
                    type={tab.icon}
                    color={
                      index === currentIndex ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'
                    }
                  />
                </Box>
                {tab.text}
              </Tab>
            ))}
        </TabList>
        <TabPanels>
          {hasScopes(['order:read', 'session:read', 'station:read']) && (
            <TabPanel>
              <OrderCard />
            </TabPanel>
          )}
          {hasScopes(['configure-price']) && (
            <TabPanel>
              <PriceControl />
            </TabPanel>
          )}
          {member?.role === 'STAFF' && hasScopes(['voucher:create']) && (
            <TabPanel>
              <VoucherProvider>
                <ListVoucherTab />
                <VoucherDetailsModal />
              </VoucherProvider>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default Payments;
