import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Link } from 'components';
import Config from 'config';
import { FormControl, FormErrorMessage, Input, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ButtonContainer,
  HeaderContainer,
  SuccessContainer,
  SuccessInstructionsContainer,
  SuccessButtonContainer,
  InputContainer,
} from './styles';

import auth from '../../services/auth';

const ForgotPassword = withRouter(({ history }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRequestChangePasswordEmail = async () => {
    if (loading) return;

    setLoading(true);
    try {
      if (!email)
        throw new Error(t('forgot-password.form.empty-input-error-message'));

      const { error } = await auth.requestForgotPasswordEmail(email);

      if (error) throw new Error(error);

      setSuccess(true);
    } catch (error) {
      if (error instanceof Error) {
        setError(
          error.message.includes('inválido')
            ? t('forgot-password.invalid-email-error-message')
            : t('forgot-password.email-sending-error-mesage')
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeEmail = (text: string) => {
    setEmail(text);
    if (text) setError('');
  };

  return (
    <Container>
      {success ? (
        <SuccessContainer>
          <Config.ICONS.CHECK_THIN width={170} height={170} />
          <SuccessInstructionsContainer>
            <Typography color="SECONDARY_GRAY">
              {t('forgot-password.header.success-message')}
            </Typography>
          </SuccessInstructionsContainer>
          <SuccessButtonContainer>
            <Button
              data-test="success-back-login-button"
              size="sm"
              w="100%"
              onClick={() => history.push('/login')}
            >
              {t('forgot-password.go-back-login-link.title')}
            </Button>
          </SuccessButtonContainer>
        </SuccessContainer>
      ) : (
        <>
          <HeaderContainer>
            <Typography weight="bold" size={35}>
              {t('forgot-password.header.title')}
            </Typography>
            <Typography color="SECONDARY_GRAY">
              {t('forgot-password.header.subtitle')}
            </Typography>
          </HeaderContainer>
          <InputContainer>
            <FormControl isInvalid={!!error}>
              <Input
                data-test="forgot-password-input"
                focusBorderColor="none"
                type="text"
                placeholder={t('forgot-password.form.input.placeholder')}
                value={email}
                onChange={(event) => onChangeEmail(event.target.value)}
              />
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
          </InputContainer>
          <ButtonContainer>
            <Button
              data-test="submit-forgot-password"
              w="100%"
              size="lg"
              isLoading={loading}
              onClick={handleRequestChangePasswordEmail}
            >
              {t('forgot-password.form.submit-btn.title')}
            </Button>
          </ButtonContainer>
          <Link to="/login" size={14}>
            {t('forgot-password.go-back-login-link.title')}
          </Link>
        </>
      )}
    </Container>
  );
});

export default ForgotPassword;
