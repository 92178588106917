import styled from 'styled-components';

export const Card = styled('section')`
  flex-basis: 100%;
  display: flex;
  gap: 4px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-radius: 8px;
`;
