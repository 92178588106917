import { Auth } from '@voltbras/auth-client';
import Config from 'config';
import { FirebaseAuth } from './identity-provider/firebaseAuth';

const getAuth = (): Auth => {
  switch (Config.CODE) {
    case 'edp':
    case 'voltbras':
    case 'neoenergia':
    case 'demo':
    case 'ipiranga':
    case 'plug-r':
    case 'neocharge':
    case 'green-yellow':
    case 'recar':
    case 'greenea':
    case 'wecharge':
    case 'ecodot':
    case 'greenspace':
    case 'pontoon':
    case 'chargeon':
    case 'ende-guaracachi':
    case 'evmobi':
    case 'full-charge':
    case 'helte-energy':
    case 'sp-charge':
    case 'evpv-power':
    case 'emobility-solutions':
    case 'ev':
    case 'vox-company':
    case 'ypf':
    case 'ion-mobilidade':
    case 'on-mobility':
    case 'canaa':
    case 'equatorial':
    case 'solarprime':
    case 'now-charge':
    case 'silis-mobility':
    case 'angulo-solucoes':
    case 'e-mog':
    case 'ipe-carregadores':
      return FirebaseAuth;
  }
};

const auth: Auth = getAuth();

export default auth;
