import React, { ReactNode } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import Config from 'config';
import { useTranslation } from 'react-i18next';
import ArchiveVoucherModal from './containers/ArchiveVoucherModal';
import { VoucherTableFilter } from './containers/VouchersFilters';
import { VouchersTable } from './containers/VoucherTable';
import { useListVouchers } from './service';

const ListVoucherContainer: React.FC<{ children: ReactNode }> = ({
  children,
}) => (
  <Flex direction="column">
    <Box
      bg="white"
      borderWidth="thin"
      borderColor="gray.100"
      borderRadius={8}
      maxWidth="full"
      overflowX="auto"
      minHeight={'2xl'}
      height={'auto'}
      overflowY="hidden"
    >
      <Flex flexDirection="column" h={'full'}>
        {children}
      </Flex>
    </Box>
  </Flex>
);

export function ListVoucherTab() {
  const { t } = useTranslation();
  const { showCreateVoucherButton, openCreateVoucherScreen } =
    useListVouchers();

  return (
    <ListVoucherContainer>
      <Flex w="full" px={10} py={4} justify={'space-between'} align={'center'}>
        <Heading fontSize={'md'} color={Config.COLORS.PRIMARY_GRAY}>
          {t('payments.voucher-tab.table-header.title')}
        </Heading>
        <Flex>
          <VoucherTableFilter />
          {showCreateVoucherButton && (
            <Button
              data-testid={'create-voucher-btn'}
              size="sm"
              ml="auto"
              onClick={openCreateVoucherScreen}
            >
              {t('payments.voucher-tab.table-header-btn.title')}
            </Button>
          )}
        </Flex>
      </Flex>
      <VouchersTable />
      <ArchiveVoucherModal />
    </ListVoucherContainer>
  );
}
