import ErrorContainer from 'new-components/ErrorComponent';
import NewModal from 'new-components/NewModal';
import styled from 'styled-components';
import {
  FormLabel as FormLabelChakra,
  FormErrorMessage as FormErrorMessageChakra,
  FormErrorMessageProps,
  FormLabelProps,
  chakra,
  Tag as TagChakra,
} from '@chakra-ui/react';

export const Container = styled(NewModal)``;
export const ErrorComponent = styled(ErrorContainer)`
  top: calc(50% - 31px);
`;
export const Body = styled('section')`
  background-color: ${({
    theme: {
      COLORS: { BACKGROUND },
    },
  }) => BACKGROUND};
  max-height: 74vh;
  flex: 1;
`;

export const FormLabel = chakra(FormLabelChakra, {
  baseStyle: {
    color: '#939393',
    fontWeight: 'normal',
    lineHeight: '12px',
    fontSize: '10px',
    paddingBottom: '8px',
  } as FormLabelProps,
});

export const Tag = chakra(TagChakra, {
  baseStyle: {
    color: 'gray.900',
    cursor: 'pointer',
    _active: {
      backgroundColor: 'gray.200',
    },
  },
});

export const FormErrorMessage = chakra(FormErrorMessageChakra, {
  baseStyle: {
    lineHeight: '14.06px',
    fontSize: '12px',
    paddingLeft: '2px',
  } as FormErrorMessageProps,
});
