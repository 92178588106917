import styled from 'styled-components';

import loadingComponent from 'new-components/LoadingComponent';

export const Container = styled.div`
  padding: 16px;
  overflow: auto;
`;

export const RowContainer = styled.div`
  padding-bottom: 12px;
`;

export const LoadingComponent = styled(loadingComponent)`
  position: inherit;
`;
