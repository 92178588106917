import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Size } from './size';

const DIALOG_CLASS_NAME = 'custom-dialog';

const sizeStyles: Record<Size, string> = {
  sm: `
    width: 94%;
    max-width: 500px;
  `,
  md: `
    width: 675px;
    max-width: 675px;
`,
  lg: `
    width: 1200px;
    max-width: 1200px;

    height: calc(100vh - 24px - 24px);
    margin: auto;
    margin-top: 24px;
  `,
};

export const ModalContainer = styled(Modal).attrs({
  dialogClassName: DIALOG_CLASS_NAME,
})<{ size: Size; overflow?: string }>`
  background: ${({ theme: { COLORS } }) => `${COLORS.PRIMARY_GRAY}66`};
  transition: opacity 0.4s linear;

  .${DIALOG_CLASS_NAME} {
    ${({ size }) => sizeStyles[size]}
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    transition-delay: 0.4s;
  }

  // child of the custom dialog
  .modal-content {
    height: 100%;

    border-radius: 12px;
    overflow: ${({ overflow }) => overflow ?? 'hidden'};

    background: ${({ theme }) => theme.COLORS.WHITE};
    box-sizing: border-box;
    border: none;
  }
`;
