import { Text } from '@chakra-ui/react';
import Dropdown from 'new-components/Dropdown';
import * as uuid from 'uuid';

export type Props = {
  onClick: () => void;
  option: string;
};

export function DropdownOption({ onClick, option }: Props) {
  return (
    <Dropdown.Section key={uuid.v4()}>
      <Dropdown.Row
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        <Text>{option}</Text>
      </Dropdown.Row>
    </Dropdown.Section>
  );
}
