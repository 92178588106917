import {
  PinInputFieldProps,
  PinInputField as PinInputFieldChakra,
  chakra,
} from '@chakra-ui/react';
import Config from 'config';
import ErrorContainer from 'new-components/ErrorComponent';
import NewModal from 'new-components/NewModal';
import styled from 'styled-components';

export const Container = styled(NewModal)``;
export const ErrorComponent = styled(ErrorContainer)`
  top: calc(50% - 31px);
`;
export const Body = styled('section')`
  background-color: ${({
    theme: {
      COLORS: { BACKGROUND },
    },
  }) => BACKGROUND};
  max-height: 74vh;
  flex: 1;
`;

export const Header = styled('section')`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 24px 24px 0 24px;
`;

export const PinInputField = chakra(PinInputFieldChakra, {
  baseStyle: {
    fontSize: '5xl',
    color: 'gray.900',
    placeholder: 'custom placeholder',
    border: 'none',
    borderRadius: 'none',
    borderBottom: `2px solid ${Config.COLORS.PRIMARY_GRAY}`,
    _focusVisible: { boxShadow: 'none', borderBottomColor: 'gray.900' },
    colorScheme: 'gray',
    paddingBottom: '5px',
    _hover: { borderBottomColor: 'gray.700', color: 'gray.700' },
  } as PinInputFieldProps,
});
