import React from 'react';
import SquareButton from 'components/SquareButton';
import Config from 'config';
import { useTranslation } from 'react-i18next';

interface Props {
  toggleFilterPanel: () => void;
  active: boolean;
}

const FilterButton: React.FC<Props> = ({ active, toggleFilterPanel }) => {
  const { t } = useTranslation();

  return (
    <SquareButton
      mouseoverDescription={t('filter.mouse-over-title')}
      onClick={toggleFilterPanel}
      hasActiveState={active}
      data-test="filter-button"
    >
      <Config.ICONS.FILTER />
    </SquareButton>
  );
};

export default FilterButton;
