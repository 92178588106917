import { Box, Flex } from '@chakra-ui/react';

const VerticalLine = ({ left }: { left: number | string }) => (
  <Flex
    zIndex={1}
    left={left}
    height="full"
    borderLeftColor="gray.200"
    borderLeftWidth="thin"
    align="center"
    position="absolute"
  >
    <Box w={1} h="px" bg="gray.200" />
  </Flex>
);

export { VerticalLine };
