import { useEffect, useRef, useState } from 'react';

export const useCooldownService = () => {
  const loginCooldownTimeoutInSeconds = 10;
  const [loginCooldownTimeoutCount, setLoginCooldownTimeoutCount] = useState(
    loginCooldownTimeoutInSeconds
  );
  const [loginCooldownError, setLoginCooldownError] = useState(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const resetCooldown = () => {
    setLoginCooldownTimeoutCount(loginCooldownTimeoutInSeconds);
    setLoginCooldownError(false);
  };

  const startLoginCooldownTimeout = () => {
    setLoginCooldownError(true);
  };

  const whenLoginCooldownTimeoutEnds = (callback: () => void = () => {}) => {
    if (loginCooldownTimeoutCount === 0) {
      setTimeout(() => {
        callback();
      }, 1000);
    }
  };

  useEffect(() => {
    if (loginCooldownError) {
      if (loginCooldownTimeoutCount === loginCooldownTimeoutInSeconds) {
        intervalRef.current = setInterval(() => {
          setLoginCooldownTimeoutCount((cooldownCount) => {
            if (intervalRef.current && cooldownCount === 0) {
              clearInterval(intervalRef.current);
              resetCooldown();
              return loginCooldownTimeoutInSeconds;
            }
            return cooldownCount - 1;
          });
        }, 1000);
      }
    }
    return () => {
      if (intervalRef.current) return clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line
  }, [loginCooldownError]);

  return {
    loginCooldownError,
    loginCooldownTimeoutCount,
    startLoginCooldownTimeout,
    whenLoginCooldownTimeoutEnds,
  };
};
