import Page from 'components/Page';
import MapCard from 'pages/Map/MapCard';
import React from 'react';
import { MapContainer } from './styles';

const Map: React.FC = () => (
  <Page>
    <MapContainer>
      <MapCard />
    </MapContainer>
  </Page>
);

export default Map;
