import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { Organization } from 'contexts/Auth/context';
import React, { useCallback, useMemo, useState } from 'react';
import NewDropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import {
  checkIfMemberBelongsToParentOrganization,
  OrganizationTree,
  treeStructureOrganizations,
} from 'services/multiorg';
import { MotionBox, NewDropdownContainer, NewDropdownSection } from './styles';
import { getRowPaddingLeft } from './function';
import Row from './row';

type Props = {
  setSelectedOrganization: (org: { id: string; name: string }) => void;
  organizations: Organization[];
  value: string;
  isInvalid?: boolean;
};

export const SelectOrganizationDropdown = ({
  setSelectedOrganization,
  organizations,
  value,
  isInvalid = false,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedOrganizationIds, setExpandedOrganizationIds] = useState<
    string[] | undefined
  >(undefined);

  const handleChangeExpandedOrganizationIds = (orgId: string) => {
    if (expandedOrganizationIds?.includes(orgId)) {
      setExpandedOrganizationIds((state) => state?.filter((s) => s !== orgId));
    } else {
      setExpandedOrganizationIds((state) =>
        state?.length ? [...state, orgId] : [orgId]
      );
    }
  };

  const handleSelectOrganizationDropdownRowPress = (
    orgId: string,
    orgName: string
  ) => {
    setSelectedOrganization({
      id: orgId,
      name: orgName,
    });
    setIsDropdownOpen(false);
  };

  const structuredOrganizationsTree = useMemo(
    () => treeStructureOrganizations(organizations ?? []),
    [organizations]
  );

  const checkIfAllParentOrganizationsIsExpanded = useCallback(
    (
      organization: OrganizationTree,
      finalOrganizationIds?: string[]
    ): boolean => {
      const isMemberBelongsToParentOrganization =
        checkIfMemberBelongsToParentOrganization(
          organizations ?? [],
          organization.parentOrganizationId
        );
      if (
        organization.parentOrganizationId &&
        isMemberBelongsToParentOrganization
      ) {
        const parentOrganization = organizations.find(
          (o) => o.id === organization.parentOrganizationId
        );

        if (parentOrganization) {
          return checkIfAllParentOrganizationsIsExpanded(
            parentOrganization,
            finalOrganizationIds?.length
              ? [...finalOrganizationIds, organization.parentOrganizationId]
              : [organization.parentOrganizationId]
          );
        }
      }
      const necessaryExpandedOrganizationIdsForRowToBeVisible =
        finalOrganizationIds?.length ? finalOrganizationIds : [];

      const isAllNecessaryOrganizationsExpanded =
        necessaryExpandedOrganizationIdsForRowToBeVisible.every((o) =>
          expandedOrganizationIds?.includes(o)
        );

      return isAllNecessaryOrganizationsExpanded;
    },
    [expandedOrganizationIds, organizations]
  );

  const getOrganizationComponents = (
    organization: OrganizationTree,
    index: number = 0
  ) => {
    const isRowOpen = checkIfAllParentOrganizationsIsExpanded(organization);
    if (organization?.suborganizations?.length) {
      return (
        <>
          {isRowOpen && (
            <Row
              onClickToExpandSuborganizations={() =>
                handleChangeExpandedOrganizationIds(organization.id)
              }
              organization={organization}
              paddingLeft={getRowPaddingLeft(
                !!organization.parentOrganizationId,
                index
              )}
              onClickToSelectOrganization={() =>
                handleSelectOrganizationDropdownRowPress(
                  organization.id,
                  organization.name
                )
              }
              isExpanded={
                expandedOrganizationIds?.includes(organization.id) ?? false
              }
            />
          )}
          <Box>
            {organization.suborganizations
              .map((suborganizations) =>
                getOrganizationComponents(suborganizations, index + 1)
              )
              .map((component) => (
                <>{component}</>
              ))}
          </Box>
        </>
      );
    }

    return isRowOpen ? (
      <Row
        onClickToExpandSuborganizations={() =>
          handleChangeExpandedOrganizationIds(organization.id)
        }
        organization={organization}
        paddingLeft={getRowPaddingLeft(
          !!organization.parentOrganizationId,
          index
        )}
        onClickToSelectOrganization={() =>
          handleSelectOrganizationDropdownRowPress(
            organization.id,
            organization.name
          )
        }
        isExpanded={expandedOrganizationIds?.includes(organization.id) ?? false}
        position="relative"
      />
    ) : (
      <></>
    );
  };

  return (
    <Box minW={56}>
      <MotionBox initial="hidden" animate="visible">
        <NewDropdown
          isOpen={isDropdownOpen}
          toggleDropdown={setIsDropdownOpen}
          onClickOutside={() => setIsDropdownOpen(false)}
          isBeingUsedInsideModal
          content={() => (
            <NewDropdownContainer>
              <>
                <NewDropdownSection>
                  {structuredOrganizationsTree.map(getOrganizationComponents)}
                </NewDropdownSection>
              </>
            </NewDropdownContainer>
          )}
        >
          <Box
            borderWidth={isInvalid ? 2 : 1}
            borderColor={isInvalid ? 'error.500' : 'gray.100'}
            borderRadius="lg"
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsDropdownOpen((state) => !state);
            }}
            px={4}
            h={10}
            lineHeight={10}
          >
            <Flex justifyContent="space-between" alignItems="center" w="full">
              <Text maxWidth={40} fontSize="sm" noOfLines={1}>
                {value}
              </Text>
              <IconButton
                pl={2}
                size="sm"
                aria-label="Abrir"
                variant="unstyled"
                icon={
                  <Icon
                    type={
                      isDropdownOpen ? 'NEW_CHEVRON_DOWN' : 'NEW_CHEVRON_RIGHT'
                    }
                    size={20}
                    color="PRIMARY_GRAY"
                  />
                }
              />
            </Flex>
          </Box>
        </NewDropdown>
      </MotionBox>
    </Box>
  );
};
