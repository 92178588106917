import DialogModal from 'atomic-components/organisms/DialogModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  FormControl,
  Box,
  Heading,
  Button,
} from '@chakra-ui/react';
import config from 'config';
import NumberFormat from 'react-number-format';
import { moneyMask } from 'pages/Payments/PriceControl/functions';
import { formatNumber } from 'services/format';
import { toast } from 'react-toastify';
import { useAuthMutation } from 'hooks';
import {
  GenerateBankSlipPaymentToWalletMutation,
  GenerateBankSlipPaymentToWalletMutationVariables,
  PayerAddress,
} from 'generated/graphql';
import generateBankSlipPaymentToWalletMutation from 'graphql/mutations/generateBankSlipPaymentToWallet';
import { validateEmail } from 'services/validation';
import { getAddress } from 'services/cep';
import { FormLabel, Tag, FormErrorMessage } from './styles';
import SucessGeneratingBankSlip from '../SucessGeneratingBankSlip';
import {
  formatCentsToReal,
  hasSomeInputEmpty,
  IsInputValueUnderMinimumValue,
} from './functions';

type Props = {
  onClose: () => void;
  show: boolean;
  minimumWalletDepositAmount?: number;
  availableBalance?: number;
  driverId?: number;
};

const INITIAL_PAYER_ADDRESS_STATE = {
  city: '',
  country: 'Brasil',
  neighborhood: '',
  postalCode: '',
  state: '',
  street: '',
  streetNumber: '',
};

const ValuesOptions = [5000, 10000, 15000, 20000, 30000];

const DepositToWalletModal = ({
  show,
  onClose,
  minimumWalletDepositAmount,
  availableBalance,
  driverId,
}: Props) => {
  const [amountInputValue, setAmountInputValue] = useState<number>(0);
  const [payerNameInputValue, setPayerNameInputValue] = useState<string>('');
  const [payerEmailInputValue, setPayerEmailInputValue] = useState<string>('');
  const [payerCpfCnpjInputValue, setPayerCpfCnpjInputValue] =
    useState<string>('');
  const [payerAddress, setPayerAddress] = useState<PayerAddress>(
    INITIAL_PAYER_ADDRESS_STATE
  );
  const [selectedCpfCnpj, setSelectedCpfCnpj] = useState<string>('');

  const allInputValues = {
    address: payerAddress,
    amount: amountInputValue,
    profile: {
      name: payerNameInputValue,
      CPFCNPJ: payerCpfCnpjInputValue,
      email: payerEmailInputValue,
    },
  };

  const isButtonDisabled = hasSomeInputEmpty(allInputValues);

  const [openSucessBankSlipModal, setOpenSucessBankSlipModal] =
    useState<boolean>(false);

  const [isInputCurrencyFocused, setIsInputCurrencyFocused] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const handleChangeCEPText = async (value: string) => {
    if (!value.match(/^\d+$/)) return;
    setPayerAddress((state) => ({
      ...state,
      postalCode: value,
    }));
    if (value.length === 8) {
      const { address, errorMessage } = await getAddress(value);
      if (errorMessage) return;
      setPayerAddress((state) => ({
        ...state,
        city: address?.city ?? '',
        state: address?.state ?? '',
        street: address?.street ?? '',
        neighborhood: address?.neighborhood ?? '',
      }));
    }
  };

  const formatedAvailableBalance = formatCentsToReal(availableBalance ?? 0);
  const newFormatedAvailableBalance = formatNumber(formatedAvailableBalance);
  const formatedAfterDepositBalanceValue = formatNumber(
    formatedAvailableBalance + formatCentsToReal(amountInputValue)
  );
  const isEmailValid =
    payerEmailInputValue === '' ? false : !validateEmail(payerEmailInputValue);
  const [generateBankSlipPaymentToWallet, { loading, data, error }] =
    useAuthMutation<
      GenerateBankSlipPaymentToWalletMutation,
      GenerateBankSlipPaymentToWalletMutationVariables
    >(generateBankSlipPaymentToWalletMutation);

  if (!minimumWalletDepositAmount) return null;

  const cpfOrCnpjInvalid =
    error?.message ===
    '[GraphQL] Some error happened while creating invoice: {"errors":{"payer.cpf_cnpj":["não é válido"]}}422';

  function emptyAllInputsState() {
    setAmountInputValue(0);
    setPayerNameInputValue('');
    setPayerEmailInputValue('');
    setPayerCpfCnpjInputValue('');
    setPayerAddress(INITIAL_PAYER_ADDRESS_STATE);
    setSelectedCpfCnpj('');
  }

  const handleGenerateBankSlipPaymentButtonPress = async () => {
    if (!driverId) return;
    try {
      await generateBankSlipPaymentToWallet({
        variables: {
          data: {
            amount: amountInputValue,
            payer: {
              CPFCNPJ: payerCpfCnpjInputValue,
              email: payerEmailInputValue,
              name: payerNameInputValue,
              address: payerAddress,
            },
            driverId,
          },
        },
      });
      toast.success(
        t('deposit-to-wallet-modal.form.toast-sucess', {
          user_name: payerNameInputValue,
          pix_or_boleto: 'boleto',
        })
      );
      emptyAllInputsState();
      setOpenSucessBankSlipModal(true);
    } catch {
      toast.error('Infelizmente, houve um erro inesperado ao gerar o boleto.');
    }
  };

  const showInvalidAmountInputError =
    IsInputValueUnderMinimumValue(
      minimumWalletDepositAmount,
      amountInputValue
    ) && !isInputCurrencyFocused;

  return (
    <>
      <DialogModal
        header={{
          title: t('deposit-to-wallet-modal.form.title'),
        }}
        cancelButton={{
          text: t('deposit-to-wallet-modal.form.cancel-button'),
          onClick: () => {
            onClose();
          },
        }}
        visible={show}
        onClose={() => {
          onClose();
        }}
        submitButton={{
          colorScheme: 'primary',
          onPress: async () => {
            await handleGenerateBankSlipPaymentButtonPress();
          },
          text: t('deposit-to-wallet-modal.form.generate-bank-slip-button'),
          disabled: isButtonDisabled,
          loading,
        }}
        size="md"
      >
        <HStack align="center" paddingLeft={5}>
          <VStack align={'left'}>
            <FormControl isInvalid={showInvalidAmountInputError}>
              <HStack spacing={'24'}>
                <Box>
                  <Heading
                    paddingBottom={'16px'}
                    lineHeight={'5'}
                    fontSize={'md'}
                  >
                    {t('deposit-to-wallet-modal.form.add-balance-section')}
                  </Heading>
                  <FormLabel>
                    {t('deposit-to-wallet-modal.form.input-title')}
                  </FormLabel>
                  <Box>
                    <Box height={'60px'}>
                      <InputGroup>
                        <InputLeftAddon
                          borderColor={
                            isInputCurrencyFocused
                              ? 'primary.500'
                              : showInvalidAmountInputError
                              ? 'red.500'
                              : 'gray.100'
                          }
                          backgroundColor={'white'}
                          children="R$"
                          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                          style={{
                            boxShadow: isInputCurrencyFocused
                              ? `0 0 0 1px ${config.COLORS.PRIMARY}`
                              : showInvalidAmountInputError
                              ? `0 0 0 1px ${config.COLORS.ERROR}`
                              : 'none',
                          }}
                        />
                        <NumberFormat
                          maxLength={7}
                          width={'340px'}
                          borderLeftRadius={'none'}
                          placeholder={t(
                            'deposit-to-wallet-modal.form.input-placeholder'
                          )}
                          value={amountInputValue}
                          onValueChange={(value) =>
                            setAmountInputValue(value.floatValue ?? 0)
                          }
                          customInput={Input}
                          format={moneyMask}
                          onFocus={() => setIsInputCurrencyFocused(true)}
                          onBlur={() => setIsInputCurrencyFocused(false)}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {t(
                          'deposit-to-wallet-modal.form.minimum -wallet-deposit',
                          {
                            minimumWalletDeposit: formatNumber(
                              formatCentsToReal(minimumWalletDepositAmount)
                            ),
                          }
                        )}
                      </FormErrorMessage>
                    </Box>
                    <HStack marginTop={'8px'} spacing={'2.5'}>
                      {ValuesOptions.map((options, index) => (
                        <Tag
                          onClick={() => setAmountInputValue(options)}
                          key={index}
                          colorScheme="gray"
                          variant="outline"
                          size="lg"
                        >
                          {formatCentsToReal(options)},00
                        </Tag>
                      ))}
                    </HStack>
                  </Box>
                </Box>
                <Box>
                  <VStack align={'left'} spacing={'2.5'}>
                    <Text color="gray.500" fontSize={'10px'}>
                      {t('deposit-to-wallet-modal.form.current-balance')}
                    </Text>
                    <Text lineHeight={'14px'} fontSize="md">
                      {t('deposit-to-wallet-modal.form.current-balance-value', {
                        balanceValue: newFormatedAvailableBalance,
                      })}
                    </Text>
                    <Text color="gray.500" fontSize={'10px'}>
                      {t('deposit-to-wallet-modal.form.balance-after-deposit')}
                    </Text>
                    <Text lineHeight={'14px'} fontSize="md">
                      {t(
                        'deposit-to-wallet-modal.form.balance-after-deposit-value',
                        {
                          afterDepositBalanceValue:
                            formatedAfterDepositBalanceValue,
                        }
                      )}
                    </Text>
                  </VStack>
                </Box>
              </HStack>
            </FormControl>
            <Box>
              <Heading paddingTop={12} lineHeight={5} fontSize={'md'}>
                {t('deposit-to-wallet-modal.form.title-payer-data')}
              </Heading>
              <Text
                paddingTop={2}
                fontWeight={'normal'}
                lineHeight={'base'}
                fontSize={'sm'}
              >
                {t('deposit-to-wallet-modal.form.sub-title-payer-data')}
              </Text>
              <FormControl>
                <FormLabel pt={4}>
                  {t('deposit-to-wallet-modal.form.name-input')}
                </FormLabel>
                <Input
                  onChange={(event) =>
                    setPayerNameInputValue(event.target.value)
                  }
                  width="full"
                  value={payerNameInputValue}
                />
              </FormControl>
              <FormControl isInvalid={cpfOrCnpjInvalid}>
                <FormLabel pt={4}>
                  {t('deposit-to-wallet-modal.form.cpf-cnpj-number')}
                </FormLabel>
                <InputGroup>
                  <Button
                    width={'48px'}
                    height={'32px'}
                    marginRight={'8px'}
                    borderColor={'TERTIARY_GRAY'}
                    borderWidth={'1px'}
                    _active={{
                      borderColor: 'blue.500',
                      borderWidth: '2px',
                    }}
                    isActive={selectedCpfCnpj === 'CPF'}
                    onClick={(event) =>
                      setSelectedCpfCnpj(event.currentTarget.value)
                    }
                    colorScheme="teal"
                    variant="ghost"
                    size="sm"
                    value={'CPF'}
                  >
                    CPF
                  </Button>
                  <Button
                    borderColor={'TERTIARY_GRAY'}
                    borderWidth={'1px'}
                    marginRight={'8px'}
                    width={'48px'}
                    height={'32px'}
                    _active={{
                      borderColor: 'blue.500',
                      borderWidth: '2px',
                    }}
                    isActive={selectedCpfCnpj === 'CNPJ'}
                    onClick={(event) =>
                      setSelectedCpfCnpj(event.currentTarget.value)
                    }
                    colorScheme="teal"
                    variant="ghost"
                    size="sm"
                    value={'CNPJ'}
                  >
                    CNPJ
                  </Button>
                  <NumberFormat
                    format={
                      selectedCpfCnpj === 'CPF'
                        ? '###.###.###-##'
                        : '##.###.###/####-##'
                    }
                    mask="_"
                    allowEmptyFormatting
                    value={payerCpfCnpjInputValue}
                    onValueChange={(values) => {
                      setPayerCpfCnpjInputValue(values.formattedValue);
                    }}
                    label={t(
                      'edit-session-modal.body.duration-input.placeholder'
                    )}
                    customInput={Input}
                  />
                </InputGroup>
                <FormErrorMessage marginLeft={'28'}>
                  {selectedCpfCnpj === 'CPF'
                    ? `${t('deposit-to-wallet-modal.form.invalid-cpf')}`
                    : `${t('deposit-to-wallet-modal.form.invalid-cnpj')}`}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={isEmailValid}>
                <FormLabel pt={4}>
                  {t('deposit-to-wallet-modal.form.e-mail')}
                </FormLabel>
                <Box height={'38px'}>
                  <Input
                    onChange={(event) => {
                      setPayerEmailInputValue(event.target.value);
                    }}
                    value={payerEmailInputValue}
                  />
                  <FormErrorMessage>
                    {t(
                      'deposit-to-wallet-modal.form.error-message-invalid-email'
                    )}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </Box>
            <Box>
              <Heading paddingTop={12} lineHeight={5} fontSize={'md'}>
                {t('deposit-to-wallet-modal.form.payment-adress')}
              </Heading>
              <HStack>
                <FormControl>
                  <FormLabel pt={4}>
                    {t('deposit-to-wallet-modal.form.cep')}
                  </FormLabel>
                  <Input
                    maxLength={9}
                    onChange={(event) =>
                      handleChangeCEPText(event.target.value)
                    }
                    value={payerAddress.postalCode}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel pt={4}>
                    {t('deposit-to-wallet-modal.form.adress')}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(event) =>
                        setPayerAddress((state) => ({
                          ...state,
                          street: event.target.value,
                        }))
                      }
                      value={payerAddress.street}
                    />
                  </InputGroup>
                </FormControl>
              </HStack>
              <HStack>
                <FormControl>
                  <FormLabel pt={4}>
                    {t('deposit-to-wallet-modal.form.house-number')}
                  </FormLabel>
                  <Input
                    onChange={(event) =>
                      setPayerAddress((state) => ({
                        ...state,
                        streetNumber: event.target.value,
                      }))
                    }
                    value={payerAddress.streetNumber}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel pt={4}>
                    {t(
                      'deposit-to-wallet-modal.form.aditional-info-about-house'
                    )}
                  </FormLabel>
                  <Input
                    onChange={(event) =>
                      setPayerAddress((state) => ({
                        ...state,
                        complement: event.target.value,
                      }))
                    }
                    value={payerAddress.complement}
                  />
                </FormControl>
              </HStack>
              <HStack>
                <FormControl>
                  <FormLabel pt={4}>
                    {t('deposit-to-wallet-modal.form.neighborhood')}
                  </FormLabel>
                  <Input
                    onChange={(event) => {
                      setPayerAddress((state) => ({
                        ...state,
                        neighborhood: event.target.value,
                      }));
                    }}
                    value={payerAddress.neighborhood}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel pt={4}>
                    {t('deposit-to-wallet-modal.form.city')}
                  </FormLabel>
                  <Input
                    onChange={(event) =>
                      setPayerAddress((state) => ({
                        ...state,
                        city: event.target.value,
                      }))
                    }
                    value={payerAddress.city}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel pt={4}>
                    {t('deposit-to-wallet-modal.form.state')}
                  </FormLabel>
                  <Input
                    onChange={(event) =>
                      setPayerAddress((state) => ({
                        ...state,
                        state: event.target.value,
                      }))
                    }
                    value={payerAddress.state}
                  />
                </FormControl>
              </HStack>
            </Box>
          </VStack>
        </HStack>
      </DialogModal>
      {data?.generateBankSlipPaymentToWallet.secureUrl && (
        <SucessGeneratingBankSlip
          onClose={() => {
            onClose();
            setOpenSucessBankSlipModal(!openSucessBankSlipModal);
          }}
          show={openSucessBankSlipModal}
          bankSlipLink={data.generateBankSlipPaymentToWallet.secureUrl}
        />
      )}
    </>
  );
};

export default DepositToWalletModal;
