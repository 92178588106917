import React, { useContext, useEffect } from 'react';
import { VStack, HStack, Flex, Box, ScaleFade } from '@chakra-ui/react';
import Button from 'atomic-components/atoms/Button';
import Checkbox from 'atomic-components/atoms/Checkbox';
import Card from 'atomic-components/atoms/Card';
import { Namespace, TFunction } from 'react-i18next';
import { StationMonitoringStatus } from 'pages/Monitoring/types';
import MonitoringContext from 'contexts/monitoring/context';
import {
  HandleButtonDisabledArgs,
  HandleChangePreStatusArgs,
  HandleSaveStatusSelectedArgs,
  HandleStatusTextSelectArgs,
} from '../helper';
import {
  CustomScaleFadeStyles,
  HeadingTitle,
  PaddingApplyButton,
  PaddingSubTitle,
  TextSubTitle,
} from './styles';

export type ContainerFilterContainerProps = {
  setStatusSelected: (
    statusSelected: StationMonitoringStatus[] | undefined
  ) => void;
  t: TFunction<Namespace<'common'>, undefined>;
  helper: {
    handleChangePreStatusSelected: ({
      newEvent,
      preStatusSelected,
      setPreStatusSelected,
    }: HandleChangePreStatusArgs) => void;
    handleStatusTextSelect: ({
      status,
      t,
    }: HandleStatusTextSelectArgs) => string;
    handleButtonDisabled: ({
      preStatusSelected,
      setButtonDisabled,
      stationsMonitoringStatus,
      statusSelected,
    }: HandleButtonDisabledArgs) => void;
    handleSaveStatusSelected: ({
      preStatusSelected,
      setStatusSelected,
    }: HandleSaveStatusSelectedArgs) => void;
  };
  statusSelected: StationMonitoringStatus[] | undefined;
  onCloseModal: () => void;
  isOpen: boolean;
};

const FilterContainer: React.FC<ContainerFilterContainerProps> = ({
  setStatusSelected,
  helper,
  t,
  statusSelected,
  onCloseModal,
  isOpen,
}) => {
  document.onkeydown = (keyboard) => {
    if (keyboard.key === 'Escape') {
      onCloseModal();
    }
  };
  const {
    preStatusSelected,
    setPreStatusSelected,
    buttonDisabled,
    setButtonDisabled,
  } = useContext(MonitoringContext);

  const stationsMonitoringStatus: StationMonitoringStatus[] = [
    'AVAILABLE',
    'CHARGING',
    'INOPERATIVE',
    'UNKNOWN',
  ];

  if (preStatusSelected === undefined) setPreStatusSelected([]);

  const handleButtonDisabled = (disabled: boolean) => {
    setButtonDisabled(disabled);
  };
  useEffect(() => {
    helper.handleButtonDisabled({
      preStatusSelected,
      setButtonDisabled: handleButtonDisabled,
      stationsMonitoringStatus,
      statusSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    helper,
    preStatusSelected,
    setButtonDisabled,
    setStatusSelected,
    statusSelected,
  ]);

  const handleSaveStatusSelectedButtonPress = () => {
    helper.handleSaveStatusSelected({
      preStatusSelected,
      setStatusSelected,
    });
    onCloseModal();
  };

  const handleRemoveStatusSelectedButtonPress = () => {
    helper.handleSaveStatusSelected({
      preStatusSelected: [],
      setStatusSelected,
    });
    setPreStatusSelected([]);
    onCloseModal();
  };

  const filter = (
    <Box marginTop={'4'}>
      <Card minHeight={'246px'} width={'100%'}>
        <VStack align={'left'}>
          <HeadingTitle>{t('monitoring.filterCard.title')}</HeadingTitle>
          <PaddingSubTitle>
            <TextSubTitle>
              {t('monitoring.filterCard.filter-status')}
            </TextSubTitle>
          </PaddingSubTitle>
          <HStack spacing={'6'} alignItems={'flex-start'}>
            {stationsMonitoringStatus.map((status, index) => (
              <Checkbox
                key={index}
                onChange={() => {
                  helper.handleChangePreStatusSelected({
                    newEvent: status,
                    preStatusSelected,
                    setPreStatusSelected,
                  });
                }}
                isChecked={preStatusSelected?.includes(status)}
                value={status}
              >
                {helper.handleStatusTextSelect({ status, t })}
              </Checkbox>
            ))}
          </HStack>
          <VStack align={'left'}>
            <PaddingApplyButton>
              <Button
                size={'lg'}
                onClick={handleSaveStatusSelectedButtonPress}
                disabled={buttonDisabled}
              >
                {t('monitoring.filterCard.apply-filter')}
              </Button>
            </PaddingApplyButton>
            <Flex justifyContent={'left'}>
              <Button
                variant="link"
                onClick={handleRemoveStatusSelectedButtonPress}
                size={'sm'}
              >
                {t('monitoring.filterCard.remove-filter')}
              </Button>
            </Flex>
          </VStack>
        </VStack>
      </Card>
    </Box>
  );

  return (
    <ScaleFade initialScale={0.3} in={isOpen} style={CustomScaleFadeStyles}>
      {filter}
    </ScaleFade>
  );
};

export default FilterContainer;
