import { Box, Text, Heading, useBreakpointValue } from '@chakra-ui/react';
import { Link } from 'components';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useURLParams } from 'hooks/useURLParam';
import { BlankState } from 'main/account-exclusion/components/blank-state';
import { StepsList } from 'main/account-exclusion/components/steps-list';
import { Trans, useTranslation } from 'react-i18next';

export const AccountExclusionIntroduction = () => {
  const { t } = useTranslation();
  const textSize = useBreakpointValue({ base: 14, md: 16 });
  const headingSize = useBreakpointValue({ base: 24, md: 32 });
  const urlParams = useURLParams();

  const appName = urlParams.get('appName');
  const publishedBy = urlParams.get('publishedBy');
  const supportEmail = urlParams.get('supportEmail');

  const { isMediaLargerThan800 } = useMediaQuery();

  if (!appName || !publishedBy) return <BlankState />;

  return (
    <Box mt={32} pb={8}>
      <Heading fontSize={headingSize}>
        {t('account-exclusion-introduction.title')}
      </Heading>

      <Text mt={6} color="gray.500" fontSize={textSize}>
        <Trans
          values={{
            appName,
            publishedBy,
          }}
          i18nKey="account-exclusion-introduction.subtitle"
          components={{
            Heading: (
              <Heading as="strong" color="gray.500" fontSize={textSize} />
            ),
          }}
        />
      </Text>

      <Text mt={6} color="gray.500" fontSize={textSize}>
        <Trans
          i18nKey="account-exclusion-introduction.description"
          components={{
            Link: (
              <Link
                size={isMediaLargerThan800 ? 16 : 14}
                target="_blank"
                textDecoration="underline"
                to={{
                  pathname: 'https://lgpd-brasil.info/capitulo_02/artigo_16',
                }}
              />
            ),
          }}
        />
        ;
      </Text>

      <Heading mt={6} color="gray.500" fontSize={textSize}>
        {t('account-exclusion-introduction.warning-text')}
      </Heading>

      {supportEmail && (
        <Text mt={6} color="gray.500" fontSize={textSize}>
          {t('account-exclusion-introduction.support-text', {
            supportEmail,
          })}
        </Text>
      )}
      <StepsList appName={appName} />

      <Text mt={6} color="gray.500" fontSize={textSize}>
        {t('account-exclusion-introduction.information-text')}
      </Text>
    </Box>
  );
};
