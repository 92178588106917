import Config from 'config';
import {
  ConnectorsListFieldsFragment,
  StationConnectorsCurrentSessionQuery,
  StationConnectorsCurrentSessionQueryVariables,
} from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import moment from 'moment';
import { InformationBubble } from 'new-components/InformationBubble';
import React, { useCallback } from 'react';
import StationConectorListSessions from 'graphql/queries/StationConnectorsCurrentSession';
import { Skeleton } from '@chakra-ui/skeleton';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatPower } from '../../../services/format';
import SummaryInfo from '../SummaryInfo';
import {
  BubbleContainer,
  BubbleDescription,
  ConnectorData,
  ConnectorTitle,
  Container,
  ErrorSolutionContainer,
  InformationBubblesAboutSession,
  InfoText,
  InnerContainer,
} from './styles';

type Session = NonNullable<ConnectorsListFieldsFragment['currentSession']>;

type Props = {
  connector: ConnectorsListFieldsFragment;
  connectorId: number;
  noContainer?: boolean;
  stationId?: string;
};

const MAX_ENERGY_UNUPDATED_INTERVAL_ALLOWED = moment
  .duration(2, 'minutes')
  .asSeconds();

const ConnectorInfo: React.FC<Props> = ({
  connector,
  noContainer,
  stationId,
  connectorId,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const { power, id, status, type, currentType, errorCode, station } =
    connector;

  const errorSolution = errorCode && Config.LABELS.ERROR_SOLUTIONS[errorCode];
  const Main = noContainer ? React.Fragment : Container;

  const energyIsUnupdated = useCallback(
    (session: Session) =>
      session.duration > MAX_ENERGY_UNUPDATED_INTERVAL_ALLOWED &&
      session.energyMeasurements.length === 0,
    []
  );
  const { data, loading } = useRealtimeQuery<
    StationConnectorsCurrentSessionQuery,
    StationConnectorsCurrentSessionQueryVariables
  >(StationConectorListSessions, {
    variables: {
      where: {
        stationId,
      },
      includeConnectorsCurrentSession: hasScopes([
        'session:read',
        'order:read',
      ]),
    },
  });

  const session = data?.station?.connectors.find(
    (connector) => connector.id === connectorId
  );

  return (
    <Main>
      <InnerContainer>
        {loading ? (
          <Stack>
            <Skeleton width="60%" />
            <Skeleton width="20%" />
            <Skeleton width="80%" />
            {station.status === 'UNKNOWN' && <Skeleton width="80%" />}
          </Stack>
        ) : (
          <Stack>
            <ConnectorData>
              <ConnectorTitle weight="bold" size={14}>
                {t('connectors-list.connector-row.title')}{' '}
                {`${id}`.padStart(2, '0')} - {currentType}-
                {Config.LABELS.CONNECTOR_TYPES[type]}
              </ConnectorTitle>

              <InfoText>{formatPower(power, { digits: 0 })}</InfoText>
              <InfoText>
                {t('connectors-list.status-row.title', {
                  connectorStatus: t(Config.LABELS.CONNECTOR_STATUS[status]),
                })}{' '}
              </InfoText>
              {errorCode && (
                <>
                  <InfoText>
                    {t('connectors-list.reason-row.title')}{' '}
                    {t(Config.LABELS.ERROR_LABELS[errorCode])}
                  </InfoText>
                  {errorSolution && (
                    <ErrorSolutionContainer>
                      <InfoText>
                        {t('connectors-list.solution-row.title')}{' '}
                        {t(errorSolution)}
                      </InfoText>
                    </ErrorSolutionContainer>
                  )}
                </>
              )}
            </ConnectorData>

            <>
              {hasScopes(['session:read']) && session?.currentSession && (
                <InformationBubblesAboutSession>
                  <SummaryInfo session={session.currentSession} />
                  {energyIsUnupdated(session.currentSession) && (
                    <BubbleContainer>
                      <InformationBubble
                        state="active"
                        icon={{ type: 'NEW_WARNING', color: 'WARNING' }}
                        accent="warning"
                        title={t(
                          'connectors-list.warning.no-recharge.data.title'
                        )}
                        content={
                          <>
                            <BubbleDescription>
                              {t(
                                'connectors-list.warning.no-recharge.data.message'
                              )}
                            </BubbleDescription>
                          </>
                        }
                      />
                    </BubbleContainer>
                  )}
                  {station.status === 'UNKNOWN' && (
                    <BubbleContainer>
                      <InformationBubble
                        state="active"
                        icon={{ type: 'NEW_WARNING', color: 'WARNING' }}
                        accent="warning"
                        title={t(
                          'connectors-list.warning.no-station.signal.title'
                        )}
                        content={
                          <>
                            <BubbleDescription>
                              {t(
                                'connectors-list.warning.no-station.signal.message'
                              )}
                            </BubbleDescription>
                          </>
                        }
                      />
                    </BubbleContainer>
                  )}
                </InformationBubblesAboutSession>
              )}
            </>
          </Stack>
        )}
      </InnerContainer>
    </Main>
  );
};

export default ConnectorInfo;
