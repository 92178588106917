import { Circle, Flex, ListItem, Text } from '@chakra-ui/react';

type Props = {
  text: string;
  color: 'gray.500' | 'success.700' | 'error.500';
};

export const PasswordPolicyRow = ({ text, color }: Props) => (
  <ListItem>
    <Flex alignItems="center" gap={2}>
      <Circle size={2} bg={color} />
      <Text color={color}>{text}</Text>
    </Flex>
  </ListItem>
);
