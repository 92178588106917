import styled from 'styled-components';

export const Container = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-self: flex-end;
  align-items: center;
  gap: 36px;
`;

export const CardWrapper = styled.div`
  flex: 1;
  background-color: ${({
    theme: {
      COLORS: { BACKGROUND },
    },
  }) => BACKGROUND};
  margin-bottom: 30px;
`;

export const OrderTableContainer = styled.div`
  max-height: 828px;
  height: 700px;
  margin-bottom: 47px;
  overflow-y: auto;
  border: 1px solid
    ${({
      theme: {
        COLORS: { TERTIARY_GRAY },
      },
    }) => TERTIARY_GRAY};
  border-radius: 8px;
`;

export const FilterButtonContainer = styled.div`
  margin-left: 25px;
`;
