import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const CardContainer = styled.div``;

export const Page = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  flex: 1;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const MapContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 50%;
  height: calc(100vh-86px);
  margin-top: -44px;
  width: 100%;
  max-width: 700px;
`;

export const LoadingOrErrorContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  margin-top: -50px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
`;

export const StationsCardsContent = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.space['SPACE-16']}px;
  row-gap: ${({ theme }) => theme.space['SPACE-16']}px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  margin: ${({ theme }) => theme.space['SPACE-24']}px 0px
    ${({ theme }) => theme.space['SPACE-48']}px;
`;

export const StationsCardsContainer = styled.div`
  padding: 0px ${({ theme }) => theme.padding['PADDING-16']}px;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.08);
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  position: relative;
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: left;
  width: 100%;
  margin-top: 24px;
`;

export const FilterButton = styled.button`
  align-items: center;
  background-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0px 16px;
`;
