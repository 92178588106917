import styled from 'styled-components';
import { NewTag } from 'new-components/NewTag';
import { Card } from '../../Card/styles';
import { H3, BodyTypography } from '../../../../Typographies/styles';

export const Container = styled(Card)`
  flex-basis: 50%;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 12px;
  padding: 12px;
  opacity: ${(props: { state: string }) =>
    props.state === 'disabled' ? '0.5' : '1'};
`;

Container.defaultProps = {
  state: 'enabled',
};

export const TopPart = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
`;
export const BottomPart = styled('section')``;

export const MoneyAndStatus = styled('section')`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const Value = styled(H3)``;
export const Status = styled(NewTag)``;

export const Description = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;
export const Date = styled(BodyTypography)`
  align-self: flex-end;
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;
