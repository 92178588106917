import { Link, Typography } from 'components';
import config from 'config';
import AuthContext from 'contexts/Auth/context';
import { Organization } from 'generated/graphql';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
  Button,
} from '@chakra-ui/react';
import { IconPassword } from 'components/Input/styles';
import { useTranslation } from 'react-i18next';
import { useURLParams } from 'hooks/useURLParam';
import {
  Container,
  ExtrasContainer,
  LoginButtonContainer,
  LoginErrorContainer,
  RemindUserContainer,
  TermsOfUseContainer,
} from './styles';
import { useCooldownService } from './service/cooldownService';

const REMIND_USER_KEY = '@voltbras/user-email';

const getRememberedEmail = () =>
  window.localStorage.getItem(REMIND_USER_KEY) || '';
const setRememberedEmail = (email: string) =>
  window.localStorage.setItem(REMIND_USER_KEY, email);

const Login: React.FC<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryFromUrl = useURLParams();

  const {
    loginByEmail,
    loginBySSO,
    setOrganizationIdSelectedOnLogin,
    identityProvider,
    memberAuthToken,
  } = useContext(AuthContext);

  const {
    loginCooldownError,
    loginCooldownTimeoutCount,
    startLoginCooldownTimeout,
    whenLoginCooldownTimeoutEnds,
  } = useCooldownService();

  const [hasRemembered, setHasRemembered] = useState(!!getRememberedEmail());
  const [email, setEmail] = useState(getRememberedEmail());
  const [password, setPassword] = useState('');

  useEffect(
    () => setRememberedEmail(hasRemembered ? email : ''),
    [hasRemembered, email]
  );

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);

  whenLoginCooldownTimeoutEnds(() => {
    setLoading(false);
  });

  const onChangeEmail = (text: string) => {
    setEmail(text);
    if (text) {
      setEmailError('');
      setLoginError('');
    }
  };

  const onChangePassword = (text: string) => {
    setPassword(text);
    if (text) {
      setPasswordError('');
      setLoginError('');
    }
  };

  const redirectToTFAFlow = () => history.push('/two-factor-authentication');

  useEffect(() => {
    if (identityProvider.type === 'SSO_IdP' && memberAuthToken)
      history.push('/');
  }, [identityProvider, memberAuthToken, history]);

  const handleSignInWithSSO = useCallback(async () => {
    const result = await loginBySSO();
    if (result.error) setLoginError(t('login.conection-error-message'));
  }, [loginBySSO, setLoginError, t]);

  const handleLogin = useCallback(
    async (email?: string, password?: string) => {
      if (loading) return;
      try {
        if (!email)
          return setEmailError(t('login.form-empty-input-error-message'));
        if (!password)
          return setPasswordError(t('login.form-empty-input-error-message'));
        setLoading(true);
        if (!email || !password) throw new Error('no email or password');

        const { error } = await loginByEmail(email, password);
        if (error && error.includes('CooldownError')) {
          startLoginCooldownTimeout();
          return;
        }
        if (error && error.includes('Autenticação em 2 etapas é obrigatório')) {
          redirectToTFAFlow();
          return;
        }
        if (error) setLoginError(error);
        else history.push('/');
        setLoading(false);
      } catch (error: any) {
        setLoginError(t('login.conection-error-message'));
        setLoading(false);
      }
    },
    [loading, loginByEmail, setLoginError, history] // eslint-disable-line
  );

  useEffect(() => {
    const defaultOrgId: Organization['id'] | null = queryFromUrl.get('orgId');
    if (defaultOrgId) return setOrganizationIdSelectedOnLogin(defaultOrgId);
  }, [queryFromUrl, setOrganizationIdSelectedOnLogin]);

  const [isPasswordVisible, showPassword] = useState(false);

  return (
    <>
      <Container>
        <Typography weight="bold" size={35}>
          {t('login.welcome-bold-title')}
        </Typography>
        <Typography color="SECONDARY_GRAY" size={16}>
          {t('login.welcome.subtitle')}
        </Typography>
        {(loginError || loginCooldownError) && (
          <LoginErrorContainer>
            {loginCooldownError ? (
              <Typography data-testid="cooldown-error" color="WHITE">
                {t('login.cooldown-error-message', {
                  seconds: loginCooldownTimeoutCount,
                })}
              </Typography>
            ) : (
              <Typography data-testid="login-error" color="WHITE">
                {loginError}
              </Typography>
            )}
          </LoginErrorContainer>
        )}
        <Box width="100%" mt="10">
          <FormControl isInvalid={!!emailError}>
            <Input
              data-testid="email-input"
              focusBorderColor="none"
              placeholder={t('login.form.email-input.placeholder')}
              type="text"
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
            />
            <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box width="100%" mt="10">
          <FormControl isInvalid={!!passwordError}>
            <InputGroup>
              <Input
                data-testid="password-input"
                focusBorderColor="none"
                placeholder={t('login.form.password-input.placeholder')}
                value={password}
                type={isPasswordVisible ? 'text' : 'password'}
                onKeyDown={(event) =>
                  event.key === 'Enter' && handleLogin(email, password)
                }
                onChange={(e) => onChangePassword(e.target.value)}
              />
              <InputRightElement
                children={
                  <IconPassword
                    onClick={() => showPassword(!isPasswordVisible)}
                    show={!isPasswordVisible}
                  />
                }
              />
            </InputGroup>
            <FormErrorMessage>{passwordError}</FormErrorMessage>
          </FormControl>
        </Box>
        <ExtrasContainer>
          <RemindUserContainer>
            <Checkbox
              isChecked={hasRemembered}
              onChange={() =>
                setHasRemembered((hasRemembered) => !hasRemembered)
              }
            >
              <Typography size={16}>
                {t('login.form.remember-user-checkbox.title')}
              </Typography>
            </Checkbox>
          </RemindUserContainer>
          <Link
            data-test="forgot-password-button"
            to="/forgot-password"
            color="PRIMARY_GRAY"
            size={16}
          >
            {t('login.form.remember-forgot-password-link.title')}
          </Link>
        </ExtrasContainer>
        <LoginButtonContainer>
          <Button
            data-testid="login-button"
            width="100%"
            size="lg"
            onClick={() => handleLogin(email, password)}
            isLoading={loading}
          >
            {t('login.form.submit-btn.title')}
          </Button>
        </LoginButtonContainer>

        {identityProvider.type === 'SSO_IdP' && config.YPF_SSO_ENABLED ? (
          <LoginButtonContainer>
            <Button
              width="100%"
              size="lg"
              onClick={() => handleSignInWithSSO()}
              isLoading={loading}
            >
              {config.CODE === 'ypf' && <>{t('login.sign-in-with-ypf-sso')}</>}
            </Button>
          </LoginButtonContainer>
        ) : null}
      </Container>
      {config.CODE === 'edp' ? (
        <TermsOfUseContainer>
          <Typography weight="light" size={16}>
            Clicando em Entrar eu aceito os{' '}
          </Typography>
          <Link to="/terms-of-service" weight="bold" size={16}>
            Termos de uso
          </Link>
          <Typography weight="light" size={16}>
            {' '}
            e{' '}
          </Typography>
          <Link to="/terms-of-service" weight="bold" size={16}>
            Política de Privacidade
          </Link>
        </TermsOfUseContainer>
      ) : null}
    </>
  );
};

export default Login;
