import { Typography } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: none;
  justify-content: space-between;
  width: 100%;
  &:not(:first-child) {
    margin-top: 25px;
  }
`;

export const ConnectorInfoContainer = styled.div``;

export const Content = styled.div`
  margin-top: 30px;
  flex-grow: 1;
  flex-basis: 100%;
  max-width: 100%;

  @media (min-width: 800px) {
    max-width: calc(50% - 10px);
  }

  @media (min-width: 1250px) {
    max-width: calc(33.33% - 10px);
  }
`;

export const ConnectorTitle = styled(Typography).attrs({
  size: 12,
  color: 'WHITE',
})<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  min-height: 25px;
  margin-bottom: 14px;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 10px;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

// TODO: Nelson fix
export const ActionButton = styled.button`
  width: 120px;
  cursor: pointer;
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
  font-family: ${({ theme }) => theme.FONTS.PRIMARY};
  font-size: 14px;
  height: 31px;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.COLORS.TERTIARY_GRAY}`};
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  margin: 10px 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ResetButtonsContainer = styled(ButtonsContainer)`
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
`;

export const ResetButton = styled(ActionButton)`
  min-width: 167px;
  margin: 0;
  width: calc(50% - 20px);
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  border: ${({ theme }) => `2px solid ${theme.COLORS.TERTIARY_GRAY}`};
  background: ${({ theme }) => theme.COLORS.CLICKABLE};
  color: ${({ theme }) => theme.COLORS.WHITE};

  &:first-child {
    margin-right: 20px;
  }
`;

export const ConfigurationContainer = styled.div`
  display: flex;
  flex: none;
  flex-flow: row wrap;
  justify-content: center;
`;

export const ConfigurationResponse = styled.div`
  border: ${({ theme }) => `1px solid ${theme.COLORS.TERTIARY_GRAY}`};
  border-radius: 8px;
`;

export const StreamEventsContainer = styled.div`
  margin-bottom: 47px;
`;

export const OperationInfo = styled(Typography).attrs({
  size: 10,
  color: 'SECONDARY_GRAY',
})``;
export const OperationInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 150px;
`;

export const OperationSection = styled.div`
  display: flex;
`;
