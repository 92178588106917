import styled from 'styled-components';

export const DropdownContainer = styled.div`
  width: 170px;
  z-index: 1;
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: 345px;
`;
