import styled from 'styled-components';
import { Card } from '../Card/styles';
import { H3, BodyTypography } from '../../../Typographies/styles';

export const Container = styled(Card)`
  flex-flow: column nowrap;
  gap: 16px;
`;
export const Title = styled(H3)``;

export const Item = styled('section')`
  display: flex;
  justify-content: space-between;
`;
export const ItemSeparator = styled('hr')`
  border-top: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
`;
export const ItemTypography = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;
