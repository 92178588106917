import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  margin-top: 24px;
  align-items: center;
`;

export const MainLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PageActions = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  && > * {
    margin-left: 8px;
  }
`;
