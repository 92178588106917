import { Typography } from 'components';
import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  visibility: hidden;
`;

export const Container = styled.div<{ active: boolean }>`
  background-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-radius: 8px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: 300ms;

  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.COLORS.TERTIARY_GRAY};
          border: 1px solid ${theme.COLORS.PRIMARY_GRAY};
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
          ${ButtonContainer} {
            visibility: inherit;
          }
        `
      : css`
          :hover {
            background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
            ${ButtonContainer} {
              visibility: inherit;
            }
          }
        `}
`;

export const BottomContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-left: -7.5px;
`;

export const StationName = styled(Typography).attrs({
  size: 16,
  weight: 'bold',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StationNameContainer = styled.div`
  align-items: center;
  display: flex;
  height: 19px;
  margin: 4px 0px;
  width: 100%;
`;
