import Container from 'atomic-components/organisms/Container';
import {
  StationDetailsHmiQuery,
  StationDetailsHmiQueryVariables,
} from 'generated/graphql';
import changeStationDescriptionMutation from 'graphql/mutations/changeStationDescription';
import changeStationNameMutation from 'graphql/mutations/changeStationName';
import stationDetailsHMI from 'graphql/queries/stationDetailsHMI';
import { useAuthMutation, useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import _ from 'lodash';
import ImagesCrop from 'pages/StationPage/ManagerHMI/ImagesCrop';
import ImagesGallery from 'pages/StationPage/ManagerHMI/ImagesGallery';
import { StationContext } from 'pages/StationPage/StationContext';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import toast from 'services/toast';
import Card from 'pages/StationPage/Card';
import {
  Flex,
  Stack,
  Text,
  FormLabel,
  FormControl,
  Input,
  Spacer,
  Box,
  Textarea,
  Heading,
  useToast,
  Button,
} from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import { Icon } from 'new-components/Icon';
import SaveToast from 'pages/StationPage/Toast';
import { useTranslation } from 'react-i18next';
import useAuthPostRequest from 'hooks/useAuthPostRequest';
import {
  ChangeStationImagesPostRequestBody,
  setupChangeStationImagesPostRequest,
} from 'rest/post/changeStationImages';
import { getFileFromURL } from '../../../services/file';
import {
  AddButtonContainer,
  AddImageIcon,
  AddImageRow,
  HiddenInput,
  ImageGalleryRow,
} from './styles';

type ImagesActions =
  | {
      type: 'PUSH';
      file: File;
    }
  | {
      type: 'REMOVE';
      index: number;
    }
  | {
      type: 'SUBSTITUTE-ALL';
      files: File[];
    };

const ManagerHMI: React.FC = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const MAX_IMAGES_SIZE = 6;
  const { id: stationId } = useContext(StationContext);

  const [cropImages, setCropImages] = useState<Array<File>>([]);
  const [cropImageOpen, setCropImageOpen] = useState(false);
  const nameMutation = useAuthMutation(changeStationNameMutation);
  const descriptionMutation = useAuthMutation(changeStationDescriptionMutation);
  const [toastVisible, setToastVisible] = useState(false);
  const { handleAuthPost } = useAuthPostRequest();
  const { data, loading } = useAuthQuery<
    StationDetailsHmiQuery,
    StationDetailsHmiQueryVariables
  >(stationDetailsHMI, {
    variables: { where: { stationId } },
    skip: !stationId || !hasScopes(['station:edit']),
  });

  const station = data?.station;
  // This is to close when user navigates to another page with toast open
  const toastOnAnotherPage = useToast();
  const { images = [], name = '', description = '' } = station ?? {};

  const [lastSavedState, setLastSavedState] = useState({
    images: [] as File[],
    name,
    description,
  });
  const [localDescription, setLocalDescription] = useState(description || '');

  const [localImages, setLocalImages] = useReducer(
    (state: File[], action: ImagesActions): File[] => {
      switch (action.type) {
        case 'PUSH':
          if (state.length < MAX_IMAGES_SIZE) {
            return [...(state || []), action.file];
          }
          return state;
        case 'REMOVE': {
          const updateState = [...state];
          updateState.splice(action.index, 1);
          return updateState;
        }
        case 'SUBSTITUTE-ALL':
          if (state.length <= MAX_IMAGES_SIZE) {
            return action.files;
          }
          return state;
        default:
          return state;
      }
    },
    []
  );
  const [localStationName, setLocalStationName] = useState(name || '');

  const handleSetFields = useCallback(() => {
    Promise.all(images.map(getFileFromURL)).then((files) => {
      setLocalImages({ type: 'SUBSTITUTE-ALL', files });
      setLastSavedState({ images: files, name, description });
      setLocalStationName(name || '');
      setLocalDescription(description || '');
    });
  }, [description, name, images]);

  useEffect(() => {
    if (loading) return;
    if (images.length) {
      handleSetFields();
    } else {
      setLastSavedState({ images: [], name, description });
      setLocalStationName(name || '');
      setLocalDescription(description || '');
    }
  }, [images, name, description, loading, handleSetFields]);

  const hasChanges = {
    name: lastSavedState.name !== localStationName && !loading,
    description: lastSavedState.description !== localDescription && !loading,
    images: !_.isEqual(lastSavedState.images, localImages) && !loading,
    get any() {
      return this.name || this.description || this.images;
    },
  };

  const saveChanges = async () => {
    const where = { stationId };

    const callChangeStationImagesPostRequest = async (
      body: ChangeStationImagesPostRequestBody,
      label: string
    ) => {
      if (!station) {
        throw new Error('Not valid station');
      }
      try {
        await handleAuthPost(
          setupChangeStationImagesPostRequest({
            stationId: station.id,
            body,
          })
        );
        toast.success(
          t('station-page.general.manager-hmi.toast-success-message', { label })
        );
      } catch (e) {
        toast.error(
          t('station-page.general.manager-hmi.toast-error-message', {
            label,
          })
        );
      }
    };

    const callMutation = async (
      [mutation]: typeof nameMutation,
      variable: Object,
      label: string
    ) => {
      try {
        await mutation({ variables: { where, ...variable } });
        toast.success(
          t('station-page.general.manager-hmi.toast-success-message', { label })
        );
      } catch (e) {
        toast.error(
          t('station-page.general.manager-hmi.toast-error-message', {
            label,
          })
        );
      }
    };
    if (!hasScopes(['station:edit']))
      return toast.error(t('station-page.general.toast.not-permission-error'));
    if (hasChanges.name)
      callMutation(
        nameMutation,
        { name: localStationName },
        t('station-page.general.manager-hmi.change-nome-text')
      );
    if (hasChanges.description)
      callMutation(
        descriptionMutation,
        { data: { description: localDescription } },
        'a descrição'
      );

    if (hasChanges.images)
      callChangeStationImagesPostRequest(
        { images: localImages },
        t('station-page.general.manager-hmi.change-images-text')
      );
    setLastSavedState({
      images: localImages,
      name: localStationName,
      description: localDescription,
    });
    toastOnAnotherPage.closeAll();
  };

  const handleInputFiles = (files: FileList | null) => {
    if (!files || !files.length) return;
    const emptyImages = MAX_IMAGES_SIZE - localImages.length;
    if (files.length > emptyImages) {
      if (emptyImages === 0)
        toast.error(
          t('station-page.general.manger-hmi.toast-max-images-error-message', {
            maxImages: MAX_IMAGES_SIZE,
          })
        );
      else
        toast.error(
          t(
            'station-page.general.manger-hmi.toast-limit-images-error-message',
            {
              emptyImages,
            }
          )
        );
      return;
    }
    setCropImageOpen(true);
    setCropImages(Array.from(files));
  };

  const clearFileInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = '';
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputFiles(e.target.files);
    clearFileInputValue(e);
    setToastVisible(true);
  };

  const handleCloseCropImages = () => {
    setCropImageOpen(false);
    setCropImages([]);
  };

  const isValid = {
    localName: localStationName?.length <= 50,
    localDescription: localDescription?.length <= 500,
    get allInputs() {
      return this.localDescription && this.localName && hasChanges.any;
    },
  };

  const preventImageDialogShowOnEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const handleSetLocalStationName = (value: string) => {
    setLocalStationName(value);
    setToastVisible(true);
  };

  const handleSetLocalDescription = (value: string) => {
    setLocalDescription(value);
    setToastVisible(true);
  };

  const handleSetLocalImages = (images: File[]) => {
    setLocalImages({
      type: 'SUBSTITUTE-ALL',
      files: [...localImages, ...images],
    });
    setToastVisible(true);
  };

  const handleRemoveLocalImages = (index: number) => {
    setLocalImages({ type: 'REMOVE', index });
    setToastVisible(true);
  };

  const resetFields = () => {
    handleSetFields();
    setToastVisible(false);
    toastOnAnotherPage.closeAll();
  };

  return (
    <Container loading={loading}>
      <SaveToast
        id="managerHMI"
        onClick={saveChanges}
        resetFields={resetFields}
        isVisible={toastVisible}
        isValid={isValid.allInputs}
        inputsValues={[
          localDescription,
          localStationName,
          localImages,
          toastVisible,
          isValid.allInputs,
        ]}
      />
      <Stack spacing="8">
        <Heading fontSize="2xl">
          {t('station-page.general-tab.header.title')}
        </Heading>
        <Box maxWidth="2xl">
          {cropImageOpen && (
            <ImagesCrop
              onCrop={(images) => handleSetLocalImages(images)}
              onClose={handleCloseCropImages}
              images={cropImages}
            />
          )}
          <Flex direction="column">
            <Stack spacing="8">
              <FormControl isInvalid={!isValid.localName}>
                <Flex align="center" pb="2">
                  <FormLabel m="0" pr="2">
                    {t('station-page.general.form.station-name-input.title')}
                  </FormLabel>
                  <Tooltip
                    label={t(
                      'station-page.general.form.station-name-field.label'
                    )}
                    placement="right"
                    bg="gray.900"
                    hasArrow
                  >
                    <Stack>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </Stack>
                  </Tooltip>
                </Flex>
                <Input
                  maxLength={50}
                  onChange={(e) => handleSetLocalStationName(e.target.value)}
                  value={localStationName}
                  focusBorderColor={isValid.localName ? 'primary.400' : 'error'}
                />
              </FormControl>
              <FormControl isInvalid={!isValid.localDescription}>
                <Flex align="center" pb="2">
                  <FormLabel m="0" pr="2">
                    {t(
                      'station-page.general.form.station-description-input.title'
                    )}
                  </FormLabel>
                  <Tooltip
                    label={t(
                      'station-page.general.form.station-description-field.label'
                    )}
                    placement="right"
                    bg="gray.900"
                    hasArrow
                  >
                    <Stack>
                      <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                    </Stack>
                  </Tooltip>
                </Flex>
                <Stack spacing="2">
                  <Textarea
                    bg="white"
                    value={localDescription}
                    onChange={(e) => handleSetLocalDescription(e.target.value)}
                    focusBorderColor={
                      isValid.localDescription ? 'primary.400' : 'error'
                    }
                    fontSize="sm"
                  />
                  <Flex>
                    <Spacer />
                    <Text
                      fontSize="sm"
                      color={
                        isValid.localDescription ? 'gray.500' : 'error.500'
                      }
                    >{`${t('station-page.caracters.title')} - ${
                      localDescription?.length
                    }/500`}</Text>
                  </Flex>
                </Stack>
              </FormControl>
              <Box>
                <AddImageRow>
                  <Box>
                    <Flex alignItems="center" gridGap="2">
                      <Heading fontSize="md">
                        {t(
                          'station-page.general.form.station-image-field.title'
                        )}
                      </Heading>
                      <Tooltip
                        label={t(
                          'station-page.general.form.station-image-field.label'
                        )}
                        placement="right"
                        bg="gray.900"
                        hasArrow
                      >
                        <Stack>
                          <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                        </Stack>
                      </Tooltip>
                    </Flex>
                    <AddButtonContainer>
                      <Button
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        fontWeight="normal"
                        onClick={() => {}}
                      >
                        <HiddenInput
                          title={t(
                            'station-page.general.form.station-image-field-title'
                          )}
                          required={true}
                          multiple={true}
                          accept={'image/png,image/jpeg,image/jpg'}
                          type={'file'}
                          onKeyPress={preventImageDialogShowOnEnterKeyPress}
                          onChange={handleFileInputChange}
                        />
                        <AddImageIcon />
                        {t('station-page.general.form.station-image-field.btn')}
                      </Button>
                    </AddButtonContainer>
                  </Box>
                </AddImageRow>
                <Card p="0" pl="2">
                  <ImageGalleryRow>
                    <ImagesGallery
                      imagesSize={MAX_IMAGES_SIZE}
                      images={localImages}
                      onUpdate={(images) =>
                        setLocalImages({
                          type: 'SUBSTITUTE-ALL',
                          files: images,
                        })
                      }
                      onDelete={(index) => handleRemoveLocalImages(index)}
                    />
                  </ImageGalleryRow>
                </Card>
              </Box>
            </Stack>
          </Flex>
        </Box>
      </Stack>
    </Container>
  );
};

export default ManagerHMI;
