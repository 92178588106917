import Typography from 'components/Typography';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 100%;
`;

export const StatusAndAlarmsCard = styled.div`
  align-items: center;
  background-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0px 16px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  & ${StatusAndAlarmsCard}:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  & ${StatusAndAlarmsCard}:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const SatationStatus = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  weight: 'bold',
})`
  white-space: nowrap;
`;

export const TagContainer = styled.div`
  margin-left: 8px;
  display: flex;
`;
