import { gql } from '@apollo/client';

export default gql`
  query StationPageDetails(
    $where: StationWhereUniqueInput!
    $includeAlarms: Boolean!
  ) {
    station(where: $where) {
      cpoId
      id
      archived
      name
      activeAlarms: alarmsSummary(filter: { resolved: false })
        @include(if: $includeAlarms) {
        count
      }
      stationConnectivityStatus
      lastConnectedAt
      model {
        capabilities
      }
    }
  }
`;
