import Config from 'config';
import React from 'react';
import { NotNestedColors } from 'types/components';
import { Container } from './styles';

interface Props {
  top?: number;
  color?: keyof NotNestedColors;
  size: number;
  className?: string;
}

const LoadingComponent: React.FC<Props> = ({ top, color, size, className }) => (
  <Container top={top} size={size} className={className}>
    <Config.ICONS.SPINNER
      color={Config.COLORS[color ?? 'TERTIARY_GRAY']}
      height={size}
      width="100%"
    />
  </Container>
);

export default LoadingComponent;
