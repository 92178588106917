import { gql } from '@apollo/client';

export default gql`
  query StationOwnerControlDetails($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      cpoId
      chargePointId
      name
      routerConnectivityStatus
      stationConnectivityStatus
      errorCode
      coordinates {
        latitude
        longitude
      }
      address {
        street
        streetNumber
        city
        state
        neighborhood
        postalCode
        country
      }
      configPortal {
        url
        user
        password
      }
      routerPortal {
        url
        user
        password
      }
      ocppVersion
      lastConnectedAt
      model {
        vendor
        modelCode
      }
    }
  }
`;
