import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const NeoenergiaConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: false,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-4',
  CODE: 'neoenergia',
  LABEL: 'Neoenergia',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#427314',
    CLICKABLE: '#FF5A00',
    ERROR: '#FF5A00',
    SIDEBAR: '#6C8D31',
    LOGIN_LOGO_BACKGROUND: '#F4F4F4',

    RISE: '#427314',
    FALL: '#FF5A00',
    NEUTRAL: '#0063BE',

    CRITICAL: '#F6334F',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#427314',
    NoCost: '#F4F4F4',
    Error: '#FF5A00',

    AVAILABLE: '#427314',
    CHARGING: '#0063BE',
    INOPERATIVE: '#FF5A00',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#FF5A00',
    RESERVED: '#FF5A00',

    PREPARING: '#0063BE',
    FINISHING: '#0063BE',

    SELECTED_DATE_BOUND_BACKGROUND: '#3E681D',
    SELECTED_DATE_BOUND_BORDER: '#E8EAEA',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#97B080',

    DATE_PRE_SELECTED_BACKGROUND: '#97B080',
    DATE_PRE_SELECTED_BORDER: '#E8EAEA',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#3E681D',
    DATE_PRE_SELECTED_COLOR: '#E8EAEA',

    DATE_SELECTED_BACKGROUND: '#427314',
    DATE_SELECTED_BORDER: '#E8EAEA',
    DATE_SELECTED_HOVER_BACKGROUND: '#97B080',

    CURRENT_DAY: '#427314',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#427314',
      Subsidized: '#427314',
      NoCost: '#F4F4F4',
      Error: '#FF5A00',
      ErrorOnPreAuthorization: '#FF5A00',
    },
    STATION_STATUS: {
      AVAILABLE: '#427314',
      CHARGING: '#0063BE',
      INOPERATIVE: '#FF5A00',
      UNKNOWN: '#6E6E6E',
      // we're not using, but just so typescript
      // doesn't complain
      PLANNED: '#FF5A00',
      RESERVED: '#FF5A00',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#427314',
      PREPARING: '#0063BE',
      CHARGING: '#0063BE',
      FINISHING: '#0063BE',
      INOPERATIVE: '#FF5A00',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#FF5A00',
    },

    BILLING: '#427314',
    RECHARGES: '#FF5A00',
    AVAILABILITY: '#0063BE',

    BUTTON_AVAILABLE: '#427314',
    BUTTON_UNAVAILABLE: '#939393',
    BUTTON_LOADING: '#FF5A00',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#0063BE',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#3E681D',
      SELECTED_DATE_BOUND_BORDER: '#E8EAEA',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#97B080',

      DATE_PRE_SELECTED_BACKGROUND: '#97B080',
      DATE_PRE_SELECTED_BORDER: '#E8EAEA',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#3E681D',
      DATE_PRE_SELECTED_COLOR: '#E8EAEA',

      DATE_SELECTED_BACKGROUND: '#427314',
      DATE_SELECTED_BORDER: '#E8EAEA',
      DATE_SELECTED_HOVER_BACKGROUND: '#97B080',

      CURRENT_DAY: '#427314',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Neoenergia - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/neoenergia',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#9be258',
      '100': '#81da2c',
      '200': '#74ca23',
      '300': '#67b41f',
      '400': '#5b9e1c',
      '500': '#427314',
      '600': '#29480c',
      '700': '#1d3209',
      '800': '#101c05',
      '900': '#000000',
    },
    LINK: {
      '50': '#ffceb3',
      '100': '#ffad80',
      '200': '#ff9c66',
      '300': '#ff8c4d',
      '400': '#ff7b33',
      '500': '#FF5A00',
      '600': '#cc4800',
      '700': '#b33f00',
      '800': '#993600',
      '900': '#662400',
    },
    ERROR: {
      '50': '#ffceb3',
      '100': '#ffad80',
      '200': '#ff9c66',
      '300': '#ff8c4d',
      '400': '#ff7b33',
      '500': '#FF5A00',
      '600': '#cc4800',
      '700': '#b33f00',
      '800': '#993600',
      '900': '#662400',
    },
    SUCCESS: {
      '50': '#9be258',
      '100': '#81da2c',
      '200': '#74ca23',
      '300': '#67b41f',
      '400': '#5b9e1c',
      '500': '#427314',
      '600': '#29480c',
      '700': '#1d3209',
      '800': '#101c05',
      '900': '#000000',
    },
    CHARGING: {
      '50': '#71bbff',
      '100': '#3fa3ff',
      '200': '#2597ff',
      '300': '#0c8aff',
      '400': '#007ef1',
      '500': '#0063BE',
      '600': '#00488b',
      '700': '#003b72',
      '800': '#002e58',
      '900': '#001325',
    },
    AVAILABLE: {
      '50': '#9be258',
      '100': '#81da2c',
      '200': '#74ca23',
      '300': '#67b41f',
      '400': '#5b9e1c',
      '500': '#427314',
      '600': '#29480c',
      '700': '#1d3209',
      '800': '#101c05',
      '900': '#000000',
    },
    SECONDARY: {
      '50': '#71bbff',
      '100': '#3fa3ff',
      '200': '#2597ff',
      '300': '#0c8aff',
      '400': '#007ef1',
      '500': '#0063BE',
      '600': '#00488b',
      '700': '#003b72',
      '800': '#002e58',
      '900': '#001325',
    },
    RESERVE: {
      '50': '#71bbff',
      '100': '#3fa3ff',
      '200': '#2597ff',
      '300': '#0c8aff',
      '400': '#007ef1',
      '500': '#0063BE',
      '600': '#00488b',
      '700': '#003b72',
      '800': '#002e58',
      '900': '#001325',
    },
    INOPERATIVE: {
      '50': '#ffceb3',
      '100': '#ffad80',
      '200': '#ff9c66',
      '300': '#ff8c4d',
      '400': '#ff7b33',
      '500': '#FF5A00',
      '600': '#cc4800',
      '700': '#b33f00',
      '800': '#993600',
      '900': '#662400',
    },
  },
};
export default NeoenergiaConfig;
