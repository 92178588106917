const getCookieFromKey = (
  key: string,
  document: Document
): string | undefined => {
  const foundCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${key}=`));
  if (foundCookie) return foundCookie.split('=')[1];
};

export default getCookieFromKey;
