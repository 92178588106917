import { Heading } from '@chakra-ui/react';
import React from 'react';
import { ContentGroup } from '../StationUserGenerics/styles';
import { Container, Title, UserId, UserInfo, UserInfoItem } from './styles';

type Props = {
  title: string;
  boldText: string;
  bodyText1?: string;
  bodyText2?: string;
  bodyText3?: string;
  footer: React.ReactNode;
  clickable: boolean;
  onClick?: () => void;
  emspId: string;
};

const Card: React.FC<Props> = ({
  title,
  boldText,
  bodyText1,
  bodyText2,
  bodyText3,
  footer,
  clickable,
  onClick,
  emspId,
}) => (
  <Container clickable={clickable} onClick={onClick}>
    <ContentGroup>
      <Title>{title}</Title>
      <UserId>{boldText}</UserId>
      <Heading fontSize="14px">e-MSP : {emspId}</Heading>
      <UserInfo>
        <UserInfoItem>{bodyText1 ?? '-'}</UserInfoItem>
        <UserInfoItem>{bodyText2 ?? '-'}</UserInfoItem>
        <UserInfoItem>{bodyText3 ?? '-'}</UserInfoItem>
      </UserInfo>
    </ContentGroup>
    <ContentGroup>{footer}</ContentGroup>
  </Container>
);

export default Card;
