import { Text as ChakraText } from '@chakra-ui/react';
import React from 'react';

type ContainerTextProps = {
  fontFamily: 'heading' | 'body' | 'mono';
  fontSize: 'sm';
  fontWeight: 'normal';
  lineHeight: '4';
  color?: 'gray.900' | 'gray.500';
};

const Text: React.FC<ContainerTextProps> = ({
  children,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  color,
}) => (
  <ChakraText
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    color={color}
  >
    {children}
  </ChakraText>
);
export default Text;
