import React from 'react';
import ConfigType from 'config/typing';
import PaymentMethodInfo from 'new-components/PaymentMethodInfo';
import PaymentWalletInfo from 'new-components/PaymentMethodInfo/paymentWalletInfo';
import { Container, ScrollContainer } from './styles';

export type PaymentMethod = {
  title: string;
  info: string;
  icon: keyof ConfigType['NEW_ICONS'];
  type: string;
};

type Props = {
  paymentMethods: PaymentMethod[];
  minimumWalletDepositAmount?: number;
  availableBalance?: number;
  driverId?: number;
};

const PaymentMethods: React.FC<Props> = ({
  paymentMethods,
  minimumWalletDepositAmount,
  availableBalance,
  driverId,
}) => {
  const paymentMethodsWalletInfo =
    paymentMethods &&
    paymentMethods.find((paymentMethod) => paymentMethod.type === 'Wallet');

  const paymentMethodsWithoutWallet = paymentMethods.filter(
    (paymentMethod) => paymentMethod.type !== 'Wallet'
  );

  return (
    <Container>
      {paymentMethodsWalletInfo && (
        <PaymentWalletInfo
          minimumWalletDepositAmount={minimumWalletDepositAmount}
          availableBalance={availableBalance}
          driverId={driverId}
          {...(paymentMethodsWalletInfo as PaymentMethod)}
        />
      )}
      <ScrollContainer>
        {paymentMethodsWithoutWallet.map((paymentMethod, i) => (
          <React.Fragment key={i}>
            <PaymentMethodInfo {...paymentMethod} />
          </React.Fragment>
        ))}
      </ScrollContainer>
    </Container>
  );
};

export default PaymentMethods;
