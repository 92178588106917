import { gql } from '@apollo/client';

export default gql`
  query AllVehicles($limit: Int, $offset: Int, $search: String) {
    vehicles(
      search: $search
      limit: $limit
      offset: $offset
      sort: { emspId: ASC }
    ) {
      id
      label
      plate
      emspId
    }
    vehiclesSummary(search: $search) {
      count
    }
  }
`;
