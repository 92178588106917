import { SAMLAuthProvider, signInWithRedirect } from 'firebase/auth';
import { FirebaseAuth } from './firebaseAuth';
import IdentityProvider, { IdentityProviderType } from '.';
import SSOIdentityProvider from './sso';
import YPFSSOIdentityProvider from './sso/ypf';
import { auth } from './firebaseConfig';

export interface IIdentityProviderFactory {
  build(orgId: string): IdentityProvider;
}

export default class IdentityProviderFactory
  implements IIdentityProviderFactory
{
  private inferIdPTypeFromOrgId(orgId: string): IdentityProviderType {
    switch (orgId) {
      case 'ypf':
        return 'SSO_IdP';
      default:
        return 'INTERNAL_IdP';
    }
  }

  build(orgId: string): IdentityProvider {
    const idpType = this.inferIdPTypeFromOrgId(orgId);
    if (idpType === 'SSO_IdP') return this.buildSSOIdentityProvider(orgId);
    return this.buildInternalAuthIdentityProvider();
  }

  private buildInternalAuthIdentityProvider(): IdentityProvider {
    return {
      type: 'INTERNAL_IdP',
      ...FirebaseAuth,
    };
  }

  private buildSSOIdentityProvider(orgId: string): IdentityProvider {
    let ssoIdP: SSOIdentityProvider;
    switch (orgId) {
      case 'ypf': {
        const YPFSAMLAuthProvider = new SAMLAuthProvider('saml.ypf');
        ssoIdP = new YPFSSOIdentityProvider(
          auth,
          YPFSAMLAuthProvider,
          signInWithRedirect
        );
        break;
      }

      default: {
        throw new Error(
          `No SSO Identity Provider configured for OrgId${orgId}`
        );
      }
    }

    return ssoIdP;
  }
}
