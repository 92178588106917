import { Scope } from '@voltbras/auth-directives';
import Loader from 'components/Loader';
import config from 'config';
import { StationConnectivityStatus } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import { NewTag } from 'new-components/NewTag';
import React, { useContext } from 'react';
import { LoadingContainer } from 'styles';
import { Box, Flex, Heading, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TranslationKeyType } from 'types/translation';
import OwnerInternalUse from './OwnerInternalUse';
import StationAnalytics from './StationAnalytics';
import { StationContext, StationProvider } from './StationContext';
import StationControl from './StationControl';
import StationHeader from './StationHeader';
import { StationLogs } from './StationLogs';
import StepByStepConfiguration from './StepByStepConfiguration/index';
import StationConfiguration from './StationConfiguration';

type TabProps = {
  title: TranslationKeyType;
  path: string;
  component: any;
  requiredScope: Scope[];
  icon?: keyof NotNestedNewIcons;
  counter?: string;
  id: number;
};

export const tabs: TabProps[] = [
  {
    title: 'station-page.general-tab.title',
    path: '/',
    component: StationAnalytics,
    requiredScope: ['station:read'],
    icon: 'NEW_METRICS',
    id: 1,
  },
  {
    title: 'station-page.control-tab.title',
    path: '/control',
    component: StationControl,
    requiredScope: ['station:control'],
    icon: 'NEW_HOME',
    id: 2,
  },
  {
    title: 'station-page.logs-tab.title',
    path: '/logs',
    component: StationLogs,
    icon: 'NEW_BURGER_MENU',
    requiredScope: ['alarm:read'],
    counter: 'N',
    id: 3,
  },
  {
    title: 'station-page.settings-tab.title',
    path: '/settings',
    component: StationConfiguration,
    requiredScope: ['station:edit'],
    icon: 'NEW_GEAR',
    id: 4,
  },
  {
    title: 'station-page.internal-use-tab.title',
    path: '/owner/internaluse',
    icon: undefined,
    requiredScope: ['station:internal'],
    component: OwnerInternalUse,
    id: 5,
  },
  {
    title: 'station-page.step-by-step-tab.title',
    path: '/step-configuration',
    icon: undefined,
    requiredScope: ['station:internal'],
    component: StepByStepConfiguration,
    id: 6,
  },
];

export type KnownTab = typeof tabs[number];

type Props = {
  stationId: string;
  onTabChange: (tab: KnownTab) => void;
  currentTab: KnownTab;
  children?: any;
};

const StationPageInsideContext: React.FC<React.PropsWithChildren<Props>> = ({
  stationId,
  children,
  currentTab,
  onTabChange,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  if (!stationId) throw new Error(t('station-page.invalid-station-error'));

  const {
    lastConnectedAt,
    stationName,
    stationConnectivityStatus,
    setStationId,
    loading,
    activeAlarmsCount,
    archived,
    cpoId,
  } = useContext(StationContext);

  setStationId(stationId);

  const filteredTabs = tabs.filter((tab) => hasScopes(tab.requiredScope));

  const currentTabIndex = filteredTabs
    .map((tab) => tab.id)
    .indexOf(currentTab.id as TabProps['id']);

  const connectivityStatusToLabel = (status: StationConnectivityStatus) =>
    t(config.LABELS.STATION_CONNECTIVITY_STATUS[status]);

  return (
    <Flex flexFlow="column" w="100%" height="100%" boxSizing="border-box">
      {loading ? (
        <LoadingContainer>
          <Loader color="INFO_GRAY" size={50} />
        </LoadingContainer>
      ) : (
        <>
          <StationHeader
            cpoId={cpoId}
            tag={
              <>
                <NewTag
                  type="text"
                  size="big"
                  status={stationConnectivityStatus}
                >
                  {connectivityStatusToLabel(stationConnectivityStatus)}
                </NewTag>
                {archived && (
                  <NewTag
                    type="text"
                    color="danger"
                    size="big"
                    status={archived}
                  >
                    {t('station-page.header.tag.title')}
                  </NewTag>
                )}
              </>
            }
            info={lastConnectedAt}
          >
            {stationName}
          </StationHeader>

          <Box>
            <Tabs index={currentTabIndex}>
              <TabList pl="6">
                {filteredTabs.map((tab, index) => (
                  <Tab
                    data-test={`sub-page${index}`}
                    onClick={() => onTabChange(tab)}
                    key={index}
                  >
                    {tab.icon && (
                      <Box mr={2} mb={0.5}>
                        <Icon
                          type={tab.icon}
                          color={
                            currentTabIndex === index
                              ? 'PRIMARY_GRAY'
                              : 'SECONDARY_GRAY'
                          }
                        />
                      </Box>
                    )}
                    {t(tab.title)}
                    {
                      // The id 3 is equivalent to station logs tab,
                      // don't use title to compare because it may change with internationalization.
                      tab.id === 3 && activeAlarmsCount > 0 && (
                        <Flex
                          height={4}
                          borderRadius="full"
                          alignItems="center"
                          justifyContent="center"
                          bg="error.500"
                          ml={2}
                        >
                          <Heading color="white" fontSize="small" p="1.5">
                            {activeAlarmsCount}
                          </Heading>
                        </Flex>
                      )
                    }
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
          <Box overflowY="auto" h="100%">
            {children}
          </Box>
        </>
      )}
    </Flex>
  );
};

export const StationPage = (props: Props) => (
  <StationProvider>
    <StationPageInsideContext {...props} />
  </StationProvider>
);
