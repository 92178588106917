import useHasScopes from 'hooks/useHasScope';
import Modal from 'new-components/NewModal';
import GroupOptionsDropdown from 'new-components/GroupOptionsDropdown';
import React, { useContext, useState } from 'react';
import ConfirmGroupDeletionModal from 'atomic-components/organisms/modals/ConfirmGroupDeletion';
import {
  Box,
  TabList,
  Tab,
  Tabs,
  Heading,
  TabPanels,
  TabPanel,
  Tag,
  Flex,
} from '@chakra-ui/react';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import Stations from './Stations';
import { Container } from './styles';
import Users from './Users';

type Props = {
  id: any;
  show: boolean;
  name: string;
  orgId?: string;
  onClose: () => void;
  updateListGroups: () => void;
};

type TabProps = {
  icon: keyof NotNestedNewIcons;
  text: string;
};

const DriverModal: React.FC<Props> = ({
  id,
  onClose,
  show,
  name,
  updateListGroups,
  orgId,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { orgNameMap } = useContext(AuthContext);
  const tabs = [
    hasScopes(['group:read']) && {
      icon: 'NEW_USER',
      text: t('access-groups-modal.users-tab.title'),
    },

    hasScopes(['group:read']) && {
      icon: 'NEW_AC_CHARGER',
      text: t('access-groups-modal.station-tab.title'),
    },
  ] as TabProps[];

  const CloseDeletionModal = () => {
    setDeleteGroup(false);
  };

  return (
    <Container show={show} onClose={onClose} size="lg">
      {hasScopes(['group:edit']) && (
        <Modal.Header.Attachments padding="24">
          <GroupOptionsDropdown onDeleteGroup={() => setDeleteGroup(true)} />
          <Modal.Header.Close onClose={onClose} />
        </Modal.Header.Attachments>
      )}
      <ConfirmGroupDeletionModal
        groupId={id}
        show={deleteGroup}
        onClose={CloseDeletionModal}
        closeModalContainer={onClose}
        updateListGroups={updateListGroups}
      />
      <Tabs onChange={(index) => setCurrentIndex(index)}>
        <Flex pl={6} pt={8}>
          <Heading fontSize="lg">{name}</Heading>
          <Tag
            marginLeft={5}
            bg={'gray.200'}
            padding={'1.5 2'}
            textAlign={'center'}
          >
            <Heading fontSize="14px" color={'white'} width={'full'}>
              {orgId ? formatOrgId(orgId, orgNameMap) : '-'}
            </Heading>
          </Tag>
        </Flex>
        <TabList pl={6} pt={6} mt={2}>
          {tabs
            .filter((tab) => !!tab)
            .map((tab, index) => (
              <Tab>
                <Box mr={2} mb={0.5}>
                  <Icon
                    type={tab.icon}
                    color={
                      index === currentIndex ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'
                    }
                  />
                </Box>
                {tab.text}
              </Tab>
            ))}
        </TabList>
        <TabPanels maxHeight="74vh" flex="1" overflow="auto" bg="gray.50">
          <TabPanel p={0}>
            {hasScopes(['group:read']) && (
              <Users groupId={id} updateGroupSummary={updateListGroups} />
            )}
          </TabPanel>
          <TabPanel p={0}>
            {hasScopes(['group:read']) && (
              <Stations groupId={id} updateGroupSummary={updateListGroups} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default DriverModal;
