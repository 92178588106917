import { Center } from '@chakra-ui/react';
import { useURLParams } from 'hooks/useURLParam';
import { BlankState } from 'new-components/BlankState';
import { useTranslation } from 'react-i18next';
import { MemberResetPasswordForm } from './member-reset-password';
import { DriverResetPasswordForm } from './driver-reset-password';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const urlParams = useURLParams();

  const resetCode = urlParams.get('passwordResetToken');

  const emspId = urlParams.get('emspId') ?? 'dashboard';
  let accountType: 'Member' | 'Driver' = 'Member';
  if (emspId !== 'dashboard') {
    accountType = 'Driver';
  }

  if (!resetCode) {
    return (
      <Center h="full">
        <BlankState
          description={t('reset-password.blank-state.description')}
          title={t('reset-password.blank-state.title')}
          icon="NEW_ERROR"
        />
      </Center>
    );
  }

  return (
    <Center h="full" flexDirection="column" margin="auto" maxWidth="md" p={4}>
      {accountType === 'Driver' && (
        <DriverResetPasswordForm resetCode={resetCode} orgId={emspId} />
      )}
      {accountType === 'Member' && (
        <MemberResetPasswordForm resetCode={resetCode} />
      )}
    </Center>
  );
};
