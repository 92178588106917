import { Auth } from '@voltbras/auth-client';
import { t } from 'i18next';
import sendPasswordRedefinitionEmail from 'graphql/mutations/sendPasswordRedefinitionEmail';
import Config from 'config';
import {
  GenerateMemberAuthTokenMutation,
  GenerateMemberAuthTokenMutationVariables,
  RequestPasswordRedefinitionEmailMutation,
  RequestPasswordRedefinitionEmailMutationVariables,
} from 'generated/graphql';
import {
  MultiFactorError,
  onAuthStateChanged,
  signOut,
  User,
  signInWithCustomToken,
} from 'firebase/auth';
import generateMemberAuthToken from 'graphql/mutations/generateMemberAuthToken';
import { createClient } from 'graphql/client';
import { auth } from 'services/identity-provider/firebaseConfig';
import { setErrorInfoInLocageStorage } from 'services/tfa';
import {
  getUserInactivationErrorMessage,
  passwordExpirationErrorMessage,
} from 'services/utils';
import {
  InactiveUserError,
  LoginFailedError,
  MFARequiredError,
  PasswordExpiredError,
  CooldownError,
} from './errors';

const getMemberAuthTokenFromAPI = async (
  email: string,
  password: string
): Promise<{ customToken: string }> => {
  const client = createClient();
  const { data, error } = await client
    .mutation<
      GenerateMemberAuthTokenMutation,
      GenerateMemberAuthTokenMutationVariables
    >(generateMemberAuthToken, {
      data: {
        email,
        password,
      },
    })
    .toPromise();

  if (!data) throw new LoginFailedError();

  if (error) throw new LoginFailedError();

  if (data.generateMemberAuthToken.__typename === 'MemberAuthenticationError') {
    if (data.generateMemberAuthToken.type === 'MFA_REQUIRED')
      throw new MFARequiredError(
        data.generateMemberAuthToken.serializedErrorObject
      );
    if (data.generateMemberAuthToken.type === 'INACTIVE_USER')
      throw new InactiveUserError(data.generateMemberAuthToken.message);
    if (data.generateMemberAuthToken.type === 'EXPIRED_PASSWORD')
      throw new PasswordExpiredError();
    if (data.generateMemberAuthToken.type === 'MEMBER_LOGIN_LOCKED')
      throw new CooldownError();

    throw new LoginFailedError();
  }

  return {
    customToken: data.generateMemberAuthToken.authToken,
  };
};

const getAuthTokenFromFirebase = async (
  firebaseUser: User
): Promise<{ authToken: string }> => {
  const authToken = await firebaseUser.getIdToken(true);
  return { authToken };
};

export const FirebaseAuth: Auth = {
  emailLogin: async (email, password) => {
    try {
      const { customToken } = await getMemberAuthTokenFromAPI(email, password);

      const { user: firebaseUser } = await signInWithCustomToken(
        auth,
        customToken
      );
      if (!firebaseUser) {
        return { error: t('login.incorrect-fields-error-message') };
      }

      return { authToken: await firebaseUser.getIdToken(true) };
    } catch (error: any) {
      if (error instanceof InactiveUserError)
        return getUserInactivationErrorMessage(error);

      if (error instanceof PasswordExpiredError)
        return passwordExpirationErrorMessage();

      if (error instanceof MFARequiredError) {
        const twoFactorErrorInfo = JSON.parse(
          error.message
        ) as MultiFactorError;
        setErrorInfoInLocageStorage(twoFactorErrorInfo);
        return { error: 'Autenticação em 2 etapas é obrigatório' };
      }

      if (error instanceof LoginFailedError) {
        return { error: t('login.incorrect-fields-error-message') };
      }

      if (error instanceof CooldownError) {
        return { error: 'CooldownError' };
      }

      return { error: t('login.generic-error-message') };
    }
  },
  signUp: async (_input) => {
    throw new Error('Este método ainda não foi implementado');
  },
  async autoLogin() {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) resolve(getAuthTokenFromFirebase(user));
        resolve({ authToken: undefined });
      });
    });
  },

  async requestForgotPasswordEmail(email: string) {
    const client = createClient();
    try {
      const result = await client
        .mutation<
          RequestPasswordRedefinitionEmailMutation,
          RequestPasswordRedefinitionEmailMutationVariables
        >(sendPasswordRedefinitionEmail, { email, orgId: Config.CODE })
        .toPromise();

      if (result.error) throw new Error(result.error.message);
    } catch (error: any) {
      if (error.message.includes('Member not found'))
        return { error: 'e-mail inválido' };
      return { error: 'erro ao enviar o e-mail de esqueci a senha' };
    }
    return {};
  },
  async getRefreshedAuthToken() {
    const authToken = await this.autoLogin();
    if (!authToken) return { error: 'erro ao pegar token de autenticação' };
    return authToken;
  },

  logout: async () => signOut(auth),

  checkIfEmailAlreadyIsUsed: async (_email) => {
    throw new Error('Este método ainda não foi implementado');
  },
};
