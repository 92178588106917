import { Typography } from 'components';
import { Icon } from 'new-components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

type IconSize = 'small' | 'default' | 'large';

interface Props {
  top?: number;
  size?: IconSize;
  className?: string;
}

const ErrorComponent: React.FC<Props> = ({ top, size, className }) => {
  const { t } = useTranslation();
  const errorTextToDisplay = t('gerenic-loading-error.message');
  const notNullableIconSize = size ?? 'default';

  const getIconSize = (size: IconSize) => {
    switch (size) {
      case 'small':
        return 16;
      case 'default':
        return 36;
      case 'large':
        return 64;
    }
  };

  return (
    <Container
      top={top}
      className={className}
      size={getIconSize(notNullableIconSize)}
      direction={notNullableIconSize === 'small' ? 'row' : 'column'}
    >
      <Icon
        color="SECONDARY_GRAY"
        type="NEW_CLOSE_X"
        size={getIconSize(notNullableIconSize)}
      />
      <Typography size={14} color={'SECONDARY_GRAY'}>
        {errorTextToDisplay}
      </Typography>
    </Container>
  );
};

export default ErrorComponent;
