import ConfigType from 'config/typing';
import React from 'react';
import { Icon } from 'new-components/Icon';
import { Container, Title, Info } from './styles';

export type Props = {
  title: string;
  info: string;
  icon: keyof ConfigType['NEW_ICONS'];
};

const PaymentMethodInfo: React.FC<Props> = ({ title, info, icon }) => (
  <>
    <Container>
      <Icon size={20} type={icon} color="PRIMARY"></Icon>
      <Title>{title}</Title>
      <Info>{info}</Info>
    </Container>
  </>
);

export default PaymentMethodInfo;
