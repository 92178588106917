import Config from 'config';
import { StationStatus } from 'generated/graphql';
import { NewTag } from 'new-components/NewTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';
import {
  CardsContainer,
  ContentContainer,
  SatationStatus,
  StatusAndAlarmsCard,
  TagContainer,
} from './styles';

type Props = {
  statusCount: Record<StationStatus, number>;
  loading: boolean;
};

const MapHeader: React.FC<Props> = ({ statusCount, loading }) => {
  const { t } = useTranslation();
  const renderTag = (currentStatus: StationStatus) => {
    if (Object.prototype.hasOwnProperty.call(statusCount, currentStatus))
      return (
        <TagContainer>
          <SkeletonCircle size={'5'} display={'flex'} isLoaded={!loading}>
            <NewTag type="text" status={currentStatus}>
              {statusCount[currentStatus]}
            </NewTag>
          </SkeletonCircle>
        </TagContainer>
      );
  };

  const stationStatus = Config.LABELS.STATION_STATUS;

  const mapStationStatus = () =>
    Object.entries(stationStatus)
      .filter(([STATUS]) => STATUS !== 'PLANNED' && STATUS !== 'RESERVED')
      .map(([STATUS, value]) => (
        <StatusAndAlarmsCard key={STATUS}>
          <Skeleton isLoaded={!loading}>
            <SatationStatus>{t(value)}</SatationStatus>
          </Skeleton>
          {renderTag(STATUS as StationStatus)}
        </StatusAndAlarmsCard>
      ));

  return (
    <ContentContainer>
      <CardsContainer>{mapStationStatus()}</CardsContainer>
    </ContentContainer>
  );
};

export { MapHeader };
