import { Flex, Heading, ScaleFade, Text } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';

export const SuccessSubmitFeedback = () => {
  const { t } = useTranslation();

  return (
    <ScaleFade initialScale={0.4} in={true}>
      <Flex
        gap={4}
        maxWidth="sm"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Icon type="NEW_SUCCESS" color="AVAILABLE" size={28} />
        <Heading fontSize="3xl">
          {t('reset-password.success-submit-feedback.title')}
        </Heading>
        <Text color="gray.500" textAlign="center">
          {t('reset-password.success-submit-feedback.subtitle')}
        </Text>
      </Flex>
    </ScaleFade>
  );
};
