import { transformToTableFilters } from 'components/Filter/helper';
import Table, { Column, FetchDataFunction } from 'components/Table';
import { useMemo } from 'react';
import {
  FormattedSession,
  sessionFieldLabelTable,
} from 'services/formatSessionRow';
import { TimeRange } from 'services/timeRange';
import { getColumnOptions } from 'services/formatSessionTable';
import { useTranslation } from 'react-i18next';
import { Term } from '..';

type Props<T extends Partial<FormattedSession>> = {
  fetchData: FetchDataFunction<T>;
  data: T[];
  pageCount: number;
  columns: ReadonlyArray<keyof T>;
  appliedFilters: Term[];
  timeRange?: TimeRange;
  loading?: boolean;
  handleRowClick: (sessionId?: string, cpoId?: string, emspId?: string) => void;
};

function SessionTable<T extends Partial<FormattedSession>>({
  fetchData,
  data,
  columns,
  pageCount,
  appliedFilters,
  timeRange,
  loading,
  handleRowClick,
}: Props<T>) {
  const { t } = useTranslation();
  const selectedColumns: Column<T>[] = Object.entries(sessionFieldLabelTable)
    .filter(([key]) => columns.includes(key as keyof T))
    .map(([key, label]) => getColumnOptions(key, t(label)));

  const tableFilters = useMemo(
    () => transformToTableFilters(appliedFilters),
    [appliedFilters]
  );

  return (
    <Table<T>
      fetchData={fetchData}
      data={data}
      pageCount={pageCount}
      columns={selectedColumns}
      onRowPress={({ original: { id, cpoId, emspId } }) =>
        handleRowClick(id, cpoId, emspId)
      }
      paginated
      columnMaxWidth={125}
      filters={tableFilters}
      timeRange={timeRange}
      loading={loading}
    />
  );
}

export default SessionTable;
