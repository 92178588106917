import {
  StationLogsAlarmsListingQuery,
  StationLogsAlarmsListingQueryVariables,
} from 'generated/graphql';
import stationLogsAlarmsListing from 'graphql/queries/stationLogsAlarmsListing';
import { useAuthQuery } from 'hooks';
import { BlankState } from 'new-components/BlankState';
import LoadingComponent from 'new-components/LoadingComponent';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StationContext } from '../../StationContext';
import { StationLogsContext } from '../StationLogsContext';
import { StationAlarmFilter } from './StationAlarmFilter';
import { StationAlarmList } from './StationAlarmList';
import { AlarmsContainer, ErrorComponent, Separator } from './styles';

export const StationAlarms: React.FC = () => {
  const { t } = useTranslation();
  const { id: stationId } = useContext(StationContext);
  const [alarmCounts, setAlarmCounts] = useState<{
    resolved: number;
    active: number;
  }>();
  const [offset, setOffset] = useState(0);
  const { alarms, filterResolvedAlarms, setAlarms } =
    useContext(StationLogsContext);

  const {
    data: response,
    error,
    loading,
  } = useAuthQuery<
    StationLogsAlarmsListingQuery,
    StationLogsAlarmsListingQueryVariables
  >(stationLogsAlarmsListing, {
    variables: {
      stationId,
      limit: offset + 10,
      offset,
      resolved: filterResolvedAlarms,
    },
    skip: !stationId,
  });

  useEffect(() => {
    const activeCount = response?.station?.activeSummary.count;
    const resolvedCount = response?.station?.resolvedSummary.count;

    if (activeCount === undefined || resolvedCount === undefined) return;
    setAlarmCounts({ active: activeCount, resolved: resolvedCount });
    if (!loading) {
      const newAlarms = response?.station?.alarms;
      if (newAlarms?.length) {
        setAlarms((old) => {
          const newDiffAlarms = newAlarms.filter(
            (a) => !old.some((o) => o.id === a.id)
          );
          if (newDiffAlarms.length === 0) return old;
          return [...old, ...newDiffAlarms];
        });
      }
    }
  }, [response, loading, alarms, setAlarmCounts, setAlarms]);

  useEffect(() => {
    setOffset(0);
  }, [filterResolvedAlarms]);

  const fetchMoreAlarms = useCallback((offset: number) => {
    setOffset(offset);
  }, []);

  const alarmListContentControl = useCallback(() => {
    // Can't be alarms, because there is a flink in react state, then can't use
    // react state and query loading
    if (!response?.station?.alarms.length && !error && !loading)
      return (
        <BlankState
          description={t(
            'station-page.station-logs.station-alarms.description'
          )}
          icon="NEW_CHECK_CIRCLE_OUTLINE"
          title={t('station-page.station-logs.station-alarms.title')}
        />
      );

    if (!alarms.length) return <LoadingComponent size={32} top={32} />;
    if (error) return <ErrorComponent />;
    return (
      <>
        <StationAlarmList
          alarms={alarms || []}
          onScrollEnd={fetchMoreAlarms}
          totalAlarms={
            filterResolvedAlarms
              ? alarmCounts?.resolved ?? 0
              : alarmCounts?.active ?? 0
          }
        />
      </>
    );
  }, [
    loading,
    response,
    fetchMoreAlarms,
    alarms,
    error,
    filterResolvedAlarms,
    alarmCounts,
  ]);

  return (
    <>
      <StationAlarmFilter
        activeCount={alarmCounts?.active}
        resolvedCount={alarmCounts?.resolved}
      />
      <Separator />
      <AlarmsContainer id="AlarmsListContainer">
        {alarmListContentControl()}
      </AlarmsContainer>
    </>
  );
};
