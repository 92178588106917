import { ApolloError } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import Card from 'components/Card';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import ConnectorsList from 'components/ConnectorsList';
import ConnectorsListHelp from 'components/ConnectorsList/ConnectorsListHelp';
import Filter, {
  FilterTerms,
  transformToTableFilters,
} from 'components/Filter';
import Link from 'components/Link';
import Search from 'atomic-components/molecules/Search';
import StationLink from 'components/StationLink';
import Table, { Column, FetchDataFunction, Row } from 'components/Table';
import Config from 'config';
import useHasScopes from 'hooks/useHasScope';
import StationSettingsDropdown from 'new-components/StationSettingsDropdown';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FormattedStation,
  getStationFieldLabel,
} from 'services/formatStationRow';
import { FilterInstance, FilterTerm } from 'types';
import { useTranslation } from 'react-i18next';
import {
  AddStationContainer,
  ButtonContainer,
  DetailsButton,
  FilterButtonContainer,
  StationTableContainer,
} from './styles';

type FilterKeys = 'city' | 'stationName';
type Term = FilterTerm<FilterKeys>;

export type Props<T extends Partial<FormattedStation> & { id: string }> = {
  selectedColumns: ReadonlyArray<keyof T>;
  hasFooter: boolean;
  data: T[];
  pageCount: number;
  fetchData: FetchDataFunction<T>;
  loading: boolean;
  error?: ApolloError;
  canSearch: boolean;
  showStationDetailButton?: boolean;
  onRowPress?: (row: Row<T>) => void;
};

const areTermsEqual = (termA: Term, termB: Term) =>
  termA.value === termB.value && termA.type === termB.type;

const selectStationDetailColumn: Column<any> = {
  Header: () => null,
  id: 'stationDetailsButton',
  disableSortBy: true,
  Cell: () => <DetailsButton>Detalhes</DetailsButton>,
};

function StationTableCard<T extends Partial<FormattedStation> & { id: string }>(
  props: Props<T>
) {
  const { t } = useTranslation();

  const filterSettings: FilterInstance<FilterKeys>[] = [
    {
      inputType: 'text',
      type: 'city',
      placeholder: t('filter-by-city.placeholder'),
      label: t('filter-by-city.title'),
    },
    {
      inputType: 'text',
      type: 'stationName',
      placeholder: t('filter-by-station.placeholder'),
      label: t('filter-by-station.title'),
    },
  ];

  const hasScopes = useHasScopes();
  const {
    selectedColumns,
    hasFooter,
    data,
    fetchData,
    loading,
    error,
    canSearch,
    onRowPress,
    pageCount,
    showStationDetailButton,
  } = props;

  const history = useHistory();

  const [columns] = useState<Column<T>[]>(
    selectedColumns
      .filter((key) => key !== 'id')
      // @ts-ignore
      .map<Column<T>>((key) => {
        // @ts-ignore
        const options: Column<T> = {};

        if (key === 'stationName') {
          // @ts-ignore
          options.Cell = ({ row: { original } }) => (
            <StationLink
              stationId={original.id!}
              stationName={original.stationName!}
              cpoId={original.cpoId}
            />
          );
        } else if (key === 'connectors') {
          options.disableSortBy = true;
          options.Header = (
            <>
              {t('home.station-use-of-connectors.title')}
              <ConnectorsListHelp />
            </>
          );
          // @ts-ignore
          options.Cell = ({ row: { original } }) => (
            <ConnectorsList
              connectors={original.connectors ?? []}
              stationId={original.id}
            />
          );
        } else if (key === 'stationConfigs') {
          options.disableSortBy = true;
          options.Header = <></>;

          // @ts-ignore
          options.Cell = ({ row: { original } }) =>
            (hasScopes(['station:internal']) ||
              hasScopes(['station:edit'])) && (
              <StationSettingsDropdown stationId={original.id} />
            );
        } else if (key === 'orgName') {
          options.disableSortBy = true;
          options.Header = <>{t('home.org.title')}</>;

          // @ts-ignore
          options.Cell = ({ row: { original } }) =>
            (hasScopes(['station:internal']) ||
              hasScopes(['station:edit'])) && <>{original.orgName}</>;
        }
        const stationFieldLabel = getStationFieldLabel(
          key as keyof FormattedStation
        );
        return {
          Header: stationFieldLabel && t(stationFieldLabel),
          accessor: key,
          ...options,
        };
      })
      // @ts-ignore
      .concat(showStationDetailButton ? selectStationDetailColumn : [])
  );

  const [appliedFilterTerms, setAppliedFilterTerms] = useState<Term[]>([]);
  const removeAppliedFilter = (term: Term) =>
    setAppliedFilterTerms(
      appliedFilterTerms.filter((x) => !areTermsEqual(x, term))
    );
  const [isFilterPanelVisible, setFilterPanelVisibility] = useState(false);
  const toggleFilterPanel = () => setFilterPanelVisibility((prev) => !prev);

  const [searchValue, setSearchValue] = useState('');

  const tableFilters = useMemo(
    () => transformToTableFilters(appliedFilterTerms),
    [appliedFilterTerms]
  );

  return (
    <Card minHeight={841} type="complex" error={error} loading={false}>
      <CardHeader>
        <CardTitle title={t('station-table-card.title')} />
        {canSearch && (
          <>
            <FilterTerms
              renderOnHeader={true}
              terms={appliedFilterTerms}
              removeTerm={removeAppliedFilter}
            />
            <ButtonContainer>
              <FilterButtonContainer>
                <Filter
                  filters={filterSettings}
                  toggleFilterPanel={toggleFilterPanel}
                  active={isFilterPanelVisible}
                  setAppliedFilterTerms={setAppliedFilterTerms}
                  appliedFilterTerms={appliedFilterTerms}
                />
              </FilterButtonContainer>
              <Box mr="9">
                <Search
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  placeholder={t('search-station-by-name-input.title')}
                />
              </Box>
            </ButtonContainer>
          </>
        )}
        {hasScopes(['staff']) && (
          <AddStationContainer
            onClick={() => history.push('owner/create')}
            data-test="add-station-button"
          >
            <Config.ICONS.ADD />
          </AddStationContainer>
        )}
      </CardHeader>
      <StationTableContainer hasFooter={hasFooter}>
        <Table<T>
          fetchData={fetchData}
          data={data}
          pageCount={pageCount}
          columns={columns.slice()}
          filters={tableFilters}
          searchValue={searchValue}
          onRowPress={onRowPress}
          columnMaxWidth={200}
          paginated
          loading={loading}
        />
      </StationTableContainer>
      {hasFooter && (
        <CardFooter>
          <Link to="/historico" size={16}>
            {t('station-table-card.show-complete-history')}
          </Link>
        </CardFooter>
      )}
    </Card>
  );
}

// TODO: typar corretamente
interface propsEqual {
  (a: any, b: any): boolean;
}

const typedMemo: <T>(t: T, arePropsEqual: propsEqual) => T = React.memo;

export default typedMemo(
  StationTableCard,
  (a, b) =>
    JSON.stringify(a.data) === JSON.stringify(b.data) && a.loading === b.loading
);
