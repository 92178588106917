export default `
Termos de Uso

RESUMO DOS TERMOS DO CONTRATO DO USUÁRIO DA EDP GRID
Abaixo, a EDP Grid – Gestão de Redes Inteligentes de Distribuição S.A. (“EDP GRID”), empresa regularmente inscrita no CNPJ sob o nº 02.154.070/0008-04[], com sede na cidade de São Paulo, Estado de São Paulo, na Rua Gomes de Carvalho nº 1.996, 8º andar, disponibiliza a você um resumo das regras principais do seu contrato. O contrato completo (“Contrato do Usuário”) é composto por este Termos de Uso da EDP Grid e pela Política de Privacidade da EDP Grid, cuja leitura atenta a EDP GRID recomenda.
A EDP Smart é uma marca registrada pela EDP Brasil, a qual tem como objetivo a oferta de serviços, a comercialização e soluções em energia, através das empresas EDP, dentre elas a EDP Grid.
Os Termos de Uso se referem à utilização dos serviços de recarga de veículos elétricos, oferecidos pela EDP Smart, compostos por um aplicativo (EV.Charge) e pelo site https://mobilidade.edpsmart.com.br, além de outros que venham a complementar a sua oferta de serviços, estritamente voltados para esta finalidade. 
Ao utilizar os serviços da EPD Smart, Você está de acordo com o Contrato do Usuário e eventuais alterações unilaterais promovidas pela EDP Smart, cuja responsabilidade pela verificação periódica das alterações e aprimoramentos compete a Você. Caso Você não concorde com o Contrato do Usuário, não poderá utilizar os serviços da EDP Smart.

1. Produtos e Preços da EDP Smart
A EDP Smart oferece diversas soluções de mobilidade sustentável, dentre as quais destacamos:
    (i) aquisição ou aluguel de carregadores elétricos B2C (proprietários de veículos elétricos que desejam manter em sua residência carregador elétrico para veículos próprios);
    (ii) aquisição ou aluguel de carregadores elétricos B2B (frotas de veículos, locadoras de automóveis, empresas de aplicativos para compartilhamento de veículos);
    (iii) estação públicas de recarga de veículos elétricos.

O preço do carregamento elétrico de veículos, indicado junto às estações de recarga, varia de acordo com cada estação de recarga, tipo de tomada e potência, e quantidade de tempo e energia utilizados para recarga do veículo elétrico. Os valores finais poderão apresentar variações mediante descontos ou condições especiais para Você, caso a EDP Smart oferte esta possibilidade. 

2. Créditos EDP Smart
Créditos EDP Smart são uma espécie de tokens virtuais que Você adquire por meio de pagamento em formato pré-pago:
    (i) com cartão de crédito; ou
    (ii) por meio de pagamento de boleto bancário emitido pela EDP Smart. 

Os Créditos EDP Smart não expiram e servem para pagar por todas as recargas que fizer em seu veículo elétrico. Cada Crédito EDP Smart custa R$ 1 (um real). Os Créditos EDP Smart não são reembolsáveis, inclusive no caso de a EDP Smart interromper ou encerrar o oferecimento de soluções de mobilidade sustentável.
Os Créditos EDP Smart constituirão um saldo que será consumido mediante a utilização dos serviços da EDP Smart por Você, mediante a precificação de cada serviço. 

3. Armazenamento de Créditos EDP Smart 
Seus Créditos EDP Smart ficam armazenados junto ao cadastro do usuário, identificado através de seu CPF, e vinculados à codificação do cartão eletrônico (“EV CARD”), o qual também serve para desbloquear, por aproximação, Estações de Recarga EDP Smart, e podem ser adquiridos no site https://mobilidade.edpsmart.com.br ou pelo aplicativo “EV.Charge”, ambos fornecidos pela EDP Smart. 

4. Estações de Recarga EDP Smart
As Estações de Recarga da EDP Smart estão disponíveis em diversas cidades do Brasil. O mapa de estações de recarga pode ser visualizado no site https://mobilidade.edpsmart.com.br ou pelo aplicativo “EV.Charge”. Pelo mapa é possível consultar os horários e dias de disponibilidade de cada estação de recarga, bem como se a estação se encontra disponível, ocupada ou temporariamente fora de funcionamento.
As Estações de Recarga EDP Smart oferecem carregamento de energia elétrica para veículos elétricos, e algumas delas são operadas mediante pagamento via Créditos EDP Smart, na forma prevista pela Resolução Normativa ANEEL nº 819/2018.
Você poderá recarregar seu veículo elétrico utilizando os Créditos EDP Smart, de forma pré-paga, ou por meio da utilização de seu cartão de crédito, em formato pós-pago. Na opção de pagamento pós-pago por cartão de crédito, será feita uma pré-autorização no momento em que você selecionar, por meio do aplicativo, a recarga do seu veículo elétrico. Ao final da recarga, será descontado o valor correspondente à utilização do serviço para abastecimento, sobre o valor pré-autorizado. 

5. Coleta de dados
Além dos dados fornecidos por você em seu cadastro no aplicativo e no site https://mobilidade.edpsmart.com.br, a EDP Smart coleta, de forma anonimizada, dados identificadores do consumo de energia elétrica do seu veículo elétrico durante as recargas, de modo a nos permitir aprimorar a prestação dos nossos serviços e melhorar sua experiência.

TERMOS DE USO DA edp GRID
A EDP Smart é uma marca registrada pela EDP Brasil, a qual tem como objetivo a oferta de serviços, a comercialização e soluções em energia, através das empresas EDP, dentre elas a EDP Grid – Gestão de Redes Inteligentes de Distribuição S.A. (“EDP GRID”).
A EDP Smart oferece serviços de energia elétrica, dentre eles aquisição de carregadores elétricos para veículos elétricos e venda de serviço de recarga para veículos elétricos em estações (conforme Resolução Normativa ANEEL nº 819/18), em diversas cidades brasileiras (as “Cidades”), que podem ser consultadas no Aplicativo “EV.Charge” (o “Aplicativo “EV.Charge””) ou no site https://mobilidade.edpsmart.com.br.

1. Usuários do Aplicativo “EV.Charge”.
Para poder se tornar um usuário e usufruir dos Serviços por meio do Aplicativo “EV.Charge”, Você precisa: (i) estar localizado dentro do Brasil; (ii) ter mais de 18 (dezoito anos) e gozar de plena capacidade legal; (iii) ter o conhecimento, a habilidade e as condições físicas e de saúde necessárias para utilizar os Serviços; (iv) portar um dispositivo móvel compatível com o Aplicativo “EV.Charge” e com acesso à Internet; e (v) ter lido atentamente e concordado com o Contrato do Usuário.

2. Cadastro dos usuários.
Para utilizar os Serviços, você precisa seguir os seguintes passos: (i) obter o Aplicativo “EV.Charge”, disponível na Apple Store e no Google Play, de acordo com o sistema operacional do seu dispositivo móvel; (ii) criar uma conta no Aplicativo “EV.Charge” vinculando sua conta do Facebook ou do Google ao fazer o login pela plataforma que você escolher; (iii) completar seu cadastro com as informações necessárias solicitadas pelo Aplicativo “EV.Charge”; e (iv) cadastrar um meio de pagamento válido. Caso escolha um cartão de crédito como forma de pagamento tanto para aquisição dos Créditos EDP Smart (formato pré-pago) quanto para pagamento dos serviços após a utilização (formato pós-pago), Você declara ser o titular deste cartão de crédito ou deter a permissão necessária do titular do cartão para utilizá-lo na compra de Créditos EDP Smart.
Você declara que forneceu à EDP Smart informações verdadeiras, precisas, atuais e completas, para fins de seu cadastro, tendo ciência que eventual falha em fornecer tais informações constituirá uma violação ao Contrato do Usuário e poderá resultar na rescisão imediata da sua conta. Você garante que atualizará suas informações de cadastro para mantê-las verdadeiras, precisas, atuais e completas, mantendo a confidencialidade de sua senha.
Você garante e responde, em qualquer caso, civil e criminalmente, pela veracidade, exatidão e autenticidade dos dados cadastrados.
Atenção: ao instalar o Aplicativo “EV.Charge” e realizar o seu cadastro, Você declara e confirma que entendeu e concordou integralmente com todas as regras estabelecidas no Contrato do Usuário.

3. Pagamento pelos Serviços.
Ao utilizar um dos Serviços, conforme melhor explicado abaixo, os seus Créditos EDP Smart serão convertidos em reais (R$), e debitados conforme a utilização, de acordo com a precificação estabelecida junto a cada serviço utilizado, o qual pode variar conforme com os termos do item 1. 
Alternativamente, a EDP Smart poderá oferecer, como modalidade de pagamento, plano de assinatura mensal por meio do seu cartão de crédito, garantindo-se condição especial de preços pela utilização dos serviços. 
O débito será efetivado de acordo com os preços de uso aplicáveis para cada uma das estações de recarga (sendo o preço aplicável à estação de recarga o “Preço”), conforme disponível no Aplicativo “EV.Charge” e também disponível no site https://mobilidade.edpsmart.com.br, sendo que as informações do Aplicativo “EV.Charge” sempre prevalecerão em caso de conflito.
Hoje, 1 (um) crédito EDP Smart custa R$ 1,00 (um real).
Os critérios e valores da Tabela de Conversão de Créditos EDP Smart poderão sofrer alterações e atualizações de tempos em tempos, a critério exclusivo da EDP Smart. Você fica desde já avisado e ciente de que, antes de efetuar a aquisição de Créditos EDP Smart a partir do Aplicativo “EV.Charge”, é sua responsabilidade verificar os valores e regramentos aplicáveis à época da intenção de aquisição dos referidos créditos e que constarão da Tabela de Conversão de Créditos EDP Smart vigente.

4. Contratação dos Serviços.
Depois de criar a conta e adquirir Créditos EDP Smart (para o caso de pagamento pré-pago), ou cadastrar o cartão de crédito para o pagamento (no caso de pós-pago), o uso de um dos Serviços é muito simples! Basta localizar as estações de recarga disponíveis próximos de Você e seguir as instruções no Aplicativo “EV.Charge” e nas próprias estações de recarga, conforme explicado abaixo:

    • As Estações de Recarga da EDP Smart estão distribuídas dentro das Cidades e em Rodovias Intermunicipais e Interestaduais, conforme o mapa delineado na tela inicial do Aplicativo “EV.Charge” e disponível no site https://mobilidade.edpsmart.com.br, sendo que as informações do Aplicativo “EV.Charge” sempre prevalecerão em caso de conflito;
    • As Estações de Recarga são desbloqueadas (i) por meio da aproximação do Cartão EDP Smart junto à Estação de Recarga; (ii) por desbloqueio direto do próprio aplicativo EV.Charge via QR Code; ou (iii) inserção de um código apresentado na Estação de Recarga. Importante lembrar que antes de efetivar o desbloqueio, Você deverá configurar seu Aplicativo “EV.Charge” (Seção “Meios de Pagamento”) para o tipo de pagamento desejado (Pré-Pago, com utilização de Créditos EDP Smart, ou Pós-Pago, com pagamento via cartão de crédito);
    • Caso a Estação de Recarga apresente qualquer defeito ou item que não aparente funcionar da forma como deveria, de modo a eventualmente comprometer a sua segurança ou a de terceiros, NÃO UTILIZE A ESTAÇÃO DE RECARGA. Utilize o canal do Aplicativo “EV.Charge” para relatar o defeito e localize uma nova Estação de Recarga que esteja em plenas condições de uso;
    • Seu veículo deve encontrar-se estacionado dentro da área designada e desligado;
    • Para interromper/finalizar uma recarga, Você deve:
        ◦ caso o veículo elétrico ainda não esteja totalmente carregado, aproximar novamente o Cartão EDP Smart da Estação de Recarga, desbloquear a Estação e selecionar a opção para parar o carregamento ou, alternativamente, interromper a recarga diretamente pelo aplicativo EV.Charge. Neste momento, será feita a cobrança;
        ◦ caso o veículo elétrico esteja totalmente carregado, retirar o plug do carregador da Estação de Recarga, após seu desarme automático. Neste momento, será feita a cobrança.
    • NO ATO DO ENCERRAMENTO/INTERRUPÇÃO DO USO DA ESTAÇÃO DE RECARGA, CERTIFIQUE-SE DE QUE VOCÊ COLOCOU O PLUG DO CARREGADOR NO DEVIDO LUGAR, JUNTO À ESTAÇÃO DE RECARGA. eM CASO DE DÚVIDAS QUANTO AO VALOR CARREGADO, CONSULTE NO PAINEL DA ESTAÇÃO DE RECARGA ANTES DE SAIR DO LOCAL, OU PELO APLICATIVO “EV.CHARGE”.
    • Além da possível cobrança dos valores decorrentes do não encerramento da Estação de Recarga ao final da sua utilização, Você também será responsável por todas as perdas e danos causados em decorrência de tal ato.

As Estação de Recarga podem ser utilizadas conforme horários de funcionamento indicados no mapa do Aplicativo “EV.Charge”, dependendo da disponibilidade das Estações de Recarga (o “Horário das Estações de Recarga”). As Estações de Recarga apenas podem ser desbloqueadas para uso, e seu veículo elétrico apenas pode ser recarregado, durante o Horários das Estações de Recarga. Você não conseguirá desbloquear Estações de Recarga por meio do Aplicativo “EV.Charge” fora do Horário das Estações de Recarga.

5. Responsabilidades e Obrigações Gerais dos Usuários.
Ao utilizar e desfrutar dos Serviços da EDP Smart, você declara e aceita que:
a) a sua conta é pessoal e intransferível, cabendo a Você garantir o sigilo das suas informações de acesso;
b) é responsável por manter as informações pessoais da sua conta atualizadas e se responsabiliza pelas declarações falsas ou inexatas que tenham sido prestadas por Você e que vierem a causar prejuízos a terceiros ou a própria EDP Smart;
c) irá ler e respeitar as regras e recomendações que poderão ser incluídas no Aplicativo “EV.Charge” ou em adesivos nas Estações de Recarga;
d) será responsável pelas Estações de Recarga durante todo o período de uso dos Serviços, devendo zelar por elas e adotar os cuidados básicos para que ela não seja exposta à risco de destruição ou avarias, devendo encerrar o uso das Estações de Recarga e disponibilizá-la para uso de outros Usuários nas mesmas boas condições em que recebeu;
e) não poderá utilizar o Aplicativo “EV.Charge” para fins ilícitos ou proibidos, incluindo utilizar o Aplicativo “EV.Charge” de forma indevida para retirar informações de outros usuários ou burlar as cobranças pelos Serviços;
f) não deverá acessar o Aplicativo “EV.Charge” de forma automatizada, que não seja por meio do Aplicativo “EV.Charge” disponível nas lojas da Apple e da Google, nem efetuar alterações em seu código fonte de qualquer natureza e para qualquer propósito;
g) será o único responsável por qualquer dano ou prejuízo que as Estações de Recarga vierem a sofrer durante seu uso, até o valor máximo do preço da Estação de Recarga, devendo isentar a EDP Grid por qualquer falha na recarga do veículo, uso dos serviços e eventos relacionados por sua natureza;
h) será o único responsável por qualquer violação legal efetuada, dano à propriedade da EDP Grid, danos pessoais, danos a terceiros, perda, avaria das Estações de Recarga, bem como a terceiros (pessoas, animais e objetos), dentre outros que possam ocorrer, além de arcar com os custos de multas administrativas e judiciais aplicadas por órgãos reguladores de trânsito e outros quando do uso das Estações de Recarga em desacordo com o Contrato do Usuário;
i) conhece e observará rigorosamente as regras de trânsito e segurança aplicáveis estabelecidas pela legislação vigente e tomará as devidas precauções para não se envolver em acidentes;
j) não retirará ou modificará qualquer acessório, parte e/ou componente das Estações de Recarga;
k) em caso de perda, extravio, furto ou roubo do dispositivo móvel utilizado para acesso ao Aplicativo “EV.Charge”, deverá comunicar imediatamente a EDP Smart, com a imediata apresentação de boletim de ocorrência nos casos de furto ou roubo, sendo certo que serão devidas as eventuais cobranças anteriores à data da comunicação formal do ocorrido;
l) caso constate que algum dos itens da Estação de Recarga não aparenta estar em plenas condições de uso, como explicado na Cláusula 4, e Você decidir usar a Estação de Recarga mesmo assim, Você assume integralmente a responsabilidade por eventual acidente ocorrido com Você e/ou com terceiros;
m) não utilizará as Estações de Recarga para fazer propagandas e/ou publicidade de qualquer tipo; e
n) renuncia a todas as representações e garantias, expressas, implícitas ou estatutárias, não expressamente definidas nestes Termos de Uso, incluindo as garantias implícitas de comercialização, aptidão para um propósito específico e não violação, estando Você ciente, ainda, que a EDP Grid não faz nenhuma representação, garantia ou garantia relativa à confiabilidade, oportunidade, qualidade, adequação ou disponibilidade dos serviços ou de qualquer serviço.
Por fim, Você reconhece que será o único responsável pelo pagamento dos custos relativos aos danos às Estações de Recarga a partir da sua conta que sejam resultantes de uso impróprio, incluindo, sem limitação, as hipóteses listadas nesta Cláusula 5, concordando que poderá haver cobrança automática de tarifa adicional por meio da forma de pagamento cadastrada nestes casos.
Você também deverá indenizar, defender e isentar a EDP Grid contra todas e quaisquer reivindicações de terceiros relacionadas ou decorrentes da sua utilização da plataforma, incluindo, mas não limitado a danos às Estações de Recarga e seus acessórios, danos a coisas de terceiros, dano a pessoa (integridade física), dentre outros.

6. Responsabilidades e Direitos da EDP Grid.
Para garantir uma ótima experiência do Aplicativo “EV.Charge”, a EDP Grid se compromete com a manutenção e reparo das Estações de Recarga e de seus componentes, realizando a supervisão dos Serviços sempre que possível e sempre dentro das melhores práticas do mercado de disponibilização de Estação de Recargas. Além disso, a EDP Grid poderá:
a) emitir cupons ou códigos promocionais, a serem utilizados para o uso de Serviços, a seu exclusivo critério, sendo que a EDP Grid também poderá, a seu exclusivo critério, emitir regulamento específico contendo os termos e condições desses cupons e códigos;
b) descontinuar, temporária ou permanentemente, o uso do Aplicativo “EV.Charge” e/ou da plataforma, bem como adicionar ou retirar tipos de Serviços do Aplicativo “EV.Charge” de forma unilateral, sem comunicação prévia, a qualquer tempo e sem nenhum ônus a si e/ou a Você;
c) alterar, aumentar ou diminuir o número e a área das Estações de Recargas, ficando obrigada somente a atualizar referido número e área no Aplicativo “EV.Charge” e no site https://mobilidade.edpsmart.com.br, sendo que as informações do Aplicativo “EV.Charge” sempre prevalecerão;
d) alterar, aumentar ou diminuir o Horário da Estação de Recarga, ficando obrigada somente a atualizar referidas informações no Aplicativo “EV.Charge” e no site https://mobilidade.edpsmart.com.br, sendo que as informações do Aplicativo “EV.Charge” sempre prevalecerão;
e) adicionar ou remover Cidades, ficando obrigada somente a atualizar referidas informações no Aplicativo “EV.Charge” e no site https://mobilidade.edpsmart.com.br, sendo que as informações do Aplicativo “EV.Charge” sempre prevalecerão;
f) alterar, aumentar ou diminuir o Preço de uma ou mais Cidades, ficando obrigada somente a atualizar referidas informações no Aplicativo “EV.Charge” e no site https://mobilidade.edpsmart.com.br, sendo que as informações do Aplicativo “EV.Charge” sempre prevalecerão em caso de conflito, sendo que Você aceitará o Preço ao continuar usando os serviços;
g) suspender ou cancelar a conta do Usuário caso verifique algum indício de fraude ou utilização indevida de cartões de crédito por terceiros que não detenham a autorização necessária dos seus titulares;
h) fazer advertência, suspender, temporariamente ou definitivamente, bloquear e/ou cancelar o seu acesso ao Aplicativo “EV.Charge” quando verificar que você violou a legislação aplicável, o Contrato do Usuário ou qualquer conduta que prejudique o bom funcionamento do Aplicativo “EV.Charge” e o uso por outros usuários;
i) fazer denúncia às autoridades públicas para a apuração de eventuais crimes pelo uso indevido dos Serviços.
Por fim, a EDP Grid não se responsabiliza:
a) por qualquer dano ou prejuízo, direto ou indireto, de qualquer natureza, sofrido por Você em razão de falhas na Internet, no sistema e/ou servidor utilizados por Você, decorrentes de condutas de terceiros, caso fortuito ou força maior;
b) pelo funcionamento do Aplicativo “EV.Charge” de forma ininterrupta e sem erros, porém, garante que sempre trabalhará para oferecer excelência nos Serviços; e
c) pela utilização indevida por terceiros e sem sua autorização dos dados do seu cartão de crédito, cabendo a Você e à administradora do seu cartão a responsabilidade de garantir o sigilo dos seus dados e da aprovação dos débitos realizados em seu cartão.

7. Disposições Gerais.
7.1 Propriedade.
Todos os produtos, Serviços e os direitos aqui descritos são de propriedade da EDP Grid. A concordância com estes termos ou o uso da plataforma não transmitem ou concedem a Você quaisquer direitos: (i) relacionados à plataforma, interface, conteúdo, arranjo e a aparência dos serviços; ou (ii) usar ou referir-se de qualquer maneira em/ao nome da empresa, o logotipo, desenhos, textos, ilustrações, imagens, dados, botões, interfaces de usuário, códigos fonte, código de objeto, know-how, informações, os nomes dos produtos, marcas comerciais de propriedade exclusiva da EDP Grid.

7.2 Superveniência.
Todas as condições relacionadas ao Contrato do Usuário quanto à limitação e exclusão de responsabilidade, renúncias, assunção de risco, garantias e obrigação de indenização, permanecem ao término da relação estabelecida entre Você e a EDP Grid, bem como qualquer valor não pago no momento da rescisão e/ou expiração desta relação.

7.3 Modificações.
Os documentos que integram o Contrato do Usuário poderão sofrer alterações, edições, adições, parciais ou integrais, cabendo a Você visitá-los rotineiramente de modo a tomar integral conhecimento das novas disposições e regramentos. Após a sua concordância inicial com o Contrato do Usuário, eventuais alterações/modificações não dependerão de nova aceitação sua, passando a serem aplicadas de modo automático toda vez que Você fizer uso da plataforma. Você desde já declara concordar com tal regramento e que será sua responsabilidade exclusiva revisitar os documentos que integram o Contrato do Usuário em busca da sua atualização sobre as regras de utilização da plataforma.

7.4 Legislação Aplicável.
O Contrato do Usuário será interpretado e regido conforme a legislação brasileira e aplicado em todo território brasileiro. As partes elegem, para resolver qualquer dúvida, demanda ou desavença decorrente do Contrato do Usuário o foro da Comarca do Município de São Paulo, Estado de São Paulo.

7.5 Contato.
Caso você queira reportar falhas, problemas, defeitos nos Serviços, registrar ocorrências, dar sugestões de melhorias e críticas, compartilhar histórias ou nos contar um pouco da sua experiência com o Aplicativo “EV.Charge” ou tiver alguma pergunta relacionada a estes Termos de Uso ou à Política de Privacidade do Aplicativo da EDP Smart, você pode entrar em contato por meio do canal de atendimento do Aplicativo “EV.Charge”.
`;
