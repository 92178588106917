import Config from 'config';
import styled from 'styled-components';

type ButtonProps = {
  open: Boolean;
  size: 'small' | 'medium' | 'large';
  type: 'text' | 'icon';
  variant: 'secondary' | 'tertiary';
  loading?: Boolean;
};

export const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 5px;
`;

export const ContainerInlineBlock = styled.div`
  position: relative;
  display: inline-block;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  display: inline-block;
`;

export const ContainerButton = styled.div<ButtonProps>`
  height: ${({ size }) =>
    size === 'small' ? '32px' : size === 'medium' ? '40px' : '45px'};
  width: ${({ type }) => (type === 'text' ? 'auto' : '32px')};
  padding: ${({ type }) => (type === 'text' ? '0px 16px' : '0px')};
  display: flex;
  box-shadow: 0 0 0
    ${({ variant }) => (variant === 'secondary' ? '1px' : '0px')}
    ${({ theme }) => theme.COLORS.BACKGROUND};
  position: relative;
  align-items: center;
  justify-content: center;
  border: ${({ variant }) => (variant === 'secondary' ? '1px' : '0px')} solid
    ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  border-radius: 8px;
  user-select: none;
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  background-color: ${({ theme, open, variant }) => {
    if (!open && variant === 'secondary') return theme.COLORS.WHITE;
    if (!open && variant === 'tertiary') return 'transparent';
    return theme.COLORS.TERTIARY_GRAY;
  }};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, open }) =>
      open ? theme.COLORS.TERTIARY_GRAY : theme.COLORS.BACKGROUND};
  }
  & > ${IconContainer} {
    margin-right: ${({ type }) => (type === 'text' ? '8px' : '0px')};
  }
`;

export const ContainerErrorOrLoader = styled.div<{
  size: 'small' | 'medium' | 'large';
  type: 'text' | 'icon';
}>`
  height: 100%;
  width: auto;
  left: calc(50% - 8px);
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownContainer = styled.div<{
  horizontalAlignment: 'start' | 'end';
}>`
  position: absolute;
  z-index: 100;
  top: 40px;

  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  white-space: nowrap;
  align-self: ${({ horizontalAlignment }) => `flex-${horizontalAlignment}`};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
`;

export const OptionsContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  display: flex;
  align-items: flex-start;
  padding: 8.5px 16px;
  position: relative;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND};
  }
`;

export const Arrow = styled(Config.ICONS.PAGINATION_UP)<{ open: boolean }>`
  margin-top: 3px;
  justify-self: center;
  transform: ${({ open }) => (!open ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-left: 17px;
  height: 10px;
`;
