import Dropdown from 'new-components/Dropdown';
import { BodyTypography, H5 } from 'new-components/Typographies/styles';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Box, BoxProps, chakra, TooltipProps, Tooltip } from '@chakra-ui/react';

const ROW_MIN_HEIGTH = 36;
const LIMIT_OF_DISPLAYED_ORGANIZATION_ROWS = 10;

export const MotionBox = motion<BoxProps>(Box);

export const BoxAnimation = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

export const ItemAnimation = {
  hidden: { y: -10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

export const StyledTooltip = chakra(Tooltip, {
  baseStyle: {
    fontSize: 'xs',
    px: 2,
    textAlign: 'center',
  } as TooltipProps,
});

export const ProfileButtonContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 4px;
  padding: 8px;
  margin: 4px 42px 4px 4px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  }
  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
        `
      : ''}
`;

export const DropdownContainer = styled(Dropdown.Container)`
  min-width: 320px;
`;

export const ProfileHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2;
  padding: 16px;
`;

export const ChevronLeftGridArea = styled.div`
  grid-area: icon;
`;

export const PageTitleGridArea = styled.div`
  display: flex;
  grid-area: title;
  align-items: center;
  justify-content: center;
`;

export const DropdownRow = styled(Dropdown.Row)`
  min-height: ${ROW_MIN_HEIGTH}px;
  position: relative;
`;

export const OrganizationSectionContent = styled(Dropdown.Section)<{
  numberOfRows?: number;
}>`
  max-height: ${LIMIT_OF_DISPLAYED_ORGANIZATION_ROWS * ROW_MIN_HEIGTH}px;

  ${({ numberOfRows }) =>
    numberOfRows && numberOfRows > LIMIT_OF_DISPLAYED_ORGANIZATION_ROWS
      ? css`
          overflow-y: scroll;

          ::-webkit-scrollbar {
            width: 6px;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${({ theme: { COLORS } }) =>
              COLORS.TERTIARY_GRAY};
            border-radius: 16px;
            border: 0px solid;
          }
        `
      : ''}
`;

export const OrganizationRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OrganizationLabel = styled(BodyTypography)`
  text-transform: capitalize;
`;

export const BoldOrganizationLabel = styled(H5)`
  text-transform: capitalize;
`;
