import { getBorderStyle, getPadding, getRadius, getSpace } from './getters';
import { ThemeProps } from './types';

type Resolver<T> = (props: ThemeProps) => T;

export type OmitThemeProp<Args extends any[]> = Args[2] extends ThemeProps
  ? [Args[0], Args[1]]
  : [Args[0]];

function createSelector<T extends (...args: any) => any>(getter: T) {
  function select(
    ...args: OmitThemeProp<Parameters<T>>
  ): Resolver<ReturnType<T>>;
  function select(...args: Parameters<T>): ReturnType<T>;

  function select(...args: any): any {
    if (args.length === getter.length) {
      return getter(...args);
    }
    return (props: ThemeProps) => getter(...args, props);
  }
  return select;
}

export const radius = createSelector(getRadius);
export const padding = createSelector(getPadding);
export const space = createSelector(getSpace);
export const borderStyle = createSelector(getBorderStyle);
