import SquareButton from 'components/SquareButton';
import Config from 'config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClickToExport: () => Promise<void>;
};

const ExportButton: React.FC<Props> = ({ onClickToExport }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClickToExport();
    setLoading(false);
  };

  return (
    <SquareButton
      data-test="export-button"
      mouseoverDescription={t('export-button.title')}
      onClick={handleClick}
      loading={loading}
    >
      <Config.ICONS.EXPORT />
    </SquareButton>
  );
};

export default ExportButton;
