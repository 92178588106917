import styled from 'styled-components';

export const ButtonContainer = styled.div`
  height: 34px;
  width: 74px;
  z-index: 2;
  align-self: flex-end;
  margin-left: 20px;
  position: relative;
  left: -205px;
  bottom: 16px;
  padding-right: 6px;
`;

export const Container = styled.div<{
  isVisible: boolean;
  marginRight?: string;
}>`
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  height: 432px;
  width: 100%;
  justify-content: center;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  margin-right: -186px;
  margin-right: ${({ marginRight }) => marginRight || 'none'};
  align-content: center;
  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.SELECTED_DATE_BOUND_BACKGROUND};
    border: 1px solid
      ${({ theme: { COLORS } }) => COLORS.CALENDAR.SELECTED_DATE_BOUND_BORDER};
    &:hover {
      background: ${({ theme: { COLORS } }) =>
        COLORS.CALENDAR.SELECTED_DATE_BOUND_HOVER_BACKGROUND};
    }
  }

  .CalendarDay__hovered_span {
    background: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.DATE_PRE_SELECTED_BACKGROUND};
    border: 1px solid
      ${({ theme: { COLORS } }) => COLORS.CALENDAR.DATE_PRE_SELECTED_BORDER};
    color: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.DATE_PRE_SELECTED_COLOR};
    &:hover {
      background: ${({ theme: { COLORS } }) =>
        COLORS.CALENDAR.DATE_PRE_SELECTED_HOVER_BACKGROUND};
    }
  }

  .CalendarDay__selected_span {
    background: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.DATE_SELECTED_BACKGROUND};
    border: 1px solid
      ${({ theme: { COLORS } }) => COLORS.CALENDAR.DATE_SELECTED_BORDER};
    &:hover {
      background: ${({ theme: { COLORS } }) =>
        COLORS.CALENDAR.DATE_SELECTED_HOVER_BACKGROUND};
    }
  }

  /** X pra limpar as datas e setinhas de navegação */
  .DateRangePickerInput_clearDates,
  .DayPickerNavigation_button__default {
    border-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
    &:hover {
      background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
    }
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    }
  }

  .DateRangePickerInput_clearDates {
    width: 35px;
    height: 35px;
    top: 33px;
    right: 150px;
  }

  .DateRangePickerInput_clearDates_svg {
    top: 12px;
    position: absolute;
    right: 10px;
  }

  .DayPickerNavigation_button__default {
    top: 13px;
  }

  .DayPickerNavigation_button__disabled {
    display: none;
  }

  .CalendarDay__default {
    font-size: 14px;
    font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
  }

  /** month */
  .CalendarMonth_caption {
    margin-bottom: 18px;

    strong {
      color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
      font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
      font-size: 16px;
    }
  }

  /** indicador do dia da semana */
  .DayPicker_weekHeader_ul small {
    color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
    font-size: 14px;
    font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
  }

  .DateInput_input__focused {
    border-bottom: 2px solid ${({ theme: { COLORS } }) => COLORS.PRIMARY};
  }
  .DateInput_input {
    font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
    font-size: 14px;
    text-align: center;
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }
  .DateInput_fang {
    display: none;
  }

  .DateRangePickerInput {
    margin-left: 150px;
    margin-top: 10px;
  }

  .DateRangePicker {
    width: 619px;
    margin-left: -5px;
    &_picker {
      padding-bottom: 16px;
    }
  }

  .CalendarDay__today {
    color: ${({ theme: { COLORS } }) => COLORS.CALENDAR.CURRENT_DAY};
    font-family: ${({ theme: { FONTS } }) => FONTS.SECONDARY};
  }

  .DayPicker_transitionContainer {
    height: 340px !important;
  }
`;
