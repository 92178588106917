import { Typography } from 'components';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  top?: number;
  size: number;
  direction: string;
}>`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: center;
  ${({ direction }) =>
    direction === 'row'
      ? css`
          & ${Typography} {
            margin-left: 10px;
          }
        `
      : css`
          & ${Typography} {
            margin-top: 10px;
          }
        `}
  ${({ size, top }) =>
    top &&
    css`
      left: calc(50% - ${size / 2}px);
      right: calc(50% - ${size / 2}px);
    `}
  > *:first-child {
    // we use top != null to check if top is different than undefined AND null.
    ${({ top }) =>
      top != null ? `margin-top: ${top}px` : `align-items: center;`};
  }
  & .b {
    fill: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  }
`;
