import {
  Box,
  TabList,
  Tabs,
  Tab,
  Flex,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import Loader from 'components/Loader';
import {
  CarModel,
  DriverModalGeneralTabQuery,
  DriverModalGeneralTabQueryVariables,
  DriverModalHeaderQuery,
  DriverModalHeaderQueryVariables,
  DriverModalOrdersTabQuery,
  DriverModalOrdersTabQueryVariables,
  DriverModalSessionsTabQuery,
  DriverModalSessionsTabQueryVariables,
} from 'generated/graphql';
import driverModalGeneralTab from 'graphql/queries/driverModalGeneralTab';
import DriverModalHeader from 'graphql/queries/driverModalHeader';
import driverModalOrdersTab from 'graphql/queries/driverModalOrdersTab';
import driverModalSessionsTab from 'graphql/queries/driverModalSessionsTab';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import React, { useState, useContext } from 'react';
import AuthContext from 'contexts/Auth/context';
import { formatCpf } from 'services/formatCpf';
import { formatOrderFields } from 'services/formatOrderRow';
import { formatPostalCode } from 'services/formatPostalCode';
import {
  formatSessionFields,
  SessionFormatInput,
} from 'services/formatSessionRow';
import { getCardBrandIcon } from 'services/getCardBrandIcon';
import { getCardBrandName } from 'services/getCardBrandName';
import { getLastFourCardNumbers } from 'services/getLastFourCardNumbers';
import { LoadingContainer } from 'styles';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'services/format';
import { CurrencyType } from 'types';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import General from './General';
import { PaymentMethod } from './General/PaymentMethods';
import UserInfo from './UserInfo';
import Orders from './Orders';
import Sessions from './Sessions';
import { Body, Container } from './styles';

type Props = {
  id: number;
  handleSessionRowClick: (sessionId?: string) => void;
  onClose: () => void;
  show: boolean;
};

type TabProps = {
  icon: keyof NotNestedNewIcons;
  text: string;
};

const defaultPagination = {
  pageIndex: 0,
  pageSize: 25,
};

type Session = NonNullable<
  DriverModalSessionsTabQuery['driver']
>['sessions'][number];

const DriverModal: React.FC<Props> = ({
  id,
  handleSessionRowClick,
  onClose,
  show,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [
    { pageIndex: sessionPageIndex, pageSize: sessionPageSize },
    setSessionPagination,
  ] = useState(defaultPagination);

  const [
    { pageIndex: orderPageIndex, pageSize: orderPageSize },
    setOrderPagination,
  ] = useState(defaultPagination);

  const { data: headerData, loading: headerLoading } = useAuthQuery<
    DriverModalHeaderQuery,
    DriverModalHeaderQueryVariables
  >(DriverModalHeader, {
    variables: {
      where: { id },
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  const {
    data: generalData,
    error: generalError,
    loading: generalLoading,
    refetch: generalRefetchInfo,
  } = useAuthQuery<
    DriverModalGeneralTabQuery,
    DriverModalGeneralTabQueryVariables
  >(driverModalGeneralTab, {
    variables: {
      where: { id },
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  const {
    data: sessionsData,
    error: sessionsError,
    loading: sessionsLoading,
  } = useRealtimeQuery<
    DriverModalSessionsTabQuery,
    DriverModalSessionsTabQueryVariables
  >(driverModalSessionsTab, {
    variables: {
      where: { id },
      offset: sessionPageIndex * sessionPageSize,
      limit: sessionPageSize,
    },
    fetchPolicy: 'network-only',
    skip: !id || !hasScopes(['session:read', 'order:read']),
  });

  const {
    data: ordersData,
    error: ordersError,
    loading: ordersLoading,
  } = useAuthQuery<
    DriverModalOrdersTabQuery,
    DriverModalOrdersTabQueryVariables
  >(driverModalOrdersTab, {
    variables: {
      where: { id },
      pagination: {
        offset: orderPageIndex * orderPageSize,
        limit: orderPageSize,
      },
      filter: {
        status: [
          'Captured',
          'Error',
          'ErrorOnPreAuthorization',
          'PreAuthorized',
          'Subsidized',
        ],
      },
    },
    fetchPolicy: 'network-only',
    skip: !id || !hasScopes(['order:read']),
  });

  const formatPaymentMethod = (
    paymentMethod: NonNullable<
      DriverModalGeneralTabQuery['driver']
    >['paymentMethods'][number],
    currencyType?: CurrencyType
  ): PaymentMethod => {
    if (paymentMethod.__typename === 'Wallet') {
      return {
        icon: 'NEW_DIGITAL_WALLET',
        title: 'Carteira Digital',
        info: `Saldo: ${formatCurrency(paymentMethod.availableBalance / 100, {
          currencyType,
        })}`,
        type: paymentMethod.__typename,
      };
    }
    if (paymentMethod.__typename === 'PaymentCard') {
      return {
        info: `${getCardBrandName(
          paymentMethod.brand
        )} ${getLastFourCardNumbers(paymentMethod.partiallyHiddenCardNumber)}`,
        icon: getCardBrandIcon(paymentMethod.brand),
        title: t('driver-modal.payment-card.title'),
        type: paymentMethod.__typename,
      };
    }
    return {
      info: paymentMethod.title,
      title: `Greenea - ${paymentMethod.holderFullName}`,
      icon: 'NEW_CARD_GREENEA',
      type: paymentMethod.__typename,
    };
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const defaultCurrencyTypes = useDefaultCurrencyTypes();

  const tabs = [
    hasScopes(['driver:read']) && {
      icon: 'NEW_METRICS',
      text: t('driver-modal.general-tab.title'),
    },
    hasScopes(['driver:read', 'station:read', 'session:read']) && {
      icon: 'NEW_AC_CHARGER',
      text: t('driver-modal.recharges-tab.title'),
    },
    hasScopes([
      'driver:read',
      'station:read',
      'session:read',
      'order:read',
    ]) && {
      icon: 'NEW_CREDIT_CARD',
      text: t('driver-modal.payments-tab.title'),
    },
  ] as TabProps[];

  const { orgNameMap } = useContext(AuthContext);

  const walletInfo = generalData?.driver?.paymentMethods.find(
    (paymentMethod) => paymentMethod.__typename === 'Wallet'
  );

  const availableBalance =
    walletInfo?.__typename === 'Wallet' ? walletInfo.availableBalance : 0;
  const minimumBalanceForSession =
    walletInfo?.__typename === 'Wallet'
      ? walletInfo.minimumBalanceForSession
      : 0;

  const enrichConnectorDataWithStationId = (
    session: Session
  ): Partial<SessionFormatInput> & { defaultCurrencyType?: CurrencyType } => ({
    ...session,
    connector: session.connector
      ? {
          ...session.connector,
          station: {
            id: session.stationProfile?.id ?? '',
          },
        }
      : undefined,
  });

  return (
    <Container show={show} onClose={onClose} size="lg">
      {headerLoading ? (
        <LoadingContainer>
          <Loader color="INFO_GRAY" size={50} />
        </LoadingContainer>
      ) : (
        <>
          <Tabs onChange={(index) => setCurrentIndex(index)}>
            <Modal.Header>
              <Box display="flex">
                <Flex direction="column" pt={6} w="100%">
                  <UserInfo
                    userProfile={headerData?.driver?.profile}
                    id={id}
                    orgId={headerData?.driver?.emspId}
                  />
                  <TabList pt={2} pl={6}>
                    {tabs
                      .filter((tab) => !!tab)
                      .map((tab, index) => (
                        <Tab>
                          <Box mb={0.5} mr={2}>
                            <Icon
                              type={tab.icon}
                              color={
                                index === currentIndex
                                  ? 'PRIMARY_GRAY'
                                  : 'SECONDARY_GRAY'
                              }
                            />
                          </Box>
                          {tab.text}
                        </Tab>
                      ))}
                  </TabList>
                </Flex>
              </Box>
            </Modal.Header>
            <Modal.Header.Attachments padding="16">
              <Modal.Header.Close onClose={onClose} />
            </Modal.Header.Attachments>
            <Body>
              <TabPanels>
                {hasScopes(['driver:read']) && (
                  <TabPanel p={0}>
                    <>
                      <General
                        address={{
                          ...generalData?.driver?.addresses?.[0],
                          postalCode: formatPostalCode(
                            generalData?.driver?.addresses?.[0]?.postalCode ||
                              ''
                          ),
                        }}
                        cars={
                          generalData?.driver?.cars?.map(
                            (car) => car.model
                          ) as Partial<CarModel>[]
                        }
                        datesMarks={{
                          createdAt:
                            generalData?.driver?.createdAt &&
                            t('driver-modal.general-tab.created-at-value', {
                              value: new Date(generalData.driver.createdAt),
                            }),
                        }}
                        error={!!generalError}
                        loading={generalLoading}
                        driverId={id}
                        paymentMethods={generalData?.driver?.paymentMethods.map(
                          (paymentMethod) =>
                            formatPaymentMethod(
                              paymentMethod,
                              getOrganizationDefaultCurrencyType(
                                defaultCurrencyTypes,
                                generalData.driver?.emspId
                              )
                            )
                        )}
                        minimumWalletDepositAmount={minimumBalanceForSession}
                        availableBalance={availableBalance}
                        profile={{
                          ...generalData?.driver?.profile,
                          cellPhone: generalData?.driver?.profile?.cellPhone,
                          CPF: formatCpf(generalData?.driver?.profile?.CPF),
                        }}
                        refetchInfo={generalRefetchInfo}
                      />
                    </>
                  </TabPanel>
                )}
                {hasScopes(['driver:read', 'station:read', 'session:read']) && (
                  <TabPanel p={0}>
                    <Sessions
                      data={sessionsData?.driver?.sessions.map((session) =>
                        formatSessionFields(
                          enrichConnectorDataWithStationId(session),
                          orgNameMap,
                          defaultCurrencyTypes
                        )
                      )}
                      error={!!sessionsError}
                      fetchData={setSessionPagination}
                      handleSessionRowClick={handleSessionRowClick}
                      loading={sessionsLoading}
                      pageCount={Math.ceil(
                        (sessionsData?.driver?.sessionsSummary.count || 0) /
                          sessionPageSize
                      )}
                    />
                  </TabPanel>
                )}
                {hasScopes(['driver:read', 'station:read', 'session:read']) && (
                  <TabPanel p={0}>
                    <Orders
                      data={ordersData?.driver?.orders.map(formatOrderFields)}
                      error={!!ordersError}
                      fetchData={setOrderPagination}
                      handleSessionRowClick={handleSessionRowClick}
                      loading={ordersLoading}
                      pageCount={Math.ceil(
                        (ordersData?.driver?.ordersSummary.count || 0) /
                          orderPageSize
                      )}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Body>
          </Tabs>
        </>
      )}
    </Container>
  );
};

export default DriverModal;

// export default React.memo(UserModal, (a, b) => a.id === b.id);
