import { gql } from '@apollo/client';

export default gql`
  mutation StopSession($where: SelectConnectorInput!) {
    stopConnectorCharge(where: $where) {
      __typename
      ... on Ok {
        ok
      }
      ... on Error {
        message
      }
    }
  }
`;
