import Config from 'config';
import { AlarmLabelFieldsFragment } from 'generated/graphql';
import { t } from 'i18next';

export const formatAlarmLabel = (alarm: AlarmLabelFieldsFragment) => {
  const defaultLabel = t(Config.LABELS.STATION_ALARM_TYPES[alarm.__typename]);
  return alarm.__typename !== 'StationAlarmStopChargingFailed' &&
    alarm.__typename !== 'StationAlarmWentOffline' &&
    alarm.vendorErrorCode
    ? `${defaultLabel} [${alarm.vendorErrorCode}]`
    : defaultLabel;
};
