import React from 'react';
import { StationLogProvider } from './StationLogsContext';

import { StationEvents } from './StationEvents';

import { StationAlarms } from './StationAlarms';

import { Container, NewCardScroll } from './styles';

export const StationLogs: React.FC = () => (
  <StationLogProvider>
    <Container>
      <NewCardScroll>
        <StationAlarms />
      </NewCardScroll>
      <NewCardScroll>
        <StationEvents />
      </NewCardScroll>
    </Container>
  </StationLogProvider>
);
