import { Box, Flex } from '@chakra-ui/react';
import Dropdown from 'components/Dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaginationButton from './PaginationButton';

const linePerPageOptions = [100, 50, 25] as const;
export type linesPerPageOptionsType = typeof linePerPageOptions[number];
interface Props {
  pageCount: number;
  setPageCount: (option: linesPerPageOptionsType) => void;
  setActivePage: (currentPage: number) => void;
  activePage: number;
  selectedLinesPerPageOption: linesPerPageOptionsType;
  setSelectedLinesPerPageOption: (option: linesPerPageOptionsType) => void;
}

const Pagination: React.FC<Props> = ({
  pageCount,
  setActivePage,
  setPageCount,
  activePage,
  selectedLinesPerPageOption,
  setSelectedLinesPerPageOption,
}) => {
  const { t } = useTranslation();
  const isActive = {
    first: activePage === 1,
    inMiddle: activePage >= 2 && (activePage < pageCount || pageCount === 2),
    last: activePage === pageCount,
  };

  const showDots = {
    onLeft: activePage >= 3 && pageCount >= 4,
    onRight: activePage <= pageCount - 2 && pageCount >= 4,
  };
  const firstValueOnCentralBlock = isActive.first
    ? activePage
    : isActive.inMiddle
    ? activePage - 1
    : activePage - 2;
  const linesPerPageClicked = (option: linesPerPageOptionsType) => {
    setSelectedLinesPerPageOption(option);
    setPageCount(option);
    setActivePage(1);
  };

  return (
    <Flex
      w="100%"
      flexFlow="row nowrap"
      justifyContent="center"
      alignItems="flex-end"
      data-test="pagination-container"
      position="absolute"
      bottom="3"
    >
      {!isActive.first && (
        <PaginationButton
          previousPage
          onClick={() => setActivePage(activePage - 1)}
        />
      )}
      {showDots.onLeft && (
        <PaginationButton dottedRight onClick={() => setActivePage(1)}>
          1
        </PaginationButton>
      )}
      {pageCount >= 1 && (
        <PaginationButton
          active={isActive.first}
          onClick={() => setActivePage(firstValueOnCentralBlock)}
        >
          {firstValueOnCentralBlock}
        </PaginationButton>
      )}
      {pageCount >= 2 && (
        <PaginationButton
          active={isActive.inMiddle}
          onClick={() => setActivePage(firstValueOnCentralBlock + 1)}
        >
          {firstValueOnCentralBlock + 1}
        </PaginationButton>
      )}
      {pageCount >= 3 && (
        <PaginationButton
          active={isActive.last}
          onClick={() => setActivePage(firstValueOnCentralBlock + 2)}
        >
          {firstValueOnCentralBlock + 2}
        </PaginationButton>
      )}
      {showDots.onRight && (
        <PaginationButton dottedLeft onClick={() => setActivePage(pageCount)}>
          {pageCount}
        </PaginationButton>
      )}
      {!isActive.last && (
        <PaginationButton
          nextPage
          onClick={() => setActivePage(activePage + 1)}
        />
      )}
      <Box w="32">
        <Dropdown
          data-test="pagination-dropdown"
          options={linePerPageOptions}
          value={selectedLinesPerPageOption}
          format={(option) => `${option} / ${t('pagination.options-per-page')}`}
          onChange={linesPerPageClicked}
          openDirection="top"
        />
      </Box>
    </Flex>
  );
};

export default Pagination;
