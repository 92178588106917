import Config from 'config/index';
import {
  ActivateMemberMutation,
  ActivateMemberMutationVariables,
  AllTeamMembersQuery,
  InactivateMemberMutation,
  InactivateMemberMutationVariables,
  Member,
  MemberRole,
  ResendInviteMemberMutation,
  ResendInviteMemberMutationVariables,
  UpdateMemberMutation,
  UpdateMemberMutationVariables,
} from 'generated/graphql';
import activateMember from 'graphql/mutations/activateMember';
import inactivateMember from 'graphql/mutations/inactivateMember';
import updateMemberMutation from 'graphql/mutations/updateMember';
import { useAuthMutation } from 'hooks';
import moment from 'moment';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import { BodyTypography, H5 } from 'new-components/Typographies/styles';
import React, { useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'services/toast';
import AuthContext from 'contexts/Auth/context';
import resendInviteMemberMutation from 'graphql/mutations/resendInviteMember';
import {
  AboutMemberAccess,
  AboutMemberInactivation,
  DropdownContainer,
  DropdownRow,
} from './styles';
import { ResendInviteMemberDropdownRow } from './ResendInviteMemberDropdownRow';

const SELECTABLE_ROLES: Array<MemberRole> = [
  'ADMIN',
  'SUPPORT',
  'TECHNICIAN',
  'VIEWER',
];

type Props = {
  member: AllTeamMembersQuery['members'][number];
  refetchMembers: () => void;
};

function MemberDropdown({
  member: {
    active,
    role: memberRole,
    id: memberId,
    accessGiven,
    lastInactivation,
    organizationId,
    profile,
  },
  refetchMembers,
}: Props) {
  const { t } = useTranslation();
  const [togglingMemberActivation, setTogglingMemberActivation] =
    useState(false);
  const [updatingMemberRole, setUpdatingMemberRole] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<MemberRole>(memberRole);

  const { member } = useContext(AuthContext);
  const notAuthorizedDisableMember =
    member?.role === 'ADMIN' &&
    (memberRole === 'OWNER' || memberRole === 'ADMIN');

  const [dispatchActivateMemberMutation] = useAuthMutation<
    ActivateMemberMutation,
    ActivateMemberMutationVariables
  >(activateMember);
  const [dispatchInactivateMemberMutation] = useAuthMutation<
    InactivateMemberMutation,
    InactivateMemberMutationVariables
  >(inactivateMember);
  const [dispatchUpdateMemberMutation] = useAuthMutation<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
  >(updateMemberMutation);

  const updateMemberRole = useCallback(
    async (memberId: Member['id'], role: MemberRole) => {
      setUpdatingMemberRole(true);
      try {
        const { errors } = await dispatchUpdateMemberMutation({
          variables: {
            where: { memberId, organizationId },
            data: {
              role,
            },
          },
        });
        setUpdatingMemberRole(false);

        if (errors) {
          setSelectedRole(memberRole);
          toast.error(t('members.toast-toggle-permission-error.message'));
        } else {
          toast.success(
            t('members.toast-permission-changed-successfully.message')
          );
          refetchMembers();
        }
      } catch (error) {
        setSelectedRole(memberRole);
        setUpdatingMemberRole(false);
        toast.error(t('members.toast-toggle-permission-error.message'));
      }
    },
    [dispatchUpdateMemberMutation, refetchMembers, memberRole, organizationId] // eslint-disable-line
  );

  const toggleMemberActivation = useCallback(
    async (memberId: Member['id'], isActive: boolean) => {
      const reason = t('members.toggle-activation.message', {
        action: isActive ? 'Inativado' : 'Ativado',
      });

      const mutation = isActive
        ? dispatchInactivateMemberMutation
        : dispatchActivateMemberMutation;

      setTogglingMemberActivation(true);
      try {
        const { errors } = await mutation({
          variables: {
            where: { memberId, organizationId },
            reason,
          },
        });
        setTogglingMemberActivation(false);

        if (errors) {
          toast.error(
            t('members.toast-toggle-error-message', {
              action: isActive
                ? t('members.toast-deactivation-action-text')
                : t('members.toast-activation-action-text'),
            })
          );
        } else {
          toast.success(
            t('members.toast-toggle-successfully-message', {
              action: isActive
                ? t('members.toast-disabled-action-text')
                : t('members.toast-activated-action-text'),
            })
          );
          refetchMembers();
        }
      } catch (error) {
        setTogglingMemberActivation(false);
        toast.error(
          t('members.toast-toggle-error-message', {
            action: isActive
              ? t('members.toast-deactivation-action-text')
              : t('members.toast-activation-action-text'),
          })
        );
      }
    },
    [
      dispatchInactivateMemberMutation,
      dispatchActivateMemberMutation,
      refetchMembers,
      organizationId,
      t,
    ]
  );

  const memberName = accessGiven.by?.profile.firstName
    ? `${accessGiven.by.profile.firstName}${
        accessGiven.by.profile.lastName
          ? ` ${accessGiven.by.profile.lastName}`
          : ''
      }`
    : 'Voltbras';

  const [resendInviteMember, { loading: isResendInviteLoading }] =
    useAuthMutation<
      ResendInviteMemberMutation,
      ResendInviteMemberMutationVariables
    >(resendInviteMemberMutation);

  const handleResendMemberInvitationDropdownRowPress = async () => {
    if (!profile.email) return;
    try {
      await resendInviteMember({
        variables: {
          data: {
            email: profile.email,
            role: selectedRole,
          },
          where: {
            id: organizationId,
          },
        },
      });
      toast.success(
        t('member-dropdown.resend-invite-member.toast-successfully-message')
      );
    } catch {
      toast.error(
        t('member-dropdown.resend-invite-member.toast-error-message')
      );
    }
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggleDropdown={setIsDropdownOpen}
      onClickOutside={() => setIsDropdownOpen(false)}
      content={() => (
        <DropdownContainer>
          <>
            <Dropdown.Section>
              <AboutMemberAccess>
                <H5 color="SECONDARY_GRAY">
                  {t('members.dropdown.section.access-granted.title')}
                </H5>
                <BodyTypography color="SECONDARY_GRAY">
                  {t('members.dropdown.section.access-granted.by', {
                    memberName,
                    dateTime: moment(accessGiven.at).format(
                      'DD/MM/YYYY [às] HH:mm'
                    ),
                  })}
                </BodyTypography>
              </AboutMemberAccess>
            </Dropdown.Section>
            {lastInactivation && (
              <Dropdown.Section>
                <AboutMemberInactivation>
                  <H5 color="SECONDARY_GRAY">
                    {t('members.dropdown.section.deactivated-member.title')}
                  </H5>
                  <BodyTypography color="SECONDARY_GRAY">
                    {t('members.dropdown.section.deactivated-member.by', {
                      memberName: `${lastInactivation.by?.profile.firstName} ${lastInactivation.by?.profile.lastName}`,
                      dateTime: moment(lastInactivation.at).format(
                        'DD/MM/YYYY [às] HH:mm'
                      ),
                    })}
                  </BodyTypography>
                </AboutMemberInactivation>
              </Dropdown.Section>
            )}
            {active && (
              <Dropdown.Section>
                <>
                  {SELECTABLE_ROLES.map((role) => (
                    <DropdownRow
                      clickable={selectedRole !== role && !updatingMemberRole}
                      onClick={async (event) => {
                        event.stopPropagation();
                        setSelectedRole(role);
                        await updateMemberRole(memberId, role);
                      }}
                    >
                      <>
                        <H5>{t(Config.LABELS.ROLES[role])}</H5>
                        {selectedRole === role &&
                          (updatingMemberRole ? (
                            <Dropdown.RowLoading />
                          ) : (
                            <Icon type="NEW_CHECK" color="PRIMARY" size={20} />
                          ))}
                      </>
                    </DropdownRow>
                  ))}
                </>
              </Dropdown.Section>
            )}
            <Dropdown.Section>
              <>
                <ResendInviteMemberDropdownRow
                  loading={isResendInviteLoading}
                  onClick={handleResendMemberInvitationDropdownRowPress}
                />
                {active ? (
                  <Dropdown.Row
                    clickable={!togglingMemberActivation}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      toggleMemberActivation(memberId, active);
                    }}
                  >
                    <H5 color="ERROR">
                      {t('members.disable-member.row.title')}
                    </H5>
                    {togglingMemberActivation ? (
                      <Dropdown.RowLoading color="ERROR" />
                    ) : (
                      <Icon type="NEW_REMOVE_CIRCLE" color="ERROR" size={20} />
                    )}
                  </Dropdown.Row>
                ) : (
                  <Dropdown.Row
                    clickable={!togglingMemberActivation}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleMemberActivation(memberId, active);
                    }}
                  >
                    <H5>{t('members.active-member.row.title')}</H5>
                    {togglingMemberActivation ? (
                      <Dropdown.RowLoading />
                    ) : (
                      <Icon
                        type="NEW_SHUTDOWN"
                        color="PRIMARY_GRAY"
                        size={20}
                      />
                    )}
                  </Dropdown.Row>
                )}
              </>
            </Dropdown.Section>
          </>
        </DropdownContainer>
      )}
    >
      <Dropdown.TriggerButtonContainer
        active={isDropdownOpen}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        isDisabled={notAuthorizedDisableMember}
      >
        <Icon type="NEW_THREE_HORIZONTAL_DOTS" color="PRIMARY_GRAY" size={24} />
      </Dropdown.TriggerButtonContainer>
    </Dropdown>
  );
}

export { MemberDropdown };
