import { gql } from '@apollo/client';
import connectorsListFieldsFragment from 'graphql/fragments/connectorsListFields';
import pricingConfigFieldsFragment from '../fragments/pricingConfigFields';

export default gql`
  query StationDetails($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      cpoId
      name
      coordinates {
        latitude
        longitude
      }

      status
      connectors {
        pricingConfig {
          ...PricingConfigFields
        }
        ...ConnectorsListFields
      }
      energyPrice
      address {
        street
        streetNumber
        city
        state
        neighborhood
        postalCode
      }
      images
      openingHours
      description
    }
  }
  ${connectorsListFieldsFragment}
  ${pricingConfigFieldsFragment}
`;
