import { Scope } from '@voltbras/auth-directives';
import { VehicleModalQuery } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import { Icon } from 'new-components/Icon';
import React from 'react';
import NavBar from 'atomic-components/molecules/NavBar';
import { Container, StyledNavBar } from './styles';
import VehicleInfo from './VehicleInfo';

type TabModel = {
  title: string;
  path: string;
  icon: any;
  requiredScope: Scope[];
};

export const tabs: TabModel[] = [
  {
    title: 'Geral',
    path: '/',
    icon: <Icon type="NEW_METRICS" color="PRIMARY_GRAY" />,
    requiredScope: ['driver:read'],
  },
  {
    title: 'Recargas',
    path: '/sessions',
    icon: <Icon type="NEW_AC_CHARGER" color="TERTIARY_GRAY" />,
    requiredScope: ['driver:read', 'station:read', 'session:read'],
  },
];

export type Tab = typeof tabs[number];

export type Props = {
  currentTab: Tab;
  id: number;
  profile?: NonNullable<VehicleModalQuery['vehicle']>;
  onTabChange: (tab: Tab) => void;
};

const Header: React.FC<Props> = ({ currentTab, id, profile, onTabChange }) => {
  const hasScopes = useHasScopes();
  return (
    <Container>
      <VehicleInfo profile={profile} id={id}></VehicleInfo>
      <StyledNavBar>
        {tabs
          .filter((tab) => hasScopes(tab.requiredScope))
          .map((tab, index) => (
            <NavBar.NavLink
              active={tab === currentTab}
              onClick={() => onTabChange(tab)}
              key={index}
            >
              {tab?.icon}
              <NavBar.NavLink.Title>{tab.title}</NavBar.NavLink.Title>
            </NavBar.NavLink>
          ))}
      </StyledNavBar>
    </Container>
  );
};

export default Header;
