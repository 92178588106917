import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { FromDateUntilDate } from 'services/timeRange';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth/context';
import { Container } from './styles';

type Props = {
  onSelectDate: (
    start: moment.Moment | null,
    end: moment.Moment | null
  ) => void;
  isVisible: boolean;
  closeCalendar?: () => void;
  defaultValue?: FromDateUntilDate;
};

const Calendar: React.FC<Props> = ({
  onSelectDate,
  isVisible,
  closeCalendar,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const { member } = useContext(AuthContext);
  const lng = (member?.language ?? 'pt_BR').replace('_', '-');

  const defaultProps = {
    startDatePlaceholderText: t('date-dropdown.start-date-placeholder-text'),
    endDatePlaceholderText: t('date-dropdown.end-date-placeholder-text'),
    startDateId: 'start_unique_id',
    endDateId: 'end_unique_id',
    showClearDates: true,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: true,
    hideKeyboardShortcutsPanel: true,
    minimumNights: 0,
  };
  const [selectedDate, setSelectedDate] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: defaultValue?.from ? moment(defaultValue?.from) : null,
    endDate: defaultValue?.to ? moment(defaultValue?.to) : null,
  });
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    'startDate'
  );

  const { startDate, endDate } = selectedDate;

  const handleSelection = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    setSelectedDate({ startDate, endDate });
    onSelectDate(startDate, endDate);
    if (endDate) {
      closeCalendar?.();
    }
  };

  moment.updateLocale(lng, {
    weekdaysMin: ['D', 'S', 'T', 'Q', 'Q ', 'S ', 'S  '],
  });
  moment.locale(member?.language ?? 'pt_BR');

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      padding="0.3125rem"
      width="39.0625rem"
      visibility={isVisible ? 'visible' : 'hidden'}
      height="26.875rem"
      bgColor="white"
    >
      <Container>
        <DateRangePicker
          {...defaultProps}
          openDirection="down"
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleSelection}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => {
            if (focusedInput) setFocusedInput(focusedInput);
          }}
          numberOfMonths={2}
          maxDate={undefined}
          isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
          initialVisibleMonth={() => moment()}
        />
      </Container>
    </Box>
  );
};

export default Calendar;
