import { createContext } from 'react';
import { CreateVoucherClient } from '../client';
import { useCreateVoucherGraphqlClient } from '../client/graphql';

export type CreateVoucherClientContextType = {
  getClient: () => () => CreateVoucherClient;
};

export const CreateVoucherClientContext =
  createContext<CreateVoucherClientContextType>({
    getClient: () => () => ({
      createVoucher: () => ({
        createVoucherMutation: async () => new Promise((r) => r),
        createVoucherLoading: false,
      }),
      getDrivers: () => ({
        data: undefined,
        getDriversResultError: false,
        getDriversResultLoading: false,
      }),
      getStations: () => ({
        data: undefined,
        getStationsResultError: false,
        getStationsResultLoading: false,
      }),
    }),
  });

export const CreateVoucherClientProvider: React.FC = ({ children }) => (
  <CreateVoucherClientContext.Provider
    value={{ getClient: () => useCreateVoucherGraphqlClient }}
  >
    {children}
  </CreateVoucherClientContext.Provider>
);
