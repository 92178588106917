import { gql } from '@apollo/client';

export default gql`
  query AllVoucher(
    $filter: VoucherFilterInput
    $sort: VoucherSortInput
    $search: String
    $pagination: VoucherPaginationInput
  ) {
    vouchers(
      filter: $filter
      sort: $sort
      search: $search
      pagination: $pagination
    ) {
      id
      name
      description
      status
      variableDiscount
      usageLimit
      isUnlimited
      emspId
      createdByMember {
        id
      }
      startsAt
      createdAt
      expiresAt
    }
    vouchersSummary(filter: $filter) {
      count
    }
  }
`;
