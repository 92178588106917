import styled from 'styled-components';
import { Typography } from 'components';

export const UserNameContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const StarsContainer = styled.div`
  margin-left: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  > * {
    margin-right: 3.5px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
`;

export const UserName = styled(Typography)`
  margin-left: 10px;
`;
