import styled from 'styled-components';

export const Container = styled.div<{
  marginRight?: string;
}>`
  justify-content: center;
  justify-content: center;
  margin-right: -11.625rem;
  margin-right: ${({ marginRight }) => marginRight || 'none'};
  align-content: center;
  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.SELECTED_DATE_BOUND_BACKGROUND};
    border: 1px solid
      ${({ theme: { COLORS } }) => COLORS.CALENDAR.SELECTED_DATE_BOUND_BORDER};
    &:hover {
      background: ${({ theme: { COLORS } }) =>
        COLORS.CALENDAR.SELECTED_DATE_BOUND_HOVER_BACKGROUND};
    }
  }

  .CalendarDay__hovered_span {
    background: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.DATE_PRE_SELECTED_BACKGROUND};
    border: 1px solid
      ${({ theme: { COLORS } }) => COLORS.CALENDAR.DATE_PRE_SELECTED_BORDER};
    color: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.DATE_PRE_SELECTED_COLOR};
    &:hover {
      background: ${({ theme: { COLORS } }) =>
        COLORS.CALENDAR.DATE_PRE_SELECTED_HOVER_BACKGROUND};
    }
  }

  .CalendarDay__selected_span {
    background: ${({ theme: { COLORS } }) =>
      COLORS.CALENDAR.DATE_SELECTED_BACKGROUND};
    border: 1px solid
      ${({ theme: { COLORS } }) => COLORS.CALENDAR.DATE_SELECTED_BORDER};
    &:hover {
      background: ${({ theme: { COLORS } }) =>
        COLORS.CALENDAR.DATE_SELECTED_HOVER_BACKGROUND};
    }
  }

  /** X pra limpar as datas e setinhas de navegação */
  .DateRangePickerInput_clearDates,
  .DayPickerNavigation_button__default {
    border-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
    &:hover {
      background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
    }
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    }
  }

  .DateRangePickerInput_clearDates {
    top: 60%;
    right: 20%;
  }

  .CalendarDay__default {
    font-size: 0.875rem;
    font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
  }

  /** month */
  .CalendarMonth_caption {
    margin-bottom: 1.125rem;

    strong {
      color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
      font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
      font-size: 1rem;
    }
  }

  /** indicador do dia da semana */
  .DayPicker_weekHeader_ul small {
    color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
    font-size: 0.875rem;
    font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
  }

  .DateInput_input__focused {
    border-bottom: 0.125rem solid ${({ theme: { COLORS } }) => COLORS.PRIMARY};
  }
  .DateInput_input {
    font-family: ${({ theme: { FONTS } }) => FONTS.PRIMARY};
    font-size: 0.875rem;
    text-align: center;
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }
  .DateInput_fang {
    display: none;
  }

  .DateRangePickerInput {
    margin-left: 9.375rem;
    margin-top: 0.625rem;
    border: none;
  }

  .DateRangePicker {
    width: 38.6875rem;
    margin-left: -0.3125rem;
    &_picker {
      padding-bottom: 1rem;
    }
  }

  .CalendarDay__today {
    color: ${({ theme: { COLORS } }) => COLORS.CALENDAR.CURRENT_DAY};
    font-family: ${({ theme: { FONTS } }) => FONTS.SECONDARY};
  }

  .DayPicker_transitionContainer {
    height: 21.25rem !important;
  }
`;
