import {
  Flex,
  HStack,
  FormControl,
  PinInput,
  Spinner,
  Box,
  Button as CapthaButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import React, { useContext, useState } from 'react';
import DialogModal from 'atomic-components/organisms/DialogModal';
import toast from 'services/toast';
import { getTFAFactorStatus } from 'services/tfa';
import { useEnableMemberTFA, useDisableMemberTFA } from 'hooks/tfa';
import AuthContext from 'contexts/Auth/context';
import { auth } from 'services/identity-provider/firebaseConfig';
import { MultiFactorResolver, RecaptchaVerifier } from 'firebase/auth';
import { formatCellPhone } from 'services/formatCellPhone';
import { PinInputField } from './styles';

type Props = {
  onClose: () => void;
  show: boolean;
  hashedVerificationCode: string;
  sendTFACode: (recaptchaVerifier: RecaptchaVerifier) => Promise<void>;
  disableTFAInfo?: {
    resolver: MultiFactorResolver;
    verificationId: string;
  };
  recaptchaVerifier?: RecaptchaVerifier;
  phoneNumber: string;
  onFinish: () => void;
};

const ToggleTFAStatusModal = ({
  show,
  onClose,
  disableTFAInfo,
  hashedVerificationCode,
  sendTFACode,
  recaptchaVerifier,
  phoneNumber,
  onFinish,
}: Props) => {
  const isTFAEnabled = getTFAFactorStatus();
  const [confirmationCodeValue, setConfirmationCodeValue] =
    useState<string>('');
  const { member } = useContext(AuthContext);
  const { currentUser } = auth;
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [isWrongCode, setIsWrongCode] = useState(false);

  const [activateMemberTFA, { loading: enableLoading }] = useEnableMemberTFA();
  const [disableMemberTFA, { loading: disableLoading }] = useDisableMemberTFA();

  const handleChangeConfirmationCodeValue = async (value: string) => {
    if (isWrongCode) setIsWrongCode(false);
    setConfirmationCodeValue(value);
    if (!member?.profile.email || !currentUser) return;

    if (value.length === 6) {
      try {
        if (isTFAEnabled && disableTFAInfo) {
          await disableMemberTFA({
            resolver: disableTFAInfo.resolver,
            verificationId: disableTFAInfo.verificationId,
            verificationCode: value,
          });

          toast.success(`Autenticação em 2 etapas desabilitada com sucesso.`);
        } else {
          await activateMemberTFA({
            hashCode: hashedVerificationCode,
            verificationCode: value,
            currentUser,
          });
          toast.success(`Autenticação em 2 etapas habilitado com sucesso.`);
        }
        onFinish();
        setConfirmationCodeValue('');
      } catch (e: any) {
        if (e.code.includes('auth/invalid-verification-code')) {
          setIsWrongCode(true);
          return;
        }
        toast.error(
          'Infelizmente, houve um erro inesperado. Tente novamente em outro momento.'
        );
      }
    }
  };

  const loading = enableLoading || disableLoading || loadingSendCode;

  const cellPhoneFormatted = formatCellPhone(phoneNumber);

  return (
    <Box>
      <DialogModal
        visible={show}
        header={{
          title: `${
            isTFAEnabled ? 'Desativar' : 'Ativar'
          } autenticação em 2 etapas`,
          icon: <Icon type="NEW_LOCK" color="PRIMARY" size={24} />,
        }}
        subtitle={`Insira abaixo o código de confirmação de 6 dígitos, enviado para ${cellPhoneFormatted} para concluir a ${
          isTFAEnabled ? 'desativação' : 'ativação'
        } da autenticação em 2 etapas.`}
        submitButton={{
          onPress: async () => {
            try {
              setLoadingSendCode(true);
              const currentRecaptchaVerifier =
                recaptchaVerifier ||
                new RecaptchaVerifier(
                  'pin-input',
                  {
                    size: 'invisible',
                  },
                  auth
                );
              await sendTFACode(currentRecaptchaVerifier);
            } catch {
              toast.error(
                'Infelizmente, houve um erro inesperado. Tente novamente em outro momento.'
              );
            } finally {
              setLoadingSendCode(false);
            }
          },
          text: 'Enviar código novamente',
          colorScheme: 'primary',
          loading,
        }}
        cancelButton={{
          onClick: () => onClose(),
          text: 'Cancelar',
        }}
        onClose={() => onClose()}
      >
        {loading ? (
          <Flex justifyContent="center" my={3}>
            <Spinner size="lg" colorScheme="gray" />
          </Flex>
        ) : (
          <FormControl mt={6} isInvalid={isWrongCode}>
            <Flex justifyContent="center">
              <HStack spacing={7}>
                <PinInput
                  value={confirmationCodeValue}
                  size="lg"
                  placeholder=""
                  onChange={(value) => handleChangeConfirmationCodeValue(value)}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
            <Flex justifyContent="center">
              <FormErrorMessage>
                O código digitado é inválido. Por favor, tente novamente.
              </FormErrorMessage>
            </Flex>
          </FormControl>
        )}
      </DialogModal>
      <CapthaButton
        position="absolute"
        left={0}
        background="transparent"
        width={2}
        height={2}
        cursor="default"
        id={`button-pin`}
        _active={{
          cursor: 'default',
        }}
        _hover={{
          cursor: 'default',
          background: 'transparent',
        }}
      />
    </Box>
  );
};

export default ToggleTFAStatusModal;
