import Config from 'config';
import { EMspStationsAgreementQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPower } from '../../../services/format';
import {
  ConnectorData,
  ConnectorTitle,
  Container,
  ErrorSolutionContainer,
  InfoText,
  InnerContainer,
} from './styles';

type Connector = NonNullable<
  NonNullable<
    NonNullable<EMspStationsAgreementQuery['emsp']>['roamingInfo']
  >['agreement']
>['stationsProfile'][number]['connectors'][number];

type Props = {
  connector: Connector;
  noContainer?: boolean;
};

const ConnectorInfo: React.FC<Props> = ({ connector, noContainer }) => {
  const { t } = useTranslation();
  const { power, id, status, type, currentType, errorCode } = connector;
  const errorSolution = errorCode && Config.LABELS.ERROR_SOLUTIONS[errorCode];
  const Main = noContainer ? React.Fragment : Container;

  return (
    <Main>
      <InnerContainer>
        <ConnectorData>
          <ConnectorTitle weight="bold" size={14}>
            {t('connectors-list.connector-row.title')}{' '}
            {`${id}`.padStart(2, '0')} - {currentType}-
            {Config.LABELS.CONNECTOR_TYPES[type]}
          </ConnectorTitle>

          <InfoText>{formatPower(power, { digits: 0 })}</InfoText>
          <InfoText>
            {t('connectors-list.status-row.title', {
              connectorStatus: t(Config.LABELS.CONNECTOR_STATUS[status]),
            })}
          </InfoText>
          {errorCode && (
            <>
              <InfoText>
                {t('connectors-list.reason-row.title')}{' '}
                {t(Config.LABELS.ERROR_LABELS[errorCode])}
              </InfoText>
              {errorSolution && (
                <ErrorSolutionContainer>
                  <InfoText>
                    {t('connectors-list.solution-row.title')} {t(errorSolution)}
                  </InfoText>
                </ErrorSolutionContainer>
              )}
            </>
          )}
        </ConnectorData>
      </InnerContainer>
    </Main>
  );
};

export default ConnectorInfo;
