import { gql } from '@apollo/client';

export default gql`
  query StationDetailsHMI($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      name
      openingHours
      description
      images
    }
  }
`;
