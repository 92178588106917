import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Modal from 'new-components/NewModal';
import TextInput from 'new-components/TextInput';
import { H3, BodyTypography } from 'new-components/Typographies/styles';
import { formatCurrency, formatEnergy } from 'services/format';
import secondsToTime from 'services/secondsToTime';
import toast from 'services/toast';
import {
  ForceStopChargeMutation,
  ForceStopChargeMutationVariables,
  CalculateSessionPriceQuery,
  CalculateSessionPriceQueryVariables,
} from 'generated/graphql';
import { Icon } from 'new-components/Icon';
import forceStopCharge from 'graphql/mutations/forceStopCharge';
import calculateSessionPrice from 'graphql/queries/calculateSessionPrice';
import { useAuthMutation, useNonWorkspaceableAuthQuery } from 'hooks';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencyType } from 'types';
import {
  sanitizeSessionDuration,
  sanitizeEnergyConsumed,
  energyMask,
} from './functions';
import { Header, Form, TypographyWithMT, FlexRow } from './styles';

type Props = {
  show: boolean;
  onClose: () => void;
  sessionId: string;
  energyConsumed: number;
  duration: number;
  connectorId: any;
  stationId: any;
  currencyType?: CurrencyType;
};

const ForceStopRechargeModal: React.FC<Props> = ({
  show,
  onClose,
  sessionId,
  connectorId,
  stationId,
  currencyType,
  ...params
}) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState(secondsToTime(params.duration));
  const [energyConsumed, setEnergyConsumed] = useState(
    formatEnergy(params.energyConsumed)
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [ForceStop] = useAuthMutation<
    ForceStopChargeMutation,
    ForceStopChargeMutationVariables
  >(forceStopCharge);

  const { data, refetch } = useNonWorkspaceableAuthQuery<
    CalculateSessionPriceQuery,
    CalculateSessionPriceQueryVariables
  >(calculateSessionPrice, {
    variables: {
      duration: sanitizeSessionDuration(duration),
      energyConsumed: sanitizeEnergyConsumed(energyConsumed),
      sessionId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (duration.includes('_')) {
      setIsDisabled(true);
      return;
    }

    setIsDisabled(false);
    setIsLoading(true);

    const timeout = setTimeout(() => {
      setIsLoading(false);
      refetch();
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [energyConsumed, duration, refetch]);

  const handleCloseModal = () => {
    setEnergyConsumed(formatEnergy(params.energyConsumed));
    setDuration(secondsToTime(params.duration));
    onClose();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await ForceStop({
        variables: {
          data: {
            duration: sanitizeSessionDuration(duration),
            energyConsumed: sanitizeEnergyConsumed(energyConsumed),
          },
          where: {
            connectorId,
            stationId,
            sessionId,
          },
        },
        fetchPolicy: 'no-cache',
      });

      switch (result.data?.forceStopCharge.__typename) {
        case 'Error':
          toast.error(t('force-stop-recharge-modal.toast-error-message'));
          break;
        case 'Ok':
          toast.success(
            t('force-stop-recharge-modal.toast-successfully-message')
          );
          break;
        default:
          break;
      }
      onClose();
      setEnergyConsumed(formatEnergy(params.energyConsumed));
      setDuration(secondsToTime(params.duration));
      setIsLoading(false);
    } catch (err) {
      toast.error(t('force-stop-recharge-modal.toast-error-message'));
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal show={show} onClose={handleCloseModal} size="sm" centered>
      <Modal.Header>
        <Header>
          <FlexRow>
            <Icon color="Error" type="NEW_WARNING" size={24} />
            <H3>{t('force-stop-recharge-modal.header.title')}</H3>
          </FlexRow>
          <BodyTypography>
            {t('force-stop-recharge-modal.header.subtitle')}
          </BodyTypography>
        </Header>
        <Modal.Header.Attachments padding="16">
          <Modal.Header.Close onClose={handleCloseModal} />
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <NumberFormat
            format="##:##:##"
            mask="_"
            allowEmptyFormatting
            value={duration}
            onValueChange={(values) => setDuration(values.formattedValue)}
            customInput={TextInput}
            name="duration"
            label={t(
              'force-stop-recharge-modal.body.duration-input.placeholder'
            )}
          />
          <NumberFormat
            format={energyMask}
            allowEmptyFormatting
            value={energyConsumed}
            onValueChange={(values) => setEnergyConsumed(values.formattedValue)}
            customInput={TextInput}
            name="energyConsumed"
            label={t(
              'force-stop-recharge-modal.body.energy-consumed-inp.placeholder'
            )}
          />
          <TypographyWithMT>
            <Trans
              i18nKey="force-stop-recharge-modal.body.warning-description"
              components={{
                Strong: <strong />,
              }}
            />
          </TypographyWithMT>
          <Flex justifyContent="flex-end">
            <Stack direction="row" spacing={6}>
              <Button
                variant="link"
                color="gray.900"
                fontSize="sm"
                onClick={handleCloseModal}
              >
                {t('force-stop-recharge-modal.body.cancel-btn.title')}
              </Button>
              <Button
                colorScheme="error"
                fontSize="sm"
                type="submit"
                isLoading={isLoading}
                isDisabled={isDisabled}
                color="white"
              >
                {t('force-stop-recharge-modal.body.stop-recharge-btn.title', {
                  price: formatCurrency(data?.calculateSessionPrice, {
                    currencyType,
                  }),
                })}
              </Button>
            </Stack>
          </Flex>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ForceStopRechargeModal;
