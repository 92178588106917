import Config from 'config';
import { Station } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import Dropdown from 'new-components/Dropdown';
import { H5 } from 'new-components/Typographies/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props = {
  stationId: Station['id'];
} & RouteComponentProps<{}>;

const StationSettingsDropdown = ({ stationId, history }: Props) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggleDropdown={setIsDropdownOpen}
      position="left"
      align="start"
      onClickOutside={() => setIsDropdownOpen(false)}
      content={() => (
        <Dropdown.Container>
          <Dropdown.Section>
            <>
              {hasScopes(['station:edit']) && (
                <Dropdown.Row
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(`/meus-eletropostos/${stationId}/settings`);
                  }}
                >
                  <H5>
                    {t('station-settings-dropdown.edit-information-row.title')}
                  </H5>
                </Dropdown.Row>
              )}
              {hasScopes(['station:control']) && (
                <Dropdown.Row
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(`/meus-eletropostos/${stationId}/control`);
                  }}
                >
                  <H5>
                    {t('station-settings-dropdown.station-control-row.title')}
                  </H5>
                </Dropdown.Row>
              )}
              {hasScopes(['station:internal']) && (
                <>
                  <Dropdown.Row
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(
                        `/meus-eletropostos/${stationId}/owner/edit`
                      );
                    }}
                  >
                    <H5>
                      {t(
                        'station-settings-dropdown.advanced-settings-row.title'
                      )}
                    </H5>
                  </Dropdown.Row>
                  <Dropdown.Row
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(
                        `/meus-eletropostos/${stationId}/owner/operation`
                      );
                    }}
                  >
                    <H5>
                      {t(
                        'station-settings-dropdown.send-and-receive-message-row.title'
                      )}
                    </H5>
                  </Dropdown.Row>
                </>
              )}
            </>
          </Dropdown.Section>
        </Dropdown.Container>
      )}
    >
      <Dropdown.TriggerButtonContainer
        active={isDropdownOpen}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Config.ICONS.SETTINGS height={20} width={20} />
      </Dropdown.TriggerButtonContainer>
    </Dropdown>
  );
};

export default withRouter(StationSettingsDropdown);
