import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

type SheetFile = {
  filename: string;
  sheets: Array<{
    tabName: string;
    data: Record<string, string | number>[];
  }>;
};

export const downloadSheetFile = (sheetFileData: SheetFile) => {
  const workbook = XLSX.utils.book_new();
  sheetFileData.sheets.forEach((sheet) => {
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(sheet.data),
      sheet.tabName
    );
  });
  XLSX.writeFile(workbook, `${sheetFileData.filename}.xlsx`);
};

export const downloadSheetFileFromBase64 = (
  sheetContentInBase64: string,
  fileName: string
) => {
  try {
    const byteCharacters = atob(sheetContentInBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, fileName);
  } catch (error) {
    console.warn({ error });
    throw new Error('Erro na conversão dos dados');
  }
};
