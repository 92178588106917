import { AllVoucherQuery, VoucherStatus } from 'generated/graphql';
import { VoucherClientContext } from 'pages/Payments/Voucher/context/client-context';
import { VoucherEventsContext } from 'pages/Payments/Voucher/context/events-context';
import { useContext, useEffect, useState } from 'react';
import { ListVoucherStatesContext } from '../../../context/state-context';

export type SelectedVoucherData = { id: string; status: VoucherStatus };
export type Voucher = NonNullable<AllVoucherQuery['vouchers']>[number];
export type VouchersSummary = AllVoucherQuery['vouchersSummary'];
export type VoucherTablePagination = {
  pageIndex: number;
  pageSize: number;
};

export function useVouchersTable() {
  const events = useContext(VoucherEventsContext).getEvents();
  const { parsedVoucherTableFilters, requestedVoucherName } = useContext(
    ListVoucherStatesContext
  );
  const client = useContext(VoucherClientContext).getClient()();

  const initialVoucherTablePagination: VoucherTablePagination = {
    pageIndex: 0,
    pageSize: 25,
  };
  const [voucherTablePagination, changeVoucherTablePagination] = useState(
    initialVoucherTablePagination
  );

  const { data: listVoucherData, loading: listVouchersLoading } =
    client.listVoucher({
      pagination: {
        limit: voucherTablePagination.pageSize,
        offset:
          voucherTablePagination.pageIndex * voucherTablePagination.pageSize,
      },
      filter: parsedVoucherTableFilters,
      search: requestedVoucherName,
    });
  const vouchers = listVoucherData.vouchers ?? [];
  const vouchersSummary = listVoucherData.vouchersSummary ?? { count: 0 };

  const renderBlankState = !listVoucherData?.vouchers?.length;

  const pageCount = Math.ceil(
    (vouchersSummary?.count ?? 0) / (voucherTablePagination?.pageSize ?? 0)
  );

  useEffect(() => {
    events.updateListVouchersLoading(listVouchersLoading);
    // eslint-disable-next-line
  }, [listVouchersLoading]);

  return {
    renderBlankState,
    listVouchersLoading,
    changeVoucherTablePagination,
    vouchers,
    pageCount,
    requestedVoucherName,
  };
}
