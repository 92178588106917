import { gql } from '@apollo/client';

export default gql`
  fragment AlarmLabelFields on StationAlarm {
    id
    stationId
    __typename
    ... on StationAlarmStopChargingFailed {
      driverId
      sessionId
    }
    ... on StationAlarmWentInoperative {
      errorCode
      vendorErrorCode
      vendorId
      info
    }
    ... on StationAlarmWentUnavailable {
      errorCode
      info
      vendorErrorCode
    }
  }
`;
