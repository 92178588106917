import { gql } from '@apollo/client';
import connectorsListFieldsFragment from 'graphql/fragments/connectorsListFields';

export default gql`
  query StationConnectors($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      name
      status
      connectors {
        ...ConnectorsListFields
      }
    }
  }
  ${connectorsListFieldsFragment}
`;
