import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const IonConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-11',
  CODE: 'canaa',
  LABEL: 'Canaã',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#00AFEF',
    CLICKABLE: '#0388B9',
    ERROR: '#F87060',
    SIDEBAR: '#00A7FD',
    LOGIN_LOGO_BACKGROUND: '#00A7FD',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#0388B9',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#00C1FD',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#2957A4',

    PREPARING: '#00C1FD',
    FINISHING: '#00C1FD',

    SELECTED_DATE_BOUND_BACKGROUND: '#0388B9',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#03759F',

    DATE_PRE_SELECTED_BACKGROUND: '#00AFEF',
    DATE_PRE_SELECTED_BORDER: '#25C3FD',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#3DCAFE',
    DATE_PRE_SELECTED_COLOR: '#03759F',

    DATE_SELECTED_BACKGROUND: '#00AFEF',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#0388B9',

    CURRENT_DAY: '#00AFEF',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#2957A4',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#2957A4',
      PREPARING: '#00C1FD',
      FINISHING: '#00C1FD',
    },

    BILLING: '#04E762',
    RECHARGES: '#00C1FD',
    AVAILABILITY: '#2957A4',

    BUTTON_AVAILABLE: '#00AFEF',
    BUTTON_UNAVAILABLE: '#3DCAFE',
    BUTTON_LOADING: '#25C3FD',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#00AFEF',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#0388B9',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#03759F',

      DATE_PRE_SELECTED_BACKGROUND: '#00AFEF',
      DATE_PRE_SELECTED_BORDER: '#25C3FD',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#3DCAFE',
      DATE_PRE_SELECTED_COLOR: '#03759F',

      DATE_SELECTED_BACKGROUND: '#00AFEF',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#0388B9',

      CURRENT_DAY: '#00AFEF',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -15.806472,
      lng: -47.957583,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Canaã - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/canaa',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#56D2FF',
      '100': '#3DCAFE',
      '200': '#25C3FD',
      '300': '#0CBCFD',
      '400': '#0CBCFD',
      '500': '#00AFEF',
      '600': '#0388B9',
      '700': '#03759F',
      '800': '#036386',
      '900': '#03506D',
    },
    LINK: {
      '50': '#56D2FF',
      '100': '#3DCAFE',
      '200': '#25C3FD',
      '300': '#0CBCFD',
      '400': '#0CBCFD',
      '500': '#00AFEF',
      '600': '#0388B9',
      '700': '#03759F',
      '800': '#036386',
      '900': '#03506D',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#B44900',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#D8D100',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#B1E4FF',
      '100': '#7ED3FF',
      '200': '#64CAFF',
      '300': '#4BC2FF',
      '400': '#31B9FF',
      '500': '#00A7FD',
      '600': '#0085CA',
      '700': '#0075B1',
      '800': '#006497',
      '900': '#004264',
    },
    RESERVE: {
      '50': '#5B8AD8',
      '100': '#467BD3',
      '200': '#336CCD',
      '300': '#2E62B9',
      '400': '#2957A4',
      '500': '#2957A4',
      '600': '#20427A',
      '700': '#1B3766',
      '800': '#162C51',
      '900': '#10213D',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default IonConfig;
