import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react';
import {
  CreateRfidMutation,
  CreateRfidMutationVariables,
} from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import { Icon } from 'new-components/Icon';
import { useContext, useState } from 'react';
import DialogModal from 'atomic-components/organisms/DialogModal';
import CreateRfid from 'graphql/mutations/createRfid';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SelectOrganizationDropdown } from 'atomic-components/molecules/SelectOrganizationDropdown';
import AuthContext from 'contexts/Auth/context';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  refetchRfidsInfo: () => void;
};

const CreateRFIDModal = ({ onClose, isOpen, refetchRfidsInfo }: Props) => {
  const { t } = useTranslation();
  const [rfidDecimalInputValue, setRfidDecimalInputValue] = useState('');
  const [rfidErrorMessage, setRfidErrorMessage] = useState('');
  const { organizations } = useContext(AuthContext);
  const [selectedOrganization, setSelectedOrganization] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(undefined);

  const [createRfid, { loading }] = useAuthMutation<
    CreateRfidMutation,
    CreateRfidMutationVariables
  >(CreateRfid, {
    context: { headers: { 'org-id': selectedOrganization?.id ?? '' } },
  });

  const successFeedbackAndUpdateRfidInfo = () => {
    toast.success(
      t('create-rfid-modal.toast-rfid-successfully-registered.message')
    );
    refetchRfidsInfo();
    setRfidDecimalInputValue('');
  };

  const handleCreateRFID = async () => {
    if (!selectedOrganization?.id) return;
    try {
      const { data } = await createRfid({
        variables: {
          data: {
            decimalId: rfidDecimalInputValue,
          },
          where: {
            id: selectedOrganization.id,
          },
        },
      });

      switch (data?.createRFID) {
        case 'RFIDAlreadyExistsError':
          return setRfidErrorMessage(
            t('create-rfid-modal.rfid-already-exists-error.message')
          );
        case 'InvalidRFIDInputError':
          return setRfidErrorMessage(
            t('create-rfid-modal.invalid-rfid-input-error')
          );
        case 'GenericError':
          return toast.error(t('create-rfid-modal.generic-error'));
        case 'RFIDSuccessfullyCreated':
          return successFeedbackAndUpdateRfidInfo();
      }
    } catch (e) {
      toast.error(t('create-rfid-modal.generic-error'));
    }
  };

  const handleCloseModal = () => {
    setRfidErrorMessage('');
    setRfidDecimalInputValue('');
    onClose();
  };

  const selectedOrganizationModules = organizations?.find(
    (o) => o.id === selectedOrganization?.id
  )?.modules;

  const isButtonDisabled =
    !rfidDecimalInputValue ||
    !selectedOrganization ||
    !selectedOrganizationModules?.includes('RFID');

  const isInvalidForm =
    !!rfidErrorMessage ||
    (selectedOrganization && !selectedOrganizationModules?.includes('RFID'));

  const handleChangeRfidDecimalInputValue = (value: string) => {
    if (!!value && !value.match(/^\d+$/)) return;
    setRfidDecimalInputValue(value);
  };

  return (
    <DialogModal
      header={{
        title: t('create-rfid-modal.dialog-modal.header.title'),
        icon: <Icon type="NEW_RFID" color="PRIMARY" size={24} />,
      }}
      subtitle={t('create-rfid-modal.dialog-modal.header.subtitle')}
      visible={isOpen}
      onClose={handleCloseModal}
      submitButton={{
        colorScheme: 'primary',
        onPress: handleCreateRFID,
        text: t('create-rfid-modal.dialog-modal.submit-btn.title'),
        loading,
        disabled: isButtonDisabled,
      }}
      cancelButton={{
        text: t('dialog-modal.modal.body.cancel-btn'),
        onClick: () => onClose(),
      }}
      size="md"
    >
      <FormControl isInvalid={isInvalidForm} mt={2}>
        <FormLabel>
          <Heading fontSize="sm">
            {t('create-rfid-modal.dialog-modal.form.title')}
          </Heading>
        </FormLabel>
        <Flex>
          <Box w="full" h={14}>
            <Flex>
              <Input
                placeholder={t(
                  'create-rfid-modal.dialog-modal.form.input.placeholder'
                )}
                isInvalid={!!rfidErrorMessage}
                value={rfidDecimalInputValue}
                onChange={(e) =>
                  handleChangeRfidDecimalInputValue(e.target.value)
                }
                onFocus={() => setRfidErrorMessage('')}
                mr={6}
              />
              <SelectOrganizationDropdown
                isInvalid={
                  selectedOrganization &&
                  !selectedOrganizationModules?.includes('RFID')
                }
                value={
                  selectedOrganization?.name ??
                  t(
                    'create-rfid-modal.dialog-modal.form.select-organization-dropdown.default-value'
                  )
                }
                setSelectedOrganization={setSelectedOrganization}
                organizations={organizations ?? []}
              />
            </Flex>
            <FormErrorMessage fontSize="xs">
              {!selectedOrganizationModules?.includes('RFID')
                ? t(
                    'create-rfid-modal.organization-without-rfid-module-error.message',
                    {
                      organizationName: selectedOrganization?.name,
                    }
                  )
                : rfidErrorMessage}
            </FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
    </DialogModal>
  );
};

export default CreateRFIDModal;
