import Config from 'config';
import { StationMonitoringPreviewQuery } from 'generated/graphql';
import { NewTag } from 'new-components/NewTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TagContainer } from '../styles';
import { Code, Information, Name } from './styles';

type StationInformationProps = {
  station: StationMonitoringPreviewQuery['station'];
};

const StationInformation = ({ station }: StationInformationProps) => {
  const { t } = useTranslation();
  return (
    <>
      {station && (
        <Information>
          <Column>
            <TagContainer margin="0 0 6px 0">
              <NewTag type="text" size="big" status={station.status}>
                {t(Config.LABELS.STATION_STATUS[station.status])}
              </NewTag>
            </TagContainer>
            <Name>{station.name}</Name>
            <Code>{station.tag}</Code>
          </Column>
        </Information>
      )}
    </>
  );
};

export { StationInformation };
