import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const GreenspaceConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-13',
  CODE: 'greenspace',
  LABEL: 'Greenspace',
  LANGUAGE: 'es_ES',
  COUNTRY: 'PA',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#4C7C71',
    CLICKABLE: '#395C54',
    ERROR: '#F87060',
    SIDEBAR: '#0F2C33',
    LOGIN_LOGO_BACKGROUND: '#0F2C33',

    RISE: '#ABD699',
    FALL: '#F87060',
    NEUTRAL: '#4C7C71',

    CRITICAL: '#F74C39',
    WARNING: '#E6B606',

    PreAuthorized: '#F9FAFA',
    Captured: '#578926',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#4C7C71',
    CHARGING: '#00C1FD',
    INOPERATIVE: '#EA7128',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91',

    PREPARING: '#00C1FD',
    FINISHING: '#00C1FD',

    SELECTED_DATE_BOUND_BACKGROUND: '#395C54',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#0A2025',

    DATE_PRE_SELECTED_BACKGROUND: '#7CB2A6',
    DATE_PRE_SELECTED_BORDER: '#5E9D8F',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#6CA89A',
    DATE_PRE_SELECTED_COLOR: '#0A2025',

    DATE_SELECTED_BACKGROUND: '#4C7C71',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#395C54',

    CURRENT_DAY: '#4C7C71',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#578926',
      Subsidized: '#578926',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#4C7C71',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#662C91',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#4C7C71',
      PREPARING: '#00C1FD',
      CHARGING: '#00C1FD',
      FINISHING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },

    BILLING: '#ABD699',
    RECHARGES: '#00C1FD',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#4C7C71',
    BUTTON_UNAVAILABLE: '#6CA89A',
    BUTTON_LOADING: '#6CA89A',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#0F2C33',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#395C54',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#0A2025',

      DATE_PRE_SELECTED_BACKGROUND: '#7CB2A6',
      DATE_PRE_SELECTED_BORDER: '#5E9D8F',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#6CA89A',
      DATE_PRE_SELECTED_COLOR: '#0A2025',

      DATE_SELECTED_BACKGROUND: '#4C7C71',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#395C54',

      CURRENT_DAY: '#7CB2A6',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Não temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Greenspace - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/greenspace',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#7CB2A6',
      '100': '#6CA89A',
      '200': '#5E9D8F',
      '300': '#558C80',
      '400': '#538579',
      '500': '#4C7C71',
      '600': '#395C54',
      '700': '#304C45',
      '800': '#263C37',
      '900': '#1C2C28',
    },
    LINK: {
      '50': '#69A2B1',
      '100': '#588996',
      '200': '#4B7580',
      '300': '#3C5E67',
      '400': '#2D464D',
      '500': '#0F2C33',
      '600': '#0C252B',
      '700': '#0A2025',
      '800': '#07171B',
      '900': '#07191E',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#E7F4E1',
      '100': '#D8ECCF',
      '200': '#C9E5BD',
      '300': '#BADEAB',
      '400': '#AFD89E',
      '500': '#ABD699',
      '600': '#8DC676',
      '700': '#7FBE64',
      '800': '#70B653',
      '900': '#64A848',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#00C1FD',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#7CB2A6',
      '100': '#6CA89A',
      '200': '#5E9D8F',
      '300': '#558C80',
      '400': '#538579',
      '500': '#4C7C71',
      '600': '#395C54',
      '700': '#304C45',
      '800': '#263C37',
      '900': '#1C2C28',
    },
    SECONDARY: {
      '50': '#69A2B1',
      '100': '#588996',
      '200': '#4B7580',
      '300': '#3C5E67',
      '400': '#2D464D',
      '500': '#0F2C33',
      '600': '#0C252B',
      '700': '#0A2025',
      '800': '#07171B',
      '900': '#07191E',
    },
    RESERVE: {
      '50': '#bd92de',
      '100': '#a66bd2',
      '200': '#9a57cc',
      '300': '#8e43c6',
      '400': '#8238b8',
      '500': '#662C91',
      '600': '#4a206a',
      '700': '#3d1a56',
      '800': '#2f1443',
      '900': '#13081c',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default GreenspaceConfig;
