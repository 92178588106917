import {
  Box,
  Button as CapthaButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';

import { Icon } from 'new-components/Icon';
import DialogModal from 'atomic-components/organisms/DialogModal';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { IconPassword } from 'components/Input/styles';
import AuthContext from 'contexts/Auth/context';
import { dispatchTFACode, reauthenticateMember } from 'services/tfa';
import toast from 'services/toast';
import { MultiFactorResolver, RecaptchaVerifier } from 'firebase/auth';
import { getOrgCountryDDIPhoneNumber } from 'services/organization';
import { auth } from 'services/identity-provider/firebaseConfig';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onFinish: () => void;
  setHashedVerificationCode: (value: React.SetStateAction<string>) => void;
  phoneNumber: string;
  setDisableTFAInfo: React.Dispatch<
    React.SetStateAction<
      | {
          verificationId: string;
          resolver: MultiFactorResolver;
        }
      | undefined
    >
  >;
};

const ConfirmPasswordTFAModal = ({
  onClose,
  isOpen,
  onFinish,
  setDisableTFAInfo,
  setHashedVerificationCode,
  phoneNumber,
}: Props) => {
  const { t } = useTranslation();
  const { member } = useContext(AuthContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const { currentUser } = auth;
  const [currentRecaptcha, setCurrentRecaptcha] =
    useState<RecaptchaVerifier | null>(null);
  const [loading, setLoading] = useState(false);

  const createNewRecaptcha = () =>
    new RecaptchaVerifier(
      `button-pin`,
      {
        size: 'invisible',
      },
      auth
    );

  const handleSendTFACode = async (captcha: RecaptchaVerifier) => {
    if (!currentUser) return;
    const { verificationId } = await dispatchTFACode(
      `${getOrgCountryDDIPhoneNumber()}${phoneNumber}`,
      currentUser,
      captcha
    );
    setHashedVerificationCode(verificationId);
  };

  const handleReauthenticateMemberButtonPress = async () => {
    if (!member?.profile.email || !currentUser) return;

    const recaptchaVerifier = currentRecaptcha ?? createNewRecaptcha();
    setCurrentRecaptcha(recaptchaVerifier);
    setLoading(true);

    try {
      const result = await reauthenticateMember({
        currentUser,
        email: member.profile.email,
        password,
        recaptchaVerifier,
      });

      if (!result) {
        await handleSendTFACode(recaptchaVerifier);
      }
      if (result) {
        setDisableTFAInfo({
          resolver: result.resolver,
          verificationId: result.verificationId,
        });
      }

      onFinish();
      setPassword('');
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        setIsInvalid(true);
        return;
      }
      toast.error(
        'Infelizmente, houve um erro inesperado. Tente novamente em outro momento.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setIsPasswordVisible(false);
  };

  return (
    <>
      <CapthaButton
        position="absolute"
        left={6}
        bottom={2}
        width={2}
        height={2}
        background="transparent"
        cursor="default"
        id="button-pin"
        _active={{
          cursor: 'default',
        }}
        _hover={{
          cursor: 'default',
          background: 'transparent',
        }}
      />
      <DialogModal
        header={{
          title: 'Confirme sua senha',
          icon: <Icon type="NEW_LOCK" color="PRIMARY" size={24} />,
        }}
        subtitle="Antes de ativar a autenticação em duas etapas, precisamos confirmar a sua senha de acesso, para garantirmos a segurança da sua conta."
        visible={isOpen}
        onClose={() => handleCloseModal()}
        submitButton={{
          colorScheme: 'primary',
          onPress: () => handleReauthenticateMemberButtonPress(),
          text: 'Confirmar',
          disabled: isInvalid || password.trim() === '',
          loading,
        }}
        cancelButton={{
          text: t('dialog-modal.modal.body.cancel-btn'),
          onClick: () => handleCloseModal(),
        }}
        size="sm"
      >
        <FormControl>
          <Text mb={2} fontSize="sm">
            Por favor, digite a sua senha:
          </Text>
          <Flex>
            <Box w="full" h={14}>
              <FormControl isInvalid={isInvalid}>
                <InputGroup>
                  <Input
                    focusBorderColor="none"
                    placeholder={t('login.form.password-input.placeholder')}
                    value={password}
                    type={isPasswordVisible ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setIsInvalid(false)}
                  />
                  <InputRightElement
                    children={
                      <IconPassword
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        show={isPasswordVisible}
                      />
                    }
                  />
                </InputGroup>
                <FormErrorMessage>Senha incorreta</FormErrorMessage>
              </FormControl>
            </Box>
          </Flex>
        </FormControl>
      </DialogModal>
    </>
  );
};

export default ConfirmPasswordTFAModal;
