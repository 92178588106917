import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationConfigurationKey(
    $key: String!
    $value: String!
    $where: SelectStationInput!
  ) {
    changeStationConfigurationKey(key: $key, value: $value, where: $where)
  }
`;
