import ConfigType from 'config/typing';
import { PaymentCardBrand } from 'generated/graphql';

export const getCardBrandIcon = (
  brand?: PaymentCardBrand
): keyof ConfigType['NEW_ICONS'] => {
  switch (brand) {
    case 'Master':
      return 'NEW_MASTER';
    case 'Visa':
      return 'NEW_VISA';
    case 'Elo':
      return 'NEW_ELO';
    default:
      return 'NEW_CREDIT_CARD';
  }
};
