import { Typography } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 25px 24px 16px;
  box-sizing: border-box;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled(Typography).attrs({
  weight: 'bold',
  size: 24,
  color: 'PRIMARY_GRAY',
})``;

export const InfoText = styled(Typography).attrs({
  size: 12,
  color: 'SECONDARY_GRAY',
})`
  margin-left: 12px;
`;
