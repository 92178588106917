import defaultTheme from './theme';
import {
  BorderStyleToken,
  PaddingToken,
  RadiusToken,
  SpaceToken,
  ThemeProps,
} from './types';

function getTheme(props: ThemeProps) {
  return props.theme ? props.theme : defaultTheme;
}

// getRadius('RADIUS-12', { theme }) === "12px"
export function getRadius(token: RadiusToken, props: ThemeProps) {
  return `${getTheme(props).radius[token]}px`;
}

// getPadding('PADDING-12', { theme }) === "12px"
export function getPadding(token: PaddingToken, props: ThemeProps) {
  return `${getTheme(props).padding[token]}px`;
}

// getSpace('SPACE-12', { theme }) === "12px"
export function getSpace(token: SpaceToken, props: ThemeProps) {
  return `${getTheme(props).space[token]}px`;
}

// getBorderStyle('default', { theme }) === "1px solid ${Config.COLORS.TERTIARY_GRAY}"
export function getBorderStyle(token: BorderStyleToken, props: ThemeProps) {
  return `${getTheme(props).borderStyle[token]}`;
}
