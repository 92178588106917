import {
  Alert,
  Flex,
  HStack,
  AlertTitle,
  useToast,
  Button,
} from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { ReactNode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  children?: ReactNode;
  loading?: boolean;
  inputsValues?: any;
  onClick: () => void;
  resetFields: () => void;
  isVisible?: boolean;
  isValid?: boolean;
};
const Toast = ({
  id,
  onClick,
  inputsValues,
  resetFields,
  loading,
  isVisible,
  isValid = true,
}: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const currentInputsValues = Object.values(inputsValues).join();

  const handleSubmit = useCallback(() => {
    onClick();
  }, [onClick]);

  const handleResetFields = useCallback(() => {
    resetFields();
  }, [resetFields]);

  const CustomToast = useCallback(
    () => (
      <Alert
        status="info"
        bg="gray.900"
        color="white"
        borderRadius="8"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        minWidth="max-content"
        mb="2"
      >
        <Flex>
          <HStack spacing="16">
            <Flex align="center" gridGap="4">
              <Icon type="NEW_INFO" color="WHITE" />
              <AlertTitle fontSize="14" fontWeight="normal">
                {t('station-page.toast.alert.title')}
              </AlertTitle>
            </Flex>
            <Flex>
              <HStack spacing="4">
                <Button
                  size="sm"
                  variant="link"
                  colorScheme="gray"
                  color="white"
                  onClick={handleResetFields}
                  fontWeight="bold"
                >
                  {t('station-page.toast.alert.reset-btn.title')}
                </Button>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  isLoading={loading}
                  fontWeight="bold"
                >
                  {t('station-page.toast.alert.submit-btn.title')}
                </Button>
              </HStack>
            </Flex>
          </HStack>
        </Flex>
      </Alert>
    ),
    [loading, handleResetFields, handleSubmit, t]
  );

  useEffect(() => {
    if (!isVisible || !isValid) {
      return toast.closeAll();
    }
    if (!toast.isActive(id)) {
      toast({
        duration: null,
        id,
        isClosable: false,
        render: () => <CustomToast />,
      });
    } else {
      toast.update(id, {
        duration: null,
        isClosable: false,
        render: () => <CustomToast />,
      });
    }
  }, [
    CustomToast,
    currentInputsValues,
    id,
    isValid,
    isVisible,
    loading,
    toast,
  ]);
  return <></>;
};

export default Toast;
