import { gql } from '@apollo/client';

export default gql`
  query StationAccessRestriction($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      openingHours
      accessRestriction {
        active
        description
        establishmentType
      }
    }
  }
`;
