import AuthContext from 'contexts/Auth/context';
import {
  GenerateOrdersReportQuery,
  GenerateOrdersReportQueryVariables,
} from 'generated/graphql';
import { useContext } from 'react';
import { TimeRange } from 'services/timeRange';
import { useClient } from 'urql';
import generateOrdersReport from 'graphql/queries/generateOrdersReport';

type ExportSessionArgs = {
  filterOrdersByStationId?: string;
  timeRange?: TimeRange;
};

export const useExportOrders = ({ timeRange }: ExportSessionArgs) => {
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  const client = useClient();
  return async () => {
    try {
      const { data, error } = await client
        .query<GenerateOrdersReportQuery, GenerateOrdersReportQueryVariables>(
          generateOrdersReport,
          {
            timeRange: timeRange?.toTimeRangeInput(new Date()),
          },
          {
            fetchOptions: {
              headers: {
                authorization: `Bearer ${memberAuthToken}`,
                'org-ids': selectedOrganizationIds as any,
              },
            },
            requestPolicy: 'network-only',
          }
        )
        .toPromise();

      const url = data?.generateOrdersReport.url;

      if (error) throw new Error(error.message);
      if (!url) return;

      saveAs(url, `historico-pagamentos.xlsx`);
    } catch (error) {
      alert('Erro ao baixar dados');
    }
  };
};
