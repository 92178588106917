import React, { useState } from 'react';
import { StationLogsAlarmsListingQuery } from 'generated/graphql';

type Dispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export type StationLogsContextType = {
  selectedAlarmId?: number;
  filterResolvedAlarms: boolean;
  alarms: NonNullable<StationLogsAlarmsListingQuery['station']>['alarms'];
  setSelectedAlarmId: Dispatch<number | undefined>;
  setAlarmResolvedFilter: Dispatch<boolean>;
  setAlarms: Dispatch<
    NonNullable<StationLogsAlarmsListingQuery['station']>['alarms']
  >;
  setSelectedAlarmStationLogId: Dispatch<number | null>;
  selectedAlarmStationLogId?: number | null;
};

export const StationLogsContext = React.createContext<StationLogsContextType>(
  {} as StationLogsContextType
);

export const StationLogProvider: React.FC = ({ children }) => {
  const [selectedAlarmId, setSelectedAlarmId] =
    useState<StationLogsContextType['selectedAlarmId']>();

  const [filterResolvedAlarms, setAlarmResolvedFilter] =
    useState<StationLogsContextType['filterResolvedAlarms']>(false);

  const [selectedAlarmStationLogId, setSelectedAlarmStationLogId] = useState<
    number | null
  >(null);

  const [alarms, setAlarms] = useState<StationLogsContextType['alarms']>([]);

  return (
    <StationLogsContext.Provider
      value={{
        selectedAlarmId,
        filterResolvedAlarms,
        alarms,
        setAlarmResolvedFilter,
        setSelectedAlarmId,
        setAlarms,
        selectedAlarmStationLogId,
        setSelectedAlarmStationLogId,
      }}
    >
      {children}
    </StationLogsContext.Provider>
  );
};
