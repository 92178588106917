import Config from 'config/index';
import { InjectColorProps } from '.';
import { RowLoading } from './styles';

const DropdownRowLoading = ({ color = 'PRIMARY_GRAY' }: InjectColorProps) => (
  <RowLoading>
    <Config.ICONS.SPINNER
      color={Config.COLORS[color]}
      height={16}
      width="100%"
    />
  </RowLoading>
);

export { DropdownRowLoading };
