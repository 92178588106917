import { useTranslation } from 'react-i18next';
import { VStack, Text, Heading, Button } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { Link } from 'react-router-dom';
import { Header } from './styles';

type Props = {
  onClose: () => void;
  show: boolean;
  minimumWalletDepositAmount?: number;
  availableBalance?: number;
  bankSlipLink: string;
};

const SucessGeneratingBankSlip = ({ show, onClose, bankSlipLink }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal centered onClose={() => onClose()} show={show} size="sm">
        <Modal.Header>
          <Header>
            <Heading fontSize={'2xl'}>
              {t('sucess-bank-slip-modal.bank-slip-sucess.header-title')}
            </Heading>
          </Header>
          <Modal.Header.Attachments padding="24">
            <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
          </Modal.Header.Attachments>
        </Modal.Header>
        <VStack padding={'24px 40px 24px 40px'}>
          <VStack
            padding={'24px 100px 24px 100px'}
            marginBottom={'18px'}
            align={'center'}
            spacing={'2.5'}
            bg={'#F4F4F4'}
          >
            <Heading fontSize={'lg'}>
              {t('sucess-bank-slip-modal.bank-slip-sucess.inside-header-title')}
            </Heading>
            <Icon type="NEW_CHECK" size={256} color="AVAILABLE"></Icon>
            <Text
              align={'center'}
              fontWeight={'normal'}
              lineHeight={'16.8px'}
              fontSize={'sm'}
              width={'304px'}
              marginBottom={'24px'}
            >
              {t('sucess-bank-slip-modal.bank-slip-sucess.body-text')}
            </Text>
          </VStack>

          <Link
            to={{
              pathname: bankSlipLink,
            }}
            target="_blank"
          >
            <Button height={'36px'} width={'180px'}>
              <Heading color={'#FFFFFF'} lineHeight={'16.8px'} size={'14px'}>
                {t('sucess-bank-slip-modal.bank-slip-sucess.footer-button')}
              </Heading>
            </Button>
          </Link>
        </VStack>
      </Modal>
    </>
  );
};

export default SucessGeneratingBankSlip;
