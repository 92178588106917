import Card from 'components/Card';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import CardHeader from 'components/Card/CardHeader/index';
import {
  ConnectorsSummaryQuery,
  ConnectorsSummaryQueryVariables,
  ConnectorStatus,
} from 'generated/graphql';
import connectorsSummary from 'graphql/queries/connectorsSummary';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectorsSummary from './ConnectorsSummary';

type Organization = ConnectorsSummaryQuery;

const getConnectorsSummaryData = (organization: Organization | undefined) => {
  const {
    sessionsSummary: { energyConsumed = 0 } = {},
    connectorsSummaryPerStatus = [],
  } = organization || {};

  const getConnectorsCount = (statuses: ConnectorStatus[]) =>
    connectorsSummaryPerStatus
      .filter(
        (connectorSummary) =>
          connectorSummary && statuses.includes(connectorSummary.status)
      )
      .reduce(
        (total, connectorSummary) => total + (connectorSummary?.count || 0),
        0
      );

  return {
    energyConsumed,
    availableConnectors: getConnectorsCount(['AVAILABLE']),
    chargingConnectors: getConnectorsCount([
      'PREPARING',
      'CHARGING',
      'FINISHING',
    ]),
    inoperativeConnectors: getConnectorsCount(['INOPERATIVE', 'RESERVED']),
    unknownConnectors: getConnectorsCount(['UNKNOWN']),
  };
};

const ConnectorsSummaryCard = () => {
  const hasScopes = useHasScopes();
  const { t } = useTranslation();
  const { data, error, loading } = useRealtimeQuery<
    ConnectorsSummaryQuery,
    ConnectorsSummaryQueryVariables
  >(connectorsSummary, {
    variables: {
      includeSessionSummary: hasScopes(['session:read']),
    },
    pollInterval: 1000 * 10,
  });

  const connectorSummaryData = useMemo(
    () => getConnectorsSummaryData(data),
    [data]
  );

  return (
    <Card type="simple" loading={loading} error={error}>
      <CardHeader height={24}>
        <CardTitle title={t('home.connectors-summary.title')} />
      </CardHeader>
      <ConnectorsSummary data={connectorSummaryData} />
    </Card>
  );
};

export default ConnectorsSummaryCard;
