import { Box, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { VoucherValidityPeriodSelection } from './ValidityPeriod';
import { VoucherUsageLimitSelection } from './UsageLimit';
import { VoucherOrganizationSelection } from './SelectOrganizations';
import { VoucherDiscountAmountSelection } from './DiscountSelection';

const Rules = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Flex
        bg="white"
        borderColor="gray.100"
        borderRadius="1rem"
        borderWidth="thin"
        flexDirection="column"
        p="1.5rem"
        gap="1.25rem"
      >
        <Box w="100%" bg="white">
          <Heading fontSize="xl">
            {t('payments.voucher-tab.create-voucher.rules.title')}
          </Heading>
        </Box>
        <Flex w="100%" flexDirection="row" mt="4">
          <Flex w="60%" gap="1.5rem" flexDirection="column">
            <VoucherDiscountAmountSelection />
            <VoucherUsageLimitSelection />
            <VoucherValidityPeriodSelection />
          </Flex>
          <VoucherOrganizationSelection />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Rules;
