export const getFileFromURL = async (url: string): Promise<File> => {
  const resp = await fetch(url, { method: 'GET' });
  const blob = await resp.blob();
  const newFile = await new File([blob], `${url}`, {
    type: resp.headers.get('content-type') ?? 'image/jpeg',
  });
  return newFile;
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  // Cast to a File() type
  return theBlob as File;
};

export const fileToDataURL = (file: File): Promise<string> => {
  const fr = new FileReader();
  return new Promise((resolve) => {
    fr.onload = function onload() {
      resolve(fr.result as string);
    };
    fr.readAsDataURL(file);
  });
};
