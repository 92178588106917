import { ArchiveStationMutationVariables } from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { BodyTypography, H3 } from 'new-components/Typographies/styles';
import React from 'react';
import toast from 'services/toast';
import archiveStation from 'graphql/mutations/archiveStation';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Header, Body, Label, ButtonWrapper } from './styles';

type Props = {
  onClose: () => void;
  show: boolean;
  onClickStopRecharge?: () => void;
  setIsModalArchiveStation: (isModalArchiveStation: boolean) => void;
  stationId: string;
};

const ConfirmStationArchiving: React.FC<Props> = ({
  onClose,
  show,
  stationId,
  setIsModalArchiveStation,
}) => {
  const { t } = useTranslation();
  const [ArquiveStation, { loading }] =
    useAuthMutation<ArchiveStationMutationVariables>(archiveStation);

  const handleArquiveStation = async () => {
    try {
      await ArquiveStation({
        variables: {
          where: {
            stationId,
          },
        },
      });
      toast.success(
        t('confirm-station-archiving-modal.toast-successfully-message')
      );
    } catch (e) {
      toast.error('confirm-station-archiving-modal.toast-error-message');
    }

    setIsModalArchiveStation(false);
  };
  return (
    <Modal size="sm" show={show} onClose={onClose} centered>
      <Modal.Header>
        <Header>
          <Icon color="Error" type="NEW_WARNING" size={24} />
          <H3>{t('confirm-station-archiving-modal.header.title')}</H3>
        </Header>
        <Modal.Header.Attachments padding="24">
          <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Label>
            <BodyTypography>
              {t('confirm-station-archiving-modal.body.label')}
            </BodyTypography>
          </Label>
          <ButtonWrapper>
            <Flex>
              <Stack direction="row" spacing={6}>
                <Button
                  variant="link"
                  color="gray.900"
                  size="sm"
                  onClick={onClose}
                >
                  {t('confirm-station-archiving-modal.body.cancel-btn')}
                </Button>
                <Button
                  colorScheme="error"
                  size="sm"
                  onClick={handleArquiveStation}
                  isLoading={loading}
                  color="white"
                >
                  {t('confirm-station-archiving-modal.body.archive-btn')}
                </Button>
              </Stack>
            </Flex>
          </ButtonWrapper>
        </Body>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmStationArchiving;
