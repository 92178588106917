import React from 'react';

import { GetDriverRfidsQuery } from 'generated/graphql';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import RFIDStatusTag from 'atomic-components/atoms/StatusTag';
import { RFIDDropdown } from 'pages/RFID/RFIDDropdown';
import LoadingComponent from 'new-components/LoadingComponent';
import { LoadingContainer } from '../styles';

export type RFIDType = NonNullable<
  GetDriverRfidsQuery['driver']
>['rfids'][number];

type Props = {
  rfids?: RFIDType[];
  refetchInfo?: () => void;
  loading: boolean;
};

const RFIDs: React.FC<Props> = ({ rfids, refetchInfo, loading }) => {
  if (loading) {
    return (
      <LoadingContainer>
        <LoadingComponent size={51} />
      </LoadingContainer>
    );
  }
  return (
    <Flex gap="4">
      {rfids?.length
        ? rfids.map((rfid, i) => (
            <Box
              width={268}
              p={3}
              borderRadius="8"
              border="1px"
              borderColor="gray.100"
              key={i}
              bg="white"
            >
              <Flex alignItems="center">
                <Icon color="PRIMARY" type="NEW_RFID" size={20}></Icon>
                <Text marginLeft="1" fontWeight="bold">
                  {rfid.profile?.name || '-'}
                </Text>
                <Box marginLeft="auto">
                  <RFIDDropdown
                    status={rfid.status}
                    decimalId={rfid.decimalId}
                    refetchInfo={refetchInfo}
                    isBeingUsedInsideModal={true}
                  />
                </Box>
              </Flex>
              <Text fontSize="14" marginBottom="1">
                {rfid.decimalId}
              </Text>
              <RFIDStatusTag status={rfid.status} />
            </Box>
          ))
        : '-'}
    </Flex>
  );
};

export default RFIDs;
