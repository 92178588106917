import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationLocation(
    $where: SelectStationInput!
    $latitude: Float!
    $longitude: Float!
    $city: String!
    $country: String!
    $neighborhood: String!
    $postalCode: String!
    $state: String!
    $street: String!
    $streetNumber: String!
  ) {
    changeStationLocation(
      where: $where
      coordinates: { latitude: $latitude, longitude: $longitude }
      address: {
        city: $city
        country: $country
        neighborhood: $neighborhood
        postalCode: $postalCode
        state: $state
        street: $street
        streetNumber: $streetNumber
      }
    )
  }
`;
