import React, { useState } from 'react';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  Heading,
} from '@chakra-ui/react';
import useHasScopes from 'hooks/useHasScope';
import { useTranslation } from 'react-i18next';
import useHasModule from 'hooks/useHasModule';
import Users from './Users';
import Vehicles from './Vehicles';
import AccessRestriction from './AccessRestriction';

type TabProps = {
  icon: keyof NotNestedNewIcons;
  text: string;
};

const Customers = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const hasModule = useHasModule();
  const [currentIndex, setCurrentIndex] = useState(0);
  const tabs = [
    hasModule('USERS') &&
      hasScopes(['driver:read']) && {
        icon: 'NEW_USER',
        text: t('customers.drivers-tab.title'),
      },
    hasModule('FLEET') &&
      hasScopes(['vehicle:read']) && {
        icon: 'NEW_TRUCK',
        text: t('customers.vehicles-tab.title'),
      },
    hasScopes(['group:read']) && {
      icon: 'NEW_ORG',
      text: t('customers.access-restriction-tab.title'),
    },
  ] as TabProps[];

  return (
    <Flex w="100%" bg="gray.50" flexDirection="column">
      <Box p="6" pt="7" bg="white">
        <Heading fontSize="2xl">{t('customers.header.title')}</Heading>
      </Box>
      <Tabs onChange={(index) => setCurrentIndex(index)}>
        <TabList pl="6">
          {tabs
            .filter((tab) => !!tab)
            .map((tab, index) => (
              <Tab data-test={`customers-head-button-${index}`}>
                <Box pr="2">
                  <Icon
                    type={tab.icon}
                    color={
                      index === currentIndex ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'
                    }
                  />
                </Box>
                {tab.text}
              </Tab>
            ))}
        </TabList>
        <TabPanels>
          {hasScopes(['driver:read']) && hasModule('USERS') && (
            <TabPanel>
              <Users />
            </TabPanel>
          )}
          {hasScopes(['vehicle:read']) && hasModule('FLEET') && (
            <TabPanel>
              <Vehicles />
            </TabPanel>
          )}

          {hasScopes(['group:read']) && (
            <TabPanel>
              <AccessRestriction />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default Customers;
