import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Heading,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import Config from 'config';
import { Link } from 'components';
import { useState } from 'react';
import useMutation from 'hooks/useAuthMutation';
import {
  SendDriverPasswordRedefinitionEmailMutation,
  SendDriverPasswordRedefinitionEmailMutationVariables,
} from 'generated/graphql';
import sendDriverPasswordRedefinitionEmailMutation from 'graphql/mutations/send-driver-password-redefinition-email';
import { useTranslation } from 'react-i18next';
import { useURLParams } from 'hooks/useURLParam';
import { BlankState } from 'main/account-exclusion/components/blank-state';

export const RequestDriverPasswordReset = () => {
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const urlParam = useURLParams();
  const appCode = urlParam.get('appCode');

  const [sendDriverPasswordRedefinitionEmail, { data, loading }] = useMutation<
    SendDriverPasswordRedefinitionEmailMutation,
    SendDriverPasswordRedefinitionEmailMutationVariables
  >(sendDriverPasswordRedefinitionEmailMutation);

  if (!appCode) return <BlankState />;

  const handleSendDriverPasswordResetEmail = async () => {
    try {
      await sendDriverPasswordRedefinitionEmail({
        variables: {
          email: emailInput,
          orgId: appCode,
        },
      });
    } catch (error) {
      setError(true);
    }
  };

  const handleChangeInputEmail = (value: string) => {
    setError(false);
    setEmailInput(value);
  };

  if (data?.sendDriverPasswordRedefinitionEmail) {
    return (
      <Flex
        h="full"
        margin="auto"
        maxWidth={416}
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Config.ICONS.CHECK_THIN width={120} height={120} />

        <Text textAlign="center" color="gray.500" mb={8}>
          {t('account-exclusion.driver-password-reset.success-feedback.text')}
        </Text>

        <Link
          to={`/account-exclusion-login/?appCode=${appCode}`}
          textDecoration="underline"
        >
          {t('account-exclusion.driver-password-reset.go-back-link-text')}
        </Link>
      </Flex>
    );
  }

  const isButtonDisabled = !!error || !emailInput.trim();

  return (
    <Flex
      h="full"
      margin="auto"
      maxWidth={512}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Heading size="lg" pb={1}>
        {t('account-exclusion.driver-password-reset.title')}
      </Heading>
      <Text color="gray.500" textAlign="center">
        {t('account-exclusion.driver-password-reset.subtitle')}
      </Text>

      <FormControl isInvalid={error} mt={8} h={16}>
        <FormLabel fontSize="sm">
          {t('account-exclusion.email-form-label')}
        </FormLabel>
        <Input
          placeholder={t('account-exclusion-form.email-input-placeholder')}
          onChange={(e) => handleChangeInputEmail(e.target.value)}
        />
        <FormErrorMessage>
          {t('account-exclusion.driver-password-reset.form-error-message')}
        </FormErrorMessage>
      </FormControl>

      <Button
        mt={12}
        w="full"
        isLoading={loading}
        disabled={isButtonDisabled}
        onClick={() => handleSendDriverPasswordResetEmail()}
      >
        {t('account-exclusion.driver-password-reset.button-title')}
      </Button>

      <Box mt={10}>
        <Link
          to={`/account-exclusion-login/?appCode=${appCode}`}
          textDecoration="underline"
        >
          {t('account-exclusion.driver-password-reset.go-back-link-text')}
        </Link>
      </Box>
    </Flex>
  );
};
