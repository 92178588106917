import { Typography } from 'components';
import styled, { css } from 'styled-components';

const enabledStyles = css`
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  border: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
`;

const disabledStyles = css`
  background-color: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  border: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  color: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
`;

const errorStyles = css`
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  border: 1px solid ${({ theme }) => theme.COLORS.ERROR};
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
`;

const getStateStylesFromProps = (state: Props) => {
  if (state.disabled) {
    return disabledStyles;
  }
  if (state.error) {
    return errorStyles;
  }
  return enabledStyles;
};
// TODO: use typography tokens instead of font-size + line-height
// https://voltbras.atlassian.net/wiki/spaces/~313404286/pages/977928193/Design+System#Typography

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Label = styled(Typography).attrs({
  as: 'label',
  color: 'PRIMARY_GRAY',
  weight: 'bold',
})`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

type Props = {
  disabled?: boolean;
  // https://github.com/styled-components/styled-components/issues/1198
  error: 1 | 0;
};

const inputStyles = css<Props>`
  height: 40px;
  border-radius: 8px;
  // TODO: use typography tokens
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
  padding: 0px 16px;
  &::placeholder {
    color: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  }
  &:focus {
    border: 2px solid ${({ theme }) => theme.COLORS.PRIMARY};
    outline: none !important;
  }
  ${(props) => getStateStylesFromProps(props)}
`;

export const StyledSelect = styled.select`
  ${inputStyles}
`;

export const ErrorText = styled(Typography).attrs({
  // TODO: change to typography token
  size: 12,
  color: 'ERROR',
  weight: 'bold',
})`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
`;

export const HelperText = styled(Typography).attrs({
  // TODO: change to typography token
  size: 12,
  color: 'TERTIARY_GRAY',
})`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
`;
