import Dropdown from 'new-components/Dropdown';
import styled, { css } from 'styled-components';

export const ActionButtonContainer = styled.div<{ active: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  }
  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
        `
      : ''}
`;

export const DropdownContainer = styled(Dropdown.Container)`
  max-width: 280px;
`;

/*
  A altura mínima aqui definida evita o redimensionamento da linha quando selecionada. Quando a seleção é realizada
  um icone indicador desta seleção é rederizado na linha causando um redimensionamento nela caso seja mais "baixa"
  do que o icone.
*/
export const DropdownRow = styled(Dropdown.Row)`
  min-height: 36px;
`;

export const AboutMemberAccess = styled.div`
  padding: 4px 16px;
  line-height: 0.5;
`;
export const AboutMemberInactivation = styled.div`
  padding: 4px 16px;
  line-height: 0.5;
`;
