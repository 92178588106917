import gql from 'graphql-tag';

export default gql`
  fragment PricingConfigFields on PricingConfig {
    __typename
    chargeFee
    enabled
    ... on PerTimePricingConfig {
      costPerMinute
    }
    ... on PerEnergyPricingConfig {
      costPerKwh
    }
  }
`;
