import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { BodyTypography, H3 } from 'new-components/Typographies/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthMutation } from 'hooks';
import toast from 'services/toast';
import {
  CreateGroupMutation,
  CreateGroupMutationVariables,
} from 'generated/graphql';
import createGroupMutation from 'graphql/mutations/createGroup';
import useHasScopes from 'hooks/useHasScope';
import AccessGroupsModal from 'atomic-components/organisms/modals/AccessGroups';
import { Box, Flex, FormControl, Input, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth/context';
import { SelectOrganizationDropdown } from 'atomic-components/molecules/SelectOrganizationDropdown';
import { Header, Body } from './styles';

type Props = {
  onClose: () => void;
  show: boolean;
  onGroupCreated: () => void;
};

const CreateAccessGroupModal: React.FC<Props> = ({
  onClose,
  show,
  onGroupCreated,
}) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState('');
  const [openGroupWhenCreated, setOpenGroupWhenCreated] = useState(false);
  const hasScopes = useHasScopes();
  const { organizations, selectedOrganizationIds } = useContext(AuthContext);
  const [selectedOrganization, setSelectedOrganization] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(undefined);

  const [createGroup, { data, loading }] = useAuthMutation<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >(createGroupMutation, {
    context: { headers: { 'org-id': selectedOrganization?.id ?? '' } },
  });

  const handleCreateGroupClick = async () => {
    if (!selectedOrganization) return;
    try {
      await createGroup({
        variables: {
          data: {
            name: groupName,
          },
          where: {
            id: selectedOrganization.id,
          },
        },
      });
      onGroupCreated();
      setOpenGroupWhenCreated(true);
      toast.success(t('create-access-group-modal.toast-successfully-message'));
      onClose();
    } catch (e) {
      console.error(e);
      toast.error(t('create-access-group-modal.toast-error-message'));
    }
  };

  const handleNewGroup = (value: string) => {
    setGroupName(value);
  };

  useEffect(() => {
    setOpenGroupWhenCreated(false);
  }, [selectedOrganization]);

  const showAccessGroupsModal =
    (openGroupWhenCreated &&
      !!selectedOrganization?.id &&
      selectedOrganizationIds?.includes(selectedOrganization.id)) ??
    false;

  const isEmsp = !!organizations?.find((o) => o.id === selectedOrganization?.id)
    ?.emsp;

  const isButtonDisabled =
    groupName.trim() === '' || !selectedOrganization || !isEmsp;

  return (
    <>
      <Modal
        size="md"
        show={show}
        onClose={onClose}
        centered
        overflow="visible"
      >
        <Modal.Header>
          <Header>
            <Icon color="PRIMARY" type="NEW_ORG" size={24} />
            <H3>{t('create-access-group-modal.header.title')}</H3>
          </Header>
          <Modal.Header.Attachments padding="24">
            <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
          </Modal.Header.Attachments>
        </Modal.Header>
        <Modal.Body>
          <Body>
            <BodyTypography>
              {t('create-access-group-modal.header.subtitle')}
            </BodyTypography>
            <BodyTypography>
              {t('create-access-group-modal.header.description')}
            </BodyTypography>
            <FormControl>
              <Flex flexFlow="column">
                <Box h={14} mb={8}>
                  <Flex mb={2}>
                    <Box w="full" mr={6}>
                      <Input
                        onChange={(e) => handleNewGroup(e.target.value)}
                        placeholder={t(
                          'create-access-group-modal.form.input.placeholder'
                        )}
                        value={groupName}
                      />
                    </Box>
                    <SelectOrganizationDropdown
                      value={
                        selectedOrganization?.name ??
                        t(
                          'create-access-group-modal.form.select-organization-dropdown.default-value'
                        )
                      }
                      setSelectedOrganization={setSelectedOrganization}
                      organizations={organizations ?? []}
                    />
                  </Flex>
                  {selectedOrganization && !isEmsp && (
                    <Text color="error.500" fontSize="xs">
                      {t(
                        'create-access-group-modal.form.organization-is-not-emsp-error-text',
                        {
                          orgName: selectedOrganization?.name,
                        }
                      )}
                    </Text>
                  )}
                </Box>
                <Flex alignSelf="flex-end">
                  <Button
                    onClick={handleCreateGroupClick}
                    isLoading={loading}
                    disabled={isButtonDisabled}
                  >
                    {t('create-access-group-modal.form.btn')}
                  </Button>
                </Flex>
              </Flex>
            </FormControl>
          </Body>
        </Modal.Body>
      </Modal>

      {hasScopes(['group:read']) && openGroupWhenCreated && (
        <AccessGroupsModal
          name={data?.createGroup.name ? data.createGroup.name : '-'}
          id={data?.createGroup.id}
          orgId={data?.createGroup.organizationId}
          show={showAccessGroupsModal}
          onClose={() => setOpenGroupWhenCreated(false)}
          updateListGroups={onGroupCreated}
        />
      )}
    </>
  );
};

export default CreateAccessGroupModal;
