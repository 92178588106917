import { VoucherFilterInput } from 'generated/graphql';
import { createContext } from 'react';

export interface ListVoucherStatesContextType {
  parsedVoucherTableFilters: Partial<VoucherFilterInput>;
  requestedVoucherName: string;
}

export const initialListVoucherStatesContextValues: ListVoucherStatesContextType =
  {
    parsedVoucherTableFilters: {},
    requestedVoucherName: '',
  };

export const ListVoucherStatesContext =
  createContext<ListVoucherStatesContextType>(
    initialListVoucherStatesContextValues
  );
