import styled from 'styled-components';

import errorComponent from 'new-components/ErrorComponent';

export const AlarmsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
`;

export const ErrorComponent = styled(errorComponent)`
  position: inherit;
`;
