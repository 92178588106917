import { ReactComponent as SearchIcon } from 'assets/svgs/icon_search.svg';
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon, CloseIconContainer, Container } from './styles';

type Props = {
  placeholder: string;
  setSearchValue: (searchValue: string) => void;
  searchValue: string;
};

const Search: React.FC<Props> = ({ placeholder, setSearchValue }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchValue(value);
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, setSearchValue]);

  return (
    <Container>
      <FormControl ml="4">
        <InputGroup>
          <InputLeftElement children={<SearchIcon />} pb="2" />
          <Input
            variant="filled"
            size="sm"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            focusBorderColor="gray.100"
            _placeholder={{
              fontSize: 'md',
            }}
          />
          <InputRightElement
            children={
              <CloseIconContainer
                onClick={() => {
                  setValue('');
                  setSearchValue('');
                }}
              >
                <CloseIcon />
              </CloseIconContainer>
            }
            pb="2"
          />
        </InputGroup>
      </FormControl>
    </Container>
  );
};

export default Search;
