import styled from 'styled-components';

export const SessionTableContainer = styled.div`
  max-height: 828px;
  margin-bottom: 47px;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const FilterButtonContainer = styled.div`
  margin-right: 36px;
  margin-left: 25px;
`;

export const OrderTotalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
