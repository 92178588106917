import {
  StationMonitoringPreviewQuery,
  StationMonitoringPreviewQueryVariables,
} from 'generated/graphql';
import stationMonitoringPreview from 'graphql/queries/stationMonitoringPreview';
import useHasScopes from 'hooks/useHasScope';
import { Icon } from 'new-components/Icon';
import LoadingComponent from 'new-components/LoadingComponent';
import { H5 } from 'new-components/Typographies/styles';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import { useTranslation } from 'react-i18next';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import { useContext } from 'react';
import { StationAlarms } from './StationAlarms';
import { StationConnection } from './StationConnection';
import { StationInformation } from './StationInformation';

import { Close, Column, Container, ErrorOrLoading } from './styles';

type StationPreviewProps = {
  stationId: string;
  onClose?: () => void;
  onOpenStationPage: () => void;
  onAlarmPress: () => void;
  /** Used by the google-react-map lib to define the position of the card  */
  lat: number;
  /** Used by the google-react-map lib to define the position of the card  */
  lng: number;
  /** Used to allow scrolling on the station preview alarms section  */
  setScrollWheelEnabled: (state: boolean) => void;
};

const StationPreview = ({
  stationId,
  onClose,
  onOpenStationPage,
  onAlarmPress,
  setScrollWheelEnabled,
}: StationPreviewProps) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const { data, loading } = useRealtimeQuery<
    StationMonitoringPreviewQuery,
    StationMonitoringPreviewQueryVariables
  >(stationMonitoringPreview, {
    variables: {
      where: { stationId },
      includeAlarms: hasScopes(['alarm:read']),
    },
    fetchPolicy: 'network-only',
  });

  const { orgNameMap } = useContext(AuthContext);

  const CloseButton = () => (
    <Close onClick={onClose}>
      <Icon type="NEW_CLOSE_X" color="PRIMARY_GRAY" size={20} />
    </Close>
  );

  const station = data?.station;

  const renderContent = () => {
    if (loading)
      return (
        <Container>
          <ErrorOrLoading>
            <LoadingComponent size={32} />
          </ErrorOrLoading>
          <CloseButton />
        </Container>
      );

    return (
      <Container>
        {station ? (
          <>
            <Box
              bg="gray.50"
              borderColor="gray.200"
              borderWidth="thin"
              h={6}
              lineHeight={6}
              borderTopRadius="md"
            >
              <Flex justify="center">
                <Text fontSize="xs" noOfLines={1}>
                  {formatOrgId(station.cpoId, orgNameMap)}
                </Text>
              </Flex>
            </Box>
            <StationInformation station={station} />
            <StationConnection
              routerConnectivityStatus={station.routerConnectivityStatus}
              stationConnectivityStatus={station.stationConnectivityStatus}
              lastConnectedAt={station.lastConnectedAt}
            />
            {hasScopes(['alarm:read']) &&
              station?.criticalAlarmsCount &&
              station?.criticalAlarmsCount.count && (
                <StationAlarms
                  stationId={stationId}
                  onAlarmPress={onAlarmPress}
                  setScrollWheelEnabled={setScrollWheelEnabled}
                />
              )}
            <Column>
              <Button
                onClick={onOpenStationPage}
                colorScheme="gray"
                variant="outline"
                fontWeight="normal"
                size="sm"
              >
                {t('station-preview.open-station-btn.title')}
              </Button>
            </Column>
          </>
        ) : (
          <ErrorOrLoading>
            <Icon type="NEW_CLOSE_X" color="SECONDARY_GRAY" size={64} />
            <H5 color="SECONDARY_GRAY">{t('gerenic-loading-error.message')}</H5>
          </ErrorOrLoading>
        )}
        <CloseButton />
      </Container>
    );
  };

  return <>{renderContent()}</>;
};

export default StationPreview;
