import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const SPChargeConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'sp-charge',
  LABEL: 'SPCharge',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#DBFF00',
    CLICKABLE: '#97AF04',
    ERROR: '#F87060',
    SIDEBAR: '#007BA5',
    LOGIN_LOGO_BACKGROUND: '#FAFBFD',

    RISE: '#00CC54',
    FALL: '#F87060',
    NEUTRAL: '#007BA5',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#00CC54',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#00CC54',
    CHARGING: '#00AAFF',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#A65C11',

    PREPARING: '#00AAFF',
    FINISHING: '#00AAFF',

    SELECTED_DATE_BOUND_BACKGROUND: '#025470',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#024157',

    DATE_PRE_SELECTED_BACKGROUND: '#007BA5',
    DATE_PRE_SELECTED_BORDER: '#02A0D6',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#01B3F0',
    DATE_PRE_SELECTED_COLOR: '#024157',

    DATE_SELECTED_BACKGROUND: '#007BA5',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#025470',

    CURRENT_DAY: '#007BA5',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#00CC54',
      Subsidized: '#00CC54',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#00CC54',
      CHARGING: '#00AAFF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#A65C11',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#00CC54',
      PREPARING: '#00AAFF',
      CHARGING: '#00AAFF',
      FINISHING: '#00AAFF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#A65C11',
    },

    BILLING: '#00CC54',
    RECHARGES: '#00AAFF',
    AVAILABILITY: '#A65C11',

    BUTTON_AVAILABLE: '#DBFF00',
    BUTTON_UNAVAILABLE: '#E5FE4D',
    BUTTON_LOADING: '#DDFD1C',
    BUTTON_TEXT_COLOR: '#007BA5',
    LINK: '#007BA5',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#025470',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#024157',

      DATE_PRE_SELECTED_BACKGROUND: '#007BA5',
      DATE_PRE_SELECTED_BORDER: '#02A0D6',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#01B3F0',
      DATE_PRE_SELECTED_COLOR: '#024157',

      DATE_SELECTED_BACKGROUND: '#007BA5',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#025470',

      CURRENT_DAY: '#007BA5',
    },
  },
  FONTS: {
    PRIMARY: 'Montserrat-Regular',
    SECONDARY: 'Montserrat-Bold',
    TERTIARY: 'Montserrat-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -21.20101328071702,
      lng: -47.813747969317134,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'SP Charge - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/sp-charge',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#E9FF66',
      '100': '#E5FE4D',
      '200': '#E1FD35',
      '300': '#DDFD1C',
      '400': '#D9FC03',
      '500': '#DBFF00',
      '600': '#ADC804',
      '700': '#97AF04',
      '800': '#819603',
      '900': '#6B7C03',
    },
    LINK: {
      '50': '#0DC1FE',
      '100': '#01B3F0',
      '200': '#02A0D6',
      '300': '#028DBD',
      '400': '#027AA3',
      '500': '#007BA5',
      '600': '#025470',
      '700': '#024157',
      '800': '#012E3E',
      '900': '#011B25',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#34FE87',
      '100': '#1BFE78',
      '200': '#02FD69',
      '300': '#02E35F',
      '400': '#03C954',
      '500': '#00CC54',
      '600': '#03963F',
      '700': '#037D35',
      '800': '#02642A',
      '900': '#024B20',
    },
    CHARGING: {
      '50': '#66CCFF',
      '100': '#4DC3FE',
      '200': '#35BAFD',
      '300': '#1CB2FD',
      '400': '#03A9FC',
      '500': '#00AAFF',
      '600': '#0487C8',
      '700': '#0476AF',
      '800': '#036596',
      '900': '#03547C',
    },
    AVAILABLE: {
      '50': '#34FE87',
      '100': '#1BFE78',
      '200': '#02FD69',
      '300': '#02E35F',
      '400': '#03C954',
      '500': '#00CC54',
      '600': '#03963F',
      '700': '#037D35',
      '800': '#02642A',
      '900': '#024B20',
    },
    SECONDARY: {
      '50': '#0DC1FE',
      '100': '#01B3F0',
      '200': '#02A0D6',
      '300': '#028DBD',
      '400': '#027AA3',
      '500': '#007BA5',
      '600': '#025470',
      '700': '#024157',
      '800': '#012E3E',
      '900': '#011B25',
    },
    RESERVE: {
      '50': '#EC8F31',
      '100': '#E9821B',
      '200': '#D57615',
      '300': '#BD6914',
      '400': '#A55C12',
      '500': '#A65C11',
      '600': '#77420D',
      '700': '#5F360B',
      '800': '#482909',
      '900': '#311C06',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default SPChargeConfig;
