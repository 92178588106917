import { Text, Input, InputLeftAddon, InputGroup } from '@chakra-ui/react';
import {
  PricingMethod,
  UpdateConnectorPricingConfigurationMutation,
  UpdateConnectorPricingConfigurationMutationVariables,
} from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import { useState } from 'react';
import DialogModal from 'atomic-components/organisms/DialogModal';
import NumberFormat from 'react-number-format';
import { moneyMask } from 'pages/Payments/PriceControl/functions';
import config from 'config';
import updateConnectorPricingConfigurationMutation from 'graphql/mutations/updateConnectorPricingConfiguration';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type Props = {
  station: {
    id: string;
    name: string;
    minimumChargeFee: number;
  };
  connectors: {
    id: number;
  }[];
  organizationId: string;
  refetchTableData: () => void;
  onClose: () => void;
  show: boolean;
  openFirstModalAgain: () => void;
  clearSelectedStationState: () => void;
};

const DEFAULT_METHOD = 'PER_ENERGY' as PricingMethod;
const DEFAULT_ENABLED = false;

const ConfigureConnectorPricingConfigLastStepModal = ({
  onClose,
  show,
  connectors,
  organizationId,
  station,
  refetchTableData,
  openFirstModalAgain,
  clearSelectedStationState,
}: Props) => {
  const { t } = useTranslation();
  const [stationConnectorsCost, setStationConnectorsCost] = useState(0);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [updateConnectorPricingConfiguration, { loading }] = useAuthMutation<
    UpdateConnectorPricingConfigurationMutation,
    UpdateConnectorPricingConfigurationMutationVariables
  >(updateConnectorPricingConfigurationMutation);

  const resetStates = () => {
    clearSelectedStationState();
    setStationConnectorsCost(0);
  };

  const handleCreatePricingConfigDialogButtonClick = async () => {
    const data = connectors.map((c) => ({
      connectorId: c.id,
      cost: stationConnectorsCost,
      method: DEFAULT_METHOD,
      orgId: organizationId,
      stationId: station.id,
      chargeFee: station.minimumChargeFee,
      enabled: DEFAULT_ENABLED,
    }));
    try {
      await updateConnectorPricingConfiguration({
        variables: {
          data,
        },
      });
      toast.success(
        t(
          'configure-connector-pricing-config.last-step-modal.toast-successfully-message',
          {
            stationName: station.name,
          }
        )
      );
      resetStates();
      refetchTableData();
      onClose();
    } catch {
      toast.error(
        t(
          'configure-connector-pricing-config.last-step-modal.toast-error-message'
        )
      );
    }
  };

  const handleCloseModal = () => {
    onClose();
    openFirstModalAgain();
  };

  return (
    <DialogModal
      header={{
        title: t('configure-connector-pricing-config.last-step-modal.title'),
      }}
      subtitle={t(
        'configure-connector-pricing-config.last-step-modal.subtitle',
        {
          stationName: station.name,
        }
      )}
      onClose={() => onClose()}
      visible={show}
      submitButton={{
        colorScheme: 'primary',
        onPress: () => handleCreatePricingConfigDialogButtonClick(),
        text: t('configure-connector-pricing-config.last-step-modal.btn'),
        loading,
      }}
      size="md"
      cancelButton={{
        text: t('dialog-modal.modal.body.go-back-btn'),
        onClick: () => handleCloseModal(),
      }}
    >
      <InputGroup>
        <InputLeftAddon
          children="R$"
          fontSize="sm"
          bg="white"
          color="gray.900"
          borderColor={isInputFocused ? 'primary.500' : 'gray.100'}
          style={{
            boxShadow: isInputFocused
              ? `0 0 0 1px ${config.COLORS.PRIMARY}`
              : 'none',
          }}
          transition="all 0.2s cubic-bezier(.08,.52,.52,1)" // same input transition
        />
        <NumberFormat
          maxLength={6}
          width={60}
          format={moneyMask}
          fontWeight="bold"
          fontFamily="mono"
          value={stationConnectorsCost}
          onValueChange={(value) =>
            setStationConnectorsCost(value.floatValue ?? 0)
          }
          borderLeftRadius="none"
          customInput={Input}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
      </InputGroup>
      <Text mt={4} fontSize="sm">
        {t('configure-connector-pricing-config.last-step-modal.footer-text')}
      </Text>
    </DialogModal>
  );
};

export default ConfigureConnectorPricingConfigLastStepModal;
