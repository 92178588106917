import { Box, Flex, Heading } from '@chakra-ui/react';
import {
  DeleteConsumerRfidMutation,
  DeleteConsumerRfidMutationVariables,
  RfidStatus,
} from 'generated/graphql';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import React, { useState } from 'react';
import ConfirmRFIDBlockingModal from 'atomic-components/organisms/modals/ConfirmRFIDBlockingModal';
import ConfirmRFIDUnlockingModal from 'atomic-components/organisms/modals/ConfirmRFIDUnlockingModal';
import { useAuthMutation } from 'hooks';
import DeleteConsumerRFID from 'graphql/mutations/deleteConsumerRFID';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type Props = {
  status: RfidStatus;
  refetchInfo?: () => void;
  decimalId: string;
  isBeingUsedInsideModal?: boolean;
};

function RFIDDropdown({
  status,
  decimalId,
  refetchInfo,
  isBeingUsedInsideModal,
}: Props) {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [confirmRFIDBlockingModalVisible, setConfirmRFIDBlockingModalVisible] =
    useState(false);
  const [
    confirmRFIDUnlockingModalVisible,
    setConfirmRFIDUnlockingModalVisible,
  ] = useState(false);

  const rfidIsBlocked =
    status === 'BlockedByMember' || status === 'PreRegisteredAndBlocked';
  const rfidCanBeDeleted =
    status === 'PreRegistered' || status === 'PreRegisteredAndBlocked';

  const [deleteConsumerRFID, { loading }] = useAuthMutation<
    DeleteConsumerRfidMutation,
    DeleteConsumerRfidMutationVariables
  >(DeleteConsumerRFID, {
    variables: {
      where: {
        decimalId,
      },
    },
  });

  const handleDeleteConsumerRFID = async () => {
    try {
      await deleteConsumerRFID();
      if (refetchInfo) refetchInfo();
      toast.success(t('rfid.rfid-dropdown.toast-successful-delete.message'));
    } catch (e) {
      toast.error(t('rfid.rfid-dropdown.toast-delete-error.message'));
    }
  };

  return (
    <>
      <Dropdown
        isOpen={isDropdownOpen}
        toggleDropdown={setIsDropdownOpen}
        onClickOutside={() => setIsDropdownOpen(false)}
        isBeingUsedInsideModal={isBeingUsedInsideModal}
        content={() => (
          <Dropdown.Container>
            <Dropdown.Section>
              <Dropdown.Row
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteConsumerRFID();
                }}
                clickable={rfidCanBeDeleted}
              >
                <Box minWidth="200px">
                  <Flex
                    align="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Heading
                      fontSize="sm"
                      color={rfidCanBeDeleted ? 'gray.900' : 'gray.100'}
                    >
                      {t('rfid.rfid-dropdown.delete-rfid.title')}
                    </Heading>
                    {loading ? (
                      <Dropdown.RowLoading />
                    ) : (
                      <Icon
                        type="NEW_TRASH"
                        color={
                          rfidCanBeDeleted ? 'PRIMARY_GRAY' : 'TERTIARY_GRAY'
                        }
                        size={20}
                      />
                    )}
                  </Flex>
                </Box>
              </Dropdown.Row>
            </Dropdown.Section>
            <Dropdown.Section>
              {rfidIsBlocked ? (
                <Dropdown.Row
                  onClick={(event) => {
                    event.stopPropagation();
                    setConfirmRFIDUnlockingModalVisible(true);
                  }}
                >
                  <Box minWidth="200px">
                    <Flex
                      align="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Heading fontSize="sm">
                        {t('rfid.rfid-dropdown.unlock-rfid.title')}
                      </Heading>
                      <Icon
                        type="NEW_ACTIVATE"
                        color="PRIMARY_GRAY"
                        size={20}
                      />
                    </Flex>
                  </Box>
                </Dropdown.Row>
              ) : (
                <Dropdown.Row
                  onClick={(event) => {
                    event.stopPropagation();
                    setConfirmRFIDBlockingModalVisible(true);
                  }}
                >
                  <Box minWidth="200px">
                    <Flex
                      align="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Heading fontSize="sm" color="error.500">
                        {t('rfid.rfid-dropdown.lock-rfid.title')}
                      </Heading>
                      <Icon type="NEW_REMOVE_CIRCLE" color="ERROR" size={20} />
                    </Flex>
                  </Box>
                </Dropdown.Row>
              )}
            </Dropdown.Section>
          </Dropdown.Container>
        )}
      >
        <Dropdown.TriggerButtonContainer
          active={isDropdownOpen}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <Icon
            type="NEW_THREE_HORIZONTAL_DOTS"
            color="PRIMARY_GRAY"
            size={24}
          />
        </Dropdown.TriggerButtonContainer>
      </Dropdown>
      <ConfirmRFIDBlockingModal
        onClose={() => setConfirmRFIDBlockingModalVisible(false)}
        isOpen={confirmRFIDBlockingModalVisible}
        refetchInfo={refetchInfo}
        decimalId={decimalId}
      />
      <ConfirmRFIDUnlockingModal
        onClose={() => setConfirmRFIDUnlockingModalVisible(false)}
        isOpen={confirmRFIDUnlockingModalVisible}
        refetchInfo={refetchInfo}
        decimalId={decimalId}
      />
    </>
  );
}

export { RFIDDropdown };
