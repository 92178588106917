import { Scope } from '@voltbras/auth-directives';
import { hasScope } from '@voltbras/auth-directives/dist/directives/hasScope';
import AuthContext from 'contexts/Auth/context';
import { useContext } from 'react';

export default function useHasScopes(): (requiredScope: Scope[]) => boolean {
  const { member } = useContext(AuthContext);
  const memberScopes = (member?.scopes || []) as Scope[];

  return (requiredScope: Scope[]): boolean =>
    requiredScope.length ===
    requiredScope.filter((scope) => hasScope(memberScopes, scope)).length;
}
