import styled, { css } from 'styled-components';

export const SelectorLinkContainer = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border-bottom: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-left: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-right: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-top: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
  font: ${({ theme: { FONTS } }) => FONTS.SECONDARY};
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 6px 16px;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  }
  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.COLORS.TERTIARY_GRAY};
      border: 1px solid ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
      box-shadow: inset 0px 2px 0px rgba(23, 26, 28, 0.08);
      color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  & ${SelectorLinkContainer}:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  & ${SelectorLinkContainer}:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
