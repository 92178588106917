import { Typography } from 'components';
import Table, { Column } from 'components/Table';
import {
  ActivateMemberMutation,
  ActivateMemberMutationVariables,
  ConfigurationsPageQuery,
  InactivateMemberMutation,
  InactivateMemberMutationVariables,
  Member,
  MemberRole,
  Organization,
} from 'generated/graphql';
import activateMember from 'graphql/mutations/activateMember';
import inactivateMember from 'graphql/mutations/inactivateMember';
import { useAuthMutation } from 'hooks';
import NewCard from 'new-components/NewCard';
import React from 'react';
import toast from 'services/toast';
import { IconButton, Button } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';
import { FormValues } from './MemberForm';
import {
  PaddedContainer,
  RightAlignedButtonRow,
  SpaceBetweenButtons,
  TableCardWrapper,
} from './styles';

type Account = ConfigurationsPageQuery['allMembers'][number];

type Props = {
  members: Account[];
  refetchMembers: () => void;
  setSelectedAccounToEdit: (values: FormValues | undefined) => void;
};

/* FIXME: com a mudança na estrutura do serviço de usuário esta página passou a ser bastante estranha */
const MembersTable: React.FC<Props> = ({
  members,
  refetchMembers,
  setSelectedAccounToEdit,
}) => {
  const { t } = useTranslation();
  const [dispatchActivateMemberMutation] = useAuthMutation<
    ActivateMemberMutation,
    ActivateMemberMutationVariables
  >(activateMember);
  const [dispatchInactivateMemberMutation] = useAuthMutation<
    InactivateMemberMutation,
    InactivateMemberMutationVariables
  >(inactivateMember);

  const activateOrInactivateMember = async (
    isActive: boolean,
    memberId: Member['id'],
    organizationId: Organization['id']
  ) => {
    const reason = prompt(
      t('configurations.members-table.form.reason', {
        action: isActive
          ? t('configurations.members-table.inactivation.title')
          : t('configurations.members-table.activation.title'),
      })
    );
    if (!reason) return;

    const mutation = isActive
      ? dispatchInactivateMemberMutation
      : dispatchActivateMemberMutation;

    const { errors } = await mutation({
      variables: {
        where: { memberId, organizationId },
        reason,
      },
    });

    if (errors) {
      toast.error(
        t('configurations.members-table.form.error', {
          action: isActive
            ? t('configurations.members-table.inactivate.title').toLowerCase()
            : t('configurations.members-table.activate.title').toLowerCase(),
        })
      );
    } else {
      toast.success(
        t('configurations.members-table.successfully.message', {
          newMemberStatus: isActive
            ? t('configurations.members-table.inactivated.title')
            : t('configurations.members-table.activated.title'),
        })
      );
      refetchMembers();
    }
  };

  const selectAccountToEdit = (account: Account): void => {
    setSelectedAccounToEdit({
      memberId: account.id,
      firstName: account.profile?.firstName ?? '',
      lastName: account.profile?.lastName ?? '',
      email: account.profile?.email ?? '',
      organizationId: account.organizationId,
      role: account.role as MemberRole,
    });
  };

  const columns: Column<Account>[] = [
    {
      Header: t('configurations.members-table.name.column.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { profile },
        },
      }) => (
        <Typography>{`${profile?.firstName} ${profile?.lastName}`}</Typography>
      ),
    },
    {
      Header: t('configurations.members-table.email.column.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            member,
          },
        },
      }) => <Typography>{member?.profile?.email || '-'}</Typography>,
    },
    {
      Header: t('configurations.members-table.company.column.title') as string,
      accessor: 'organizationId',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { organizationId },
        },
      }) => <Typography>{organizationId}</Typography>,
    },
    {
      Header: t('configurations.members-table.role.column.title'),
      accessor: 'role',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { role },
        },
      }) => <Typography>{role}</Typography>,
    },
    {
      Header: t('configurations.members-table.status.column.title'),
      accessor: 'active',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { active },
        },
      }) => (
        <Typography>
          {t('configurations.members-table.status.value', {
            memberStatus: active
              ? t('configurations.members-table.active-member.title')
              : t('configurations.members-table.inactive-member.title'),
          })}
        </Typography>
      ),
    },
    {
      Header: t('configurations.members-table.action.column.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original,
        },
      }) => (
        <RightAlignedButtonRow>
          <Button
            colorScheme="gray"
            variant="outline"
            fontWeight="light"
            size="sm"
            onClick={() =>
              activateOrInactivateMember(
                original.active,
                original.id,
                original.organizationId
              )
            }
          >
            {t('configurations.members-table.active-or-inactivate-btn.title', {
              action: original.active
                ? t('configurations.members-table.inactivate.title')
                : t('configurations.members-table.activate.title'),
            })}
          </Button>
          <SpaceBetweenButtons />
          <IconButton
            aria-label={t(
              'configurations.members-table.edit-member.aria-label'
            )}
            colorScheme="gray"
            variant="outline"
            size="sm"
            icon={<Icon type="NEW_EDIT" color="PRIMARY_GRAY" size={20} />}
            onClick={() => selectAccountToEdit(original)}
          />
        </RightAlignedButtonRow>
      ),
    },
  ];

  return (
    <TableCardWrapper>
      <NewCard>
        <PaddedContainer>
          <Table<Account> data={members} columns={columns} />
        </PaddedContainer>
      </NewCard>
    </TableCardWrapper>
  );
};

export default MembersTable;
