import { gql } from '@apollo/client';

export default gql`
  mutation ConfigureStationVisibility(
    $where: SelectStationInput!
    $visibility: StationVisibility!
  ) {
    configureStationVisibility(where: $where, visibility: $visibility)
  }
`;
