/* NÃO SEI SE ESTE É UM BOM NOME PARA O COMPONENTE, KKK */

import React from 'react';
import { InjectButtonProps, InjectChildrenProps } from '.';
import { TriggerButtonContainer } from './styles';

type TriggerButtonProps = {
  active?: boolean;
  isDisabled?: boolean;
} & InjectChildrenProps &
  InjectButtonProps &
  Partial<React.ForwardedRef<HTMLButtonElement>>;

/** Este é um container para o botão que responsável por abrir e fechar o dropdown. Por padrão este container
 * já possui efeito de hover e ativo/inativo. Quando ativo assume a aparecencia igual a do efeito de hover enquanto
 * ```active = true```.
 */
const DropdownTriggerButtonContainer = React.forwardRef<
  HTMLButtonElement,
  TriggerButtonProps
>(({ active = false, onClick, children, ...props }, ref) => (
  <TriggerButtonContainer
    active={active}
    onClick={props.isDisabled ? () => {} : onClick}
    ref={ref}
    isDisabled={props.isDisabled}
    {...props}
  >
    {children}
  </TriggerButtonContainer>
));

export { DropdownTriggerButtonContainer };
