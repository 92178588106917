import env from '@beam-australia/react-env';
import { Typography } from 'components';
import Page from 'components/Page';
import {
  ResetStationMutation,
  ResetStationMutationVariables,
  RouterConnectivityStatus,
  StationConnectivityStatus,
  StationOwnerControlDetailsQuery,
  StationOwnerControlDetailsQueryVariables,
} from 'generated/graphql';
import resetStationMutation from 'graphql/mutations/resetStation';
import stationOwnerControlDetails from 'graphql/queries/stationOwnerControlDetails';
import { useAuthMutation } from 'hooks';
import sendEvent from 'hooks/sendEvent';
import useHasScopes from 'hooks/useHasScope';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import moment from 'moment';
import LoadingComponent from 'new-components/LoadingComponent';
import NewPageHeader from 'new-components/NewPageHeader';
import Tag from 'new-components/Tag';
import React, { useContext, useReducer } from 'react';
import toast from 'services/toast';
import getCookieFromKey from 'services/getCookieFromKey';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StationContext } from '../StationContext';
import ConnectorsTable from './ConnectorsTable';
import StationEventsTable from './StationEventsTable';
import {
  Card,
  Container,
  FlexibleColumn,
  LoadingContainer,
  Row,
  StationControlInfo,
  StationControlInfoHeader,
  TableContainerIdentifier,
  TableTitle,
  TitleAndTag,
  TypographyWithPadding,
  TypographyWithPaddingRight,
} from './styles';

type ResetTypes = 'Hard' | 'Soft';

const DEFAULT_URL_SUFFIX = 'localhost:4000';

const StationControl: React.FC = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [resetStation] = useAuthMutation<
    ResetStationMutation,
    ResetStationMutationVariables
  >(resetStationMutation);
  const { id: stationId } = useContext(StationContext);

  type LoadingResetType = { hard: boolean; soft: boolean };
  type LoadingResetAction = { type: ResetTypes; to: boolean };
  const loadingResetDefaultValue = { hard: false, soft: false };
  const [loadingReset, setLoadingReset] = useReducer(
    (state: LoadingResetType, action: LoadingResetAction): LoadingResetType => {
      const { type, to: loadingBoolean } = action;
      switch (type) {
        case 'Hard':
          return { ...loadingResetDefaultValue, hard: loadingBoolean };
        case 'Soft':
          return { ...loadingResetDefaultValue, soft: loadingBoolean };
      }
    },
    loadingResetDefaultValue
  );

  const { data, loading } = useRealtimeQuery<
    StationOwnerControlDetailsQuery,
    StationOwnerControlDetailsQueryVariables
  >(stationOwnerControlDetails, {
    variables: { where: { stationId } },
    skip: !stationId || !hasScopes(['station:control']),
  });

  const stationName = data?.station?.name ?? '';

  const {
    chargePointId,
    address,
    ocppVersion,
    name,
    stationConnectivityStatus = 'OFFLINE',
    routerConnectivityStatus,
    errorCode,
    lastConnectedAt,
    model,
    cpoId,
  } = data?.station ?? {};

  const {
    vendor: stationModelVendor = '-',
    modelCode: stationModelCode = '-',
  } = model ?? {};

  const lastConnectionFromNow = moment(lastConnectedAt).fromNow();

  const connectivityStatusToLabel = (
    status?: StationConnectivityStatus | RouterConnectivityStatus
  ) => {
    switch (status) {
      case 'ONLINE':
        return t('station-page.control-tab.online-status-label');
      case 'OFFLINE':
        return t('station-page.control-tab.offline-status-label');
      case 'NOT_CONFIGURED':
        return t('station-page.control-tab.not-configured-status-label');
      default:
        return '-';
    }
  };

  const alertOnError = async (
    command: Promise<{ data?: any; errors?: any }>,
    type: ResetTypes
  ) => {
    try {
      setLoadingReset({ type, to: true });
      const { data, errors } = await command;
      if (errors) {
        toast.error(
          t('station-page.control-tab.reset-error.message', {
            resetType: `${type} Reset`,
            stationName,
          })
        );
      } else if (data) {
        sendEvent({
          category: 'Station',
          action: `${type} Reset Successful`,
          label: `${stationId}`,
        });
        toast.success(
          <>
            {t('station-page.control-tab.reset-successfully.message', {
              resetType: `${type} Reset`,
              stationName,
            })}
          </>
        );
      }
    } catch (error) {
      toast.error(
        t('station-page.control-tab.reset-error.message', {
          resetType: `${type} Reset`,
          stationName,
        })
      );
    }
    setLoadingReset({ type, to: false });
  };

  const executeMutation = (type: ResetTypes) => {
    sendEvent({
      category: 'Station',
      action: `Click ${type} Reset`,
      label: `${stationId}`,
    });
    if (
      window.confirm(
        t('station-page.control-tab.confirm-station-reset', {
          type,
          stationName,
        })
      )
    )
      alertOnError(
        resetStation({ variables: { where: { stationId }, type } }),
        type
      );
  };

  return (
    <Page>
      <Container>
        <NewPageHeader>
          <Button
            variant="outline"
            colorScheme="gray"
            size="sm"
            fontWeight="normal"
            bg="white"
            onClick={() => {
              const authToken = getCookieFromKey('authToken', document);
              window.open(
                `https://${stationId}.config.${
                  env('URL_SUFFIX') ?? DEFAULT_URL_SUFFIX
                }/?authToken=${authToken}&orgId=${cpoId}`
              );
            }}
          >
            {t('station-page.control-tab.header.access-plataform-btn.title')}
          </Button>
          <Button
            variant="outline"
            colorScheme="gray"
            bg="white"
            fontWeight="normal"
            size="sm"
            onClick={() => {
              executeMutation('Soft');
            }}
            isLoading={loadingReset.soft}
          >
            {t('station-page.control-tab.header.soft-reset-btn.title')}
          </Button>
          <Button
            variant="outline"
            colorScheme="gray"
            bg="white"
            fontWeight="normal"
            size="sm"
            onClick={() => {
              executeMutation('Hard');
            }}
            isLoading={loadingReset.hard}
          >
            {t('station-page.control-tab.header.hard-reset-btn.title')}
          </Button>
        </NewPageHeader>
        <StationControlInfo>
          <Card>
            {loading ? (
              <LoadingContainer>
                <LoadingComponent size={50} />
              </LoadingContainer>
            ) : (
              <>
                <StationControlInfoHeader>
                  <TitleAndTag>
                    <Typography size={20} weight="bold">
                      {name}
                    </Typography>
                    <Tag type={stationConnectivityStatus} />
                    <Typography size={12} color="SECONDARY_GRAY">
                      {lastConnectionFromNow}
                    </Typography>
                  </TitleAndTag>
                </StationControlInfoHeader>
                <Row>
                  <FlexibleColumn>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t('station-page.control-tab.card.row.location.title')}
                      </TypographyWithPadding>
                    </Row>
                    <Row>
                      <TypographyWithPaddingRight lineHeight={24} size={16}>
                        {address?.street}, {address?.streetNumber}
                      </TypographyWithPaddingRight>
                    </Row>
                    <Row>
                      <TypographyWithPaddingRight lineHeight={24} size={16}>
                        {address?.neighborhood}, {address?.city} -{' '}
                        {address?.state}
                      </TypographyWithPaddingRight>
                    </Row>
                  </FlexibleColumn>
                  <FlexibleColumn>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t(
                          'station-page.control-tab.card.row.station-id/ocpp.title'
                        )}
                      </TypographyWithPadding>
                      <TypographyWithPadding size={16}>
                        {chargePointId}
                      </TypographyWithPadding>
                    </Row>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t(
                          'station-page.control-tab.card.row.ocpp-version.title'
                        )}
                      </TypographyWithPadding>
                      <TypographyWithPadding size={16}>
                        {ocppVersion}
                      </TypographyWithPadding>
                    </Row>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t(
                          'station-page.control-tab.card.row.manufacturer.title'
                        )}
                      </TypographyWithPadding>
                      <TypographyWithPadding size={16}>
                        {stationModelVendor}
                      </TypographyWithPadding>
                    </Row>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t('station-page.control-tab.card.row.model.title')}
                      </TypographyWithPadding>
                      <TypographyWithPadding size={16}>
                        {stationModelCode}
                      </TypographyWithPadding>
                    </Row>
                  </FlexibleColumn>
                  <FlexibleColumn>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t(
                          'station-page.control-tab.card.row.station-signal.title'
                        )}
                      </TypographyWithPadding>
                      <Typography size={16}>
                        {connectivityStatusToLabel(stationConnectivityStatus)}
                      </Typography>
                    </Row>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t(
                          'station-page.control-tab.card.row.modem-signal.title'
                        )}
                      </TypographyWithPadding>
                      <Typography size={16}>
                        {connectivityStatusToLabel(routerConnectivityStatus)}
                      </Typography>
                    </Row>
                    <Row>
                      <TypographyWithPadding color="SECONDARY_GRAY" size={14}>
                        {t(
                          'station-page.control-tab.card.row.error-code.title'
                        )}
                      </TypographyWithPadding>
                      <Typography size={16}>{errorCode ?? '-'}</Typography>
                    </Row>
                  </FlexibleColumn>
                </Row>
              </>
            )}
          </Card>
        </StationControlInfo>

        <TableTitle>
          {t('station-page.control-tab.connectors-table.title')}
        </TableTitle>
        <Card>
          <TableContainerIdentifier>
            {data?.station && (
              <ConnectorsTable station={data?.station} stationId={stationId} />
            )}
          </TableContainerIdentifier>
        </Card>

        <TableTitle>
          {t('station-page.control-tab.events-table.title')}
        </TableTitle>
        <Card>
          <StationEventsTable stationId={stationId} />
        </Card>
      </Container>
    </Page>
  );
};

export default StationControl;
