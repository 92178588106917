import { useState } from 'react';
import { StationMonitoringStatus } from 'pages/Monitoring/types';
import MonitoringContext from './context';

const MonitoringProvider: React.FC = ({ children }) => {
  const monitoringStatusSelected = window.localStorage.getItem(
    'MONITORING_STATUS_SELECTED'
  );
  const defaultStatusSelected: StationMonitoringStatus[] | undefined =
    monitoringStatusSelected && JSON.parse(monitoringStatusSelected);

  const [preStatusSelected, setPreStatusSelected] = useState<
    StationMonitoringStatus[] | undefined
  >(defaultStatusSelected);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  return (
    <MonitoringContext.Provider
      value={{
        setPreStatusSelected,
        preStatusSelected,
        buttonDisabled,
        setButtonDisabled,
      }}
    >
      {children}
    </MonitoringContext.Provider>
  );
};

export default MonitoringProvider;
