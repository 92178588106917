import {
  ConnectorType,
  PricingMethod,
  TableConnectorsPriceQuery,
} from 'generated/graphql';
import { t } from 'i18next';
import { formatNumber } from 'services/format';
import { CurrencyType } from 'types';
import {
  PricingMethod as PricingConfigMethod,
  PricingConfig,
} from 'types/station';

export type ConnectorConfig = {
  chargeFee: string;
  connectorId: number;
  cost: string;
  stationId: string;
  method: PricingMethod;
  stationName: string;
  cpoId?: string;
  type: ConnectorType;
  power: number;
  orgId: string;
  minimumChargeFee: number;
  connectorEnabled?: boolean;
  hasPricingConfig: boolean;
  emspId?: string;
  currencyType?: CurrencyType;
};

type ConnectorPricingConfig = NonNullable<
  NonNullable<
    TableConnectorsPriceQuery['stationsProfile'][number]
  >['connectors'][number]['pricingConfig']
>[number];

type Station = TableConnectorsPriceQuery['stationsProfile'][number];

export const DEFAULT_STATION_CONNECTOR_PRICING_CONFIG = {
  method: 'PER_ENERGY' as PricingMethod,
  connectorEnabled: false,
  hasPricingConfig: false,
  minimumChargeFee: 0,
};

export const getBillingMethodLabel = (pricingMethod: PricingMethod) => {
  if (pricingMethod === 'PER_ENERGY') return '/kWh';
  if (pricingMethod === 'PER_TIME')
    return `/${t('payments.price-control-tab.minutes-abbreviation-text')}`;
  return '-';
};

export const parseFormattedNumber = (value: string) =>
  Number(value.replace(/\./g, '').replace(',', ''));

export const formatPricingConfigMethod = (
  pricingMethod?: PricingConfigMethod
): PricingMethod => {
  if (pricingMethod === 'PerTimePricingConfig') return 'PER_TIME';
  return 'PER_ENERGY';
};

export const moneyMask = (value: string) => {
  const number = Number(value) || 0;
  return formatNumber(number / 100);
};

export const PRICING_METHODS: Array<PricingMethod> = ['PER_TIME', 'PER_ENERGY'];

export const allChargeFeesAbleToBeUpdated = (
  connectorsConfig: ConnectorConfig[]
) => {
  const someNewChargeFeeValueIsInvalid = connectorsConfig.filter(
    (connectorConfig) =>
      parseFormattedNumber(connectorConfig?.chargeFee) <
        connectorConfig?.minimumChargeFee &&
      (connectorConfig.hasPricingConfig || connectorConfig.connectorEnabled)
  ).length;

  return someNewChargeFeeValueIsInvalid;
};
export const getConnectorCost = (config?: PricingConfig) => {
  switch (config?.__typename) {
    case 'PerTimePricingConfig':
      return config.costPerMinute;
    case 'PerEnergyPricingConfig':
      return config.costPerKwh;
  }
};

export const getConnectorsConfigChanged = (
  connectorsConfig: ConnectorConfig[],
  currentConnectorsConfig: ConnectorConfig[]
) => {
  const connectorsConfigChanged = currentConnectorsConfig.filter(
    (connectorConfig, index) => {
      if (
        JSON.stringify(connectorConfig) ===
        JSON.stringify(connectorsConfig[index])
      )
        return false;
      return true;
    }
  );

  const formattedConnectorsConfigChanged = connectorsConfigChanged.flatMap(
    (data) => ({
      stationId: data.stationId,
      method: data.method,
      cost: parseFormattedNumber(data.cost),
      chargeFee: parseFormattedNumber(data.chargeFee),
      connectorId: data.connectorId,
      enabled: data.connectorEnabled,
      orgId: data.orgId,
    })
  );

  return formattedConnectorsConfigChanged;
};

export const getErrorMessage = (number: number) => {
  const moneyFormatted = formatNumber(number / 100);
  const label = t('payments.price-control-tab.invalid-charge-fee.message', {
    chargeFee: moneyFormatted,
  });
  return label;
};

export const getInitialConnectorChargeFee = (
  connectorPricingConfig: ConnectorPricingConfig,
  minimumStationChargeFee: number
) => {
  const connectorHasPrincingConfig = !!connectorPricingConfig;

  if (connectorHasPrincingConfig)
    return formatNumber(connectorPricingConfig?.chargeFee);
  return formatNumber(minimumStationChargeFee / 100);
};

export const structureTableData = (
  station: Station
): ConnectorConfig[] | undefined =>
  station.connectors.flatMap((connector) => {
    if (connector.pricingConfig?.length) {
      return connector.pricingConfig.map((pricingConfig) => ({
        stationId: station.id,
        stationName: station.name,
        cpoId: station.cpo.roamingInfo?.roamingId,
        connectorId: connector.id,
        type: connector.type,
        power: connector.power,
        orgId: pricingConfig?.emspId ?? '-',
        currencyType: station?.emsps?.find(
          (emsp) => emsp?.id === pricingConfig?.emspId
        )?.sessionPaymentConfig?.currencyType,
        chargeFee: getInitialConnectorChargeFee(
          pricingConfig,
          station.minimumChargeFee?.find(
            (m) => m?.emspId === pricingConfig?.emspId
          )?.chargeFee ?? 0
        ),
        cost: formatNumber(getConnectorCost(pricingConfig)) ?? 0,
        method: formatPricingConfigMethod(
          pricingConfig?.__typename ?? 'PerEnergyPricingConfig'
        ),
        minimumChargeFee:
          station.minimumChargeFee?.find(
            (m) => m?.emspId === pricingConfig?.emspId
          )?.chargeFee ?? 0,
        connectorEnabled: pricingConfig?.enabled,
        hasPricingConfig: !!pricingConfig,
        emspId: station.emsps.find((emsp) => emsp?.id === pricingConfig?.emspId)
          ?.roamingInfo?.roamingId,
      }));
    }
    return [];
  });
