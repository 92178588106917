import { gql } from '@apollo/client';

export default gql`
  mutation sendDriverPasswordRedefinitionEmail(
    $email: String!
    $orgId: String!
  ) {
    sendDriverPasswordRedefinitionEmail(email: $email, orgId: $orgId)
  }
`;
