import styled from 'styled-components';
import Typography from 'components/Typography';
import { ConnectorStatus } from 'generated/graphql';

export const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  grid-area: footer;
`;

export const ConnectorsInfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const ConnectorsInfoLabel = styled(Typography).attrs({ size: 12 })``;

export const Status = styled.div<{ status?: ConnectorStatus }>`
  background-color: ${({ theme, status }) =>
    status
      ? theme.COLORS.CONNECTOR_STATUS[status]
      : theme.COLORS.TERTIARY_GRAY};
  border-radius: 4px;
  height: 6px;
  margin: 4px 0px;
  width: 22px;
`;

export const Label = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  size: 14,
})``;
