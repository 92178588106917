import { Flex, Button, Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import { CreateVoucherStatesContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/state-context';
import { useContext } from 'react';
import { calculatePreDefinedDates } from '../functions';
import { useCreateVoucherRulesValidityPeriod } from '../services';

type PredefinedDatePeriod = {
  amount: number;
  unit: 'hours' | 'days' | 'months';
};

const preDefinedValidityPeriods: PredefinedDatePeriod[] = [
  { amount: 24, unit: 'hours' },
  { amount: 7, unit: 'days' },
  { amount: 15, unit: 'days' },
  { amount: 30, unit: 'days' },
  { amount: 3, unit: 'months' },
  { amount: 6, unit: 'months' },
  { amount: 12, unit: 'months' },
];

const PreDefinedPeriods = () => {
  const { t } = useTranslation();
  const { onPreDefinedValidityPeriodButtonClick } =
    useCreateVoucherRulesValidityPeriod();
  const {
    createVoucherData: { validityPeriod },
  } = useContext(CreateVoucherStatesContext);
  return (
    <Box mt="2">
      <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
        {t(
          'payments.voucher-tab.create-voucher.rules.validity-period.other-options'
        )}
      </Text>
      <Flex gap="0.625rem" flexDirection="row" mt="2">
        {preDefinedValidityPeriods.map((item, index) => (
          <Button
            key={index}
            bgColor="white"
            border="1px"
            borderColor="gray.300"
            borderRadius="0.5rem"
            color="black"
            fontSize="sm"
            fontWeight="normal"
            h="2rem"
            mt="1rem"
            isDisabled={validityPeriod.isUnexpirable}
            lineHeight="2rem"
            minWidth="5.2rem"
            textAlign="center"
            w="5.2rem"
            _hover={{ bg: 'gray.100' }}
            onClick={() => {
              const { from, to } = calculatePreDefinedDates(
                item.amount,
                item.unit
              );
              onPreDefinedValidityPeriodButtonClick(from, to);
            }}
          >
            {t(
              `payments.voucher-tab.create-voucher.rules.validity-period.duration-${item.unit}`,
              { amount: item.amount }
            )}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default PreDefinedPeriods;
