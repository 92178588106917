import { StationConfigurationKey } from 'generated/graphql';

type Props = {
  stationConfigurationKeys: StationConfigurationKey[];
  currentStationConfigurationKeys: StationConfigurationKey[];
};

export const getStationConfigurationKeysChanged = ({
  stationConfigurationKeys,
  currentStationConfigurationKeys,
}: Props) => {
  const changedConfigurationKeys = stationConfigurationKeys.filter(
    (stationConfigurationKeys, index) => {
      if (
        JSON.stringify(stationConfigurationKeys) ===
        JSON.stringify(currentStationConfigurationKeys[index])
      )
        return false;
      return true;
    }
  );

  return changedConfigurationKeys;
};

export const checkDifferenceBetweenObjects = ({
  stationConfigurationKeys,
  currentStationConfigurationKeys,
}: Props) => {
  const hasDifference =
    JSON.stringify(stationConfigurationKeys) !==
    JSON.stringify(currentStationConfigurationKeys);
  return hasDifference;
};

export const sortStationConfigurationKeys = (
  data: StationConfigurationKey[] | []
) => {
  const sortedStationConfigurationKeys = data.sort((a, b) => {
    if (a.key > b.key) {
      return 1;
    }
    if (b.key > a.key) {
      return -1;
    }
    return 0;
  });

  return sortedStationConfigurationKeys;
};
