import Typography from 'components/Typography';
import {
  Connector,
  ReleaseConnectorLockMutation,
  ReleaseConnectorLockMutationVariables,
  Station,
} from 'generated/graphql';
import releaseConnectorLockMutation from 'graphql/mutations/releaseConnectorLock';
import { useAuthMutation } from 'hooks';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import { H5 } from 'new-components/Typographies/styles';
import { useCallback, useState } from 'react';
import toast from 'services/toast';
import { Trans, useTranslation } from 'react-i18next';
import { DropdownContainer } from './styles';

const boldifyText = (text: string | number) => (
  <Typography weight="bold">{text}</Typography>
);

type Props = {
  connector: Pick<Connector, 'id' | 'type'>;
  station: Pick<Station, 'id' | 'name'>;
};

const ManageConnectorDropdown = ({ station, connector }: Props) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [realisingConnector, setRealisingConnector] = useState(false);

  const [releaseLock] = useAuthMutation<
    ReleaseConnectorLockMutation,
    ReleaseConnectorLockMutationVariables
  >(releaseConnectorLockMutation);

  const releaseConnectorLock = useCallback(
    async (
      connector: Pick<Connector, 'id' | 'type'>,
      station: Pick<Station, 'id' | 'name'>
    ) => {
      const ConnectorTypeBoldifyText = () => boldifyText(connector.type);
      const ConnectorIdBoldifyText = () => boldifyText(`#${connector.id}`);
      const StationNameBoldifyText = () => boldifyText(station.name);
      const errorMessage = (
        <>
          <Trans
            i18nKey="manage-connector-dropdown-error"
            components={{
              ConnectorTypeBoldifyText: <ConnectorTypeBoldifyText />,
              ConnectorIdBoldifyText: <ConnectorIdBoldifyText />,
              StationNameBoldifyText: <StationNameBoldifyText />,
            }}
          />
        </>
      );

      const successMessage = (
        <>
          <Trans
            i18nKey="manage-connector-successfully-unlocked"
            components={{
              ConnectorTypeBoldifyText: <ConnectorTypeBoldifyText />,
              ConnectorIdBoldifyText: <ConnectorIdBoldifyText />,
              StationNameBoldifyText: <StationNameBoldifyText />,
            }}
          />
        </>
      );

      window.confirm(
        t('manage-connector-dropdown.confirm-station-unlock.message', {
          stationName: station.name,
        })
      );

      setRealisingConnector(true);
      try {
        const { data, errors } = await releaseLock({
          variables: {
            where: { stationId: station.id, connectorId: connector.id },
          },
        });
        setRealisingConnector(false);

        if (errors) {
          toast.error(errorMessage);
        } else if (data) {
          toast.success(successMessage);
        }
      } catch (error) {
        setRealisingConnector(false);
        toast.error(errorMessage);
        console.warn(error);
      }
    },
    [releaseLock] // eslint-disable-line
  );

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggleDropdown={setIsDropdownOpen}
      position="left"
      align="start"
      onClickOutside={() => setIsDropdownOpen(false)}
      content={() => (
        <DropdownContainer>
          <Dropdown.Section>
            <Dropdown.Row
              onClick={async (event) => {
                event.stopPropagation();
                await releaseConnectorLock(connector, station);
              }}
            >
              <>
                <H5>
                  {t('manage-connector-dropdown-unlock-connector.row.title')}
                </H5>
                {realisingConnector && <Dropdown.RowLoading />}
              </>
            </Dropdown.Row>
          </Dropdown.Section>
        </DropdownContainer>
      )}
    >
      <Dropdown.TriggerButtonContainer
        active={isDropdownOpen}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Icon type="NEW_THREE_HORIZONTAL_DOTS" color="PRIMARY_GRAY" size={24} />
      </Dropdown.TriggerButtonContainer>
    </Dropdown>
  );
};

export { ManageConnectorDropdown };
