import React from 'react';
import { Container, Title, CounterLabel } from './styles';

type Props = {
  active: boolean;
  onClick: () => void;
};

const NavLink: React.FC<Props> = ({ children, active, onClick }) => (
  <Container active={active} onClick={onClick}>
    {children}
  </Container>
);

export default Object.assign(NavLink, { Title, CounterLabel });
