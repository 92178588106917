import styled from 'styled-components';

export const FilterContainer = styled.div`
  [data-test='filter-container'] {
    display: flex;
    left: 16px;
    top: 250px;
    margin: 0;
    max-width: calc(100% - 46px);
    z-index: 999;
  }
`;
