import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TabPanels,
  Text,
} from '@chakra-ui/react';
import useHasModule from 'hooks/useHasModule';
import useHasScopes from 'hooks/useHasScope';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RFIDMaganagement from './Management';

type TabProps = {
  icon: keyof NotNestedNewIcons;
  text: string;
};

const RFID: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const hasScopes = useHasScopes();
  const hasModule = useHasModule();

  const tabs = [
    hasModule('RFID') &&
      hasScopes(['rfid:read']) && {
        icon: 'NEW_RFID',
        text: t('rfid.rfid-tab.title'),
      },
  ] as TabProps[];

  return (
    <Flex
      h="100%"
      w="100%"
      bg="gray.50"
      boxSizing="border-box"
      flexDirection="column"
    >
      <Tabs onChange={(index) => setCurrentIndex(index)} w="100%">
        <Box position="relative" zIndex="1">
          <Box w="100%" p="6" bg="white">
            <Heading fontSize="2xl">{t('rfid.header.title')}</Heading>
            <Box mt="2">
              <Text color="gray.500" fontSize="sm">
                {t('rfid.subtitle')}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box bg="white" w="100%" zIndex="1" position="relative">
          <TabList pl="6" shadow="sm">
            {tabs
              .filter((tab) => !!tab)
              .map((tab, index) => (
                <Tab display="flex" alignItems="center">
                  <Flex alignItems="center">
                    <Box mb="0.5" mr="2">
                      <Icon
                        type={tab.icon}
                        color={
                          index === currentIndex
                            ? 'PRIMARY_GRAY'
                            : 'SECONDARY_GRAY'
                        }
                      />
                    </Box>
                    {tab.text}
                  </Flex>
                </Tab>
              ))}
          </TabList>
        </Box>
        <TabPanels overflowY="auto" overflowX="hidden" h="75vh">
          <TabPanel paddingTop="0">
            <RFIDMaganagement />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default RFID;
