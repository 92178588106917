import { gql } from '@apollo/client';
import alarmLabelFieldsFragment from 'graphql/fragments/alarmLabelFields';

export default gql`
  query StationMonitoringPreviewAlarms(
    $limit: Int
    $offset: Int
    $where: StationWhereUniqueInput!
    $stationId: String!
  ) {
    station(where: $where) {
      id
      alarms(
        filter: {
          archived: false
          resolved: false
          stationId: $stationId
          level: CRITICAL
        }
        limit: $limit
        offset: $offset
        sort: { timestamp: DESC }
      ) {
        __typename
        id
        connectorId
        timestamp
        ...AlarmLabelFields
        stationId
        createdByStationLogId
      }
      criticalAlarmsCount: alarmsSummary(
        filter: { level: CRITICAL, resolved: false }
      ) {
        count
      }
    }
  }
  ${alarmLabelFieldsFragment}
`;
