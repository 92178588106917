import { Checkbox, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { verifyIfOrganizationIsSelected } from '../functions';

type Props = {
  preSelectedOrganizationIds?: string[];
  organizationId: string;
  children: ReactNode;
  onClick: () => void;
};

export const OrganizationCheckbox = ({
  preSelectedOrganizationIds,
  organizationId,
  children,
  onClick,
}: Props) => {
  const isSelectedOrganization = verifyIfOrganizationIsSelected(
    organizationId,
    preSelectedOrganizationIds
  );
  return (
    <Flex alignItems="center">
      <Checkbox
        pointerEvents="auto"
        _hover={{
          bg: 'gray.100',
          transition: '0.2s',
          borderRadius: 'md',
        }}
        isDisabled={
          preSelectedOrganizationIds?.length === 1 &&
          organizationId === preSelectedOrganizationIds[0]
        }
        size="sm"
        isChecked={isSelectedOrganization}
        onChange={(e) => {
          e.stopPropagation();
          onClick();
        }}
        m={0}
        px={2}
        py={1}
        mr={1.5}
      >
        {children}
      </Checkbox>
    </Flex>
  );
};
