import env from '@beam-australia/react-env';
import { ApplicationName } from 'index';
import IpeCarregadoresConfig from './ipe-carregadores';
import AnguloSolucoesConfig from './angulo-solucoes';
import AtlasPowerConfig from './atlas-power';
import CanaaConfig from './canaa';
import ChargeOnConfig from './chargeon';
import EMogConfig from './e-mog';
import EasyChargeConfig from './easycharge';
import EcodotConfig from './ecodot';
import EDPConfig from './edp';
import ElectroBoConfig from './electrobo';
import EquatorialConfig from './equatorial';
import EvMobiConfig from './ev-mobi';
import FullChargeConfig from './full-charge';
import GreenYellowConfig from './green-yellow';
import GreeneaConfig from './greenea';
import GreenspaceConfig from './greenspace';
import HelteEnergyConfig from './helte-energy';
import IonConfig from './ion';
import IpirangaConfig from './ipiranga';
import NeochargeConfig from './neocharge';
import NeoenergiaConfig from './neoenergia';
import NowChargeConfig from './now-charge';
import OnMobilityConfig from './on-mobility';
import PlugRConfig from './plug-r';
import PontoonConfig from './pontoon';
import RecarConfig from './recar';
import SilisMobilityConfig from './silis-mobility';
import SolarprimeConfig from './solarprime';
import SPChargeConfig from './sp-charge';
import StellantisConfig from './stellantis';
import ConfigType from './typing';
import VoltbrasConfig from './voltbras';
import VoxConfig from './vox';
import WeChargeConfig from './wecharge';
import YpfConfig from './ypf';

export const configs: Record<ConfigType['CODE'], ConfigType> = {
  edp: EDPConfig,
  voltbras: VoltbrasConfig,
  neoenergia: NeoenergiaConfig,
  ipiranga: IpirangaConfig,
  'plug-r': PlugRConfig,
  neocharge: NeochargeConfig,
  recar: RecarConfig,
  'green-yellow': GreenYellowConfig,
  greenea: GreeneaConfig,
  wecharge: WeChargeConfig,
  ecodot: EcodotConfig,
  greenspace: GreenspaceConfig,
  pontoon: PontoonConfig,
  demo: {
    ...VoltbrasConfig,
    API: {
      ...VoltbrasConfig.API,
    },
  },
  chargeon: ChargeOnConfig,
  'ende-guaracachi': ElectroBoConfig,
  evmobi: EvMobiConfig,
  'full-charge': FullChargeConfig,
  'helte-energy': HelteEnergyConfig,
  'sp-charge': SPChargeConfig,
  'evpv-power': AtlasPowerConfig,
  'emobility-solutions': EasyChargeConfig,
  ev: StellantisConfig,
  'vox-company': VoxConfig,
  ypf: YpfConfig,
  'ion-mobilidade': IonConfig,
  'on-mobility': OnMobilityConfig,
  canaa: CanaaConfig,
  equatorial: EquatorialConfig,
  solarprime: SolarprimeConfig,
  'now-charge': NowChargeConfig,
  'silis-mobility': SilisMobilityConfig,
  'angulo-solucoes': AnguloSolucoesConfig,
  'e-mog': EMogConfig,
  'ipe-carregadores': IpeCarregadoresConfig,
};

const applicationName = env('APPLICATION_NAME') as ApplicationName;

const isValidWhitelabel = (appCode: string): appCode is ConfigType['CODE'] =>
  Object.keys(configs).includes(appCode);

export const getConfigOf = (appCode: ConfigType['CODE']): ConfigType =>
  configs[appCode];

const getAppCodeFromURL = (): ConfigType['CODE'] | undefined => {
  const domainPrefix = window.location.href.match(/((\w|-)+)\./);
  if (!domainPrefix) return;
  return domainPrefix.map((x) => x.toLowerCase()).find(isValidWhitelabel);
};

const appCodeFromEnv = env('CODE');

const getAccountExclusionAppCode = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const appCodeFromUrlParam = urlSearchParams.get('appCode');

  const appCode =
    appCodeFromUrlParam && isValidWhitelabel(appCodeFromUrlParam)
      ? appCodeFromUrlParam
      : 'voltbras';

  return appCode;
};

const getDashboardAppCode = () => {
  const appCode = isValidWhitelabel(appCodeFromEnv)
    ? appCodeFromEnv
    : getAppCodeFromURL() || 'voltbras';

  return appCode;
};

const getAppCode = (): ConfigType['CODE'] => {
  switch (applicationName) {
    case 'ACCOUNT_EXCLUSION':
      return getAccountExclusionAppCode();
    default:
      return getDashboardAppCode();
  }
};

const appCode = getAppCode();
const config: ConfigType = getConfigOf(appCode);

export default config;
