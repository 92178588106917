import { gql } from '@apollo/client';

export default gql`
  query DriverModalSessionsTab(
    $where: DriverWhereUniqueInput!
    $offset: Int
    $limit: Int
  ) {
    driver(where: $where) {
      id
      sessions(offset: $offset, limit: $limit, sort: { createdAt: DESC }) {
        id
        driverId
        energyConsumed
        duration
        createdAt
        startedChargingAt
        finishedAt
        status
        cpoId
        emspId
        stationProfile {
          id
          name
          address {
            state
            city
            streetNumber
            postalCode
          }
        }
        connector {
          id
          type
          power
          currentType
        }
        order {
          orderId
          capturedAt
          netValue
          totalCost
          currencyType
        }
      }
      sessionsSummary {
        count
      }
    }
  }
`;
