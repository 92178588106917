import { t } from 'i18next';
import { PricingConfig } from 'types/station';
import { CurrencyType } from 'types';
import { formatCurrency } from './format';

const formatPriceWithCurrency = (price: number, currencyType?: CurrencyType) =>
  formatCurrency(price, { currencyType });

export const formatPricingConfig = (
  config: PricingConfig,
  chargeFee?: number,
  currencyType?: CurrencyType
): string => {
  const formattedChargeFee = chargeFee
    ? `${formatPriceWithCurrency(chargeFee, currencyType)} + `
    : '';
  switch (config.__typename) {
    case 'PerTimePricingConfig':
      return `${formattedChargeFee} ${formatPriceWithCurrency(
        config.costPerMinute,
        currencyType
      )} / ${t('format-pricing-config-service.minutes-abbreviation')}`;
    case 'PerEnergyPricingConfig':
      return `${formattedChargeFee} ${formatPriceWithCurrency(
        config.costPerKwh,
        currencyType
      )} / kWh`;
  }
};
