import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const DotsWrapper = styled(Link)`
  text-decoration: none;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 10px 5px 0;

  width: fit-content;
  max-width: 100%;
  border-radius: 9px;
  margin-top: -5px;
  margin-left: -11px;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND};
  }
  &:active {
    background-color: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 16px;
  margin-right: 9px;
  margin-left: 12px;
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
`;
