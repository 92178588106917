import { gql } from '@apollo/client';

export default gql`
  query AllStationsOrganizationIndicators(
    $timeRange: TimeRangeInput!
    $sessionsFilter: SessionFilterInput = {
      includesStatus: [CHARGING, WAITING_UNPLUG, FINISHED]
    }
  ) {
    organizations {
      name
      id
      averageUsageRate(timeRange: $timeRange)
      sessionsSummary(timeRange: $timeRange, filter: $sessionsFilter) {
        grossValue
        count
        timeCharging
      }
    }
  }
`;
