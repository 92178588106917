import styled from 'styled-components';
import Config from 'config';
import { Typography } from 'components';

export const Title = styled(Typography).attrs({
  size: 14,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
  line-height: 20px;
`;

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 16px;

  cursor: pointer;

  & > * {
    color: ${Config.COLORS.SECONDARY_GRAY};
    margin-right: 8px;
  }

  & > svg {
    color: auto;
  }

  & > :last-child {
    margin-right: 0;
  }

  :hover {
    ${Title} {
      color: ${Config.COLORS.PRIMARY_GRAY};
    }

    ${({ active }) =>
      !active &&
      `
       box-shadow: inset 0px -2px 0px ${Config.COLORS.SECONDARY_GRAY};
    `}
  }

  ${({ active }) =>
    active &&
    `
    & > * { 
      color: ${Config.COLORS.PRIMARY_GRAY};
    }
    
    box-shadow: inset 0px -2px 0px ${Config.COLORS.PRIMARY};
  `}
`;

export const CounterLabel = styled(Typography).attrs({
  size: 12,
  weight: 'bold',
})`
  color: white;
  line-height: 16px;
  height: 16px;
  padding: 0 6px;
  border-radius: 100px;
  background: ${({ theme }) => theme.COLORS.ERROR};
`;
