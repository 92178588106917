import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Switch,
  Flex,
  Text,
  Box,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import {
  AllowStationToAcceptRfidMutation,
  AllowStationToAcceptRfidMutationVariables,
  StationUnlockMethodsQuery,
  StationUnlockMethodsQueryVariables,
} from 'generated/graphql';
import stationUnlockMethods from 'graphql/queries/stationUnlockMethods';
import { StationContext } from 'pages/StationPage/StationContext';
import { useAuthMutation, useAuthQuery } from 'hooks';
import Card from 'pages/StationPage/Card';
import Toast from 'pages/StationPage/Toast';
import LoadingComponent from 'new-components/LoadingComponent';
import AllowStationToAcceptRFID from 'graphql/mutations/allowStationToAcceptRFID';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Unlock = () => {
  const { t } = useTranslation();
  const { id: stationId, model } = useContext(StationContext);
  const [isRFIDAllowed, setIsRFIDAllowed] = useState(false);
  const [currentIsRFIDAllowed, setCurrentIsRFIDAllowed] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const toastOnAnotherPage = useToast();

  const { data, loading } = useAuthQuery<
    StationUnlockMethodsQuery,
    StationUnlockMethodsQueryVariables
  >(stationUnlockMethods, {
    variables: {
      where: {
        stationId,
      },
    },
  });

  const [allowStationToAcceptRFID, { loading: loadingMutation }] =
    useAuthMutation<
      AllowStationToAcceptRfidMutation,
      AllowStationToAcceptRfidMutationVariables
    >(AllowStationToAcceptRFID, {
      variables: {
        data: {
          allowed: isRFIDAllowed,
        },
        where: {
          stationId,
        },
      },
    });

  const station = data?.station;
  const stationModelSupportsRFIDCapability =
    model?.capabilities.includes('RFID');

  const closeToast = useCallback(async () => {
    setToastVisible(false);
    toastOnAnotherPage.closeAll();
  }, [toastOnAnotherPage]);

  const openToast = () => setToastVisible(true);

  useEffect(() => {
    if (currentIsRFIDAllowed !== isRFIDAllowed) {
      openToast();
    } else {
      closeToast();
    }
  }, [currentIsRFIDAllowed, isRFIDAllowed, closeToast]);

  useEffect(() => {
    setCurrentIsRFIDAllowed(
      station?.unlockMethods.includes('ChargingCard') ?? false
    );
    setIsRFIDAllowed(station?.unlockMethods.includes('ChargingCard') ?? false);
  }, [station]);

  const handleSubmit = async () => {
    try {
      await allowStationToAcceptRFID();
      setCurrentIsRFIDAllowed(isRFIDAllowed);
      toast.success(
        t('station-page.config-tab.unlock-tab.toast-successfully-msg')
      );
    } catch (error) {
      toast.error(t('station-page.config-tab.unlock-tab.toast-error-msg'));
    }
    closeToast();
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" mt="40">
        <LoadingComponent size={51} />
      </Flex>
    );
  }
  return (
    <Box p="4">
      <Heading fontSize="2xl" mb="8">
        {t('station-page.config-tab.unlock-tab.header.title')}
      </Heading>
      <Heading fontSize="md" pb="2">
        {t('station-page.config-tab.unlock-tab.header.subtitle')}
      </Heading>
      <Card>
        <Flex alignItems="center" pb="19.5px">
          <Switch
            flexDirection="row"
            display="flex"
            alignItems="center"
            isChecked={isRFIDAllowed}
            onChange={() => setIsRFIDAllowed((allowed) => !allowed)}
            opacity={stationModelSupportsRFIDCapability ? '1' : '0.3'}
            pointerEvents={stationModelSupportsRFIDCapability ? 'auto' : 'none'}
            m="0"
          >
            <Flex alignItems="center">
              <Text fontSize="sm" pl="2">
                {t('station-page.config-tab.unlock-tab.switch.title')}{' '}
              </Text>
              <Text
                as="strong"
                fontFamily="heading"
                fontSize="sm"
                pr="9px"
                fontWeight="normal"
              >
                {t('station-page.config-tab.unlock-tab.switch.bold-title')}
              </Text>
            </Flex>
          </Switch>
          <Box>
            <Tooltip
              textAlign="center"
              label={t('station-page.config-tab.unlock-tab.switch.label')}
              bg="gray.900"
              placement="right"
              hasArrow
            >
              <Stack>
                <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
              </Stack>
            </Tooltip>
          </Box>
        </Flex>
        <Box>
          <Text fontSize="sm" lineHeight="7" color="gray.600">
            {t('station-page.config-tab.unlock-tab.functionality-description')}
          </Text>
        </Box>
      </Card>
      <Toast
        id={'unlock'}
        resetFields={() => {
          setIsRFIDAllowed(currentIsRFIDAllowed);
          closeToast();
        }}
        onClick={handleSubmit}
        loading={loadingMutation}
        isVisible={toastVisible}
        inputsValues={[isRFIDAllowed]}
      />
    </Box>
  );
};

export default Unlock;
