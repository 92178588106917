import { Heading } from '@chakra-ui/react';
import Dropdown from 'new-components/Dropdown';
import { Icon } from 'new-components/Icon';
import { useTranslation } from 'react-i18next';

type Props = {
  loading: boolean;
  onClick: () => void;
};
const ResendInviteMemberDropdownRow = ({ loading, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Dropdown.Row onClick={onClick}>
      <Heading fontSize="sm">
        {t('member-dropdown.resend-invite-member-dropdown-row.heading')}
      </Heading>
      {loading ? (
        <Dropdown.RowLoading />
      ) : (
        <Icon type="NEW_SHARE" color="PRIMARY_GRAY" size={20} />
      )}
    </Dropdown.Row>
  );
};

export { ResendInviteMemberDropdownRow };
