import { useState } from 'react';
import { ListVoucherEventsContext } from './events-context';
import {
  initialListVoucherStatesContextValues,
  ListVoucherStatesContext,
} from './state-context';

export const ListVoucherProvider: React.FC = ({ children }) => {
  const initialStates = initialListVoucherStatesContextValues;

  const [parsedVoucherTableFilters, updateParsedVoucherTableFilters] = useState(
    initialStates.parsedVoucherTableFilters
  );
  const [requestedVoucherName, updateRequestedVoucherName] = useState(
    initialStates.requestedVoucherName
  );

  return (
    <ListVoucherEventsContext.Provider
      value={{
        getEvents: () => ({
          updateParsedVoucherTableFilters,
          parsedVoucherTableFilters,
          updateRequestedVoucherName,
          requestedVoucherName,
        }),
      }}
    >
      <ListVoucherStatesContext.Provider
        value={{
          parsedVoucherTableFilters,
          requestedVoucherName,
        }}
      >
        {children}
      </ListVoucherStatesContext.Provider>
    </ListVoucherEventsContext.Provider>
  );
};
