import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PersonalizedPeriod from './PersonalizedPeriod';
import PreDefinedPeriods from './PreDefinedPeriods';
import InexpirableCheckBox from './InexpirableCheckBox';

export const VoucherValidityPeriodSelection = () => {
  const { t } = useTranslation();
  return (
    <FormControl isRequired={true} mt="2rem">
      <FormLabel fontWeight="bold" fontSize="sm">
        {t('payments.voucher-tab.create-voucher.rules.validity-period.title')}
      </FormLabel>
      <Flex flexDirection="column" gap="0.5rem">
        <PersonalizedPeriod />
        <PreDefinedPeriods />
        <InexpirableCheckBox />
      </Flex>
    </FormControl>
  );
};
