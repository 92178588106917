import { ConnectorStatus } from 'generated/graphql';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

export type OpenStateType = 'ACTIVE' | 'INACTIVE' | 'NONE';

export const ConnectorContainer = styled.div<{ openState: OpenStateType }>`
  ${({ theme: { COLORS }, openState }) => css`
    align-items: center;
    background: ${openState === 'ACTIVE' ? COLORS.TERTIARY_GRAY : COLORS.WHITE};
    border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    border-radius: 8px;
    display: flex;
    height: 28px;
    justify-content: center;
    position: relative;
    width: 28px;
    &:hover {
      background: ${openState === 'ACTIVE'
        ? COLORS.TERTIARY_GRAY
        : COLORS.BACKGROUND};
    }
  `}
`;

export const Status = styled.div<{ status: ConnectorStatus }>`
  background-color: ${({ theme, status }) =>
    theme.COLORS.CONNECTOR_STATUS[status]};
  border-radius: 12px;
  height: 12px;
  width: 12px;
`;
