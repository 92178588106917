import React from 'react';
import Config from 'config';
import { Slide, toast, ToastProps } from 'react-toastify';
import styled from 'styled-components';
import { SuccessMessage, ErrorMessage } from '../components/ToastContainer';

const toastSuccess = toast.success;
const toastError = toast.error;

const ContainerXIcon = styled.div``;

const Icon = styled(Config.ICONS.TOAST_X_ICON)`
  & > .b {
    fill: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  }
  & > .a {
    fill: none;
  }
`;

const CloseButton = ({ closeToast }: ToastProps) => (
  <ContainerXIcon onClick={closeToast}>
    <Icon />
  </ContainerXIcon>
);

// redefines toast methods to keep the same methods signature
// but define new behavior as to add the default
// UI component and options
toast.success = (content, options) =>
  toastSuccess(<SuccessMessage>{content}</SuccessMessage>, {
    ...options,
    closeButton: CloseButton,
    transition: Slide,
  });
toast.error = (content, options) =>
  toastError(<ErrorMessage>{content}</ErrorMessage>, {
    ...options,
    autoClose: false,
    closeButton: CloseButton,
    transition: Slide,
  });

export default toast;
