import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import { useCreateVoucherAbout } from './services';

const About = () => {
  const voucherDescriptionMaxLength = 250;
  const { t } = useTranslation();
  const { name, description, setName, setDescription } =
    useCreateVoucherAbout();
  return (
    <Flex
      borderColor="gray.100"
      borderRadius="1rem"
      borderWidth="thin"
      w="100%"
      bg="white"
      flexDirection="column"
      p="1.5rem"
      gap="1.5rem"
    >
      <Box>
        <Heading fontSize="xl">
          {t('payments.voucher-tab.create-voucher.about.title')}
        </Heading>
        <Text mt="0.125rem" color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
          {t('payments.voucher-tab.create-voucher.about.subtitle')}
        </Text>
      </Box>

      <FormControl w="100%" paddingTop="0.5rem" isRequired={true}>
        <FormLabel fontWeight="bold" fontSize="sm">
          {t('payments.voucher-tab.create-voucher.about.voucher-name')}
        </FormLabel>
        <Input
          h="2rem"
          type="text"
          value={name}
          placeholder={t(
            'payments.voucher-tab.create-voucher.about.voucher-name.placeholder'
          )}
          onChange={({ target: { value } }) => setName(value)}
        />
      </FormControl>
      <FormControl w="100%" paddingTop="0.25rem" isRequired={true}>
        <FormLabel fontWeight="bold" fontSize="sm">
          {t('payments.voucher-tab.create-voucher.about.voucher-description')}
        </FormLabel>
        <Textarea
          h="5rem"
          maxLength={voucherDescriptionMaxLength}
          multiple
          type="text"
          value={description}
          placeholder={t(
            'payments.voucher-tab.create-voucher.about.voucher-description.placeholder'
          )}
          onChange={({ target: { value } }) => setDescription(value)}
        />
        <Flex flexDirection="row-reverse">
          <Text mt="2" color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
            {t(
              'payments.voucher-tab.create-voucher.about.voucher-description.characters'
            )}
            {' - '}
            {description.length}/{voucherDescriptionMaxLength}
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default About;
