import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import React from 'react';

type ContainerCheckboxProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  key?: React.Key;
  value?: string | number;
};

const Checkbox: React.FC<ContainerCheckboxProps> = ({
  children,
  onChange,
  isChecked,
  key,
  value,
}) => (
  <ChakraCheckbox
    value={value}
    key={key}
    isChecked={isChecked}
    onChange={onChange}
  >
    {children}
  </ChakraCheckbox>
);
export default Checkbox;
