import React from 'react';
import { CardAtom } from './styles';

type CardProps = {
  minHeight?: string;
  width?: string;
  marginTop?: string;
};

const Card: React.FC<CardProps> = ({
  children,
  width = '100%',
  marginTop = '0px',
  minHeight = '100%',
}) => (
  <CardAtom minHeight={minHeight} marginTop={marginTop} width={width}>
    {children}
  </CardAtom>
);

export default Card;
