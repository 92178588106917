import Config from 'config';
import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  COLORS: Config.COLORS,
  FONTS: Config.FONTS,
  radius: {
    'RADIUS-4': 4,
    'RADIUS-8': 8,
    'RADIUS-12': 12,
    'RADIUS-16': 16,
    'RADIUS-24': 24,
    'RADIUS-32': 32,
    'RADIUS-FULL': 1000,
  },
  padding: {
    'PADDING-4': 4,
    'PADDING-8': 8,
    'PADDING-12': 12,
    'PADDING-16': 16,
    'PADDING-24': 24,
    'PADDING-32': 32,
    'PADDING-48': 48,
    'PADDING-64': 64,
  },
  space: {
    'SPACE-4': 4,
    'SPACE-8': 8,
    'SPACE-12': 12,
    'SPACE-16': 16,
    'SPACE-24': 24,
    'SPACE-32': 32,
    'SPACE-48': 48,
    'SPACE-64': 64,
  },
  borderStyle: {
    DEFAULT: `1px solid ${Config.COLORS.TERTIARY_GRAY}`,
  },
};

export default defaultTheme;
