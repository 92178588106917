import { useContext, memo } from 'react';
import { BoxProps, Flex, IconButton, Text } from '@chakra-ui/react';
import AuthContext, { Organization } from 'contexts/Auth/context';
import { CustomDomComponent } from 'framer-motion';
import { Icon } from 'new-components/Icon';
import { checkIfMemberBelongsToParentOrganization } from 'services/multiorg';
import { ItemAnimation, MotionBox, DropdownRow } from '../styles';
import { VerticalLine } from './verticalLine';
import { VERTICAL_LINE_SPACING_TO_INCREASE } from '../function';

type Props = {
  position?: 'relative' | 'initial';
  organization: Organization;
  paddingLeft: number;
  onClickToSelectOrganization: () => void;
  onClickToExpandSuborganizations: () => void;
  isExpanded: boolean;
} & Omit<CustomDomComponent<BoxProps>, '$$typeof'>;

const Row = ({
  organization,
  paddingLeft,
  onClickToExpandSuborganizations,
  onClickToSelectOrganization,
  position = 'initial',
  isExpanded,
  ...rest
}: Props) => {
  const { organizations } = useContext(AuthContext);

  const checkIfHasSuborganization = (orgId?: string) =>
    organizations?.some((org) => org.parentOrganizationId === orgId);

  const isMemberBelongsToParentOrganization =
    checkIfMemberBelongsToParentOrganization(
      organizations ?? [],
      organization.parentOrganizationId
    );

  const showVerticalLine =
    !!organization.parentOrganizationId &&
    !!isMemberBelongsToParentOrganization;

  return (
    <MotionBox
      variants={ItemAnimation}
      key={organization.id}
      width="full"
      position={position}
      {...rest}
    >
      <DropdownRow
        onClick={(e) => {
          e.stopPropagation();
          onClickToSelectOrganization();
        }}
      >
        <Flex
          w="full"
          justify="space-between"
          align="center"
          pl={`${paddingLeft}px`}
        >
          {showVerticalLine && (
            <VerticalLine
              left={`${paddingLeft + VERTICAL_LINE_SPACING_TO_INCREASE}px`}
            />
          )}
          <Text noOfLines={1} fontSize="sm" maxWidth={40}>
            {organization.name}
          </Text>
          {checkIfHasSuborganization(organization.id) && (
            <IconButton
              size="xs"
              colorScheme="gray"
              aria-label="Expandir Suborganizações"
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                onClickToExpandSuborganizations();
              }}
              icon={
                <Icon
                  type={isExpanded ? 'NEW_CHEVRON_DOWN' : 'NEW_CHEVRON_RIGHT'}
                  size={20}
                  color="PRIMARY_GRAY"
                />
              }
            />
          )}
        </Flex>
      </DropdownRow>
    </MotionBox>
  );
};

export default memo(Row);
