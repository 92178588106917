import { gql } from '@apollo/client';

export default gql`
  mutation ConfigureStationChargeFinishedMessage(
    $where: SelectStationInput!
    $data: StationChargeFinishedMessageInput!
  ) {
    configureStationChargeFinishedMessage(where: $where, data: $data)
  }
`;
