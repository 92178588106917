import styled from 'styled-components';
import Config from 'config';
import { Typography } from 'components';

export const UpArrow = Config.ICONS.VERTICAL_ARROW;

export const DownArrow = styled(UpArrow)`
  transform: rotate(180deg);
`;

export const AppendixContainer = styled.span`
  margin-right: 4px;
`;

export const Container = styled(Typography).attrs({
  weight: 'bold',
})`
  display: flex;
  align-items: center;
`;
