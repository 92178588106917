import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const GreenYellowConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: false,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: false,
  GA_TRACKING_ID: 'UA-119882526-8',
  CODE: 'green-yellow',
  LABEL: 'GreenYellow',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#86BC25',
    CLICKABLE: '#659908',
    ERROR: '#D42E11',
    SIDEBAR: '#373737',
    LOGIN_LOGO_BACKGROUND: '#86BC25',

    RISE: '#0C5526',
    FALL: '#D42E11',
    NEUTRAL: '#81B4BF',

    CRITICAL: '#D42E11',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#0C5526',
    NoCost: '#F4F4F4',
    Error: '#D42E11',

    AVAILABLE: '#86BC25',
    CHARGING: '#00757E',
    INOPERATIVE: '#EA5E5E',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#D42E11',
    RESERVED: '#81B4BF',

    PREPARING: '#00757E',
    FINISHING: '#00757E',

    SELECTED_DATE_BOUND_BACKGROUND: '#659908',
    SELECTED_DATE_BOUND_BORDER: '#86BC25',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#528000',

    DATE_PRE_SELECTED_BACKGROUND: '#e1ffab',
    DATE_PRE_SELECTED_BORDER: '#b3e35d',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#e1ffab',
    DATE_PRE_SELECTED_COLOR: '#659908',

    DATE_SELECTED_BACKGROUND: '#86BC25',
    DATE_SELECTED_BORDER: '#b3e35d',
    DATE_SELECTED_HOVER_BACKGROUND: '#659908',

    CURRENT_DAY: '#659908',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#86BC25',
      Subsidized: '#86BC25',
      NoCost: '#F4F4F4',
      Error: '#D42E11',
      ErrorOnPreAuthorization: '#D42E11',
    },
    STATION_STATUS: {
      AVAILABLE: '#86BC25',
      CHARGING: '#00757E',
      INOPERATIVE: '#D42E11',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#D42E11',
      RESERVED: '#81B4BF',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#86BC25',
      PREPARING: '#00757E',
      CHARGING: '#00757E',
      FINISHING: '#00757E',
      INOPERATIVE: '#D42E11',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#81B4BF',
    },

    BILLING: '#0C5526',
    RECHARGES: '#00757E',
    AVAILABILITY: '#81B4BF',

    BUTTON_AVAILABLE: '#86BC25',
    BUTTON_UNAVAILABLE: '#939393',
    BUTTON_LOADING: '#659908',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#81B4BF',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#659908',
      SELECTED_DATE_BOUND_BORDER: '#86BC25',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#528000',

      DATE_PRE_SELECTED_BACKGROUND: '#e1ffab',
      DATE_PRE_SELECTED_BORDER: '#b3e35d',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#e1ffab',
      DATE_PRE_SELECTED_COLOR: '#659908',

      DATE_SELECTED_BACKGROUND: '#86BC25',
      DATE_SELECTED_BORDER: '#b3e35d',
      DATE_SELECTED_HOVER_BACKGROUND: '#659908',

      CURRENT_DAY: '#659908',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'GreenYellow - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/green-yellow',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#e1ffab',
      '100': '#bce86d',
      '200': '#b3e35d',
      '300': '#a7db4b',
      '400': '#98cf36',
      '500': '#86BC25',
      '600': '#659908',
      '700': '#528000',
      '800': '#466D00',
      '900': '#304a01',
    },
    LINK: {
      '50': '#c8dee3',
      '100': '#bed8de',
      '200': '#b2d1d8',
      '300': '#a4c9d1',
      '400': '#94bfc9',
      '500': '#81B4BF',
      '600': '#51919f',
      '700': '#3b6973',
      '800': '#325962',
      '900': '#2a4c53',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#D42E11',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#5fe890',
      '100': '#43e47c',
      '200': '#22df65',
      '300': '#1bb953',
      '400': '#148b3e',
      '500': '#0C5526',
      '600': '#0a4820',
      '700': '#083d1b',
      '800': '#073417',
      '900': '#062c14',
    },
    CHARGING: {
      '50': '##55f2ff',
      '100': '#37f0ff',
      '200': '#14edff',
      '300': '#00d7e9',
      '400': '#00aab8',
      '500': '#00757E',
      '600': '#00636b',
      '700': '#00545b',
      '800': '#00474d',
      '900': '#003c41',
    },
    AVAILABLE: {
      '50': '#e1ffab',
      '100': '#bce86d',
      '200': '#b3e35d',
      '300': '#a7db4b',
      '400': '#98cf36',
      '500': '#86BC25',
      '600': '#659908',
      '700': '#528000',
      '800': '#466D00',
      '900': '#304a01',
    },
    SECONDARY: {
      '50': '#a6a6a6',
      '100': '#969696',
      '200': '#848484',
      '300': '#6e6e6e',
      '400': '#555555',
      '500': '#373737',
      '600': '#2f2f2f',
      '700': '#282828',
      '800': '#222222',
      '900': '#1d1d1d',
    },
    RESERVE: {
      '50': '#c8dee3',
      '100': '#bed8de',
      '200': '#b2d1d8',
      '300': '#a4c9d1',
      '400': '#94bfc9',
      '500': '#81B4BF',
      '600': '#51919f',
      '700': '#3b6973',
      '800': '#325962',
      '900': '#2a4c53',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#D42E11',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default GreenYellowConfig;
