import styled from 'styled-components';
import { Typography } from 'components';
import config from 'config';

export const CardContainer = styled.div`
  padding: 10px 20px;
  margin-bottom: 20px;
`;

export const TypographData = styled(Typography)`
  background: ${() => config.COLORS.TERTIARY_GRAY};
  padding: 3px 8px;
  border-radius: 15px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: bold;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  row-gap: 15px;
`;

export const TypographyStyled = styled(Typography)`
  margin: 0;
  padding: 0;
  line-height: 25px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CardWrapper = styled.div`
  flex: 1;
  background-color: ${({
    theme: {
      COLORS: { BACKGROUND },
    },
  }) => BACKGROUND};
`;

export const OrderTableContainer = styled.div`
  padding: 1px;
  min-height: 800px;
  margin-bottom: 0px;
  border: 1px solid
    ${({
      theme: {
        COLORS: { TERTIARY_GRAY },
      },
    }) => TERTIARY_GRAY};
  border-radius: 8px;
`;

export const Container = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
