import { Flex, Text } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Wallet: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Flex alignItems={'center'} gap={'1rem'}>
      <Icon type={'NEW_DIGITAL_WALLET'} color="PRIMARY"></Icon>
      <Text>{t('payments.order-payment-method.wallet')}</Text>
    </Flex>
  );
};
