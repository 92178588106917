import { gql } from '@apollo/client';

export default gql`
  query groupDrivers($where: GroupWhereUniqueInput!) {
    group(where: $where) {
      id
      drivers {
        id
        profile {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
