import { gql } from '@apollo/client';
import pricingConfigFieldsFragment from '../fragments/pricingConfigFields';

export default gql`
  query StationDetailsStaticData($where: StationWhereUniqueInput!) {
    station(where: $where) {
      name
      id
      accessRestriction {
        active
      }
      chargePointId
      openingHours
      qrCode
      images
      unlockMethods
      address {
        city
        state
        street
        streetNumber
        postalCode
      }
      description
      connectors {
        id
        station {
          id
        }
        pricingConfig {
          ...PricingConfigFields
        }
      }
    }
  }
  ${pricingConfigFieldsFragment}
`;
