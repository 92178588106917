import styled from 'styled-components/';
import ErrorContainer from 'new-components/ErrorComponent';
import { BodyTypography } from 'new-components/Typographies/styles';

export const ErrorComponent = styled(ErrorContainer)`
  top: calc(50% - 31px);
`;

export const Body = styled('section')`
  background: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  display: flex;
  flex-flow: column nowrap;
  gap: 48px;
  padding: 24px;
  overflow-y: scroll;
  border-top: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  max-height: 74vh;
`;

export const ContentGroup = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;

export const CardsGroup = styled('section')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

export const PaymentInfo = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
`;

export const PaymentInfoId = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;
