import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@chakra-ui/react';
import { selectedOrganizationsChanged } from '../functions';

type ApplyDropdownFilterProps = {
  preSelectedOrganizationIds?: string[];
  selectedOrganizationIds?: string[];
  setSelectedOrganizationIds: Function;
};

export const ApplyDropdownFilter = (props: ApplyDropdownFilterProps) => {
  const { t } = useTranslation();

  const {
    preSelectedOrganizationIds,
    selectedOrganizationIds,
    setSelectedOrganizationIds,
  } = props;

  const isDisabled =
    selectedOrganizationsChanged(
      preSelectedOrganizationIds,
      selectedOrganizationIds
    ) || !preSelectedOrganizationIds?.length;

  return (
    <Flex w="100%" justifyContent="center" pt={2} pb={2}>
      <Button
        size="xs"
        isDisabled={isDisabled}
        data-test="organizationApplyFilterButton"
        onClick={(e) => {
          e.stopPropagation();
          setSelectedOrganizationIds(preSelectedOrganizationIds || []);
        }}
      >
        {t('profile-dropdown.organizations-section.button.apply-filters-text')}
      </Button>
    </Flex>
  );
};
