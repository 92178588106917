import { gql } from '@apollo/client';

export default gql`
  query organizationGroups {
    groups(sort: { orgId: ASC }) {
      id
      name
      driversSummary {
        count
      }
      stationsProfileSummary(
        filter: { includesVisibility: [PUBLIC, PRIVATE] }
      ) {
        count
      }
      organization {
        id
      }
    }
  }
`;
