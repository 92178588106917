import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const EquatorialConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: false,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: false,
  GA_TRACKING_ID: 'UA-119882526-9',
  CODE: 'equatorial',
  LABEL: 'Enova Mobility',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#432574',
    CLICKABLE: '#E8D6F8',
    ERROR: '#F87060',
    SIDEBAR: '#432574',
    LOGIN_LOGO_BACKGROUND: '#432574',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#331B63',

    CRITICAL: '#F74C39',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#00C1FD',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91',

    PREPARING: '#00C1FD',
    FINISHING: '#00C1FD',

    SELECTED_DATE_BOUND_BACKGROUND: '#E8D6F8',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#261253',

    DATE_PRE_SELECTED_BACKGROUND: '#432574',
    DATE_PRE_SELECTED_BORDER: '#432574',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#E8D6F8',
    DATE_PRE_SELECTED_COLOR: '#261253',

    DATE_SELECTED_BACKGROUND: '#432574',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#E8D6F8',

    CURRENT_DAY: '#432574',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#11D073',
      CHARGING: '#00C2FF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F6334F',
      RESERVED: '#6C36FE',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#00C1FD',
      CHARGING: '#00C1FD',
      FINISHING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },

    BILLING: '#04E762',
    RECHARGES: '#00C1FD',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#432574',
    BUTTON_UNAVAILABLE: '#E8D6F8',
    BUTTON_LOADING: '#CFAFF1',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#432574',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#E8D6F8',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#261253',

      DATE_PRE_SELECTED_BACKGROUND: '#432574',
      DATE_PRE_SELECTED_BORDER: '#432574',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#E8D6F8',
      DATE_PRE_SELECTED_COLOR: '#261253',

      DATE_SELECTED_BACKGROUND: '#432574',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#E8D6F8',

      CURRENT_DAY: '#432574',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -2.4984344781938272,
      lng: -44.26189618236045,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Enova Mobility - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/equatorial',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#EBDFF5',
      '100': '#E8D6F8',
      '200': '#CFAFF1',
      '300': '#A57ED5',
      '400': '#7853AB',
      '500': '#432574',
      '600': '#E8D6F8',
      '700': '#261253',
      '800': '#1A0B43',
      '900': '#120737',
    },
    LINK: {
      '50': '#EBDFF5',
      '100': '#EBDFF5',
      '200': '#CFAFF1',
      '300': '#A57ED5',
      '400': '#7853AB',
      '500': '#432574',
      '600': '#331B63',
      '700': '#261253',
      '800': '#1A0B43',
      '900': '#120737',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#b1ecff',
      '100': '#7ee0ff',
      '200': '#64daff',
      '300': '#4bd4ff',
      '400': '#31ceff',
      '500': '#00C1FD',
      '600': '#009aca',
      '700': '#0087b1',
      '800': '#007397',
      '900': '#004c64',
    },
    AVAILABLE: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#B1E4FF',
      '100': '#7ED3FF',
      '200': '#64CAFF',
      '300': '#4BC2FF',
      '400': '#31B9FF',
      '500': '#432574',
      '600': '#0085CA',
      '700': '#0075B1',
      '800': '#006497',
      '900': '#004264',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#662C91',
      '600': '#4A206A',
      '700': '#3D1A56',
      '800': '#2F1443',
      '900': '#13081C',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default EquatorialConfig;
