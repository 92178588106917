import './styles.css';
import config from 'config';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';

type SelectProps = {
  id: any;
  name: string;
};

type MultiSelectProps = {
  options: Array<SelectProps>;
  setSearchValue: (value: string) => void;
  setSelectedValues: (value: Array<SelectProps>) => void;
  selectedValues: Array<SelectProps>;
  emptyRecordMsg: string;
};

const MultiSelect = ({
  options,
  setSearchValue,
  selectedValues,
  setSelectedValues,
  emptyRecordMsg,
}: MultiSelectProps) => {
  const { t } = useTranslation();
  return (
    <Multiselect
      closeIcon="cancel"
      emptyRecordMsg={emptyRecordMsg}
      options={options}
      selectedValues={selectedValues}
      displayValue="name"
      keepSearchTerm={true}
      placeholder={t('multi-select.input.placeholder')}
      onSelect={(selectList) => setSelectedValues(selectList)}
      onRemove={(selectList) => setSelectedValues(selectList)}
      onSearch={setSearchValue}
      caseSensitiveSearch={false}
      showCheckbox
      avoidHighlightFirstOption={true}
      closeOnSelect={false}
      style={{
        chips: {
          backgroundColor: config.COLORS.PRIMARY_GRAY,
          color: config.COLORS.WHITE,
          borderRadius: '4px',
          fontWeight: 'bold',
        },
        optionContainer: {
          borderRadius: '5px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)',
          border: `1px solid ${config.COLORS.INFO_GRAY}`,
        },
        multiselectContainer: {
          padding: '0px',
        },
        searchBox: {
          padding: '8px 8px',
          borderRadius: '6px',
          fontSize: '14px',
          overflowY: 'auto',
          maxHeight: '100px',
          minHeight: '100px',
        },
        option: {
          color: config.COLORS.PRIMARY_GRAY,
          padding: '10px 10px',
          borderRadius: '0px',
          fontSize: '13px',
          fontWeight: '500',
          alignItems: 'center',
          display: 'flex',
        },
      }}
    />
  );
};

export default MultiSelect;
