import {
  ResetMemberPasswordMutation,
  ResetMemberPasswordMutationVariables,
} from 'generated/graphql';
import resetMemberPasswordMutation from 'graphql/mutations/reset-member-password';
import { useAuthMutation } from 'hooks';
import { ResetPasswordForm } from 'main/reset-password/components/reset-password-form';
import { FormEvent, useState } from 'react';

type Props = {
  resetCode: string;
};

export const MemberResetPasswordForm = ({ resetCode }: Props) => {
  const [password, setPassword] = useState('');

  const [resetMemberPassword, { loading }] = useAuthMutation<
    ResetMemberPasswordMutation,
    ResetMemberPasswordMutationVariables
  >(resetMemberPasswordMutation);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await resetMemberPassword({
      variables: {
        data: {
          password,
          resetCode,
        },
      },
    });
  };

  return (
    <ResetPasswordForm
      password={password}
      isLoading={loading}
      setPassword={setPassword}
      onSubmit={onSubmit}
    />
  );
};
