import styled from 'styled-components';

export const Container = styled.div`
  width: 172px;
  box-sizing: unset;
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  border-radius: 6px;
  height: 290px;
  position: relative;

  box-sizing: unset;
`;

export const ContentHolder = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 23px;

  box-sizing: unset;
`;

export const AlignButtonClose = styled.div`
  display: flex;
  flex: 1;
  height: 12px;
  justify-content: flex-end;
  margin-bottom: 8px;
  cursor: pointer;

  box-sizing: unset;
`;

export const DescriptionContainer = styled.div`
  text-align: center;
  margin: 0px 10px;

  line-height: normal;
  box-sizing: unset;
`;

export const CircleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 22px 21px 29px;

  box-sizing: unset;
`;

export const QuestionMarkButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
`;

export const ContainerPopover = styled.div`
  box-sizing: unset;
`;

export const QuestionMark = styled.span`
  color: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  font-family: ${({ theme }) => theme.FONTS.PRIMARY};
  font-size: 12px;
  cursor: pointer;
`;

export const QuestionMarkCircle = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  border-radius: 16px;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-top: auto;
  margin-left: 5px;
  width: 16px;
  box-sizing: unset;
`;
