import { gql } from '@apollo/client';

export default gql`
  query GetStationOrganizations($where: StationProfileWhereUniqueInput!) {
    stationProfile(where: $where) {
      id
      minimumChargeFee {
        chargeFee
        emspId
      }
      cpo {
        id
      }
      connectors {
        id
        pricingConfig {
          emspId
        }
      }
      emsps {
        id
      }
    }
  }
`;
