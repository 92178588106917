import Typography from 'components/Typography';
import Config from 'config';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FromDateUntilDate, TimeRange } from 'services/timeRange';
import Calendar from './Calendar';
import {
  CalendarIcon,
  ContainerCommonIntervalPick,
  ContainerPersonalizedIntervalPick,
  DropdownOption,
  DropdownSelector,
  DropdownSelectorContainer,
  Icon,
  Wrapper,
} from './styles';

type Props = {
  options: readonly TimeRange[];
  defaultOption: TimeRange;
  format: (t: TimeRange) => string;
  placeholderValue: TimeRange;
  onChange: (key: TimeRange) => void;
  openDirection?: 'top' | 'bottom';
};

export default function DateDropdown({
  options,
  defaultOption,
  format,
  onChange,
  openDirection = 'bottom',
  placeholderValue,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, toggleDropdown] = useState(false);
  const [selectedPeriod, changeSelectedPeriod] =
    useState<TimeRange>(defaultOption);
  const [lastState, setLastState] = useState<TimeRange>(selectedPeriod);
  const [personalizedDate] = useState(
    new FromDateUntilDate(
      t('dropdown.time.periods.personalized-filter.title'),
      t('dropdown.time.periods.personalized-filter.title'),
      'DD/MM/YYYY',
      null,
      null
    )
  );
  const correctOptions = [...options, personalizedDate];
  const isPersonalized = (x: TimeRange) => x === personalizedDate;
  const ContainerIfPersonalized = isPersonalized(selectedPeriod)
    ? ContainerPersonalizedIntervalPick
    : ContainerCommonIntervalPick;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (!wrapperRef.current?.contains(e.target)) {
      toggleDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const iconClicked = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (placeholderValue !== selectedPeriod) {
      toggleDropdown(false);
      e.stopPropagation();
    }
    if (placeholderValue) {
      changeSelectedPeriod(placeholderValue);
      onChange(placeholderValue);
    }
    personalizedDate.unapply();
  };
  const optionClicked = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: TimeRange
  ) => {
    if (
      selectedPeriod.label !==
      t('dropdown.time.periods.personalized-filter.title')
    )
      setLastState(selectedPeriod);
    if (isPersonalized(option)) e.stopPropagation();
    changeSelectedPeriod(option);
    if (option !== personalizedDate) {
      onChange(option);
      personalizedDate.unapply();
    }
  };

  const onSelectDate = (
    from: moment.Moment | null,
    to: moment.Moment | null
  ) => {
    personalizedDate.apply(from, to);
    onChange(personalizedDate);
    setLastState(selectedPeriod);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <DropdownSelectorContainer
        isWide={isPersonalized(selectedPeriod) && lastState === selectedPeriod}
      >
        <DropdownSelector
          data-test="data-change"
          hover={isOpen}
          open={isOpen}
          onClick={() => toggleDropdown((prev) => !prev)}
          numberOfOptions={1}
          index={correctOptions.indexOf(selectedPeriod)}
          openDirection={openDirection}
        >
          <DropdownOption onClick={() => changeSelectedPeriod(selectedPeriod)}>
            <Typography size={14}>
              {format(
                isPersonalized(selectedPeriod) ? lastState : selectedPeriod
              )}
            </Typography>
          </DropdownOption>
          <CalendarIcon />
          <Icon
            hashover={
              `${!(placeholderValue === selectedPeriod)}` as 'true' | 'false'
            }
            as={
              !(placeholderValue === selectedPeriod)
                ? Config.ICONS.X
                : Config.ICONS.PAGINATION_UP
            }
            onClick={iconClicked}
          />
        </DropdownSelector>
      </DropdownSelectorContainer>
      {isOpen && (
        <ContainerIfPersonalized>
          <DropdownSelectorContainer>
            <DropdownSelector
              open={isOpen}
              onClick={() => toggleDropdown((prev) => !prev)}
              numberOfOptions={correctOptions.length}
              index={correctOptions.indexOf(selectedPeriod)}
              openDirection={openDirection}
            >
              {correctOptions.map((option, index) => (
                <DropdownOption
                  key={index}
                  onClick={(e) => optionClicked(e, option)}
                  data-test={index}
                >
                  <Typography size={14}>
                    {isPersonalized(option)
                      ? t('dropdown.time.periods.personalized-filter.title')
                      : format(option)}
                  </Typography>
                </DropdownOption>
              ))}
            </DropdownSelector>
          </DropdownSelectorContainer>
          <Calendar
            defaultValue={selectedPeriod as FromDateUntilDate}
            isVisible={isPersonalized(selectedPeriod)}
            closeCalendar={() => {
              toggleDropdown(false);
            }}
            onSelectDate={onSelectDate}
          />
        </ContainerIfPersonalized>
      )}
    </Wrapper>
  );
}
