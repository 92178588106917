import Typography from 'components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column nowrap;
  grid-template-rows: 6fr 1fr;
  grid-template-areas:
    'chart'
    'footer';
  height: 374px;
`;

export const Label = styled(Typography).attrs({ color: 'SECONDARY_GRAY' })``;

export const ChartContainer = styled.div`
  justify-self: center;
  grid-area: chart;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
  height: 160px;
  width: 160px;
`;

export const ChargingPercentValue = styled(Typography).attrs({
  size: 25,
  weight: 'bold',
  color: 'SECONDARY_GRAY',
})``;

export const ChargingText = styled(Typography).attrs({
  size: 14,
  color: 'SECONDARY_GRAY',
})``;
