import {
  Heading,
  Box,
  Tooltip,
  Flex,
  Text,
  Spacer,
  Skeleton,
  Stack,
  Button,
  useToast,
} from '@chakra-ui/react';
import {
  ChangeStationConfigurationKeyMutation,
  ChangeStationConfigurationKeyMutationVariables,
  StationConfigurationKey,
  StationRfidConfigurationKeysQuery,
  StationRfidConfigurationKeysQueryVariables,
  SynchronizeStationConfigurationKeysMutation,
  SynchronizeStationConfigurationKeysMutationVariables,
} from 'generated/graphql';
import changeStationConfigurationKeyMutation from 'graphql/mutations/changeStationConfigurationKey';
import stationRfidConfigurationKeys from 'graphql/queries/stationRfidConfigurationKeys';
import { useAuthMutation, useAuthQuery } from 'hooks';
import { Icon } from 'new-components/Icon';
import { StationContext } from 'pages/StationPage/StationContext';
import Toast from 'pages/StationPage/Toast';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ErrorComponent from 'new-components/ErrorComponent';
import synchronizeStationConfigurationKeysMutation from 'graphql/mutations/synchronizeStationConfigurationKeys';
import { useTranslation } from 'react-i18next';
import {
  checkDifferenceBetweenObjects,
  getStationConfigurationKeysChanged,
  sortStationConfigurationKeys,
} from '../AdvancedSettings/functions';
import ConfigurationKeyInput from '../components/ConfigurationKeyInput';

const Usage = () => {
  const { t } = useTranslation();
  const { id: stationId } = useContext(StationContext);
  const [toastVisible, setToastVisible] = useState(false);
  const toastOnAnotherPage = useToast();

  const [stationRFIDConfigurationKeys, setStationRFIDConfigurationKeys] =
    useState<StationConfigurationKey[] | []>([]);
  const [
    currentStationRFIDConfigurationKeys,
    setCurrentStationRFIDConfigurationKeys,
  ] = useState<StationConfigurationKey[] | []>([]);

  const resetFields = async () => {
    setStationRFIDConfigurationKeys(currentStationRFIDConfigurationKeys);
    setToastVisible(false);
  };

  const closeToast = useCallback(async () => {
    setToastVisible(false);
    toastOnAnotherPage.closeAll();
  }, [toastOnAnotherPage]);

  const openToast = () => setToastVisible(true);

  const {
    data,
    error,
    loading: loadingQuery,
    refetch,
  } = useAuthQuery<
    StationRfidConfigurationKeysQuery,
    StationRfidConfigurationKeysQueryVariables
  >(stationRfidConfigurationKeys, {
    variables: {
      where: {
        stationId,
      },
    },
  });

  const sortedStationConfigurationKeys = sortStationConfigurationKeys(
    data?.station?.rfidConfigurationKeys ?? []
  );

  useEffect(() => {
    setCurrentStationRFIDConfigurationKeys(sortedStationConfigurationKeys);
    setStationRFIDConfigurationKeys(sortedStationConfigurationKeys);
  }, [sortedStationConfigurationKeys]);

  const [changeStationRFIDConfigurationKey, { loading: loadingChanges }] =
    useAuthMutation<
      ChangeStationConfigurationKeyMutation,
      ChangeStationConfigurationKeyMutationVariables
    >(changeStationConfigurationKeyMutation);

  const [synchronizeStationConfigurationKeys, { loading: loadingSync }] =
    useAuthMutation<
      SynchronizeStationConfigurationKeysMutation,
      SynchronizeStationConfigurationKeysMutationVariables
    >(synchronizeStationConfigurationKeysMutation, {
      variables: {
        where: {
          stationId,
        },
      },
    });

  const handleSynchronizeStationConfigurationKeysButtonPress = async () => {
    try {
      await synchronizeStationConfigurationKeys();
      toast.success(
        t('station-page.config-advanced-tab.toast-successfully-synced-msg')
      );
      await refetch();
    } catch {
      toast.error(t('station-page.config-advanced-tab.toast-sync-error-msg'));
    }
  };

  const changeStationRFIDConfigurationToSpecificKey = async ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    try {
      await changeStationRFIDConfigurationKey({
        variables: {
          key,
          value,
          where: {
            stationId,
          },
        },
      });
      toast.success(
        t('station-page.toast-station-key-successfully-configured.message', {
          key,
        })
      );
      setCurrentStationRFIDConfigurationKeys(stationRFIDConfigurationKeys);
    } catch {
      toast.error(
        t('station-page.toast-station-configuration-key-error.message', {
          key,
        })
      );
    } finally {
      setToastVisible(false);
    }
  };

  const handleSaveChangesButtonPress = async () => {
    const diff = getStationConfigurationKeysChanged({
      stationConfigurationKeys: stationRFIDConfigurationKeys,
      currentStationConfigurationKeys: currentStationRFIDConfigurationKeys,
    });

    for (let i = 0; i < diff.length; i++) {
      const { key, value } = diff[i];
      changeStationRFIDConfigurationToSpecificKey({
        key,
        value,
      });
    }
  };

  useEffect(() => {
    const hasDifference = checkDifferenceBetweenObjects({
      stationConfigurationKeys: stationRFIDConfigurationKeys,
      currentStationConfigurationKeys: currentStationRFIDConfigurationKeys,
    });
    if (hasDifference) {
      openToast();
    } else {
      closeToast();
    }
  }, [
    stationRFIDConfigurationKeys,
    currentStationRFIDConfigurationKeys,
    closeToast,
  ]);

  return (
    <Box p="4" maxWidth="container.md">
      <Box mb="8">
        <Flex justifyContent="space-between">
          <Heading fontSize="2xl">
            {t('station-page.config-tab.usage-tab.header.title')}
          </Heading>
          <Button
            isLoading={loadingSync}
            colorScheme="gray"
            variant="outline"
            fontFamily="heading"
            fontWeight="normal"
            bg="white"
            color="gray.900"
            size="sm"
            leftIcon={
              <Icon type="NEW_REFRESH" size={24} color="PRIMARY_GRAY" />
            }
            onClick={() =>
              handleSynchronizeStationConfigurationKeysButtonPress()
            }
          >
            {t(
              'station-page.config-tab.usage-tab.header.synchronize-btn.title'
            )}
          </Button>
        </Flex>
      </Box>
      <Box mb="4">
        <Flex align="center">
          <Heading fontSize="md">
            {t('station-page.config-tab.usage-tab.header.subtitle')}
          </Heading>
          <Tooltip
            label={t('station-page.config-tab.usage-tab.header.label')}
            hasArrow
            placement="right"
            bg="gray.900"
          >
            <Box ml="2">
              <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
            </Box>
          </Tooltip>
        </Flex>
      </Box>
      <Box
        borderRadius="8"
        borderWidth="thin"
        borderColor="gray.100"
        bg="white"
        width="100%"
        boxShadow="sm"
        position="relative"
      >
        {error ? (
          <Box mt="24" pb="52">
            <ErrorComponent size="large" />
          </Box>
        ) : loadingQuery || loadingSync ? (
          Array(6)
            .fill('')
            .map((_) => (
              <Box p="4" borderBottomColor="gray.100" borderBottomWidth="thin">
                <Skeleton h="10" />
              </Box>
            ))
        ) : (
          <>
            <Box px="4" pt="4">
              <Box mb="1.5">
                <Text fontSize="sm">
                  {t(
                    'station-page.config-tab.usage-tab.card.allow-offline-station-unlock-question'
                  )}{' '}
                  <Text as="strong" fontFamily="heading" fontWeight="normal">
                    {t(
                      'station-page.config-tab.usage-tab.card.action-to-allow.title'
                    )}
                  </Text>{' '}
                  {t('station-page.config-tab.usage-tab.options-text', {
                    numberOfKeys: stationRFIDConfigurationKeys.length,
                  })}
                </Text>
              </Box>
              <Text color="gray.600" fontSize="sm" lineHeight="7">
                {t(
                  'station-page.config-tab.usage-tab.card.allow-offline-station-unlock-description'
                )}
              </Text>
              <Box mt="4" mb="1.5">
                <Text fontSize="sm">
                  {t(
                    'station-page.config-tab.usage-tab.card.not-allow-offline-station-unlock-question'
                  )}{' '}
                  <Text as="strong" fontFamily="heading" fontWeight="normal">
                    {t(
                      'station-page.config-tab.usage-tab.card.action-to-not-allow.title'
                    )}
                  </Text>{' '}
                  {t('station-page.config-tab.usage-tab.options-text', {
                    numberOfKeys: stationRFIDConfigurationKeys.length,
                  })}
                </Text>
              </Box>
              <Text color="gray.600" fontSize="sm">
                {t(
                  'station-page.config-tab.usage-tab.card.not-allow-offline-station-unlock-description'
                )}
              </Text>
            </Box>
            <Spacer mt="4" />
            {stationRFIDConfigurationKeys.map(
              (stationRFIDconfigurationKey, index) => (
                <Box
                  py="4"
                  borderBottomColor="gray.100"
                  borderBottomWidth="thin"
                  key={index}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    px="4"
                  >
                    <Box>
                      <Flex align="center">
                        <Heading fontSize="md">
                          {stationRFIDconfigurationKey.label}
                        </Heading>
                        <Tooltip
                          label={stationRFIDconfigurationKey.description}
                          hasArrow
                          placement="right"
                          bg="gray.900"
                        >
                          <Stack ml="2">
                            <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
                          </Stack>
                        </Tooltip>
                      </Flex>
                      <Box mt="0.5">
                        <Text color="gray.600" fontSize="11px">
                          {stationRFIDconfigurationKey.key}
                        </Text>
                      </Box>
                    </Box>
                    <ConfigurationKeyInput
                      stationConfigurationKey={stationRFIDconfigurationKey}
                      onChangeValue={(value) =>
                        setStationRFIDConfigurationKeys(
                          stationRFIDConfigurationKeys.map(
                            (stationRFIDconfigurationKey, currentIndex) =>
                              currentIndex !== index
                                ? stationRFIDconfigurationKey
                                : {
                                    ...stationRFIDconfigurationKey,
                                    value,
                                  }
                          )
                        )
                      }
                    />
                  </Flex>
                </Box>
              )
            )}
          </>
        )}
      </Box>
      <Toast
        id={'changeStationRFIDConfigurationKeys'}
        resetFields={resetFields}
        onClick={() => handleSaveChangesButtonPress()}
        loading={loadingChanges}
        isVisible={toastVisible}
        inputsValues={[stationRFIDConfigurationKeys]}
      />
    </Box>
  );
};

export default Usage;
