import { Typography } from 'components';
import styled from 'styled-components';

export const Alarms = styled.div`
  border-bottom: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 208px;
  overflow-y: auto;
`;

export const Title = styled(Typography).attrs({
  size: 12,
  weight: 'bold',
})``;

export const LoaderWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: center;
`;

export const InfiniteScrollEnd = styled(Typography).attrs({
  size: 12,
  color: 'SECONDARY_GRAY',
})`
  margin: 16px 0 24px;
  display: block;
  text-align: center;
`;

export const List = styled.div`
  overflow-y: scroll;
`;

export const AlarmContainer = styled.div`
  padding-bottom: 12px;
`;
