import Config from 'config';
import ConfigType from 'config/typing';
import styled from 'styled-components';

interface Props {
  color: keyof ConfigType['COLORS'];
  size?: number;
}

const Loader = styled(Config.ICONS.SPINNER)<Props>`
  align-self: center;
  width: ${({ size }) => `${size || 38}px`};
  height: ${({ size }) => `${size || 38}px`};
  color: ${({ theme, color }) =>
    color ? theme.COLORS[color] : theme.COLORS.PRIMARY_GRAY};
`;

export default Loader;
