import Config from 'config';
import {
  OrderStatus,
  RouterConnectivityStatus,
  SessionStatus,
  StationConnectivityStatus,
  StationStatus,
} from 'generated/graphql';
import { Icon, NotNestedNewIcons } from 'new-components/Icon';
import React from 'react';
import {
  ColorOptions,
  Container,
  IconContainer,
  TagContainer,
  Text,
} from './styles';

const mapOrderStatusToTagColors: Record<OrderStatus, NewTagColors> = {
  Captured: 'success',
  Subsidized: 'success',
  PreAuthorized: 'default',
  Error: 'danger',
  ErrorOnPreAuthorization: 'danger',
  Cancelled: 'warning',
  NoCost: 'default',
};

const mapSessionStatusToTagColors: Record<SessionStatus, NewTagColors> = {
  CHARGING: 'charging',
  ERROR: 'danger',
  FINISHED: 'default',
  TIMED_OUT: 'danger',
  WAITING_PLUG: 'default',
  WAITING_UNPLUG: 'default',
};

export const mapStationStatusToTagColors: Record<StationStatus, NewTagColors> =
  {
    AVAILABLE: 'success',
    CHARGING: 'charging',
    INOPERATIVE: 'danger',
    UNKNOWN: 'default',
    PLANNED: 'information',
    RESERVED: 'information',
  };

const mapConnectivityStatusToTagColors: Record<
  /* Station and Modem has the same status at this moment */
  StationConnectivityStatus,
  NewTagColors
> = {
  ONLINE: 'success',
  OFFLINE: 'default',
};

type NewTagTypes = 'icon' | 'text' | 'icon-text';
type NewTagSizes = 'inline' | 'default' | 'big';
export type StatusTypes =
  | OrderStatus
  | SessionStatus
  | StationStatus
  | RouterConnectivityStatus
  | StationConnectivityStatus;
export type NewTagColors = keyof typeof ColorOptions;
export type NewTagProps = {
  type: NewTagTypes;
  /** The default tag size is 'default' */
  size?: NewTagSizes;
  /** The default tag color is 'default' */
  color?: NewTagColors;
  status?: StatusTypes;
} & (
  | {
      type: 'icon';
      icon: keyof NotNestedNewIcons;
    }
  | {
      type: 'text';
      /** The text must be rendered as a children  */
      children: React.ReactNode;
    }
  | {
      type: 'icon-text';
      /** The text must be rendered as a children  */
      children: React.ReactNode;
      icon: keyof NotNestedNewIcons;
    }
);
const NewTag: React.FC<NewTagProps> = ({
  size = 'default',
  color = 'default',
  status,
  ...props
}) => {
  const getTagColor = () => {
    if (Config.LABELS.STATION_STATUS[status as StationStatus])
      return ColorOptions[mapStationStatusToTagColors[status as StationStatus]];
    if (Config.LABELS.MODEM_STATUS[status as StationConnectivityStatus])
      return ColorOptions[
        mapConnectivityStatusToTagColors[status as StationConnectivityStatus]
      ];
    if (Config.LABELS.ORDER_STATUS[status as OrderStatus])
      return ColorOptions[mapOrderStatusToTagColors[status as OrderStatus]];
    if (Config.LABELS.SESSION_STATUS[status as SessionStatus])
      return ColorOptions[mapSessionStatusToTagColors[status as SessionStatus]];
    return ColorOptions[color];
  };
  return (
    <Container>
      <TagContainer color={getTagColor()} size={size}>
        {props.type !== 'text' && (
          <IconContainer>
            <Icon type={props.icon} color="WHITE" size={12} />
          </IconContainer>
        )}
        {props.type !== 'icon' && size !== 'inline' && (
          <Text props={size}>{props.children}</Text>
        )}
      </TagContainer>
      {props.type !== 'icon' && size === 'inline' && (
        <Text props={size}>{props.children}</Text>
      )}
    </Container>
  );
};
export { NewTag };
