import { typographyStyle } from 'components/Typography';
import Config from 'config';
import styled, { css } from 'styled-components';

type Direction = 'up' | 'down' | 'left' | 'right';

const getDegrees = ({ direction }: { direction: Direction }) => {
  switch (direction) {
    case 'up':
      return 0;
    case 'down':
      return 180;
    case 'right':
      return 90;
    case 'left':
      return 270;
  }
};

export const Chevron = styled(Config.ICONS.CHEVRON)<{ direction: Direction }>`
  transform: rotate(${getDegrees}deg);
  height: 11px;
`;

export const HeaderCell = styled.th.attrs({
  weight: 'bold',
  color: 'SECONDARY_GRAY',
  size: 14,
})`
  ${typographyStyle}
  background-color: ${(props) => props.theme.COLORS.BACKGROUND};
  height: 45px;
  text-align: center;
  vertical-align: middle;
  padding: 0 10px;
`;

export const HeaderInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderRow = styled.tr`
  ${HeaderCell}:last-child {
    div {
      justify-content: flex-end !important;
    }
    text-align: center;
    padding-right: 20px;
  }
  ${HeaderCell}:nth-child(1) {
    div {
      justify-content: flex-start !important;
    }
    text-align: left;
    padding-left: 20px;
  }
`;

export const Cell = styled.td`
  padding: 7px 10px;
  text-align: center;
  height: 50px;
  ${typographyStyle}
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  box-sizing: unset;
`;

export const Row = styled.tr<{ clickable?: boolean; selected?: boolean }>`
  justify-content: center;

  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border: solid;
  border-width: 1px 0;
  border-color: #cdd7d6;
  ${Cell}:first-child {
    text-align: left;
    padding-left: 20px;
    overflow: hidden;
  }
  ${Cell}:last-child {
    margin-left: auto;
    padding-right: 20px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // This needed because column has a inline max width prop, it's a workaround using current table :c
    max-width: max-content !important;
  }

  background-color: ${({ theme, selected }) =>
    selected && theme.COLORS.BACKGROUND};

  &:hover {
    background-color: ${({ theme, clickable }) =>
      clickable ? theme.COLORS.BACKGROUND : 'initial'};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

// scrollbar-etc properties are supported in mozilla only
// if you wish to change chrome's scrollbar only use -webkit-scrollbar properties instead.
export const TableContainer = styled.div`
  overflow-y: visible;
  overflow-y: auto;
  max-height: 100%;
`;

export const TableBody = styled.tbody`
  flex-direction: column;

  ${Row}:first-child {
    border-top: none;
  }

  ${Row}:last-child {
    border-bottom: none;
  }
`;

interface ContainerProps {
  top?: number;
  size: number;
  position?: string;
}

export const BlankStateContainer = styled.div<ContainerProps>`
  position: absolute;
  width: 300px;
  top: 250px;
  ${({ size, top }) =>
    top &&
    css`
      left: calc(45% - ${size / 2}px);
      right: calc(50% - ${size / 2}px);
    `}
  > *:first-child {
    // we use top != null to check if top is different than undefined AND null.
    ${({ top }) =>
      top != null ? `margin-top: ${top}px` : `align-items: center`};
  }
`;
