import { gql } from '@apollo/client';
import alarmLabelFieldsFragment from 'graphql/fragments/alarmLabelFields';

export default gql`
  query TableStationEvents(
    $where: StationWhereUniqueInput!
    $limit: Int
    $offset: Int
    $filter: StreamEventFilterInput
  ) {
    station(where: $where) {
      id
      events(
        sort: { streamVersion: DESC }
        limit: $limit
        offset: $offset
        filter: $filter
      ) {
        streamId
        streamVersion
        type
        timestamp
        data
        severity
        alarm {
          ...AlarmLabelFields
        }
      }
      eventsSummary(filter: $filter) {
        count
      }
    }
  }
  ${alarmLabelFieldsFragment}
`;
