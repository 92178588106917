import { FilterTerm, Filters } from 'types';
import FilterPanel, { SetFiltersTermsProps } from './FilterPanel';
import FilterButton from './FilterButton';

type Props<U extends string> = {
  filters: Filters<U>;
  toggleFilterPanel: () => void;
  active: boolean;
  setAppliedFilterTerms: SetFiltersTermsProps<U>;
  appliedFilterTerms: FilterTerm<U>[];
};

const Filter = <U extends string>({
  filters,
  toggleFilterPanel,
  active,
  setAppliedFilterTerms,
  appliedFilterTerms,
}: Props<U>) => (
  <>
    <FilterButton toggleFilterPanel={toggleFilterPanel} active={active} />
    {active && (
      <FilterPanel
        filters={filters}
        closeFilter={toggleFilterPanel}
        setAppliedFilterTerms={setAppliedFilterTerms}
        appliedFilterTerms={appliedFilterTerms}
      />
    )}
  </>
);

export default Filter;
