import styled, { css } from 'styled-components';

type ContainerProps = {
  justifyFlexEnd: boolean;
};

export const NAVBAR_HEIGHT = '45px';

export const Container = styled.div<ContainerProps>`
  ${({ justifyFlexEnd }) => css`
    display: flex;
    align-items: center;
    justify-content: ${justifyFlexEnd ? 'flex-end' : 'space-between'};
    height: ${NAVBAR_HEIGHT};
    min-height: ${NAVBAR_HEIGHT};
    width: 100%;
    box-shadow: 0px 2px 6px #0000000a;
    background: ${({ theme }) => theme.COLORS.WHITE};
    z-index: 1;
  `}
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: 45px;
  padding: 0px 12px 0px 12px;
  height: 29px;
  border-radius: 9px;
  &:hover {
    background: ${({ theme }) => theme.COLORS.BACKGROUND};
    cursor: pointer;
  }
`;

export const LogoutMenu = styled.div`
  width: 92px;
  height: 45px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
`;
export const LogoutMenuContainer = styled.div`
  width: 94px;
  height: 47px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
`;

export const LogoutButton = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  &:hover {
    background: ${({ theme }) => theme.COLORS.BACKGROUND};
    cursor: pointer;
  }
`;

export const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
`;

export const OptionsContainer = styled.div`
  height: 16px;
  margin-left: 15px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
`;
