import styled, { css } from 'styled-components';

export const Container = styled.div<{ minWidth?: number }>`
  ${({ minWidth }) =>
    minWidth
      ? css`
          min-width: ${minWidth}px;
        `
      : ''}
  background: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-radius: 8px;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    border-radius: 16px;
    border: 0px solid;
  }
`;

export const Section = styled.div`
  padding: 4px 0px;

  :not(:first-child) {
    border-top: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  }
`;

export const Row = styled.button<{ active?: boolean }>`
  border: none;
  background: transparent;
  width: 100%;
  > h5 {
    border: 5px solid red;
    padding: 50px;
  }
  ${({ active }) =>
    active
      ? css`
          cursor: pointer;
          :hover {
            background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
          }
        `
      : css`
          pointer-events: none;
        `}
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RowLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderIconContainer = styled.div<{
  justify?: 'flex-start' | 'flex-end';
}>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-items: ${({ justify }) => justify ?? 'center'};
  align-content: center;
`;

export const ChevronLeftButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  }
`;

export const HeaderGridContainer = styled.div<{
  gridColumns?: string;
  gridAreas?: string;
}>`
  display: grid;
  grid-template-columns: ${({ gridColumns }) =>
    gridColumns ?? '1fr 1fr 1fr 1fr'};
  ${({ gridAreas }) =>
    gridAreas
      ? css`
          grid-template-areas: ${gridAreas.toString()}px;
        `
      : css`
          grid-template-areas: 'icon title title .';
        `}
  justify-content: center;
  align-items: center;
`;

export const TriggerButtonContainer = styled.button<{
  active: boolean;
  isDisabled?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px;

  cursor: pointer;
  background-color: transparent;
  border: none;

  :hover {
    background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  }
  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
        `
      : ''}

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.3;
          cursor: not-allowed !important;
        `
      : ''}
`;
