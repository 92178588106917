import React, { useState, useContext } from 'react';
import { ButtonContainer } from 'cards/StationTable/styles';
import { Typography } from 'components';
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import ExportButton from 'components/ExportButton';
import { userIdMatcher } from 'components/Filter';
import Search from 'atomic-components/molecules/Search';
import Table, { Column } from 'components/Table';
import { AllDriversQuery, AllDriversQueryVariables } from 'generated/graphql';
import allDrivers from 'graphql/queries/allDrivers';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import DriverModal from 'new-components/DriverModal';
import RechargeModal from 'new-components/RechargeModal';
import { useExportClients } from 'services/export/clients';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth/context';
import { formatOrgId } from 'services/format';
import { parseSearchValue } from '../functions';
import {
  Container,
  ExportButtonContainer,
  TableContainer,
  TitleContainer,
} from './styles';

type Driver = AllDriversQuery['drivers'][number];

const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const Clients: React.FC = () => {
  const { t } = useTranslation();
  const { orgNameMap } = useContext(AuthContext);
  const columns: Column<Driver>[] = [
    {
      Header: t('consumers.users.table.id.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { label },
        },
      }) => <Typography>{label}</Typography>,
    },
    {
      Header: t('profile-dropdown.organization-section.singular-title'),
      disableSortBy: false,
      disableFilters: false,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            emspId,
          },
        },
      }) => <>{formatOrgId(emspId, orgNameMap)}</>,
    },
    {
      Header: t('consumers.users.table.nome.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            profile: { firstName, lastName },
          },
        },
      }) => (
        <Typography>
          {firstName && lastName ? `${firstName} ${lastName}` : '-'}
        </Typography>
      ),
    },
    {
      Header: t('consumers.users.table.email.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            profile: { email },
          },
        },
      }) => <Typography>{email || '-'}</Typography>,
    },
  ];
  const hasScopes = useHasScopes();
  const [{ pageIndex, pageSize }, setPagination] = useState(defaultPagination);
  const [searchValue, setSearchValue] = useState('');
  const [driverModal, setDriverModal] = useState<{ show: boolean; id: number }>(
    {
      show: false,
      id: 0,
    }
  );
  const [rechargeModal, setRechargeModal] = useState<{
    show: boolean;
    sessionId: string;
    cpoId: string;
    emspId: string;
  }>({ show: false, sessionId: '', cpoId: '', emspId: '' });

  const exportClients = useExportClients();

  const { data, error, loading } = useAuthQuery<
    AllDriversQuery,
    AllDriversQueryVariables
  >(allDrivers, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      search: parseSearchValue(searchValue, userIdMatcher),
    },
  });

  const handleDriverRowClick = (id: number) =>
    hasScopes(['driver:read']) && setDriverModal({ id, show: true });
  const handleDriverModalClose = () =>
    hasScopes(['driver:read']) &&
    setDriverModal({ ...driverModal, show: false });
  const handleSessionRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) => {
    if (!hasScopes(['driver:read'])) return;
    if (!sessionId) {
      alert(t('consumers.there-is-no-recharge-session-error'));
    } else {
      setRechargeModal({
        show: true,
        sessionId,
        cpoId: cpoId || '',
        emspId: emspId || '',
      });
    }
  };

  const handleRechargeModalClose = () =>
    setRechargeModal({ ...rechargeModal, show: false });

  return (
    <Container>
      {hasScopes(['driver:read']) && driverModal.show && (
        <DriverModal
          handleSessionRowClick={handleSessionRowClick}
          id={driverModal.id}
          show={driverModal.show}
          onClose={handleDriverModalClose}
        />
      )}
      <RechargeModal
        onClose={handleRechargeModalClose}
        sessionId={rechargeModal.sessionId}
        show={rechargeModal.show}
        cpoId={rechargeModal.cpoId}
        emspId={rechargeModal.emspId}
      />
      <Card minHeight={841} error={error} type="complex">
        <CardHeader>
          <TitleContainer>
            <CardTitle title={t('customers.users-card.title')} />
          </TitleContainer>
          <ButtonContainer>
            {hasScopes(['driver:export']) && (
              <ExportButtonContainer>
                <ExportButton onClickToExport={exportClients} />
              </ExportButtonContainer>
            )}
            <Box mr="9">
              <Search
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={t('search-driver-input.title')}
              />
            </Box>
          </ButtonContainer>
        </CardHeader>

        <TableContainer>
          <Table<Driver>
            paginated
            columns={columns}
            data={data?.drivers.filter((account) => account !== null) ?? []}
            searchValue={searchValue}
            fetchData={setPagination}
            pageCount={Math.ceil((data?.driversSummary.count ?? 0) / 25)}
            onRowPress={({ original: { id } }) => handleDriverRowClick(id || 0)}
            loading={loading}
          />
        </TableContainer>
      </Card>
    </Container>
  );
};

export default Clients;
