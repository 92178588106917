import { Button as ChakraButton } from '@chakra-ui/react';
import React from 'react';

type ContainerButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  variant?: 'link';
  disabled?: boolean;
};

const Button: React.FC<ContainerButtonProps> = ({
  children,
  onClick,
  size,
  variant,
  disabled,
}) => (
  <ChakraButton
    variant={variant}
    size={size}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </ChakraButton>
);
export default Button;
