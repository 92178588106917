import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { theme as chakraTheme } from 'chakra/theme';
import AuthProvider from 'contexts/Auth/provider';
import { createClient } from 'graphql/client';
import Head from 'Head';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import { BrowserRouter, Route } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import defaultTheme from 'theme/theme';
import { Provider } from 'urql';
import '../../react-app-env.d';
import resources from 'translation/index';
import ToastContainer from 'components/ToastContainer';
import { useState } from 'react';
import { useURLParams } from 'hooks/useURLParam';
import GlobalStyles from 'styles';
import { AccountExclusionProvider } from './contexts/account-exclusion';
import { AccountExclusionLogin } from './pages/account-exclusion-login';
import { RequestAccountExclusion } from './pages/request-account-exclusion';
import { Header } from './components/header';
import { AccountExclusionIntroduction } from './pages/account-exclusion-introduction';
import { RequestDriverPasswordReset } from './pages/request-driver-password-reset';

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

const AccountExclusionApp = () => {
  const urlParam = useURLParams();
  const lng = urlParam.get('language') ?? undefined;

  i18n.use(initReactI18next).init({
    lng,
    resources,
    fallbackLng: 'pt_BR',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

  const [client, setClient] = useState(createClient());
  const resetClient = () => setClient(createClient());

  return (
    <Provider value={client}>
      <Head />
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <ToastContainer />
          <AuthProvider resetClient={resetClient}>
            <BrowserRouter>
              <AccountExclusionProvider>
                <Header />
                <Flex flexDirection="column" h="full" w="full">
                  <Box
                    maxW="container.md"
                    margin="auto"
                    h="full"
                    w="full"
                    px={6}
                  >
                    <Route
                      component={AccountExclusionIntroduction}
                      path="/"
                      exact
                    />
                    <Route
                      component={AccountExclusionLogin}
                      path="/account-exclusion-login"
                      exact
                    />
                    <Route
                      component={RequestDriverPasswordReset}
                      path="/request-driver-password-reset"
                      exact
                    />

                    <Route
                      component={RequestAccountExclusion}
                      path="/request-account-exclusion"
                      exact
                    />
                  </Box>
                </Flex>
              </AccountExclusionProvider>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </ChakraProvider>
    </Provider>
  );
};
export default AccountExclusionApp;
