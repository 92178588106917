import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const StellantisConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'ev',
  LABEL: 'Stellantis',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#243882',
    CLICKABLE: '#1A2759',
    ERROR: '#F87060',
    SIDEBAR: '#243882',
    LOGIN_LOGO_BACKGROUND: '#243882',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#1A2759',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#243882',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91',

    PREPARING: '#243882',
    FINISHING: '#243882',

    SELECTED_DATE_BOUND_BACKGROUND: '#B9AF22',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#A39A1E',

    DATE_PRE_SELECTED_BACKGROUND: '#CFC425',
    DATE_PRE_SELECTED_BORDER: '#DFD648',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#E4DB5D',
    DATE_PRE_SELECTED_COLOR: '#A39A1E',

    DATE_SELECTED_BACKGROUND: '#CFC425',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#B9AF22',

    CURRENT_DAY: '#243882',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#243882',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#662C91',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#243882',
      CHARGING: '#243882',
      FINISHING: '#243882',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },

    BILLING: '#04E762',
    RECHARGES: '#243882',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#243882',
    BUTTON_UNAVAILABLE: '#3351C0',
    BUTTON_LOADING: '#2E49AB',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#243882',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#B9AF22',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#A39A1E',

      DATE_PRE_SELECTED_BACKGROUND: '#CFC425',
      DATE_PRE_SELECTED_BORDER: '#DFD648',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#E4DB5D',
      DATE_PRE_SELECTED_COLOR: '#A39A1E',

      DATE_SELECTED_BACKGROUND: '#CFC425',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#B9AF22',

      CURRENT_DAY: '#243882',
    },
  },
  FONTS: {
    PRIMARY: 'Verdana-Regular',
    SECONDARY: 'Verdana-Bold',
    TERTIARY: 'Verdana-Regular',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Stellantis - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/stellantis',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#3F5DCD',
      '100': '#3351C0',
      '200': '#2E49AB',
      '300': '#294096',
      '400': '#2C4396',
      '500': '#243882',
      '600': '#1A2759',
      '700': '#141F45',
      '800': '#0F1631',
      '900': '#090D1E',
    },
    LINK: {
      '50': '#3F5DCD',
      '100': '#3351C0',
      '200': '#2E49AB',
      '300': '#294096',
      '400': '#2C4396',
      '500': '#243882',
      '600': '#1A2759',
      '700': '#141F45',
      '800': '#0F1631',
      '900': '#090D1E',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#3F5DCD',
      '100': '#3351C0',
      '200': '#2E49AB',
      '300': '#294096',
      '400': '#2C4396',
      '500': '#243882',
      '600': '#1A2759',
      '700': '#141F45',
      '800': '#0F1631',
      '900': '#090D1E',
    },
    AVAILABLE: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#E8E072',
      '100': '#E4DB5D',
      '200': '#DFD648',
      '300': '#DBD032',
      '400': '#D8CD28',
      '500': '#CFC425',
      '600': '#B9AF22',
      '700': '#A39A1E',
      '800': '#8D851B',
      '900': '#777117',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#662C91',
      '600': '#4A206A',
      '700': '#3D1A56',
      '800': '#2F1443',
      '900': '#13081C',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default StellantisConfig;
