import { Typography } from 'components';
import Config from 'config';
import styled from 'styled-components';

export const Information = styled.div`
  border-bottom: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled(Typography).attrs({
  size: 16,
  weight: 'bold',
})`
  margin-bottom: 6px;
`;

export const Code = styled(Typography).attrs({
  size: 14,
  color: 'SECONDARY_GRAY',
})`
  font-family: ${Config.FONTS.TERTIARY};
`;
