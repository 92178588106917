export const formatCellPhone = (phoneNumber?: string) => {
  if (!phoneNumber) return;
  const numbers = phoneNumber.replace(/\D/g, '');
  const char: any = { 0: '(', 2: ') ', 3: ' ', 7: '-' };
  let r = '';
  for (let i = 0; i < numbers.length; i++) {
    r += (char[i] || '') + numbers[i];
  }
  return r.substring(0, 16);
};
