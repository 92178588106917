import React, { useState, useContext } from 'react';
import { ButtonContainer } from 'cards/StationTable/styles';
import { Typography } from 'components';
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
import CardTitle from 'components/Card/CardHeader/CardTitle';
import ExportButton from 'components/ExportButton';
import Search from 'atomic-components/molecules/Search';
import Table, { Column } from 'components/Table';
import { AllVehiclesQuery, AllVehiclesQueryVariables } from 'generated/graphql';
import allVehicles from 'graphql/queries/allVehicles';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import RechargeModal from 'new-components/RechargeModal';
import { useExportVehicles } from 'services/export/vehicles';
import VehicleModal from 'new-components/VehicleModal';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import { parseSearchValue } from '../functions';
import {
  Container,
  ExportButtonContainer,
  TableContainer,
  TitleContainer,
} from './styles';

type Vehicle = AllVehiclesQuery['vehicles'][number];

const vehicleIdMatcher = /[V,v][E,e] ([0-9]+)/;

const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const Vehicles: React.FC = () => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const [{ pageIndex, pageSize }, setPagination] = useState(defaultPagination);
  const [searchValue, setSearchValue] = useState('');
  const [vehicleModal, setVehicleModal] = useState<{
    show: boolean;
    id: number;
  }>({
    show: false,
    id: 0,
  });
  const [rechargeModal, setRechargeModal] = useState<{
    show: boolean;
    sessionId: string;
    cpoId: string;
    emspId: string;
  }>({ show: false, sessionId: '', cpoId: '', emspId: '' });
  const { orgNameMap } = useContext(AuthContext);

  const columns: Column<Vehicle>[] = [
    {
      Header: t('consumers.vehicles.table.id.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          // @ts-ignore
          original: { label },
        },
      }) => <Typography>{label}</Typography>,
    },
    {
      Header: t('profile-dropdown.organization-section.singular-title'),
      disableSortBy: false,
      disableFilters: false,
      Cell: ({
        row: {
          // @ts-ignore
          original: { emspId },
        },
      }) => <>{formatOrgId(emspId, orgNameMap)}</>,
    },
    {
      Header: t('consumers.vehicles.table.plate.title'),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            // @ts-ignore
            plate,
          },
        },
      }) => <Typography>{plate}</Typography>,
    },
  ];

  const exportVehicles = useExportVehicles();

  const { data, error, loading } = useAuthQuery<
    AllVehiclesQuery,
    AllVehiclesQueryVariables
  >(allVehicles, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      search: parseSearchValue(searchValue, vehicleIdMatcher),
    },
  });

  const handleVehicleRowClick = (id: number) =>
    setVehicleModal({ id, show: true });

  const handleVehicleModalClose = () =>
    setVehicleModal({ ...vehicleModal, show: false });

  const handleSessionRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) => {
    if (!sessionId) {
      alert(t('consumers.there-is-no-recharge-session-error'));
    } else {
      setRechargeModal({
        show: true,
        sessionId,
        cpoId: cpoId || '',
        emspId: emspId || '',
      });
    }
  };

  const handleRechargeModalClose = () =>
    setRechargeModal({ ...rechargeModal, show: false });

  return (
    <Container>
      {hasScopes(['vehicle:read']) && vehicleModal.show && (
        <VehicleModal
          handleSessionRowClick={handleSessionRowClick}
          id={vehicleModal.id}
          show={vehicleModal.show}
          onClose={handleVehicleModalClose}
        />
      )}
      <RechargeModal
        onClose={handleRechargeModalClose}
        sessionId={rechargeModal.sessionId}
        show={rechargeModal.show}
        cpoId={rechargeModal.cpoId}
        emspId={rechargeModal.emspId}
      />
      <Card minHeight={841} error={error} type="complex">
        <CardHeader>
          <TitleContainer>
            <CardTitle title={t('customers.vehicles-card.title')} />
          </TitleContainer>
          <ButtonContainer>
            {hasScopes(['vehicle:export']) && (
              <ExportButtonContainer>
                <ExportButton onClickToExport={exportVehicles} />
              </ExportButtonContainer>
            )}
            <Box mr="9">
              <Search
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={t('search-vehicle-input.title')}
              />
            </Box>
          </ButtonContainer>
        </CardHeader>

        <TableContainer>
          <Table<Vehicle>
            paginated
            columns={columns}
            data={data?.vehicles.filter((account) => account !== null) ?? []}
            searchValue={searchValue}
            fetchData={setPagination}
            pageCount={Math.ceil((data?.vehiclesSummary.count ?? 0) / 25)}
            onRowPress={({ original: { id } }) =>
              handleVehicleRowClick(id || 0)
            }
            loading={loading}
          />
        </TableContainer>
      </Card>
    </Container>
  );
};

export default Vehicles;
