import Loader from 'components/Loader';
import React from 'react';
import { Container } from './styles';

interface Props {
  onClick: () => void;
  visual?: 'primary' | 'secondary';
  disabled?: boolean;
  loading?: boolean;
  mouseoverDescription?: string;
  hasActiveState?: boolean;
}

const SquareButton: React.FC<Props> = ({
  onClick,
  disabled,
  loading,
  children,
  visual = 'primary',
  mouseoverDescription,
  hasActiveState = false,
  ...props
}) => (
  <Container
    onClick={() => disabled || onClick()}
    visual={visual}
    title={mouseoverDescription}
    active={hasActiveState}
    {...props}
  >
    {loading ? <Loader color="SECONDARY_GRAY" size={20} /> : children}
  </Container>
);
export default SquareButton;
