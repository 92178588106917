import Config from 'config';
import {
  Address,
  ExportedSessionFieldsFragment,
  Station,
} from 'generated/graphql';
import { t } from 'i18next';
import {
  formatOrgId,
  formatCurrency,
  formatEnergy,
  formatPower,
} from 'services/format';
import { CurrencyType } from 'types';
import { TranslationKeyType } from 'types/translation';
import { getOrganizationDefaultCurrencyType } from './getOrganizationDefaultCurrencyType';

export type FormattedSession = {
  id: string;

  consumerLabel: string;
  stationId: string;
  stationName: string;

  date: string;
  begin: string;
  end: string;

  energyConsumed: string;
  duration: string;

  status: string;
  edited: boolean;

  // /** DEPRECATED: use orderGrossValue */
  // revenue: string;

  orderId: string;
  orderDate: string;
  orderTotalCost: string;
  orderNetValue: string;

  connectorType: string;
  connectorId: number;
  connectorPower: string;

  externalDriverId: string;
  unlockType: 'Cartão' | 'Aplicativo';

  emspId: string;
  cpoId: string;
};

export const convertSecondsToDuration = (rawSeconds: number) => {
  const rawMinutes = rawSeconds / 60;
  const rawHours = rawMinutes / 60;
  return {
    seconds: Math.floor(rawSeconds % 60),
    minutes: Math.floor(rawMinutes % 60),
    hours: Math.floor(rawHours),
  };
};

export type SessionFormatInput = ExportedSessionFieldsFragment & {
  stationId: string;
  stationProfile?: Pick<Station, 'name' | 'id'> & {
    address?: Pick<Address, 'state' | 'city'>;
  };
  emspId?: string;
  cpoId?: string;
};

export const formatSessionFields = (
  {
    duration,
    energyConsumed,
    startedChargingAt,
    createdAt,
    finishedAt,
    edited,
    stationId,
    stationProfile,
    connector,
    driver,
    remote,
    status,
    consumerLabel,
    id,
    order,
    emspId,
    cpoId,
  }: Partial<SessionFormatInput> & { defaultCurrencyType?: CurrencyType },
  orgNameMap: { [key: string]: string },
  defaultCurrencyTypes: { orgId: string; currencyType?: CurrencyType }[]
): Partial<FormattedSession> => {
  // there are some sessions in the backend that
  // have negative duration, it is a bug in the backend
  // that somehow happens.
  // that's why there's the Math.max
  const convertedDuration = convertSecondsToDuration(
    Math.max(duration || 0, 0)
  );
  const beginTimestamp = startedChargingAt || createdAt;
  return {
    consumerLabel: consumerLabel ?? '-',
    cpoId: cpoId ? formatOrgId(cpoId, orgNameMap) : '-',
    emspId: emspId ? formatOrgId(emspId, orgNameMap) : '-',
    date: t('format-session-row.date', {
      value: beginTimestamp ? new Date(beginTimestamp) : '-',
    }),
    begin: t('format-session-row.begin', {
      value: beginTimestamp ? new Date(beginTimestamp) : '-',
    }),
    end: finishedAt
      ? t('format-session-row.end', {
          value: new Date(finishedAt),
        })
      : '-',
    edited,
    energyConsumed: formatEnergy(energyConsumed || 0),
    duration: `${convertedDuration.hours}:${String(
      convertedDuration.minutes
    ).padStart(2, '0')}:${String(convertedDuration.seconds).padStart(2, '0')}`,
    id: id || '',

    orderId: order?.orderId ?? '-',
    orderDate: order?.capturedAt
      ? t('format-session-row.order-date', {
          value: new Date(order.capturedAt),
        })
      : '-',
    orderTotalCost: formatCurrency(order?.totalCost, {
      currencyType:
        order?.currencyType ??
        getOrganizationDefaultCurrencyType(defaultCurrencyTypes, emspId),
    }),
    orderNetValue: formatCurrency(order?.netValue, {
      currencyType:
        order?.currencyType ??
        getOrganizationDefaultCurrencyType(defaultCurrencyTypes, emspId),
    }),

    status,

    connectorId: connector?.id,
    connectorType: `Conector ${`${connector?.id}`.padStart(2, '0')} - ${
      connector?.currentType
    }-${Config.LABELS.CONNECTOR_TYPES[connector?.type!]}`,
    connectorPower: formatPower(connector?.power),
    externalDriverId: driver?.externalUid,
    unlockType: remote ? 'Aplicativo' : 'Cartão',
    stationId: stationProfile?.id ?? stationId,
    stationName: stationProfile?.name ?? stationId,
  };
};

// SESSION TABLE CARD
export const sessionFieldLabelTable: Record<
  keyof Partial<FormattedSession>,
  TranslationKeyType
> = {
  consumerLabel: 'history.session-table.consumer-label.title',
  emspId: 'history.session-table.emsp.title',
  stationName: 'history.session-table.station-name.title',
  cpoId: 'history.session-table.cpo.title',

  energyConsumed: 'history.session-energy.consumed.title',
  duration: 'history.session-table.duration.title',

  date: 'history.session-table.date.title',
  begin: 'history.session-table.begin.title',
  end: 'history.session-table.end.title',

  id: 'history.session-table.id.title',
  orderDate: 'history.session-table.order-date.title',
  orderId: 'history.session-table.order-id.title',
  orderTotalCost: 'history.session-table.order-total-cost.title',
  orderNetValue: 'history.session-table.order-net-value.title',

  status: 'history.session-table.status.title',
  edited: 'history.session-table.edited.title',

  connectorPower: 'history.session-table.connector-power.title',
  connectorId: 'history.session-table.connector-id.title',
  connectorType: 'history.session-table.connector-type.title',
  externalDriverId: 'history.session-table.external-driver-id.title',
  stationId: 'history.session-table.station-id.title',
  unlockType: 'history.session-table.unlock-type.title',
};

export const getSessionFieldLabel = (key: keyof FormattedSession): string =>
  sessionFieldLabelTable[key];

export const formatSessionFieldLabel = (session: Partial<FormattedSession>) =>
  Object.fromEntries(
    Object.entries(session).map(([key, value = '-']) => [
      getSessionFieldLabel(key as keyof Partial<FormattedSession>),
      value,
    ])
  );
