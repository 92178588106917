import styled from 'styled-components';

export const FilterTermsContainer = styled.div`
  display: flex;
  height: 66px;
  align-items: center;
  flex-flow: row nowrap;
  flex: 1 1 0;
  margin-right: 15px;
  margin-left: 15px;
  overflow-x: auto;
  scrollbar-color: #aaa transparent;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #aaa;
    }
    ::-webkit-scrollbar-track: {
      background: #fff;
    }
    scrollbar-width: auto;
  }
`;
