import { formatCurrencyType } from 'services/format';
import { CurrencyType } from 'types';
import Typography, { Props as TypographyProps } from 'components/Typography';

type Props = {
  currencyType?: CurrencyType;
} & TypographyProps;

const CurrencyTypeIcon: React.FC<Props> = ({
  currencyType,
  color = 'LIGHT_GRAY',
}: Props) => {
  const formatedCurrencyType = formatCurrencyType({ currencyType });
  return (
    <Typography color={color} size={12} weight="bold">
      {formatedCurrencyType}
    </Typography>
  );
};

export default CurrencyTypeIcon;
