import { Box, Stack, Flex, Text, Skeleton } from '@chakra-ui/react';
import React, { useState, useMemo } from 'react';
import { Scope } from '@voltbras/auth-directives';
import useHasScopes from 'hooks/useHasScope';
import { Module, StationModelCapability } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { TranslationKeyType } from 'types/translation';
import useHasModule from 'hooks/useHasModule';

type Props = {
  tabs: Tab[];
  loading?: boolean;
};
export type Tab = {
  title: TranslationKeyType;
  component: React.FC;
  requiredScope: Scope[];
  requiredModule?: Module;
  filter?: (capabilities: StationModelCapability[]) => boolean;
};
const Sidebar: React.FC<Props> = ({ tabs: allTabs, loading }) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const hasModule = useHasModule();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabs = useMemo(
    () =>
      allTabs
        .filter((tab) => hasScopes(tab.requiredScope))
        .filter((tab) =>
          tab.requiredModule ? hasModule(tab.requiredModule) : true
        ),
    [hasScopes, allTabs, hasModule]
  );

  const CurrentPage = tabs[currentTabIndex].component;
  return (
    <Flex w="100%">
      <Box as="aside" w="64" height="100vh" position="fixed" py="2" shadow="md">
        <Stack spacing="2" align="stretch" direction="column" p="6">
          {tabs.map((tab, index) => (
            <Skeleton isLoaded={!loading} h="9">
              <Box
                bg={index === currentTabIndex ? 'gray.50' : 'white'}
                borderRadius="8"
                px="6"
                py="2"
                onClick={() => setCurrentTabIndex(index)}
                cursor="pointer"
                _hover={{
                  background: 'gray.50',
                }}
              >
                <Text
                  fontFamily={index === currentTabIndex ? 'heading' : 'body'}
                  fontSize="sm"
                  color="gray.900"
                >
                  {t(tab.title)}
                </Text>
              </Box>
            </Skeleton>
          ))}
        </Stack>
      </Box>
      <Box bg="gray.50" ml="64" p="6" minHeight="100vh" width="100%">
        <CurrentPage />
      </Box>
    </Flex>
  );
};

export default Sidebar;
