import { Skeleton, Flex, FlexProps, IconButton, Box } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import React from 'react';
import { InjectChildrenProps, InjectDivProps } from '.';

type Props = {
  isOpen: boolean;
  loading?: boolean;
  clickable?: boolean;
} & InjectChildrenProps &
  InjectDivProps &
  FlexProps;

export const DropdownTriggerSelectContainer = React.forwardRef<
  HTMLDivElement,
  Props
>(({ isOpen, onClick, loading, children, clickable = true, ...rest }, ref) => (
  <Flex
    opacity={clickable ? 1 : 0.6}
    onClick={!clickable || loading ? undefined : onClick}
    justifyContent="space-between"
    alignItems="center"
    px={4}
    h={10}
    lineHeight={10}
    borderWidth={1}
    borderColor="gray.100"
    borderRadius="lg"
    cursor={!clickable || loading ? 'not-allowed' : 'pointer'}
    ref={ref}
    _hover={{
      shadow: 'sm',
    }}
    {...rest}
  >
    <Skeleton isLoaded={!loading} h={7} w="full">
      <Flex justifyContent="space-between" alignItems="center" h="full">
        <Box noOfLines={1}>{children}</Box>
        <IconButton
          pl={2}
          size="sm"
          aria-label="Abrir"
          variant="unstyled"
          icon={
            <Icon
              type={isOpen ? 'NEW_CHEVRON_DOWN' : 'NEW_CHEVRON_RIGHT'}
              size={20}
              color="PRIMARY_GRAY"
            />
          }
        />
      </Flex>
    </Skeleton>
  </Flex>
));
