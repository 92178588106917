import { Cell } from 'components/Table/styles';
import Config from 'config';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 120px;
  padding-top: 15px;
`;

export const CommentTypeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const FeedbackIcon = styled(Config.ICONS.FEEDBACK)`
  margin-right: 17px;
`;

export const ReviewIcon = styled(Config.ICONS.REVIEW)`
  margin-right: 17px;
`;

export const TitleContainer = styled.div`
  padding: 20px 0;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 417px;
  margin-bottom: 47px;
  ${Cell} {
    vertical-align: top;
  }
`;

export const CommentUserContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  white-space: normal;
  max-width: 300px;
`;

export const CommentStationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
`;

export const CommentMetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
`;

export const FilterButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  width: 100%;
`;

export const CommentOriginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: flex-start;
`;
