import styled from 'styled-components';
import Config from 'config';
import Typography from 'components/Typography';

export const LightText = styled(Typography)`
  margin-top: 16px;
`;

export const InputOuterContainer = styled.div`
  &:not(:last-child) {
    margin-right: 50px;
  }
  width: 197.5px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  height: 33px;
  margin-bottom: 10px;
`;

export const SubmitButton = styled(Config.ICONS.ARROW).attrs({
  title: 'Adicionar',
})`
  background: ${({ theme }) => theme.COLORS.CLICKABLE};
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;
  margin-left: 8px;
  -webkit-transform: rotate(90deg);
  box-sizing: unset;
`;
