import React from 'react';
import { Icon } from 'new-components/Icon';
import styled from 'styled-components';

const Header = styled.div`
  position: relative;
`;

// TODO: "16" | "24" is too much low level, can we improve this somehow?
const Attachments = styled.div<{ padding: '16' | '24' }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: ${({ padding }) => padding}px;
  right: ${({ padding }) => padding}px;
`;

const CloseContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-radius: 50%;
  box-shadow: 0px 2px 0px 0px #0000000a;
  cursor: pointer;
`;

type Props = {
  onClose: () => void;
};

const Close: React.FC<Props> = ({ onClose }) => (
  <CloseContainer onClick={onClose}>
    <Icon type={'NEW_CLOSE_X'} color="PRIMARY_GRAY" size={24} />
  </CloseContainer>
);

export const ModalHeader = Object.assign(Header, {
  Attachments,
  Close,
});
