import env from '@beam-australia/react-env';
import { MembershipsQuery } from 'generated/graphql';
import membershipsQuery from 'graphql/queries/memberships';
import { useCallback, useEffect, useState, useLayoutEffect } from 'react';
import auth from 'services/auth';
import { auth as firebaseAuth } from 'services/identity-provider/firebaseConfig';
import { useClient } from 'urql';
import getCookieFromKey from 'services/getCookieFromKey';
import { getSmallerRoleAmongSelectedOrganizations } from 'services/multiorg';
import IdentityProviderFactory from 'services/identity-provider/factory';
import config from 'config';
import { onAuthStateChanged } from 'firebase/auth';
import AuthContext, { Member, Organization } from './context';

export const URL_SUFFIX = env('URL_SUFFIX') ?? 'localhost';
export const CHANGING_SELECTED_ORG_IDS_COOKIE_KEY = 'changingSelectedOrgIds';
const ORGANIZATIONIDS_COOKIE_KEY = 'organizationIds';

type Props = { resetClient: () => void };

/** O contexto AuthProvider lida com autenticação, controle das organizações e do membro selecionado  */
const AuthProvider: React.FC<Props> = ({ resetClient, children }) => {
  const client = useClient();
  const [member, setMember] = useState<Member | undefined>();
  const [memberAuthToken, setMemberAuthToken] = useState<string | undefined>();
  const [memberships, setMemberships] =
    useState<MembershipsQuery['memberships']>();
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [selectedOrganizationIds, setSelectedOrganizationIds] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [orgNameMap, setOrgNameMap] = useState({});

  /*                                                    Login                                                  */
  /* --------------------------------------------------------------------------------------------------------- */

  const getMembershipsData = useCallback(
    async (authToken: string) => {
      try {
        const { data, error } = await client
          .query<MembershipsQuery>(
            membershipsQuery,
            {},
            {
              fetchOptions: {
                headers: {
                  authorization: `Bearer ${authToken}`,
                },
              },
              requestPolicy: 'network-only',
            }
          )
          .toPromise();

        if (error) {
          throw error;
        }
        if (!data) {
          throw new Error(
            'Aconteceu algum erro interno, tente novamente depois.'
          );
        }
        setOrgNameMap(
          Object.fromEntries(
            data.memberships.map((m) => [m.organizationId, m.organization.name])
          )
        );
        return data.memberships;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [client]
  );

  const storeAuthTokenOnCookies = useCallback((authToken: string) => {
    const rootDomain = new URL(window.location.href).host.split(':')[0];
    // We add the authToken cookie to other 2 subdomains used when accessing
    // station's config and router views.
    const routerDomain = `.router.${URL_SUFFIX}`;
    const configDomain = `.config.${URL_SUFFIX}`;
    const domains = [routerDomain, configDomain, rootDomain];
    domains.forEach((domain) => {
      document.cookie = `authToken=${authToken}; domain=${domain}`;
    });
  }, []);

  const handleLoginResults = useCallback(
    async (result: { authToken?: string; error?: any }) => {
      if (result?.authToken) {
        try {
          if (!result.authToken) throw new Error('sem token');

          // => buscar dados
          const memberMemberships = await getMembershipsData(result.authToken);

          // => armazenar os dados nos estados internos
          const memberOrganizations = memberMemberships?.map(
            (membership) => membership.organization
          );

          // => authToken
          setMemberAuthToken(result.authToken);
          storeAuthTokenOnCookies(result.authToken);
          setOrganizations(memberOrganizations);
          setMemberships(memberMemberships);
          if (memberMemberships.length === 0) setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error({ error });
          return error;
        }
      }
      setLoading(false);
      return result.error;
    },
    [getMembershipsData, storeAuthTokenOnCookies]
  );

  /* Para manter toda a lógica de login centralizada esta 
  função é exposta para ser utilizada na página de Login */
  const loginByEmail = useCallback(
    async (email: string, password: string): Promise<{ error?: string }> => {
      const emailLoginResult = await auth.emailLogin(email, password);
      /* TODO: verificar o tratamento do erro de senha e email invalidos */
      const error = await handleLoginResults(emailLoginResult);

      if (error?.message?.includes('account not found'))
        return { error: 'Esse usuário não pertence a nenhuma organização' };
      if (error?.message) {
        return { error: 'Erro ao autenticar' };
      }
      return { error };
    },
    [handleLoginResults]
  );

  const makeMemberLoginByToken = async (authToken: string) => {
    await handleLoginResults({ authToken });
  };

  const refetch = useCallback(async () => {
    if (memberAuthToken) {
      try {
        // => buscar dados
        const memberMemberships = await getMembershipsData(memberAuthToken);

        // => armazenar os dados nos estados internos
        const memberOrganizations = memberMemberships?.map(
          (membership) => membership.organization
        );

        setOrganizations(memberOrganizations);
        setMemberships(memberMemberships);
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  }, [getMembershipsData, memberAuthToken]);

  /* gerenciamento do localStorage */
  const time = new Date();
  time.setFullYear(time.getFullYear() + 1);

  const storeSelectedOrganizationIds = useCallback(
    (orgIds: Organization['id'][]) => {
      document.cookie = `${ORGANIZATIONIDS_COOKIE_KEY}=${orgIds}; expires=${time}`;
    },
    // eslint-disable-next-line
    []
  );
  const getSelectedOrganizationId = useCallback(
    (): string | undefined =>
      getCookieFromKey(ORGANIZATIONIDS_COOKIE_KEY, document),
    []
  );

  const addOrganizationToSelectedList = useCallback(
    (orgId: string) => {
      const organizationIsAlreadySelected =
        selectedOrganizationIds?.includes(orgId);

      if (!selectedOrganizationIds) {
        storeSelectedOrganizationIds([orgId]);
      }
      if (selectedOrganizationIds && organizationIsAlreadySelected === false) {
        storeSelectedOrganizationIds([...selectedOrganizationIds, orgId]);
      }
    },
    [storeSelectedOrganizationIds, selectedOrganizationIds]
  );

  useLayoutEffect(() => {
    // When the user comes from redirect by another whitelabel,
    // we save a temporary cookie shared per domain (CHANGING_SELECTED_ORG_IDS_COOKIE_KEY)
    // with the selected organizations in the old whitelabel,
    // so here, we save this selected organizations in the new whitelabel store
    // and we expire the shared cookie after it has been used
    const changingSelectedOrgIds = getCookieFromKey(
      CHANGING_SELECTED_ORG_IDS_COOKIE_KEY,
      document
    );
    if (changingSelectedOrgIds) {
      storeSelectedOrganizationIds(changingSelectedOrgIds?.split(','));
      document.cookie = `${CHANGING_SELECTED_ORG_IDS_COOKIE_KEY}=""; expires=${new Date()}; domain=${URL_SUFFIX};`;
    }
  }, [getSelectedOrganizationId, storeSelectedOrganizationIds]);

  useEffect(() => {
    /* TODO: melhorar o tratamento dos erros que podem vir do handleLogin */
    async function autoLogin() {
      const autoLoginResult = await auth.autoLogin();
      handleLoginResults(autoLoginResult);
    }
    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Renova o authToken a cada 30min, já que ele possui validade de 60min.
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      auth.getRefreshedAuthToken().then(({ authToken }) => {
        if (authToken && member) {
          setMemberAuthToken(authToken);
          storeAuthTokenOnCookies(authToken);
        }
      });
    }, 30 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  });

  /*                                                Organization                                               */
  /* --------------------------------------------------------------------------------------------------------- */

  /*             -----            */

  const resetSelectedOrganization = useCallback(() => {
    setSelectedOrganizationIds([]);
    storeSelectedOrganizationIds([]);
  }, [setSelectedOrganizationIds, storeSelectedOrganizationIds]);

  const logout = useCallback(async () => {
    try {
      await auth.logout();
      window.location.pathname = '/login';
      resetSelectedOrganization();
    } catch (error) {
      console.error(error);
    }
  }, [resetSelectedOrganization]);

  const switchOrganizationDashboardUrl = useCallback(
    (orgId: string, orgDashbordUrl: string) => {
      const dashboardUrlHost = new URL(
        env('ENV') ? orgDashbordUrl : 'http://localhost:3000'
      ).host;

      // => armazenando no estado
      setSelectedOrganizationIds((state) =>
        !state.length
          ? [orgId]
          : !state.includes(orgId)
          ? [...state, orgId]
          : state
      );
      // => armazenando no localStorage
      addOrganizationToSelectedList(orgId);

      // => redirecionar para outra url se existir
      if (orgDashbordUrl && dashboardUrlHost !== window.location.host) {
        window.location.replace(`${orgDashbordUrl}?orgId=${orgId}`);
      }

      // => limpando o cache
      resetClient();
    },
    [resetClient, addOrganizationToSelectedList]
  );

  const setOrganizationIdSelectedOnLogin = useCallback(
    (orgId: Organization['id']) =>
      setSelectedOrganizationIds((state) =>
        !state.length
          ? [orgId]
          : !state.includes(orgId)
          ? [...state, orgId]
          : state
      ),
    [setSelectedOrganizationIds]
  );

  const extractOrganizationFromUrl = useCallback(
    (organizations: Organization[]) => {
      const { origin } = new URL(window.location.href);
      return organizations?.find((org) => org.dashboardUrl.includes(origin));
    },
    []
  );

  const setDefaultOrganization = useCallback(
    (organizations: Array<Organization>) => {
      /*
        Set the default organization from the available options. We can get the
        default organization from query parameters, cookies or the current
        window url.
      */

      // Retrieve organization selected from login/orgiId="orgId" query
      // parameter.
      const orgIdSelectedOnLoginPage = organizations.find(
        (org) =>
          org.id ===
          selectedOrganizationIds.find(
            (selectedOrgId) => selectedOrgId === org.id
          )
      );
      if (orgIdSelectedOnLoginPage)
        return switchOrganizationDashboardUrl(
          orgIdSelectedOnLoginPage.id,
          orgIdSelectedOnLoginPage.dashboardUrl
        );

      // Search for the default organization set on cookies.
      const storedOrganizationIds = getSelectedOrganizationId();
      if (storedOrganizationIds?.length) {
        return setSelectedOrganizationIds(storedOrganizationIds.split(','));
      }

      // Retrieve organization from the url host if there is any.
      const organizationFromUrl = extractOrganizationFromUrl(organizations);
      if (organizationFromUrl)
        return switchOrganizationDashboardUrl(
          organizationFromUrl.id,
          organizationFromUrl.dashboardUrl
        );

      // Switch to the first organization the member has access to.
      return switchOrganizationDashboardUrl(
        organizations[0].id,
        organizations[0].dashboardUrl
      );
    },
    [
      selectedOrganizationIds,
      getSelectedOrganizationId,
      switchOrganizationDashboardUrl,
      extractOrganizationFromUrl,
    ]
  );

  /* lógica de loading */
  useEffect(() => {
    const newMember = getSmallerRoleAmongSelectedOrganizations(
      memberships ?? [],
      selectedOrganizationIds
    );

    if (memberships?.length && !newMember) {
      setMember(memberships[0]);
    } else {
      setMember(newMember);
    }

    if (memberAuthToken && selectedOrganizationIds.length && newMember)
      setLoading(false);
  }, [selectedOrganizationIds, memberships, memberAuthToken]);

  /* lógica de definição da organização padrão */
  useEffect(() => {
    if (organizations) setDefaultOrganization(organizations);
    // -> incluindo o setDefaultOrganization no array de deps ele atinge o máximo de recursividade, resultando em um erro.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  useEffect(() => {
    if (selectedOrganizationIds.length)
      storeSelectedOrganizationIds(selectedOrganizationIds);
  }, [selectedOrganizationIds, storeSelectedOrganizationIds]);

  const identityProvider = new IdentityProviderFactory().build(config.CODE);

  useEffect(() => {
    if (identityProvider.type === 'SSO_IdP') {
      try {
        onAuthStateChanged(firebaseAuth, async (args) => {
          const authToken = await args?.getIdToken();
          setMemberAuthToken(authToken);
        });
      } catch (error) {
        console.error('Could not get auth token');
      }
    }
  }, [identityProvider]);

  const loginBySSO = useCallback(async () => {
    if (identityProvider.type === 'SSO_IdP') {
      try {
        await identityProvider.redirect();
        return {};
      } catch (error) {
        if (typeof error === 'string') return { error };
        if (error instanceof Error) return { error: error.message };
        return { error: `Generic Error: ${error}` };
      }
    }
    return {};
  }, [identityProvider]);

  return (
    <AuthContext.Provider
      value={{
        member,
        organizations,
        loginByEmail,
        loginBySSO,
        logout,
        memberAuthToken,
        identityProvider,
        switchOrganizationDashboardUrl,
        setOrganizationIdSelectedOnLogin,
        loading,
        refetch,
        selectedOrganizationIds,
        setSelectedOrganizationIds,
        orgNameMap,
        makeMemberLoginByToken,
        setMember,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
