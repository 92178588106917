import { gql } from '@apollo/client';

export default gql`
  mutation ResendInviteMember(
    $data: InviteMemberInput!
    $where: EmspWhereUniqueInput!
  ) {
    resendInviteMember(data: $data, where: $where)
  }
`;
