import { Namespace, TFunction } from 'react-i18next';
import { TemplateData, MonitoringPageRepository } from '../repository';
import { StationMonitoringStatus } from '../types';

export type HandleChangePreStatusArgs = {
  newEvent: StationMonitoringStatus;
  preStatusSelected: StationMonitoringStatus[] | undefined;
  setPreStatusSelected: (
    preStatusSelected: StationMonitoringStatus[] | undefined
  ) => void;
};

export type HandleStatusTextSelectArgs = {
  status: StationMonitoringStatus;
  t: TFunction<Namespace<'common'>, undefined>;
};

export type HandleButtonDisabledArgs = {
  statusSelected: StationMonitoringStatus[] | undefined;
  preStatusSelected: StationMonitoringStatus[] | undefined;
  setButtonDisabled: (disabled: boolean) => void;
  stationsMonitoringStatus: StationMonitoringStatus[];
};

export type HandleSaveStatusSelectedArgs = {
  setStatusSelected: (
    preStatusSelected: StationMonitoringStatus[] | undefined
  ) => void;
  preStatusSelected: StationMonitoringStatus[] | undefined;
};

export default class MonitoringPageHelper {
  constructor(private readonly repository: MonitoringPageRepository) {}

  getTemplateData(
    statusSelected: StationMonitoringStatus[] | undefined,
    includeAlarms: boolean
  ): TemplateData {
    const result = this.repository.getTemplateData(
      statusSelected,
      includeAlarms
    );

    return result;
  }

  handleChangePreStatusSelected({
    newEvent,
    preStatusSelected,
    setPreStatusSelected,
  }: HandleChangePreStatusArgs) {
    const alreadyOnArray = preStatusSelected?.includes(newEvent);

    if (!alreadyOnArray) {
      const newState = preStatusSelected
        ? [...preStatusSelected, newEvent]
        : [newEvent];

      setPreStatusSelected(newState);
      return;
    }

    const newState = preStatusSelected?.filter((p) => p !== newEvent) ?? [];

    setPreStatusSelected(newState);
  }

  handleStatusTextSelect({ status, t }: HandleStatusTextSelectArgs) {
    switch (status) {
      case 'AVAILABLE':
        return t('monitoring.filterCard.filter-by-status.available');
      case 'CHARGING':
        return t('monitoring.filterCard.filter-by-status.charging');
      case 'INOPERATIVE':
        return t('monitoring.filterCard.filter-by-status.inoperative');
      case 'UNKNOWN':
        return t('monitoring.filterCard.filter-by-status.unknown');
    }
  }

  handleButtonDisabled({
    statusSelected,
    preStatusSelected,
    setButtonDisabled,
    stationsMonitoringStatus,
  }: HandleButtonDisabledArgs) {
    const sortedPreStatusSelected = JSON.stringify(preStatusSelected?.sort());
    const sortedStatusSelected = JSON.stringify(statusSelected?.sort());

    if (statusSelected !== preStatusSelected) {
      setButtonDisabled(false);
    }
    if (
      statusSelected?.length === 0 &&
      sortedPreStatusSelected === JSON.stringify(stationsMonitoringStatus)
    ) {
      setButtonDisabled(true);
    }
    if (sortedStatusSelected === sortedPreStatusSelected) {
      setButtonDisabled(true);
    }
  }

  getDefaultStatusSelected() {
    const monitoringStatusSelected = window.localStorage.getItem(
      'MONITORING_STATUS_SELECTED'
    );
    const defaultStatusSelected: StationMonitoringStatus[] | undefined =
      monitoringStatusSelected && JSON.parse(monitoringStatusSelected);

    return defaultStatusSelected;
  }

  handleSaveStatusSelected = ({
    preStatusSelected,
    setStatusSelected,
  }: HandleSaveStatusSelectedArgs) => {
    setStatusSelected(preStatusSelected);

    window.localStorage.setItem(
      'MONITORING_STATUS_SELECTED',
      JSON.stringify(preStatusSelected)
    );
  };
}
