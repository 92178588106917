import { ChakraProvider } from '@chakra-ui/react';
import { theme as chakraTheme } from 'chakra/theme';
import Config from 'config';
import Loader from 'components/Loader';
import AuthContext from 'contexts/Auth/context';
import AuthProvider from 'contexts/Auth/provider';
import { createClient } from 'graphql/client';
import Head from 'Head';
import useConnectivityMonitor from 'hooks/useConnectivityMonitor';
import useGATracking from 'hooks/useGATracking';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { getLanguage } from 'services/organization';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import i18n from 'i18next';
import { FormatTranslationOptionsType } from 'types/translation';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import defaultTheme from 'theme/theme';
import { Provider } from 'urql';
import { useClearCache } from 'react-clear-cache';
import SharedConfig from 'config/shared';
import ToastContainer from 'components/ToastContainer';
import ZendeskWidget from 'components/ZendeskWidget';
import { Dashboard, LoggedOut } from 'pages';
import '../../react-app-env.d';
import resources from 'translation';
import GlobalStyles, { LoadingContainer } from 'styles';

mixpanel.init('13b2db8c01d2a829c756322343177ce2');

const Router = () => {
  useGATracking();
  const { loading, member } = useContext(AuthContext);
  const {
    isLatestVersion,
    emptyCacheStorage,
    loading: loadingCurrentVersion,
  } = useClearCache();

  i18n.use(initReactI18next).init({
    lng: getLanguage(member?.language),
    resources,
    fallbackLng: Config.LANGUAGE ?? 'pt_BR',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
      format: (value, format, _, options: FormatTranslationOptionsType) => {
        const lng = (member?.language ?? 'pt_BR').replace('_', '-');
        switch (format) {
          case 'currency':
            return new Intl.NumberFormat('pt-BR', options).format(value);
          case 'energy':
            return new Intl.NumberFormat(lng, options).format(value / 1000);
          case 'number':
            return new Intl.NumberFormat(lng, options).format(value);
          default:
            return value instanceof Date ? moment(value).format(format) : value;
        }
      },
    },
  });

  i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
  });

  async function clearUserBrowserCache() {
    await emptyCacheStorage();
    window.location.replace(window.location.href);
  }

  useEffect(() => {
    // Clear user browser cache after new deployment
    if (isLatestVersion === false) {
      clearUserBrowserCache();
    }
  }, [isLatestVersion]); // eslint-disable-line

  return (
    <>
      {loading || loadingCurrentVersion ? (
        <LoadingContainer>
          <Loader color="INFO_GRAY" size={50} />
        </LoadingContainer>
      ) : (
        <Switch>
          <Route
            component={LoggedOut}
            path={[
              '/login',
              '/forgot-password',
              '/terms-of-service',
              '/two-factor-authentication',
            ]}
          />
          <Route component={Dashboard} />
        </Switch>
      )}
    </>
  );
};

const App = () => {
  const [client, setClient] = useState(createClient());
  moment.locale('pt-br');
  useConnectivityMonitor();

  const resetClient = () => setClient(createClient());

  return (
    <Provider value={client}>
      <Head />
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <ToastContainer />
          <AuthProvider resetClient={resetClient}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
            <ZendeskWidget embeddedKey={SharedConfig.ZENDESK_EMBEDDED_KEY} />
          </AuthProvider>
        </ThemeProvider>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
