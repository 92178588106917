// import env from '@beam-australia/react-env';
import env from '@beam-australia/react-env';
import ConfigType from '../typing.d';
import * as ICONS from './icons';

const DEFAULT_API_BASE_URL = 'https://api.staging.voltbras.com.br';

const SharedConfig = {
  YPF_SSO_ENABLED: false,
  AUTH: {
    TENANT_ID: 'dashboard-lpnn4',
  },
  COLORS: {
    WHITE: '#FFFFFF',
    PRIMARY_GRAY: '#333333',
    SECONDARY_GRAY: '#939393',
    TERTIARY_GRAY: '#DBDBDB',
    BACKGROUND: '#F4F4F4',
    LIGHT_GRAY: '#B5B5B5',
    INFO_GRAY: '#CDD7D6',
    WARNING: '#FF7C01',
    // TODO: add other commons colors, there are duplicated colors in the project
  },
  COLOR_SCHEME: {
    GRAY: {
      '50': '#f4f4f4',
      '100': '#DBDBDB',
      '200': '#c2c2c2',
      '300': '#b5b5b5',
      '400': '#a8a8a8',
      '500': '#939393',
      '600': '#666666',
      '700': '#595959',
      '800': '#4d4d4d',
      '900': '#333333',
    },
  },
  API: {
    BASE_URL: env('API_BASE_URL') || DEFAULT_API_BASE_URL,
    GRAPHQL_ENDPOINT: env('API_GRAPHQL_ENDPOINT') || '/graphql',
    GOOGLE_MAPS: {
      KEY: 'AIzaSyDuT4dsYUq01DBbXUkAxp4ESw7SJrrQm0o',
    },
  },
  ICONS,
  LABELS: {
    CONNECTOR_TYPES: {
      TYPE1CCS: 'CCS 1',
      TYPE2CCS: 'CCS 2',
      TYPE2: 'Type 2',
      TYPE3: 'Type 3',
      CHADEMO: 'CHAdeMO',
      J1772: '1772',
      WALL: 'Wall',
      TESLA: 'Tesla',
      GBT_AC: 'GB/T',
      GBT_DC: 'GB/T',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: 'shared-config.connector-status.available.title',
      PREPARING: 'shared-config.connector-status.preparing.title',
      CHARGING: 'shared-config.connector-status.charging.title',
      FINISHING: 'shared-config.connector-status.finishing.title',
      INOPERATIVE: 'shared-config.connector-status.inoperative.title',
      UNKNOWN: 'shared-config.connector-status.unknown.title',
      RESERVED: 'shared-config.connector-status.reserved.title',
    },
    STATION_STATUS: {
      AVAILABLE: 'shared-config.station-status.available.title',
      CHARGING: 'shared-config.station-status.charging.title',
      INOPERATIVE: 'shared-config.station-status.inoperative.title',
      PLANNED:
        'shared-config.station-status.planned.title' /* A label não esta bem descrita, alterar quando for usar */,
      RESERVED: 'shared-config.station-status.reserved.title',
      UNKNOWN: 'shared-config.station-status.unknown.title',
    },
    RESERVATION_STATUS: {
      SCHEDULED: 'shared-config.reservation-status.scheduled.title',
      ONGOING: 'shared-config.reservation-status.ongoing.title',
      CONCLUDED: 'shared-config.reservation-status.concluded.title',
      EXPIRED: 'shared-config.reservation-status.expired.title',
      CANCELED: 'shared-config.reservation-status.canceled.title',
    },
    MODEM_STATUS: {
      NOT_CONFIGURED: 'shared-config.model-status.not-configured.title',
      ONLINE: 'shared-config.model-online.title',
      OFFLINE: 'shared-config.model-offline.title',
    },
    ORDER_STATUS: {
      PreAuthorized: 'shared-config.order-status.pre-authorized.title',
      Captured: 'shared-config.order-status.captured.title',
      Subsidized: 'shared-config.order-status.subsidized.title',
      Error: 'shared-config.order-status.error.title',
      ErrorOnPreAuthorization:
        'shared-config.order-status.error-on-pre-authorization.title',
      NoCost: 'shared-config.order-status.no-cost.title',
      Cancelled: 'shared-config.order-status.cancelled.title',
    },
    SESSION_STATUS: {
      CHARGING: 'shared-config.session-status.charging.title',
      ERROR: 'shared-config.session-status.error.title',
      FINISHED: 'shared-config.session-status.finished.title',
      TIMED_OUT: 'shared-config.session-status.timed-out.title',
      WAITING_PLUG: 'shared-config.session-status.waiting-plug.title',
      WAITING_UNPLUG: 'shared-config.session-status.waiting-unplug.title',
    },
    STATION_CONNECTIVITY_STATUS: {
      ONLINE: 'shared-config.station-connectivity-status.online.title',
      OFFLINE: 'shared-config.station-connectivity-status.offline.title',
    },
    UNLOCK_METHODS: {
      Open: 'shared-config.unlock-methods.open.title',
      ChargingCard: 'shared-config.unlock-methods.charging-card.title',
      Remote: 'shared-config.unlock-methods.remote.title',
    },
    CONECTOR_ALARM_TYPES: {
      StationAlarmStopChargingFailed:
        'shared-config.conector-alarm-types.stop-charging-failed.title',
      StationAlarmWentOffline:
        'shared-config.conector-alarm-types.went-offline.title',
      StationAlarmWentInoperative:
        'shared-config.conector-alarm-types.went-inoperative.title',
      StationAlarmWentUnavailable:
        'shared-config.conector-alarm-types.went-unavailable.title',
    },
    STATION_ALARM_TYPES: {
      StationAlarmStopChargingFailed:
        'shared-config.station-alarm-types.stop-charging-failed.title',
      StationAlarmWentOffline:
        'shared-config.station-alarm-types.went-offline.title',
      StationAlarmWentInoperative:
        'shared-config.station-alarm-types.went-inoperative.title',
      StationAlarmWentUnavailable:
        'shared-config.station-alarm-types.went-unavailable.title',
    },
    ERROR_LABELS: {
      ConnectorLockFailure:
        'shared-config.error-labels.connector-lock-failure.title',
      EVCommunicationError:
        'shared-config.error-labels.ev-communication-error.title',
      GroundFailure: 'shared-config.error-labels.ground-failure-error.title',
      HighTemperature:
        'shared-config.error-labels.high-temperature-error.title',
      InternalError: 'shared-config.error-labels.internal-error.title',
      LocalListConflict: 'shared-config.error-labels.local-list-conflict.title',
      NoError: '-',
      Mode3Error: 'shared-config.error-labels.other-error.title',
      OtherError: 'shared-config.error-labels.other-error.title',
      OverCurrentFailure:
        'shared-config.error-labels.over-current-failure.title',
      OverVoltage: 'shared-config.error-labels.over-voltage.title',
      PowerMeterFailure: 'shared-config.error-labels.power-meter.failure',
      PowerSwitchFailure: 'shared-config.error-labels.power-switch.failure',
      ReaderFailure: 'shared-config.error-labels.reader-failure.title',
      ResetFailure: 'shared-config.error-labels.reset-failure.title',
      UnderVoltage: 'shared-config.error-labels.under-voltage.title',
      WeakSignal: 'shared-config.error-labels.weak-signal.title',
      EmergencyButtonPressed:
        'shared-config.error-labels.emergency-button.pressed.title',
      NoSignal: 'shared-config.error-labels.no-signal.title',
    },
    ERROR_SOLUTIONS: {
      EmergencyButtonPressed:
        'shared-config.error-solutions.emergency-btn-pressed.title',
      HighTemperature: 'shared-config.error-solutions.high-temperature.title',
      InternalError: 'shared-config.error-solutions.internal-error.title',
      // NoSignal: 'Melhore a captação do sinal colocando uma antena maior ou posicionando a existente em local mais aberto.'
    },
    ESTABLISHMENT_TYPE: {
      DEFAULT: 'shared-config.establishment-type.default.title',
      AIRPORT: 'shared-config.establishment-type.airport.title',
      FUEL_STATION: 'shared-config.establishment-type.fuel-station.title',
      HOTEL: 'shared-config.establishment-type.hotel.title',
      INN: 'shared-config.establishment-type.inn.title',
      MALL: 'shared-config.establishment-type.mall.title',
      PARKING_LOT: 'shared-config.establishment-type.parking-lot.title',
      RECREATION_AREA: 'shared-config.establishment-type.recreation-area.title',
      RESTAURANT: 'shared-config.establishment-type.restaurant.title',
      SUPERMARKET: 'shared-config.establishment-type.supermarket.title',
    },
    BILLING_MODES: {
      PER_ENERGY: 'shared-config.billing-modes.per-energy.title',
      PER_TIME: 'shared-config.billing-modes.per-time.title',
    },
    STATION_CONFIGURATION_KEY_UNIT: {
      WH: 'shared-config.station-configuration-key.wh',
      PERCENTAGE: 'shared-config.station-configuration-key.percentage',
      SECONDS: 'shared-config.station-configuration-key.seconds',
      TIMES: 'shared-config.station-configuration-key.times',
    },
    ROLES: {
      STAFF: 'shared-config.roles.staff.title',
      OWNER: 'shared-config.roles.owner.title',
      ADMIN: 'shared-config.roles.admin.title',
      SUPPORT: 'shared-config.roles.support.title',
      TECHNICIAN: 'shared-config.roles.technician.title',
      VIEWER: 'shared-config.roles.viewer.title',
    },
  } as ConfigType['LABELS'],
  POLLING_INTERVAL: 4000,
  ZENDESK_EMBEDDED_KEY: 'e419bc20-9da5-466f-bf28-f4bf8d69017f',
};

export default SharedConfig;
