import {
  UnlockConsumerRfidMutation,
  UnlockConsumerRfidMutationVariables,
} from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import { Icon } from 'new-components/Icon';
import DialogModal from 'atomic-components/organisms/DialogModal';
import { toast } from 'react-toastify';
import UnlockConsumerRFID from 'graphql/mutations/unlockConsumerRFID';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  refetchInfo?: () => void;
  decimalId: string;
};

const ConfirmRFIDUnlockingModal = ({
  onClose,
  isOpen,
  refetchInfo,
  decimalId,
}: Props) => {
  const { t } = useTranslation();
  const [unlockConsumerRFID, { loading }] = useAuthMutation<
    UnlockConsumerRfidMutation,
    UnlockConsumerRfidMutationVariables
  >(UnlockConsumerRFID, {
    variables: {
      where: {
        decimalId,
      },
    },
  });

  const handleUnlockConsumerRFID = async () => {
    try {
      await unlockConsumerRFID();
      if (refetchInfo) refetchInfo();
      toast.success(
        t('confirm-rfid-unlocking-modal.toast-successfully-blocked-message')
      );
    } catch (e) {
      toast.error(t('confirm-rfid-unlocking-modal.toast-error-message'));
    }
  };

  return (
    <DialogModal
      header={{
        title: t('confirm-rfid-unlocking-modal.dialog-modal.header.title'),
        icon: <Icon type="NEW_INFO" color="PRIMARY" size={24} />,
      }}
      subtitle={t('confirm-rfid-unlocking-modal.dialog-modal.subtitle')}
      visible={isOpen}
      onClose={onClose}
      submitButton={{
        colorScheme: 'primary',
        onPress: handleUnlockConsumerRFID,
        text: t('confirm-rfid-unlocking-modal.dialog-modal.submit-btn-title'),
        loading,
      }}
      cancelButton={{
        text: t('dialog-modal.modal.body.cancel-btn'),
        onClick: () => onClose(),
      }}
    />
  );
};

export default ConfirmRFIDUnlockingModal;
