import { gql } from '@apollo/client';

export default gql`
  query SessionsSummary(
    $thisMonthTimeRange: TimeRangeInput!
    $lastMonthTimeRange: TimeRangeInput!
  ) {
    organizations {
      name
      thisMonthSummary: sessionsSummary(
        timeRange: $thisMonthTimeRange
        filter: {
          includesStatus: [CHARGING, WAITING_PLUG, WAITING_UNPLUG, FINISHED]
        }
      ) {
        count
        timeCharging
        driverCount
        energyConsumed
      }
      lastMonthSummary: sessionsSummary(
        timeRange: $lastMonthTimeRange
        filter: {
          includesStatus: [CHARGING, WAITING_PLUG, WAITING_UNPLUG, FINISHED]
        }
      ) {
        count
        timeCharging
        driverCount
        energyConsumed
      }
    }
  }
`;
