import {
  Box,
  FormControl,
  Text,
  FormLabel,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth/context';
import Dropdown from 'new-components/Dropdown';
import Config from 'config';
import { useCreateVoucherRulesSelectOrganization } from './services';

export const VoucherOrganizationSelection = () => {
  const { t } = useTranslation();
  const { selectedOrganizationIds } = useContext(AuthContext);
  const { organizationId, setOrganizationId } =
    useCreateVoucherRulesSelectOrganization();
  const { isOpen, onClose, onToggle } = useDisclosure();

  useEffect(() => {
    if (selectedOrganizationIds?.length === 1) {
      setOrganizationId(selectedOrganizationIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationIds]);

  return (
    <FormControl isRequired={true}>
      <Flex flexDirection="column" gap="1rem">
        <Flex flexDirection="column">
          <FormLabel fontWeight="bold" fontSize="sm">
            {t('payments.voucher-tab.create-voucher.rules.organization.title')}
          </FormLabel>
          <Box>
            <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
              {t(
                'payments.voucher-tab.create-voucher.rules.organization.subtitle'
              )}
            </Text>
          </Box>
        </Flex>
        <Box>
          <Dropdown
            isOpen={isOpen}
            onClickOutside={onClose}
            position="left"
            toggleDropdown={onToggle}
            content={() => (
              <Dropdown.Container>
                <Dropdown.Section>
                  <Box w="16rem">
                    {selectedOrganizationIds?.map((organizationId) => (
                      <Dropdown.Row
                        onClick={() => {
                          setOrganizationId(organizationId);
                          onClose();
                        }}
                      >
                        <Text fontSize="sm">{organizationId}</Text>
                      </Dropdown.Row>
                    ))}
                  </Box>
                </Dropdown.Section>
              </Dropdown.Container>
            )}
          >
            <Dropdown.TriggerButtonContainer active={isOpen} onClick={onToggle}>
              <Flex
                alignItems="center"
                border="1px"
                borderColor="gray.100"
                borderRadius="0.5rem"
                flexDirection="row"
                h="2rem"
                justifyContent="space-between"
                paddingX="1.25rem"
                w="16rem"
              >
                <Text fontSize="sm">
                  {organizationId ??
                    t(
                      'payments.voucher-tab.create-voucher.rules.organization.select-placeholder'
                    )}
                </Text>
                <Config.NEW_ICONS.NEW_CHEVRON_DOWN
                  height="1.25rem"
                  width="1.25rem"
                />
              </Flex>
            </Dropdown.TriggerButtonContainer>
          </Dropdown>
        </Box>
      </Flex>
    </FormControl>
  );
};
