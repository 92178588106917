import { gql } from '@apollo/client';

export default gql`
  mutation AllowStationToAcceptRFID(
    $data: AllowStationToAcceptRFIDInput!
    $where: SelectStationInput!
  ) {
    allowStationToAcceptRFID(data: $data, where: $where)
  }
`;
