import { Typography } from 'components';
import { typographyStyle } from 'components/Typography';
import Config from 'config';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: -148px;
  left: -50px;
  width: 240px;
  background: ${({ theme }) => theme.COLORS.WHITE};
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 8px;
  cursor: default;
  display: flex;
  flex-flow: column nowrap;
  :before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 40px;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    background: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const Row = styled.div`
  display: flex;
  flew-flow: row nowrap;
  z-index: 1;
`;
export const Text = styled.span`
  text-align: left;
  ${typographyStyle}
  line-height: 130%;
`;

export const UnshrinkableText = styled(Typography)`
  flex: none;
`;

export const SpinnerIcon = styled(Config.ICONS.SPINNER)`
  position: relative;
  color: ${Config.COLORS.TERTIARY_GRAY};
  max-height: 50px;
  max-width: 50px;
  align-self: center;
`;

export const ErrorIcon = styled(Config.ICONS.X)`
  position: relative;
  color: ${Config.COLORS.ERROR};
  max-height: 50px;
  max-width: 50px;
  align-self: center;
`;
