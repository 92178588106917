import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { StationAlarm, StationAlarmData } from 'new-components/StationAlarm';

import { useTranslation } from 'react-i18next';
import { StationLogsContext } from '../../StationLogsContext';
import { Container, RowContainer, LoadingComponent } from './styles';

type Props = {
  alarms: StationAlarmData[];
  totalAlarms: number;
  onScrollEnd: (offset: number) => void;
};

type OnPressAlarmLineArgs = {
  id: number;
  resolvedBy?: number;
  createdByStationLogId: number;
};

export const StationAlarmList: React.FC<Props> = ({
  totalAlarms,
  onScrollEnd,
}) => {
  const { t } = useTranslation();
  const {
    selectedAlarmId,
    alarms,
    filterResolvedAlarms,
    setSelectedAlarmId,
    setSelectedAlarmStationLogId,
  } = useContext(StationLogsContext);

  const onPressAlarmLine = ({
    id,
    createdByStationLogId,
  }: OnPressAlarmLineArgs) => {
    setSelectedAlarmId((old) => (old === id ? undefined : id));

    setSelectedAlarmStationLogId((currentStationLogId) =>
      currentStationLogId === createdByStationLogId
        ? null
        : createdByStationLogId
    );
  };

  return (
    <Container>
      <InfiniteScroll
        dataLength={alarms.length}
        hasMore={alarms.length !== totalAlarms}
        next={() => onScrollEnd(alarms.length)}
        loader={<LoadingComponent size={32} top={32} />}
        scrollThreshold={0.9}
        scrollableTarget="AlarmsListContainer"
      >
        {alarms.map((alarm) => (
          <RowContainer key={alarm.id}>
            <StationAlarm
              alarm={alarm}
              isResolved={filterResolvedAlarms}
              resolvedText={
                alarm.resolvedAt
                  ? t(
                      'station-page.station-log-tab.station-alarms.station-alarm-list.station-alarm.resolved-text',
                      {
                        value: new Date(alarm.resolvedAt),
                      }
                    )
                  : '-'
              }
              selected={selectedAlarmId === alarm.id}
              onClick={({ id, createdByStationLogId }) =>
                onPressAlarmLine({
                  id,
                  createdByStationLogId,
                })
              }
            />
          </RowContainer>
        ))}
      </InfiniteScroll>
    </Container>
  );
};
