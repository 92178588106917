import { Box, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  exclusionScheduledDate: Date;
  appName: string;
};

export const SuccessSubmitFeedback = ({
  exclusionScheduledDate,
  appName,
}: Props) => {
  const { t } = useTranslation();
  const formattedDate = moment(exclusionScheduledDate).format('DD/MM/YYYY');
  const titleSize = useBreakpointValue({ base: 24, md: 32 });
  const textSize = useBreakpointValue({ base: 14, md: 16 });

  return (
    <Flex h="full" margin="auto" flexDirection="column" justifyContent="center">
      <Heading fontSize={titleSize}>
        {t('account-exclusion-success-feedback.title')}
      </Heading>

      <Heading mt={6} color="gray.500" fontSize={textSize}>
        {t('account-exclusion-success-feedback.subtitle', {
          date: formattedDate,
        })}
      </Heading>

      <Box mt={12}>
        <Text color="gray.500" fontSize={textSize}>
          {t('account-exclusion-success-feedback.success-text', {
            appName,
          })}
        </Text>
        <Text mt={4} color="gray.500" fontSize={textSize}>
          {t('account-exclusion-success-feedback.cancel-text')}
        </Text>
      </Box>
    </Flex>
  );
};
