import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const EcodotConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-12',
  CODE: 'ecodot',
  LABEL: 'EcoDot.',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#2ED058',
    CLICKABLE: '#26A547',
    ERROR: '#F87060',
    SIDEBAR: '#1D1D1D',
    LOGIN_LOGO_BACKGROUND: '#1D1D1D',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#0091CA',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#00AAFF',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91',

    PREPARING: '#00AAFF',
    FINISHING: '#00AAFF',

    SELECTED_DATE_BOUND_BACKGROUND: '#26A547',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#21903E',

    DATE_PRE_SELECTED_BACKGROUND: '#6BE089',
    DATE_PRE_SELECTED_BORDER: '#56DB79',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#6BE089',
    DATE_PRE_SELECTED_COLOR: '#26A547',

    DATE_SELECTED_BACKGROUND: '#2ED058',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#26A547',

    CURRENT_DAY: '#2ED058', //

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00AAFF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#662C91',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#00AAFF',
      CHARGING: '#00AAFF',
      FINISHING: '#00AAFF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91',
    },

    BILLING: '#04E762',
    RECHARGES: '#00AAFF',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#7FE59A',
    BUTTON_UNAVAILABLE: '#56DB79',
    BUTTON_LOADING: '#6BE089',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#0091CA',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#26A547',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#21903E',

      DATE_PRE_SELECTED_BACKGROUND: '#7FE59A',
      DATE_PRE_SELECTED_BORDER: '#56DB79',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#6BE089',
      DATE_PRE_SELECTED_COLOR: '#21903E',

      DATE_SELECTED_BACKGROUND: '#2ED058',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#26A547',

      CURRENT_DAY: '#2ED058',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'EcoDot. - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/ecodot',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#7FE59A',
      '100': '#6BE089',
      '200': '#56DB79',
      '300': '#42D668',
      '400': '#37D666',
      '500': '#2ED058',
      '600': '#26A547',
      '700': '#21903E',
      '800': '#1D7B35',
      '900': '#18662D',
    },
    LINK: {
      '50': '#7EDAFF',
      '100': '#4BCCFF',
      '200': '#31C5FF',
      '300': '#18BEFF',
      '400': '#00B6FD',
      '500': '#0091CA',
      '600': '#006C97',
      '700': '#005A7E',
      '800': '#004864',
      '900': '#002331',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#00AAFF',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#786E6E',
      '100': '#6A6262',
      '200': '#5C5656',
      '300': '#4F4A4A',
      '400': '#4F4A4A',
      '500': '#1D1D1D',
      '600': '#272626',
      '700': '#1A1919',
      '800': '#0D0D0D',
      '900': '#000000',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#662C91',
      '600': '#4a206a',
      '700': '#3d1a56',
      '800': '#2f1443',
      '900': '#13081c',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default EcodotConfig;
