export const calculatePreDefinedDates = (
  amountToAdd: number,
  unitToAdd: 'hours' | 'months' | 'days'
): { from: Date; to: Date } => {
  const endDate = new Date();

  switch (unitToAdd) {
    case 'hours':
      endDate.setHours(endDate.getHours() + amountToAdd);
      break;
    case 'days':
      endDate.setDate(endDate.getDate() + amountToAdd);
      break;
    case 'months':
      endDate.setMonth(endDate.getMonth() + amountToAdd);
      break;
  }
  return {
    from: new Date(),
    to: endDate,
  };
};
