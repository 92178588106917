import { gql } from '@apollo/client';

export default gql`
  query ConfigurationsPage {
    allMembers {
      id
      role
      active
      organizationId
      profile {
        id
        firstName
        lastName
        email
      }
    }
    organizations {
      id
    }
  }
`;
