import styled from 'styled-components';

export const Container = styled('section')`
  gap: 16px;
  white-space: nowrap;
  display: flex;
  width: 1100px;
  padding-bottom: 12px;
  height: 145px;
`;

export const ScrollContainer = styled('section')`
  gap: 16px;
  white-space: nowrap;
  display: flex;
  overflow: auto;
  padding-bottom: 12px;
`;
