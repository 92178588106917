import { Typography } from 'components';
import Config from 'config';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 300px;
  overflow: auto;
  background: ${Config.COLORS.BACKGROUND};
  box-shadow: 1px 0px 6px #0000001a;
`;

export const CloseButton = styled(Config.ICONS.X)`
  position: absolute;
  top: 15px;
  right: 15px;
  height: 26px;
  width: 26px;
  padding: 7px;
  background: #ffffffcc;
  border-radius: 4px;
  cursor: pointer;
`;

export const StationImage = styled.img`
  width: 100%;
  height: 168px;
  object-fit: contain;
  background: ${Config.COLORS.TERTIARY_GRAY};
`;

export const Content = styled.div`
  padding: 0px 20px 40px;
  display: flex;
  flex-flow: column nowrap;
`;

export const ContentRow = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  &:last-child {
    margin-left: -6.5px;
    display: flex;
    align-items: center;
    height: 16px;
  }
`;

export const PriceContentRow = styled(ContentRow)`
  margin-top: 10px;
  row-gap: ${({ theme }) => theme.space['SPACE-4']}px;
`;

export const Text = styled(Typography)`
  width: 100%;
  text-align: left;
`;

export const FlexibleText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 15px);
`;

export const UnshrinkableText = styled(Text)`
  flex: none;
`;

export const ContentColumn = styled.span`
  width: 50%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const ConnectorPriceColumn = styled(ContentColumn)`
  width: 50%;
`;

export const CalendarIcon = styled(Config.ICONS.CALENDAR)`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  margin-bottom: 2px;
`;

export const WalletIcon = styled(Config.ICONS.WALLET)`
  height: 13px;
  width: 16px;
  margin-right: 6px;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.COLORS.PRIMARY_GRAY};
`;

export const SingleLineText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 15px);
`;

export const PriceText = styled(Text)``;

export const SpinnerIcon = styled(Config.ICONS.SPINNER)`
  position: relative;
  color: ${Config.COLORS.TERTIARY_GRAY};
  max-height: 50px;
  max-width: 50px;
  align-self: center;
  left: 125px;
  top: 59px;
`;

export const ErrorIcon = styled(Config.ICONS.X)`
  position: relative;
  color: ${Config.COLORS.ERROR};
  max-height: 50px;
  max-width: 50px;
  align-self: center;
  left: 125px;
  top: 59px;
`;
