import { ConnectorsListFieldsFragment } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import ConnectorPopover from './ConnectorPopover';
import { Container } from './styles';

type Connector = Omit<ConnectorsListFieldsFragment, 'energyMeasurements'>;

type Props = {
  connectors: Connector[];
  stationId?: string;
};

const ConnectorsList: React.FC<Props> = ({ connectors, stationId }) => {
  const [selectedConnectorId, setSelectedConnectorId] = useState<
    number | undefined
  >(undefined);
  const selectedConnector: Connector | undefined = connectors.find(
    ({ id }) => id === selectedConnectorId
  );
  const closePopover = () => setSelectedConnectorId(undefined);

  useEffect(() => {
    window.addEventListener('scroll', closePopover, true);
    return () => window.removeEventListener('scroll', () => closePopover);
  }, []);

  const getConnectorOpenState = (connector: Pick<Connector, 'id'>) => {
    if (!selectedConnector) return 'NONE';
    if (connector.id === selectedConnector.id) return 'ACTIVE';
    return 'INACTIVE';
  };

  const selectConnectorControl = (connector: Pick<Connector, 'id'>) => {
    if (connector.id === selectedConnector?.id)
      setSelectedConnectorId(undefined);
    else setSelectedConnectorId(connector.id);
  };

  return (
    <Container>
      {connectors.map((connector) => (
        <ConnectorPopover
          stationId={stationId}
          key={connector.id}
          connector={connector || {}}
          selectedConnector={selectedConnector}
          closePopover={closePopover}
          getConnectorOpenState={(connector) =>
            getConnectorOpenState(connector)
          }
          selectConnectorControl={(connector) =>
            selectConnectorControl(connector)
          }
          connectorId={connector.id}
        />
      ))}
    </Container>
  );
};

export default ConnectorsList;
