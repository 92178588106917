import { gql } from '@apollo/client';

export default gql`
  query AllDrivers($limit: Int, $offset: Int, $search: String) {
    drivers(
      search: $search
      limit: $limit
      offset: $offset
      sort: { emspId: ASC }
    ) {
      id
      label
      emspId
      profile {
        id
        firstName
        lastName
        email
      }
    }
    driversSummary(search: $search) {
      count
    }
  }
`;
