import styled from 'styled-components';
import Config from 'config';

export const AxisTextStyle = {
  fontFamily: Config.FONTS.PRIMARY,
  fontSize: 13,
  fill: Config.COLORS.SECONDARY_GRAY,
  opacity: 1,
};

export const AxisText = styled.text(AxisTextStyle);

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.1);
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  .recharts-surface {
    overflow: visible;
  }
`;
