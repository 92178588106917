import { Typography } from 'components';
import Config from 'config';
import styled from 'styled-components';

export const MarkerContainer = styled.div`
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -100%);
`;

export const AlarmInfoContainer = styled.div`
  position: absolute;
  top: 0px;
`;

export const AlarmInfo = styled(Config.ICONS.MAKER_INFOS.ALARM)``;

export const AlarmText = styled(Typography).attrs({
  color: 'WHITE',
  weight: 'bold',
})<{
  hasMoreThanTenAlarms: boolean;
  hasMoreThanHundredAlarms: boolean;
}>`
  position: absolute;
  ${({ hasMoreThanHundredAlarms, hasMoreThanTenAlarms }) => {
    if (hasMoreThanHundredAlarms)
      return `
        font-size: 8px;
        right: 2px;
        top: 17px;
     `;
    if (hasMoreThanTenAlarms)
      return `
        font-size: 10px;
        right: 4px;
        top: 17px;
      `;

    return `
        font-size: 12px;
        right: 5.5px;
        top: 17px;
      `;
  }}
`;
