import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: 24px;
`;

export const TableCardPadding = styled.div`
  padding: 24px 16px;
`;

export const StatusTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingOrErrorContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
