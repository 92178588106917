import React, { useContext } from 'react';
import Typography from 'components/Typography';
import { Flex, Text } from '@chakra-ui/react';
import AuthContext from 'contexts/Auth/context';
import { DotsWrapper, DotsContainer, Dot } from './styles';

type Props = {
  stationId: string;
  stationName: string;
  cpoId: string;
};

const StationLink: React.FC<Props> = ({ stationId, stationName, cpoId }) => {
  const { selectedOrganizationIds } = useContext(AuthContext);
  const isCPO = selectedOrganizationIds?.includes(cpoId);

  return (
    <>
      {isCPO ? (
        <DotsWrapper to={`/meus-eletropostos/${stationId}`}>
          <DotsContainer>
            <Dot />
            <Dot />
            <Dot />
          </DotsContainer>
          <Typography>{stationName ?? '---'}</Typography>
        </DotsWrapper>
      ) : (
        <Flex alignItems="flex-start">
          <Text>{stationName ?? '---'}</Text>
        </Flex>
      )}
    </>
  );
};

export default StationLink;
