import React, { useContext } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { ConnectorsSummaryFooter } from './ConnectorSummaryFooter';
import {
  ChargingPercentValue,
  ChargingText,
  ChartContainer,
  Container,
} from './styles';

type Data = {
  energyConsumed: number;
  availableConnectors: number;
  chargingConnectors: number;
  inoperativeConnectors: number;
  unknownConnectors: number;
};

const ConnectorsSummary: React.FC<{ data: Data }> = ({
  data: {
    availableConnectors,
    chargingConnectors,
    inoperativeConnectors,
    unknownConnectors,
  },
}) => {
  const { t } = useTranslation();
  const { COLORS } = useContext(ThemeContext);
  const totalConnnectors =
    chargingConnectors +
    inoperativeConnectors +
    availableConnectors +
    unknownConnectors;
  const chargingConnectorsPercent =
    (chargingConnectors / totalConnnectors) * 100;

  return (
    <Container>
      <ChartContainer>
        <CircularProgressbarWithChildren
          value={inoperativeConnectors}
          counterClockwise
          maxValue={totalConnnectors}
          styles={buildStyles({
            pathColor: COLORS.CONNECTOR_STATUS.INOPERATIVE,
            trailColor: COLORS.TERTIARY_GRAY,
          })}
        >
          <CircularProgressbarWithChildren
            // chargingConnectors will render on top of
            // unknownConnectors, so we have to sum both
            // them to make the unknownConnectors visible
            value={unknownConnectors + chargingConnectors}
            maxValue={totalConnnectors}
            styles={buildStyles({
              pathColor: COLORS.CONNECTOR_STATUS.UNKNOWN,
              trailColor: 'transparent',
            })}
          >
            <CircularProgressbarWithChildren
              value={chargingConnectors}
              maxValue={totalConnnectors}
              styles={buildStyles({
                pathColor: COLORS.CONNECTOR_STATUS.CHARGING,
                trailColor: 'transparent',
              })}
            >
              <ChargingPercentValue>
                {Math.round(
                  Number.isNaN(chargingConnectorsPercent)
                    ? 0
                    : chargingConnectorsPercent
                )}
                %
              </ChargingPercentValue>
              <ChargingText>
                {t('home.connectors-summary.charging-text')}
              </ChargingText>
            </CircularProgressbarWithChildren>
          </CircularProgressbarWithChildren>
        </CircularProgressbarWithChildren>
      </ChartContainer>

      <ConnectorsSummaryFooter
        availableConnectors={availableConnectors}
        chargingConnectors={chargingConnectors}
        inoperativeConnectors={inoperativeConnectors}
        unknownConnectors={unknownConnectors}
      />
    </Container>
  );
};
export default ConnectorsSummary;
