import styled from 'styled-components';

export const ContainerConnectors = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: space-between;
  height: 50px;
`;

export const CentralizedRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const ContainerIcon = styled.div`
  margin-right: 10px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  float: right;
`;
