import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const IpirangaConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'ipiranga',
  LABEL: 'Ipiranga',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#FFD100',
    CLICKABLE: '#C8A504',
    ERROR: '#EA7128',
    SIDEBAR: '#00266E',
    LOGIN_LOGO_BACKGROUND: '#FFD100',

    RISE: '#94DE00',
    FALL: '#EA7128',
    NEUTRAL: '#011B4B',

    CRITICAL: '#EA7128',
    WARNING: '#E6B606',

    PreAuthorized: '#F9FAFA',
    Captured: '#94DE00',
    NoCost: '#F9FAFA',
    Error: '#EA7128',

    AVAILABLE: '#94DE00',
    CHARGING: '#FFD100',
    INOPERATIVE: '#EA7128',
    UNKNOWN: '#666666',
    PLANNED: '#EA7128',
    RESERVED: '#00D9B8',

    PREPARING: '#FFD100',
    FINISHING: '#FFD100',

    SELECTED_DATE_BOUND_BACKGROUND: '#AF9004',
    SELECTED_DATE_BOUND_BORDER: '#C8A504',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#967B03',

    DATE_PRE_SELECTED_BACKGROUND: '#FEDE4D',
    DATE_PRE_SELECTED_BORDER: '#FDD41C',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#FEDE4D',
    DATE_PRE_SELECTED_COLOR: '#AF9004',

    DATE_SELECTED_BACKGROUND: '#FFD100',
    DATE_SELECTED_BORDER: '#FDD41C',
    DATE_SELECTED_HOVER_BACKGROUND: '#C8A504',

    CURRENT_DAY: '#C8A504',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F9FAFA',
      Captured: '#94DE00',
      Subsidized: '#94DE00',
      NoCost: '#F4F4F4',
      Error: '#EA7128',
      ErrorOnPreAuthorization: '#EA7128',
    },
    STATION_STATUS: {
      AVAILABLE: '#94DE00',
      CHARGING: '#FFD100',
      INOPERATIVE: '#EA7128',
      UNKNOWN: '#666666',
      PLANNED: '#EA7128',
      RESERVED: '#00D9B8',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#94DE00',
      PREPARING: '#FFD100',
      CHARGING: '#FFD100',
      FINISHING: '#FFD100',
      INOPERATIVE: '#EA7128',
      UNKNOWN: '#666666',
      RESERVED: '#00D9B8',
    },

    BILLING: '#94DE00',
    RECHARGES: '#FFD100',
    AVAILABILITY: '#00D9B8',

    BUTTON_AVAILABLE: '#FFD100',
    BUTTON_UNAVAILABLE: '#FEDE4D',
    BUTTON_LOADING: '#FDD935',
    BUTTON_TEXT_COLOR: '#00266E',
    LINK: '#00266E',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#AF9004',
      SELECTED_DATE_BOUND_BORDER: '#C8A504',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#967B03',

      DATE_PRE_SELECTED_BACKGROUND: '#FEDE4D',
      DATE_PRE_SELECTED_BORDER: '#FDD41C',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#FEDE4D',
      DATE_PRE_SELECTED_COLOR: '#AF9004',

      DATE_SELECTED_BACKGROUND: '#FFD100',
      DATE_SELECTED_BORDER: '#FDD41C',
      DATE_SELECTED_HOVER_BACKGROUND: '#C8A504',

      CURRENT_DAY: '#C8A504',
    },
  },
  FONTS: {
    PRIMARY: 'Verdana-Regular',
    SECONDARY: 'Verdana-Bold',
    TERTIARY: 'Verdana-Regular',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Não temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Ipiranga - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/ipiranga',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#FFE366',
      '100': '#FEDE4D',
      '200': '#FDD935',
      '300': '#FDD41C',
      '400': '#FCCF03',
      '500': '#FFD100',
      '600': '#C8A504',
      '700': '#AF9004',
      '800': '#967B03',
      '900': '#7C6603',
    },
    LINK: {
      '50': '#014FE5',
      '100': '#0147CB',
      '200': '#013EB1',
      '300': '#023597',
      '400': '#022D7E',
      '500': '#00266E',
      '600': '#011B4B',
      '700': '#011232',
      '800': '#010919',
      '900': '#010918',
    },
    ERROR: {
      '50': '#FFA566',
      '100': '#FE964D',
      '200': '#FD8735',
      '300': '#FD781C',
      '400': '#FC6A03',
      '500': '#FF6900',
      '600': '#C85504',
      '700': '#AF4A04',
      '800': '#964003',
      '900': '#7C3503',
    },
    SUCCESS: {
      '50': '#C1FF45',
      '100': '#B8FE2D',
      '200': '#AFFD14',
      '300': '#A4F502',
      '400': '#93DB03',
      '500': '#94DE00',
      '600': '#71A803',
      '700': '#608F03',
      '800': '#4F7503',
      '900': '#3E5C02',
    },
    CHARGING: {
      '50': '#FFE366',
      '100': '#FEDE4D',
      '200': '#FDD935',
      '300': '#FDD41C',
      '400': '#FCCF03',
      '500': '#FFD100',
      '600': '#C8A504',
      '700': '#AF9004',
      '800': '#967B03',
      '900': '#7C6603',
    },
    AVAILABLE: {
      '50': '#C1FF45',
      '100': '#B8FE2D',
      '200': '#AFFD14',
      '300': '#A4F502',
      '400': '#93DB03',
      '500': '#94DE00',
      '600': '#71A803',
      '700': '#608F03',
      '800': '#4F7503',
      '900': '#3E5C02',
    },
    SECONDARY: {
      '50': '#014FE5',
      '100': '#0147CB',
      '200': '#013EB1',
      '300': '#023597',
      '400': '#022D7E',
      '500': '#00266E',
      '600': '#011B4B',
      '700': '#011232',
      '800': '#010919',
      '900': '#010918',
    },
    RESERVE: {
      '50': '#40FFE2',
      '100': '#28FEDD',
      '200': '#0FFDD9',
      '300': '#02F0CC',
      '400': '#03D6B6',
      '500': '#00D9B8',
      '600': '#03A38B',
      '700': '#038A75',
      '800': '#037060',
      '900': '#02574A',
    },
    INOPERATIVE: {
      '50': '#FFA566',
      '100': '#FE964D',
      '200': '#FD8735',
      '300': '#FD781C',
      '400': '#FC6A03',
      '500': '#FF6900',
      '600': '#C85504',
      '700': '#AF4A04',
      '800': '#964003',
      '900': '#7C3503',
    },
  },
};
export default IpirangaConfig;
