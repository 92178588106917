import React from 'react';
import { Scope } from '@voltbras/auth-directives';
import Config from 'config';
import useHasScopes from 'hooks/useHasScope';
import useHasModule from 'hooks/useHasModule';
import { useLocation } from 'react-router-dom';
import { TranslationKeyType } from 'types/translation';
import { Module } from 'generated/graphql';
import MenuItem from './MenuItem/MenuItem';

type Route = {
  label: TranslationKeyType;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  path?: string;
  requiredScope: Scope[];
  requiredModule?: Module;
};

const Routes: Route[] = [
  {
    label: 'sidebar.home.label',
    icon: Config.ICONS.SIDEBAR.HOME,
    path: '/',
    requiredScope: ['station:read'],
  },
  {
    label: 'sidebar.history.label',
    icon: Config.ICONS.SIDEBAR.HISTORY,
    path: '/historico',
    requiredScope: ['session:read', 'station:read'],
  },
  {
    label: 'sidebar.map.label',
    path: '/mapa',
    icon: Config.ICONS.SIDEBAR.MAP,
    requiredScope: ['station:read'],
  },
  {
    label: 'sidebar.my-electroposts.label',
    path: '/meus-eletropostos',
    icon: Config.ICONS.SIDEBAR.STATION,
    requiredScope: ['station:read'],
  },
  {
    label: 'sidebar.payments.label',
    path: '/pagamentos',
    icon: Config.ICONS.SIDEBAR.PAYMENT,
    requiredScope: ['order:read'],
    requiredModule: 'PAYMENT',
  },
  // {
  //   label: 'sidebar.reservations.label',
  //   path: '/reservas',
  //   icon: Config.ICONS.SIDEBAR.RESERVATION,
  // },
  {
    label: 'sidebar.my-clients.label',
    path: '/meus-clientes',
    icon: Config.ICONS.SIDEBAR.USER,
    requiredScope: ['driver:read'],
    requiredModule: 'USERS' || 'FLEET',
  },
  {
    label: 'sidebar.rfid.label',
    path: '/rfid',
    icon: Config.ICONS.SIDEBAR.RFID,
    requiredScope: ['rfid:read'],
    requiredModule: 'RFID',
  },
  {
    label: 'sidebar.comments.label',
    path: '/comentarios',
    icon: Config.ICONS.SIDEBAR.COMMENT,
    requiredScope: ['comment:read'],
  },
  {
    label: 'sidebar.monitoring.label',
    path: '/monitoring',
    icon: Config.ICONS.SIDEBAR.MONITORING,
    requiredScope: ['station:read'],
  },
  {
    label: 'sidebar.settings.label',
    path: '/configuracoes',
    // TODO: change
    icon: Config.ICONS.SIDEBAR.MONITORING,
    requiredScope: ['station:internal'],
  },
];

const Menu: React.FC<{ showText: boolean }> = ({ showText }) => {
  const currentRoute = useLocation();
  const hasScopes = useHasScopes();
  const hasModule = useHasModule();

  const checkIfRoutePathIsActive = (path?: string) => {
    if (!path) return false;
    if (path === '/') return path === currentRoute.pathname;
    if (currentRoute.pathname.startsWith(path)) return true;
    return path === currentRoute.pathname;
  };

  const checkWhitelabelHasModule = (route?: Route) =>
    route?.requiredModule ? hasModule(route.requiredModule) : true;

  const checkUserHasRole = (route?: Route) =>
    route?.requiredScope ? hasScopes(route.requiredScope) : true;

  return (
    <div style={{ flexDirection: 'column' }}>
      {Routes.filter(checkWhitelabelHasModule)
        .filter(checkUserHasRole)
        .map((route) => (
          <MenuItem
            {...route}
            key={route.label}
            isActive={checkIfRoutePathIsActive(route.path)}
            showText={showText}
            data-test={`sidebar-item-${route.label.slugify()}`}
          />
        ))}
    </div>
  );
};

export default Menu;
