import Typography from 'components/Typography';
import Config from 'config';
import styled from 'styled-components';

export const Title = styled(Typography)`
  margin-bottom: 16px;
`;

export const Container = styled.div`
  position: absolute;
  right: 0px;
  top: 61px;
  min-height: 310px;
  width: calc(100% - 36px);
  box-sizing: border-box;
  background: ${({ theme }) => theme.COLORS.WHITE};
  border: 2px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 15px 20px 31px;
  z-index: 2;
  margin-left: 20px;
  margin-right: 36px;
`;

export const CloseButton = styled(Config.ICONS.X)`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 16px;
  width: 16px;
  padding: 5px;
  cursor: pointer;
  box-sizing: unset;
`;

export const AllInputsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const ErrorText = styled(Typography)`
  margin-bottom: 16px;
`;

export const FilterText = styled(Typography)`
  margin-right: 15px;
`;

export const BlueText = styled(Typography)`
  width: 180px;
  margin-top: 20px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Footer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 45px;
  width: 152px;
  flex-flow: row nowrap;
  align-items: center;
`;
