import React from 'react';
import { OrderStatus } from 'generated/graphql';
import { Container } from './styles';
import Card from './Card';

export type Status = {
  moneyValue: string;
  status: string;
  statusType: OrderStatus;
  description: string;
  date: string;
};

export type Props = {
  transitions: Array<Status>;
};

const PaymentStatus: React.FC<Props> = ({ transitions }) => (
  <Container>
    {transitions[1] && <Card {...transitions[1]} state="enabled"></Card>}
    <Card
      {...transitions[0]}
      state={transitions[1] ? 'disabled' : 'enabled'}
    ></Card>
  </Container>
);

export default PaymentStatus;
