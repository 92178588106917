import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationUnlockMethods(
    $where: SelectStationInput!
    $data: UnlockMethodsInput!
  ) {
    changeStationUnlockMethods(where: $where, data: $data)
  }
`;
