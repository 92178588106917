import styled from 'styled-components';

export const Container = styled.div`
  min-height: 148px;
  display: flex;
  height: 100%;
  overflow: hidden;
  padding-bottom: 50px;
`;

export const TagContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const JSONWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 21px;
  padding-bottom: 16px;
`;

const tableRowHeight = '68px';

export const JSONSidebar = styled.div`
  left: 0;
  position: absolute;
  height: calc(100% + ${tableRowHeight} + 1px);
  width: 5px;
  transform: translateY(-${tableRowHeight});

  background-color: ${({ theme }) => theme.COLORS.SIDEBAR};
`;
