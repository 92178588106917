import { Box, Button, Heading } from '@chakra-ui/react';
import { FormEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMutation from 'hooks/useAuthMutation';
import loginMutation from 'graphql/mutations/login';
import { LoginMutation, MutationLoginArgs } from 'generated/graphql';
import { AccountExclusionAuthContext } from 'main/account-exclusion/contexts/account-exclusion';
import { useTranslation } from 'react-i18next';
import { useURLParams } from 'hooks/useURLParam';
import { AccountExclusionFormError } from './error';
import { ForgotPasswordLink } from '../forgot-password-link';
import { PasswordInput } from './password-input';
import { EmailInput } from './email-input';
import { BlankState } from '../blank-state';

export const Form = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { setEmail: setContextEmail, setToken: setContextToken } = useContext(
    AccountExclusionAuthContext
  );
  const [login, { loading }] = useMutation<LoginMutation, MutationLoginArgs>(
    loginMutation
  );
  const urlParam = useURLParams();
  const appCode = urlParam.get('appCode');

  if (!appCode) return <BlankState />;

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const { data } = await login({
        variables: {
          authMethod: 'EXTERNAL_FIREBASE',
          data: {
            email: emailInput,
            emspId: appCode,
            password,
          },
        },
      });
      if (data) {
        setContextEmail(emailInput);
        setContextToken(data.login.authToken);
        history.push(`/request-account-exclusion/?appCode=${appCode}`);
      }
    } catch (error) {
      const isWrongCredentialsError =
        error.message.includes('auth/user-not-found') ||
        error.message.includes('auth/invalid-email') ||
        error.message.includes('auth/wrong-password');

      if (error instanceof Error && isWrongCredentialsError)
        setErrorMessage(t('login.incorrect-fields-error-message'));

      return setErrorMessage(t('login.generic-error-message'));
    }
  };

  const handleChangeInputEmail = (value: string) => {
    setErrorMessage(null);
    setEmailInput(value);
  };

  const handleChangeInputPassword = (value: string) => {
    setErrorMessage(null);
    setPassword(value);
  };

  const isButtonDisabled =
    !!errorMessage || !emailInput.trim() || !password.trim();

  return (
    <Box w="full">
      <Heading fontSize={{ base: 24, md: 32 }}>
        {t('account-exclusion-form.title')}
      </Heading>

      <form onSubmit={handleLogin}>
        <AccountExclusionFormError text={errorMessage} />

        <EmailInput onChange={(value) => handleChangeInputEmail(value)} />
        <PasswordInput
          showPassword={showPassword}
          onChange={(e) => handleChangeInputPassword(e)}
          setShowPassword={() => setShowPassword((state) => !state)}
        />
        <ForgotPasswordLink appCode={appCode} />

        <Button
          mt={10}
          w="full"
          type="submit"
          disabled={isButtonDisabled}
          isLoading={loading}
        >
          {t('account-exclusion-form.submit-button-text')}
        </Button>
      </form>
    </Box>
  );
};
