import Container from 'atomic-components/organisms/Container';
import {
  Stack,
  Heading,
  Box,
  Flex,
  Text,
  Tooltip,
  Switch,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import Card from 'pages/StationPage/Card';
import { useAuthMutation, useAuthQuery } from 'hooks';
import {
  ConfigureStationChargeFinishedMessageMutation,
  ConfigureStationChargeFinishedMessageMutationVariables,
  DisableStationChargeFinishedMessageMutation,
  DisableStationChargeFinishedMessageMutationVariables,
  StationChargeFinishedMessageQuery,
  StationChargeFinishedMessageQueryVariables,
} from 'generated/graphql';
import ConfigureStationChargeFinishedMessage from 'graphql/mutations/configureStationChargeFinishedMessage';
import { useCallback, useContext, useEffect, useState } from 'react';
import { StationContext } from 'pages/StationPage/StationContext';
import Toast from 'pages/StationPage/Toast';
import toast from 'services/toast';
import StationChargeFinishedMessage from 'graphql/queries/stationChargeFinishedMessage';
import DisableChargeFinishedMessage from 'graphql/mutations/disableChargeFinishedMessage';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const { t } = useTranslation();
  const { id: stationId } = useContext(StationContext);
  const [description, setDescription] = useState({ value: '', error: false });
  const [title, setTitle] = useState({
    value: '',
    error: false,
  });
  const [hasChargeFinishedMessage, setHasChargeFinishedMessage] =
    useState(false);

  const { data, loading: loadingData } = useAuthQuery<
    StationChargeFinishedMessageQuery,
    StationChargeFinishedMessageQueryVariables
  >(StationChargeFinishedMessage, {
    variables: {
      where: {
        stationId,
      },
    },
  });

  const chargeInfo = data?.station?.chargeFinishedMessage;
  // This is to close when user navigates to another page with toast open
  const toastOnAnotherPage = useToast();

  const [currentDescription, setCurrentDescription] = useState(
    chargeInfo?.description ?? ''
  );
  const [currentTitle, setCurrentTitle] = useState(chargeInfo?.title ?? '');
  const [currentHasChargeFinishedMessage, setCurrentHasChargeFinishedMessage] =
    useState(chargeInfo?.active ?? false);
  const [toastVisible, setToastVisible] = useState(false);
  const [configureStationChargeFinishedMessage, { loading: loadingConfigure }] =
    useAuthMutation<
      ConfigureStationChargeFinishedMessageMutation,
      ConfigureStationChargeFinishedMessageMutationVariables
    >(ConfigureStationChargeFinishedMessage, {
      variables: {
        where: {
          stationId,
        },
        data: {
          description: description.value,
          title: title.value,
        },
      },
    });

  const [disableChargeFinishedMessage, { loading: loadingDisable }] =
    useAuthMutation<
      DisableStationChargeFinishedMessageMutation,
      DisableStationChargeFinishedMessageMutationVariables
    >(DisableChargeFinishedMessage, {
      variables: {
        where: {
          stationId,
        },
      },
    });

  useEffect(() => {
    setCurrentDescription(chargeInfo?.description ?? '');
    setCurrentTitle(chargeInfo?.title ?? '');
    setCurrentHasChargeFinishedMessage(chargeInfo?.active ?? false);
    setDescription({ error: false, value: chargeInfo?.description ?? '' });
    setTitle({ error: false, value: chargeInfo?.title ?? '' });
    setHasChargeFinishedMessage(chargeInfo?.active ?? false);
  }, [chargeInfo]);

  const handleUpdateOldValues = async () => {
    setCurrentDescription(description.value);
    setCurrentTitle(title.value);
    setCurrentHasChargeFinishedMessage(hasChargeFinishedMessage);
  };

  const validateFields = () => {
    if (!title.value) {
      setTitle({ ...title, error: true });
      return false;
    }
    if (!description.value) {
      setDescription({ ...description, error: true });
      return false;
    }
    return true;
  };

  const closeToast = useCallback(() => {
    setToastVisible(false);
    toastOnAnotherPage.closeAll();
  }, [toastOnAnotherPage]);

  const openToast = () => {
    setToastVisible(true);
  };

  useEffect(() => {
    const oldVariables = {
      description: currentDescription,
      title: currentTitle,
      isChargeMessage: currentHasChargeFinishedMessage,
    };
    const newVariables = {
      description: description.value,
      title: title.value,
      isChargeMessage: hasChargeFinishedMessage,
    };
    if (JSON.stringify(oldVariables) !== JSON.stringify(newVariables)) {
      openToast();
    } else {
      closeToast();
    }
  }, [
    description,
    title,
    hasChargeFinishedMessage,
    closeToast,
    currentDescription,
    currentTitle,
    currentHasChargeFinishedMessage,
  ]);

  const handleSetIsChargeFinishedMessage = () => {
    setTitle({ ...title, error: false });
    setDescription({ ...description, error: false });
    setHasChargeFinishedMessage(!hasChargeFinishedMessage);
  };

  const handleConfigureStationChargeMessage = async () => {
    if (!hasChargeFinishedMessage) {
      try {
        await disableChargeFinishedMessage();
        await handleUpdateOldValues();
        toast.success(
          t('station-page.config-tab.notification-tab.toast-successfully-msg')
        );
      } catch (e) {
        toast.error(
          t('station-page.config-tab.notification-tab.toast-error-msg')
        );
      }
    } else {
      if (!validateFields()) return;
      try {
        await configureStationChargeFinishedMessage();
        await handleUpdateOldValues();
        toast.success(
          t('station-page.config-tab.notification-tab.toast-successfully-msg')
        );
      } catch (e) {
        toast.error(
          t('station-page.config-tab.notification-tab.toast-error-msg')
        );
      }
    }
    closeToast();
  };

  const resetFields = async () => {
    setDescription({ error: false, value: currentDescription || '' });
    setTitle({
      error: false,
      value: currentTitle ?? '',
    });
    setHasChargeFinishedMessage(currentHasChargeFinishedMessage ?? false);
    closeToast();
  };

  const isValid = {
    description: description.value?.length <= 200,
  };
  return (
    <Container loading={loadingData}>
      <Stack spacing="8">
        <Heading fontSize="2xl">
          {t('station-page.config-tab.notification-tab.header.title')}
        </Heading>
        <Box maxWidth="2xl">
          <Flex alignItems="center" gridGap="2" pb="2">
            <Heading fontSize="md">
              {t('station-page.config-tab.notification-tab.header.subtitle')}
            </Heading>
            <Tooltip
              label={t('station-page.config-tab.notification-tab.header.label')}
              bg="gray.900"
              placement="right"
              hasArrow
            >
              <Stack>
                <Icon type="NEW_INFO_FILL" color="SECONDARY_GRAY" />
              </Stack>
            </Tooltip>
          </Flex>
          <Card>
            <Stack spacing="6">
              <Flex alignItems="center">
                <Switch
                  flexDirection="row"
                  display="flex"
                  alignItems="center"
                  isChecked={hasChargeFinishedMessage}
                  onChange={() => handleSetIsChargeFinishedMessage()}
                  m="0"
                >
                  <Heading fontSize="sm" pl="2">
                    {t(
                      'station-page.config-tab.notification-tab.container.title'
                    )}
                  </Heading>
                </Switch>
              </Flex>
              <FormControl isRequired isInvalid={title.error}>
                <FormLabel>
                  {t(
                    'station-page.config-tab.notification-tab.form.message.title'
                  )}
                </FormLabel>
                <Input
                  maxLength={50}
                  borderRadius="8"
                  value={title.value}
                  disabled={!hasChargeFinishedMessage}
                  onChange={(e) =>
                    setTitle({ ...title, value: e.target.value })
                  }
                  onFocus={() => setTitle({ ...title, error: false })}
                />
              </FormControl>
              <FormControl isRequired isInvalid={description.error}>
                <FormLabel>
                  {t(
                    'station-page.config-tab.notification-tab.form.description.title'
                  )}
                </FormLabel>
                <Textarea
                  bg="white"
                  fontSize="sm"
                  value={description.value}
                  disabled={!hasChargeFinishedMessage}
                  onChange={(e) =>
                    setDescription({ ...description, value: e.target.value })
                  }
                  onFocus={() =>
                    setDescription({ ...description, error: false })
                  }
                  focusBorderColor={
                    isValid.description ? 'primary.500' : 'error.500'
                  }
                />
                <Flex pt="2" alignItems="center">
                  <Spacer>
                    <FormErrorMessage>
                      {t(
                        'station-page.config-tab.notification-tab.required-field-error'
                      )}
                    </FormErrorMessage>
                  </Spacer>
                  <Text
                    fontSize="sm"
                    color={isValid.description ? 'gray.500' : 'error.500'}
                  >{`${t('station-page.caracters.title')} - ${
                    description.value?.length
                  }/200`}</Text>
                </Flex>
              </FormControl>
              <Toast
                id={'chargeFinishedMessage'}
                resetFields={resetFields}
                onClick={handleConfigureStationChargeMessage}
                loading={loadingConfigure || loadingDisable}
                isVisible={toastVisible}
                isValid={isValid.description}
                inputsValues={[description.value, title.value, toastVisible]}
              />
            </Stack>
          </Card>
        </Box>
      </Stack>
    </Container>
  );
};

export default Notification;
