import styled from 'styled-components';
import Config from 'config';

export const CloseButton = styled(Config.ICONS.X)`
  position: absolute;
  top: 12px;
  right: 20px;
  height: 20px;
  width: 20px;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
  margin: 5px -7px -7px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  }
`;
