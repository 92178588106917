import type { FilterTerm, CheckboxGroupFilter } from 'types';

import React from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { GroupOuterContainer, GroupContainer } from './styles';

type Props<U extends string> = {
  filter: CheckboxGroupFilter<U>;

  getChecked: (term: FilterTerm<U>) => boolean;
  submitCheckbox: (term: FilterTerm<U>) => void;
  toggleCheckbox: (term: FilterTerm<U>) => void;
};

export const CheckBoxGroupInput = <U extends string>({
  filter: { type, label, boxes },
  getChecked,
  submitCheckbox,
  toggleCheckbox,
}: Props<U>) => (
  <GroupOuterContainer>
    <Text fontSize="small" fontWeight="medium">
      {label}
    </Text>
    <GroupContainer>
      {boxes.map(({ value, label }, index) => {
        const term = { type, value, label };
        const onChange = () => {
          submitCheckbox(term);
          toggleCheckbox(term);
        };
        const checked = getChecked(term);

        return (
          <Flex alignItems="flex-end" key={index} mr={8} h={9}>
            <Checkbox isChecked={checked} onChange={onChange}>
              <Text fontSize="sm">{label}</Text>
            </Checkbox>
          </Flex>
        );
      })}
    </GroupContainer>
  </GroupOuterContainer>
);

export default CheckBoxGroupInput;
