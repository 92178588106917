import type {
  DateTimeResolution,
  PerDateTimeInput,
  TimeRangeInput,
} from 'generated/graphql';
import type { DurationInputArg2 } from 'moment';
import moment from 'moment';

type DateLike = string | Date | moment.Moment;

export interface TimeRange {
  label: string;
  shortLabel: string;
  format(date: DateLike): string;
  toTimeRangeInput(date?: DateLike): TimeRangeInput;
  toPerDateTimeInput(date?: DateLike): PerDateTimeInput;
  convertDateTimeByCurrentResolution(date: DateLike): DateLike;
}

export class FromDateUntilDate implements TimeRange {
  constructor(
    readonly label: string,
    public shortLabel: string,
    private readonly formatString: string,
    public from: DateLike | null,
    public to: DateLike | null,
    private readonly formatShortLabel?: (
      fromLabel: string,
      toLabel: string
    ) => string
  ) {
    this.originalShortLabel = shortLabel;
  }

  private resolution: DateTimeResolution = 'DAY';

  private readonly originalShortLabel: string;

  setFrom(from: DateLike | null) {
    this.from = from;
  }

  setTo(to: DateLike | null) {
    this.to = to;
  }

  apply = (from: DateLike | null, to: DateLike | null) => {
    this.setFrom(from);
    this.setTo(to);
    const fromLabel = moment(this.from).format(this.formatString);
    const toLabel = moment(this.to).format(this.formatString);
    this.shortLabel = this.formatShortLabel
      ? this.formatShortLabel(fromLabel, toLabel)
      : `${fromLabel} - ${toLabel}`;
  };

  unapply() {
    this.setFrom(null);
    this.setTo(null);
    this.shortLabel = this.originalShortLabel;
  }

  // Change according to UI spec
  // @ts-ignore
  format = (date: DateLike) => moment(date).format(this.formatString);

  // TODO: convert to class method?
  toTimeRangeInput = () => ({
    // @ts-ignore
    from: moment(this.from).startOf('day').toISOString(),
    // @ts-ignore
    to: moment(this.to).endOf('day').toISOString(),
  });

  toPerDateTimeInput = () => ({
    resolution: this.resolution,
    timeRange: this.toTimeRangeInput(),
  });

  convertDateTimeByCurrentResolution = (date: DateLike) =>
    this.resolution === 'HOUR' ? date : moment().startOf('day');
}
/**
 * This is responsible for getting a timerange from sometime in the past until the present moment
 */
export class FromDateOnwards implements TimeRange {
  constructor(
    private readonly unitsAgo: number,
    private readonly resolution: DateTimeResolution,
    private readonly duration: DurationInputArg2,
    readonly label: string,
    readonly shortLabel: string,
    private readonly formatString: string
  ) {}

  // @ts-ignore
  format = (date?: DateLike) => moment(date).format(this.formatString);

  toTimeRangeInput = (date?: DateLike): TimeRangeInput => ({
    from: this.getStartOfTimeRange(date).toISOString(),
    to: undefined,
  });

  toPerDateTimeInput = (date?: DateLike): PerDateTimeInput => {
    const { resolution } = this;
    return {
      resolution,
      timeRange: this.toTimeRangeInput(date),
    };
  };

  getStartOfTimeRange = (date?: DateLike): moment.Moment => {
    const { unitsAgo, duration } = this;

    return moment(date).subtract(unitsAgo, duration);
  };

  convertDateTimeByCurrentResolution = (date: DateLike) => {
    const { resolution } = this;
    return resolution === 'HOUR' ? date : moment().startOf('day');
  };
}

export const TIME_PERIOD_KEYS = [
  'TODAY',
  'WEEK',
  'MONTH',
  'TRIMESTER',
  'SEMESTER',
  'YEAR',
  'ALL',
] as const;

export type FromDateOnwardsKey = typeof TIME_PERIOD_KEYS[number];

export const TIME_PERIODS: Record<FromDateOnwardsKey, FromDateOnwards> = {
  TODAY: new FromDateOnwards(
    24,
    'HOUR',
    'hour',
    'dropdown.time.periods.today.title',
    'dropdown.time.periods.today.short-title',
    'HH:mm'
  ),
  WEEK: new FromDateOnwards(
    7,
    'DAY',
    'days',
    'dropdown.time.periods.week.title',
    'dropdown.time.periods.week.short-title',
    'MMM-DD'
  ),
  MONTH: new FromDateOnwards(
    30,
    'DAY',
    'days',
    'dropdown.time.periods.month.title',
    'dropdown.time.periods.month.short-title',
    'MMM-DD'
  ),
  TRIMESTER: new FromDateOnwards(
    3,
    'MONTH',
    'months',
    'dropdown.time.periods.trimester.title',
    'dropdown.time.periods.trimester.short-title',
    'MMM-DD'
  ),
  SEMESTER: new FromDateOnwards(
    6,
    'MONTH',
    'months',
    'dropdown.time.periods.semester.title',
    'dropdown.time.periods.semester.short-title',
    'MMM'
  ),
  YEAR: new FromDateOnwards(
    12,
    'MONTH',
    'months',
    'dropdown.time.periods.year.title',
    'dropdown.time.periods.year.short-title',
    'MMM'
  ),
  ALL: new FromDateOnwards(
    12,
    'YEAR',
    'years',
    'dropdown.time.periods.default-filter.title',
    'dropdown.time.periods.default-filter.title',
    'MMM-YYYY'
  ),
};

export const TIME_PERIODS_LIST = TIME_PERIOD_KEYS.map(
  (k) => TIME_PERIODS[k]
).slice(0, 6);

export const DEFAULT_PERIOD = TIME_PERIODS.WEEK;
