import { gql } from '@apollo/client';

export default gql`
  query Memberships {
    memberships {
      id
      active
      role
      scopes
      organizationId
      language
      profile {
        id
        firstName
        lastName
        email
        cellPhone
      }
      organization {
        id
        name
        dashboardUrl
        parentOrganizationId
        modules
        emsp {
          id
          sessionPaymentConfig {
            currencyType
          }
        }
      }
    }
  }
`;
