import {
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
} from '@apollo/client';
import AuthContext from 'contexts/Auth/context';
import { DocumentNode } from 'graphql';
import { merge } from 'lodash';
import { useContext, useState } from 'react';
import { useClient } from 'urql';

export default function useAuthMutation<
  TData = any,
  TVariables extends object = OperationVariables
>(
  Mutation: DocumentNode,
  options?: Pick<
    MutationHookOptions<TData, TVariables, RequestInit>,
    'variables' | 'context'
  >
): MutationTuple<TData, TVariables> {
  const client = useClient();
  const { selectedOrganizationIds } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TData>();
  const [error, setError] = useState();

  const fetchOptions = {
    ...(typeof client.fetchOptions === 'function'
      ? client.fetchOptions?.()
      : client.fetchOptions),
    headers: {
      ...(typeof client.fetchOptions === 'function'
        ? client.fetchOptions?.().headers
        : client.fetchOptions?.headers),
      'org-ids': selectedOrganizationIds as any,
    },
  };

  return [
    async (opts) => {
      setLoading(true);
      try {
        const { data, error } = await client
          .mutation<TData, TVariables>(
            Mutation,
            opts?.variables ?? options?.variables,
            {
              ...opts?.context,
              fetchOptions: merge(fetchOptions, options?.context),
            }
          )
          .toPromise();
        if (error || !data) throw error;
        setData(data);
        setLoading(false);
        return { data };
      } catch (error: any) {
        setError(error);
        setLoading(false);
        throw error;
      }
    },
    {
      called: false,
      client: {} as any,
      loading,
      data,
      error,
    },
  ];
}
