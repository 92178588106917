import { Flex, Stack } from '@chakra-ui/react';
import LoadingComponent from 'new-components/LoadingComponent';
import { ReactNode } from 'react';

type Props = {
  loading?: boolean;
  children?: ReactNode;
};

const Container = ({ loading, children }: Props) => {
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" mt="40">
        <LoadingComponent size={51} />
      </Flex>
    );
  }
  return <Stack p="4">{children}</Stack>;
};

export default Container;
