import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Heading,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  Switch,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import Card from 'components/Card';
import ToggleTFAStatusModal from 'atomic-components/organisms/modals/TFA/ToggleTFAStatus';
import ConfirmPasswordTFAModal from 'atomic-components/organisms/modals/TFA/ConfirmPasswordTFA';
import { dispatchTFACode, getTFAFactorStatus } from 'services/tfa';
import { MultiFactorResolver, RecaptchaVerifier } from 'firebase/auth';
import { auth } from 'services/identity-provider/firebaseConfig';
import useHasModule from 'hooks/useHasModule';
import { useAuthMutation } from 'hooks';
import {
  UpdateOwnMemberProfileMutation,
  UpdateOwnMemberProfileMutationVariables,
} from 'generated/graphql';
import updateOwnMemberProfileMutation from 'graphql/mutations/updateOwnMemberProfile';
import Toast from 'pages/StationPage/Toast';
import AuthContext from 'contexts/Auth/context';
import toast from 'services/toast';
import { useTranslation } from 'react-i18next';
import {
  getOrgCountryDDIPhoneNumber,
  getOrgCountryPhoneNumberMask,
  getOrgCountryMinimumPhoneNumberLength,
  getOrgCountryMaxPhoneNumberLength,
} from 'services/organization';

const Profile: React.FC = () => {
  const { currentUser } = auth;
  const { member, setMember } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState(
    member?.profile.cellPhone ?? ''
  );
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(
    member?.profile.cellPhone ?? ''
  );
  const [isEnabled, setIsEnabled] = useState(getTFAFactorStatus());
  const [isConfirmPasswordModalVisible, setIsConfirmPasswordModalVisible] =
    useState(false);
  const [isToggleTFAModalVisible, setIsToggleTFAModalVisible] = useState(false);
  const [isInputPhoneFocused, setIsInputPhoneFocused] =
    useState<boolean>(false);

  const [isInvalidMessage, setIsInvalidMessage] = useState<string | null>('');
  const [hashedVerificationCode, setHashedVerificationCode] = useState('');
  const [recaptchaVerifierState, setRecaptchaVerifierState] = useState<
    RecaptchaVerifier | undefined
  >(undefined);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [disableTFAInfo, setDisableTFAInfo] = useState<
    | {
        verificationId: string;
        resolver: MultiFactorResolver;
      }
    | undefined
  >(undefined);

  const { t } = useTranslation();
  const hasModule = useHasModule();

  const orgCountryDDIPhoneNumber = getOrgCountryDDIPhoneNumber();
  const hasMinimumPhoneNumberLength =
    phoneNumber?.length >= getOrgCountryMinimumPhoneNumberLength();
  const isPhoneInvalid =
    phoneNumber?.trim() === '' || !hasMinimumPhoneNumberLength;

  const [dispatchUpdateOwnMemberProfile, { loading }] = useAuthMutation<
    UpdateOwnMemberProfileMutation,
    UpdateOwnMemberProfileMutationVariables
  >(updateOwnMemberProfileMutation);

  const openToast = () => {
    setIsToastVisible(true);
  };
  const closeToast = () => {
    setIsToastVisible(false);
  };

  useEffect(() => {
    if (currentPhoneNumber !== phoneNumber) {
      openToast();
    } else {
      closeToast();
    }
  }, [phoneNumber, currentPhoneNumber]);

  if (!currentUser) return null;

  const resetToogleField = () => setIsEnabled((state) => !state);

  const handleChangeSwitchValue = async () => {
    if (phoneNumber?.trim() === '') {
      setIsInvalidMessage(t('profile.required-field-error-message'));
      setIsInvalidMessage('');
      return;
    }
    if (!hasMinimumPhoneNumberLength) {
      setIsInvalidMessage(t('profile.invalid-cell-phone.message'));
      return;
    }
    setIsEnabled((state) => !state);
    setIsConfirmPasswordModalVisible(true);
  };

  const handleCloseConfirmPasswordTFAModal = () => {
    setIsConfirmPasswordModalVisible(false);
    resetToogleField();
  };

  const onFinishPasswordConfirmation = async () => {
    setIsConfirmPasswordModalVisible(false);
    setIsToggleTFAModalVisible(true);
  };

  const formatPhoneNumberWithDDI = () =>
    `${orgCountryDDIPhoneNumber}${phoneNumber}`;

  const sendTFACode = async (captcha: RecaptchaVerifier) => {
    if (!currentUser) return;
    const cellPhoneWithDDI = formatPhoneNumberWithDDI();
    const { verificationId, recaptchaVerifier } = await dispatchTFACode(
      cellPhoneWithDDI,
      currentUser,
      captcha
    );
    setRecaptchaVerifierState(recaptchaVerifier);
    setHashedVerificationCode(verificationId);
  };

  const handleCloseToggleTFAStatusModal = () => {
    setIsToggleTFAModalVisible(false);
    resetToogleField();
  };

  const updateContextFields = () => {
    if (!member) return;
    setMember({
      ...member,
      profile: {
        ...member?.profile,
        cellPhone: phoneNumber,
      },
    });
    closeToast();
    setCurrentPhoneNumber(phoneNumber);
  };

  const handleDispatchUpdateOwnMemberProfile = async () => {
    try {
      await dispatchUpdateOwnMemberProfile({
        variables: {
          data: {
            cellPhone: phoneNumber,
          },
        },
      });
      updateContextFields();
      toast.success(t('profile.toast-success-message'));
    } catch (e) {
      toast.error(t('profile.toast-error-message'));
    }
  };

  const handleResetChanges = async () => {
    setPhoneNumber(currentPhoneNumber);
    closeToast();
  };

  const onBlurNumberFormatInput = () => {
    if (phoneNumber?.trim() !== '' && !hasMinimumPhoneNumberLength) {
      setIsInvalidMessage(t('profile.invalid-cell-phone.message'));
    }
    setIsInputPhoneFocused(false);
  };

  return (
    <Flex w="full" bg="gray.50" flexDirection="column" width="full">
      <Box p={6} pt="7" bg="white">
        <Heading fontSize="2xl">{t('profile.title')}</Heading>
      </Box>
      <Box mx={6} mt={8} maxWidth="7xl">
        <Card maxHeight={800} type="complex">
          <HStack
            justifyContent="space-between"
            alignItems="flex-start"
            padding={6}
          >
            <Flex minWidth={60} flexDirection="column">
              <Heading fontSize="lg">{t('profile.card.title')}</Heading>
              <Box paddingLeft={1.5}>
                <FormControl isInvalid={!!isInvalidMessage}>
                  <FormLabel pt={4}>
                    {t('profile.card.cell-phone-form-label')}
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      borderColor={
                        isInputPhoneFocused ? 'primary.500' : 'gray.100'
                      }
                      backgroundColor="white"
                      children={orgCountryDDIPhoneNumber}
                    />
                    <NumberFormat
                      id="pin-input"
                      format={getOrgCountryPhoneNumberMask()}
                      mask="_"
                      allowEmptyFormatting
                      value={phoneNumber}
                      borderLeftRadius={'none'}
                      onValueChange={(values) => setPhoneNumber(values.value)}
                      customInput={Input}
                      onFocus={() => {
                        setIsInvalidMessage('');
                        setIsInputPhoneFocused(true);
                      }}
                      onBlur={onBlurNumberFormatInput}
                      maxLength={getOrgCountryMaxPhoneNumberLength()}
                    />
                  </InputGroup>
                  <FormErrorMessage maxWidth={60}>
                    {isInvalidMessage}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            {hasModule('TFA') && (
              <Flex flexDirection="column" paddingLeft={12}>
                <Heading fontSize="lg">
                  {t('profile.card.security-and-authentication.title')}
                </Heading>
                <Box>
                  <HStack mt={8}>
                    <Switch
                      display="flex"
                      isChecked={isEnabled}
                      isDisabled={isPhoneInvalid}
                      onChange={() => {
                        handleChangeSwitchValue();
                      }}
                    >
                      <Heading fontSize="md">
                        {t('profile.card.switch.title')}
                      </Heading>
                    </Switch>
                  </HStack>
                  <Box>
                    <Text color="gray.500" fontSize="sm" lineHeight={7}>
                      {t('profile.card.enable-tfa.text')}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            )}
          </HStack>
        </Card>
      </Box>
      <ToggleTFAStatusModal
        onClose={handleCloseToggleTFAStatusModal}
        show={isToggleTFAModalVisible}
        disableTFAInfo={disableTFAInfo}
        hashedVerificationCode={hashedVerificationCode}
        sendTFACode={sendTFACode}
        recaptchaVerifier={recaptchaVerifierState}
        phoneNumber={phoneNumber ?? '-'}
        onFinish={() => setIsToggleTFAModalVisible(false)}
      />
      <ConfirmPasswordTFAModal
        isOpen={isConfirmPasswordModalVisible}
        onClose={handleCloseConfirmPasswordTFAModal}
        onFinish={onFinishPasswordConfirmation}
        setDisableTFAInfo={setDisableTFAInfo}
        setHashedVerificationCode={setHashedVerificationCode}
        phoneNumber={phoneNumber ?? '-'}
      />
      <Toast
        id="saveCellPhone"
        resetFields={handleResetChanges}
        onClick={handleDispatchUpdateOwnMemberProfile}
        loading={loading}
        isVisible={isToastVisible}
        isValid={!isPhoneInvalid}
        inputsValues={[phoneNumber, currentPhoneNumber, isToastVisible]}
      />
    </Flex>
  );
};

export default Profile;
