import { createContext, ReactNode, useState } from 'react';

type AccountExclusionAuthContextArgs = {
  setEmail: (email: string) => void;
  setToken: (token: string) => void;
  setAppName: (appName: string) => void;
  token: string | null;
  email: string | null;
  appName: string | null;
};

type AccountExclusionProviderProps = {
  children: ReactNode;
};

export const AccountExclusionAuthContext =
  createContext<AccountExclusionAuthContextArgs>({
    setEmail: () => {},
    setToken: () => {},
    setAppName: () => {},

    token: null,
    email: null,
    appName: null,
  });

export const AccountExclusionProvider = ({
  children,
}: AccountExclusionProviderProps) => {
  const tokenFromSessionStorage = sessionStorage.getItem(
    '@voltbras/account-exclusion-token'
  );
  const appNameFromSessionStorage = sessionStorage.getItem(
    '@voltbras/account-exclusion-app-name'
  );
  const emailFromSessionStorage = sessionStorage.getItem(
    '@voltbras/account-exclusion-email'
  );

  const [token, setToken] = useState<string | null>(tokenFromSessionStorage);
  const [email, setEmail] = useState<string | null>(emailFromSessionStorage);
  const [appName, setAppName] = useState<string | null>(
    appNameFromSessionStorage
  );

  const handleSetAppName = (appName: string) => {
    sessionStorage.setItem('@voltbras/account-exclusion-app-name', appName);
    setAppName(appName);
  };
  const handleSetToken = (token: string) => {
    sessionStorage.setItem('@voltbras/account-exclusion-token', token);
    setToken(token);
  };
  const handleSetEmail = (email: string) => {
    sessionStorage.setItem('@voltbras/account-exclusion-email', email);
    setEmail(email);
  };

  return (
    <AccountExclusionAuthContext.Provider
      value={{
        setEmail: (email: string) => handleSetEmail(email),
        setToken: (token: string) => handleSetToken(token),
        setAppName: (appName: string) => handleSetAppName(appName),
        token,
        email,
        appName,
      }}
    >
      {children}
    </AccountExclusionAuthContext.Provider>
  );
};
