import { Organization } from 'contexts/Auth/context';
import {
  AllStationsOrganizationIndicatorsQuery,
  MemberRole,
  MembershipsQuery,
  Module,
  SessionsSummaryQuery,
} from 'generated/graphql';

export type OrganizationTree = {
  id: string;
  name: string;
  dashboardUrl: string;
  modules: Module[];
  parentOrganizationId?: string;
  suborganizations?: OrganizationTree[];
};

export type SummaryArray = Array<{ name: string; value: number }>;

export type OrgSummary = SessionsSummaryQuery['organizations'][number];

export const compareOrgs = (
  a: SummaryArray[number],
  b: SummaryArray[number]
) => {
  if (a.value > b.value) {
    return -1;
  }
  if (a.value < b.value) {
    return 1;
  }
  return 0;
};

export function treeStructureOrganizations(
  organizations: Organization[],
  finalOrganizations?: Organization[]
): OrganizationTree[] {
  if (!finalOrganizations) {
    // Define as organizações que vão aparecer no topo da árvore. São consideradas organizações
    // do topo, aquelas que coincidir com algum dos seguintes cenários:
    //   1 - Organização que o membro não tem acesso à organização criadora (parentOrganizationId).
    //   2 - Organização não ter parentOrganizationId
    const firstLevelOrganizations = organizations.filter(
      (org) =>
        !org.parentOrganizationId ||
        !organizations.map((o) => o.id).includes(org.parentOrganizationId)
    );

    return treeStructureOrganizations(
      organizations.filter(
        (org) =>
          !firstLevelOrganizations
            .map((filteredOrg) => filteredOrg.id)
            .includes(org.id)
      ),
      firstLevelOrganizations
    );
  }

  return finalOrganizations.map((org) => {
    const filteredOrganizations = organizations.filter(
      (subOrg) => subOrg.parentOrganizationId === org.id
    );

    return {
      ...org,
      suborganizations: treeStructureOrganizations(
        organizations.filter(
          (subOrg) =>
            !filteredOrganizations
              .map((filteredOrg) => filteredOrg.id)
              .includes(subOrg.id)
        ),
        filteredOrganizations
      ),
    };
  });
}

export function checkIfMemberBelongsToParentOrganization(
  organizations: Organization[],
  currentParentOrganizationId?: string
): boolean {
  return currentParentOrganizationId
    ? organizations?.map((o) => o.id).includes(currentParentOrganizationId)
    : false;
}

export const getUsageRateSummaryOfAllSelectedOrganizations = (
  indicatorsData?: AllStationsOrganizationIndicatorsQuery
) =>
  indicatorsData
    ? indicatorsData.organizations
        .map((org) => org.averageUsageRate)
        .reduce((a, b) => a + b, 0) / indicatorsData.organizations.length
    : 0;

export const getRechargesSummaryOfAllSelectedOrganizations = (
  indicatorsData?: AllStationsOrganizationIndicatorsQuery
) =>
  indicatorsData
    ? indicatorsData.organizations
        .map((org) => org.sessionsSummary.count)
        .reduce((a, b) => a + b, 0)
    : 0;

export const getAverageRevenueOfSelectedOrganizations = (
  organizations: Array<{ value: number; name: string }>,
  numberOfRecharges: number
) => {
  const revenue = organizations
    .map((org) => org.value)
    .reduce((revenueA, revenueB) => revenueA + revenueB, 0);

  return revenue / numberOfRecharges || 0;
};

const addOrgsSummaries = (
  orgA: Omit<OrgSummary, 'name'>,
  orgB: Omit<OrgSummary, 'name'>
): OrgSummary => ({
  name: 'summary',
  thisMonthSummary: {
    count: orgA.thisMonthSummary.count + orgB.thisMonthSummary.count,
    driverCount:
      orgA.thisMonthSummary.driverCount + orgB.thisMonthSummary.driverCount,
    energyConsumed:
      orgA.thisMonthSummary.energyConsumed +
      orgB.thisMonthSummary.energyConsumed,
    timeCharging:
      orgA.thisMonthSummary.timeCharging + orgB.thisMonthSummary.timeCharging,
  },
  lastMonthSummary: {
    count: orgA.lastMonthSummary.count + orgB.lastMonthSummary.count,
    driverCount:
      orgA.lastMonthSummary.driverCount + orgB.lastMonthSummary.driverCount,
    energyConsumed:
      orgA.lastMonthSummary.energyConsumed +
      orgB.lastMonthSummary.energyConsumed,
    timeCharging:
      orgA.lastMonthSummary.timeCharging + orgB.lastMonthSummary.timeCharging,
  },
});

export const computeOrgSummariesTotal = (
  orgs: SessionsSummaryQuery['organizations']
): Omit<OrgSummary, 'name'> =>
  orgs.reduce((prev, current) => addOrgsSummaries(prev, current));

export const getSmallerRoleAmongSelectedOrganizations = (
  memberships: MembershipsQuery['memberships'],
  selectedOrganizationIds: string[]
) => {
  const sortedRoles: MemberRole[] = [
    'VIEWER',
    'TECHNICIAN',
    'SUPPORT',
    'ADMIN',
    'OWNER',
    'STAFF',
  ];
  const memberOrganizationsSelected = memberships?.filter((membership) =>
    selectedOrganizationIds.includes(membership.organization.id)
  );

  const smallerRole = sortedRoles.find((r) =>
    memberOrganizationsSelected.some((m) => m.role === r)
  );

  const smallerRoleOrganization = memberships.find(
    (m) =>
      m.role === smallerRole &&
      selectedOrganizationIds.includes(m.organizationId)
  );
  return smallerRoleOrganization;
};
