import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  &:first-child {
    margin-left: 7.5px;
  }
  &:nth-child(n + 2) {
    margin-left: 8px;
  }
`;
