import getAdressInfo, { CEP as AddressInfo } from 'cep-promise';
import Card, { CardType } from 'components/Card';
import {
  AllowStationToBePubliclyAccessedViaOcppMutationVariables,
  ChangeStationChargePointIdMutationVariables,
  ChangeStationLocationMutationVariables,
  ChangeStationUnlockMethodsMutationVariables,
  ConfigureStationCommunicationMutationVariables,
  ConfigureStationVisibilityMutationVariables,
  StationOwnerEditDetailsQuery,
  StationOwnerEditDetailsQueryVariables,
} from 'generated/graphql';
import allowStationToBePubliclyAccessedViaOCPP from 'graphql/mutations/allowStationToBePubliclyAccessedViaOCPP';
import changeStationChargePointIdMutation from 'graphql/mutations/changeStationChargePointId';
import changeStationLocationMutation from 'graphql/mutations/changeStationLocation';
import changeStationUnlockMethodsMutation from 'graphql/mutations/changeStationUnlockMethods';
import configureStationCommunicationMutation from 'graphql/mutations/configureStationCommunication';
import configureStationVisibility from 'graphql/mutations/configureStationVisibility';
import stationOwnerEditDetails from 'graphql/queries/stationOwnerEditDetails';
import { useAuthMutation, useAuthQuery } from 'hooks';
import NewDropdown from 'new-components/NewDropdown';
import { StationContext } from 'pages/StationPage/StationContext';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'services/toast';
import ConfirmStationArchiving from 'atomic-components/organisms/modals/ConfirmStationArchiving';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Text,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  CardInnerContainer,
  CheckboxContainer,
  Column,
  Container,
  InnerTitle,
  InputContainer,
  Row,
  Title,
} from './styles';

type SetVariablesFunction<Variables> = (
  vars: Partial<Partial<Variables>>
) => void;

type FormProps<Variables> = {
  stationId: string;
  mutationFile: any;
  type?: CardType;
  children: (
    setVariables: SetVariablesFunction<Variables>,
    variables: Partial<Variables>
  ) => React.ReactNode;
  defaultVariables?: Partial<Variables>;
};

const parseCoordinate = (coordinate: string): number => {
  const parsedText = parseFloat(coordinate);
  // eslint-disable-next-line
  return isNaN(parsedText)
    ? (coordinate as any as number)
    : parseFloat(coordinate);
};

function Form<Variables extends { where: { stationId: string } }>({
  stationId,
  children,
  mutationFile,
  type = 'simple',
  defaultVariables,
}: FormProps<Variables>) {
  const { t } = useTranslation();
  const [variables, setVariables] = useState<Partial<Variables>>(
    defaultVariables ?? {}
  );
  useEffect(() => {
    if (defaultVariables && Object.values(variables).length === 0) {
      setVariables(defaultVariables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVariables]);
  const [callMutation, { loading }] = useAuthMutation(mutationFile);

  const where = { stationId };

  const tryMutation = async () => {
    try {
      await callMutation({ variables: { where, ...variables } });
      toast.success(t('generic-feedback-success-message'));
    } catch (e) {
      toast.error(t('generic-feedback-error-message'));
      console.warn('Erro na mutation, você preencheu todos os campos? ', e);
    }
  };

  return (
    <Card type={type} loading={false} error={false} padded={20}>
      {children(
        (partialVars) => setVariables({ ...variables, ...partialVars }),
        variables
      )}
      <ButtonContainer>
        <Button
          colorScheme="gray"
          variant="outline"
          fontWeight="normal"
          isLoading={loading}
          onClick={tryMutation}
        >
          Salvar
        </Button>
      </ButtonContainer>
    </Card>
  );
}

// From https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
function omitDeep<T>(value: T, key: string): T {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key)) as any;
  }
  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      // @ts-ignore
      return { [k]: omitDeep(value[k], key), ...newObject };
    }, {} as T);
  }
  return value;
}

const OwnerEditStation: React.FC = () => {
  const { id: stationId } = useContext(StationContext);

  const isInArray = (array: string[], string: string) => {
    if (!array) return false;
    return array.includes(string);
  };

  const toggleString = <T extends string>(array: T[], string: T) => {
    const newArray = array ? [...array] : [];
    if (isInArray(newArray, string))
      newArray.splice(newArray.indexOf(string), 1);
    else newArray.push(string);
    return newArray;
  };

  const { data } = useAuthQuery<
    StationOwnerEditDetailsQuery,
    StationOwnerEditDetailsQueryVariables
  >(stationOwnerEditDetails, {
    variables: { where: { stationId } },
    skip: !stationId,
  });

  const correctedData: typeof data = omitDeep(data, '__typename');

  const station = correctedData?.station;

  const stationVisibilities = ['PUBLIC', 'PRIVATE'] as const;

  const [isModalArchiveStation, setIsModalArchiveStation] = useState(false);
  const [cep, setCep] = useState('');
  const [addressInfo, setAddressInfo] = useState<AddressInfo | undefined>();
  useEffect(() => {
    if (cep.length === 8) {
      getAdressInfo(cep.replace('-', '')).then((res: any) =>
        setAddressInfo(res)
      );
    }
  }, [cep]);

  const {
    ocppVersion,
    ocppUrl,
    ocppPublicAccessAllowed,
    chargePointId,
    routerPortal,
    configPortal,
    address,
    coordinates,
    unlockMethods,
  } = station ?? {};
  return (
    <>
      <Container>
        <Row>
          <Form<ChangeStationUnlockMethodsMutationVariables>
            stationId={stationId}
            mutationFile={changeStationUnlockMethodsMutation}
            type="simple"
            defaultVariables={{
              data: {
                unlockMethods: unlockMethods ?? [],
              },
            }}
          >
            {(setVariables, variables) => (
              <>
                <Title>Mudar método de desbloqueio</Title>
                <Row>
                  <CheckboxContainer>
                    <Checkbox
                      isChecked={isInArray(
                        (
                          variables as ChangeStationUnlockMethodsMutationVariables
                        )?.data?.unlockMethods,
                        'ChargingCard'
                      )}
                      onChange={() =>
                        setVariables({
                          data: {
                            unlockMethods: toggleString(
                              (
                                variables as ChangeStationUnlockMethodsMutationVariables
                              )?.data?.unlockMethods,
                              'ChargingCard'
                            ),
                          },
                        })
                      }
                    >
                      <Text fontSize="sm">ChargingCard</Text>
                    </Checkbox>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      isChecked={isInArray(
                        (
                          variables as ChangeStationUnlockMethodsMutationVariables
                        )?.data?.unlockMethods,
                        'Remote'
                      )}
                      onChange={() =>
                        setVariables({
                          data: {
                            unlockMethods: toggleString(
                              (
                                variables as ChangeStationUnlockMethodsMutationVariables
                              )?.data?.unlockMethods,
                              'Remote'
                            ),
                          },
                        })
                      }
                    >
                      <Text fontSize="sm">Remote</Text>
                    </Checkbox>
                  </CheckboxContainer>
                </Row>
                <Row>
                  <CheckboxContainer>
                    <Checkbox
                      isChecked={isInArray(
                        (
                          variables as ChangeStationUnlockMethodsMutationVariables
                        )?.data?.unlockMethods,
                        'Open'
                      )}
                      onChange={() =>
                        setVariables({
                          data: {
                            unlockMethods: toggleString(
                              (
                                variables as ChangeStationUnlockMethodsMutationVariables
                              )?.data?.unlockMethods,
                              'Open'
                            ),
                          },
                        })
                      }
                    >
                      <Text fontSize="sm">Livre</Text>
                    </Checkbox>
                  </CheckboxContainer>
                </Row>
              </>
            )}
          </Form>

          <Card type="normal" loading={false} error={false} padded={20}>
            <Title>Criar conector</Title>
            <p>Use a tela de passo a passo</p>
          </Card>
          <Row>
            <Form<ConfigureStationVisibilityMutationVariables>
              stationId={stationId}
              mutationFile={configureStationVisibility}
              type="simple"
              defaultVariables={{
                where: { stationId },
                visibility: station?.visibility || 'PUBLIC',
              }}
            >
              {(setVariables, variables) => (
                <>
                  <Title>Mudar visibilidade da estação</Title>
                  <NewDropdown
                    options={stationVisibilities}
                    format={(x) => x}
                    onClickAction={(text) =>
                      setVariables({
                        visibility: text,
                      })
                    }
                    type={'text'}
                    horizontalAlignment="start"
                    label={variables.visibility || 'Visibilidade da estação'}
                  />
                </>
              )}
            </Form>

            <Card error={false} type="simple" loading={false}>
              <Title>Clique abaixo se deseja arquivar esta estação</Title>
              <Box mt={4}>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  width="100%"
                  fontWeight="normal"
                  onClick={() => setIsModalArchiveStation(true)}
                >
                  Arquivar
                </Button>
              </Box>
            </Card>
          </Row>
        </Row>
        <Row>
          <Form<ChangeStationLocationMutationVariables>
            stationId={stationId}
            mutationFile={changeStationLocationMutation}
            type="complex"
            defaultVariables={{
              ...address,
              ...coordinates,
              ...(addressInfo
                ? {
                    ...addressInfo,
                    country: 'BR',
                    postalCode: cep,
                  }
                : {}),
            }}
          >
            {(
              setVariables,
              {
                latitude,
                longitude,
                street,
                streetNumber,
                city,
                state,
                neighborhood,
                postalCode,
                country,
              }
            ) => (
              <>
                <Title>Mudar localização</Title>
                <CardInnerContainer>
                  <InnerTitle>Coordenadas:</InnerTitle>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Latitude</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="Float"
                          value={`${latitude || ''}`}
                          onChange={(event) =>
                            setVariables({
                              latitude: parseCoordinate(event.target.value),
                            })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Longitude</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="Float"
                          value={`${longitude || ''}`}
                          onChange={(event) =>
                            setVariables({
                              longitude: parseCoordinate(event.target.value),
                            })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">CEP:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          onChange={(event) => setCep(event.target.value)}
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                </CardInnerContainer>
                <CardInnerContainer>
                  <InnerTitle>Informações:</InnerTitle>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Cidade:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="String"
                          value={city}
                          onChange={(event) =>
                            setVariables({ city: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">País:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="BR"
                          value={country}
                          onChange={(event) =>
                            setVariables({ country: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Bairro:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="String"
                          value={neighborhood}
                          onChange={(event) =>
                            setVariables({ neighborhood: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">CEP:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="String"
                          value={postalCode}
                          onChange={(event) =>
                            setVariables({ postalCode: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Estado:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="SC | SP | RJ | ..."
                          value={state}
                          onChange={(event) =>
                            setVariables({ state: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Nome da rua:</FormLabel>
                      <InputContainer>
                        <Input
                          type="text"
                          placeholder="String"
                          value={street}
                          onChange={(event) =>
                            setVariables({ street: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                  <Column type="simple">
                    <FormControl>
                      <FormLabel fontSize="sm">Nº da rua:</FormLabel>
                      <InputContainer>
                        <Input
                          width="32%"
                          type="text"
                          placeholder="String"
                          value={streetNumber}
                          onChange={(event) =>
                            setVariables({ streetNumber: event.target.value })
                          }
                        />
                      </InputContainer>
                    </FormControl>
                  </Column>
                </CardInnerContainer>
              </>
            )}
          </Form>
        </Row>
        <Row>
          <Form<ConfigureStationCommunicationMutationVariables>
            stationId={stationId}
            mutationFile={configureStationCommunicationMutation}
            type="complex"
            defaultVariables={{
              ocppVersion,
              configPortal,
              routerPortal,
              ocppUrl,
            }}
          >
            {(
              setVariables,
              { ocppVersion, configPortal, routerPortal, ocppUrl }
            ) => (
              <>
                <Title>Configurar comunicação</Title>
                <Box mt={4}>
                  <CardInnerContainer>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Versão OCPP:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={ocppVersion}
                            placeholder="V15 | V16 "
                            onChange={(event) =>
                              setVariables({
                                ocppVersion: event.target.value as any,
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Url OCPP:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={ocppUrl}
                            placeholder="String (EFACEC ends with /smartgrid/ChargePointService)"
                            onChange={(event) =>
                              setVariables({ ocppUrl: event.target.value })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                  </CardInnerContainer>

                  <CardInnerContainer>
                    <InnerTitle>Portal de roteamento</InnerTitle>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel>Url:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={routerPortal?.url}
                            placeholder="URL"
                            onChange={(event) =>
                              setVariables({
                                // @ts-ignore
                                routerPortal: {
                                  ...routerPortal,
                                  url: event.target.value,
                                },
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Usuário:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={routerPortal?.user}
                            placeholder="String"
                            onChange={(event) =>
                              setVariables({
                                // @ts-ignore
                                routerPortal: {
                                  ...routerPortal,
                                  user: event.target.value,
                                },
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Senha:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={routerPortal?.password}
                            placeholder="String"
                            onChange={(event) =>
                              setVariables({
                                // @ts-ignore
                                routerPortal: {
                                  ...routerPortal,
                                  password: event.target.value,
                                },
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                  </CardInnerContainer>
                  <CardInnerContainer>
                    <InnerTitle>Portal de configuração</InnerTitle>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Url:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={configPortal?.url}
                            placeholder="URL"
                            onChange={(event) =>
                              setVariables({
                                // @ts-ignore
                                configPortal: {
                                  ...configPortal,
                                  url: event.target.value,
                                },
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Usuário:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={configPortal?.user}
                            placeholder="String"
                            onChange={(event) =>
                              setVariables({
                                // @ts-ignore
                                configPortal: {
                                  ...configPortal,
                                  user: event.target.value,
                                },
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                    <Column type="simple">
                      <FormControl>
                        <FormLabel fontSize="sm">Senha:</FormLabel>
                        <InputContainer>
                          <Input
                            type="text"
                            value={configPortal?.password}
                            placeholder="String"
                            onChange={(event) =>
                              setVariables({
                                // @ts-ignore
                                configPortal: {
                                  ...configPortal,
                                  password: event.target.value,
                                },
                              })
                            }
                          />
                        </InputContainer>
                      </FormControl>
                    </Column>
                  </CardInnerContainer>
                </Box>
              </>
            )}
          </Form>
        </Row>
        <Row>
          <Form<AllowStationToBePubliclyAccessedViaOcppMutationVariables>
            stationId={stationId}
            mutationFile={allowStationToBePubliclyAccessedViaOCPP}
            type="half"
            defaultVariables={{ allow: ocppPublicAccessAllowed }}
          >
            {(setVariables, variables) => (
              <>
                <Title>
                  Deixar estação acessível publicamente via OCPP(acessível FORA
                  da VPN!)
                </Title>
                <Checkbox
                  isChecked={variables.allow ?? false}
                  onChange={() => setVariables({ allow: !variables.allow })}
                />
              </>
            )}
          </Form>
          <Form<ChangeStationChargePointIdMutationVariables>
            stationId={stationId}
            mutationFile={changeStationChargePointIdMutation}
            type="half"
            defaultVariables={{ chargePointId }}
          >
            {(setVariables, { chargePointId }) => (
              <>
                <Title>Configurar OCPP ID(ChargePoint Identity)</Title>
                <Box mt={4}>
                  <InputContainer>
                    <Input
                      type="text"
                      placeholder="String"
                      value={chargePointId}
                      onChange={(event) =>
                        setVariables({ chargePointId: event.target.value })
                      }
                    />
                  </InputContainer>
                </Box>
              </>
            )}
          </Form>
        </Row>
        <ConfirmStationArchiving
          show={isModalArchiveStation}
          onClose={() => setIsModalArchiveStation(false)}
          stationId={stationId}
          setIsModalArchiveStation={setIsModalArchiveStation}
        />
      </Container>
    </>
  );
};

export default OwnerEditStation;
