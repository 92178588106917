import { BasicStationsDetailsQuery } from 'generated/graphql';
import basicStationsDetails from 'graphql/queries/basicStationsDetails';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardTitle from '../../../components/Card/CardHeader/CardTitle/index';
import CardHeader from '../../../components/Card/CardHeader/index';
import Card from '../../../components/Card/index';
import Map from './Map';
import { MapContainer } from './styles';

const MapCard = () => {
  const { t } = useTranslation();
  const { data, error, loading } = useRealtimeQuery<BasicStationsDetailsQuery>(
    basicStationsDetails,
    {
      pollInterval: 1000 * 10,
    }
  );

  const stations = data?.stations || [];

  return (
    <Card type="complex" error={error} loading={loading}>
      <CardHeader>
        <CardTitle title={t('map.map-card.title')} />
      </CardHeader>
      <MapContainer>
        <Map stations={stations} />
      </MapContainer>
    </Card>
  );
};

export default MapCard;
