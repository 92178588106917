import { Icon } from 'new-components/Icon';
import { Typography } from 'components';
import { NewTag } from 'new-components/NewTag';
import { SessionStatus as SessionStatusType } from 'generated/graphql';
import ConfigType from 'config/typing';
import { useTranslation } from 'react-i18next';
import { CurrencyType } from 'types';
import CurrencyTypeIcon from 'atomic-components/atoms/CurrencyTypeIcon';
import {
  Container,
  SessionInfo,
  SessionStatus,
  PastTime,
  SessionIdContainer,
  SessionIdTitle,
  Separator,
  SessionId,
  SessionMetrics,
  TimeConsumed,
  EnergyConsumed,
  TotalCost,
} from './styles';

type Props = {
  status?: SessionStatusType;
  icon: keyof ConfigType['NEW_ICONS'];
  label?: string;
  pastTime?: string;
  sessionId: string;
  duration?: string;
  energyConsumed?: string;
  totalCost?: string;
  currencyType?: CurrencyType;
};

const Header: React.FC<Props> = ({
  status,
  icon,
  label,
  pastTime,
  sessionId,
  duration,
  energyConsumed,
  totalCost,
  currencyType,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <SessionInfo>
        <SessionStatus>
          <NewTag status={status} type="icon-text" size="big" icon={icon}>
            {label}
          </NewTag>

          <PastTime>{pastTime || '-'}</PastTime>
        </SessionStatus>
        <SessionIdContainer>
          <SessionIdTitle>{t('recharge-modal.header.title')}</SessionIdTitle>
          <Separator>/</Separator>
          <SessionId>{sessionId || '-'}</SessionId>
        </SessionIdContainer>
        <SessionMetrics>
          <TimeConsumed>
            <Icon type="NEW_CLOCK" color="LIGHT_GRAY"></Icon>
            <Typography>{duration || '-'}</Typography>
          </TimeConsumed>
          <EnergyConsumed>
            <Icon type="NEW_AC_CHARGER" color="LIGHT_GRAY"></Icon>
            <Typography>{energyConsumed || '-'}</Typography>
          </EnergyConsumed>
          <TotalCost>
            <CurrencyTypeIcon currencyType={currencyType} />
            <Typography>{totalCost || '-'}</Typography>
          </TotalCost>
        </SessionMetrics>
      </SessionInfo>
    </Container>
  );
};

export default Header;
