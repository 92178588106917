import { gql } from '@apollo/client';

export default gql`
  query DriverModalGeneralTab($where: DriverWhereUniqueInput!) {
    driver(where: $where) {
      id
      emspId
      createdAt
      profile {
        id
        firstName
        lastName
        email
        cellPhone
        CPF
      }
      addresses {
        id
        street
        streetNumber
        city
        state
        postalCode
      }
      cars {
        model {
          label
        }
      }
      paymentMethods {
        id
        ... on PaymentCard {
          brand
          partiallyHiddenCardNumber
        }
        ... on PlacePlan {
          title
          holderFullName
        }
        ... on Wallet {
          availableBalance
          preAuthorizedBalance
          totalBalance
          minimumBalanceForSession
        }
        __typename
      }
    }
  }
`;
