import { ForgotPassword, Login, TermsOfService } from 'pages';
import TwoFactorAuthLogin from 'pages/TwoFactorAuthLogin';
import React from 'react';
import { Route } from 'react-router-dom';
import {
  Container,
  LeftContainer,
  LeftContainerLogo,
  RightContainer,
} from './styles';

const LoggedOut: React.FC<{}> = () => (
  <Container>
    <LeftContainer>
      <LeftContainerLogo />
    </LeftContainer>
    <RightContainer>
      <Route path="/login" component={Login} />
      <Route path="/two-factor-authentication" component={TwoFactorAuthLogin} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/terms-of-service" component={TermsOfService} />
    </RightContainer>
  </Container>
);

export default LoggedOut;
