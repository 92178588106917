import { Typography } from 'components';
import { Cell, TableContainer } from 'components/Table/styles';
import NewCard from 'new-components/NewCard';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -30px;
`;

export const StationControlInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Card = styled(NewCard)`
  margin-bottom: 24px;
  padding: 24px;
`;

export const StationControlInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  margin-bottom: 18px;
  align-items: center;
  > *:first-child {
    margin-right: 8px;
  }
`;

export const TypographyWithPaddingRight = styled(Typography)`
  padding-right: 16px;
`;

export const TableContainerIdentifier = styled.div`
  /* Lidando com o fato de o dropdown da pagina de controle não aparecer por conta do overflow: hidden */
  ${Cell}:last-child, ${TableContainer} {
    overflow: visible;
  }
  margin-bottom: -24px;
`;

export const TypographyWithPadding = styled(TypographyWithPaddingRight)`
  padding-bottom: 11px;
`;

export const FlexibleColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 33.33%;
  min-height: 100%;
  margin-bottom: -11px;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  width: 100%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;

export const TitleAndTag = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;

export const TableTitle = styled(Typography).attrs({
  size: 20,
  weight: 'bold',
})`
  display: block;
  margin-bottom: 16px;
  margin-left: 8px;
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 32px;
`;
