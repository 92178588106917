import IndicatorCard from 'components/IndicatorCard';
import { useAuthQuery } from 'hooks';
import {
  SessionsSummaryQuery,
  SessionsSummaryQueryVariables,
} from 'generated/graphql';
import sessionsSummaries from 'graphql/queries/sessionsSummaries';
import moment from 'moment';
import { formatNumber } from 'services/format';
import { compareOrgs, computeOrgSummariesTotal } from 'services/multiorg';
import { Container } from './styles';

const now = moment();

type OrgSummary = SessionsSummaryQuery['organizations'][number];

const SessionsSummariesCards = () => {
  const { data, error, loading } = useAuthQuery<
    SessionsSummaryQuery,
    SessionsSummaryQueryVariables
  >(sessionsSummaries, {
    variables: {
      thisMonthTimeRange: {
        from: now.clone().startOf('month').toISOString(),
        to: now.clone().toISOString(),
      },
      lastMonthTimeRange: {
        from: now.clone().startOf('month').subtract(1, 'month').toISOString(),
        to: now.clone().subtract(1, 'month').toISOString(),
      },
    },
  });

  const sessionsSummariesTotal: Omit<OrgSummary, 'name'> =
    data && data.organizations.length > 0
      ? computeOrgSummariesTotal(data.organizations)
      : {
          thisMonthSummary: {
            count: 0,
            driverCount: 0,
            energyConsumed: 0,
            timeCharging: 0,
          },
          lastMonthSummary: {
            count: 0,
            driverCount: 0,
            energyConsumed: 0,
            timeCharging: 0,
          },
        };

  const sessionsOrderedOrgArray = data
    ? data.organizations
        .map((org) => ({
          name: org.name,
          value: org.thisMonthSummary.count,
        }))
        .sort(compareOrgs)
    : [];
  const clientsOrderedOrgArray = data
    ? data.organizations
        .map((org) => ({
          name: org.name,
          value: org.thisMonthSummary.driverCount,
        }))
        .sort(compareOrgs)
    : [];
  const energyOrderedOrgArray = data
    ? data.organizations
        .map((org) => ({
          name: org.name,
          value: org.thisMonthSummary.energyConsumed,
        }))
        .sort(compareOrgs)
    : [];

  return (
    <Container>
      <IndicatorCard
        borderColor={'RECHARGES'}
        thisPeriodTotalValue={sessionsSummariesTotal.thisMonthSummary.count}
        lastPeriodTotalValue={sessionsSummariesTotal.lastMonthSummary.count}
        loading={loading}
        error={error}
        indicatorText={'home.sessions-this-month'}
        periodText={'home.sessions-timeline-graph.period'}
        detailsText={'home.sessions-details'}
        orderedOrgArray={sessionsOrderedOrgArray}
        showChange={true}
      />
      <IndicatorCard
        borderColor={'RECHARGES'}
        thisPeriodTotalValue={
          sessionsSummariesTotal.thisMonthSummary.driverCount
        }
        lastPeriodTotalValue={
          sessionsSummariesTotal.lastMonthSummary.driverCount
        }
        loading={loading}
        error={error}
        indicatorText={'home.clients-this-month'}
        periodText={'home.sessions-timeline-graph.period'}
        detailsText={'home.clients-details'}
        orderedOrgArray={clientsOrderedOrgArray}
        showChange={true}
      />
      <IndicatorCard
        borderColor={'BILLING'}
        thisPeriodTotalValue={
          sessionsSummariesTotal.thisMonthSummary.energyConsumed
        }
        lastPeriodTotalValue={
          sessionsSummariesTotal.lastMonthSummary.energyConsumed
        }
        loading={loading}
        error={error}
        indicatorText={'home.energy-this-month'}
        periodText={'home.sessions-timeline-graph.period'}
        detailsText={'home.energy-details'}
        valueSuffix={'kWh'}
        valueFormat={(n: number) => formatNumber(n / 1000, { digits: 2 })}
        orderedOrgArray={energyOrderedOrgArray}
        showChange={true}
      />
    </Container>
  );
};

export default SessionsSummariesCards;
