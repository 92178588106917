import Dropdown from 'new-components/Dropdown';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 12px;
`;

export const DropdownContainer = styled(Dropdown.Container)`
  min-width: 200px;
`;

export const ActionButtonContainer = styled.div<{ active: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 3px;
  box-shadow: 0px 2px 0px 0px #0000000a;
  cursor: pointer;
  border: ${({ theme: { COLORS } }) => `1px solid ${COLORS.TERTIARY_GRAY}`};
  :hover {
    background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
  }
  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme: { COLORS } }) => COLORS.BACKGROUND};
        `
      : ''}
`;
