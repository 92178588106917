import { gql } from '@apollo/client';

export default gql`
  query StationConnectorsInfo($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      name
      status
      connectors {
        id
        status
        power
        type
        station {
          id
          status
        }
        currentType
        errorCode
      }
    }
  }
`;
