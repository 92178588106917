import React from 'react';
import { Icon } from 'new-components/Icon';
import { Flex, Heading, Text, Tag } from '@chakra-ui/react';
import AuthContext from 'contexts/Auth/context';
import { formatOrgId } from 'services/format';

type Props = {
  id: number;
  userProfile?: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  orgId?: string;
};

const UserInfo: React.FC<Props> = ({ id, userProfile, orgId }) => {
  const { orgNameMap } = React.useContext(AuthContext);
  return (
    <Flex flexFlow="column nowrap" pl={6}>
      <Flex alignItems="center" gap={2}>
        <Icon type="NEW_USER" color="SECONDARY_GRAY"></Icon>
        <Heading fontSize="lg">{id}</Heading>
        <Tag
          marginLeft={5}
          bg={'gray.200'}
          padding={'6px 8px'}
          textAlign={'center'}
        >
          <Heading fontSize="14px" color={'white'} width={'full'}>
            {orgId ? formatOrgId(orgId, orgNameMap) : '-'}
          </Heading>
        </Tag>
      </Flex>
      <Text color="gray.500" fontSize="xs">
        {userProfile?.firstName} {userProfile?.lastName}
      </Text>
      <Text color="gray.500" fontSize="xs">
        {userProfile?.email}
      </Text>
    </Flex>
  );
};

export default UserInfo;
