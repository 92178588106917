import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import Modal from 'new-components/NewModal';
import TextInput from 'new-components/TextInput';
import { H3, BodyTypography } from 'new-components/Typographies/styles';
import { formatEnergy } from 'services/format';
import secondsToTime from 'services/secondsToTime';
import toast from 'services/toast';
import { useAuthMutation } from 'hooks';
import editSessionMutation from 'graphql/mutations/editSession';
import {
  EditSessionMutation,
  EditSessionMutationVariables,
} from 'generated/graphql';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  sanitizeSessionDuration,
  sanitizeEnergyConsumed,
  energyMask,
} from './functions';
import { Header, Form } from './styles';

type Props = {
  show: boolean;
  onClose: () => void;
  sessionId: string;
  energyConsumed: number;
  duration: number;
};

const EditSessionModal: React.FC<Props> = ({
  show,
  onClose,
  sessionId,
  ...data
}) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState(secondsToTime(data.duration));
  const [energyConsumed, setEnergyConsumed] = useState(
    formatEnergy(data.energyConsumed)
  );

  const [editSession, { loading }] = useAuthMutation<
    EditSessionMutation,
    EditSessionMutationVariables
  >(editSessionMutation);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const time = sanitizeSessionDuration(duration);
    const energy = sanitizeEnergyConsumed(energyConsumed);

    if (energy == null || time == null) {
      return toast.error(t('edit-session-modal.invalid-fields-error'));
    }

    try {
      await editSession({
        variables: {
          where: { sessionId },
          data: {
            timeCharging: time,
            energyConsumed: energy,
          },
        },
      });

      toast.success(t('edit-session-modal.toast-successfully-message'));
      onClose();
    } catch (err) {
      toast.error(t('edit-session-modal.toast-error-message'));
    }
  };

  return (
    <Modal show={show} onClose={onClose} size="sm" centered>
      <Modal.Header>
        <Header>
          <H3>{t('edit-session-modal.header.title')}</H3>
          <BodyTypography>
            {t('edit-session-modal.header.subtitle')}{' '}
          </BodyTypography>
        </Header>
        <Modal.Header.Attachments padding="16">
          <Modal.Header.Close onClose={onClose} />
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <NumberFormat
            format="##:##:##"
            mask="_"
            allowEmptyFormatting
            value={duration}
            onValueChange={(values) => setDuration(values.formattedValue)}
            customInput={TextInput}
            name="duration"
            label={t('edit-session-modal.body.duration-input.placeholder')}
          />
          <NumberFormat
            format={energyMask}
            allowEmptyFormatting
            value={energyConsumed}
            onValueChange={(values) => setEnergyConsumed(values.formattedValue)}
            customInput={TextInput}
            name="energyConsumed"
            label={t(
              'edit-session-modal.body.energy-consumed-input.placeholder'
            )}
          />
          <Flex justifyContent="flex-end" mt={6}>
            <Stack direction="row" spacing={6}>
              <Button
                color="gray.900"
                variant="link"
                onClick={onClose}
                size="sm"
              >
                {t('edit-session-modal.body.cancel-btn.title')}
              </Button>
              <Button isLoading={loading} type="submit" size="sm">
                {t('edit-session-modal.body.save-btn.title')}
              </Button>
            </Stack>
          </Flex>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default EditSessionModal;
