import {
  Button,
  CloseButton,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import Config from 'config';
import { useTranslation } from 'react-i18next';
import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import LoadingComponent from 'new-components/LoadingComponent';
import {
  useVoucherDetails,
  VoucherActionsTypeWithoutViewDetails,
} from './services';
import { VoucherStatusTag } from '../components/VoucherStatusTag';
import { GeneralInformation } from './containers/GeneralInformation';
import { VoucherDrivers } from './containers/VoucherDrivers';
import { VoucherStations } from './containers/VoucherStations';

type AvailableOptions = {
  [K in VoucherActionsTypeWithoutViewDetails]: () => ReactElement;
};

export const VoucherDetailsModal = () => {
  const { t } = useTranslation();

  const {
    visibleOptionsPerVoucherStatus,
    voucherName,
    resumedVoucherStatus,
    voucherDetailsLoading,
    voucherDetailsModalVisible,
    closeVoucherDetailsModal,
  } = useVoucherDetails();

  const voucherActionButtons: AvailableOptions = {
    ACTIVE: () => (
      <Button
        variant={'ghost'}
        fontSize={'sm'}
        fontWeight={700}
        color={Config.COLORS.PRIMARY_GRAY}
        onClick={() => {}}
      >
        {t('payments.voucher.actions.active.title')}
      </Button>
    ),
    ARCHIVE: () => (
      <Button
        variant={'ghost'}
        fontSize={'sm'}
        fontWeight={700}
        color={Config.COLORS.CRITICAL}
        onClick={() => {}}
      >
        {t('payments.voucher.actions.archive.title')}
      </Button>
    ),
    DISABLE: () => (
      <Button
        variant={'ghost'}
        fontSize={'sm'}
        fontWeight={700}
        color={Config.COLORS.PRIMARY_GRAY}
        onClick={() => {}}
      >
        {t('payments.voucher.actions.disable.title')}
      </Button>
    ),
    EDIT: () => (
      <Button
        variant={'ghost'}
        fontSize={'sm'}
        fontWeight={700}
        color={Config.COLORS.PRIMARY_GRAY}
        onClick={() => {}}
      >
        {t('payments.voucher.actions.edit.title')}
      </Button>
    ),
  };

  const options = visibleOptionsPerVoucherStatus.map(
    (option) => voucherActionButtons[option]
  );
  const tabs: string[] = [
    t('payments.voucher-tab.voucher-details.tab.general.title'),
    t('payments.voucher-tab.voucher-details.tab.selected-clients.title'),
    t('payments.voucher-tab.voucher-details.tab.selected-stations.title'),
  ];

  return (
    <Modal
      show={voucherDetailsModalVisible}
      onClose={closeVoucherDetailsModal}
      centered
      size={'lg'}
    >
      {voucherDetailsLoading ? (
        <LoadingComponent size={50} top={300} />
      ) : (
        <>
          <Modal.Header>
            <Flex
              p="1rem 1.5rem 1rem 1.5rem"
              bg="white"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Flex>
                <Flex alignItems={'center'} mr={6}>
                  <Icon size={24} type={'NEW_VOUCHER'} color={'PRIMARY_GRAY'} />
                  <Heading
                    paddingTop={1}
                    ml={2}
                    fontWeight={700}
                    fontSize={'lg'}
                  >
                    {voucherName}
                  </Heading>
                </Flex>
                <VoucherStatusTag status={resumedVoucherStatus} />
              </Flex>
              <Flex alignItems={'center'}>
                <Flex mr={6}>{options.map((option) => option())}</Flex>
                <CloseButton
                  size={'sm'}
                  borderRadius={'full'}
                  borderWidth={1}
                  onClick={closeVoucherDetailsModal}
                />
              </Flex>
            </Flex>
          </Modal.Header>
          <Modal.Body style={{ background: Config.COLORS.BACKGROUND }}>
            <Tabs>
              <TabList pl="6">
                {tabs
                  .filter((tab) => !!tab)
                  .map((tab, index) => (
                    <Tab data-testid={`voucher-head-tab-${index}`} key={index}>
                      {tab}
                    </Tab>
                  ))}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <GeneralInformation />
                </TabPanel>
                <TabPanel>
                  <VoucherDrivers />
                </TabPanel>
                <TabPanel>
                  <VoucherStations />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: Config.COLORS.BACKGROUND }} />
        </>
      )}
    </Modal>
  );
};
