import { gql } from '@apollo/client';
import sessionRowFieldsFragment from 'graphql/fragments/sessionRowFields';

export default gql`
  query TableSessions(
    $limit: Int
    $offset: Int
    $filter: SessionFilterInput
    $timeRange: TimeRangeInput
    $sort: SessionSortInput = { createdAt: DESC }
  ) {
    sessionsSummary(filter: $filter, timeRange: $timeRange) {
      count
      energyConsumed
    }
    sessions(
      filter: $filter
      timeRange: $timeRange
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      ...SessionRowFields
      cpoId
      emspId
      stationId
      stationProfile {
        id
        name
        address {
          state
          city
          streetNumber
          postalCode
        }
      }
    }
  }
  ${sessionRowFieldsFragment}
`;
