import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-bottom: 25px;
`;
