import { LoginResult } from '@voltbras/auth-client';
import { t } from 'i18next';

export const getUserInactivationErrorMessage = (error: any): LoginResult => {
  const regex = /-\d+-/;

  const userInactivationTime = error.message
    .match(regex)?.[0]
    .replace(/-/g, '');

  const singularOrPluralDay =
    userInactivationTime > 1
      ? t('login.inactivated-user-error-message.days')
      : t('login.inactivated-user-error-message.day');

  return {
    error: t('login.inactivated-user-error-message', {
      userInactivationTime,
      singularOrPluralDay,
    }),
  };
};

export const passwordExpirationErrorMessage = (): LoginResult => ({
  error: t('login.password-expiration-error-message'),
});
