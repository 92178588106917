import {
  EmailAuthProvider,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  MultiFactorInfo,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  User as FirebaseUser,
} from 'firebase/auth';
import { auth } from './identity-provider/firebaseConfig';

const { currentUser } = auth;

export const USER_INFO_TFA_LOGIN_ERROR_COOKIE_KEY = 'userInfoTFALoginError';

export const getTFAFactorStatus = () => {
  const { currentUser } = auth;
  if (!currentUser) return;
  const userTFAFactors = multiFactor(currentUser).enrolledFactors;
  const isTFAFactorEnabled = !!userTFAFactors.length;
  return isTFAFactorEnabled;
};

// This is because firebase uses uses the login error to perform two factor authentication,
// we need this information to use in the two auth authentication screen.
export const setErrorInfoInLocageStorage = (
  twoFactorError: MultiFactorError
) => {
  window.localStorage.setItem(
    USER_INFO_TFA_LOGIN_ERROR_COOKIE_KEY,
    JSON.stringify(twoFactorError)
  );
};
export const generateRecaptcha = () => {
  try {
    const recaptchaVerifier = new RecaptchaVerifier(
      'pin-input',
      {
        size: 'invisible',
      },
      auth
    );
    return recaptchaVerifier;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const reauthenticateMember = async ({
  password,
  email,
  currentUser,
  recaptchaVerifier,
}: {
  password: string;
  email: string;
  currentUser: FirebaseUser;
  recaptchaVerifier: RecaptchaVerifier;
}) => {
  const userCredentials = EmailAuthProvider.credential(email, password);

  try {
    await reauthenticateWithCredential(currentUser, userCredentials);
  } catch (error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
      if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        return {
          verificationId,
          resolver,
          recaptchaVerifier,
        };
      }
    }
    throw error;
  }
};

export const dispatchTFACode = async (
  phoneNumber: string,
  currentUser: FirebaseUser,
  existingRecaptcha: RecaptchaVerifier
) => {
  const recaptchaVerifier = existingRecaptcha;

  const multiFactorSession = await multiFactor(currentUser).getSession();

  const phoneInfoOptions = {
    phoneNumber,
    session: multiFactorSession,
  };
  const phoneAuthProvider = new PhoneAuthProvider(auth);

  const verificationId = await phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    recaptchaVerifier
  );

  return {
    verificationId,
    recaptchaVerifier,
  };
};

export const insertTFACodeVerification = (
  hashCode: string,
  verificationCode: string
) => {
  if (!currentUser) return;
  const credential = PhoneAuthProvider.credential(hashCode, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
  return multiFactor(currentUser).enroll(multiFactorAssertion);
};

export const getAuthPhoneNumber = (currentUser: FirebaseUser | null) => {
  if (!currentUser) return '';
  const factors: MultiFactorInfo & {
    phoneNumber?: string;
  } = multiFactor(currentUser).enrolledFactors[0];
  return factors?.phoneNumber?.slice(3);
};
