import { gql } from '@apollo/client';

export default gql`
  query ConnectorsSummary($includeSessionSummary: Boolean!) {
    sessionsSummary(
      filter: { includesStatus: [CHARGING, WAITING_UNPLUG, FINISHED] }
    ) @include(if: $includeSessionSummary) {
      energyConsumed
      count
      timeCharging
    }
    connectorsSummaryPerStatus {
      status
      count
    }
  }
`;
