import { gql } from '@apollo/client';

export default gql`
  mutation UpdateGroupDrivers(
    $data: UpdateGroupDriversInput!
    $where: GroupWhereUniqueInput!
  ) {
    updateGroupDrivers(data: $data, where: $where)
  }
`;
