import styled from 'styled-components';

export const GraphContainer = styled.div`
  height: 90%;
  margin-bottom: 20px;
`;

export const GraphOption = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  align-items: center;
`;

export const GraphOptionDecoration = styled.div<{ color: string }>`
  width: 22px;
  height: 6px;
  background: ${({ color }) => color} 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 10px;
`;

export const GraphOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 100%;
  & > ${GraphOption}:nth-child(3) {
    position: absolute;
    right: 0;
    margin-right: 0px;
  }
`;
