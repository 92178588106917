import styled from 'styled-components';
import { NewCardProps } from '.';

export const Container = styled.div<{ height: NewCardProps['height'] }>`
  box-sizing: border-box;
  box-shadow: 0px 10px 20px #0000000a;
  border-radius: 16px;
  background: ${({ theme }) => theme.COLORS.WHITE};

  width: 100%;
  height: ${({ height }) => height};

  display: flex;
  flex-flow: column nowrap;
  position: relative;

  // before adding new styles, remember
  // this is a generic component
`;
