import {
  Box,
  Input,
  InputGroup,
  InputRightAddon,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import { Scope } from '@voltbras/auth-directives';
import Config from 'config';
import { StationConfigurationKey } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import { useTranslation } from 'react-i18next';

type Props = {
  stationConfigurationKey: StationConfigurationKey;
  onChangeValue: (value: string) => void;
};

const ConfigurationKeyInput = ({
  stationConfigurationKey,
  onChangeValue,
}: Props) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();

  const hasPermissionToEdit = stationConfigurationKey.scopes
    ? hasScopes(stationConfigurationKey.scopes as Scope[])
    : true;

  const isDisabled = stationConfigurationKey.readonly || !hasPermissionToEdit;

  switch (stationConfigurationKey.type) {
    case 'STRING':
      return (
        <Box mt="3" w="100%">
          <InputGroup>
            <Input
              isDisabled={isDisabled}
              value={stationConfigurationKey.value}
              onChange={(e) => onChangeValue(e.target.value)}
              rows={1}
              maxHeight="40"
              minHeight="10"
            />
            {stationConfigurationKey.unit && (
              <InputRightAddon
                children={
                  Config.LABELS.STATION_CONFIGURATION_KEY_UNIT[
                    stationConfigurationKey.unit
                  ]
                }
                fontSize="xs"
                bg="gray.50"
                borderColor="gray.100"
                color="gray.500"
              />
            )}
          </InputGroup>
        </Box>
      );
    case 'BOOLEAN':
      return (
        <Switch
          isChecked={stationConfigurationKey.value === 'true'}
          isDisabled={isDisabled}
          onChange={() =>
            stationConfigurationKey.value === 'true'
              ? onChangeValue('false')
              : onChangeValue('true')
          }
          m="0"
        />
      );
    case 'INT':
      return (
        <InputGroup maxWidth="48">
          <Input
            isDisabled={isDisabled}
            type="number"
            value={stationConfigurationKey.value}
            onChange={(e) => onChangeValue(e.target.value)}
          />
          <InputRightAddon
            children={
              stationConfigurationKey.unit
                ? t(
                    Config.LABELS.STATION_CONFIGURATION_KEY_UNIT[
                      stationConfigurationKey.unit
                    ]
                  )
                : 'unidade(s)'
            }
            fontSize="xs"
            bg="gray.50"
            borderColor="gray.100"
            color="gray.500"
          />
        </InputGroup>
      );

    default:
      return (
        <Textarea
          isDisabled={isDisabled}
          w="100%"
          mt="3"
          value={stationConfigurationKey.value}
          rows={1}
          maxHeight="40"
          minHeight="10"
          pt="2.5"
          onChange={(e) => onChangeValue(e.target.value)}
        />
      );
  }
};

export default ConfigurationKeyInput;
