type DriverProps = {
  id: number;
  profile: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
};

type StationProps = {
  id: string;
  name: string;
};

export const formatDriverNameToCheckbox = (data: Array<DriverProps>) => {
  const result = data.map((value) => ({
    id: value.id,
    name: `${value.profile.firstName} ${value.profile.lastName} - ${value.profile.email}`,
  }));

  return result;
};

export const formatStationNameToCheckbox = (data: Array<StationProps>) => {
  const result = data.map((value) => ({
    id: value.id,
    name: `${value.name} ${value.id.substr(0, 3)}...`,
  }));

  return result;
};
