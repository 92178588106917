import { PayerAddress } from 'generated/graphql';
import { validateEmail } from 'services/validation';

type HasSomeInputEmptyProps = {
  address: PayerAddress;
  amount: number;
  profile: {
    email: string;
    CPFCNPJ: string;
    name: string;
  };
};

export const hasSomeInputEmpty = ({
  address,
  profile,
  amount,
}: HasSomeInputEmptyProps) =>
  address.city.trim() === '' ||
  address.neighborhood.trim() === '' ||
  address.postalCode.trim() === '' ||
  address.state.trim() === '' ||
  address.streetNumber.trim() === '' ||
  address.street.trim() === '' ||
  amount === 0 ||
  profile.name.trim() === '' ||
  profile.email.trim() === '' ||
  profile.CPFCNPJ.trim() === '' ||
  profile.CPFCNPJ.includes('_') ||
  !validateEmail(profile.email);
export const formatCentsToReal = (amount: number) => amount / 100;

export const IsInputValueUnderMinimumValue = (
  minimumWalletDepositAmount: number,
  amount: number
) => {
  if (amount === 0) return false;
  return (
    formatCentsToReal(minimumWalletDepositAmount) > formatCentsToReal(amount)
  );
};
