import Page from 'components/Page';
import Table, { Column } from 'components/Table';
import Config from 'config/index';
import {
  AllTeamMembersQuery,
  AllTeamMembersQueryVariables,
} from 'generated/graphql';
import allTeamMembers from 'graphql/queries/allTeamMembers';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import ErrorComponent from 'new-components/ErrorComponent';
import NewCard from 'new-components/NewCard';
import NewPageHeader from 'new-components/NewPageHeader';
import { NewTag } from 'new-components/NewTag';
import { BodyTypography, H5 } from 'new-components/Typographies/styles';
import React, { useState, useContext } from 'react';
import { MemberDropdown } from 'new-components/MemberDropdown';

import InviteMemberModal from 'atomic-components/organisms/modals/InviteMember';
import { Button, Flex } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { formatOrgId } from 'services/format';
import { useTranslation } from 'react-i18next';
import Search from 'atomic-components/molecules/Search';
import AuthContext from 'contexts/Auth/context';
import {
  Header,
  LoadingOrErrorContainer,
  StatusTagContainer,
  TableCardPadding,
} from './styles';

const fullName = (firstName?: string, lastName?: string) =>
  `${firstName} ${lastName}`;

type Member = AllTeamMembersQuery['members'][number];

const Members: React.FC = () => {
  const { t } = useTranslation();
  const [inviteMemberVisible, setInviteMemberVisible] = useState(false);
  const hasScopes = useHasScopes();
  const [searchValue, setSearchValue] = useState('');
  const { orgNameMap } = useContext(AuthContext);

  const {
    data,
    loading,
    error,
    refetch: refetchMembers,
  } = useAuthQuery<AllTeamMembersQuery, AllTeamMembersQueryVariables>(
    allTeamMembers,
    {
      variables: {
        search: searchValue,
      },
    }
  );

  const handleCloseInviteMemberModal = () => setInviteMemberVisible(false);

  const columns: Column<Member>[] = [
    {
      Header: t('members.table.name.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            /* @ts-ignore */
            profile: { firstName, lastName },
            /* @ts-ignore */
            active,
          },
        },
      }) => (
        <H5 color={active ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'}>
          {fullName(firstName, lastName)}
        </H5>
      ),
    },
    {
      Header: t('profile-dropdown.organization-section.singular-title'),
      disableFilters: true,
      disableSortBy: false,
      Cell: ({
        row: {
          original: {
            /* @ts-ignore */
            organizationId,
          },
        },
      }) => <>{formatOrgId(organizationId as string, orgNameMap)}</>,
    },
    {
      Header: t('members.table.email.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            /* @ts-ignore */
            profile,
            /* @ts-ignore */
            active,
          },
        },
      }) => (
        <BodyTypography color={active ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'}>
          {profile?.email || '-'}
        </BodyTypography>
      ),
    },
    {
      Header: t('members.table.role.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            /* @ts-ignore */
            role,
            /* @ts-ignore */
            active,
          },
        },
      }) => (
        <BodyTypography color={active ? 'PRIMARY_GRAY' : 'SECONDARY_GRAY'}>
          {/* @ts-ignore */}
          {t(Config.LABELS.ROLES[role])}
        </BodyTypography>
      ),
    },
    {
      Header: t('members.table.status.title') as string,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: {
            /* @ts-ignore */
            active,
          },
        },
      }) => (
        <StatusTagContainer>
          {active ? (
            <NewTag type="text" color="success">
              {t('members.status-column.active-title')}
            </NewTag>
          ) : (
            <H5 color="SECONDARY_GRAY">
              {t('members.status-column.inactive-title')}
            </H5>
          )}
        </StatusTagContainer>
      ),
    },
    {
      Header: () => null,
      disableSortBy: true,
      disableFilters: true,
      accessor: 'organizationId',
      Cell: ({ row: { original } }) =>
        hasScopes(['member:edit']) && (
          <MemberDropdown member={original} refetchMembers={refetchMembers} />
        ),
    },
  ];

  if (error)
    return (
      <LoadingOrErrorContainer>
        <ErrorComponent size="large" />
      </LoadingOrErrorContainer>
    );

  return (
    <Page>
      <InviteMemberModal
        close={handleCloseInviteMemberModal}
        onClose={handleCloseInviteMemberModal}
        show={inviteMemberVisible}
      />
      <Header>
        <NewPageHeader title={t('members.header.title')}>
          {hasScopes(['member:invite']) && (
            <Button
              rightIcon={<Icon type="NEW_ADD_MEMBER" size={20} color="WHITE" />}
              onClick={() => setInviteMemberVisible(true)}
              fontSize="sm"
            >
              {t('members.header.add-new-member-btn.title')}
            </Button>
          )}
        </NewPageHeader>
      </Header>
      <NewCard>
        <Flex mr="9" marginLeft="auto" marginTop="16px">
          <Search
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            placeholder={t('search-member-by-name-input.title')}
          />
        </Flex>
        <TableCardPadding>
          {/* @ts-ignore */}
          <Table<TeamMember>
            /* TODO: Filtragem provisoria removendo os staffs */
            data={
              data?.members.filter((member) => member.role !== 'STAFF') ?? []
            }
            searchValue={searchValue}
            columns={columns}
            loading={loading}
          />
        </TableCardPadding>
      </NewCard>
    </Page>
  );
};

export default Members;
