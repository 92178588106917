import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const HelteEnergyConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'helte-energy',
  LABEL: 'HelteEnergy',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#004693',
    CLICKABLE: '#022E5E',
    ERROR: '#F87060',
    SIDEBAR: '#0B1626',
    LOGIN_LOGO_BACKGROUND: '#0B1626',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#022E5E',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#00C1FD',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#EA5B0C',

    PREPARING: '#00C1FD',
    FINISHING: '#00C1FD',

    SELECTED_DATE_BOUND_BACKGROUND: '#022E5E',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#012245',

    DATE_PRE_SELECTED_BACKGROUND: '#004693',
    DATE_PRE_SELECTED_BORDER: '#015EC5',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#016ADE',
    DATE_PRE_SELECTED_COLOR: '#012245',

    DATE_SELECTED_BACKGROUND: '#004693',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#022E5E',

    CURRENT_DAY: '#004693',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#04E762',
      Subsidized: '#04E762',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#EA5B0C',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      PREPARING: '#00C1FD',
      CHARGING: '#00C1FD',
      FINISHING: '#00C1FD',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#EA5B0C',
    },

    BILLING: '#04E762',
    RECHARGES: '#00C1FD',
    AVAILABILITY: '#EA5B0C',

    BUTTON_AVAILABLE: '#004693',
    BUTTON_UNAVAILABLE: '#016ADE',
    BUTTON_LOADING: '#015EC5',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#004693',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#022E5E',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#012245',

      DATE_PRE_SELECTED_BACKGROUND: '#004693',
      DATE_PRE_SELECTED_BORDER: '#015EC5',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#016ADE',
      DATE_PRE_SELECTED_COLOR: '#012245',

      DATE_SELECTED_BACKGROUND: '#004693',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#022E5E',

      CURRENT_DAY: '#004693',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Urbane-Bold',
    TERTIARY: 'Roboto-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -24.557436,
      lng: -54.037866,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Helte Energy - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/helte-energy',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#0177F8',
      '100': '#016ADE',
      '200': '#015EC5',
      '300': '#0252AB',
      '400': '#024691',
      '500': '#004693',
      '600': '#022E5E',
      '700': '#012245',
      '800': '#01152C',
      '900': '#000913',
    },
    LINK: {
      '50': '#0177F8',
      '100': '#016ADE',
      '200': '#015EC5',
      '300': '#0252AB',
      '400': '#024691',
      '500': '#004693',
      '600': '#022E5E',
      '700': '#012245',
      '800': '#01152C',
      '900': '#000913',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#00C1FD',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#a0fdc7',
      '100': '#6efca9',
      '200': '#55fc9a',
      '300': '#3cfc8b',
      '400': '#23fb7c',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#3167B4',
      '100': '#2C5BA0',
      '200': '#27508B',
      '300': '#224577',
      '400': '#1D3963',
      '500': '#0B1626',
      '600': '#12223B',
      '700': '#0C1727',
      '800': '#060B14',
      '900': '#03050A',
    },
    RESERVE: {
      '50': '#F79965',
      '100': '#F6894D',
      '200': '#F47935',
      '300': '#F2691D',
      '400': '#E85C0E',
      '500': '#EA5B0C',
      '600': '#B7490C',
      '700': '#9F3F0B',
      '800': '#86360A',
      '900': '#6E2D08',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default HelteEnergyConfig;
