import { VoucherEventsContext } from 'pages/Payments/Voucher/context/events-context';
import { useVoucherActions } from 'pages/Payments/Voucher/function';
import { useContext, useState } from 'react';
import { VoucherDropdownProps } from '..';

export enum VoucherOptionsEnum {
  Active,
  Disable,
  Edit,
  ViewDetails,
  Archive,
}

export type UseVoucherActionsDropdownProps = VoucherDropdownProps;

export function useVoucherActionsDropdown({
  selectedVoucher,
}: UseVoucherActionsDropdownProps) {
  const events = useContext(VoucherEventsContext).getEvents();

  const [voucherActionsDropdownOpen, setVoucherActionsDropdownOpen] =
    useState(false);
  const closeVoucherActionsDropdown = () =>
    setVoucherActionsDropdownOpen(false);

  const toggleVoucherActionsDropdown = () => {
    setVoucherActionsDropdownOpen(!voucherActionsDropdownOpen);
  };

  const defineSelectedVoucher = () =>
    events.updateCurrentSelectedVoucher(selectedVoucher);

  const { visibleOptionsPerVoucherStatus } = useVoucherActions({
    voucherStatus: selectedVoucher?.status ?? null,
  });

  const openViewVoucherDetails = () => {
    defineSelectedVoucher();
    events.openVoucherDetailsModal();
  };

  return {
    openArchiveVoucherModal: events.openArchiveVoucherModal,
    voucherActionsDropdownOpen,
    closeVoucherActionsDropdown,
    toggleVoucherActionsDropdown,
    visibleOptionsPerVoucherStatus,
    openViewVoucherDetails,
  };
}
