import { Modal } from 'react-bootstrap';
import styled from 'styled-components/';

const CustomDialog = 'custom-dialog';

export const Container = styled(Modal).attrs({
  dialogClassName: CustomDialog,
})`
  background: ${({ theme: { COLORS } }) => `${COLORS.PRIMARY_GRAY}66`};
  transition: opacity 0.4s linear;

  .${CustomDialog} {
    width: 1200px;
    max-width: 1200px;
    margin: 24px auto auto auto;

    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    transition-delay: 0.4s;
  }

  .modal-content {
    background: transparent;
    box-sizing: border-box;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 24px;
  position: absolute;
  z-index: 1;
  width: 100%;
`;

export const RoundButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  margin: auto 0 auto auto;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-radius: 32px;
  box-shadow: 0px 2px 0px 0px #0000000a;
  cursor: pointer;
`;

export const Body = styled(Modal.Body)`
  height: calc(100vh - 24px - 24px);
  padding: 0 0 31px 0;
  border-radius: 12px;
  background: white;
`;

export const Border = styled.div`
  height: 1px;
  background: ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
`;
