import React, { useState } from 'react';
import Typography from 'components/Typography';
import {
  DropdownSelector,
  DropdownOption,
  Arrow,
  DropdownSelectorContainer,
} from './styles';

type Props<T> = {
  options: readonly T[];
  format: (t: T) => string;
  value: T;
  onChange: (key: T) => void;
  openDirection?: 'top' | 'bottom';
  disabled?: boolean;
  error?: boolean;
  onFocus?: () => void;
  borderWhenActive?: boolean;
  hasPadding?: boolean;
};

export default function Dropdown<T>({
  options,
  format,
  onChange,
  value,
  openDirection = 'bottom',
  disabled = false,
  error = false,
  borderWhenActive = false,
  hasPadding,
  ...props
}: Props<T>) {
  const [isOpen, toggleDropdown] = useState(false);

  return (
    <DropdownSelectorContainer openDirection={openDirection}>
      <DropdownSelector
        open={!disabled && isOpen}
        onClick={() => toggleDropdown((prev) => !prev)}
        numberOfOptions={options.length}
        index={options.indexOf(value)}
        openDirection={openDirection}
        borderWhenActive={borderWhenActive}
        error={error}
        disabled={disabled}
        hasPadding={hasPadding}
        {...props}
      >
        {options.map((option, index) => (
          <DropdownOption
            data-test={`dropdown-option-${index}`}
            key={index}
            onClick={() => onChange(option)}
            openDirection={openDirection}
          >
            <Typography size={14}>{format(option)}</Typography>
          </DropdownOption>
        ))}
        <Arrow open={!disabled && isOpen} opendirection={openDirection} />
      </DropdownSelector>
    </DropdownSelectorContainer>
  );
}
