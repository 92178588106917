import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadcrumbItem, Container, Divider, Path } from './styles';

interface Props {
  labels: string[];
}

const Breadcrumb: React.FC<Props> = ({ labels }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const routes = pathname
    .split('/')
    .slice(1, -1)
    .map((value, index, array) => array.slice(0, index + 1).join('/'));

  return (
    <Container>
      {labels.map((label, index) => (
        <BreadcrumbItem key={routes[index]}>
          <Path onClick={() => history.push(`/${routes[index]}`)}>{label}</Path>
          <Divider>/</Divider>
        </BreadcrumbItem>
      ))}
    </Container>
  );
};

export default Breadcrumb;
