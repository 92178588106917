import { ReactComponent as MONITORING } from './chart_icon.svg';
import { ReactComponent as COMMENT } from './comment.svg';
import { ReactComponent as HISTORY } from './icon_history.svg';
import { ReactComponent as HOME } from './icon_home.svg';
import { ReactComponent as MAP } from './icon_map.svg';
import { ReactComponent as PAYMENT } from './icon_payment.svg';
import { ReactComponent as RESERVATION } from './icon_reservation.svg';
import { ReactComponent as STATION } from './icon_station.svg';
import { ReactComponent as RFID } from './rfid.svg';
import { ReactComponent as USER } from './user-dark-fallback.svg';

export const SIDEBAR = {
  HOME,
  USER,
  HISTORY,
  MAP,
  STATION,
  PAYMENT,
  RFID,
  COMMENT,
  MONITORING,
  RESERVATION,
};
