import { Tag, Text } from '@chakra-ui/react';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { ResumedVoucherStatus } from '../../types';

export type Props = {
  status: ResumedVoucherStatus | null;
};

export function VoucherStatusTag({ status }: Props) {
  const { t } = useTranslation();
  const statesFormatting: {
    [K in ResumedVoucherStatus]: {
      color: string;
      name: string;
      textColor: string;
    };
  } = {
    AVAILABLE: {
      color: '#04E762',
      textColor: '#1F2226',
      name: t('payments.voucher-tab.status-activated.title'),
    },
    ARCHIVED: {
      color: '#454B51',
      textColor: '#FFFFFF',
      name: t('payments.voucher-tab.status-archived.title'),
    },
    DISABLED: {
      color: '#F4F4F4',
      textColor: '#1F2226',
      name: t('payments.voucher-tab.status-disabled.title'),
    },
    EXPIRED: {
      color: '#FA9C91',
      textColor: '#1F2226',
      name: t('payments.voucher-tab.status-expired.title'),
    },
    SCHEDULED: {
      color: '#662C91',
      textColor: '#FFFFFF',
      name: t('payments.voucher-tab.status-scheduled.title'),
    },
  };

  let currentStateFormatting: {
    color: string;
    name: string;
    textColor: string;
  } | null = null;
  if (status) {
    currentStateFormatting = statesFormatting[status];
  }

  return (
    <Tag
      backgroundColor={
        currentStateFormatting?.color ?? config.COLORS.TERTIARY_GRAY
      }
      textAlign={'center'}
      justifyContent={'center'}
      borderRadius={6}
      width={'6rem'}
      px={1}
      py={1}
    >
      <Text
        textColor={currentStateFormatting?.textColor ?? '#1F2226'}
        fontWeight={700}
        fontSize={'sm'}
      >
        {currentStateFormatting?.name ?? '-'}
      </Text>
    </Tag>
  );
}
