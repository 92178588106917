import {
  CHADEMO,
  J1772,
  TESLA,
  TYPE1CCS,
  TYPE2,
  TYPE2CCS,
  TYPE3,
  WALL,
  GBT_AC,
  GBT_DC,
} from './connectors';
import { ALARM } from './markerInfos';

const MAKER_INFOS = {
  ALARM,
};

const CONNECTORS = {
  CHADEMO,
  J1772,
  TYPE1CCS,
  TYPE2CCS,
  TYPE2,
  TYPE3,
  WALL,
  TESLA,
  GBT_AC,
  GBT_DC,
};

export { ReactComponent as ADD } from './add.svg';
export { ReactComponent as ALERT } from './alert.svg';
export { ReactComponent as ARCHIVE } from './archive.svg';
export { ReactComponent as ARROW } from './arrow_icon.svg';
export { ReactComponent as ARROW_MAP } from './arrow_map.svg';
export { ReactComponent as CALENDAR } from './calendar.svg';
export { ReactComponent as CHAIN } from './chain.svg';
export { ReactComponent as CHECK_THIN } from './check.svg';
export { ReactComponent as CHECK_THICK } from './check_login.svg';
export { ReactComponent as CHEVRON } from './chevron.svg';
export { ReactComponent as CLOCK } from './clock.svg';
export { ReactComponent as CONNECTORS_HELP } from './connectors-help.svg';
export { ReactComponent as ERROR_TOAST_EXCLAMATION } from './error_toast_exclamation.svg';
export { ReactComponent as EXPORT } from './export.svg';
export { ReactComponent as FALLBACK_USER } from './fallback_user_dashboard.svg';
export { ReactComponent as FEEDBACK } from './feedback.svg';
export { ReactComponent as FILTER } from './filter.svg';
export { ReactComponent as GALLERY } from './gallery.svg';
export { ReactComponent as HAND_CLICK } from './hand_click.svg';
export { ReactComponent as MANAGER_MODE_ICON } from './icon_manager_mode.svg';
export { ReactComponent as OPERATION_MODE_ICON } from './icon_operation_mode.svg';
export { ReactComponent as LAUNCH } from './launch_icon.svg';
export { ReactComponent as MENU } from './menu.svg';
export { ReactComponent as NEW_X } from './new-X.svg';
export { ReactComponent as PAGINATION_DOWN } from './pagination_down.svg';
export { ReactComponent as PREVIOUS_PAGE } from './pagination_left.svg';
export { ReactComponent as NEXT_PAGE } from './pagination_right.svg';
export { ReactComponent as PAGINATION_UP } from './pagination_up.svg';
export { ReactComponent as PASSWORD_OFF } from './password-off.svg';
export { ReactComponent as PASSWORD_ON } from './password-on.svg';
export { ReactComponent as PENCIL } from './pencil.svg';
export { ReactComponent as POWER } from './power.svg';
export { ReactComponent as RECHARGE_SMALL } from './recharge-small.svg';
export { ReactComponent as RECHARGE } from './recharge.svg';
export { ReactComponent as RESOLVE } from './resolve.svg';
export { ReactComponent as REVENUE_SMALL } from './revenue-small.svg';
export { ReactComponent as REVENUE } from './revenue.svg';
export { ReactComponent as REVIEW } from './review.svg';
export { ReactComponent as SETTINGS } from './settings.svg';
export { ReactComponent as SPINNER } from './spinner.svg';
export { ReactComponent as SUCCESS_TOAST_CHECK } from './success_toast_check.svg';
export { ReactComponent as TOAST_X_ICON } from './toast_x_icon.svg';
export { ReactComponent as TREE_HORIZONTAL_DOTS } from './tree_horizontal_dots.svg';
export { ReactComponent as UNARCHIVE } from './unarchive.svg';
export { ReactComponent as UNRESOLVE } from './unresolve.svg';
export { ReactComponent as USE_RATE_SMALL } from './usage-rate-small.svg';
export { ReactComponent as USE_RATE } from './use_rate.svg';
export { ReactComponent as VERTICAL_ARROW } from './vertical-arrow.svg';
export { ReactComponent as WALLET } from './wallet.svg';
export { ReactComponent as X } from './x.svg';
export { ReactComponent as LEFT_ARROW } from './arrow_left.svg';
export { ReactComponent as RIGHT_ARROW } from './arrow_right.svg';
export { ReactComponent as CLOSE_ICON } from './close_icon.svg';
export { ReactComponent as EDIT } from './edit.svg';
export { ReactComponent as EMPTY_STAR } from './empty-star.svg';
export { ReactComponent as FULL_STAR } from './full-star.svg';
export { ReactComponent as MARKER } from './marker_icon.svg';
export { ReactComponent as OWNER } from './owner.svg';
export { ReactComponent as SIGNAL } from './signal.svg';
export { ReactComponent as USER_DARK_FALLBACK } from './user-dark-fallback.svg';
export { OPERATION_MARKER } from './operation-markers';
export { SIDEBAR } from './sidebar';
export { CONNECTORS };
export { MAKER_INFOS };
