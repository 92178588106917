import styled from 'styled-components';

export const Header = styled.header`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Form = styled.form`
  padding: 24px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
