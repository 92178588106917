import SessionTableCard from 'cards/SessionTable';
import DateDropdown from 'components/DateDropdown';
import {
  transformToSessionFilterInput,
  transformToSessionSortInput,
} from 'components/Filter';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import {
  TableSessionsQuery,
  TableSessionsQueryVariables,
} from 'generated/graphql';
import tableSessions from 'graphql/queries/tableSessions';
import useRealtimeQuery from 'hooks/useRealtimeQuery';
import moment from 'moment';
import RechargeModal from 'new-components/RechargeModal';
import React, { useContext, useState } from 'react';
import AuthContext from 'contexts/Auth/context';
import { formatNumber } from 'services/format';
import { formatSessionFields } from 'services/formatSessionRow';
import { TimeRange, TIME_PERIODS, TIME_PERIODS_LIST } from 'services/timeRange';
import { useTranslation } from 'react-i18next';
import { TranslationKeyType } from 'types/translation';
import IndicatorCard from 'components/IndicatorCard';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import {
  CardContainer,
  CardsContainer,
  Container,
  DropdownContainer,
} from './styles';

const columnsToBeShown = [
  'consumerLabel',
  'cpoId',
  'energyConsumed',
  'emspId',
  'orderTotalCost',
  'stationName',
  'date',
  'begin',
  'end',
  'duration',
  'status',
] as const;

const defaultPagination = {
  pageIndex: 0,
  pageSize: 50,
  filters: [] as any,
  sorts: [] as any,
};

// first time accessing Dashboard(will cache more times)
const now = moment();

const History: React.FC = () => {
  const { t } = useTranslation();
  const placeholderValue = TIME_PERIODS.WEEK;
  const defaultFilters = [
    {
      inputType: 'text',
      type: 'driverId',
      placeholder: t('history.session-table-card.filter-by-user.placeholder'),
      label: t('history.session-table-card.filter-by-user.title'),
    },
    {
      inputType: 'text',
      type: 'stationName',
      placeholder: t(
        'history.session-table-card.filter-by-station.placeholder'
      ),
      label: t('history.session-table-card.filter-by-station.title'),
    },
  ] as const;

  const { selectedOrganizationIds } = useContext(AuthContext);
  const [rechargeModal, setRechargeModal] = useState({
    show: false,
    sessionId: '',
    cpoId: '',
    emspId: '',
  });
  // TODO: lookup defaults
  // State is necessary because when
  // polling, the pageIndex/Size resets
  const [{ pageIndex, pageSize, filters, sorts }, setPagination] =
    useState(defaultPagination);
  const [timeRange, setTimeRange] = useState<TimeRange>(placeholderValue);

  const { data, error, loading } = useRealtimeQuery<
    TableSessionsQuery,
    TableSessionsQueryVariables
  >(tableSessions, {
    variables: {
      timeRange: timeRange.toTimeRangeInput(now),
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: transformToSessionFilterInput(filters),
      sort: transformToSessionSortInput(sorts),
    },
    pollInterval: 1000 * 10,
  });

  const handleCloseRechargeModal = () =>
    setRechargeModal({ ...rechargeModal, show: false });
  const handleRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) =>
    setRechargeModal({
      show: true,
      sessionId: sessionId || '',
      cpoId: cpoId || '',
      emspId: emspId || '',
    });

  const currentSelectedSession = data?.sessions.find(
    (s) => s.id === rechargeModal.sessionId
  );
  const isSessionFromAnotherEmsp =
    selectedOrganizationIds && currentSelectedSession
      ? !selectedOrganizationIds.includes(currentSelectedSession.emspId)
      : false;

  const { orgNameMap } = useContext(AuthContext);
  const defaultCurrencyTypes = useDefaultCurrencyTypes();
  return (
    <Page>
      <RechargeModal
        show={rechargeModal.show}
        onClose={handleCloseRechargeModal}
        sessionId={rechargeModal.sessionId}
        isFromAnotherEmsp={isSessionFromAnotherEmsp}
        cpoId={rechargeModal.cpoId}
        emspId={rechargeModal.emspId}
      />
      <Container>
        <PageHeader>
          <DropdownContainer>
            <DateDropdown
              defaultOption={placeholderValue}
              placeholderValue={placeholderValue}
              options={TIME_PERIODS_LIST}
              format={(option) => t(option.shortLabel as TranslationKeyType)}
              onChange={setTimeRange}
            />
          </DropdownContainer>
        </PageHeader>
        <CardsContainer>
          <IndicatorCard
            error={error}
            loading={loading}
            borderColor="RECHARGES"
            indicatorText="history.sessions-indicator-card.title"
            detailsText="history.sessions-indicator-card.title"
            thisPeriodTotalValue={data?.sessionsSummary.count ?? 0}
            timePeriodText={timeRange.label as TranslationKeyType}
          />
          <IndicatorCard
            error={error}
            loading={loading}
            borderColor="RECHARGES"
            indicatorText="history.consumed-energy-indicator-card.title"
            detailsText="history.consumed-energy-indicator-card.title"
            thisPeriodTotalValue={data?.sessionsSummary.energyConsumed ?? 0}
            timePeriodText={timeRange.label as TranslationKeyType}
            valueSuffix={'kWh'}
            valueFormat={(n: number) => formatNumber(n / 1000, { digits: 2 })}
          />
        </CardsContainer>
        <CardContainer>
          <SessionTableCard
            fetchData={setPagination}
            pageCount={Math.ceil((data?.sessionsSummary.count || 0) / pageSize)}
            data={
              data?.sessions?.map((session) =>
                formatSessionFields(session, orgNameMap, defaultCurrencyTypes)
              ) ?? []
            }
            loading={loading}
            error={error}
            columns={columnsToBeShown}
            filters={defaultFilters}
            /** timeRange needs to be passed as a prop so that the Table component knows that it needs
            to refresh everytime timeRange changes */
            timeRange={timeRange}
            handleRowClick={handleRowClick}
          />
        </CardContainer>
      </Container>
    </Page>
  );
};

export default History;
