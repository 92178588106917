import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useQuery } from 'urql';
/** Esse hook faz uma query autenticada porém não restrita ao workspace/organização atual */
export default function useNonWorkspaceableAuthQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, Omit<TVariables, 'orgId'>> = {}
): QueryResult<TData, TVariables> {
  const [result, executeQuery] = useQuery<TData, TVariables>({
    query,
    pause: options?.skip,
    // TODO: Fix that, the types should be from urql
    // @ts-ignore
    requestPolicy: options?.fetchPolicy || 'cache-and-network',
    ...options,
  });
  return {
    data: result.data,
    loading: result.fetching,
    error: result.error as any,
    refetch: executeQuery,
    networkStatus: 1,
  } as any;
}
