import { Typography } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  width: max-content;
  gap: 16px;
`;

export const SummaryInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 4px;
`;

export const SummaryInfoText = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  size: 14,
})``;
