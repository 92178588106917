import Page from 'components/Page';
import {
  ConfigurationsPageQuery,
  ConfigurationsPageQueryVariables,
} from 'generated/graphql';
import configurationsPageQuery from 'graphql/queries/configurationsPage';
import useNonWorkspaceableAuthQuery from 'hooks/useNonWorkspaceableAuthQuery';
import React, { useState } from 'react';
import ManagersTable from './ManagersTable';
import ManagerForm, { FormValues } from './MemberForm';
import { Container } from './styles';

const Configurations: React.FC = () => {
  const [selectedAccounToEdit, setSelectedAccounToEdit] = useState<
    FormValues | undefined
  >();

  const { data, loading, refetch } = useNonWorkspaceableAuthQuery<
    ConfigurationsPageQuery,
    ConfigurationsPageQueryVariables
  >(configurationsPageQuery);

  return (
    <Page>
      <Container>
        {loading || !data ? (
          <h1>Loading</h1>
        ) : (
          <>
            <ManagerForm
              selectedAccounToEdit={selectedAccounToEdit}
              refetchMembers={refetch}
              organizationsIds={data.organizations.map((org) => org.id)}
            />
            <ManagersTable
              members={data!.allMembers}
              refetchMembers={refetch}
              setSelectedAccounToEdit={setSelectedAccounToEdit}
            />
          </>
        )}
      </Container>
    </Page>
  );
};
export default Configurations;
