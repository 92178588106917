import { gql } from '@apollo/client';

export default gql`
  query AllTeamMembers($search: String) {
    members(search: $search, sort: { orgId: ASC }) {
      id
      role
      active
      organizationId
      profile {
        id
        firstName
        lastName
        email
      }
      lastInactivation {
        by {
          id
          profile {
            id
            firstName
            lastName
            email
          }
        }
        at
      }
      accessGiven {
        by {
          id
          profile {
            id
            firstName
            lastName
            email
          }
        }
        at
      }
    }
  }
`;
