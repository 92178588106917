import { useState } from 'react';
import Pagination, { linesPerPageOptionsType } from 'components/Pagination';
import Search from 'atomic-components/molecules/Search';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { SkeletonsTable } from 'atomic-components/atoms/SkeletonsTable';
import Config from 'config';

const DEFAULT_LINES_PER_PAGE_OPTION = 25;

type SelectedItemsOptions = {
  allItemsSelected: boolean;
  allItemsSelectedExcept: (string | number)[];
  selectedItems: (string | number)[];
};

export type Props<T> = {
  searchBarOptions: {
    setSearchValue: (searchValue: string) => void;
    searchValue: string;
    searchBarText: string;
  };
  paginationOptions: {
    pageIndex: number;
    setPageIndex: (pageIndex: number) => void;
    pageCount: number;
    setPageCount: (pageCount: number) => void;
  };
  dataOptions: {
    columns: {
      label: string;
      dataKeys?: string[];
      data?: string | number | null;
      format?: (values: string[]) => string;
    }[];
    data: T[];
    summaryCount: number;
    loading: boolean;
    error: boolean;
  };
  selectedItemsOptions: SelectedItemsOptions;
  setItemsOptions: (selectedItemsOptions: SelectedItemsOptions) => void;
};

const SelectItemsTable = <T extends object & { id: number | string }>({
  paginationOptions: { pageCount, pageIndex, setPageCount, setPageIndex },
  dataOptions: { columns, data, error, loading, summaryCount },
  searchBarOptions: { searchBarText, searchValue, setSearchValue },
  selectedItemsOptions: {
    allItemsSelected,
    allItemsSelectedExcept,
    selectedItems,
  },
  setItemsOptions,
}: Props<T>) => {
  const { t } = useTranslation();

  const propertyAccessor = (obj: object) => (key: string) =>
    key.split('.').reduce((acc: any, prop) => acc[prop], obj);

  const getFormatedCellContent = (
    itemData: T,
    dataKeys: string[],
    format?: Function
  ): string => {
    const cellData = dataKeys.map((key) => propertyAccessor(itemData)(key));

    if (cellData.length === 1) {
      return cellData[0];
    }

    if (format) {
      return format(cellData);
    }

    return cellData.join(' ');
  };

  const handleItemCheckboxClick = (id: number | string) => {
    if (allItemsSelected) {
      setItemsOptions({
        allItemsSelectedExcept: [id],
        selectedItems: [],
        allItemsSelected: false,
      });
      return;
    }

    if (allItemsSelectedExcept.length === 0) {
      if (selectedItems.includes(id)) {
        setItemsOptions({
          allItemsSelected: false,
          allItemsSelectedExcept: [],
          selectedItems: selectedItems.filter((itemId) => itemId !== id),
        });
      } else if (selectedItems.length === summaryCount - 1) {
        setItemsOptions({
          allItemsSelected: true,
          allItemsSelectedExcept: [],
          selectedItems: [],
        });
      } else {
        setItemsOptions({
          selectedItems: [...selectedItems, id],
          allItemsSelected: false,
          allItemsSelectedExcept: [],
        });
      }
      return;
    }

    if (selectedItems.length === 0) {
      if (allItemsSelectedExcept.includes(id)) {
        if (allItemsSelectedExcept.length === 1) {
          setItemsOptions({
            allItemsSelected: true,
            allItemsSelectedExcept: [],
            selectedItems: [],
          });
        } else {
          setItemsOptions({
            allItemsSelectedExcept: allItemsSelectedExcept.filter(
              (itemId) => itemId !== id
            ),
            allItemsSelected: false,
            selectedItems: [],
          });
        }
      } else {
        setItemsOptions({
          allItemsSelectedExcept: [...allItemsSelectedExcept, id],
          allItemsSelected: false,
          selectedItems: [],
        });
      }
    }
  };

  const handleSelectAllCheckboxClick = () => {
    setItemsOptions({
      allItemsSelected: !allItemsSelected,
      selectedItems: [],
      allItemsSelectedExcept: [],
    });
  };

  const getSelectionStatusText = () => {
    if (allItemsSelected) {
      return t('select-checkbox.all-selected');
    }
    if (selectedItems.length > 0) {
      return `${selectedItems.length} ${t('select-checkbox.selected')}`;
    }
    if (allItemsSelectedExcept.length > 0) {
      return `${summaryCount - allItemsSelectedExcept.length} ${t(
        'select-checkbox.selected'
      )}`;
    }
    return t('select-checkbox.none-selected');
  };

  const [selectedLinesPerPageOption, setSelectedLinesPerPageOption] =
    useState<linesPerPageOptionsType>(DEFAULT_LINES_PER_PAGE_OPTION);

  const getTableContainerContent = () => {
    if (error) {
      return (
        <Box textAlign="center">{t('session-table-card.error-message')}</Box>
      );
    }

    if (loading) {
      return (
        <SkeletonsTable
          columns={[
            'checkboxplaceholder',
            ...columns.map(({ label }) => label),
          ]}
        />
      );
    }

    if (!data.length) {
      return <></>;
    }

    if (data.length) {
      return data.map((item) => (
        <Tr>
          <Td>
            <Checkbox
              _checked={{
                '& .chakra-checkbox__control': {
                  background: 'primary.500',
                  border: '0px',
                },
              }}
              isChecked={
                allItemsSelected ||
                selectedItems.includes(item.id) ||
                (allItemsSelectedExcept.length > 0 &&
                  !allItemsSelectedExcept.includes(item.id))
              }
              onChange={() => handleItemCheckboxClick(item.id)}
            />
          </Td>
          {columns.map(({ data, dataKeys, format }) => (
            <Td textAlign="left" fontSize="small">
              {dataKeys ? getFormatedCellContent(item, dataKeys, format) : data}
            </Td>
          ))}
        </Tr>
      ));
    }
    return <div>Unexpected State</div>;
  };

  return (
    <Flex flexDirection="column" gap="1.5rem">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="sm" color={Config.COLORS.SECONDARY_GRAY}>
          {getSelectionStatusText()}
        </Text>
        <Search
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={searchBarText}
        />
      </Flex>
      <Box overflowY="scroll" height="19.5rem">
        <Table colorScheme="gray">
          <Thead>
            <Tr>
              <Th maxWidth={20}>
                <Checkbox
                  _checked={{
                    '& .chakra-checkbox__control': {
                      background: 'primary.500',
                      border: '0px',
                    },
                  }}
                  isDisabled={data.length === 0}
                  isChecked={allItemsSelected}
                  onChange={() => handleSelectAllCheckboxClick()}
                />
              </Th>
              {columns.map(({ label }) => (
                <Th textAlign="left">{label}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody color="gray.900" position="relative">
            {getTableContainerContent()}
          </Tbody>
        </Table>
      </Box>
      <Box h="16" position="relative" justifyContent="center">
        <Pagination
          activePage={pageIndex}
          pageCount={Math.ceil((summaryCount || 0) / pageCount)}
          setActivePage={setPageIndex}
          setPageCount={setPageCount}
          setSelectedLinesPerPageOption={setSelectedLinesPerPageOption}
          selectedLinesPerPageOption={selectedLinesPerPageOption}
        />
      </Box>
    </Flex>
  );
};

export default SelectItemsTable;
