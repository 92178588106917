import React from 'react';
import { formatCurrencyType, formatNumber } from 'services/format';
import IndicatorCard from 'components/IndicatorCard';
import { CurrencyType } from 'types';
import { Container } from './styles';

interface Props {
  error: any;
  loading: boolean;
  averageRevenue: number;
  numberOfRecharges: number;
  usageRate: number;
  orderedNumberOfRechargesOrgArray: { name: string; value: number }[];
  orderedAverageRevenueOrgArray: { name: string; value: number }[];
  orderedUsageRateOrgArray: { name: string; value: number }[];
  currencyType?: CurrencyType;
}

const StationsSummaryCards: React.FC<Props> = ({
  error,
  loading,
  averageRevenue,
  numberOfRecharges,
  usageRate,
  orderedAverageRevenueOrgArray,
  orderedNumberOfRechargesOrgArray,
  orderedUsageRateOrgArray,
  currencyType,
}) => (
  <Container>
    <IndicatorCard
      error={error}
      loading={loading}
      borderColor="BILLING"
      thisPeriodTotalValue={averageRevenue}
      indicatorText={'station-average-revenue-card.title'}
      detailsText={'stations.revenue-details'}
      orderedOrgArray={orderedAverageRevenueOrgArray}
      valuePreffix={`${formatCurrencyType({ currencyType })}`}
      valueFormat={(n: number) => formatNumber(n, { digits: 2 })}
      othersComputeFunction={(othersArray: number[]) =>
        othersArray.reduce((valA, valB) => valA + valB, 0) / othersArray.length
      }
    />
    <IndicatorCard
      borderColor="RECHARGES"
      thisPeriodTotalValue={numberOfRecharges}
      loading={loading}
      error={error}
      indicatorText={'station-total-sessions-card.title'}
      detailsText={'home.sessions-details'}
      orderedOrgArray={orderedNumberOfRechargesOrgArray}
    />
    <IndicatorCard
      borderColor="AVAILABILITY"
      thisPeriodTotalValue={usageRate}
      loading={loading}
      error={error}
      indicatorText={'station-usage-rate-card.title'}
      detailsText={'stations.use-rate-details'}
      orderedOrgArray={orderedUsageRateOrgArray}
      valueFormat={(n: number) => formatNumber(n * 100, { digits: 2 })}
      valueSuffix={'%'}
      othersComputeFunction={(othersArray: number[]) =>
        othersArray.reduce((valA, valB) => valA + valB, 0) / othersArray.length
      }
    />
  </Container>
);

export default StationsSummaryCards;
