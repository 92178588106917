import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AllDriversQuery } from 'generated/graphql';
import SelectItemsTable, {
  Props as TableProps,
} from 'new-components/SelectItemsTable';
import Config from 'config';
import { CreateVoucherStatesContext } from '../../context/state-context';
import { useCreateVoucherDriversSelection } from './services';

type Driver = AllDriversQuery['drivers'][number];

const SelectDriversScreen = () => {
  const { t } = useTranslation();
  const {
    createVoucherData: { organizationId },
  } = useContext(CreateVoucherStatesContext);
  const {
    data,
    driversSelection,
    getDriversResultError,
    getDriversResultLoading,
    pageCount,
    pageIndex,
    searchValue,
    setDriversSelection,
    setPageCount,
    setPageIndex,
    setSearchValue,
  } = useCreateVoucherDriversSelection();
  const columns: TableProps<Driver>['dataOptions']['columns'] = [
    {
      label: t('payments.voucher-tab.create-voucher.drivers.table.driver-id'),
      dataKeys: ['id'],
    },
    {
      label: t('payments.voucher-tab.create-voucher.drivers.table.driver-name'),
      dataKeys: ['profile.firstName', 'profile.lastName'],
      format: ([firstName, lastName]) => `${firstName} ${lastName}`,
    },
    {
      label: t(
        'payments.voucher-tab.create-voucher.drivers.table.driver-email'
      ),
      dataKeys: ['profile.email'],
    },
  ];

  const selectDisplayContent = () => {
    if (organizationId) {
      return (
        <SelectItemsTable<Driver>
          dataOptions={{
            data: data?.drivers ?? [],
            summaryCount: data?.driversSummary.count ?? 0,
            columns,
            error: getDriversResultError,
            loading: getDriversResultLoading,
          }}
          paginationOptions={{
            pageIndex,
            setPageIndex,
            pageCount,
            setPageCount,
          }}
          searchBarOptions={{
            searchBarText: t('search-driver-input.title'),
            searchValue,
            setSearchValue,
          }}
          selectedItemsOptions={{
            allItemsSelected: driversSelection.allDriversSelected,
            allItemsSelectedExcept: driversSelection.allDriversSelectedExcept,
            selectedItems: driversSelection.selectedDrivers,
          }}
          setItemsOptions={({
            allItemsSelected,
            allItemsSelectedExcept,
            selectedItems,
          }) =>
            setDriversSelection({
              allDriversSelected: allItemsSelected,
              selectedDrivers: selectedItems as number[],
              allDriversSelectedExcept: allItemsSelectedExcept as number[],
            })
          }
        />
      );
    }

    return (
      <Box>
        {t(
          'payments.voucher-tab.create-voucher.drivers.no-organization-selected'
        )}
      </Box>
    );
  };

  return (
    <FormControl w="100%" isRequired={true}>
      <Flex
        bg="white"
        borderWidth="thin"
        borderColor="gray.100"
        borderRadius="1rem"
        p="1.5rem"
        gap="1.5rem"
        flexDirection="column"
      >
        <Box>
          <FormLabel fontSize="xl">
            {t('payments.voucher-tab.create-voucher.drivers.title')}
          </FormLabel>
          <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
            {t('payments.voucher-tab.create-voucher.drivers.subtitle')}
          </Text>
        </Box>
        {selectDisplayContent()}
      </Flex>
    </FormControl>
  );
};

export default SelectDriversScreen;
