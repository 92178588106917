import React, { useContext } from 'react';
import { Icon } from 'new-components/Icon';
import { H3 } from 'new-components/Typographies/styles';
import { Tag, Heading } from '@chakra-ui/react';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import { Profile, VehicleId, Comment } from './styles';
import { Props as HeaderProps } from '../index';

type Props = {
  id: number;
} & Pick<HeaderProps, 'profile'>;

const VehicleInfo: React.FC<Props> = ({ id, profile }) => {
  const { orgNameMap } = useContext(AuthContext);
  return (
    <Profile>
      <VehicleId>
        <Icon type="NEW_TRUCK" color="PRIMARY_GRAY"></Icon>
        <H3>{id}</H3>
        <Tag
          marginLeft={5}
          bg={'gray.200'}
          padding={'6px 8px'}
          textAlign={'center'}
        >
          <Heading fontSize="14px" color={'white'} width={'full'}>
            {profile ? formatOrgId(profile.emspId, orgNameMap) : '-'}
          </Heading>
        </Tag>
      </VehicleId>
      <Comment>{profile?.plate}</Comment>
      <Comment>{profile?.carModel.label}</Comment>
    </Profile>
  );
};
export default VehicleInfo;
