import FulfillMemberProfileModal from 'atomic-components/organisms/modals/FulfillMemberProfile';
import Navbar from 'components/TopNavbar';
import AuthContext from 'contexts/Auth/context';
import useHasScopes from 'hooks/useHasScope';
import {
  History,
  Home,
  Map,
  Members,
  OwnerCreateStation,
  Stations,
} from 'pages';
import Customers from 'pages/Customers';
import Comments from 'pages/Comments';
import Configurations from 'pages/Configurations';
import MonitoringPage from 'pages/Monitoring';
import Payments from 'pages/Payments';
import { StationPage, tabs } from 'pages/StationPage';
import React, { useContext, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import RFID from 'pages/RFID';
import useHasModule from 'hooks/useHasModule';
import Profile from 'pages/Profile';
import GraphqlMonitoringPageRepository from 'drivers/GraphqlMonitoringPageRepository';
import MonitoringPageHelper from 'pages/Monitoring/helper';
import MonitoringProvider from 'contexts/monitoring/provider';
import { CreateVoucher } from 'pages/Payments/Voucher/CreateVoucherScreen';
import { PaymentsProvider } from 'pages/Payments/context';
import { VoucherProvider } from 'pages/Payments/Voucher/context';
import Sidebar from './Sidebar';
import { Container, Content, InnerContent } from './styles';

const Dashboard = () => {
  const { member, memberAuthToken } = useContext(AuthContext);
  const hasScopes = useHasScopes();
  const hasModule = useHasModule();
  const showCustomersPage =
    (hasScopes(['driver:read']) && hasModule('USERS')) ||
    (hasScopes(['vehicle:read']) && hasModule('FLEET')) ||
    (hasScopes(['group:read']) &&
      hasScopes(['driver:read']) &&
      hasModule('USERS'));

  const [showFulfillMemberProfileModal, setShowFulfillMemberProfileModal] =
    useState(!member?.profile.firstName);

  const closeFulfillMemberProfileModal = () =>
    setShowFulfillMemberProfileModal(false);

  const monitoringPageRepository = new GraphqlMonitoringPageRepository();

  const monitoringPageHelper = new MonitoringPageHelper(
    monitoringPageRepository
  );

  const Monitoring = () => <MonitoringPage helper={monitoringPageHelper} />;

  return (
    <Container>
      <Sidebar />
      <Content className="app-content">
        <Navbar />
        <InnerContent>
          <FulfillMemberProfileModal
            closeModal={closeFulfillMemberProfileModal}
            show={showFulfillMemberProfileModal}
          />
          {hasScopes(['station:read']) && (
            <Route exact path="/" component={Home} />
          )}

          {hasScopes(['session:read', 'station:read']) && (
            <Route exact path="/historico" component={History} />
          )}

          {hasScopes(['station:read']) && (
            <Route exact path="/mapa" component={Map} />
          )}

          {hasScopes(['station:read']) && (
            <Route exact path="/meus-eletropostos" component={Stations} />
          )}

          {hasModule('PAYMENT') && hasScopes(['order:read']) && (
            <PaymentsProvider>
              <Route exact path="/pagamentos" component={Payments} />
            </PaymentsProvider>
          )}

          {member?.role === 'STAFF' &&
            hasModule('PAYMENT') &&
            hasScopes(['voucher:create']) && (
              <VoucherProvider>
                <Route
                  exact
                  path="/pagamentos/criar-cupom"
                  component={CreateVoucher}
                />
              </VoucherProvider>
            )}

          {showCustomersPage && (
            <Route exact path="/meus-clientes" component={Customers} />
          )}

          <Route exact path="/profile" component={Profile} />

          {hasModule('RFID') && hasScopes(['rfid:read']) && (
            <Route exact path="/rfid" component={RFID} />
          )}

          {hasScopes(['comment:read']) && (
            <Route exact path="/comentarios" component={Comments} />
          )}

          {hasScopes(['station:read']) && (
            <MonitoringProvider>
              <Route exact path="/monitoring" component={Monitoring} />
            </MonitoringProvider>
          )}

          {hasScopes(['station:internal']) && (
            <Route exact path="/configuracoes" component={Configurations} />
          )}

          {hasScopes(['member:read']) && (
            <Route exact path="/membros" component={Members} />
          )}

          {hasScopes(['station:internal']) && (
            <Switch>
              <Redirect
                from="/meus-eletropostos/:id/owner/operation"
                to="/meus-eletropostos/:id/owner/internaluse"
              />
              <Redirect
                from="/meus-eletropostos/:id/owner/edit"
                to="/meus-eletropostos/:id/owner/internaluse"
              />
            </Switch>
          )}

          {hasScopes(['station:read']) && (
            <Route
              path="/meus-eletropostos/:id"
              component={() => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { id: stationId } = useParams<{ id: string }>();
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const history = useHistory();
                const currentTab =
                  tabs.find(
                    (tab) =>
                      history.location.pathname ===
                      `/meus-eletropostos/${stationId}${tab.path}`
                  ) ?? tabs[0];
                return (
                  <StationPage
                    stationId={stationId}
                    currentTab={currentTab}
                    onTabChange={(tab) =>
                      history.push(`/meus-eletropostos/${stationId}${tab.path}`)
                    }
                  >
                    {tabs
                      .filter((tab) => hasScopes(tab.requiredScope))
                      .map((tab) => (
                        <Route
                          exact
                          path={`/meus-eletropostos/:id${tab.path}`}
                          component={tab.component}
                        />
                      ))}
                  </StationPage>
                );
              }}
            />
          )}
          {hasScopes(['staff']) && (
            <Route path="/owner/create" component={OwnerCreateStation} />
          )}

          {/*
            Temporarily inactive routes

             {hasScopes(['organization:read-sub']) && (
               <Route
                exact
                path="/sub-organizacoes"
                component={SubOrganizations}
              />
            )}
            {hasScopes(['agreement:read']) && hasModule('ROAMING') && (
              <Route exact path="/roaming" component={Roaming} />
            )}
          */}

          {/* A página de reservas ainda não foi lançada! */}
          {/* <Route exact path="/reservas" component={Reservations} /> */}

          {!memberAuthToken && <Redirect to="/login" />}
        </InnerContent>
      </Content>
    </Container>
  );
};

export default Dashboard;
