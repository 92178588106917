import { Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { StationDetailsQuery } from 'generated/graphql';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'new-components/Icon';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import {
  Container,
  ErrorIcon,
  Row,
  SpinnerIcon,
  UnshrinkableText,
} from './styles';

interface Props {
  stationDetails?: StationDetailsQuery['station'];
  cpoId: string;
  onClose: () => void;
  loading: boolean;
}

const StationPreview: React.FC<Props> = ({
  onClose,
  stationDetails,
  loading,
  cpoId,
}) => {
  const { t } = useTranslation();
  const { orgNameMap } = useContext(AuthContext);
  const contentControl = () => {
    if (loading) return <SpinnerIcon />;
    if (!stationDetails) return <ErrorIcon />;

    const { name, address: { street, streetNumber, city, state } = {} } =
      stationDetails;
    return (
      <Flex justify="space-between" align="flex-start" pb={2} zIndex={1}>
        <Box pt={4}>
          <Heading fontSize="xl" noOfLines={1}>
            {name}
          </Heading>
          <Row>
            <Text noOfLines={1} fontSize="sm">
              {street}
            </Text>
            <UnshrinkableText>{`, ${
              streetNumber ||
              t('map.station-preview.station-without-street-number.text')
            }`}</UnshrinkableText>
          </Row>
          <Row>
            <Text fontSize="sm">{city}</Text>
            <UnshrinkableText>{`, ${state}`}</UnshrinkableText>
          </Row>
        </Box>
        <Box pt={2}>
          <IconButton
            size="xs"
            colorScheme="gray"
            variant="ghost"
            aria-label={t(
              'map.station-preview.icon-button.close-station-view-aria-label'
            )}
            icon={<Icon type="NEW_CLOSE_X" size={20} color="PRIMARY_GRAY" />}
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          />
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Container>
        <Box
          bg="gray.50"
          w="full"
          px={4}
          py={1}
          borderWidth="thin"
          borderColor="gray.500"
          borderTopRadius="md"
        >
          <Flex align="center" justify="center">
            <Text fontSize="xs" noOfLines={1}>
              {formatOrgId(cpoId, orgNameMap)}
            </Text>
          </Flex>
        </Box>
        <Flex justify="center" px={2}>
          {contentControl()}
        </Flex>
      </Container>
    </>
  );
};

export default StationPreview;
