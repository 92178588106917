import styled from 'styled-components';
import { BodyTypography } from 'new-components/Typographies/styles';

export const Header = styled.header`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Form = styled.form`
  padding: 24px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TypographyWithMT = styled(BodyTypography)`
  padding-top: 10px;
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
