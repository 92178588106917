import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { ChevronIcon, Container, LogoIcon, LogoRow, MenuIcon } from './styles';

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const [isOpen, setOpen] = useState(true);

  const toggleOpen = () => setOpen(!isOpen);

  return (
    <Container onClick={toggleOpen} wide={isOpen}>
      <LogoRow open={isOpen}>
        <LogoIcon visible={isOpen} />
        {isOpen ? <ChevronIcon /> : <MenuIcon />}
      </LogoRow>
      <Menu showText={isOpen} />
    </Container>
  );
};

export default Sidebar;
