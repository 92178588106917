import { gql } from '@apollo/client';

export default gql`
  mutation InviteMember(
    $data: InviteMemberInput!
    $where: EmspWhereUniqueInput!
  ) {
    inviteMember(data: $data, where: $where)
  }
`;
