import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { AccountExclusionAuthContext } from 'main/account-exclusion/contexts/account-exclusion';
import { Form } from 'main/account-exclusion/components/form';

export const AccountExclusionLogin = () => {
  const { appName } = useContext(AccountExclusionAuthContext);
  const history = useHistory();

  if (!appName) history.push('/');

  return (
    <Flex
      h="full"
      margin="auto"
      maxWidth={512}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Form />
    </Flex>
  );
};
