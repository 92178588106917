import React from 'react';
import LoadingComponent from 'new-components/LoadingComponent';
import { BodyTypography } from 'new-components/Typographies/styles';
import {
  CarModel,
  Address,
  GetDriverRfidsQuery,
  GetDriverRfidsQueryVariables,
} from 'generated/graphql';
import { useAuthQuery } from 'hooks';
import driverRfidsQuery from 'graphql/queries/driverRfids';
import useHasScopes from 'hooks/useHasScope';
import useHasModule from 'hooks/useHasModule';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ErrorComponent,
  RowGroup,
  UserInfoRow,
  InfoGroup,
  InfoLabel,
  InfoValue,
  LoadingContainer,
} from './styles';
import PaymentMethods, { PaymentMethod } from './PaymentMethods';
import RFIDs from './RFIDs';

export type Props = {
  address?: Partial<Address>;
  cars?: Partial<CarModel>[];
  datesMarks: { createdAt?: string };
  error: boolean;
  loading: boolean;
  driverId: number;
  paymentMethods?: PaymentMethod[];
  profile?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    CPF?: string;
    cellPhone?: string;
  };
  refetchInfo?: () => void;
  minimumWalletDepositAmount?: number;
  availableBalance?: number;
};

const General: React.FC<Props> = ({
  address,
  cars,
  datesMarks,
  error,
  loading,
  driverId,
  paymentMethods,
  profile,
  refetchInfo,
  minimumWalletDepositAmount,
  availableBalance,
}) => {
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const hasModule = useHasModule();

  const { data: driverRfidsData, loading: driverRfidsLoading } = useAuthQuery<
    GetDriverRfidsQuery,
    GetDriverRfidsQueryVariables
  >(driverRfidsQuery, {
    variables: {
      where: {
        id: driverId,
      },
    },
    skip: !hasModule('RFID') || !hasScopes(['rfid:read']),
  });

  if (loading)
    return (
      <LoadingContainer>
        <LoadingComponent size={51} />
      </LoadingContainer>
    );

  if (error)
    return <ErrorComponent>{t('gerenic-error.message')}</ErrorComponent>;

  return (
    <Container>
      <UserInfoRow>
        <InfoGroup>
          <InfoLabel>{t('driver-modal.general-tab.info.name.title')}</InfoLabel>
          <InfoValue>
            <BodyTypography>{profile?.firstName || '-'}</BodyTypography>
            <BodyTypography>{profile?.lastName || '-'}</BodyTypography>
          </InfoValue>
        </InfoGroup>
        <InfoGroup>
          <InfoLabel>
            {t('driver-modal.general-tab.info.email.title')}
          </InfoLabel>
          <InfoValue>
            <BodyTypography>{profile?.email || '-'}</BodyTypography>
          </InfoValue>
        </InfoGroup>
      </UserInfoRow>
      <UserInfoRow>
        <InfoGroup>
          <InfoLabel>
            {t('driver-modal.general-tab.info.created-at.title')}
          </InfoLabel>
          <InfoValue>
            <BodyTypography>{datesMarks?.createdAt || '-'}</BodyTypography>
          </InfoValue>
        </InfoGroup>
      </UserInfoRow>
      <RowGroup>
        <UserInfoRow>
          <InfoGroup>
            <InfoLabel>
              {t('driver-modal.general-tab.info.address.title')}
            </InfoLabel>
            <InfoValue>
              <BodyTypography>
                {`${
                  address?.street
                    ? `${address.street}, ${address?.streetNumber || ''}`
                    : ''
                }`}
              </BodyTypography>
              <BodyTypography>
                {`${address?.city || ''}${
                  address?.city && address?.state ? ' - ' : ''
                }${address?.state || ''}`}
              </BodyTypography>
              <BodyTypography>{address?.postalCode}</BodyTypography>
            </InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>
              {t('driver-modal.general-tab.info.vehicle.title')}
            </InfoLabel>
            <InfoValue>
              {cars?.length
                ? cars.map((car) => (
                    <BodyTypography>{car.label}</BodyTypography>
                  ))
                : '-'}
            </InfoValue>
          </InfoGroup>
        </UserInfoRow>
        <UserInfoRow>
          <InfoGroup>
            <InfoLabel>
              {t('driver-modal.general-tab.info.phone.title')}
            </InfoLabel>
            <InfoValue>
              <BodyTypography>{profile?.cellPhone || '-'}</BodyTypography>
            </InfoValue>
          </InfoGroup>
        </UserInfoRow>
        <UserInfoRow>
          <InfoGroup>
            <InfoLabel>
              {t('driver-modal.general-tab.info.cpf.title')}
            </InfoLabel>
            <InfoValue>
              <BodyTypography>{profile?.CPF || '-'}</BodyTypography>
            </InfoValue>
          </InfoGroup>
        </UserInfoRow>
      </RowGroup>
      <InfoGroup>
        <InfoLabel>{t('driver-modal.general-tab.rfids.title')}</InfoLabel>
        <RFIDs
          rfids={driverRfidsData?.driver?.rfids}
          refetchInfo={refetchInfo}
          loading={driverRfidsLoading}
        />
      </InfoGroup>
      {paymentMethods?.length ? (
        <>
          <InfoGroup>
            <InfoLabel>
              {t('driver-modal.general-tab.payment-methods.title')}
            </InfoLabel>
            <PaymentMethods
              minimumWalletDepositAmount={minimumWalletDepositAmount}
              availableBalance={availableBalance}
              paymentMethods={paymentMethods}
              driverId={driverId}
            />
          </InfoGroup>
        </>
      ) : null}
    </Container>
  );
};
export default General;
