import React from 'react';
import styled, { css } from 'styled-components';
import { ToastContainer as UnstyledToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Config from 'config';
import Typography from '../Typography/index';

const ToastContainer = styled(UnstyledToastContainer).attrs({
  position: 'bottom-right',
  autoClose: 3000,
})`
  width: 368px;
  .Toastify__toast {
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 8px;
    padding: 16px;
    background-color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
    margin-right: 10px;
  }
  .Toastify__progress-bar {
    height: 4px;
    background-color: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  }
`;

const iconStyle = css`
  width: 20px;
  min-width: 24px;
  height: 20px;
  margin-right: 10px;
  margin-top: 1px;
`;

const CheckIcon = styled(Config.ICONS.SUCCESS_TOAST_CHECK)`
  & > .b {
    fill: ${({ theme }) => theme.COLORS.PRIMARY};
  }
  & > .a {
    fill: none;
  }
  ${iconStyle}
`;

const ErrorIcon = styled(Config.ICONS.ERROR_TOAST_EXCLAMATION)`
  & > .b {
    fill: ${({ theme }) => theme.COLORS.ERROR};
  }
  & > .a {
    fill: none;
  }
  ${iconStyle}
`;

export const ContainerMessage = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SuccessMessage: React.FC = ({ children }) => (
  <ContainerMessage>
    <CheckIcon />
    <Typography>{children}</Typography>
  </ContainerMessage>
);

export const ErrorMessage: React.FC = ({ children }) => (
  <ContainerMessage>
    <ErrorIcon />
    <Typography>{children}</Typography>
  </ContainerMessage>
);

export default ToastContainer;
