import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationChargePointId(
    $where: SelectStationInput!
    $chargePointId: String!
  ) {
    changeStationChargePointId(where: $where, chargePointId: $chargePointId)
  }
`;
