import { useContext, useEffect, useState } from 'react';
import { CreateVoucherEventsContext } from '../../../context/events-context';

export const useCreateVoucherErrorModal = () => {
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (events.createVoucherErrorModalOpen) setIsErrorModalOpen(true);
  }, [events.createVoucherErrorModalOpen]);

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return {
    isErrorModalOpen,
    closeErrorModal,
  };
};
