import Typography from 'components/Typography';
import Config from 'config';
import styled, { css } from 'styled-components';

const dropdownOptionHeight = 35;
const moveFirstLinePerPageUpBy = (index: number) =>
  index * -dropdownOptionHeight;
const reverse = (openDirection: 'top' | 'bottom') =>
  openDirection === 'top' ? 'bottom' : 'top';
// eslint-disable-next-line
const xor = (x: boolean, y: boolean) => !!(+x ^ +y); // this is a logical XOR operator
const ErrorColor = `2px solid ${Config.COLORS.ERROR}`;
const DefaultBorder = `1px solid ${Config.COLORS.TERTIARY_GRAY}`;
const ActiveBorder = `2px solid ${Config.COLORS.PRIMARY}`;

export const DropdownSelectorContainer = styled.div<{
  openDirection: 'top' | 'bottom';
}>`
  position: relative;
  height: ${({ openDirection }) => (openDirection === 'bottom' ? 37 : -37)}px;
  z-index: 100;
`;

export const Arrow = styled(Config.ICONS.PAGINATION_UP)<{
  open: boolean;
  opendirection: 'top' | 'bottom';
}>`
  position: absolute;
  top: 5px;
  right: 6px;
  transform: ${({ open, opendirection }) =>
    xor(open, opendirection === 'bottom') ? 'rotate(180deg)' : 'rotate(0deg)'};
  padding: 10px;
  height: 10px;
  box-sizing: unset;
`;

export const DropdownSelector = styled.div<{
  open: boolean;
  numberOfOptions: number;
  index: number;
  openDirection: 'top' | 'bottom';
  disabled: boolean;
  error: boolean;
  borderWhenActive: boolean;
  hasPadding?: boolean;
}>`
  ${({
    open,
    numberOfOptions,
    index,
    openDirection,
    disabled,
    error,
    borderWhenActive,
    hasPadding,
    theme: { COLORS },
  }) => css`
     border: ${
       error
         ? ErrorColor
         : open && borderWhenActive
         ? ActiveBorder
         : DefaultBorder
     };
     pointer-events: ${disabled ? 'none' : 'auto'};
     opacity: ${disabled ? 0.5 : 1};
    ${reverse(openDirection)}: 0px;
    height: ${
      open ? dropdownOptionHeight * numberOfOptions : dropdownOptionHeight
    }px;
    border-radius: 9px;
    border: 1px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
    user-select: none;
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.WHITE};
    display: flex;
    transition: height 0.3s;
    flex-flow: ${openDirection === 'bottom' ? 'column' : 'column-reverse'}
      nowrap;
    align-items: flex-start;
    overflow: hidden;
    box-sizing: unset;
    &:active {
      background: ${!open && COLORS.BACKGROUND};
    }
    padding: ${hasPadding ? 2 : 0}px;
    > * {
      transition: all 0.3s;
      &:first-child {
      margin-${reverse(openDirection)}: ${
    open ? '0' : moveFirstLinePerPageUpBy(index)
  }px;
    }
      &:not(${Arrow}) {
        pointer-events: ${open ? 'auto' : 'none'};
        :nth-child(${index + 1}) {
          > ${Typography} {
            color: ${open && COLORS.CLICKABLE};
          }
        }
      }
    }
  `}
`;

export const DropdownOption = styled.div<{ openDirection: 'top' | 'bottom' }>`
  width: 100%;
  display: flex;
  min-height: 35.5px;
  align-items: ${({ openDirection }) =>
    openDirection === 'top' ? 'center' : 'none'};
  &:hover {
    background: ${({ theme }) => theme.COLORS.BACKGROUND};
  }
  ${Typography} {
    margin-right: 35px;
    margin-left: 10px;
    height: 35px;
    align-items: center;
    display: flex;
  }
`;
