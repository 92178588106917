import styled from 'styled-components';
import { Typography } from 'components';

export const Container = styled.div``;

export const Form = styled.form``;

export const Label = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  size: 14,
})`
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 400px;
`;

export const TableCardWrapper = styled.div`
  margin-top: 32px;
`;

export const PaddedContainer = styled.div`
  padding: 24px 16px;
`;

export const LeftAlignedButtonRow = styled.div`
  display: flex;
  justify-content: start;
`;

export const RightAlignedButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SpaceBetweenButtons = styled.div`
  width: 8px;
`;
