import React, { useState } from 'react';
import MonitoringTemplate from 'atomic-components/templates/monitoring';
import { useTranslation } from 'react-i18next';
import useHasScopes from 'hooks/useHasScope';
import MonitoringPageHelper from './helper';
import { StationMonitoringStatus } from './types';

type MonitoringPageProps = {
  helper: MonitoringPageHelper;
};

const Monitoring: React.FC<MonitoringPageProps> = ({ helper }) => {
  const defaultStatusSelected = helper.getDefaultStatusSelected();

  const [statusSelected, setStatusSelected] = useState<
    StationMonitoringStatus[] | undefined
  >(defaultStatusSelected);

  const hasScopes = useHasScopes();

  if (statusSelected === undefined) setStatusSelected([]);

  const { t } = useTranslation();

  const { data, error, loading } = helper.getTemplateData(
    statusSelected,
    hasScopes(['alarm:read'])
  );

  return (
    <MonitoringTemplate
      templateData={{ data, error, loading }}
      helper={helper}
      props={{
        setStatusSelected,
        statusSelected,
        t,
      }}
    />
  );
};

export default Monitoring;
