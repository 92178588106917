import Typography from 'components/Typography';
import styled, { css } from 'styled-components';

export const GraphTitle = styled(Typography).attrs({
  size: 12,
  color: 'WHITE',
})<{ backgroundColor: string; showing: 'station' | 'connectors' }>`
  background: ${({ backgroundColor }) => backgroundColor};
  height: 25px;
  margin-bottom: 14px;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 10px;
  justify-content: flex-start;
  align-items: center;
  position: ${({ showing }) => showing === 'station' && 'absolute'};
  box-sizing: border-box;
  > * {
    margin-right: 10px;
  }
`;

export const GraphDescription = styled.div<{ horizontal?: boolean }>`
  position: relative;
  max-height: calc(100% - 39px);
  max-width: 33%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  overflow-y: auto;

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-flow: row nowrap;
      max-width: 100%;
      > * {
        min-width: 33.3%;
        margin-top: 0px;
        align-content: baseline;
        &:first-child {
          top: 0;
          position: sticky;
        }
      }
    `}
`;

export const ShowStationOrConnectorGraph = styled(Typography)`
  cursor: pointer;
  padding-top: 10px;
`;

export const Row = styled.div<{
  showing: 'station' | 'connectors';
  single?: boolean;
}>`
  ${({ single }) => (single ? '' : 'display: flex')};
  flex-flow: column wrap;
  flex-direction: ${({ single }) => single && 'row'};
  flex: 1;
  min-height: 204.5px;
  margin-top: 24.5px;
  ${GraphTitle} {
    width: ${({ showing }) => (showing === 'station' ? '20%' : '33%')};
  }
  ${GraphDescription} {
    display: ${({ showing }) => (showing === 'station' ? 'none' : 'flex')};
  }
  &:nth-child(2):nth-last-child(2) {
    ${({ showing, single, theme }) =>
      showing === 'connectors' &&
      css`
        ${GraphDescription} {
          max-width: 100%;
          max-height: 91.5px;
          flex-direction: row;
          justify-content: flex-start;
          > * {
            width: 33.33%;
          }
          border-bottom: ${single
            ? `2px solid ${theme.COLORS.TERTIARY_GRAY}`
            : 'none'};
          padding-bottom: ${single ? 30 : 0}px;
          margin-bottom: ${single ? 35 : 0}px;
        }
        ${GraphTitle} {
          width: 100%;
        }
      `}
  }
  &:not(:nth-last-child(2)) {
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
  }
  padding-bottom: ${({ single }) => (single ? -90 : -20)}px;
`;

export const SelectorContainer = styled.div`
  padding-top: 20px;
`;
