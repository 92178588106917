import styled from 'styled-components';
import { H5, BodyTypography } from 'new-components/Typographies/styles';
import { Button, ButtonProps, chakra } from '@chakra-ui/react';

export const Container = styled('section')`
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 7px;
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  border-radius: 8px;
  background-color: ${({ theme: { COLORS } }) => COLORS.WHITE};
  min-height: 98px;
  min-width: 200px;
`;

export const Title = styled(H5)`
  color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
`;

export const Info = styled(BodyTypography)`
  color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
`;

export const WalletContainer = styled('section')`
  border-right: 2px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  padding-right: 24px;
  height: 110px;
`;

export const IconsContainer = styled('section')`
  display: flex;
  justify-content: space-between;
`;

export const ButtonIcon = styled.button<{}>``;

export const AddCreditButton = chakra(Button, {
  baseStyle: {
    color: 'gray.900',
    borderColor: 'gray.100',
    bg: 'white',
    fontFamily: 'heading',
    fontSize: '12px',
    w: 'full',
    justifyContent: 'space-between',
    paddingTop: '2px',
  } as ButtonProps,
});
