import { Typography } from 'components';
import Config from 'config';
import { Icon } from 'new-components/Icon';
import styled, { css } from 'styled-components';
import { Accent, State } from '.';

const getBubbleStyle = (state: State, hasAction: boolean, accent: Accent) => {
  if (state === 'disabled') {
    return `
      display: grid;
      grid-template-columns: 1fr;
      background-color: ${Config.COLORS.TERTIARY_GRAY};
    `;
  }
  return `
      background-color: ${Config.COLORS.WHITE};
      display: grid;
      ${
        accent === 'danger'
          ? 'grid-template-columns: 4px 1fr;'
          : 'grid-template-columns: 1fr;'
      }  
      ${
        hasAction &&
        `
          :hover {
            cursor: pointer;
          }
        `
      }
      ${
        state !== 'selected' &&
        hasAction &&
        `
          :hover {
            background-color: ${Config.COLORS.BACKGROUND};
          }
          :focus {
            outline: none;
          }
          :active {
            background-color: ${Config.COLORS.TERTIARY_GRAY};
          }
        `
      };
      ${
        state === 'selected' &&
        `
            background-color: ${Config.COLORS.BACKGROUND};
            border: 1px solid ${Config.COLORS.PRIMARY_GRAY};
        `
      }
    `;
};

export const Container = styled.button<{
  accent: Accent;
  state: State;
  hasAction: boolean;
}>`
  ${({ hasAction, state, accent }) => css`
    background: ${({ theme: { COLORS } }) => COLORS.WHITE};
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    border-radius: 8px;
    padding: 0px;
    margin: 0px;
    width: 100%;
    overflow: hidden;
    &:hover {
      cursor: default;
    }
    ${getBubbleStyle(state, hasAction, accent)}
  `}
`;

export const DangerBorder = styled.div`
  background-color: ${({ theme: { COLORS } }) =>
    COLORS.STATION_STATUS.INOPERATIVE};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  width: 4px;
`;

export const BubbleContainer = styled.div`
  display: grid;
  grid-template-rows: 2fr;
  padding: 8px;
`;

export const BubbleContent = styled.div<{ state: State }>`
  ${({ state }) => css`
    column-gap: 8px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    ${state === 'disabled' && 'filter: grayscale(1) opacity(0.5);'}
  `}
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  width: 100%;
`;

export const Title = styled(Typography).attrs({
  weight: 'bold',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

export const LeftIcon = styled(Icon)``;

export const RightIcon = styled(Icon)`
  margin-left: 8px;
`;
