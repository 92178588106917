import { ChakraProvider } from '@chakra-ui/react';
import { theme as chakraTheme } from 'chakra/theme';
import AuthProvider from 'contexts/Auth/provider';
import { createClient } from 'graphql/client';
import Head from 'Head';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import { BrowserRouter, Route } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import defaultTheme from 'theme/theme';
import { Provider } from 'urql';
import '../../react-app-env.d';
import resources from 'translation/index';
import ToastContainer from 'components/ToastContainer';
import { useState } from 'react';
import { useURLParams } from 'hooks/useURLParam';
import GlobalStyles from 'styles';
import { ResetPassword } from './pages';

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

const ResetPasswordApp = () => {
  const urlParam = useURLParams();
  const lng = urlParam.get('language') ?? undefined;

  i18n.use(initReactI18next).init({
    lng,
    resources,
    fallbackLng: 'pt_BR',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

  const [client, setClient] = useState(createClient());
  const resetClient = () => setClient(createClient());

  return (
    <Provider value={client}>
      <Head />
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <ToastContainer />
          <AuthProvider resetClient={resetClient}>
            <BrowserRouter>
              <Route component={ResetPassword} />
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </ChakraProvider>
    </Provider>
  );
};
export default ResetPasswordApp;
