import type { FilterTerm, Filters } from 'types';

import { useReducer } from 'react';

export type CheckboxState = Map<string, boolean>;

export function useCheckboxReducer<U extends string>(
  filters: Filters<U>,
  displayFilters: FilterTerm<U>[]
) {
  const defaultCheckboxes = displayFilters.reduce((map, { type, value }) => {
    const { inputType } = filters.find((f) => f.type === type)!;

    return inputType === 'checkbox' ? map.set(type + value, true) : map;
  }, new Map<string, boolean>());

  const reducer = (
    state: CheckboxState,
    { type, value }: FilterTerm<U>
  ): CheckboxState => state.set(type + value, !state.get(type + value));

  return useReducer(reducer, defaultCheckboxes);
}
