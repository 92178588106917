import styled from 'styled-components';
import { H5 } from 'new-components/Typographies/styles';

export const Header = styled('section')`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 24px 24px 0 24px;
`;

export const Body = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  padding: 15px 24px 24px 24px;
`;

export const Label = styled('label')`
  display: flex;
  flex-flow: column nowrap;
`;

export const ButtonWrapper = styled('section')`
  align-self: flex-end;
`;

export const OrganizationLabel = styled(H5)`
  text-transform: capitalize;
`;
