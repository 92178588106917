import { useTranslation } from 'react-i18next';
import { FormControl, Box, Text, FormLabel, Flex } from '@chakra-ui/react';
import { PercentageInput } from 'atomic-components/molecules/PercentageInput';
import Config from 'config';
import { useCreateVoucherRulesDiscountSelection } from './services';

export const VoucherDiscountAmountSelection = () => {
  const { t } = useTranslation();
  const { discount, setDiscount } = useCreateVoucherRulesDiscountSelection();

  return (
    <FormControl isRequired={true}>
      <Flex flexDirection="column" gap="1rem">
        <Flex flexDirection="column">
          <FormLabel fontWeight="bold" fontSize="sm">
            {t('payments.voucher-tab.create-voucher.rules.discount.title')}
          </FormLabel>
          <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
            {t('payments.voucher-tab.create-voucher.rules.discount.subtitle')}
          </Text>
        </Flex>
        <Box>
          <PercentageInput
            value={discount}
            setValue={(value: number) => setDiscount(value)}
          />
        </Box>
      </Flex>
    </FormControl>
  );
};
