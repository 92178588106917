import { useTranslation } from 'react-i18next';
import Config from 'config';
import { Box, Flex, Checkbox, Text } from '@chakra-ui/react';
import { useCreateVoucherRulesValidityPeriod } from '../services';

const InexpirableCheckBox = () => {
  const { t } = useTranslation();
  const { validityPeriod, toggleVoucherIsUnexpirable } =
    useCreateVoucherRulesValidityPeriod();
  return (
    <Box mt="0.5rem">
      <Flex flexDirection="row" gap="0.625rem">
        <Checkbox
          _checked={{
            '& .chakra-checkbox__control': {
              background: 'primary.500',
              border: '0rem',
            },
          }}
          mt="0.25rem"
          isChecked={validityPeriod.isUnexpirable}
          onChange={() => toggleVoucherIsUnexpirable()}
        />
        <Text textAlign="center" fontSize="sm">
          {t(
            'payments.voucher-tab.create-voucher.rules.validity-period.no-validity-period'
          )}
        </Text>
      </Flex>
      <Box mt="2">
        <Text color={Config.COLORS.SECONDARY_GRAY} fontSize="sm">
          {t(
            'payments.voucher-tab.create-voucher.rules.validity-period.no-validity-period.subtitle'
          )}
        </Text>
      </Box>
    </Box>
  );
};

export default InexpirableCheckBox;
