import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const EvMobiConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'evmobi',
  LABEL: 'EV MOBI',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#2F4E24',
    CLICKABLE: '#203419',
    ERROR: '#F87060',
    SIDEBAR: '#2F4E24',
    LOGIN_LOGO_BACKGROUND: '#2F4E24',

    RISE: '#AEC90B',
    FALL: '#F87060',
    NEUTRAL: '#203419',

    CRITICAL: '#F74C39',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#AEC90B',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#407A38',
    CHARGING: '#3D3F80',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#522218',

    PREPARING: '#3D3F80',
    FINISHING: '#3D3F80',

    SELECTED_DATE_BOUND_BACKGROUND: '#152310',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#152310',

    DATE_PRE_SELECTED_BACKGROUND: '#2F4E24',
    DATE_PRE_SELECTED_BORDER: '#497B37',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#538D3F',
    DATE_PRE_SELECTED_COLOR: '#152310',

    DATE_SELECTED_BACKGROUND: '#2F4E24',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#152310',

    CURRENT_DAY: '#2F4E24',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#AEC90B',
      Subsidized: '#AEC90B',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#407A38',
      CHARGING: '#3D3F80',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#522218',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#407A38',
      PREPARING: '#3D3F80',
      CHARGING: '#3D3F80',
      FINISHING: '#3D3F80',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#522218',
    },

    BILLING: '#AEC90B',
    RECHARGES: '#3D3F80',
    AVAILABILITY: '#522218',

    BUTTON_AVAILABLE: '#2F4E24',
    BUTTON_UNAVAILABLE: '#538D3F',
    BUTTON_LOADING: '#497B37',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#2F4E24',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#152310',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#152310',

      DATE_PRE_SELECTED_BACKGROUND: '#2F4E24',
      DATE_PRE_SELECTED_BORDER: '#497B37',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#538D3F',
      DATE_PRE_SELECTED_COLOR: '#152310',

      DATE_SELECTED_BACKGROUND: '#2F4E24',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#152310',

      CURRENT_DAY: '#2F4E24',
    },
  },
  FONTS: {
    PRIMARY: 'Sora-Regular',
    SECONDARY: 'Sora-Bold',
    TERTIARY: 'Sora-Light',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -5.791348340263979,
      lng: -35.19707650287319,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'EV-MOBI - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/ev-mobi',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#5E9F46',
      '100': '#538D3F',
      '200': '#497B37',
      '300': '#3F6930',
      '400': '#355728',
      '500': '#2F4E24',
      '600': '#203419',
      '700': '#152310',
      '800': '#0B1108',
      '900': '#070B05',
    },
    LINK: {
      '50': '#5E9F46',
      '100': '#538D3F',
      '200': '#497B37',
      '300': '#3F6930',
      '400': '#355728',
      '500': '#2F4E24',
      '600': '#203419',
      '700': '#152310',
      '800': '#0B1108',
      '900': '#070B05',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#DCF545',
      '100': '#D7F42D',
      '200': '#D2F215',
      '300': '#C2E00D',
      '400': '#ADC70D',
      '500': '#AEC90B',
      '600': '#83970A',
      '700': '#6E7E09',
      '800': '#596608',
      '900': '#444E06',
    },
    CHARGING: {
      '50': '#686BBB',
      '100': '#585AB2',
      '200': '#4C4FA4',
      '300': '#454792',
      '400': '#3C3E87',
      '500': '#3D3F80',
      '600': '#35376E',
      '700': '#2D2F5D',
      '800': '#25264B',
      '900': '#1D1E3A',
    },
    AVAILABLE: {
      '50': '#6AB95F',
      '100': '#5AB04E',
      '200': '#519E47',
      '300': '#498C40',
      '400': '#45853D',
      '500': '#407A38',
      '600': '#2E5629',
      '700': '#254521',
      '800': '#1C3319',
      '900': '#132211',
    },
    SECONDARY: {
      '50': '#6AB95F',
      '100': '#5AB04E',
      '200': '#519E47',
      '300': '#498C40',
      '400': '#45853D',
      '500': '#407A38',
      '600': '#2E5629',
      '700': '#254521',
      '800': '#1C3319',
      '900': '#132211',
    },
    RESERVE: {
      '50': '#B44832',
      '100': '#9F402D',
      '200': '#8B3928',
      '300': '#773122',
      '400': '#63291D',
      '500': '#522218',
      '600': '#3B1912',
      '700': '#27110C',
      '800': '#130806',
      '900': '#160907',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default EvMobiConfig;
