import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

type StyledRouterLinkProps = {
  textDecoration: 'underline' | 'none';
};

export const StyledRouterLink = styled(RouterLink)<StyledRouterLinkProps>`
  text-decoration: ${(props) => props.textDecoration};
  &:hover {
    text-decoration: ${(props) => props.textDecoration};
  }
`;
