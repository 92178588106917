import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const VoltbrasConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: true,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-2',
  CODE: 'voltbras',
  LABEL: 'Voltbras',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#FFB901',
    CLICKABLE: '#161A20',
    ERROR: '#F87060',
    SIDEBAR: '#1F2226',
    LOGIN_LOGO_BACKGROUND: '#1F2226',

    RISE: '#11D073',
    FALL: '#F87060',
    NEUTRAL: '#0045DB',

    CRITICAL: '#F74C39',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#11D073',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#11D073',
    CHARGING: '#00C2FF',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F6334F',
    RESERVED: '#6C36FE',

    PREPARING: '#00C2FF',
    FINISHING: '#00C2FF',

    SELECTED_DATE_BOUND_BACKGROUND: '#DB9800',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#B77A00',

    DATE_PRE_SELECTED_BACKGROUND: '#FFB901',
    DATE_PRE_SELECTED_BORDER: '#FFEB99',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#FFF6CC',
    DATE_PRE_SELECTED_COLOR: '#B77A00',

    DATE_SELECTED_BACKGROUND: '#FFB901',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#DB9800',

    CURRENT_DAY: '#015AFF',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#11D073',
      Subsidized: '#11D073',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#11D073',
      CHARGING: '#00C2FF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F6334F',
      RESERVED: '#6C36FE',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#11D073',
      PREPARING: '#00C2FF',
      CHARGING: '#00C2FF',
      FINISHING: '#00C2FF',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#6C36FE',
    },

    BILLING: '#11D073',
    RECHARGES: '#00C2FF',
    AVAILABILITY: '#6C36FE',

    BUTTON_AVAILABLE: '#1F2226',
    BUTTON_UNAVAILABLE: '#EBF0F4',
    BUTTON_LOADING: '#D8E1E9',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#015AFF',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#DB9800',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#B77A00',

      DATE_PRE_SELECTED_BACKGROUND: '#FFB901',
      DATE_PRE_SELECTED_BORDER: '#FFEB99',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#FFF6CC',
      DATE_PRE_SELECTED_COLOR: '#B77A00',

      DATE_SELECTED_BACKGROUND: '#FFB901',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#DB9800',

      CURRENT_DAY: '#015AFF',
    },
  },
  FONTS: {
    PRIMARY: 'Inter-Regular',
    SECONDARY: 'Sora-Medium',
    TERTIARY: 'Sora-Bold',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -27.5993747,
      lng: -48.5198098,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'VoltBras - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/voltbras',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#FFF8D4',
      '100': '#FFF6CC',
      '200': '#FFEB99',
      '300': '#FFDD66',
      '400': '#FFCF40',
      '500': '#FFB901',
      '600': '#DB9800',
      '700': '#B77A00',
      '800': '#935E00',
      '900': '#7A4E00',
    },
    LINK: {
      '50': '#DBEDFF',
      '100': '#CCE5FF',
      '200': '#99C7FF',
      '300': '#66A6FF',
      '400': '#408AFF',
      '500': '#015AFF',
      '600': '#0045DB',
      '700': '#0033B7',
      '800': '#002493',
      '900': '#011F7A',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
    SUCCESS: {
      '50': '#E0FFE4',
      '100': '#CEFCD3',
      '200': '#C3FCC9',
      '300': '#6CF095',
      '400': '#46E287',
      '500': '#11D073',
      '600': '#0CB272',
      '700': '#08956D',
      '800': '#057863',
      '900': '#066352',
    },
    CHARGING: {
      '50': '#D9FFFD',
      '100': '#CCFFFC',
      '200': '#99FCFF',
      '300': '#66F0FF',
      '400': '#3FDEFF',
      '500': '#00C2FF',
      '600': '#0097DB',
      '700': '#0071B7',
      '800': '#005093',
      '900': '#00427A',
    },
    AVAILABLE: {
      '50': '#E0FFE4',
      '100': '#CEFCD3',
      '200': '#C3FCC9',
      '300': '#6CF095',
      '400': '#46E287',
      '500': '#11D073',
      '600': '#0CB272',
      '700': '#08956D',
      '800': '#057863',
      '900': '#066352',
    },
    SECONDARY: {
      '50': '#EBF0F3',
      '100': '#EBF0F4',
      '200': '#D8E1E9',
      '300': '#A8B3BD',
      '400': '#6B737C',
      '500': '#1F2226',
      '600': '#161A20',
      '700': '#0F131B',
      '800': '#090D16',
      '900': '#020202',
    },
    RESERVE: {
      '50': '#EDE1FF',
      '100': '#E6D6FE',
      '200': '#CBAEFE',
      '300': '#AD86FE',
      '400': '#9468FE',
      '500': '#6C36FE',
      '600': '#5227DA',
      '700': '#3C1BB6',
      '800': '#291193',
      '900': '#230F79',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#fddeda',
      '200': '#fcc8c2',
      '300': '#fbb2a9',
      '400': '#fa9c91',
      '500': '#F87060',
      '600': '#f6442f',
      '700': '#f52e17',
      '800': '#e8220a',
      '900': '#b71a08',
    },
  },
};
export default VoltbrasConfig;
