import { Client, dedupExchange } from '@urql/core';
import { devtoolsExchange } from '@urql/devtools';
// import { cacheExchange } from '@urql/exchange-graphcache';
// import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import config from 'config';

// const storage = makeDefaultStorage({
//   idbName: 'graphcache-v3', // The name of the IndexedDB database
//   maxAge: 1, // The maximum age of the persisted data in days
// });

// const alarmTypes = Object.keys({
//   ...config.LABELS.STATION_ALARM_TYPES,
//   ...config.LABELS.CONECTOR_ALARM_TYPES,
// });

const createClient = (): Client =>
  new Client({
    url: `${config.API.BASE_URL}${config.API.GRAPHQL_ENDPOINT}`,
    fetchOptions: () => {
      const cookies = Object.fromEntries(
        document.cookie
          .split('; ')
          .map((v) => v.split('=').map(decodeURIComponent))
      );
      const token = cookies.authToken;
      return {
        headers: { authorization: token ? `Bearer ${token}` : '' },
      };
    },
    requestPolicy: 'cache-and-network',
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      // cacheExchange({
      //   schema: require('../generated/graphql-schema.json'),
      //   storage,
      //   updates: {},
      //   keys: {
      //     Connector: (fields) =>
      //       `${(fields?.station as any)?.id!}-${fields.id}`,
      //     Address: (fields) => `${fields.streetNumber}-${fields.postalCode}`,
      //     SessionCollectionSummary: () => null,
      //     DriverSummary: () => null,
      //     Station: (fields) => `${fields.id}`,
      //     OrderCollectionSummary: () => null,
      //     StationCollectionSummary: () => null,
      //     // this is a way of having chaching keys in all types of alarms
      //     // like StationAlarmStopChargingFailed, StationAlarmWentOffline, etc...
      //     ...Object.fromEntries(
      //       alarmTypes.map((alarmType) => [
      //         alarmType,
      //         (fields) => `${fields.stationId}-${fields.id}`,
      //       ])
      //     ),
      //     Driver: (fields) => `${fields.id}`,
      //     CPORoamingInfo: (fields) => `${fields.roamingId}`,
      //     eMSPRoamingInfo: (fields) => `${fields.roamingId}`,
      //     Organization: (fields) => `${fields.id}`,
      //     Order: (fields) => fields.orderId?.toString() ?? '',
      //     PaymentCard: (fields) => `${fields.cardToken}`,
      //     Session: (fields) => `${fields.id}`,
      //     DeviceInfo: () => null,
      //     Profile: (fields) => `${fields.id}`,
      //     Group: (fields) => `${fields.id}`,

      //     SessionCollectionSummaryPerDateTime: () => null,
      //     ConnectorCollectionSummaryPerStatus: () => null,
      //     MeterSample: () => null,
      //     StreamEvent: (fields) => `${fields.streamId}-${fields.streamVersion}`,
      //     UsageRatePerDateTime: () => null,
      //     AlarmCollectionSummary: () => null,
      //     Coordinates: () => null,
      //     Member: (fields) => `${fields?.organizationId as any}-${fields.id}`,
      //     VehicleSummary: () => null,
      //   },
      //   optimistic: {},
      // }),
      multipartFetchExchange,
    ],
  });

export { createClient };
