import { CreateVoucherEventsContext } from 'pages/Payments/Voucher/CreateVoucherScreen/context/events-context';
import { useContext, useEffect, useState } from 'react';

export const useCreateVoucherRulesSelectOrganization = () => {
  const events = useContext(CreateVoucherEventsContext).getEvents();
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  useEffect(() => {
    events.updateVoucherOrganizationId(organizationId);
    // eslint-disable-next-line
  }, [organizationId]);

  return {
    organizationId,
    setOrganizationId,
  };
};
