import styled from 'styled-components';

const cardPadding = 12;

export const Container = styled.div`
  background: ${({ theme: { COLORS } }) => COLORS.WHITE};
  display: flex;
  flex-flow: column nowrap;
  width: 360px;
  border-radius: 8px;
  cursor: default;
  min-height: 160px;

  border: 0.5px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  left: -187px;
  position: absolute;
  top: 16px;
  z-index: 1;
  :before {
    background: ${({ theme: { COLORS } }) => COLORS.WHITE};
    border: 0.5 solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    top: -5px;
    content: '';
    height: 16px;
    left: 180px;
    position: absolute;
    transform: rotate(45deg);
    width: 16px;
    z-index: -1;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: ${cardPadding}px;
`;

export const Row = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: row nowrap;
  ${({ margin }) => (margin ? `margin: ${margin};` : null)}
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TagContainer = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`;

/* header - station information */

export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border: none;
  box-shadow: 0 0 0 0.5px ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};

  cursor: pointer;

  position: absolute;
  width: 32px;
  height: 32px;
  top: -48px;
  right: 16px;

  :hover {
    cursor: pointer;
    ${({ theme: { COLORS } }) => `background: ${COLORS.BACKGROUND};`}
  }
  :focus {
    outline: none;
  }
  :active {
    background-color: ${({ theme: { COLORS } }) => `${COLORS.BACKGROUND};`};
  }
`;

export const ErrorOrLoading = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
