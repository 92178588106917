import config from 'config';
import ConfigType from 'config/typing';
import {
  DeviceInfo,
  GetRechargeModalDataQuery,
  GetRechargeModalDataQueryVariables,
  SessionStatus,
} from 'generated/graphql';
import rechargeModalData from 'graphql/queries/rechargeModalData';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import moment from 'moment';
import AuthContext from 'contexts/Auth/context';
import EditSessionModal from 'new-components/EditSessionModal';
import LoadingComponent from 'new-components/LoadingComponent';
import Modal from 'new-components/NewModal';
import { H3 } from 'new-components/Typographies/styles';
import React, { useContext, useState } from 'react';
import { formatCurrency, formatEnergy, formatPower } from 'services/format';
import { formatPostalCode } from 'services/formatPostalCode';
import { getCardBrandIcon } from 'services/getCardBrandIcon';
import { getCardBrandName } from 'services/getCardBrandName';
import { getLastFourCardNumbers } from 'services/getLastFourCardNumbers';
import secondsToTime from 'services/secondsToTime';
import ForceStopRechargeModal from 'new-components/ForceStopRechargeModal';
import StopRechargeModal from 'atomic-components/organisms/modals/StopRecharge';
import Typography from 'components/Typography';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { CurrencyType } from 'types';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import ConsumerCard from './components/ConsumerCard';
import Header from './components/Header';
import MeasurementsGraph from './components/MeasurementsGraph';
import PaymentMethod from './components/PaymentMethod';
import PaymentStatus, {
  Props as PaymentStatusProps,
  Status as PaymentStatusType,
} from './components/PaymentStatus';
import SessionCost from './components/SessionCost';
import SessionOptionsDropdown from './components/SessionOptionsDropdown';
import StartedFinished from './components/StartedFinished';
import StationAndConnector from './components/StationAndConnector';
import {
  Body,
  CardsGroup,
  ContentGroup,
  ErrorComponent,
  PaymentInfo,
  PaymentInfoId,
} from './styles';
import { RoamingInfo } from './components/RoamingInfo';

type Props = {
  show: boolean;
  onClose: () => void;
  sessionId: string;
  isFromAnotherEmsp?: boolean;
  cpoId: string;
  emspId: string;
};

function RechargeModal({
  show,
  onClose,
  sessionId,
  isFromAnotherEmsp,
  cpoId,
  emspId,
}: Props) {
  const { t } = useTranslation();
  const { selectedOrganizationIds } = useContext(AuthContext);
  const hasScopes = useHasScopes();
  const [isEditing, setIsEditing] = useState(false);
  const [isStopRecharge, setIsStopRecharge] = useState(false);
  const [isForceStopRecharge, setIsForceStopRecharge] = useState(false);

  const handleStopRecharge = () => setIsStopRecharge(false);

  const { data, error, loading } = useAuthQuery<
    GetRechargeModalDataQuery,
    GetRechargeModalDataQueryVariables
  >(rechargeModalData, {
    variables: {
      where: { id: sessionId },
      includeOrder: hasScopes(['order:read']),
      includeConsumer:
        !isFromAnotherEmsp && hasScopes(['driver:read', 'vehicle:read']),
    },
    fetchPolicy: 'network-only',
    skip: !sessionId?.length,
  });

  const defaultCurrencyTypes = useDefaultCurrencyTypes();

  const session = data?.session;
  const currencyType =
    data?.session?.order?.currencyType ??
    getOrganizationDefaultCurrencyType(
      defaultCurrencyTypes,
      data?.session?.emspId
    );

  if (loading)
    return (
      <Modal show={show} onClose={onClose} size="lg">
        <LoadingComponent size={50} top={300} />
      </Modal>
    );

  if (error || !session) {
    return (
      <Modal show={show} onClose={onClose} size="lg">
        <ErrorComponent />
      </Modal>
    );
  }

  const mapSessionStatusToIcon = (
    status: SessionStatus
  ): keyof ConfigType['NEW_ICONS'] => {
    switch (status) {
      case 'CHARGING':
        return 'NEW_AC_CHARGER';
      case 'ERROR':
        return 'NEW_ERROR';
      case 'FINISHED':
        return 'NEW_SUCCESS';
      case 'TIMED_OUT':
        return 'NEW_ERROR';
      default:
        return 'NEW_THREE_HORIZONTAL_DOTS';
    }
  };

  const getPastTimeByStatus = (rechargeSession: typeof session) => {
    if (!rechargeSession) return;
    switch (rechargeSession.status) {
      case 'CHARGING':
        return moment(rechargeSession.startedChargingAt).fromNow();
      case 'FINISHED':
        return moment(rechargeSession.finishedAt).fromNow();
      default:
        return moment(rechargeSession.updatedAt).fromNow();
    }
  };

  const getDeviceInfoLabel = (
    remote: boolean,
    deviceInfo?: Pick<DeviceInfo, 'appName' | 'appVersion'>,
    chargingCardId?: string
  ): { icon?: keyof ConfigType['NEW_ICONS']; name: string } => {
    if (!remote) {
      if (!chargingCardId) return { icon: 'NEW_EV_STATION', name: 'Uso Livre' };
      return { icon: 'NEW_RFID', name: `RFID - ${chargingCardId}` };
    }

    if (!deviceInfo) return { name: '-' };

    return {
      icon: 'NEW_QR_CODE',
      name: `App v${deviceInfo.appVersion}`,
    };
  };

  const formatPaymentMethod = (
    paymentMethod: NonNullable<
      NonNullable<GetRechargeModalDataQuery['session']>['order']
    >['paymentMethod']
  ) => {
    if (paymentMethod) {
      if (paymentMethod.__typename === 'PaymentCard') {
        const { brand, partiallyHiddenCardNumber } = paymentMethod;
        return (
          <PaymentMethod
            icon={getCardBrandIcon(paymentMethod.brand)}
            title={t('recharge-modal.payment-card.title')}
            info={`${getCardBrandName(brand)} •••• ${getLastFourCardNumbers(
              partiallyHiddenCardNumber
            )}`}
          />
        );
      }
      if (paymentMethod.__typename === 'Wallet') {
        return (
          <PaymentMethod
            icon="NEW_DIGITAL_WALLET"
            title="Carteira Digital"
            info="Debitado do saldo disponível da carteira digital do usuário"
          />
        );
      }
      return (
        <PaymentMethod
          icon="NEW_CARD_GREENEA"
          title={`Greenea - ${paymentMethod?.title}`}
          info={paymentMethod?.holderFullName || ''}
        />
      );
    }
    return undefined;
  };

  const getPaymentStatusTransitions = (
    rechargeSession: typeof session,
    defaultCurrencyType?: CurrencyType
  ): PaymentStatusProps['transitions'] => {
    const transitions: Array<PaymentStatusType> = [];

    const currencyType =
      rechargeSession.order?.currencyType ?? defaultCurrencyType;

    if (
      rechargeSession.order?.preAuthorizedAt &&
      rechargeSession.order?.status !== 'Cancelled'
    ) {
      transitions.push({
        date: t('recharge-modal.pre-authorized.date', {
          value: new Date(rechargeSession.order?.preAuthorizedAt),
        }),
        moneyValue: formatCurrency(rechargeSession.order?.preAuthorizeAmount, {
          currencyType,
        }),
        description: t('recharge-modal.pre-authorized.description'),
        status: t('recharge-modal.pre-authorized.status.title'),
        statusType: 'PreAuthorized',
      });
    }

    if (rechargeSession.order?.capturedAt) {
      transitions.push({
        date: t('recharge-modal.captured-at.date', {
          value: new Date(rechargeSession.order?.capturedAt),
        }),
        moneyValue: formatCurrency(rechargeSession.order?.capturedValue, {
          currencyType,
        }),
        description: t('recharge-modal.captured-payment.description'),
        status: t('recharge-modal.captured-payment.status.title'),
        statusType: 'Captured',
      });
    }

    if (rechargeSession.order?.status === 'Error') {
      transitions.push({
        date: '',
        moneyValue: formatCurrency(rechargeSession.order?.valueToBeCaptured, {
          currencyType,
        }),
        description: t('recharge-modal.error-in-capture.description'),
        status: t('recharge-modal.error-in-capture.status.title'),
        statusType: 'Error',
      });
    }

    if (rechargeSession.order?.status === 'ErrorOnPreAuthorization') {
      transitions.push({
        date: '',
        moneyValue: '',
        description: t('recharge-modal.error-on-pre-authorization.description'),
        status: t('recharge-modal.error-on-pre-authorization.status.title'),
        statusType: 'ErrorOnPreAuthorization',
      });
    }

    if (rechargeSession.order?.status === 'Cancelled') {
      const cancelledAt = rechargeSession.order?.cancelledAt
        ? new Date(rechargeSession.order?.cancelledAt)
        : new Date();

      let descriptionTranslationKey:
        | 'recharge-modal.cancelled-order.no-energy.description'
        | 'recharge-modal.cancelled-order.unlock-fail.description'
        | 'recharge-modal.cancelled-order.timeout.description';

      switch (rechargeSession.order?.cancellationReason) {
        case 'Session finished without consuming energy':
          descriptionTranslationKey =
            'recharge-modal.cancelled-order.no-energy.description';
          break;
        case 'Station Unlock Failed':
          descriptionTranslationKey =
            'recharge-modal.cancelled-order.unlock-fail.description';
          break;
        case 'Station Unlock Timed Out':
          descriptionTranslationKey =
            'recharge-modal.cancelled-order.timeout.description';
          break;
        default:
          throw new Error('Unknown cancellation reason');
      }

      transitions.push({
        date: t('recharge-modal.captured-at.date', {
          value: cancelledAt,
        }),
        moneyValue: formatCurrency(0, {
          currencyType,
        }),
        description: t(descriptionTranslationKey),
        status: t('recharge-modal.cancelled-order.status.title'),
        statusType: 'Cancelled',
      });
    }

    return transitions;
  };

  const formattedDuration = secondsToTime(session.duration || 0);
  const sessionWasMadeInRoaming = session?.cpoId !== session?.emspId;
  const memberEmspIsSessionOwner = selectedOrganizationIds?.includes(
    session.emspId
  );

  return (
    <>
      <Modal show={show} onClose={onClose} size="lg">
        <Modal.Header data-test="recharge-modal">
          <Header
            energyConsumed={formatEnergy(session.energyConsumed || 0)}
            label={
              session.status && t(config.LABELS.SESSION_STATUS[session.status])
            }
            sessionId={sessionId}
            duration={formattedDuration}
            status={session.status}
            totalCost={
              memberEmspIsSessionOwner
                ? formatCurrency(session.order?.totalCost || 0, {
                    showUnit: false,
                    digits: 2,
                  })
                : '-'
            }
            currencyType={currencyType}
            pastTime={getPastTimeByStatus(session)}
            icon={mapSessionStatusToIcon(session.status)}
          />
          <Modal.Header.Attachments padding="24">
            {hasScopes(['session:edit']) && session.status === 'FINISHED' && (
              <SessionOptionsDropdown
                onEditSessionClick={() => setIsEditing(true)}
                onStopSessionClick={() => setIsStopRecharge(true)}
                rechargeStatus={session.status}
              />
            )}
            {hasScopes(['session:force-stop']) &&
              (session.status === 'CHARGING' ||
                session.status === 'WAITING_UNPLUG') && (
                <SessionOptionsDropdown
                  onEditSessionClick={() => setIsEditing(true)}
                  onStopSessionClick={() => setIsStopRecharge(true)}
                  rechargeStatus={session.status}
                />
              )}
            <Modal.Header.Close onClose={onClose} />
          </Modal.Header.Attachments>
        </Modal.Header>
        <Modal.Body>
          <Body>
            <ContentGroup>
              <CardsGroup>
                <StartedFinished
                  startInfo={{
                    date: t('recharge-modal.started-finished.start-info.date', {
                      value: session.startedChargingAt
                        ? new Date(session.startedChargingAt)
                        : '-',
                    }),
                    hour: t('recharge-modal.started-finished.start-info.hour', {
                      value: session.startedChargingAt
                        ? new Date(session.startedChargingAt)
                        : '-',
                    }),
                  }}
                  finishInfo={
                    session.stoppedChargingAt
                      ? {
                          date: t(
                            'recharge-modal.started-finished.finish-info.date',
                            {
                              value: new Date(session.stoppedChargingAt),
                            }
                          ),
                          hour: t(
                            'recharge-modal.started-finished.finish-info.hour',
                            {
                              value: new Date(session.stoppedChargingAt),
                            }
                          ),
                        }
                      : {
                          date: t('recharge-modal.recharge-in-progress.title'),
                          hour: t('recharge-modal.recharge-in-progress.title'),
                        }
                  }
                />
                <StationAndConnector
                  station={{
                    id: session.stationProfile?.id,
                    name: session.stationProfile?.name ?? session.stationId,
                    cpoId: session.cpoId,
                    status: session.stationProfile?.status,
                    ...session.stationProfile?.address,
                    postalCode: session.stationProfile?.address?.postalCode
                      ? formatPostalCode(
                          session.stationProfile?.address.postalCode
                        )
                      : '-',
                  }}
                  connector={
                    session.connector
                      ? {
                          type: {
                            icon: session.connector?.type,
                            name: config.LABELS.CONNECTOR_TYPES[
                              session.connector?.type
                            ],
                          },
                          currentType: session.connector?.currentType,
                          power: formatPower(session.connector?.power || 0),
                        }
                      : undefined
                  }
                  cpoId={cpoId}
                />
                {session.emsp && (
                  <>
                    <ConsumerCard
                      consumerId={session.consumerId}
                      consumer={session.consumer}
                      consumerLabel={session.consumerLabel ?? '-'}
                      unlockMethod={getDeviceInfoLabel(
                        session.remote,
                        session.deviceInfo,
                        session.rfid?.decimalId ?? session.chargingCardId
                      )}
                      emspId={emspId}
                    />
                  </>
                )}
              </CardsGroup>
            </ContentGroup>
            {memberEmspIsSessionOwner && session.emsp && (
              <>
                {!session.order ? (
                  <Typography>
                    {t('recharge-modal-payment-order-not-found')}
                  </Typography>
                ) : session.order.status !== 'NoCost' ? (
                  <ContentGroup>
                    <PaymentInfo>
                      <H3>{t('recharge-modal-payment-card-info.title')}</H3>
                      <PaymentInfoId>
                        {t('recharge-modal-payment-card-info.subtitle', {
                          orderId: session.order.orderId || '-',
                        })}
                      </PaymentInfoId>
                    </PaymentInfo>
                    <CardsGroup>
                      <PaymentStatus
                        transitions={getPaymentStatusTransitions(
                          session,
                          currencyType
                        )}
                      />
                      <SessionCost
                        energyConsumed={formatEnergy(session.energyConsumed)}
                        pricingConfig={session.pricingConfig}
                        currencyType={currencyType}
                        subtotal={formatCurrency(session.order?.subtotal, {
                          currencyType,
                        })}
                        chargeFee={formatCurrency(
                          session.pricingConfig?.chargeFee,
                          {
                            currencyType,
                          }
                        )}
                        totalCost={formatCurrency(session.order?.totalCost, {
                          currencyType,
                        })}
                        duration={secondsToTime(session.duration)}
                      />
                      {formatPaymentMethod(session.order.paymentMethod)}
                    </CardsGroup>
                  </ContentGroup>
                ) : null}
              </>
            )}
            {sessionWasMadeInRoaming && session.emsp && (
              <ContentGroup>
                <Heading fontSize="lg">
                  {t('recharge-modal-roaming-card-info.title')}
                </Heading>
                <CardsGroup>
                  <RoamingInfo
                    emsp={session?.emsp?.roamingInfo}
                    cpo={session?.cpo.roamingInfo}
                  />
                </CardsGroup>
              </ContentGroup>
            )}
            <ContentGroup>
              <H3>{t('recharge-modal-consumption-graph.title')}</H3>{' '}
              {/* TODO TODO VALIDATE WHETHER EVERTHING IS ALL RIGHT */}
              <MeasurementsGraph
                energyMeasurements={session.energyMeasurements}
              ></MeasurementsGraph>
            </ContentGroup>
          </Body>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
      <EditSessionModal
        show={isEditing}
        onClose={() => setIsEditing(false)}
        sessionId={sessionId}
        energyConsumed={session.energyConsumed}
        duration={session.duration}
      />
      <StopRechargeModal
        show={isStopRecharge}
        onClose={handleStopRecharge}
        setIsForceStopRecharge={setIsForceStopRecharge}
        setIsStopRecharge={setIsStopRecharge}
        connectorId={session.connector?.id}
        stationId={session.stationProfile?.id}
      />
      <ForceStopRechargeModal
        show={isForceStopRecharge}
        onClose={() => setIsForceStopRecharge(false)}
        sessionId={sessionId}
        energyConsumed={session.energyConsumed}
        duration={session.duration}
        connectorId={session.connector?.id}
        stationId={session.stationProfile?.id}
        currencyType={currencyType}
      />
    </>
  );
}

export default React.memo(RechargeModal, (a, b) => a.show === b.show);
