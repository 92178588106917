import Config from 'config';
import StationLink from 'components/StationLink';
import { Column } from 'components/Table';
import { DriverModalOrdersTabQuery, OrderStatus } from 'generated/graphql';
import PaymentCard from 'new-components/DriverModal/Orders/PaymentCard';
import { H5 } from 'new-components/Typographies/styles';
import { CenterTableElementContainer } from 'cards/SessionTable/SessionTable/styles';
import { NewTag } from 'new-components/NewTag';
import PlacePlan from 'new-components/DriverModal/Orders/PlacePlan';
import { t } from 'i18next';
import { TranslationKeyType } from 'types/translation';
import { Wallet } from 'new-components/DriverModal/Orders/Wallet';
import { Box } from '@chakra-ui/react';
import { formatCurrency } from './format';

export type FormattedOrder = {
  createdAt: string;
  orderId: string;
  paymentMethod: NonNullable<
    DriverModalOrdersTabQuery['driver']
  >['orders'][number]['paymentMethod'];
  sessionId: string;
  stationId: string;
  stationName: string;
  status: OrderStatus;
  orderCapturedOrPreAuthorizedValue: string;
  driverId?: number;
  cpoId?: string;
};

export type Order = NonNullable<
  DriverModalOrdersTabQuery['driver']
>['orders'][number];

export const formatOrderFields = ({
  createdAt,
  orderId,
  paymentMethod,
  session,
  status,
  capturedValue,
  preAuthorizeAmount,
  valueToBeCaptured,
  driverId,
  currencyType,
}: Partial<Order>): Partial<FormattedOrder> => ({
  orderId,
  createdAt: createdAt
    ? t(
        'payments.order-card-tab.table.format-session-row.format-order-fields.created-at.value',
        {
          value: new Date(createdAt),
        }
      )
    : '-',
  paymentMethod,
  sessionId: session?.id,
  stationId: session?.stationProfile?.id,
  stationName: session?.stationProfile?.name,
  cpoId: session?.stationProfile?.cpoId,
  status,
  orderCapturedOrPreAuthorizedValue: formatCurrency(
    status === 'Captured'
      ? capturedValue
      : status === 'PreAuthorized'
      ? preAuthorizeAmount
      : valueToBeCaptured,
    { currencyType }
  ),
  driverId,
});

export const orderFieldLabelTable: Record<
  keyof Omit<FormattedOrder, 'cpoId'>,
  TranslationKeyType
> = {
  orderId: 'payments.order-card-tab.table.orderId.title',
  stationId: 'payments.order-card-tab.table.stationId.title',
  stationName: 'payments.order-card-tab.table.stationName.title',
  createdAt: 'payments.order-card-tab.table.created-at.title',
  driverId: 'payments.order-card-tab.table.driver-id.title',
  status: 'payments.order-card-tab.table.status.title',
  orderCapturedOrPreAuthorizedValue:
    'payments.order-card-tab.table.order-captured-or-pre-authorized-value.title',
  paymentMethod: 'payments.order-card-tab.table.payment-method.title',
  sessionId: 'payments.order-card-tab.table.session-id.title',
};

const getPaymentCardBrand = (paymentCardBrand?: string) => {
  // TODO: Workaround because we still not have these card icons
  if (paymentCardBrand === 'Amex' || paymentCardBrand === 'Dinners')
    return 'NEW_CREDIT_CARD';

  return paymentCardBrand
    ? `NEW_${paymentCardBrand.toUpperCase()}`
    : 'NEW_CREDIT_CARD';
};

export const getColumnOptions = <T extends Partial<FormattedOrder>>(
  key: string,
  label: string
): Column<T> => {
  // @ts-ignore
  const options: Column<T> = {};

  switch (key) {
    case 'orderId':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      break;
    case 'orderCapturedOrPreAuthorizedValue':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original } }) => (
        <H5>{original.orderCapturedOrPreAuthorizedValue}</H5>
      );
      break;
    case 'orderTotalCost':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original } }) => (
        <H5>{original.orderTotalCost}</H5>
      );
      break;

    case 'stationName':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original } }) => {
        if (!original.orderId) return '-';
        return (
          <StationLink
            stationId={original.stationId!}
            stationName={original.stationName!}
            cpoId={original.cpoId!}
          />
        );
      };
      break;

    case 'paymentMethod':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original: _original = {} } }) => {
        const { paymentMethod } = _original as FormattedOrder;
        if (!paymentMethod) {
          return <></>;
        }

        switch (paymentMethod.__typename) {
          case 'PaymentCard': {
            return (
              <Box minWidth={'10rem'}>
                <PaymentCard
                  // @ts-ignore
                  brand={getPaymentCardBrand(paymentMethod.brand)}
                  lastFourCardNumbers={
                    paymentMethod.partiallyHiddenCardNumber?.slice(-4) || ''
                  }
                />
              </Box>
            );
          }
          case 'PlacePlan': {
            return (
              <Box minWidth={'10rem'}>
                <PlacePlan
                  title={paymentMethod.title}
                  holderFullName={paymentMethod.holderFullName}
                />
              </Box>
            );
          }
          case 'Wallet': {
            return (
              <Box minWidth={'10rem'}>
                <Wallet />
              </Box>
            );
          }
        }
      };

      break;
    case 'status' /* @ts-ignore */:
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original } }) => (
        <CenterTableElementContainer>
          <NewTag type="text" status={original.status as OrderStatus}>
            {t(Config.LABELS.ORDER_STATUS[original.status as OrderStatus])}
          </NewTag>
        </CenterTableElementContainer>
      );
      break;

    case 'driverId' /* @ts-ignore */:
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original } }) =>
        `${t('payments.order-card-tab.user.title')} ${original.driverId}`;
      break;
  }

  // @ts-ignore
  return {
    Header: label,
    accessor: key,
    ...options,
  };
};
