import { VoucherFilterInput, VoucherStatus } from 'generated/graphql';
import { useContext, useEffect, useState } from 'react';
import { FilterTerm } from 'types';
import { ListVoucherEventsContext } from '../../../context/events-context';

export type VoucherFilterKeys = 'voucherNames' | 'createdByMembers' | 'status';

export type VoucherFilterTerm = FilterTerm<VoucherFilterKeys>;

export function parseVoucherFilterData(
  terms: VoucherFilterTerm[]
): Partial<VoucherFilterInput> {
  const filterData: Partial<VoucherFilterInput> = {};
  const filterDataMap: {
    [key in VoucherFilterKeys]: keyof VoucherFilterInput;
  } = {
    voucherNames: 'name',
    createdByMembers: 'createdByMember',
    status: 'status',
  };

  terms.forEach((term) => {
    const parsedType = filterDataMap[term.type];
    if (term.type === 'status') {
      filterData.status = [
        ...(filterData?.status ?? []),
        term.value.toUpperCase() as VoucherStatus,
      ];
      return;
    }
    filterData[parsedType] = [
      ...((filterData[parsedType] ?? []) as any[]),
      term.value,
    ];
  });

  return filterData;
}

export type VoucherTableFiltersType = {
  appliedFilterTerms: VoucherFilterTerm[];
  requestedVoucherName: string;
};

export function useVoucherTableFilters() {
  const events = useContext(ListVoucherEventsContext).getEvents();
  const [appliedFilterTerms, setAppliedFilterTerms] = useState<
    VoucherFilterTerm[]
  >([]);
  const [requestedVoucherName, setRequestedVoucherName] = useState<string>('');

  const [voucherTableFilterVisible, setVoucherTableVisible] = useState(false);
  const toggleVoucherFilterVisibility = () =>
    setVoucherTableVisible((prev) => !prev);

  useEffect(() => {
    events.updateParsedVoucherTableFilters(
      parseVoucherFilterData(appliedFilterTerms)
    );
    // eslint-disable-next-line
  }, [appliedFilterTerms]);

  useEffect(() => {
    events.updateRequestedVoucherName(requestedVoucherName);
    // eslint-disable-next-line
  }, [requestedVoucherName]);

  return {
    appliedFilterTerms,
    setAppliedFilterTerms,
    requestedVoucherName,
    setRequestedVoucherName,
    voucherTableFilterVisible,
    toggleVoucherFilterVisibility,
  };
}
