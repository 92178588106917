import AuthContext from 'contexts/Auth/context';
import {
  ExportAllSessionsQuery,
  ExportAllSessionsQueryVariables,
  ExportStationSessionsQuery,
  ExportStationSessionsQueryVariables,
} from 'generated/graphql';
import exportAllSessionsQuery from 'graphql/queries/exportAllSessions';
import exportStationsSessionsQuery from 'graphql/queries/exportStationSessions';
import { useContext } from 'react';
import { TimeRange } from 'services/timeRange';
import { saveAs } from 'file-saver';
import { useClient } from 'urql';

type ExportSessionArgs = {
  filterSessionsByStationId?: string;
  authToken?: string;
  timeRange?: TimeRange;
};

export const useExportSessions = ({
  filterSessionsByStationId,
  timeRange,
}: ExportSessionArgs) => {
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  const client = useClient();
  return async () => {
    try {
      const { data, error } = await client
        .query<
          ExportAllSessionsQuery | ExportStationSessionsQuery,
          ExportAllSessionsQueryVariables | ExportStationSessionsQueryVariables
        >(
          filterSessionsByStationId
            ? exportStationsSessionsQuery
            : exportAllSessionsQuery,
          {
            timeRange: timeRange?.toTimeRangeInput(new Date()),
            filter: {
              includesStatus: ['CHARGING', 'WAITING_UNPLUG', 'FINISHED'],
            },
            where: {
              stationId: filterSessionsByStationId,
            },
          },
          {
            fetchOptions: {
              headers: {
                authorization: `Bearer ${memberAuthToken}`,
                'org-ids': selectedOrganizationIds as any,
              },
            },
            requestPolicy: 'network-only',
            'Access-Control-Allow-Origin': '*',
          }
        )
        .toPromise();
      const isExportAllSessionsQuery = (
        data: ExportAllSessionsQuery | ExportStationSessionsQuery | undefined
      ): data is ExportAllSessionsQuery | undefined =>
        !filterSessionsByStationId;

      const exportedSessions = isExportAllSessionsQuery(data)
        ? data?.exportSessions.url
        : data?.station?.exportSessions.url;

      if (error) throw new Error(error.message);

      if (isExportAllSessionsQuery(data)) {
        saveAs(exportedSessions!, `dados-sessoes-voltbras.xlsx`);
      } else {
        saveAs(
          exportedSessions!,
          `dados-sessoes-${
            filterSessionsByStationId
              ? `estacao-${filterSessionsByStationId}`
              : ''
          }voltbras.xlsx`
        );
      }
    } catch (error) {
      alert('Erro ao baixar dados');
    }
  };
};
