import { createContext, ReactNode, useState } from 'react';

type PaymentsContextArgs = {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
};

type PaymentsProviderProps = {
  children: ReactNode;
};

export const PaymentsContext = createContext<PaymentsContextArgs>({
  currentIndex: 0,
  setCurrentIndex: () => {},
});

export const PaymentsProvider = ({ children }: PaymentsProviderProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <PaymentsContext.Provider
      value={{
        currentIndex,
        setCurrentIndex: (index: number) => setCurrentIndex(index),
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};
