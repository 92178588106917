import { Heading as ChakraHeading } from '@chakra-ui/react';
import config from 'config';
import React from 'react';

type ContainerHeadingProps = {
  fontSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  color?: 'gray.900';
  fontFamily?: typeof config['FONTS'];
  fontWeight?: 'bold';
  lineHeight?: '5';
};

const Heading: React.FC<ContainerHeadingProps> = ({
  children,
  fontSize,
  color,
  lineHeight,
  fontWeight,
  fontFamily,
}) => (
  <ChakraHeading
    lineHeight={lineHeight}
    fontWeight={fontWeight}
    fontFamily={fontFamily}
    color={color}
    fontSize={fontSize}
  >
    {children}
  </ChakraHeading>
);
export default Heading;
