import { gql } from '@apollo/client';

export default gql`
  mutation ChangeStationOpeningHours(
    $where: SelectStationInput!
    $data: OpeningHoursInput!
  ) {
    changeStationOpeningHours(where: $where, data: $data)
  }
`;
