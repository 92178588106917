import Typography from 'components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme: { COLORS } }) => COLORS.WHITE};
  border-radius: 8px;
  border: 1px solid ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
  box-shadow: 0px 10px 20px #00000029;
  padding: 12px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

export const ConnectorData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: min-content;
`;

export const ConnectorTitle = styled(Typography).attrs({
  size: 14,
  weight: 'bold',
})`
  margin-bottom: 4px;
`;

export const InfoText = styled(Typography).attrs({
  color: 'SECONDARY_GRAY',
  size: 14,
})`
  width: max-content;
`;

export const ErrorSolutionContainer = styled.div`
  display: flex;
  margin-top: 18px;
`;
