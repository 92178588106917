import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { BodyTypography, H3 } from 'new-components/Typographies/styles';
import React, { useContext, useState } from 'react';
import { useAuthMutation } from 'hooks';
import toast from 'services/toast';
import { FormControl, Input, Flex, Button } from '@chakra-ui/react';
import AuthContext from 'contexts/Auth/context';
import {
  InviteMemberMutation,
  InviteMemberMutationVariables,
  MemberRole,
} from 'generated/graphql';
import inviteMemberMutation from 'graphql/mutations/inviteMember';
import Config from 'config';
import Dropdown from 'components/Dropdown';
import { Trans, useTranslation } from 'react-i18next';
import { SelectOrganizationDropdown } from 'atomic-components/molecules/SelectOrganizationDropdown';
import { useHistory } from 'react-router-dom';
import { Header, Body, Label, ButtonWrapper } from './styles';

type Props = {
  close: () => void;
  onClose: () => void;
  show: boolean;
  onInvitedMember?: () => void;
};

const SELECTABLE_ROLES: Array<MemberRole> = [
  'ADMIN',
  'SUPPORT',
  'TECHNICIAN',
  'VIEWER',
];

const defaultRole: MemberRole = 'VIEWER';

const InviteMemberModal: React.FC<Props> = ({
  close,
  onClose,
  show,
  onInvitedMember,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { organizations } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [currentRole, setCurrentRole] = useState<MemberRole>(defaultRole);
  const [selectedOrganization, setSelectedOrganization] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(undefined);

  const [inviteMember, { loading }] = useAuthMutation<
    InviteMemberMutation,
    InviteMemberMutationVariables
  >(inviteMemberMutation, {
    context: { headers: { 'org-id': selectedOrganization?.id ?? '' } },
  });

  const renderResendInviteMemberFeedbackToast = () =>
    toast.error(
      <Trans
        i18nKey="invite-member-modal.toast-resend-invitation-feedback-message"
        components={{
          Button: (
            <Button
              color="primary.600"
              onClick={() => {
                onClose();
                history.push('/membros');
              }}
            />
          ),
        }}
      />
    );

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };
  const handleInviteMemberClick = async () => {
    if (!selectedOrganization?.id) return;
    try {
      await inviteMember({
        variables: {
          data: { email, role: currentRole },
          where: {
            id: selectedOrganization.id,
          },
        },
      });
      toast.success(t('invite-member-modal.toast-successfully-message'));
      close();
      setCurrentRole(defaultRole);
      setEmail('');
      onInvitedMember?.();
    } catch (e) {
      console.error(e);
      if (e instanceof Error && e.message.includes('Member already exists'))
        renderResendInviteMemberFeedbackToast();
      else toast.error(t('invite-member-modal.toast-error-message'));
    }
  };

  const handleRoleChange = (role: MemberRole) => setCurrentRole(role);

  return (
    <Modal size="md" show={show} onClose={onClose} centered overflow="visible">
      <Modal.Header>
        <Header>
          <Icon color="PRIMARY" type="NEW_GROUP" size={24} />
          <H3>{t('invite-member-modal.header.title')}</H3>
        </Header>
        <Modal.Header.Attachments padding="24">
          <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Flex flexFlow="column nowrap">
            <Label htmlFor="email">
              <BodyTypography>
                {t('invite-member-modal.body.description')}
              </BodyTypography>
            </Label>
            <Flex gap={2} mt={2} mb={8}>
              <FormControl>
                <Input
                  onChange={(e) => handleEmailChange(e.target.value)}
                  placeholder={t('invite-member-modal.form.input.placeholder')}
                  value={email}
                  id="email"
                />
              </FormControl>
              <Dropdown
                options={SELECTABLE_ROLES}
                value={currentRole}
                format={(role) => t(Config.LABELS.ROLES[role])}
                onChange={handleRoleChange}
                hasPadding={true}
              />
              <SelectOrganizationDropdown
                value={selectedOrganization?.name ?? 'Organização'}
                setSelectedOrganization={setSelectedOrganization}
                organizations={organizations ?? []}
              />
            </Flex>
            <ButtonWrapper>
              <Button
                onClick={handleInviteMemberClick}
                isLoading={loading}
                isDisabled={email.trim() === '' || !selectedOrganization}
              >
                {t('invite-member-modal.form.btn')}
              </Button>
            </ButtonWrapper>
          </Flex>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

export default InviteMemberModal;
