import styled from 'styled-components';
import { Comment, H3 } from 'new-components/Typographies/styles';
import { Typography } from 'components';

export const Container = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 26px 24px 20px 24px;
`;

export const SessionInfo = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
`;

export const SessionStatus = styled('section')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const PastTime = styled(Comment)`
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;

export const SessionIdContainer = styled('section')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const SessionIdTitle = styled(Typography)`
  font-size: 18px;
  color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
`;
export const Separator = styled(Typography)`
  font-size: 18px;
  color: ${({ theme: { COLORS } }) => COLORS.SECONDARY_GRAY};
`;
export const SessionId = styled(H3)`
  color: ${({ theme: { COLORS } }) => COLORS.PRIMARY_GRAY};
`;

export const SessionMetrics = styled('section')`
  display: flex;
  gap: 16px;
`;
export const SessionMetricsItem = styled('section')`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const TimeConsumed = styled(SessionMetricsItem)``;
export const EnergyConsumed = styled(SessionMetricsItem)``;
export const TotalCost = styled(SessionMetricsItem)``;
