import styled from 'styled-components';
import Config from 'config';

export const Chevron = styled(Config.ICONS.CHEVRON)<{ upside: boolean }>`
  transform: rotate(${({ upside }) => (upside ? '180deg' : '0deg')});
`;

export const SessionGraphContainer = styled.div`
  height: 250px;
  width: 100%;
  position: relative;
`;

export const CenterTableElementContainer = styled.div`
  margin: auto;
  width: max-content;
`;
