import React from 'react';
import { Container } from './styles';

export interface NewCardProps {
  height?: 'auto' | '100%';
  className?: string;
}

const NewCard: React.FC<NewCardProps> = ({
  children,
  height = 'auto',
  className,
  ...props
}) => (
  <Container className={className} height={height} {...props}>
    {children}
  </Container>
);

export default NewCard;
