import { gql } from '@apollo/client';

export default gql`
  query ListStationsToGroup($limit: Int, $search: String) {
    stationsProfile(
      search: $search
      limit: $limit
      filter: { includesVisibility: [PUBLIC, PRIVATE] }
    ) {
      id
      name
      __typename
    }
  }
`;
