import { HttpPostRequestData } from 'hooks/useAuthPostRequest';

export type ChangeStationImagesPostRequestBody = {
  images: File[];
};

export type ChangeStationImagesPostRequestArgs = {
  stationId?: string;
  body: ChangeStationImagesPostRequestBody;
};

export function setupChangeStationImagesPostRequest({
  stationId,
  body,
}: ChangeStationImagesPostRequestArgs): HttpPostRequestData {
  const formData = new FormData();
  body.images.forEach((image) => formData.append('images', image));
  return {
    path: `/stations/${stationId}/images`,
    body: formData,
    headers: {},
  };
}
