import { gql } from '@apollo/client';

export default gql`
  query StationChargeFinishedMessage($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      chargeFinishedMessage {
        title
        description
        active
      }
    }
  }
`;
