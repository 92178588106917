import styled from 'styled-components';
import { padding } from 'theme/selectors';
import { Container as NavBarContainer } from 'atomic-components/molecules/NavBar/styles';

export const Container = styled('header')`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: ${padding('PADDING-24')} ${padding('PADDING-24')} 0
    ${padding('PADDING-24')};
`;

export const StyledNavBar = styled(NavBarContainer)`
  padding: 0;
`;
