import DialogModal from 'atomic-components/organisms/DialogModal';
import { useTranslation } from 'react-i18next';
import { useCreateVoucherErrorModal } from './services';
import { useCreateVoucherScreen } from '../../services';

export const VoucherCreationErrorModal = () => {
  const { t } = useTranslation();

  const { isErrorModalOpen, closeErrorModal } = useCreateVoucherErrorModal();
  const { createVoucherLoading, onCreateVoucherButtonClick } =
    useCreateVoucherScreen();

  return (
    <DialogModal
      visible={isErrorModalOpen}
      onClose={closeErrorModal}
      header={{
        title: t(
          'payments.voucher-tab.create-voucher.error-dialog-modal.header.title'
        ),
      }}
      subtitle={t(
        'payments.voucher-tab.create-voucher.error-dialog-modal.subtitle'
      )}
      submitButton={{
        colorScheme: 'primary',
        onPress: onCreateVoucherButtonClick,
        text: t(
          'payments.voucher-tab.create-voucher.error-dialog-modal.retry-btn-title'
        ),
        loading: createVoucherLoading,
      }}
      cancelButton={{
        text: t(
          'payments.voucher-tab.create-voucher.error-dialog-modal.close-btn-title'
        ),
        onClick: () => closeErrorModal(),
      }}
    />
  );
};
