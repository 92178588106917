import Page from 'components/Page';
import React from 'react';
import OwnerEditStation from './OwnerEditStation';
import OwnerOperations from './OwnerOperations';

const OwnerInternalUse: React.FC = () => (
  <Page>
    <OwnerEditStation />
    <OwnerOperations />
  </Page>
);

export default OwnerInternalUse;
