import { gql } from 'urql';

export default gql`
  mutation GenerateMemberAuthToken($data: MemberAuthInput!) {
    generateMemberAuthToken(data: $data) {
      ... on MemberAuthenticationError {
        message
        type
        serializedErrorObject
        __typename
      }
      ... on MemberSuccessfulAuthentication {
        authToken
        externalUid
        __typename
      }
    }
  }
`;
