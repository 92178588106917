import SharedConfig from 'config/shared';
import ConfigType from 'config/typing';
import * as SHARED_NEW_ICONS from '../shared/new-icons';
import * as ICONS from './icons';
import * as NEW_ICONS from './new-icons';

const STATION_FALLBACK_IMAGE =
  require('./images/station_fallback_image.png').default;

const IonConfig: ConfigType = {
  ...SharedConfig,
  MODULE_OPERATION_PLATFORM: true,
  MODULE_RESERVATION: false,
  MODULE_PAYMENT: true,
  GA_TRACKING_ID: 'UA-119882526-11',
  CODE: 'ion-mobilidade',
  LABEL: 'Ion',
  COLORS: {
    ...SharedConfig.COLORS,
    PRIMARY: '#D8D100',
    CLICKABLE: '#DFB600',
    ERROR: '#F87060',
    SIDEBAR: '#D8D100',
    LOGIN_LOGO_BACKGROUND: '#D8D100',

    RISE: '#04E762',
    FALL: '#F87060',
    NEUTRAL: '#006C97',

    CRITICAL: '#F87060',
    WARNING: '#E6B606',

    PreAuthorized: '#F4F4F4',
    Captured: '#04E762',
    NoCost: '#F4F4F4',
    Error: '#F87060',

    AVAILABLE: '#04E762',
    CHARGING: '#D8D100',
    INOPERATIVE: '#F87060',
    UNKNOWN: '#6E6E6E',
    PLANNED: '#F87060',
    RESERVED: '#662C91F',

    PREPARING: '#D8D100',
    FINISHING: '#D8D100',

    SELECTED_DATE_BOUND_BACKGROUND: '#DFB600',
    SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
    SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#0087B1',

    DATE_PRE_SELECTED_BACKGROUND: '#D8D100',
    DATE_PRE_SELECTED_BORDER: '#64DAFF',
    DATE_PRE_SELECTED_HOVER_BACKGROUND: '#7EE0FF',
    DATE_PRE_SELECTED_COLOR: '#0087B1',

    DATE_SELECTED_BACKGROUND: '#D8D100',
    DATE_SELECTED_BORDER: '#DBDBDB',
    DATE_SELECTED_HOVER_BACKGROUND: '#DFB600',

    CURRENT_DAY: '#D8D100',

    ORDER_STATUS: {
      Cancelled: '#FF7C01',
      PreAuthorized: '#F4F4F4',
      Captured: '#D8D100',
      Subsidized: '#D8D100',
      NoCost: '#F4F4F4',
      Error: '#F87060',
      ErrorOnPreAuthorization: '#F87060',
    },
    STATION_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#D8D100',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      PLANNED: '#F87060',
      RESERVED: '#662C91F',
    },
    CONNECTOR_STATUS: {
      AVAILABLE: '#04E762',
      CHARGING: '#D8D100',
      INOPERATIVE: '#F87060',
      UNKNOWN: '#6E6E6E',
      RESERVED: '#662C91F',
      PREPARING: '#D8D100',
      FINISHING: '#D8D100',
    },

    BILLING: '#04E762',
    RECHARGES: '#D8D100',
    AVAILABILITY: '#662C91',

    BUTTON_AVAILABLE: '#04E762',
    BUTTON_UNAVAILABLE: '#7EE0FF',
    BUTTON_LOADING: '#64DAFF',
    BUTTON_TEXT_COLOR: '#FFFFFF',
    LINK: '#DFB600',

    CALENDAR: {
      SELECTED_DATE_BOUND_BACKGROUND: '#DFB600',
      SELECTED_DATE_BOUND_BORDER: '#DBDBDB',
      SELECTED_DATE_BOUND_HOVER_BACKGROUND: '#0087B1',

      DATE_PRE_SELECTED_BACKGROUND: '#D8D100',
      DATE_PRE_SELECTED_BORDER: '#64DAFF',
      DATE_PRE_SELECTED_HOVER_BACKGROUND: '#7EE0FF',
      DATE_PRE_SELECTED_COLOR: '#0087B1',

      DATE_SELECTED_BACKGROUND: '#D8D100',
      DATE_SELECTED_BORDER: '#DBDBDB',
      DATE_SELECTED_HOVER_BACKGROUND: '#DFB600',

      CURRENT_DAY: '#D8D100',
    },
  },
  FONTS: {
    PRIMARY: 'Roboto-Regular',
    SECONDARY: 'Roboto-Bold',
    TERTIARY: 'Roboto-Mono',
  },
  API: {
    ...SharedConfig.API,
  },
  MAP: {
    INITIAL_REGION: {
      lat: -3.742204,
      lng: -38.499973,
    },
  },
  NEW_ICONS: {
    ...SHARED_NEW_ICONS,
    ...NEW_ICONS,
  },
  ICONS: {
    ...SharedConfig.ICONS,
    ...ICONS,
    SIDEBAR: {
      ...SharedConfig.ICONS.SIDEBAR,
      ...ICONS.SIDEBAR,
    },
  },
  IMAGES: {
    STATION_FALLBACK: STATION_FALLBACK_IMAGE,
  },
  TERMS_OF_SERVICE: 'Nao temos',
  META_DATA: {
    DESCRIPTION:
      'Um pacote de ferramentas para gestores e operadores possuírem total controle e acompanhamento em tempo real da rede de eletropostos.',
    TITLE: 'Ion - Plataforma de Gestão de Eletropostos',
    PUBLIC_PATH: '/ion',
  },
  COLOR_SCHEME: {
    ...SharedConfig.COLOR_SCHEME,
    PRIMARY: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#D8D100',
      '600': '#DFB600',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    LINK: {
      '50': '#7EDAFF',
      '100': '#4BCCFF',
      '200': '#31C5FF',
      '300': '#18BEFF',
      '400': '#00B6FD',
      '500': '#DFB600',
      '600': '#006C97',
      '700': '#005A7E',
      '800': '#004864',
      '900': '#002331',
    },
    ERROR: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#B44900',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
    SUCCESS: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    CHARGING: {
      '50': '#B1ECFF',
      '100': '#7EE0FF',
      '200': '#64DAFF',
      '300': '#4BD4FF',
      '400': '#31CEFF',
      '500': '#D8D100',
      '600': '#009ACA',
      '700': '#0087B1',
      '800': '#007397',
      '900': '#004C64',
    },
    AVAILABLE: {
      '50': '#A0FDC7',
      '100': '#6EFCA9',
      '200': '#55FC9A',
      '300': '#3CFC8B',
      '400': '#23FB7C',
      '500': '#04E762',
      '600': '#03b54d',
      '700': '#039c42',
      '800': '#028337',
      '900': '#015122',
    },
    SECONDARY: {
      '50': '#B1E4FF',
      '100': '#7ED3FF',
      '200': '#64CAFF',
      '300': '#4BC2FF',
      '400': '#31B9FF',
      '500': '#D8D100',
      '600': '#0085CA',
      '700': '#0075B1',
      '800': '#006497',
      '900': '#004264',
    },
    RESERVE: {
      '50': '#BD92DE',
      '100': '#A66BD2',
      '200': '#9A57CC',
      '300': '#8E43C6',
      '400': '#8238B8',
      '500': '#662C91',
      '600': '#4a206a',
      '700': '#3d1a56',
      '800': '#2f1443',
      '900': '#13081c',
    },
    INOPERATIVE: {
      '50': '#FDF0EE',
      '100': '#FDDEDA',
      '200': '#FCC8C2',
      '300': '#FBB2A9',
      '400': '#FA9C91',
      '500': '#F87060',
      '600': '#F6442F',
      '700': '#F52E17',
      '800': '#E8220A',
      '900': '#B71A08',
    },
  },
};
export default IonConfig;
