import styled from 'styled-components';

const CardFooter = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  bottom: 7px;
  position: absolute;
  justify-content: flex-start;
`;

export default CardFooter;
