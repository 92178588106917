import Map from 'components/Map';
import { StationsMonitoringQuery, StationStatus } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import { Marker } from 'new-components/Marker';
import React, { useCallback, useEffect, useState } from 'react';
import StationPreview from '../StationPreview';
import mapStyle from './map.styles';
import { Container } from './styles';

type Station = StationsMonitoringQuery['stations'][number];
type MapStation = Required<Station>;

const priorityTable: Record<StationStatus, number> = {
  CHARGING: 1,
  AVAILABLE: 2,
  INOPERATIVE: 3,
  UNKNOWN: 4,
  RESERVED: 5,
  PLANNED: 6,
};

type MonitoringMapProps = {
  stations: Station[];
  changeSelectedStation: [
    Station['id'] | undefined,
    (stationId: string | undefined) => void
  ];
  scrollIntoView: (selector: Station['chargePointId']) => void;
  onOpenStationPage: () => void;
  onAlarmPress: () => void;
};

const stationSortRule = (a: Station, b: Station) => {
  if (
    a.alarmsSummary &&
    b.alarmsSummary &&
    (a.alarmsSummary.count || b.alarmsSummary.count)
  )
    return a.alarmsSummary.count - b.alarmsSummary.count;
  return priorityTable[b.status] - priorityTable[a.status];
};

const MonitoringMap: React.FC<MonitoringMapProps> = ({
  stations,
  changeSelectedStation,
  scrollIntoView,
  onOpenStationPage,
  onAlarmPress,
}) => {
  const hasScopes = useHasScopes();
  const stationHasCoordinates = (station: Station): station is MapStation =>
    !!station.coordinates;

  const [selectedStationId, selectStationId] = changeSelectedStation;

  const [selectedStation, setSelectedStation] = useState(
    stations.find((station) => station.id === selectedStationId)
  );

  const sortStations = useCallback(
    (stationsToSort: Station[]) =>
      stationsToSort
        .slice()
        .filter(stationHasCoordinates)
        .sort(stationSortRule),
    []
  );

  const [sortedStations, setSortedStations] = useState(sortStations(stations));

  const [scrollWheelEnabled, setScrollWheelEnabled] = useState<boolean>(true);

  const generateMarker = ({
    coordinates,
    id,
    status,
    alarmsSummary,
    chargePointId,
  }: MapStation) => (
    <Marker
      lat={coordinates.latitude}
      lng={coordinates.longitude}
      stationAlarms={hasScopes(['alarm:read']) ? alarmsSummary.count : 0}
      key={id}
      status={status}
      onClick={(e) => {
        scrollIntoView(chargePointId);
        e.stopPropagation();
        selectStationId(id);
      }}
    />
  );

  useEffect(() => {
    setSortedStations(sortStations(stations));
  }, [stations, setSortedStations, sortStations]);

  useEffect(() => {
    if (selectedStationId)
      setSelectedStation(
        stations.find((station) => station.id === selectedStationId)
      );
    else setSelectedStation(undefined);
  }, [stations, setSelectedStation, selectedStationId]);

  return (
    <Container>
      <Map
        coordinatesOfAllElements={sortedStations.flatMap(
          (st) => st.coordinates
        )}
        center={
          selectedStation &&
          selectedStation.coordinates && {
            latitude: selectedStation.coordinates.latitude,
            longitude: selectedStation.coordinates.longitude,
          }
        }
        mapStyle={mapStyle}
        scrollWheelEnabled={scrollWheelEnabled}
      >
        {sortedStations.map(generateMarker)}
        {selectedStation && selectedStation.coordinates && (
          <StationPreview
            stationId={selectedStation.id}
            lat={selectedStation.coordinates.latitude}
            lng={selectedStation.coordinates.longitude}
            onClose={() => {
              selectStationId(undefined);
            }}
            setScrollWheelEnabled={setScrollWheelEnabled}
            onOpenStationPage={onOpenStationPage}
            onAlarmPress={hasScopes(['alarm:read']) ? onAlarmPress : () => {}}
          />
        )}
      </Map>
    </Container>
  );
};

export default MonitoringMap;
