import React from 'react';
import moment from 'moment';
import { InformationBubble } from 'new-components/InformationBubble';
import { Icon } from 'new-components/Icon';
import { AlarmLabelFieldsFragment } from 'generated/graphql';
import { formatAlarmLabel } from '../../services/formatAlarm';
import { BottomText, Description, Timeframe } from './styles';

export type StationAlarmData = {
  id: number;
  timestamp: string | Date;
  resolved: boolean;
  resolvedBy?: number;
  createdByStationLogId: number;
} & AlarmLabelFieldsFragment;

type Props = {
  alarm: StationAlarmData;
  selected?: boolean;
  onClick?: (alarm: StationAlarmData) => void;
  isResolved: boolean;
} & (
  | {
      isResolved: false;
    }
  | {
      isResolved: true;
      resolvedText: string;
    }
);

const StationAlarm: React.FC<Props> = ({
  alarm,
  selected,
  onClick,
  ...props
}) => {
  const color = props.isResolved ? 'SECONDARY_GRAY' : 'ERROR';
  const accent = props.isResolved ? undefined : 'danger';

  return (
    <InformationBubble
      state={selected ? 'selected' : 'active'}
      icon={{ type: 'NEW_INFO_FILL', color }}
      accent={accent}
      title={alarm.__typename}
      content={
        <>
          <Description>{formatAlarmLabel(alarm)}</Description>
          <Timeframe>{moment(new Date(alarm.timestamp)).fromNow()}</Timeframe>
        </>
      }
      onClick={() => onClick?.(alarm)}
    >
      {props.isResolved && (
        <BottomText>
          <Icon size={16} color="RISE" type="NEW_SUCCESS"></Icon>
          {props.resolvedText}
        </BottomText>
      )}
    </InformationBubble>
  );
};

export { StationAlarm };
