import { Typography } from 'components';
import styled from 'styled-components';
import Config from 'config';

export const Container = styled.div`
  height: 803px;
  width: 100%;
`;

export const Title = styled(Typography).attrs({ size: 20 })`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
`;

export const EditIcon = styled(Config.ICONS.PENCIL)`
  margin-right: 12px;
`;

export const ButtonContainer = styled.div`
  width: 156px;
  height: 30px;
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const CarouselContainer = styled.div`
  margin: 5px 0px 25px 0px;
`;
