import Config from 'config';
import * as firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const config = {
  apiKey: 'AIzaSyCV1Ek1-2UQ41CP0S2EgLhicgZ3vErViz4',
  authDomain: 'cool-encoder-243621.firebaseapp.com',
  databaseURL: 'https://cool-encoder-243621.firebaseio.com',
  projectId: 'cool-encoder-243621',
  storageBucket: 'cool-encoder-243621.appspot.com',
  messagingSenderId: '282928417739',
  appId: '1:282928417739:web:b7ecd82f3528cb4cad044e',
  measurementId: 'G-PVCX2XQ703',
};

const app = initializeApp(config);
const auth = getAuth(app);
auth.tenantId = Config.AUTH.TENANT_ID;

export { firebase, auth };
