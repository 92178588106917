import { Typography } from 'components';
import styled, { css } from 'styled-components';
import Config from 'config';

type ArrowType = {
  opacity: number;
};

export const Overlay = styled.div`
  background: #00000080;
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 786px;
  height: 500px;
  border-radius: 30px;
  background-color: white;
  z-index: 5;
  position: relative;
  padding: 10px 20px 44px;
`;

export const CurrentImageIndicator = styled(Typography).attrs({
  size: 16,
  weight: 'bold',
})``;

export const ButtonContainer = styled.div`
  height: 34px;
  width: 138px;
  margin: auto;
  margin-top: 36px;
`;

export const Footer = styled.div`
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 329px;
  margin-top: 47px;
  margin-bottom: 18px;
  position: relative;
`;

export const LeftArrow = styled(Config.ICONS.LEFT_ARROW)<ArrowType>`
  width: 23px;
  height: 15px;
  padding: 7px;
  border-radius: 6px;
  opacity: ${({ opacity }) => opacity};
  ${({ opacity }) =>
    opacity === 1 &&
    css`
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
      }
    `}
`;

export const RightArrow = styled(Config.ICONS.RIGHT_ARROW)<ArrowType>`
  width: 23px;
  height: 15px;
  padding: 7px;
  border-radius: 6px;
  opacity: ${({ opacity }) => opacity};
  ${({ opacity }) =>
    opacity === 1 &&
    css`
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.COLORS.TERTIARY_GRAY};
      }
    `}
`;

export const ImagePaginationContainer = styled.div`
  width: 253px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const PreviewImage = styled.img`
  display: block;
  max-height: calc(100% + 8px);
  max-width: calc(100% + 8px);
  margin: -3px;
`;
