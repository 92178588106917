import AuthContext from 'contexts/Auth/context';
import { useContext, useEffect } from 'react';
import { useAuthQuery } from 'hooks';
import {
  ZendeskTokenQuery,
  ZendeskTokenQueryVariables,
} from 'generated/graphql';
import zendeskToken from 'graphql/queries/zendeskToken';

type Props = {
  embeddedKey: string;
};

interface ZendeskWindowTypes extends Window {
  zESettings: any;
  zE: any;
}

const ZendeskQueryComponent: React.FC<Props> = ({ embeddedKey }) => {
  const { member } = useContext(AuthContext);

  const { data, error, loading } = useAuthQuery<
    ZendeskTokenQuery,
    ZendeskTokenQueryVariables
  >(zendeskToken);

  const zEWindow = window as ZendeskWindowTypes & typeof globalThis;

  const ZendeskAPI = (...args: any[]) => {
    zEWindow.zE.apply(null, args);
  };

  const insertScript = (zendeskKey: string) => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!loading && !error) {
      insertScript(embeddedKey);
      zEWindow.zESettings = {
        webWidget: {
          authenticate: {
            jwtFn(callback: any) {
              callback(data?.generateZendeskToken);
            },
          },
        },
      };
    }
  }, [loading, zEWindow, error, data, embeddedKey]);

  window.setTimeout(
    () => ZendeskAPI('webWidget', 'setLocale', member?.language ?? 'pt_BR'),
    15000
  );
  return <></>;
};

const ZendeskWidget: React.FC<Props> = (props) => {
  const { memberAuthToken } = useContext(AuthContext);

  if (!memberAuthToken) return <></>;

  return <ZendeskQueryComponent {...props} />;
};

export default ZendeskWidget;
