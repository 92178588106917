import Pagination, { linesPerPageOptionsType } from 'components/Pagination';
import LoadingComponent from 'new-components/LoadingComponent';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column as ColumnType,
  Filters,
  PluginHook,
  Row as RowType,
  SortingRule,
  TableOptions,
  TableState,
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { TimeRange } from 'services/timeRange';
import {
  Cell as CellComponent,
  Chevron,
  Container,
  HeaderCell,
  HeaderInnerContainer,
  HeaderRow,
  Row as RowComponent,
  TableBody,
  TableContainer,
} from './styles';

const DEFAULT_LINES_PER_PAGE_OPTION = 25;

export type Column<T extends object> = ColumnType<T>;
export type Row<T extends object> = RowType<T>;
export type FetchDataFunction<T extends object> = (args: {
  pageIndex: number;
  pageSize: number;
  filters: Filters<T>;
  sorts: SortingRule<T>[];
  search: string;
}) => void;

type Props<T extends object> = {
  searchValue?: string;
  columns: Array<Column<T>>;
  data: T[];
  filters?: Filters<T>;
  onRowPress?: (row: RowType<T>) => void;
  onRefreshTable?: () => void;
  columnMaxWidth?: number;
  headerHorizontalAlignment?: 'flex-start' | 'center' | 'flex-end';
  timeRange?: TimeRange;
  loading?: boolean;
  pageIndex?: number;
  selectedRowIndex?: number;
  isFullWidth?: boolean;
  renderBlankState?: JSX.Element;
} & (
  | {
      expandable?: false;
      renderRowSubComponent?: undefined;
    }
  | {
      expandable: true;
      renderRowSubComponent: React.ElementType<{ row: Row<T> }>;
    }
) &
  (
    | {
        paginated: true;
        fetchData: FetchDataFunction<T>;
        pageCount: number;
      }
    | {
        paginated?: false;
        pageCount?: undefined;
        fetchData?: undefined;
      }
  );

const DEFAULT_PAGE_SIZE = 25;

function Table<T extends object>({
  fetchData,
  data,
  columns,
  expandable,
  filters,
  paginated,
  columnMaxWidth,
  pageIndex: parentPageIndex,
  headerHorizontalAlignment = 'center',
  pageCount,
  renderRowSubComponent: RowSubComponent,
  onRowPress,
  onRefreshTable,
  selectedRowIndex,
  timeRange,
  searchValue = '',
  loading,
  isFullWidth,
  renderBlankState,
}: Props<T>) {
  const { t } = useTranslation();
  const clickable = !!onRowPress || expandable;
  const tablePlugins: PluginHook<T>[] = [useFilters, useSortBy, useExpanded];
  const [selectedLinesPerPageOption, setSelectedLinesPerPageOption] =
    useState<linesPerPageOptionsType>(DEFAULT_LINES_PER_PAGE_OPTION);
  if (paginated) tablePlugins.push(usePagination);

  // memoizing the row sub-component
  // as it changes every time the data array changes
  // causing it to reload while polling
  const [MemoizedRowSubComponent] = useState<any>(
    RowSubComponent &&
      React.memo(
        RowSubComponent,
        (a, b) =>
          JSON.stringify(a.row.original) === JSON.stringify(b.row.original)
      )
  );

  const [tableState, setTableState] = useState<TableState<T> | undefined>(
    undefined
  );

  const tableOptions: TableOptions<T> = {
    columns,
    data,
    initialState: { ...tableState, pageSize: DEFAULT_PAGE_SIZE },
    manualSortBy: true,
    manualPagination: true,
    pageCount,
  };
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    allColumns,
    state,
    toggleAllRowsExpanded,
    ...options
  } = useTable(tableOptions, ...tablePlugins);
  useEffect(() => {
    if (state) setTableState(state);
  }, [state]);

  const { pageSize, sortBy, pageIndex } = state;
  const { gotoPage } = options;

  // This is because the parent of table can select some specific page to navigate,
  // but without that the pagination index will be wrong
  useEffect(() => {
    if (parentPageIndex && parentPageIndex >= 0) gotoPage(parentPageIndex);
  }, [parentPageIndex, gotoPage]);

  const setPagination = useCallback(
    (num: number) => gotoPage?.(num - 1),
    [gotoPage]
  );

  useEffect(() => {
    fetchData?.({
      pageIndex,
      pageSize,
      filters: filters ?? [],
      sorts: sortBy,
      search: searchValue,
    });
  }, [fetchData, pageIndex, pageSize, filters, sortBy, searchValue]);

  useEffect(() => {
    setPagination(1);
  }, [timeRange, setPagination, filters, sortBy, searchValue]);

  const rows = paginated ? options.page : options.rows;

  useEffect(() => {
    toggleAllRowsExpanded(false);
    onRefreshTable?.();
  }, [
    pageIndex,
    pageSize,
    filters,
    sortBy,
    searchValue,
    toggleAllRowsExpanded,
    onRefreshTable,
  ]);

  return (
    <Container>
      <TableContainer>
        <table
          {...getTableProps()}
          style={{
            display: 'table',
            borderCollapse: 'collapse',
            width: isFullWidth ? '100%' : 'calc(100% - 24px)',
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderCell
                    data-test={`table-column-${column.id}`}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: t('table.sort-column.mouse-hover.title'),
                      })
                    )}
                  >
                    <HeaderInnerContainer
                      style={{
                        justifyContent: headerHorizontalAlignment,
                      }}
                    >
                      {column.render('Header')}
                      <span style={{ marginLeft: 6 }}>
                        {column.isSorted ? (
                          <Chevron
                            direction={column.isSortedDesc ? 'down' : 'up'}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </HeaderInnerContainer>
                  </HeaderCell>
                ))}
              </HeaderRow>
            ))}
          </thead>
          {loading ? (
            // fixing warning
            // https://stackoverflow.com/questions/55820297/how-to-fix-warning-validatedomnesting-div-cannot-appear-as-a-child-of
            <tbody>
              <tr>
                <td>
                  <LoadingComponent size={51} top={35} color="INFO_GRAY" />
                </td>
              </tr>
            </tbody>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {renderBlankState ||
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Fragment key={`${i}`}>
                      <RowComponent
                        {...row.getRowProps()}
                        onClick={
                          expandable
                            ? () => row.toggleRowExpanded()
                            : () => {
                                onRowPress?.(row);
                              }
                        }
                        selected={i === selectedRowIndex}
                        clickable={clickable}
                        data-test={`table-row-${i}`}
                      >
                        {row.cells.map((cell) => (
                          <CellComponent
                            data-test={`table-cell-${cell.column.id}`}
                            {...cell.getCellProps()}
                            style={{
                              maxWidth: columnMaxWidth,
                            }}
                          >
                            {cell.render('Cell')}
                          </CellComponent>
                        ))}
                      </RowComponent>
                      {!row.isExpanded ? null : (
                        <tr
                          style={{
                            display: 'table-row',
                            borderTop: 'white 2px solid',
                          }}
                        >
                          <td
                            style={{ width: '100%' }}
                            colSpan={allColumns.length}
                          >
                            {MemoizedRowSubComponent && (
                              <MemoizedRowSubComponent row={row} />
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </TableBody>
          )}
        </table>
      </TableContainer>
      {!loading && paginated && !!data.length && (
        <Pagination
          pageCount={pageCount ?? DEFAULT_PAGE_SIZE}
          setActivePage={setPagination}
          activePage={(parentPageIndex ?? pageIndex) + 1}
          setPageCount={options.setPageSize}
          setSelectedLinesPerPageOption={setSelectedLinesPerPageOption}
          selectedLinesPerPageOption={selectedLinesPerPageOption}
        />
      )}
    </Container>
  );
}

export default Table;
