import styled from 'styled-components';
import { Typography } from 'components';

export const BottomText = styled(Typography)`
  margin-top: 19px;
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
  font-size: 12px;
`;

export const Description = styled(Typography).attrs({
  size: 12,
})`
  margin: 4px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

export const Timeframe = styled(Typography).attrs({
  size: 12,
  color: 'SECONDARY_GRAY',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;
