import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationKeyType } from 'types/translation';
import { IconContainer, MenuItemContainer } from './styles';

interface Props extends RouteComponentProps {
  isActive?: boolean;
  label: TranslationKeyType;
  icon: React.ElementType;
  path?: string;
  showText: boolean;
}

const MenuItem: React.FC<Props> = ({
  isActive = false,
  label,
  icon,
  path = '/',
  history,
  showText,
  ...props
}) => {
  const Icon = icon;
  const { t } = useTranslation();
  return (
    <MenuItemContainer
      className={`menu-item ${isActive ? 'active' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(path);
      }}
      active={isActive}
      {...props}
    >
      <IconContainer>
        <Icon />
      </IconContainer>
      <span className={`${showText ? 'menu-item__open' : 'menu-item__label'}`}>
        {t(label)}
      </span>
    </MenuItemContainer>
  );
};

export default withRouter(MenuItem);
