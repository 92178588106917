import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { H3 } from 'new-components/Typographies/styles';
import React, { useState } from 'react';
import MultiSelect from 'atomic-components/molecules/MultiSelect';
import {
  ListDriversToGroupQuery,
  ListDriversToGroupQueryVariables,
  UpdateGroupDriversMutation,
  UpdateGroupDriversMutationVariables,
} from 'generated/graphql';
import { useAuthMutation, useAuthQuery } from 'hooks';
import toast from 'services/toast';
import updateGroupDriversMutation from 'graphql/mutations/updateGroupDrivers';
import { formatDriverNameToCheckbox } from 'atomic-components/organisms/modals/AccessGroups/functions/function';
import listDriversToGroup from 'graphql/queries/listDriversToGroup';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Header, Body, ButtonWrapper } from './styles';

type SelectProps = {
  id: any;
  name: string;
};

type Props = {
  onClose: () => void;
  show: boolean;
  groupId: string;
  selectedValues: Array<SelectProps>;
  setSelectedValues: (value: Array<SelectProps>) => void;
  updateListWhenUpdated: () => void;
  updateGroupSummary: () => void;
};

const UpdateGroupDriversModal: React.FC<Props> = ({
  onClose,
  show,
  groupId,
  selectedValues,
  setSelectedValues,
  updateListWhenUpdated,
  updateGroupSummary,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const [updateGroupDrivers, { loading }] = useAuthMutation<
    UpdateGroupDriversMutation,
    UpdateGroupDriversMutationVariables
  >(updateGroupDriversMutation);

  const { data, loading: loadingSearch } = useAuthQuery<
    ListDriversToGroupQuery,
    ListDriversToGroupQueryVariables
  >(listDriversToGroup, {
    variables: {
      limit: 10,
      search: searchValue,
    },
  });

  const options = formatDriverNameToCheckbox(data?.drivers || []);

  const handleUpdateGroupDrivers = async () => {
    try {
      const result = await updateGroupDrivers({
        variables: {
          data: { driverIds: selectedValues.map((item) => item.id) },
          where: {
            id: groupId,
          },
        },
      });
      if (result) {
        toast.success(t('update-group-drivers.toast-successfully-message'));
        updateListWhenUpdated();
        updateGroupSummary();
      }
    } catch (e) {
      toast.error(t('update-group-drivers.toast-error-message'));
    } finally {
      onClose();
    }
  };

  return (
    <Modal size="sm" show={show} onClose={onClose} centered overflow="visible">
      <Modal.Header>
        <Header>
          <Icon color="PRIMARY" type={'NEW_USER'} size={24} />
          <H3>{t('update-group-drivers.header.title')}</H3>
        </Header>
        <Modal.Header.Attachments padding="24">
          <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <MultiSelect
            options={options}
            setSearchValue={setSearchValue}
            setSelectedValues={setSelectedValues}
            selectedValues={selectedValues}
            emptyRecordMsg={
              loadingSearch
                ? t('update-group-drivers.body.multi-select.searching.message')
                : t(
                    'update-group-drivers.body.multi-select.not-value-found.message'
                  )
            }
          />
          <ButtonWrapper>
            <Button onClick={handleUpdateGroupDrivers} isLoading={loading}>
              {t('update-group-drivers.body.btn')}
            </Button>
          </ButtonWrapper>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateGroupDriversModal;
