import stopConnectorCharge from 'graphql/mutations/stopSession';
import {
  StopSessionMutation,
  StopSessionMutationVariables,
} from 'generated/graphql';
import { useAuthMutation } from 'hooks';
import { Icon } from 'new-components/Icon';
import Modal from 'new-components/NewModal';
import { BodyTypography, H3 } from 'new-components/Typographies/styles';
import React from 'react';
import toast from 'services/toast';
import { Button, Stack, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Header, Body, Label, ButtonWrapper } from './styles';

type Props = {
  onClose: () => void;
  show: boolean;
  onClickStopRecharge?: () => void;
  setIsForceStopRecharge: (isForceStopRecharge: boolean) => void;
  setIsStopRecharge: (isStopRecharge: boolean) => void;
  connectorId: any;
  stationId: any;
};

const StopRechargeModal: React.FC<Props> = ({
  onClose,
  show,
  setIsForceStopRecharge,
  setIsStopRecharge,
  connectorId,
  stationId,
}) => {
  const { t } = useTranslation();
  const [StopCharge, { loading }] = useAuthMutation<
    StopSessionMutation,
    StopSessionMutationVariables
  >(stopConnectorCharge);

  const handleCloseStopRecharge = async () => {
    try {
      const result = await StopCharge({
        variables: {
          where: {
            connectorId,
            stationId,
          },
        },
      });
      switch (result.data?.stopConnectorCharge.__typename) {
        case 'Error':
          setIsStopRecharge(false);
          setIsForceStopRecharge(true);
          break;
        case 'Ok':
          toast.success(
            t('stop-recharge-modal.body.toast-successfully-mesage')
          );
          onClose();
          break;
        default:
          break;
      }
    } catch (e) {
      setIsStopRecharge(false);
      setIsForceStopRecharge(true);
    }
  };
  return (
    <Modal size="sm" show={show} onClose={onClose} centered>
      <Modal.Header>
        <Header>
          <Icon color="Error" type="NEW_WARNING" size={24} />
          <H3>{t('stop-recharge-modal.header.title')}</H3>
        </Header>
        <Modal.Header.Attachments padding="24">
          <Modal.Header.Close onClose={onClose}></Modal.Header.Close>
        </Modal.Header.Attachments>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Label>
            <BodyTypography>
              {t('stop-recharge-modal.body.label')}
            </BodyTypography>
          </Label>
          <ButtonWrapper>
            <Flex>
              <Stack direction="row" spacing={6}>
                <Button
                  variant="link"
                  color="gray.900"
                  size="sm"
                  onClick={onClose}
                >
                  {t('stop-recharge-modal.body.cancel-btn.title')}
                </Button>
                <Button
                  colorScheme="error"
                  size="sm"
                  onClick={handleCloseStopRecharge}
                  isLoading={loading}
                  color="white"
                >
                  {t('stop-recharge-modal.body.stop-btn.title')}
                </Button>
              </Stack>
            </Flex>
          </ButtonWrapper>
        </Body>
      </Modal.Body>
    </Modal>
  );
};
export default StopRechargeModal;
