import React, { RefObject } from 'react';
import { Wrapper, ErrorText, HelperText, StyledSelect, Label } from './styles';

type Props<T extends string> = {
  options: readonly T[];
  value: T;
  onChange: (key: T) => void;
  name?: string;
  label?: string;
  error?: string;
  helperText?: string;
  inputRef?: RefObject<HTMLSelectElement>;
};

// TODO: change unfold more icon

function Select<T extends string>({
  onChange,
  value,
  options,
  label,
  error,
  helperText,
  inputRef,
  ...props
}: Props<T>) {
  function handleOnChange(e: React.FormEvent<HTMLSelectElement>) {
    const { selectedIndex } = e.currentTarget;
    const selectedOption = options[selectedIndex];
    onChange(selectedOption);
  }

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <StyledSelect
        ref={inputRef}
        error={error ? 1 : 0}
        onChange={handleOnChange}
        value={value}
        {...props}
      >
        {options.map((option) => (
          <option key={option} id={option}>
            {option}
          </option>
        ))}
      </StyledSelect>
      {error && <ErrorText>{error}</ErrorText>}
      {helperText && !error && <HelperText>{helperText}</HelperText>}
    </Wrapper>
  );
}

export default Select;
