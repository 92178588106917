import { useContext, useEffect, useState } from 'react';
import { VoucherStatus } from 'generated/graphql';
import { parseToResumedVoucherStatus, useVoucherActions } from '../../function';
import { VoucherClientContext } from '../../context/client-context';
import { VoucherEventsContext } from '../../context/events-context';
import { VoucherActionsType } from '../../types';

export type VoucherDriver = {
  id: number;
  name: string;
  email: string;
};

export type VoucherStation = {
  id: string;
  name: string;
  city: string;
};

export type VoucherDetails = {
  id: string;
  name: string;
  description: string;
  status: VoucherStatus | null;
  discount: number | null;
  organizationId: string | null;
  createdByMemberEmail: string | null;
  createdAt: Date | null;
  limits: {
    isUnlimited: boolean;
    usageLimit: number | null;
  };
  validityPeriod: {
    isUnexpirable: boolean;
    from: Date | null;
    to: Date | null;
  };
  drivers: VoucherDriver[];
  stations: VoucherStation[];
};

export type VoucherActionsTypeWithoutViewDetails = Exclude<
  VoucherActionsType,
  'VIEW_DETAILS'
>;

export const useVoucherDetails = () => {
  const client = useContext(VoucherClientContext).getClient()();
  const events = useContext(VoucherEventsContext).getEvents();

  const [voucherDetailsModalVisible, setVoucherDetailsModalVisible] =
    useState(false);
  const [voucherDetails, setVoucherDetails] = useState<VoucherDetails | null>();
  const { visibleOptionsPerVoucherStatus } = useVoucherActions({
    voucherStatus: voucherDetails?.status ?? null,
  });

  const {
    data: { voucher },
    loading: voucherDetailsLoading,
  } = client.getVoucher({
    where: { id: events.currentSelectedVoucher?.id ?? '' },
  });

  const closeVoucherDetailsModal = () => setVoucherDetailsModalVisible(false);

  useEffect(() => {
    if (voucher) {
      setVoucherDetails({
        id: voucher.id,
        name: voucher.name,
        description: voucher.description ?? '',
        status: voucher.status ?? null,
        discount: voucher.variableDiscount,
        organizationId: voucher.emspId,
        createdByMemberEmail: voucher.createdByMember?.profile?.email ?? null,
        createdAt: voucher.createdAt ? new Date(voucher.createdAt) : null,
        limits: {
          isUnlimited: voucher.isUnlimited ?? false,
          usageLimit: voucher.usageLimit ?? null,
        },
        validityPeriod: {
          isUnexpirable: !voucher.expiresAt,
          from: voucher.startsAt ? new Date(voucher.startsAt) : null,
          to: voucher.expiresAt ? new Date(voucher.expiresAt) : null,
        },
        drivers: (voucher.drivers ?? []).map<VoucherDriver>((driver) => ({
          id: driver.id ?? 0,
          name: `${driver.profile.firstName} ${driver.profile.lastName}`,
          email: driver.profile.email ?? '',
        })),
        stations: (voucher.stations ?? []).map<VoucherStation>((station) => ({
          id: station.id,
          name: station.name,
          city: station?.address?.city ?? '',
        })),
      });
    }
  }, [voucher]);

  useEffect(() => {
    if (voucherDetails) events.updateVoucherDetails(voucherDetails);
  }, [voucherDetails?.id]);

  useEffect(() => {
    events.updateVoucherDetailsLoading(voucherDetailsLoading);
  }, [voucherDetailsLoading]);

  useEffect(() => {
    if (events.voucherDetailsModalVisible) {
      setVoucherDetailsModalVisible(true);
    }
  }, [events.voucherDetailsModalVisible]);

  useEffect(() => {
    events.updateVoucherDetailsModalVisible(voucherDetailsModalVisible);
  }, [voucherDetailsModalVisible]);

  return {
    resumedVoucherStatus: voucherDetails?.status
      ? parseToResumedVoucherStatus(voucherDetails?.status)
      : null,
    voucherName: voucherDetails?.name,
    visibleOptionsPerVoucherStatus: visibleOptionsPerVoucherStatus.filter(
      (vs) => vs !== 'VIEW_DETAILS'
    ) as VoucherActionsTypeWithoutViewDetails[],
    voucherDetailsLoading,
    voucherDetailsModalVisible,
    closeVoucherDetailsModal,
  };
};
