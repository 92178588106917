import { List } from '@chakra-ui/react';
import { PasswordPolicyError } from 'main/reset-password/utils';
import { useTranslation } from 'react-i18next';
import { PasswordPolicy } from '.';

type Props = {
  errors: PasswordPolicyError[];
};

export const PasswordPolicyList = ({ errors }: Props) => {
  const { t } = useTranslation();

  const getRowColor = (rowError: PasswordPolicyError) => {
    const isRepeatedPasswordRowError = rowError === 'REPEATED_PASSWORD';
    if (errors.includes(rowError))
      return isRepeatedPasswordRowError ? 'error.500' : 'gray.500';
    return isRepeatedPasswordRowError ? 'gray.500' : 'success.700';
  };

  return (
    <List spacing={2} textAlign="left">
      <PasswordPolicy.Row
        color={getRowColor('TOO_SHORT')}
        text={t('password-policy-list.required-characters-text')}
      />
      <PasswordPolicy.Row
        color={getRowColor('NO_UPPERCASE')}
        text={t('password-policy-list.required-upper-case-text')}
      />
      <PasswordPolicy.Row
        color={getRowColor('NO_LOWERCASE')}
        text={t('password-policy-list.required-lower-case-text')}
      />
      <PasswordPolicy.Row
        color={getRowColor('NO_NUMBER')}
        text={t('password-policy-list.required-numbers-text')}
      />
      <PasswordPolicy.Row
        color={getRowColor('NO_SPECIAL_CHARACTER')}
        text={t('password-policy-list.required-special-character-text')}
      />
      <PasswordPolicy.Row
        color={getRowColor('REPEATED_PASSWORD')}
        text={t('password-policy-list.required-no-repeated-password-text')}
      />
    </List>
  );
};
