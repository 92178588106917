import { gql } from '@apollo/client';

export default gql`
  mutation GenerateBankSlipPaymentToWallet(
    $data: GenerateBankSlipPaymentToWalletInput!
  ) {
    generateBankSlipPaymentToWallet(data: $data) {
      digitableLine
      barcodeData
      barcode
      secureUrl
    }
  }
`;
