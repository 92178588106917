import { Box, BoxProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Dropdown from 'new-components/Dropdown';
import styled from 'styled-components';

export const NewDropdownContainer = styled(Dropdown.Container)`
  min-width: 224px;
`;

export const NewDropdownSection = styled(Dropdown.Section)`
  max-height: 260px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { COLORS } }) => COLORS.TERTIARY_GRAY};
    border-radius: 16px;
    border: 0px solid;
  }
`;

export const DropdownRow = styled(Dropdown.Row)`
  position: relative;
`;

export const MotionBox = motion<BoxProps>(Box);

export const ItemAnimation = {
  hidden: { y: -10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};
