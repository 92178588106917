import { CloseButton } from 'pages/StationPage/ManagerHMI/ImagesCrop/CloseButton';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { blobToFile } from 'services/file';
import { Button } from '@chakra-ui/react';
import { fileToDataURL } from '../../../../services/file';
import {
  ButtonContainer,
  Container,
  CurrentImageIndicator,
  Footer,
  ImageContainer,
  ImagePaginationContainer,
  LeftArrow,
  Overlay,
  RightArrow,
} from './styles';

interface Props {
  images: File[];
  onClose: () => void;
  onCrop: (x: File[]) => void;
}

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

const ImagesCrop: React.FC<Props> = ({ images, onClose, onCrop }) => {
  const [overlayMouseEvent, setOverlayMouseEvent] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [currentCroppedAreaPixels, setCurrentCroppedAreaPixels] =
    useState<Area>({} as Area);
  const [imagesCroppedAreaPixels, setImagesCroppedAreaPixels] = useState<
    Array<Area>
  >([]);
  const isLastImage = currentImage === images.length - 1;
  const isFirstImage = currentImage === 0;
  const hideArrows = images.length === 1;

  const leftArrowOpacity = hideArrows ? 0 : isFirstImage ? 0.3 : 1;
  const rightArrowOpacity = hideArrows ? 0 : isLastImage ? 0.3 : 1;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels: Area) => {
    setCurrentCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });

  const getImageDimensions = useCallback(async (url: string) => {
    const image = await createImage(url);
    return {
      width: image.naturalWidth,
      height: image.naturalHeight,
    };
  }, []);

  useEffect(() => {
    async function computeScaledZoom() {
      const { width, height } = await getImageDimensions(
        await fileToDataURL(images[currentImage])
      );
      const preZoomX = width / 786;
      const preZoomY = height / 329;
      const preMaxZoom = Math.max(preZoomX, preZoomY, 1);

      const zoomX = (444 * preMaxZoom) / width;
      const zoomY = (250 * preMaxZoom) / height;
      const maxZoom = Math.max(zoomX, zoomY);
      const scaledZoom = maxZoom;
      setMinZoom(scaledZoom);
      onZoomChange(scaledZoom);
    }
    computeScaledZoom();
  }, [images, currentImage, getImageDimensions]);

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area
  ): Promise<File> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return undefined as unknown as File;
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blobToFile(blob, `cropped`));
      }, 'image/jpeg');
    });
  };

  const showCroppedImage = async () => {
    try {
      const croppedImages = imagesCroppedAreaPixels.map(
        async (croppedArea, index) =>
          getCroppedImg(
            await fileToDataURL(images[index]),
            imagesCroppedAreaPixels[index]
          )
      );
      croppedImages.push(
        getCroppedImg(
          await fileToDataURL(images[currentImage]),
          currentCroppedAreaPixels
        )
      );
      Promise.all(croppedImages).then((resolvedCroppedImages) => {
        onCrop(resolvedCroppedImages);
        onClose();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onNextPress = () => {
    if (isLastImage) return;
    setImagesCroppedAreaPixels((prev) => {
      const prevArray = [...prev];
      prevArray[currentImage] = currentCroppedAreaPixels;
      return prevArray;
    });
    setCurrentImage((prev) => prev + 1);
  };

  const onPrevPress = () => {
    if (isFirstImage) return;
    setImagesCroppedAreaPixels((prev) => {
      const prevArray = [...prev];
      prevArray[currentImage] = currentCroppedAreaPixels;
      return prevArray;
    });
    setCurrentImage((prev) => prev - 1);
  };

  const handleButtonClick = () => {
    if (isLastImage) {
      showCroppedImage();
    } else {
      onNextPress();
    }
  };

  const [currentImageData, setCurrentImageData] = useState('');
  useEffect(() => {
    fileToDataURL(images[currentImage]).then((data) =>
      setCurrentImageData(data)
    );
  }, [currentImage, images]);

  return (
    <Overlay
      onMouseDown={() => setOverlayMouseEvent(true)}
      onMouseUp={() => {
        if (overlayMouseEvent) onClose();
      }}
    >
      <Container
        onMouseDown={(e) => {
          e.stopPropagation();
          setOverlayMouseEvent(false);
        }}
        onMouseUp={(_) => {
          setOverlayMouseEvent(false);
        }}
      >
        <CloseButton onClick={onClose}></CloseButton>
        <ImageContainer>
          <Cropper
            image={currentImageData}
            aspect={16 / 9}
            crop={crop}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={100}
            restrictPosition={true}
            cropSize={{ height: 250, width: 444 }}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropComplete}
          />
        </ImageContainer>
        <Footer>
          <ImagePaginationContainer>
            <LeftArrow
              opacity={leftArrowOpacity}
              onClick={() => onPrevPress()}
            ></LeftArrow>
            <CurrentImageIndicator>{`${currentImage + 1}/${
              images.length
            }`}</CurrentImageIndicator>
            <RightArrow
              opacity={rightArrowOpacity}
              onClick={() => onNextPress()}
            ></RightArrow>
          </ImagePaginationContainer>
          <ButtonContainer>
            <Button w="100%" size="sm" onClick={handleButtonClick}>
              {isLastImage ? 'Salvar' : 'Próximo'}
            </Button>
          </ButtonContainer>
        </Footer>
      </Container>
    </Overlay>
  );
};

export default ImagesCrop;
