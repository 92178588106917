import styled from 'styled-components';
import Config from 'config';

export const Container = styled.div`
   border-radius: 4px;
   border 1px solid ${({ theme }) => theme.COLORS.SECONDARY_GRAY};
   width: 16px;
   height: 16px;
   margin-right: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   &:hover {
      filter: brightness(0.7);
   }
`;

export const CheckIcon = styled(Config.ICONS.CHECK_THICK)`
  position: relative;
`;
