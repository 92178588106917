import Config from 'config';
import StationLink from 'components/StationLink';
import { Column } from 'components/Table';
import { SessionStatus } from 'generated/graphql';
import moment from 'moment';
import { NewTag } from 'new-components/NewTag';
import { H5 } from 'new-components/Typographies/styles';
import { Icon } from 'new-components/Icon';
import { OrderTotalContainer } from 'cards/SessionTable/styles';
import { useTranslation as UseTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { FormattedSession } from './formatSessionRow';

const getSessionDate = (session: Partial<FormattedSession>) =>
  Number(moment(session.date, 'DD/MM/YYYY').toDate());

export const getColumnOptions = <T extends Partial<FormattedSession>>(
  key: string,
  label: string
): Column<T> => {
  const { t } = UseTranslation();
  // @ts-ignore
  const options: Column<T> = {};

  switch (key) {
    case 'consumerLabel':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      break;
    case 'orderTotalCost':
      options.defaultCanSort = false;
      options.disableSortBy = true;
      // @ts-ignore
      options.Cell = ({ row: { original } }) => (
        <div>
          <OrderTotalContainer>
            {original.edited && (
              <Icon type="NEW_EDIT" color="PRIMARY_GRAY" size={12} />
            )}
            {original.orderTotalCost}
          </OrderTotalContainer>
        </div>
      );

      break;
    case 'revenue':
      // @ts-ignore
      options.Cell = ({ value }: { value?: string }) => <H5>{value || '-'}</H5>;
      break;

    case 'date':
      options.sortType = (rowA, rowB) =>
        getSessionDate(rowA.original) - getSessionDate(rowB.original);
      break;

    case 'stationName':
      // @ts-ignore
      options.Cell = ({ row: { original } }) => (
        <Flex justifyContent="center">
          <StationLink
            stationId={original.stationId!}
            stationName={original.stationName!}
            cpoId={original.cpoId}
          />
        </Flex>
      );
      break;

    case 'status' /* @ts-ignore */:
      options.Cell = ({ row: { original } }) => (
        <NewTag type="text" status={original.status as SessionStatus}>
          {t(Config.LABELS.SESSION_STATUS[original.status as SessionStatus])}
        </NewTag>
      );
      break;
  }

  // @ts-ignore
  return {
    Header: label,
    accessor: key,
    ...options,
  };
};
