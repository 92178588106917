import React, { useEffect, useRef, useState } from 'react';
import { Typography } from 'components';
import Loader from 'components/Loader';
import Config from 'config';
import { NotNestedIcons } from 'types';
import {
  Arrow,
  Container,
  ContainerButton,
  ContainerErrorOrLoader,
  ContainerInlineBlock,
  DropdownContainer,
  IconContainer,
  OptionsContainer,
  TextContainer,
} from './styles';

type Props<T> = {
  type: 'icon' | 'text';
  size?: 'small' | 'medium' | 'large';
  variant?: 'secondary' | 'tertiary';
  horizontalAlignment: 'start' | 'end';
  options: readonly T[];
  format: (t: T) => string;
  onClickAction: (key: T) => void;
  loading?: Boolean;
} & (
  | {
      type: 'icon';
      icon: keyof NotNestedIcons;
    }
  | {
      type: 'text';
      label: string;
      icon?: keyof NotNestedIcons;
    }
);

export default function NewDropdown<T>({
  size,
  variant,
  horizontalAlignment,
  options,
  format,
  onClickAction,
  loading,
  ...props
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownSize = props.type === 'icon' ? 'small' : size ?? 'medium';
  const dropdownVariant = variant ?? 'secondary';

  const getLabel = () => {
    if (props.type !== 'text') return <></>;
    const Label = <Typography size={14}>{props.label}</Typography>;
    return Label;
  };

  const handleClickOutside = (e: any) => {
    if (!containerRef.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const renderIcon = () => {
    if (!props.icon) return <></>;

    const Icon = Config.ICONS[props.icon];
    return <Icon height={20} width={20} />;
  };

  const renderIconDropdownButton = () => (
    <>
      <IconContainer>{renderIcon()}</IconContainer>
    </>
  );

  const renderTextDropdownButton = () => (
    <>
      <IconContainer>{renderIcon()}</IconContainer>
      <TextContainer>{getLabel()}</TextContainer>
      <Arrow open={isOpen} />
    </>
  );

  const renderDropdownButton = () => {
    if (props.type === 'icon') return renderIconDropdownButton();
    return renderTextDropdownButton();
  };

  const handleOptionClick = (option: T) => {
    onClickAction(option);
    setIsOpen(false);
  };

  const renderContent = () => (
    <Container ref={containerRef}>
      <ContainerInlineBlock>
        {loading && (
          <ContainerErrorOrLoader size={dropdownSize} type={props.type}>
            <Loader color="SECONDARY_GRAY" size={16} />
          </ContainerErrorOrLoader>
        )}
        <ContainerButton
          variant={dropdownVariant}
          loading={loading}
          open={isOpen}
          size={dropdownSize}
          type={props.type}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((isOpen) => !isOpen);
          }}
        >
          {renderDropdownButton()}
        </ContainerButton>
      </ContainerInlineBlock>
      {isOpen && (
        <DropdownContainer horizontalAlignment={horizontalAlignment}>
          {options.map((option, key) => (
            <OptionsContainer
              key={key}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick(option);
              }}
            >
              <Typography size={14}>{format(option)}</Typography>
            </OptionsContainer>
          ))}
        </DropdownContainer>
      )}
    </Container>
  );

  return <>{renderContent()}</>;
}
