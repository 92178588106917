import React from 'react';
import { ModalHeader } from './Header';
import { ModalBody } from './Body';
import { ModalFooter } from './Footer';
import { Size } from './size';
import { ModalContainer } from './styles';

type Props = {
  backdrop?: 'static' | boolean;
  show: boolean;
  onClose: () => void;
  size: Size;
  centered?: true;
  overflow?: 'hidden' | 'visible' | 'scroll' | 'auto';
};

const NewModal: React.FC<Props> = ({
  backdrop,
  show,
  onClose,
  size,
  centered,
  children,
  overflow,
}) => (
  <ModalContainer
    backdrop={backdrop}
    show={show}
    onClose={onClose}
    onHide={onClose}
    size={size}
    centered={centered}
    overflow={overflow}
  >
    {children}
  </ModalContainer>
);

export default Object.assign(NewModal, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});
