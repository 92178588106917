import { NotNestedColors } from 'new-components/Icon';
import React from 'react';
import Popover, { PopoverProps } from 'react-tiny-popover';
import { DropdownChevronLeftButton } from './ChevronLeftButton';
import { DropdownContainer } from './Container';
import { DropdownExpandableRow } from './ExpandableRow';
import { DropdownHeaderGridContainer } from './HeaderGridContainer';
import { DropdownRow } from './Row';
import { DropdownRowLoading } from './RowLoading';
import { DropdownSection } from './Section';
import { DropdownTriggerButtonContainer } from './TriggerButtonContainer';
import { DropdownTriggerSelectContainer } from './triggerSelectContainer';

/* Injeção de propriedades genéricas */
export type InjectChildrenProps = { children: JSX.Element | JSX.Element[] };
export type InjectDivProps = Omit<JSX.IntrinsicElements['div'], 'ref'>;
export type InjectColorProps = {
  color?: keyof NotNestedColors;
};
export type InjectButtonProps = Omit<JSX.IntrinsicElements['button'], 'ref'>;

type DropdownProps = PopoverProps & {
  isOpen: boolean;
  toggleDropdown: (isOpen: boolean) => void;
  isBeingUsedInsideModal?: boolean;
};

const Dropdown = ({
  children,
  content,
  isOpen,
  isBeingUsedInsideModal,
  ...props
}: DropdownProps) => (
  <Popover
    {...props}
    containerStyle={
      isBeingUsedInsideModal
        ? { ...props.containerStyle, zIndex: '1500' }
        : { ...props.containerStyle }
    }
    isOpen={isOpen}
    children={children}
    position="bottom"
    align="end"
    content={content}
  ></Popover>
);

export default Object.assign(Dropdown, {
  Container: DropdownContainer,
  HeaderGridContainer: DropdownHeaderGridContainer,
  Section: DropdownSection,
  Row: DropdownRow,
  TriggerSelectContainer: DropdownTriggerSelectContainer,
  RowLoading: DropdownRowLoading,
  ExpandableRow: DropdownExpandableRow,
  ChevronLeftButton: DropdownChevronLeftButton,
  TriggerButtonContainer: DropdownTriggerButtonContainer,
});
