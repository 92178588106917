import SessionTableCard from 'cards/SessionTable';
import DateDropdown from 'components/DateDropdown';
import {
  transformToSessionFilterInput,
  transformToSessionSortInput,
} from 'components/Filter';
import Page from 'components/Page';
import StationsSummaryCards from 'components/StationsSummariesCards';
import AuthContext from 'contexts/Auth/context';
import {
  StationDetailsSessionsSummaryQuery,
  StationDetailsSessionsSummaryQueryVariables,
  StationDetailsStaticDataQuery,
  StationDetailsStaticDataQueryVariables,
} from 'generated/graphql';
import stationDetailsPageStation from 'graphql/queries/stationDetailsSessionsSummary';
import stationDetailsStaticData from 'graphql/queries/stationDetailsStaticData';
import { useAuthQuery } from 'hooks';
import useHasScopes from 'hooks/useHasScope';
import moment from 'moment';
import NewPageHeader from 'new-components/NewPageHeader';
import RechargeModal from 'new-components/RechargeModal';
import { CardContainer } from 'pages/Home/styles';
import StationAndConnectorsGraphs from 'pages/StationPage/StationAnalytics/StationAndConnectorsGraphs';
import StationPanelInfosDetail from 'pages/StationPage/StationAnalytics/StationPanelInfosDetail';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatSessionFields,
  FormattedSession,
} from 'services/formatSessionRow';
import {
  DEFAULT_PERIOD,
  TimeRange,
  TIME_PERIODS_LIST,
} from 'services/timeRange';
import { TranslationKeyType } from 'types/translation';
import useDefaultCurrencyTypes from 'hooks/useDefaultCurrencyTypes';
import { getOrganizationDefaultCurrencyType } from 'services/getOrganizationDefaultCurrencyType';
import { StationContext } from '../StationContext';
import { Container, DateDropdownContainer } from './styles';

const columnsToBeShown: Array<keyof FormattedSession> = [
  'consumerLabel',
  'energyConsumed',
  'date',
  'begin',
  'end',
  'orderTotalCost',
  'duration',
];

// TODO: lookup defaults
const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
  filters: [] as any,
  search: '',
  sorts: [] as any,
};

// first time accessing Dashboard(will cache more times)
const now = moment();

const StationAnalytics: React.FC = () => {
  const { t } = useTranslation();

  const filtersConfig = [
    {
      inputType: 'text',
      type: 'driverId',
      placeholder: t('station-page.station-analytics.filter-placeholder'),
      label: t('station-page.station-analytics.filter-label'),
    },
  ] as const;

  const hasScopes = useHasScopes();
  const { id: stationId } = useContext(StationContext);

  const [rechargeModal, setRechargeModal] = useState({
    show: false,
    sessionId: '',
    cpoId: '',
    emspId: '',
  });

  const [{ pageIndex, pageSize, filters, sorts }, setPagination] =
    useState(defaultPagination);

  const [timeRange, setTimeRange] = useState<TimeRange>(DEFAULT_PERIOD);

  const {
    data: staticData,
    error: staticDataError,
    loading: staticDataLoading,
  } = useAuthQuery<
    StationDetailsStaticDataQuery,
    StationDetailsStaticDataQueryVariables
  >(stationDetailsStaticData, {
    variables: {
      where: { stationId },
    },
    fetchPolicy: 'network-only',
    skip: !stationId,
  });

  const {
    data: sessionsData,
    error: sessionsError,
    loading: sessionsLoading,
  } = useAuthQuery<
    StationDetailsSessionsSummaryQuery,
    StationDetailsSessionsSummaryQueryVariables
  >(stationDetailsPageStation, {
    variables: {
      where: { stationId },
      timeRange: timeRange.toTimeRangeInput(now),
      limit: pageSize,
      filter: transformToSessionFilterInput(filters),
      offset: pageIndex * pageSize,
      sort: transformToSessionSortInput(sorts),
    },
    skip: !hasScopes(['session:read']),
    fetchPolicy: 'network-only',
  });

  const stationStaticDataLoading = staticDataLoading;

  const sessionsDataLoading = sessionsLoading;
  const revenue = sessionsData?.station?.sessionsSummary.grossValue || 0;
  const numberOfRecharges = sessionsData?.station?.sessionsSummary.count || 0;
  const averageRevenue = revenue / numberOfRecharges || 0;

  const handleCloseRechargeModal = () =>
    setRechargeModal({ show: false, sessionId: '', cpoId: '', emspId: '' });
  const handleRowClick = (
    sessionId?: string,
    cpoId?: string,
    emspId?: string
  ) =>
    setRechargeModal({
      show: true,
      sessionId: sessionId || '',
      cpoId: cpoId || '',
      emspId: emspId || '',
    });

  const { orgNameMap } = useContext(AuthContext);
  const defaultCurrencyTypes = useDefaultCurrencyTypes();
  return (
    <Page>
      <Container>
        <RechargeModal
          show={rechargeModal.show}
          onClose={handleCloseRechargeModal}
          sessionId={rechargeModal.sessionId}
          cpoId={rechargeModal.cpoId}
          emspId={rechargeModal.emspId}
        ></RechargeModal>
        <NewPageHeader title={''}>
          {hasScopes(['session:read']) && (
            <DateDropdownContainer>
              <DateDropdown
                defaultOption={TIME_PERIODS_LIST[1]}
                placeholderValue={DEFAULT_PERIOD}
                options={TIME_PERIODS_LIST}
                format={(timeRange) =>
                  t(timeRange.shortLabel as TranslationKeyType)
                }
                onChange={setTimeRange}
              />
            </DateDropdownContainer>
          )}
        </NewPageHeader>
        <CardContainer>
          <StationPanelInfosDetail
            loading={stationStaticDataLoading}
            error={staticDataError}
            station={staticData?.station}
          />
          <StationAndConnectorsGraphs
            stationId={stationId}
            timeRange={timeRange}
          />
        </CardContainer>
        {hasScopes(['session:read']) && (
          <CardContainer>
            <StationsSummaryCards
              error={staticDataError}
              loading={sessionsDataLoading}
              averageRevenue={averageRevenue}
              numberOfRecharges={numberOfRecharges}
              usageRate={sessionsData?.station?.usageRate ?? 0}
              orderedUsageRateOrgArray={[]}
              orderedAverageRevenueOrgArray={[]}
              orderedNumberOfRechargesOrgArray={[]}
              currencyType={
                getOrganizationDefaultCurrencyType(
                  defaultCurrencyTypes,
                  sessionsData?.station?.cpoId
                ) ??
                getOrganizationDefaultCurrencyType(
                  defaultCurrencyTypes,
                  sessionsData?.station?.emsps?.[0]?.id
                )
              }
            />
          </CardContainer>
        )}
        {hasScopes(['session:read']) && (
          <CardContainer>
            <SessionTableCard
              data={
                sessionsData?.station?.tableSessions.map((session) =>
                  formatSessionFields(session, orgNameMap, defaultCurrencyTypes)
                ) || []
              }
              pageCount={Math.ceil(
                (sessionsData?.station?.tableSessionsSummary.count || 0) /
                  pageSize
              )}
              fetchData={setPagination}
              loading={sessionsDataLoading}
              error={sessionsError}
              columns={columnsToBeShown}
              filters={filtersConfig}
              tableTitle={t(
                'station-page.station-analytics.session-table-card.title'
              )}
              filterSessionsByStationId={stationId}
              timeRange={timeRange}
              handleRowClick={handleRowClick}
            />
          </CardContainer>
        )}
      </Container>
    </Page>
  );
};

export default StationAnalytics;
