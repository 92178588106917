import React from 'react';
import { StationMonitoringStatus } from 'pages/Monitoring/types';

type MonitoringContextType = {
  preStatusSelected: StationMonitoringStatus[] | undefined;
  setPreStatusSelected: (
    preStatusSelected: StationMonitoringStatus[] | undefined
  ) => void;
  buttonDisabled: boolean;
  setButtonDisabled: (disabled: boolean) => void;
};

const MonitoringContext = React.createContext<MonitoringContextType>({
  preStatusSelected: [],
  setPreStatusSelected: () => {
    throw new Error('setPreStatusSelected not defined');
  },
  buttonDisabled: true,
  setButtonDisabled: () => {
    throw new Error('setButtonDisabled not defined');
  },
});

export default MonitoringContext;
