import { gql } from '@apollo/client';

export default gql`
  mutation ConfigureAccessRestriction(
    $where: SelectStationInput!
    $data: ConfigureAccessRestrictionInput!
  ) {
    configureAccessRestriction(where: $where, data: $data)
  }
`;
