import ConnectorsList from 'components/AgreementConnectorsList';
import { StationsMonitoringQuery } from 'generated/graphql';
import React, { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Flex, Text } from '@chakra-ui/react';
import { Icon } from 'new-components/Icon';
import { NewTag } from 'new-components/NewTag';
import Config from 'config';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useHasScopes from 'hooks/useHasScope';
import { formatOrgId } from 'services/format';
import AuthContext from 'contexts/Auth/context';
import {
  BottomContainer,
  ButtonContainer,
  Container,
  StationName,
  StationNameContainer,
} from './styles';

type Station = StationsMonitoringQuery['stations'][number];

type Props = {
  station: Station;
  active?: boolean;
  onClick: (stationId: Station['id']) => void;
  title: string;
  children?: ReactNode;
};

const StationCard: React.FC<Props> = ({
  station,
  active,
  onClick,
  title,
  children,
}) => {
  const { connectors, id } = station;
  const { t } = useTranslation();
  const hasScopes = useHasScopes();
  const { orgNameMap } = useContext(AuthContext);

  const checkNumberOfAlarms = (totalAlarms: number) => {
    if (totalAlarms && totalAlarms > 100)
      return t('monitoring.station-more-than-hundred-alarms.title');

    return totalAlarms;
  };

  return (
    <Container onClick={() => onClick(id)} active={!!active}>
      <Box
        bg="gray.50"
        borderTopRadius="md"
        borderColor="gray.200"
        borderWidth="thin"
        h={6}
        lineHeight={6}
      >
        <Flex justify="center" px={2}>
          <Text fontSize="xs" noOfLines={1}>
            {formatOrgId(station.cpoId, orgNameMap)}
          </Text>
        </Flex>
      </Box>
      <Box p={4}>
        <Flex w="full" align="center">
          <NewTag type="text" status={station.status}>
            {t(Config.LABELS.STATION_STATUS[station.status])}
          </NewTag>
          {station.status === 'UNKNOWN' && (
            <Text size="xs" color="gray.500" ml={1}>
              {moment(station.lastConnectedAt).fromNow()}
            </Text>
          )}
          {hasScopes(['alarm:read']) && (
            <Flex align="center" flex={1} justify="flex-end">
              {!!station.alarmsSummary?.count && (
                <NewTag type="text" size="default" color="danger">
                  {checkNumberOfAlarms(station.alarmsSummary.count)}
                </NewTag>
              )}
            </Flex>
          )}
        </Flex>
        <StationNameContainer>
          <StationName>{title}</StationName>
        </StationNameContainer>
        {children}
        <BottomContainer>
          <ConnectorsList connectors={connectors} />
          <ButtonContainer>
            <Link to={`meus-eletropostos/${id}`}>
              <IconButton
                aria-label={t(
                  'monitoring.station-card.icon-button.see-station-details-aria-label'
                )}
                colorScheme="gray"
                variant="outline"
                size="sm"
                icon={<Icon type="NEW_LAUNCH" color="PRIMARY_GRAY" size={20} />}
              />
            </Link>
          </ButtonContainer>
        </BottomContainer>
      </Box>
    </Container>
  );
};

export default StationCard;
