import { gql } from '@apollo/client';

export default gql`
  query StationUnlockMethods($where: StationWhereUniqueInput!) {
    station(where: $where) {
      id
      unlockMethods
    }
  }
`;
