import moment from 'moment';
import { formatNumber } from 'services/format';

const isDurationValid = (value: string) => /^\d+:\d{2}:\d{2}$/.test(value);

export const sanitizeSessionDuration = (value: string) => {
  if (isDurationValid(value)) {
    return moment.duration(value).asSeconds();
  }
  return 0;
};

const getFloatFromFormattedValue = (value: string): number | null => {
  const numberWithComma = value.replace(/[^\d,]+/g, '');
  const numberWithDecimalDot = numberWithComma.replace(',', '.');
  const float = parseFloat(numberWithDecimalDot);

  if (Number.isNaN(float)) {
    return null;
  }

  return float;
};

export const sanitizeEnergyConsumed = (value: string) => {
  const float = getFloatFromFormattedValue(value);
  if (float != null) return float * 1000;
  return 0;
};

export const energyMask = (value: string): string => {
  const number = Number(value) || 0;
  const amount = formatNumber(number / 100);
  return `${amount} kWh`;
};
