import Table from 'components/Table';
import styled from 'styled-components';
import { Typography } from 'components';
import config from 'config';

export const TableStyled = styled(Table)`
  width: 100%;
  padding: 0px;
`;

export const TypographData = styled(Typography)`
  background: ${() => config.COLORS.INFO_GRAY};
  padding: 4px 6px;
  border-radius: 15px;
  margin: 0 5px;
  font-size: 12px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

export const TypographyStyled = styled(Typography)`
  margin: 0;
  padding: 0;
  line-height: 25px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
`;

export const CardWrapper = styled.div`
  flex: 1;
  background-color: ${({
    theme: {
      COLORS: { BACKGROUND },
    },
  }) => BACKGROUND};
  margin-bottom: 30px;
`;

export const OrderTableContainer = styled.div`
  overflow: hidden;
  height: 700px;
  overflow-y: none;
  border: 1px solid
    ${({
      theme: {
        COLORS: { TERTIARY_GRAY },
      },
    }) => TERTIARY_GRAY};
  border-radius: 8px;
`;
export const Container = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`;
export const CardContainer = styled.div`
  padding: 10px 20px;
  margin-bottom: 20px;
`;
