import Config from 'config';
import styled from 'styled-components';
import { TagAllTypes } from '.';

const color: Record<TagAllTypes, string> = {
  ONLINE: Config.COLORS.STATION_STATUS.AVAILABLE,
  OFFLINE: Config.COLORS.STATION_STATUS.UNKNOWN,
  UNKNOWN: Config.COLORS.STATION_STATUS.UNKNOWN,
  PLANNED: Config.COLORS.STATION_STATUS.UNKNOWN,
  CRITICAL: Config.COLORS.CRITICAL,
  WARNING: Config.COLORS.WARNING,
  INFO: Config.COLORS.BACKGROUND,
  NOT_CONFIGURED: Config.COLORS.BACKGROUND,
  AVAILABLE: Config.COLORS.STATION_STATUS.AVAILABLE,
  PREPARING: Config.COLORS.STATION_STATUS.CHARGING,
  CHARGING: Config.COLORS.STATION_STATUS.CHARGING,
  FINISHING: Config.COLORS.STATION_STATUS.CHARGING,
  INOPERATIVE: Config.COLORS.STATION_STATUS.INOPERATIVE,
  RESERVED: Config.COLORS.STATION_STATUS.RESERVED,
};

export const Container = styled.div<{ type: TagAllTypes }>`
  padding: 4px 8px;
  border-radius: 12px;
  background: ${({ type }) => color[type]};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
`;
