import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import moment from 'moment';
import { VoucherStatesContext } from 'pages/Payments/Voucher/context/states-context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';

const Property = ({
  title,
  value,
  width,
  dataTestId,
}: {
  title: string;
  value: string;
  width?: string;
  dataTestId?: string;
}) => (
  <Box style={{ width }} mb={6}>
    <Heading mb={2} fontSize="x-small" color={Config.COLORS.SECONDARY_GRAY}>
      {title}
    </Heading>
    <Text
      data-testid={dataTestId}
      fontSize={'sm'}
      color={Config.COLORS.PRIMARY_GRAY}
    >
      {value}
    </Text>
  </Box>
);

export const GeneralInformation = () => {
  const { t } = useTranslation();
  const { voucherDetails } = useContext(VoucherStatesContext);
  const {
    name,
    description,
    limits,
    discount,
    validityPeriod,
    createdByMemberEmail,
    createdAt,
  } = voucherDetails ?? {
    name: '-',
    description: '',
    createdByMemberEmail: null,
    createdAt: null,
    discount: null,
    limits: { isUnlimited: false, usageLimit: null },
    validityPeriod: { isUnexpirable: false, from: null, to: null },
  };

  const fromDate = moment(validityPeriod.from);
  const toDate = moment(validityPeriod.to);
  const diffDays = toDate.diff(fromDate, 'days');

  return (
    <Flex p="1.25rem" data-testid="general-information">
      <Box style={{ width: '30%' }}>
        <Property
          dataTestId="voucher-name"
          title={t(
            'payments.voucher-tab.voucher-details.tab.general.body.voucher-name'
          )}
          value={name}
        />
        <Property
          dataTestId="voucher-created-by"
          title={t(
            'payments.voucher-tab.voucher-details.tab.general.body.created-by'
          )}
          value={createdByMemberEmail ?? '-'}
        />
        <Property
          dataTestId="voucher-created-at"
          title={t(
            'payments.voucher-tab.voucher-details.tab.general.body.created-at'
          )}
          value={
            createdAt
              ? t(
                  'payments.voucher-tab.voucher-details.tab.general.body.created-at.value',
                  { value: createdAt }
                )
              : '-'
          }
        />
      </Box>
      <Box style={{ width: '70%' }}>
        <Property
          dataTestId="voucher-description"
          title={t(
            'payments.voucher-tab.voucher-details.tab.general.body.voucher-description'
          )}
          value={description}
        />
        <Flex w={'full'}>
          <Box w={'full'}>
            <Property
              dataTestId="voucher-discount"
              title={t(
                'payments.voucher-tab.voucher-details.tab.general.body.discount'
              )}
              value={discount === null || discount < 0 ? '-' : `${discount}%`}
            />
            <Property
              dataTestId="voucher-usage-limit"
              title={t(
                'payments.voucher-tab.voucher-details.tab.general.body.usage-limit'
              )}
              value={
                limits.usageLimit
                  ? `${limits.usageLimit}`
                  : t(
                      'payments.voucher-tab.voucher-details.tab.general.body.usage-limit.unlimited'
                    )
              }
            />
          </Box>
          <Box w={'full'}>
            {validityPeriod.from !== null && validityPeriod.to !== null ? (
              <>
                <Property
                  dataTestId="voucher-validity-period"
                  title={t(
                    'payments.voucher-tab.voucher-details.tab.general.body.validity-period'
                  )}
                  value={
                    diffDays < 0
                      ? '-'
                      : diffDays > 1
                      ? t(
                          'payments.voucher-tab.voucher-details.tab.general.body.validity-period.days',
                          { value: diffDays }
                        )
                      : t(
                          'payments.voucher-tab.voucher-details.tab.general.body.validity-period.one-day'
                        )
                  }
                />
                <Property
                  dataTestId="voucher-validity-period-date"
                  title={t(
                    'payments.voucher-tab.voucher-details.tab.general.body.date'
                  )}
                  value={t(
                    'payments.voucher-tab.voucher-details.tab.general.body.date.value',
                    {
                      from: validityPeriod.from,
                      to: validityPeriod.to,
                    }
                  )}
                />
              </>
            ) : (
              <Property
                dataTestId="voucher-validity-period-unexpirable"
                title={t(
                  'payments.voucher-tab.voucher-details.tab.general.body.validity-period'
                )}
                value={t(
                  'payments.voucher-tab.voucher-details.tab.general.body.validity-period.unexpirable'
                )}
              />
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
