import { VoucherStatus } from 'generated/graphql';
import useHasScopes from 'hooks/useHasScope';
import {
  AvailableVoucherActions,
  ResumedVoucherStatus,
  VoucherActionsType,
} from './types';

export type UseVoucherActionsProps = {
  voucherStatus: VoucherStatus | null;
};

export const useVoucherActions = ({
  voucherStatus,
}: UseVoucherActionsProps) => {
  const hasScopes = useHasScopes();

  if (!voucherStatus) {
    return {
      visibleOptionsPerVoucherStatus: ['VIEW_DETAILS'],
    };
  }

  const availableOptionsToExpiredStatus: (keyof typeof AvailableVoucherActions)[] =
    [
      'VIEW_DETAILS',
      ...((hasScopes(['voucher:edit'])
        ? ['ARCHIVE']
        : []) as (keyof typeof AvailableVoucherActions)[]),
    ];

  const dropdownOptionsByVoucherStatus: {
    [K in VoucherStatus]: (keyof typeof AvailableVoucherActions)[];
  } = {
    AVAILABLE: [
      'VIEW_DETAILS',
      ...((hasScopes(['voucher:edit'])
        ? ['DISABLE', 'ARCHIVE']
        : []) as (keyof typeof AvailableVoucherActions)[]),
    ],
    ARCHIVED: ['VIEW_DETAILS'],
    DISABLED: [
      'VIEW_DETAILS',
      ...((hasScopes(['voucher:edit'])
        ? ['ACTIVE', 'ARCHIVE']
        : []) as (keyof typeof AvailableVoucherActions)[]),
    ],
    EXPIRED: availableOptionsToExpiredStatus,
    USAGE_LIMIT_REACHED: availableOptionsToExpiredStatus,
    SCHEDULED: [
      'VIEW_DETAILS',
      ...((hasScopes(['voucher:edit'])
        ? ['EDIT', 'ARCHIVE']
        : []) as (keyof typeof AvailableVoucherActions)[]),
    ],
  };

  const visibleOptionsPerVoucherStatus: VoucherActionsType[] =
    dropdownOptionsByVoucherStatus[voucherStatus];

  return {
    visibleOptionsPerVoucherStatus,
  };
};

export const parseToResumedVoucherStatus = (
  voucherStatus: VoucherStatus
): ResumedVoucherStatus => {
  if (voucherStatus === 'USAGE_LIMIT_REACHED') {
    return 'EXPIRED';
  }
  return voucherStatus;
};
