import {
  ApolloError,
  OperationVariables,
  QueryHookOptions,
} from '@apollo/client';
import AuthContext from 'contexts/Auth/context';
import { DocumentNode } from 'graphql';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';

export default function useRealtimeQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  providedOptions: QueryHookOptions<TData, TVariables> = {}
) {
  const [data, setData] = useState<Record<string, TData | undefined>>({});
  const [error, setError] = useState<ApolloError | undefined>();
  const [polling, setPolling] = useState<boolean>(false);
  const { memberAuthToken, selectedOrganizationIds } = useContext(AuthContext);
  const [
    selectedOrganizationIdsHasChanged,
    setSelectedOrganizationIdsHasChanged,
  ] = useState(false);
  // TODO: Fix that, the types should be from urql
  // @ts-ignore
  const options: QueryHookOptions<TData, TVariables> = {
    ...providedOptions,
    context: useMemo(
      () => ({
        fetchOptions: {
          headers: {
            authorization: `Bearer ${memberAuthToken}`,
            'org-ids': selectedOrganizationIds,
          },
        },
      }),
      [memberAuthToken, selectedOrganizationIds]
    ),
    variables: {
      ...providedOptions?.variables,
    } as TVariables,
  };

  const [result, executeQuery] = useQuery<TData, TVariables>({
    query,
    // @ts-ignore
    requestPolicy: options?.fetchPolicy || 'cache-and-network',
    pause: options?.skip,
    ...options,
  });
  const { variables, skip } = options ?? {};
  const queryId = JSON.stringify({ variables, skip });
  const loading =
    (!data[queryId] || selectedOrganizationIdsHasChanged) &&
    result.fetching &&
    !polling;

  useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => {
        setPolling(true);
        executeQuery({ requestPolicy: 'network-only', polled: true });
      }, options?.pollInterval ?? 5000);
      return () => clearTimeout(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.fetching, executeQuery]);

  useEffect(() => {
    if (result.fetching) {
      setPolling(false);
    }
  }, [result]);

  const checkIfQueryContainsData = (queryData?: TData) =>
    !!(
      queryData &&
      Object.keys(queryData).length &&
      Object.keys(queryData)
        // @ts-ignore
        .map((key) => queryData[key])
        .some((element) => !!element)
    );

  useEffect(() => {
    if (
      checkIfQueryContainsData(result.data) &&
      JSON.stringify(data) !== JSON.stringify(result.data)
    ) {
      setData((data) => ({
        ...data,
        [queryId]: result.data,
      }));
    }
    if (selectedOrganizationIdsHasChanged)
      setSelectedOrganizationIdsHasChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data]);

  useEffect(() => {
    if (result.error && !data) {
      setError(result.error as any);
    }
    setError(undefined);
    if (selectedOrganizationIdsHasChanged)
      setSelectedOrganizationIdsHasChanged(false);

    // TODO: should "data" be in the deps?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.error]);

  useEffect(() => {
    executeQuery();
    setSelectedOrganizationIdsHasChanged(true);
  }, [selectedOrganizationIds, executeQuery]);

  return {
    data: data[queryId],
    loading,
    error,
    refetch: executeQuery,
    // fetchMore: variables => executeQuery({

    // })
  };
}
