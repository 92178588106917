import Card from 'components/Card';
import ImagesCarousel from 'components/ImagesCarousel';
import { StationDetailsStaticDataQuery } from 'generated/graphql';
import React from 'react';
import { StationInfos } from './StationInfos';
import { CarouselContainer, Container } from './styles';

export type StationDetail = StationDetailsStaticDataQuery['station'];

type Props = {
  loading: boolean;
  error: any;
  station?: StationDetail;
};

const StationPanelInfosDetail: React.FC<Props> = ({
  loading,
  error,
  station,
}) => {
  const { images } = station || {};

  return (
    <Card loading={loading} error={error} type="simple">
      <Container>
        <CarouselContainer>
          <ImagesCarousel images={images || []} />
        </CarouselContainer>
        <StationInfos station={station} />
      </Container>
    </Card>
  );
};

export default StationPanelInfosDetail;
